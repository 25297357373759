/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { formatValue } from '../ui-elements/Money'

import { Container } from 'react-bootstrap'
// import csvDownload from 'json-to-csv-export'
import { Wrapper } from '../ContactsPage/ContactsPage'
import { useSelector } from 'react-redux'
import { getUserOrganizationId } from '../../utils/state/selectors'
import {
  useTable,
  useBlockLayout,
  useResizeColumns,
  usePagination,
  useFilters,
} from 'react-table'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Filters from './filterHelper'
import { isNil, isEmpty, all } from 'ramda'

import {
  filterDonationsData,
  filterDonationsDataCount,
  getDonationsDataRanges,
} from '../../api/donations_data'
const _ = require('lodash')

const DATE_FORMAT = 'YYYY-MM-DD'

const PAGE_SIZES = [25, 50, 100]
const INITIAL_PAGE_SIZE = 50

const GOTO_PAGE_DEBOUNCE_INTERVAL_MSEC = 500

const filterHelper = new Filters()

const { fuzzyTextFilterFn, dateBetweenFilterFn } =
  filterHelper.getFilterFunctions()

const {
  DonorColumnFilter,
  SelectColumnFilter,
  NumberRangeColumnFilter,
  DateRangeColumnFilter,
} = filterHelper.getFilterComponents()

let doGoToPage = () => {
  console.log('Go To Page function not set yet.')
}

const Styles = styled.div`
  padding: 1rem;
  width: 100%;

  .table {
    display: inline-block;
    border-spacing: 0;
    .th {
      background-color: lightgray;
      font-weight: bold;
      height: 80px;
    }

    .td {
      text-indent: 10px;
      border-left: 1px solid black;
      border-top: 1px solid black;
      white-space: nowrap;
      overflow-x: hidden;
      overflow-y: hidden;
    }
    .th,
    .td {
      padding: 2px;
      border-left: 1px solid black;
      border-top: 1px solid black;

      ${
        '' /* In this example we use an absolute position resizer,
       so this is required. */
      }
      position: relative;

      :last-child {
        border-right: 1px solid black;
      }

      .resizer {
        display: inline-block;
        background: white;
        border: 1px solid black;
        width: 2px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing {
          background: red;
        }

        :hover {
          width: 10px;
          background: blue;
        }
      }
    }
  }
`
const formatRow = row => {
  row.donation_date = moment(row.donation_date).format(DATE_FORMAT)
  switch (row.donation_source_id) {
    case 'ANE':
      row.donation_source_id = 'Anedot'
      break
    case 'WR':
      row.donation_source_id = 'WinRed'
      break
    case 'CUST':
      row.donation_source_id = 'Customer-Tracked'
  }
  return row
}

const formatCell = (val, key) => {
  let res = val
  let fractionDigits
  switch (key) {
    case 'donation_total':
      fractionDigits = val < 1 && val > 0 ? 2 : 0
      res = formatValue(val, fractionDigits, fractionDigits, true)
      break
    case 'donor':
      res = val ? (
        <Link to={`/contact/${val._id}`}>
          {val?.prefix_name || ''} {val?.first_name} {val?.last_name}
        </Link>
      ) : (
        '----'
      )
      break
  }
  return <div>{res}</div>
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val
dateBetweenFilterFn.autoRemove = val => !val

let tableColumnResized = false

function Table({
  columns,
  data,
  recordCount,
  filterTypes,
  defaultColumn,
  fetchData,
  isLoading,
  pageCount: controlledPageCount,
  filters,
  text,
  clearFilters,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    resetResizing,
    columns: tableColumns,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      initialState: { pageIndex: 0, pageSize: INITIAL_PAGE_SIZE },
      manualPagination: true,
      autoResetPage: false,
      manualFilters: true,
      pageCount: controlledPageCount,
    },
    useFilters,
    usePagination,
    useBlockLayout,
    useResizeColumns
  )

  const gotoPageDebounced = _.debounce(
    gotoPage,
    GOTO_PAGE_DEBOUNCE_INTERVAL_MSEC
  )

  useEffect(() => {
    fetchData({ pageIndex, pageSize, filters })
  }, [fetchData, pageIndex, pageSize, filters, text])

  const _clearFilters = () => {
    tableColumns.forEach(column => {
      //Clear entries on the filter UIs
      switch (column.id) {
        //intentional fall-thru
        case 'donation_total':
        case 'donation_date':
          column.setFilter([])
          break
        case 'donation_source_id':
          column.setFilter('all')
          break
        case 'donor':
          column.setFilter('')
          break
      }
    })
    //clear the filters
    clearFilters()
    gotoPage(0)
  }

  doGoToPage = page => gotoPage(page)

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {isLoading ? (
          isNil(text) ? (
            <div className="mb-1">Loading...</div>
          ) : (
            <div className="mb-1">Searching...</div>
          )
        ) : !isNil(recordCount) ? (
          <div className="mb-1">
            {formatValue(recordCount)} records{' '}
            {all(isEmpty)(Object.entries(filters)) ? ' found' : ' filtered'}
          </div>
        ) : (
          <div>Counting records...</div>
        )}
      </div>

      <div {...getTableProps()} className="table">
        <div className="thead">
          {headerGroups.map((headerGroup, i) => (
            <div {...headerGroup.getHeaderGroupProps()} key={i} className="tr">
              {headerGroup.headers.map((column, i2) => (
                <div {...column.getHeaderProps()} key={i2} className="th">
                  <div>{column.render('Header')}</div>
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                  {/* Use column.getResizerProps to hook up the events correctly */}
                  {
                    (tableColumnResized = column.isResizing
                      ? true
                      : tableColumnResized)
                  }
                  <div
                    {...column.getResizerProps()}
                    className={`resizer ${
                      column.isResizing ? 'isResizing' : ''
                    }`}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>

        <DivTbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <div {...row.getRowProps()} key={i} className="tr">
                {row.cells.map((cell, i2) => {
                  return (
                    <div {...cell.getCellProps()} key={i2} className="td">
                      {cell.render('Cell')}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </DivTbody>
      </div>
      <div className="pagination" disabled={isLoading}>
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage || !pageCount}
        >
          {'>>'}
        </button>{' '}
        <span className="ms-2">
          Page{' '}
          <strong>
            {pageIndex + 1} of {isNil(recordCount) ? '?' : pageOptions.length}
          </strong>{' '}
        </span>
        <span className="ms-2">
          | Go to page:{' '}
          <input
            type="number"
            className="ps-2"
            defaultValue={pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              gotoPageDebounced(page)
            }}
            style={{ width: '100px' }}
          />
        </span>{' '}
        <div className="mx-2">Show</div>
        <select
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {PAGE_SIZES.map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
        <div className="ms-2">records per page</div>
        <button
          className="ms-2"
          onClick={() => {
            tableColumnResized = false
            resetResizing()
          }}
          disabled={isLoading || !tableColumnResized}
        >
          Reset Column Sizing
        </button>
        <button
          className="ms-2"
          onClick={_clearFilters}
          disabled={
            isLoading ||
            (all(isEmpty)(Object.entries(filters)) && isEmpty(text))
          }
        >
          Clear Filters
        </button>
      </div>
    </div>
  )
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
}

const DivTbody = styled.div`
  margin-top: 50px;
  width: 100%;
  :last-child {
    .td {
      border-bottom: 1px solid black;
    }
  }
`
const DonationsPage = () => {
  const [donations, setDonations] = useState([])
  const [pageCount, setPageCount] = useState(null)
  const [recordCount, setRecordCount] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [filters, setFilters] = useState({})
  const [filtersChanged, setFiltersChanged] = useState(true)
  const [text, setText] = useState('')
  const [sourceOpts, setSourceOpts] = useState({})
  const [donationAmountRange, setDonationAmountRange] = useState({})
  const [donationDateRange, setDonationDateRange] = useState({})

  const setFilterValues = obj => {
    setFilters(obj)
    doGoToPage(0)
    setFiltersChanged(true)
  }

  const setSearchValue = text => {
    setText(text)
    doGoToPage(0)
    setFiltersChanged(true)
  }

  filterHelper.setFilterValuesSetterFunction(setFilterValues)
  filterHelper.setSearchValueSetterFunction(setSearchValue)
  const fetchIdRef = React.useRef(0)
  const organizationId = useSelector(getUserOrganizationId)

  useEffect(() => {
    getDonationsDataRanges().then(ranges => {
      setSourceOpts(ranges.sources)
      setDonationAmountRange(ranges.amounts)
      setDonationDateRange(ranges.dates)
    })
  }, [])

  const clearFilters = () => {
    //clear current filters
    setFilters({})
    setText('')
    //clear UI component filters
    filterHelper.clearFilters()
    setFiltersChanged(true)
  }

  const possibleColumns = {
    donation_date: 0,
    donation_source_id: 1,
    donation_total: 2,
    donor: 3,
  }

  const columnWidths = {
    donation_date: 320,
    donation_total: 330,
    donor: 300,
  }

  const getHeaderLabel = field => {
    let label = null
    switch (field) {
      case 'donation_date':
        label = 'Donation Date'
        break
      case 'donation_source_id':
        label = 'Donation Source'
        break
      case 'donation_total':
        label = 'Amount'
        break
      case 'donor':
        label = 'Name'
        break
    }
    return label || field
  }

  const CustomCell = key => {
    const func = ({ value }) => {
      return formatCell(value, key)
    }
    return func
  }

  const columns = React.useMemo(() => {
    let cols = Object.keys(possibleColumns).map(key => {
      let col = {
        Header: getHeaderLabel(key),
        id: key,
        accessor: key,
        Cell: CustomCell(key),
      }
      switch (key) {
        case 'donation_source_id':
          col.Filter = SelectColumnFilter(sourceOpts, 'source')
          col.filter = 'includes'
          break
        case 'donation_total':
          col.Filter = NumberRangeColumnFilter(
            'donationAmount',
            donationAmountRange
          )
          col.filter = 'between'
          break
        case 'donor':
          col.Filter = DonorColumnFilter
          break
        case 'donation_date':
          col.Filter = DateRangeColumnFilter('donationDate', donationDateRange)
          col.filter = 'datebetween'
          break
        default:
        //pass
      }
      if (key in columnWidths) {
        col.width = columnWidths[key]
      }
      return col
    })
    return cols.sort((a, b) => possibleColumns[a.id] - possibleColumns[b.id])
  }, [sourceOpts])

  const fetchData = useCallback(
    ({ pageSize, pageIndex, filters }) => {
      const fetchId = ++fetchIdRef.current
      setIsLoading(true)
      if (fetchId === fetchIdRef.current) {
        const offset = pageSize * pageIndex
        const nonZeroFilter = {
          donation_total: { $ne: 0 },
          ...filters,
        }
        filterDonationsData(
          organizationId,
          offset,
          pageSize,
          nonZeroFilter,
          text
        )
          .then(donations => {
            setDonations(donations || [])
          })
          .finally(() => {
            setIsLoading(false)
          })

        if (filtersChanged) {
          filterDonationsDataCount(organizationId, nonZeroFilter, text)
            .then(count => {
              setRecordCount(count)
              setPageCount(Math.ceil(count / pageSize))
            })
            .finally(() => {
              setIsLoading(false)
            })

          setFiltersChanged(false)
        }
      }
    },
    [text, filtersChanged]
  )

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      dateBetween: dateBetweenFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const defaultColumn = useMemo(
    () => ({
      minWidth: 30,
      width: 220,
      maxWidth: 400,
      Filter: () => {},
    }),
    []
  )

  return (
    /* This report  was originally named Donation Report, and a new view
    named Donation report was created. Identifiers in the legacy code
    were not altered to relfect the name change.*/
    <Wrapper>
      <Container fluid className="w-100">
        <h1>Donations Report</h1>
        <Styles>
          <Table
            columns={columns}
            data={donations.map(row => formatRow(row))}
            recordCount={recordCount}
            filterTypes={filterTypes}
            defaultColumn={defaultColumn}
            fetchData={fetchData}
            isLoading={isLoading}
            pageCount={pageCount}
            filters={filters}
            text={text}
            clearFilters={clearFilters}
          />
        </Styles>
      </Container>
    </Wrapper>
  )
}

export default DonationsPage
