import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import PropTypes from 'prop-types'
import {
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
  Tab,
  Table,
  Tabs,
} from 'react-bootstrap'
import readXlsxFile from 'read-excel-file'

import FtpUpload from './FtpUpload'
import { parseCellToString } from '../../utils/stringsOperations'

const UploadFileModal = ({ show, handleClose }) => {
  const [data, setData] = useState(null)

  const handleFileChange = event => {
    const file = event.target.files[0]

    // Read the Excel file
    readXlsxFile(file, { dateFormat: 'mm/dd/yyyy' }).then(rows => {
      setData(rows)
    })
  }

  const handleOnClose = () => {
    handleClose()
    setData(null)
  }

  return (
    <Modal size="lg" show={show} onHide={handleOnClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload your contacts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs defaultActiveKey="fileUpload" className="mb-3">
          <Tab eventKey="fileUpload" title="File Upload">
            <Container>
              <Row>
                <Col>
                  <FormGroup controlId="formFile" className="mb-3">
                    <FormLabel>Add your file</FormLabel>
                    <FormControl
                      type="file"
                      className="p-4"
                      onChange={handleFileChange}
                      accept=".xls,.xlsx,.csv"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {data && (
                    <Table responsive>
                      <tbody>
                        {data.map((row, i) => (
                          <tr key={i}>
                            {row.map((cell, j) => (
                              <td key={j}>{parseCellToString(cell)}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </Container>
          </Tab>
          <Tab eventKey="ftp" title="FTP">
            <FtpUpload />
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleOnClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleOnClose}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

UploadFileModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default UploadFileModal
