import styled from 'styled-components'

const CardBorder = styled.div`
  min-height: 100%;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
  border: 1px solid #dce1e6;
`

export default CardBorder
