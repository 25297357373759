import { useEffect, useState } from 'react'
import ApiOrg from '../../api/organization'
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
  Col,
  ModalBody,
} from 'react-bootstrap'
import CreatableSelect from 'react-select/creatable'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { path, propEq, pluck, either } from 'ramda'
import usStates from '../../assets/datasets/us-states.json'
import Select from 'react-select'
import TagApi from '../../api/tag'
import { useSelector } from 'react-redux'
import { getUserRole, getUserOrganizationId } from '../../utils/state/selectors'
import ApiContacts from '../../api/contact'
import { RedSup } from '../ContactsPage/CreateContactModal'
import { CONTACT_LEVELS } from '../../api/enums'
import { isBundler } from '../../utils/state/roleTests'
import { formatPhoneNumber } from '../../utils/stringsOperations'

const StyledModalBody = styled(ModalBody)`
  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #00bdb2;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #0c243c;
  }
`

const findOption = (value, options) =>
  options.find(either(propEq('value', value), propEq('label', value)))

const DEFAULT_LEVEL_OPTIONS = [
  { value: CONTACT_LEVELS.CONTACT, label: CONTACT_LEVELS.CONTACT },
]

const EditContactModal = ({
  show,
  handleClose,
  contact,
  handleUpdate,
  disabledEmailInput = true,
  disabledOptionalInputs = true,
  setOrgContact = false,
}) => {
  const [contactModel, setContactModel] = useState({ ...contact })
  const [error, setError] = useState(null)
  const [validation, setValidation] = useState(false)
  const [created, setCreated] = useState(false)
  const [levelOptions, setLevelOptions] = useState(DEFAULT_LEVEL_OPTIONS)
  const [tags, setTags] = useState([])
  const userRole = useSelector(getUserRole)
  const userOrgId = useSelector(getUserOrganizationId)
  const [invalidFields, setInvalidFields] = useState([])

  useEffect(() => {
    if (show) {
      setContactModel({ ...contact })
      setError('')
      setCreated(false)
    }
  }, [show])

  useEffect(() => {
    ApiOrg.getBundlerLevelNames(userOrgId).then(levels => {
      setLevelOptions(data => {
        levels.forEach(level => {
          data = data.filter(entry => entry.label !== level)
          data.push({ label: level, value: level })
        })
        return data
      })
    })
  }, [])

  useEffect(() => {
    if (contact) {
      setContactModel({ ...contact })
    }
  }, [contact])

  useEffect(() => {
    fetchTags()
  }, [])

  const stateOptions = [
    { value: '', label: '' },
    ...usStates.map(({ name, state }) => ({
      value: state,
      label: name,
    })),
  ]

  const fetchTags = () =>
    TagApi.getTags(userOrgId).then(tags => {
      setTags(tags)
    })

  const createTag = tag =>
    TagApi.createTag(tag, userOrgId)
      .then(() => {
        setContactModel(prevState => {
          if (prevState.tags) {
            return {
              ...prevState,
              tags: [...prevState.tags, tag],
            }
          } else {
            return {
              ...prevState,
              tags: [tag],
            }
          }
        })
        fetchTags()
      })
      .catch(error => {
        setError('Error creating new tag ', error)
      })

  const selectStyles = {
    input: provided => ({
      ...provided,
      border: 'none',
      '> input[type=text]:focus': { outline: 'none !important' },
    }),
  }

  useEffect(() => {
    const updates = getUpdatesForm()
    setValidation(
      updates && Object.keys(updates).length !== 0 && validateRequiredFields()
    )
  }, [contactModel])

  const changeHandler = e => {
    setError('')
    validateRequiredFields()
    const fieldName = path(['target', 'name'], e)
    let value = path(['target', 'value'], e)
    value = fieldName === 'zipCode' ? value.replace(/[^0-9]/g, '') : value
    setContactModel(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const selectTagsHandler = e => {
    const value = pluck('value', e)
    setContactModel(prevState => ({
      ...prevState,
      tags: value,
    }))
  }

  const changeHandlerLetters = e => {
    setError('')
    validateRequiredFields()
    const fieldName = path(['target', 'name'], e)
    let value = path(['target', 'value'], e)
    value =
      fieldName === 'assistant_name'
        ? value.replace(/[^a-zA-Z\s]+/g, '')
        : value.replace(/[^a-zA-Z]+/g, '')
    setContactModel(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const changeHandlerPhone = e => {
    setError('')
    validateRequiredFields()
    const fieldName = path(['target', 'name'], e)
    let value = path(['target', 'value'], e)
    let formattedValue = `+1${value.replace(/[^\d]/g, '')}`
    setContactModel(prevState => ({
      ...prevState,
      [fieldName]: formattedValue,
    }))
  }

  const selectChangeHandler = fieldName => e => {
    validateRequiredFields()
    let value = path(['value'], e)
    if (fieldName === 'state_abbr') {
      value = findOption(value, stateOptions)?.value
    }
    setContactModel(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const handleFileChange = event => {
    validateRequiredFields()
    const file = event.target.files[0]
    setContactModel(prevState => ({
      ...prevState,
      picture: file,
    }))
  }

  const isValidEmail = email => {
    return /\S+@\S+\.\S+/.test(email)
  }

  const validateValueFields = () => {
    if (
      (contactModel?.secondaryEmail &&
        !isValidEmail(contactModel?.secondaryEmail)) ||
      (contactModel?.aEmailA && !isValidEmail(contactModel?.aEmailA)) ||
      (contactModel?.aEmailB && !isValidEmail(contactModel?.aEmailB))
    ) {
      setError('Please enter a valid email address.')
      return false
    } else if (
      contactModel?.linkedinURL &&
      contactModel?.linkedinURL !== '' &&
      !contactModel?.linkedinURL?.startsWith('https://www.linkedin.com/')
    ) {
      setError('Please provide a valid LinkedIn profile URL.')
      return false
    }
    return true
  }

  const validateRequiredFields = () => {
    const _invalidFields = []
    if (contactModel?.first_name === '') {
      _invalidFields.push('Name')
    }
    if (contactModel?.last_name === '') {
      _invalidFields.push('Last Name')
    }
    if (!isValidEmail(contactModel?.email)) {
      _invalidFields.push('Email')
    }
    setInvalidFields(_invalidFields)
    return !_invalidFields.length
  }

  const getUpdatesForm = () => {
    let result = {}
    Object.keys(contactModel).map(key => {
      if (contactModel[key] !== contact[key]) {
        result[key] = contactModel[key]
      }
    })
    return result
  }

  const handleOnSubmit = () => {
    const updates = getUpdatesForm()
    if (
      validateRequiredFields() &&
      updates &&
      Object.keys(updates).length !== 0 &&
      validateValueFields()
    ) {
      if (contact.hidden && setOrgContact) {
        updates['hidden'] = false
      }
      const body = {
        contact: { ...updates },
      }
      ApiContacts.updateContact({
        contact_person_id: contact.contact_person_id,
        body: body,
      })
        .then(data => {
          if (data?.acknowledged) {
            setCreated(true)
            handleUpdate()
          } else {
            setError('Error creating the contact')
          }
        })
        .catch(error => {
          setError('Error creating the contact ', error)
        })
    }
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg" scrollable={true}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Contact</Modal.Title>
      </Modal.Header>
      <StyledModalBody>
        {!created ? (
          <>
            <Form>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>
                      Name <RedSup>*</RedSup>
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="John"
                      autoComplete="off"
                      name="first_name"
                      value={contactModel?.first_name}
                      onChange={changeHandlerLetters}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>
                      Last Name <RedSup>*</RedSup>
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Doe"
                      autoComplete="off"
                      name="last_name"
                      value={contactModel?.last_name}
                      onChange={changeHandlerLetters}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Prefix</FormLabel>
                    <FormControl
                      placeholder="Sr"
                      type="text"
                      autoComplete="off"
                      name="prefix_name"
                      value={contactModel?.prefix_name}
                      disabled={disabledOptionalInputs}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Birthday</FormLabel>
                    <FormControl
                      type="date"
                      autoComplete="off"
                      name="birth_date"
                      min="1900-01-01"
                      max="2999-12-31"
                      value={contactModel?.birth_date}
                      disabled={disabledOptionalInputs}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>
                      Email<RedSup>*</RedSup>
                    </FormLabel>
                    <FormControl
                      type="email"
                      placeholder="johndoe@email.com"
                      autoComplete="off"
                      name="email"
                      value={contactModel?.email}
                      onChange={changeHandler}
                      disabled={disabledEmailInput && contact.email}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Secondary Email</FormLabel>
                    <FormControl
                      type="email"
                      placeholder="johndoe@email.com"
                      autoComplete="off"
                      name="secondary_email"
                      value={contactModel?.secondary_email}
                      disabled={disabledOptionalInputs}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Cell Phone Number</FormLabel>
                    <FormControl
                      type="tel"
                      placeholder="(000) - 000 0000"
                      autoComplete="off"
                      name="cell_phone_number"
                      value={formatPhoneNumber(contactModel?.cell_phone_number)}
                      onChange={changeHandlerPhone}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Home Phone Number</FormLabel>
                    <FormControl
                      type="tel"
                      placeholder="(000) - 000 0000"
                      autoComplete="off"
                      name="landline_phone_number"
                      value={formatPhoneNumber(
                        contactModel?.landline_phone_number
                      )}
                      disabled={disabledOptionalInputs}
                      onChange={changeHandlerPhone}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Employer</FormLabel>
                    <FormControl
                      placeholder="Benjamin"
                      type="text"
                      autoComplete="off"
                      name="employer"
                      value={contactModel?.employer}
                      disabled={disabledOptionalInputs}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Position</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Position"
                      autoComplete="off"
                      name="title"
                      value={contactModel?.title}
                      disabled={disabledOptionalInputs}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel htmlFor="tags">Tags</FormLabel>
                    <CreatableSelect
                      name="tags"
                      closeMenuOnSelect
                      isMulti
                      onChange={selectTagsHandler}
                      options={tags}
                      onCreateOption={createTag}
                      openMenuOnFocus
                      placeholder="Select Tags"
                      styles={selectStyles}
                      value={
                        contactModel.tags
                          ? contactModel.tags.map(t => ({
                              label: t,
                              value: t,
                            }))
                          : null
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>LinkedIn Profile</FormLabel>
                    <FormControl
                      max="5"
                      type="text"
                      autoComplete="off"
                      name="linkedin_url"
                      placeholder="https://www.linkedin.com/in/"
                      value={contactModel?.linkedin_url}
                      disabled={disabledOptionalInputs}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>City</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="City"
                      autoComplete="off"
                      name="city"
                      disabled={disabledOptionalInputs}
                      value={contactModel?.city}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>State</FormLabel>
                    <Select
                      id="state_abbr"
                      value={findOption(contactModel?.state_abbr, stateOptions)}
                      options={stateOptions}
                      placeholder="Select state"
                      onChange={selectChangeHandler('state_abbr')}
                      styles={selectStyles}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Address</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="123 Main St"
                      autoComplete="off"
                      name="address_1"
                      value={contactModel?.address_1}
                      disabled={disabledOptionalInputs}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Zip Code</FormLabel>
                    <FormControl
                      maxLength={5}
                      type="text"
                      placeholder="#####"
                      autoComplete="off"
                      name="postal_code"
                      disabled={disabledOptionalInputs}
                      value={contactModel?.postal_code}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {!isBundler(userRole) && (
                <Row>
                  <Col>
                    <FormGroup className="mb-3">
                      <FormLabel>Level</FormLabel>
                      <Select
                        id="level"
                        defaultValue={{
                          label: contactModel?.level,
                          value: contactModel?.level,
                        }}
                        options={levelOptions}
                        placeholder="Select Level"
                        onChange={selectChangeHandler('level')}
                        styles={selectStyles}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>About</FormLabel>
                    <FormControl
                      as="textarea"
                      rows={5}
                      placeholder="Bio"
                      autoComplete="off"
                      name="about_this_guy"
                      style={{ resize: 'none' }}
                      value={contactModel?.about_this_guy}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Notes</FormLabel>
                    <FormControl
                      as="textarea"
                      rows={5}
                      placeholder="Notes"
                      autoComplete="off"
                      name="notes"
                      style={{ resize: 'none' }}
                      value={contactModel?.notes}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup controlId="formFile" className="mb-3">
                    <FormLabel>Invite Alias</FormLabel>
                    <FormControl
                      placeholder="Invitation name"
                      type="text"
                      autoComplete="off"
                      name="invite_alias"
                      value={contactModel?.invite_alias}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup controlId="formFile" className="mb-3">
                    <FormLabel>Upload a Profile Picture (Optional)</FormLabel>
                    <FormControl
                      type="file"
                      onChange={handleFileChange}
                      accept=".jpg, .jpeg, .png, .svg"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Assistant&#39;s Full Name</FormLabel>
                    <FormControl
                      type="text"
                      placeholder="John"
                      autoComplete="off"
                      name="assistant_name"
                      value={contactModel?.assistant_name}
                      onChange={changeHandlerLetters}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Assistant&#39;s Email</FormLabel>
                    <FormControl
                      type="email"
                      placeholder="johndoe@email.com"
                      autoComplete="off"
                      name="assistant_email"
                      value={contactModel?.assistant_email}
                      onChange={changeHandler}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Assistant&#39;s Cell Phone Number</FormLabel>
                    <FormControl
                      type="tel"
                      placeholder="(000) - 000 0000"
                      autoComplete="off"
                      name="assistant_cell_phone_number"
                      value={formatPhoneNumber(
                        contactModel?.assistant_cell_phone_number
                      )}
                      onChange={changeHandlerPhone}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <FormLabel>Assistant&#39;s Work Phone Number</FormLabel>
                    <FormControl
                      type="tel"
                      placeholder="(000) - 000 0000"
                      autoComplete="off"
                      name="assistant_work_phone_number"
                      value={formatPhoneNumber(
                        contactModel?.assistant_work_phone_number
                      )}
                      onChange={changeHandlerPhone}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            {error}
            {invalidFields.length !== 0 &&
              `Please enter a value for ${invalidFields.join(', ')}`}
          </>
        ) : (
          <span>{`${contactModel?.first_name} ${contactModel?.last_name}'s contact has been updated`}</span>
        )}
      </StyledModalBody>
      <Modal.Footer>
        {!created && (
          <>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleOnSubmit}
              disabled={!validation}
            >
              Save Changes
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}

EditContactModal.propTypes = {
  disabledEmailInput: PropTypes.bool,
  disabledOptionalInputs: PropTypes.bool,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  contact: PropTypes.object,
  handleUpdate: PropTypes.func,
  setOrgContact: PropTypes.bool,
}

export default EditContactModal
