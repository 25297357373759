import { useEffect, useRef, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getThreadMessages, updateThread } from '../../api/nylas/mailbox'
import ReactHtmlParser from 'html-react-parser'
import { DATEFORMAT, timestampToDateString } from '../../utils/timestamp'
import { getUserToken } from '../../utils/dataModels/user'
import BackButton from './BackButton'
import ReplyForwardButton from './ReplyForwardButton'
import styled from 'styled-components'
import { BsReply, BsReplyAll, BsArrowReturnRight } from 'react-icons/bs'
import useDimensionScreen from '../../hooks/useDimensionScreen'
import MailReplyForwardCompose from './MailReplyForwardCompose/MailReplyForwardCompose'
import MailReplyDropdown from './MailReplyForwardDropdown/MailReplyDropdown'

const StyledButtonsNav = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.direction || 'row'};
  justify-content: space-between;
  gap: 1rem;
`

const MailBoxMessagePage = () => {
  const providerAccount = getUserToken()

  const [messageList, setMessageList] = useState([])
  const lastMessage = messageList.length > 0 ? messageList.reverse()[0] : null

  const [subject, setSubject] = useState([])

  const [loading, setLoading] = useState(true)

  const params = useParams()
  const location = useLocation()

  const [dimension] = useDimensionScreen()

  const [update, setUpdate] = useState(0)

  const isOriented = dimension.width > 580
  const isLess = dimension.width < 450

  const [replyForward, setReplyForward] = useState('')

  const replyRef = useRef(null)

  useEffect(() => {
    if (replyRef != null) {
      replyRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [replyForward])

  const updateReplyForward = value => {
    if (replyForward === value) {
      setReplyForward('')
    } else {
      setReplyForward(value)
    }
  }

  useEffect(() => {
    updateThread(params.id, false, providerAccount?.token)
      .then(data => data)
      .catch(error => console.log(error))
      .finally(() => {
        setSubject(location.state.subject)
        getThreadMessages(location.state.messageIds, providerAccount?.token)
          .then(response => {
            setMessageList(response.data || [])
          })
          .catch(error => console.log(error))
          .finally(() => setLoading(false))
      })
  }, [update])

  const reloadPage = () => {
    setLoading(true)
    setUpdate(update + 1)
    setReplyForward('')
  }

  const extractFrom = fromArray => {
    const [first] = fromArray
    return first ? (
      <>
        <strong>{first.name}</strong>{' '}
        <small className="text-muted">{`<${first.email}>`}</small>
      </>
    ) : (
      <></>
    )
  }

  return (
    <>
      <div className="container">
        {!loading && (
          <StyledButtonsNav>
            <BackButton />
            {isOriented ? (
              <StyledContainer>
                <ReplyForwardButton
                  title={'Reply'}
                  icon={BsReply}
                  updateReplyForward={() => updateReplyForward('Reply')}
                  isActive={replyForward === 'Reply' ? true : false}
                />
                <ReplyForwardButton
                  title={'Reply All'}
                  icon={BsReplyAll}
                  updateReplyForward={() => updateReplyForward('Reply All')}
                  isActive={replyForward === 'Reply All' ? true : false}
                />
                <ReplyForwardButton
                  title={'Forward'}
                  icon={BsArrowReturnRight}
                  updateReplyForward={() => updateReplyForward('Forward')}
                  isActive={replyForward === 'Forward' ? true : false}
                />
              </StyledContainer>
            ) : (
              <StyledContainer direction={isLess ? 'column' : 'row'}>
                <MailReplyDropdown
                  replyForward={replyForward}
                  updateReplyForward={updateReplyForward}
                />
                <ReplyForwardButton
                  title={'Forward'}
                  icon={BsArrowReturnRight}
                  updateReplyForward={() => updateReplyForward('Forward')}
                  isActive={replyForward === 'Forward' ? true : false}
                />
              </StyledContainer>
            )}
          </StyledButtonsNav>
        )}
        {messageList.reverse().map(message => (
          <div className="card" key={message.id}>
            <div className="card-header">{subject}</div>
            <div className="card-body">
              <div className="card-title w-100">
                {extractFrom(message.from)}
                <small className="position-absolute end-0 mx-2">
                  {timestampToDateString(
                    message.date,
                    DATEFORMAT.DATETIME4D3MHA
                  )}
                </small>
              </div>
              {/* <div dangerouslySetInnerHTML={{ __html: message.body }}></div> */}

              {ReactHtmlParser(message.body)}
            </div>
          </div>
        ))}

        {loading && <span>Loading...</span>}
      </div>
      {replyForward != '' && (
        <div ref={replyRef}>
          {replyForward === 'Reply' && (
            <MailReplyForwardCompose
              title={replyForward}
              message={lastMessage}
              reloadPage={reloadPage}
            />
          )}
          {replyForward === 'Reply All' && (
            <MailReplyForwardCompose
              title={replyForward}
              message={lastMessage}
              reloadPage={reloadPage}
            />
          )}

          {replyForward === 'Forward' && (
            <MailReplyForwardCompose
              title={replyForward}
              message={lastMessage}
              reloadPage={reloadPage}
            />
          )}
        </div>
      )}
    </>
  )
}

export default MailBoxMessagePage
