import styled from 'styled-components'

export const BTableStyledTh = styled.th`
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`
