import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserOrganizationId } from '../utils/state/selectors'
import ApiOrg from '../api/organization'
import { IOrganization } from '../api/interfaces'

interface ZoomCredentials {
  zoomAccountId?: string
  zoomClientId?: string
  zoomClientSecret?: string
  zoomSDKClientId?: string
  zoomSDKClientSecret?: string
}
export const useZoomCredentials = () => {
  const organizationId = useSelector(getUserOrganizationId)
  const [hasCredentials, setHasCredentials] = useState(false)
  const [zoomCredentials, setZoomCredentials] = useState<ZoomCredentials>({
    zoomAccountId: undefined,
    zoomClientId: undefined,
    zoomClientSecret: undefined,
    zoomSDKClientId: undefined,
    zoomSDKClientSecret: undefined,
  })

  useEffect(() => {
    if (organizationId) {
      ApiOrg.getOrganization(organizationId, true, true).then(
        (organization: IOrganization) => {
          const zoomAccountId = organization.zoomAccountId
          const zoomClientId = organization.zoomClientId
          const zoomClientSecret = organization.zoomClientSecret
          const zoomSDKClientId = organization.zoomSDKClientId
          const zoomSDKClientSecret = organization.zoomSDKClientSecret

          setZoomCredentials({
            zoomAccountId,
            zoomClientId,
            zoomClientSecret,
            zoomSDKClientId,
            zoomSDKClientSecret,
          })
          const noZoomCredentials =
            !zoomAccountId ||
            !zoomClientId ||
            !zoomClientSecret ||
            !zoomSDKClientId ||
            !zoomSDKClientSecret
          setHasCredentials(!noZoomCredentials)
        }
      )
    }
  }, [organizationId])

  return { hasCredentials, zoomCredentials }
}
