import { usePageHeadings } from '../../../hooks/usePageHeadings'
import { mainBreadcrumbs } from '../../../config/mainBreadcrumbs'
import {
  Button,
  ButtonGroup,
  Col,
  Container,
  Form,
  FormControl,
  FormGroup,
  Row,
} from 'react-bootstrap'
import readXlsxFile from 'read-excel-file'
import { useSelector } from 'react-redux'
import { getUser, getUserOrganizationId } from '../../../utils/state/selectors'
import { Wrapper } from './styles'
import Papa from 'papaparse'
import { useEffect, useRef, useState } from 'react'
import { Hint } from 'react-bootstrap-typeahead'
import FieldMappingDropdown from '../FieldMapDropdown'
import GeneralModal from '../../GeneralModal/GeneralModal'
import fieldMapOptions from '../fieldMapOptions'
import { levenshteinEditDistance } from 'levenshtein-edit-distance'
import GeneralConfirmationModal from '../../GeneralConfirmationModal/GeneralConfirmationModal'
import ApiContacts from '../../../api/contact'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const ContactUploadFilePage = () => {
  const navigate = useNavigate()
  const user = useSelector(getUser)
  const organizationId = useSelector(getUserOrganizationId)
  const fileRef = useRef()
  const [exampleData, setExampleData] = useState({})
  const [enableUploadButton, setEnableUploadButton] = useState(false)
  const [fieldMap, setFieldMap] = useState({})
  const [file, setFile] = useState(null)
  const [fileData, setFileData] = useState(null)
  const [fileExtension, setFileExtension] = useState(null)
  const [hasHeaderRow, setHasHeaderRow] = useState(true)
  const [message, setMessage] = useState(null)
  const [numberOfContactsImported, setNumberOfContactsImported] = useState(null)
  const [showAutoFillModal, setShowAutoFillModal] = useState(null)
  const [showImportModal, setShowImportModal] = useState(null)

  const autoFill = () => {
    const newFieldMap = { ...fieldMap }
    const optionTaken = Object.values(fieldMap)

    Object.keys(fileData[0]).forEach(fieldKey => {
      if (!(fieldKey in newFieldMap)) {
        const fieldMapOptionDistance = Object.keys(fieldMapOptions)
          .filter(a => !optionTaken.includes(a))
          .map(optionKey => {
            return {
              field: optionKey,
              distance: Math.min(
                ...fieldMapOptions[optionKey].alias
                  .map(
                    alias =>
                      levenshteinEditDistance(fieldKey, alias, true) /
                      Math.min(fieldKey.length, alias.length)
                  )
                  .reduce((p, c) => [...p, c], [])
              ),
            }
          })

        fieldMapOptionDistance.sort((a, b) => a.distance - b.distance)

        if (fieldMapOptionDistance[0].distance <= 0.5) {
          newFieldMap[fieldKey] = fieldMapOptionDistance[0].field
          optionTaken.push(fieldMapOptionDistance[0].field)
        }
      }
    })

    setFieldMap(newFieldMap)
    setShowAutoFillModal(false)
  }

  const onFileSelect = e => {
    setFile(e?.target?.files[0])
    setFileExtension(
      e?.target?.files?.[0]?.name?.split('.')?.pop()?.toLowerCase()
    )
  }

  const onUpload = () => {
    setShowImportModal(false)

    if (validateRequiredFields(true)) {
      try {
        ApiContacts.createContacts({
          contacts: fileData.map(data => {
            return {
              ...Object.keys(fieldMap)
                .map(key => {
                  const mappedKey = fieldMap[key]
                  return {
                    [mappedKey]:
                      key === 'birth_date'
                        ? moment(data[key]).format('YYYY-MM-DD')
                        : data[key] ??
                          (fieldMapOptions[mappedKey].isRequired ? 'N/A' : ''),
                  }
                })
                .reduce((p, c) => {
                  return { ...p, ...c }
                }, {}),
              ...{ organizationId, user_id: user._id, hidden: true },
            }
          }),
        })
          .then(response => {
            setExampleData({})
            setFieldMap({})
            setFile(null)
            setFileData(null)
            setFileExtension(null)
            setHasHeaderRow(true)
            setNumberOfContactsImported(parseInt(response?.insertedCount, 10))
            fileRef.current.value = null
          })
          .catch(error => {
            setErrorMessage(error)
          })
      } catch (error) {
        setErrorMessage(error)
      }
    }
  }

  const setErrorMessage = error =>
    setMessage({
      isError: true,
      title: 'Import',
      message: (
        <>
          <div className="mb-3">
            Import encountered an error. Please contact us with the follwing
            information:
          </div>
          <Form.Control
            as="textarea"
            readOnly
            rows={5}
            value={JSON.stringify(error)}
          />
        </>
      ),
    })

  const updateFileData = newData => {
    if (newData?.[0])
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      setFileData(newData.map(({ _id, ...validData }) => validData))
  }

  const validateRequiredFields = (notifyUser = false) => {
    const fieldMapValues = Object.values(fieldMap)

    return (
      Object.keys(fieldMapOptions)
        .map(key => {
          if (
            fieldMapOptions[key].isRequired &&
            !fieldMapValues.includes(key)
          ) {
            if (notifyUser)
              setMessage({
                isError: true,
                title: 'Import',
                message: `${fieldMapOptions[key].label} must be mapped.`,
              })

            return key
          } else return null
        })
        .filter(a => a).length === 0
    )
  }

  usePageHeadings('Contacts - Upload File', [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.CONTACTS(false),
    mainBreadcrumbs.UPLOADFILE(true),
  ])

  useEffect(() => {
    if (file) {
      if (fileExtension === 'csv')
        Papa.parse(file, {
          header: hasHeaderRow,
          skipEmptyLines: true,
          complete: results => {
            if (hasHeaderRow) updateFileData(results.data)
            else {
              updateFileData(
                results.data.map(row =>
                  row
                    .map((field, index) => {
                      return {
                        [`column_${'0'.repeat(
                          3 - index.toString().length
                        )}${index}`]: field,
                      }
                    })
                    .reduce((p, c) => {
                      return { ...p, ...c }
                    }, {})
                )
              )
            }

            if (results?.errors?.[0]) {
              setMessage({
                isError: true,
                title: 'Import',
                message: (
                  <>
                    <div className="mb-3">
                      CSV may have loaded with errors. Contact data may be
                      corrupt.
                    </div>
                    <div className="mb-3">
                      <strong>Row:</strong>&nbsp;
                      {results.errors[0].row}
                    </div>
                    <div className="mb-3">
                      <strong>Code:</strong>&nbsp;
                      {results.errors[0].code}
                    </div>
                    <div className="mb-3">
                      <strong>Message:</strong>&nbsp;
                      {results.errors[0].message}
                    </div>
                  </>
                ),
              })
            }
          },
        })
      else if (fileExtension === 'xlsx')
        readXlsxFile(file, { dateFormat: 'mm/dd/yyyy' }).then(results => {
          const headerRow = hasHeaderRow
            ? results.shift()
            : '*'
                .repeat(results[0].length)
                .split('')
                .map(
                  (value, index) =>
                    `column_${'0'.repeat(3 - index.toString().length)}${index}`
                )

          updateFileData(
            results.map(row =>
              row
                .map((field, index) => {
                  return { [headerRow[index]]: field }
                })
                .reduce((p, c) => {
                  return { ...p, ...c }
                }, {})
            )
          )
        })
    }
  }, [file, fileExtension, hasHeaderRow])

  useEffect(() => {
    if (fileData) {
      const newExampleData = {}

      Object.keys(fileData[0]).map(key => {
        for (let c = 0; c < fileData.length; c++) {
          const isLast = c === fileData.length - 1

          if (
            fileData?.[c]?.[key] &&
            (fileData?.[c]?.[key]?.toString()?.replace(/[0 ]/, '') || isLast)
          )
            newExampleData[key] = [
              ...(newExampleData[key] ?? []),
              fileData[c][key],
            ]
        }

        newExampleData[key] = [...new Set(newExampleData[key] || [])]
          .slice(0, 10)
          .sort()
      })

      setExampleData(newExampleData)
    }
  }, [fileData])

  useEffect(() => {
    if (fileData) setEnableUploadButton(validateRequiredFields())
  }, [fileData, fieldMap])

  return (
    <Wrapper>
      <Container fluid>
        {message && (
          <GeneralModal {...message} show onHide={() => setMessage(null)} />
        )}

        <GeneralConfirmationModal
          show={showAutoFillModal}
          title="Import"
          message="This will attempt to auto-map fields not yet mapped. This action cannot be undone. Would you like to continue?"
          onCancel={() => setShowAutoFillModal(false)}
          onHide={() => setShowAutoFillModal(false)}
          onOK={() => autoFill()}
        />

        <GeneralConfirmationModal
          show={showImportModal}
          title="Import"
          message={`You are about to import ${fileData?.length} contact(s). Any unmapped fields will not be imported. This action cannot be undone. Would you like to continue?`}
          onCancel={() => setShowImportModal(false)}
          onHide={() => setShowImportModal(false)}
          onOK={() => onUpload()}
        />

        <GeneralConfirmationModal
          show={!!numberOfContactsImported}
          title="Import"
          message={
            numberOfContactsImported === 1
              ? `New contact was imported to Benjamin.`
              : `${numberOfContactsImported} new contacts were imported  to Benjamin.`
          }
          cancelLabel="Upload Another File"
          okLabel="Return to Contacts"
          onCancel={() => setNumberOfContactsImported(null)}
          onHide={() => setNumberOfContactsImported(null)}
          onOK={() => navigate('/contacts')}
        />

        <Row className="pb-4">
          <Col md={4}>
            <FormGroup controlId="formFile">
              <FormControl
                ref={fileRef}
                type="file"
                onChange={onFileSelect}
                accept=".xls,.xlsx,.csv"
              />
              <Hint>Supported extensions include: .csv, .xlsx</Hint>
            </FormGroup>
          </Col>
          {fileData && (
            <Col md={8}>
              <ButtonGroup>
                <Button
                  variant={hasHeaderRow ? 'primary' : 'light'}
                  onClick={() => setHasHeaderRow(true)}
                >
                  Has Header Row
                </Button>
                <Button
                  variant={!hasHeaderRow ? 'primary' : 'light'}
                  onClick={() => setHasHeaderRow(false)}
                >
                  Does Not Have Header Row
                </Button>
              </ButtonGroup>
              <Button
                className="float-end"
                onClick={() => setShowAutoFillModal(true)}
              >
                Auto Map
              </Button>
            </Col>
          )}
        </Row>

        {!!fileData && (
          <Row className="mb-3">
            <Col xs={12}>
              <div className="fs-1">Map Fields</div>
              <div className="fs-6">
                The following fields must be mapped to before continuing:{' '}
                {Object.keys(fieldMapOptions)
                  .filter(key => fieldMapOptions[key].isRequired)
                  .map(key => fieldMapOptions[key].label)
                  .sort()
                  .join(', ')}
              </div>
            </Col>
          </Row>
        )}

        {!!fileData && (
          <Row>
            {Object.keys(fileData?.[0])
              .sort((a, b) =>
                a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase())
              )
              .map(key => (
                <Col key={key} md={3} className="mb-3">
                  <FieldMappingDropdown
                    exampleData={exampleData}
                    field={key}
                    fieldMap={fieldMap}
                    setFieldMap={setFieldMap}
                  />
                </Col>
              ))}
          </Row>
        )}
        {!!fileData && (
          <Row className="mt-3">
            <Col md={11}></Col>
            <Col md={1}>
              <Button
                disabled={!enableUploadButton}
                className="w-100"
                onClick={() => setShowImportModal(true)}
              >
                Upload
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    </Wrapper>
  )
}

export default ContactUploadFilePage
