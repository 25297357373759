import React, { useState } from 'react'
import { Accordion, Stack } from 'react-bootstrap'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BsChevronDown, BsChevronRight, BsChevronUp } from 'react-icons/bs'

const CustomToggle = ({ children, eventKey, toggle }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => toggle())

  return <div onClick={decoratedOnClick}>{children}</div>
}

CustomToggle.propTypes = {
  children: PropTypes.node,
  eventKey: PropTypes.string,
  toggle: PropTypes.func,
}

const SpanText = styled.span`
  color: #00bdb2;
  font-family: DM Sans;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0em;
  text-align: left;
  width: 6rem;
`
const HR = styled.hr`
  color: #00bdb2;
  background-color: #00bdb2;
  height: 1;
  width: 100%;
`

const StyledStack = styled(Stack)`
  &:hover {
    cursor: pointer;
  }
`

const CustomAccordion = ({ showContentsOnTop = false, children }) => {
  const [open, setOpen] = useState(false)

  const childrenClasses = showContentsOnTop ? 'pb-3' : 'pt-3'

  const ChildrenAccordion = () => (
    <Accordion.Collapse eventKey="0" className={childrenClasses}>
      {children}
    </Accordion.Collapse>
  )

  return (
    <>
      <Accordion>
        {showContentsOnTop && <ChildrenAccordion />}

        <CustomToggle eventKey="0" toggle={() => setOpen(value => !value)}>
          {open ? (
            <StyledStack gap={2} direction="horizontal">
              {showContentsOnTop ? (
                <BsChevronUp size={13} color="#00bdb2" />
              ) : (
                <BsChevronRight size={13} color="#00bdb2" />
              )}
              <SpanText>Show less</SpanText>
              <HR />
            </StyledStack>
          ) : (
            <StyledStack gap={2} direction="horizontal">
              <BsChevronDown size={13} color="#00bdb2" />
              <SpanText> Show more</SpanText>
              <HR />
            </StyledStack>
          )}
        </CustomToggle>

        {!showContentsOnTop && <ChildrenAccordion />}
      </Accordion>
    </>
  )
}

CustomAccordion.propTypes = {
  children: PropTypes.node,
  showContentsOnTop: PropTypes.bool,
}

export default CustomAccordion
