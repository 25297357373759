import store from './store'

/* eslint-disable no-undef */
export default {
  BASE_ENDPOINT: () => store.getState()?.envJSON?.BASE_ENDPOINT,
  VERSION: () => store.getState()?.envJSON?.NULLSTONE_VERSION,

  ATTENDEES_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/attendees`,
  CONTACTS_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/contacts`,
  DISTRICT_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/district`,
  CONTACTS_WEALTH_INFO_ENDPOINT: () =>
    `${
      store?.getState()?.envJSON?.BASE_ENDPOINT
    }/api/benjamin/contactWealthInfo`,
  DONATIONS_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/donations`,
  DONATION_DATA_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/donationData`,
  QUEUE_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/queue`,
  EVENTS_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/events`,
  PARTICIPANTS_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/participants`,
  LOGIN_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/auth/login`,
  FEDERATED_USERS_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/auth/federatedSignin`,
  SESSION_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/auth/session`,
  TASKS_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/tasks`,
  TAGS_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/tags`,
  ORGANIZATIONS_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/organizations`,
  USERS_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/users`,
  SCHEDULER_ENDPOINT: () =>
    `${
      store?.getState()?.envJSON?.BASE_ENDPOINT
    }/api/allowed/scheduler/scheduledEvent`,

  CHAT_SOCKET_ENDPOINT: () => store.getState()?.envJSON?.CHAT_SOCKET_ENDPOINT,
  CHAT_MESSAGES_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/chat/messages`,
  CHAT_ROOM_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/chat/chatroom`,
  CHAT_USERS_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/chat/users`,
  CHAT_ROOM_GETBYID_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/chat/chatroom/chatid`,

  GOOGLE_CLIENT_ID: () => store.getState()?.envJSON?.GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET: () => store.getState()?.envJSON?.GOOGLE_CLIENT_SECRET,
  GOOGLE_SCOPES: [
    'openid',
    'https://www.googleapis.com/auth/gmail.labels',
    'https://www.googleapis.com/auth/userinfo.email',
    'https://www.googleapis.com/auth/userinfo.profile',
    'https://www.googleapis.com/auth/gmail.send',
    'https://www.googleapis.com/auth/calendar',
    'https://www.googleapis.com/auth/contacts.readonly',
    'https://www.googleapis.com/auth/gmail.readonly',
  ].join(' '),
  GOOGLE_REDIRECT_URI: () => store.getState()?.envJSON?.GOOGLE_REDIRECT_URI,
  GOOGLE_OAUTH_ACCESS_TOKEN_URL: 'https://www.googleapis.com/oauth2/v4/token',
  GOOGLE_OAUTH_TOKEN_VALIDATION_URL:
    'https://www.googleapis.com/oauth2/v3/tokeninfo',

  LINKEDIN_CLIENTID: () => store.getState()?.envJSON?.LINKEDIN_CLIENTID,
  LINKEDIN_USER_INFO_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/getLIContactProfile`,

  ZOOM_SDK_CLIENT_ID: () => store.getState()?.envJSON?.ZOOM_SDK_CLIENT_ID,
  ZOOM_MEETING_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/meeting`,
  ZOOM_SIGNCALL_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/signCall`,
  ZOOM_OAUTH_ENABLED: () => store?.getState()?.envJSON?.ZOOM_OAUTH_ENABLED,
  ZOOM_OAUTH_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/auth/zoomoauthcallback`,
  SECRET_PASS: () => store?.getState()?.envJSON?.SECRET_PASS,

  MAILER_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/sendEmail`,

  FTP_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/readFileFTP`,

  BUNDLERID_ENDPOINT: () =>
    `${store?.getState()?.envJSON?.BASE_ENDPOINT}/api/benjamin/bundlerIds`,

  CALENDAR_COLORS: {
    'Org Events': '#6EDFF6',
    'Personal Events': '#f8b88b',
  },

  EVENT_TYPE: {
    event: 'event',
    providerEvent: 'providerEvent',
  },

  PARTICIPANT_STATUS: {
    yes: 'yes',
    no: 'no',
    noreply: '"noreply"',
  },
  AZURE_CLIENT_ID: () => store.getState()?.envJSON?.AZURE_CLIENT_ID,
  AZURE_TENANT_ID: () => store.getState()?.envJSON?.AZURE_TENANT_ID,
  AZURE_CLIENT_SECRET: () => store.getState()?.envJSON?.AZURE_CLIENT_SECRET,
  AZURE_AUTHORITY: () => store.getState()?.envJSON?.AZURE_AUTHORITY,
  AZURE_REDIRECT_URI: () => store.getState()?.envJSON?.AZURE_REDIRECT_URI,
  AZURE_GRAPH_BASE_ENDPOINT: 'https://graph.microsoft.com/v1.0',
  AZURE_AUTHORIZE_ENDPOINT:
    'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
  AZURE_TOKEN_ENDPOINT:
    'https://login.microsoftonline.com/common/oauth2/v2.0/token',
  AZURE_SCOPES: [
    'User.Read',
    'openid',
    'profile',
    'offline_access',
    'email',
    'Calendars.Read.Shared',
    'Calendars.ReadWrite.Shared',
    'EAS.AccessAsUser.All',
    'EWS.AccessAsUser.All',
  ].join(' '),
  CS_URL:
    'https://forms.zohopublic.com/freedomringventurestudio/form/BenjaminSupport/formperma/72MCdCrfcjXAunwriA3fJ2uXU7d9Wh5UaZVQun8ZvIg',
  REGISTER_URL:
    'https://forms.zohopublic.com/freedomringventurestudio/form/BenjaminDemoRequest/formperma/iQ8TS4ziTfQUN-inYIKTlbdhLo6OigtdiPg9LZuMat8',
}
