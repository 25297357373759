import { Button, Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { BiCheckCircle, BiError } from 'react-icons/bi'

const GeneralModal = ({
  closeButton = true,
  isError,
  message,
  onHide,
  show,
  title,
}) => {
  return (
    show && (
      <Modal show onHide={onHide}>
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>
            {isError ? <BiError /> : <BiCheckCircle />} &nbsp;
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{message}</Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    )
  )
}

GeneralModal.propTypes = {
  closeButton: PropTypes.bool,
  isError: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onHide: PropTypes.func,
  show: PropTypes.bool,
  title: PropTypes.string,
}

GeneralModal.defaultProps = {
  closeButton: true,
}

export default GeneralModal
