import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'

import APIBundlerId from '../../api/bundlerIds'
import ApiContact from '../../api/contact'
import UserApi from '../../api/user'
import { useState, useEffect } from 'react'
import { FC } from 'react'
import { IContact } from '../../api/interfaces'
import { formatValue } from '../ui-elements/Money'
import { isEmpty, isNil } from 'ramda'

const isDefined = (x: string | null) => !(isNil(x) || isEmpty(x))

const Styledcontainer = styled.div`
  border-radius: 0.25rem;
  padding: 0.5rem;
  border-style: none;
  margin-bottom: 20px;
  border-color: #d9d9d9;
`

const DivTopLevelInfo = styled.div<{
  mt?: number
  size?: number
  isGrey?: boolean
  fw?: number
}>`
  font-family: DM Sans;
  letter-spacing: 0em;
  line-height: 1.4em;
  text-align: left;
  font-weight: ${props => (props.fw ? props.fw : 800)};
  margin: 0 auto;
  vertical-align: middle;
  font-size: ${props => (props.size ? props.size : 16)}px;
  margin-top: ${props => (props.mt ? props.mt : 0)}px;
  align-items: center;
  color: ${props => (props.isGrey ? `#79838d;` : `#3b4248;`)};
`
const LeftColDiv = styled.div`
  margin-left: 10px;
`

interface ContactDetailsProps {
  contact: IContact
}
const TopLevelInfo: FC<ContactDetailsProps> = ({ contact }) => {
  const [bundlerId, setBundlerId] = useState('')
  const [districtName, setDistrictName] = useState('')

  const getBundlerId = async (userEmail: string) => {
    try {
      const userResponse = await UserApi.findUser(userEmail)
      if (userResponse && userResponse._id) {
        const bundlerIdReponse = await APIBundlerId.getUserBundlerId(
          userResponse._id
        )
        setBundlerId(bundlerIdReponse.bundlerId.toString())
      }
    } catch (error) {
      console.log(error)
    }
  }

  const retrieveDistrictName = (contact: IContact) => {
    ApiContact.getDistrictName(
      contact.state_abbr,
      contact.us_congressional_district
    ).then((res: string) => setDistrictName(res))
  }

  useEffect(() => {
    getBundlerId(contact.email)
    retrieveDistrictName(contact)
  }, [contact])

  const prependState = (state: string, district: string) => {
    let s = state && district ? `${state}-` : ''
    s += district
    return s ? s : 'Unknown'
  }
  return (
    <Styledcontainer>
      <Row>
        <Col md={5}>
          <LeftColDiv>
            <DivTopLevelInfo fw={500}>Bundler ID:</DivTopLevelInfo>
          </LeftColDiv>
        </Col>
        <Col>
          <DivTopLevelInfo isGrey={!bundlerId}>
            {bundlerId || ''}
          </DivTopLevelInfo>
        </Col>
      </Row>
      {/*May be available in data import later */}
      {/* <Row>
        <Col>
          <DivTopLevelInfo mt={20}>Est. Net Worth</DivTopLevelInfo>
        </Col>
        <Col>
          <DivTopLevelInfo mt={20} isGrey={!contact?.estNetWorth}>
            {contact?.estimated_net_worth || 'Unknown'}
          </DivTopLevelInfo>
        </Col>
      </Row> */}
      <Row>
        <Col md={5}>
          <LeftColDiv>
            <DivTopLevelInfo fw={500}>Est. Capacity</DivTopLevelInfo>
          </LeftColDiv>
        </Col>
        <Col>
          <DivTopLevelInfo isGrey={!contact?.donation_capacity}>
            {contact?.donation_capacity
              ? formatValue(contact?.donation_capacity, 0, 0, true)
              : 'Unknown'}
          </DivTopLevelInfo>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <LeftColDiv>
            <DivTopLevelInfo fw={500}>Donation Range</DivTopLevelInfo>
          </LeftColDiv>
        </Col>
        <Col>
          <DivTopLevelInfo isGrey={!contact?.donation_capacity_bracket}>
            {contact?.donation_capacity_bracket || 'Unknown'}
          </DivTopLevelInfo>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <LeftColDiv>
            <DivTopLevelInfo fw={500} mt={20}>
              Modeled Party
            </DivTopLevelInfo>
          </LeftColDiv>
        </Col>
        <Col>
          <DivTopLevelInfo mt={20} isGrey={!contact?.modeled_political_party}>
            {contact?.modeled_political_party || 'Unknown'}
          </DivTopLevelInfo>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <LeftColDiv>
            <DivTopLevelInfo fw={500}>Modeled Spectrum</DivTopLevelInfo>
          </LeftColDiv>
        </Col>
        <Col>
          <DivTopLevelInfo
            isGrey={!contact?.modeled_political_spectrum_bracket}
          >
            {contact?.modeled_political_spectrum_bracket || 'Unknown'}
          </DivTopLevelInfo>
        </Col>
      </Row>
      {/*May be re-instated if legal compliance confirmed for specific use case*/}
      {/* <Row>
        <Col>
          <LeftColDiv>
            <DivTopLevelInfo>Voting Propensity</DivTopLevelInfo>
          </LeftColDiv>
        </Col>
        <Col>
          <DivTopLevelInfo isGrey={!contact?.voting_propensity}>
            {contact?.voting_propensity || 'Unknown'}
          </DivTopLevelInfo>
        </Col>
      </Row> */}
      <Row>
        <Col md={5}>
          <LeftColDiv>
            <DivTopLevelInfo fw={500} mt={20}>
              US House
            </DivTopLevelInfo>
          </LeftColDiv>
        </Col>
        <Col>
          <DivTopLevelInfo mt={20} isGrey={!contact?.us_congressional_district}>
            {isDefined(districtName)
              ? districtName
              : prependState(
                  contact?.state_abbr,
                  contact?.us_congressional_district || ''
                )}
          </DivTopLevelInfo>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <LeftColDiv>
            <DivTopLevelInfo fw={500}>State House</DivTopLevelInfo>
          </LeftColDiv>
        </Col>
        <Col>
          <DivTopLevelInfo isGrey={!contact?.state_house_district}>
            {prependState(
              contact?.state_abbr,
              contact?.state_house_district || ''
            )}
          </DivTopLevelInfo>
        </Col>
      </Row>
      <Row>
        <Col md={5}>
          <LeftColDiv>
            <DivTopLevelInfo fw={500}>State Senate</DivTopLevelInfo>
          </LeftColDiv>
        </Col>
        <Col>
          <DivTopLevelInfo isGrey={!contact?.state_senate_district}>
            {contact?.state_senate_district || ''}
          </DivTopLevelInfo>
        </Col>
      </Row>
    </Styledcontainer>
  )
}

export default TopLevelInfo
