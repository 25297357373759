import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import {
  Col,
  Container,
  Row,
  Stack,
  Toast,
  ToastContainer,
} from 'react-bootstrap'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ApiOrg from '../../api/organization'
import {
  getCurrentContactsPage,
  getCurrentContactsPageSize,
  getShowFilterModal,
  getUser,
  getUserOrganizationId,
} from '../../utils/state/selectors'
import styled from 'styled-components'
import { BsPlusLg, BsUpload } from 'react-icons/bs'
import ActionButton from '../ui-elements/ActionButton'
import UploadFileModal from './UploadFileModal'
import CreateContactModal from './CreateContactModal'
import SuggestContactModal from './SuggestContactModal'
import ModalFilterContactsJS, {
  initialQueryState,
} from '../MailBoxPage/ModalFilterContacts/ModalFilterContactsJS'
import DonationsApi from '../../api/donation'
import { filterDonationsDataByContactId } from '../../api/donations_data'
import ContactsApi from '../../api/contact'
import UsersApi from '../../api/user'
import MailboxCompose from '../MailBoxPage/MailboxCompose/MailboxCompose'
import { mandatoryFields } from '../MailBoxPage/MailboxCompose/config'
import { sendNylasEmail } from '../../api/nylas/mailbox'
import { path } from 'ramda'
import { getUserToken } from '../../utils/dataModels/user'
import { getUserFullName } from '../../utils/state/selectors'
import USStates from '../../assets/datasets/us-states.json'
import contactColumns from './ContactColumns'
import ContactsTable from './ContactsTable'
import {
  setContactPageSize,
  setCurrentContactPage,
  setContactPageShowFilter,
} from '../../redux/contacts/contacts'
import { omit, equals } from 'ramda'
import Spinner from 'react-bootstrap/Spinner'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import moment from 'moment'
import { contact_source_types } from '../../api/enums'

const initialStateComposeEmail = {
  to: [],
  cc: [],
  bcc: [],
  subject: '',
  body: '',
}

export const Wrapper = styled.div`
  padding: 1rem 1.3125rem;
  overflow-x: auto;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`
export const StyledTh = styled.th`
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`

const UploadDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #3b4248;
  cursor: pointer;
`

const INITIAL_PAGE_SIZE = 50

const ContactsPage = ({
  renderUploadFileModal = true,
  renderCreateContactModal = false,
  renderDonorEngineModal = false,
  renderPageHeadings = true,
  organizationId,
  organization,
}) => {
  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.CONTACTS(true),
  ]
  renderPageHeadings && usePageHeadings('Contacts', breadcrumbs)
  const dispatch = useDispatch()
  const [contacts, setContacts] = useState([])

  const providerAccount = getUserToken()
  const userFullName = getUserFullName()
  const user = useSelector(getUser)
  const currentPage = useSelector(getCurrentContactsPage)
  const pageSize = useSelector(getCurrentContactsPageSize)
  const showFilterModal = useSelector(getShowFilterModal)
  const orgId = organizationId || useSelector(getUserOrganizationId)

  const [showFileModal, setShowFileModal] = useState(false)
  const [showCreateContactModal, setShowCreateContactModal] = useState(false)
  const [showSuggestContactModal, setShowSuggestContactModal] = useState(false)

  const navigate = useNavigate()
  const [update, setUpdate] = useState(0)

  const [queryFilter, setQueryFilter] = useState(initialQueryState)

  const [showCompose, setShowCompose] = useState(false)
  const [composeEmail, setComposeEmail] = useState(initialStateComposeEmail)
  const [isSending, setIsSending] = useState(false)
  const [sendStatus, setSendStatus] = useState('')
  const [toastHeader, setToastHeader] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [defaultEmailTarget, setDefaultEmailTarget] = useState([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: INITIAL_PAGE_SIZE,
  })

  const handleSetCurrentPage = page => {
    dispatch(setCurrentContactPage(page))
  }
  const handleSetPageSize = size => {
    dispatch(setCurrentContactPage(1))
    dispatch(setContactPageSize(size))
  }

  const handleClose = () => {
    setComposeEmail(initialStateComposeEmail)
    setShowCompose(false)
  }
  const handleShow = () => setShowCompose(true)

  const handleClickEmail = email => {
    setDefaultEmailTarget([email])
    handleShow()
  }

  const inputChangeHandler = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
      const fieldName = path(['target', 'id'], e)
      const value = path(['target', 'value'], e)
      setComposeEmailPropValue(fieldName, value)
    }
  }

  const setComposeEmailPropValue = (fieldName, value) => {
    setComposeEmail(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const validFormSentEmail = composeEmail => {
    const { to } = composeEmail
    return to?.length
  }

  const replaceBodyField = (body, contact) => {
    let bodyMerge = body
    mandatoryFields.forEach(mandatoryField => {
      bodyMerge = bodyMerge.replaceAll(
        `[[${mandatoryField.value}]]`,
        contact[mandatoryField.value]
      )
    })
    return bodyMerge
  }

  const handleSendClick = (mailMerge, contacts) => {
    const { to, subject, body, cc, bcc } = composeEmail
    if (!validFormSentEmail(composeEmail)) {
      setSendStatus(
        <strong className="text-danger">Fill at least To field.</strong>
      )
      setToastHeader('Send Email Status')
      setShowToast(true)
      return
    }
    setSendStatus('')
    setIsSending(true)
    const toTarget = to.map(toItem => ({ email: toItem, name: toItem }))
    const ccTarget = cc.map(ccItem => ({ email: ccItem, name: ccItem }))
    const bccTarget = bcc.map(bccItem => ({ email: bccItem, name: bccItem }))
    let promises = []
    if (mailMerge) {
      promises = contacts.map(contact =>
        sendNylasEmail(
          {
            email: providerAccount?.email || 'thebenjaminapp@gmail.com',
            name: userFullName,
          },
          [{ email: contact.email, name: contact.first_name }], // To
          subject,
          replaceBodyField(body, contact),
          ccTarget,
          bccTarget,
          providerAccount?.token,
          true,
          orgId
        )
      )
    } else {
      promises = [
        sendNylasEmail(
          {
            email: providerAccount?.email || 'thebenjaminapp@gmail.com',
            name: userFullName,
          },
          toTarget,
          subject,
          body,
          ccTarget,
          bccTarget,
          providerAccount?.token,
          true,
          orgId
        ),
      ]
    }
    Promise.allSettled(promises)
      .then(() => {
        setSendStatus('Email was sent successfully')
        setShowCompose(false)
      })
      .catch(() => {
        setSendStatus('Something is wrong, please try again.')
      })
      .finally(() => {
        setToastHeader('Send Email Status')
        setShowToast(true)
        setIsSending(false)
        setTimeout(() => setUpdate(update + 1), 500)
      })
  }

  const noEmptyValues = obj =>
    Object.fromEntries(
      Object.entries(obj).filter(
        ([, v]) => v != null && v != '' && v != undefined
      )
    )

  const fetchEventParticipants = (event, donation) =>
    DonationsApi.getEventParticipants(orgId, event, donation)

  const filterContactsByDonations = (contacts, event, donation) => {
    if (!event && !donation) {
      return contacts
    }
    return fetchEventParticipants(event, donation).then(donors =>
      contacts.filter(contact => donors.find(donor => donor.id === contact._id))
    )
  }

  const resetFilterHandler = (reloadPage = false) => {
    if (!equals(queryFilter, initialQueryState)) {
      setQueryFilter(initialQueryState)
      dispatch(setCurrentContactPage(1))
      if (reloadPage) setTimeout(() => setUpdate(update + 1), 500)
    }
  }
  const handleOpenFilterModal = () => {
    resetFilterHandler()
    dispatch(setContactPageShowFilter(true))
  }
  const handleCloseFilterModal = () => {
    dispatch(setContactPageShowFilter(false))
  }

  const applyFilterHandler = filter => {
    handleCloseFilterModal()
    if (Object.values(filter)) {
      setQueryFilter(filter)
      dispatch(setCurrentContactPage(1))
    }
  }

  const orgQuery = useQuery({
    queryKey: ['getOrganization', orgId],
    queryFn: () => ApiOrg.getOrganization(orgId),
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!orgId,
  })
  const orgUsersQuery = useQuery({
    queryKey: ['getOrgUsers', orgId, user],
    queryFn: () => UsersApi.getUsers(orgId, user.role),
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!(orgId && user?.role),
  })
  const orgUserRecords = useMemo(() => {
    const { data } = orgUsersQuery
    if (data) {
      return data
    } else {
      return []
    }
  }, [orgUsersQuery])

  const contactsQuery = useQuery({
    queryKey: [
      'getContacts',
      currentPage,
      pageSize,
      user,
      orgId,
      queryFilter,
      initialQueryState,
    ],
    queryFn: () => {
      const offset = currentPage === 0 ? 0 : (currentPage - 1) * pageSize
      if (equals(queryFilter, initialQueryState)) {
        return ContactsApi.getContacts(
          user,
          false,
          orgId,
          true,
          `${offset}`,
          `${pageSize}`
        )
      } else {
        let filters = {}
        if (Object.values(queryFilter)) {
          const valueOnlyFilter = noEmptyValues(queryFilter)
          const { event, donation, count, ...rest } = valueOnlyFilter
          const state_abbr = USStates.find(
            stateObj => stateObj.name === rest['state']
          )
            ? USStates.find(stateObj => stateObj.name === rest['state']).state
            : undefined
          const current_donations_total = rest['donation_capacity']
          filters = noEmptyValues({
            ...omit(['state', 'donation_capacity'], rest),
            state_abbr,
            current_donations_total,
          })
        }
        return ContactsApi.getContacts(
          user,
          false,
          orgId,
          true,
          `${offset}`,
          `${pageSize}`,
          filters
        )
      }
    },
    placeholderData: keepPreviousData,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!(user && orgId),
  })
  const contactRecords = useMemo(() => {
    const { isPending, isError, error, data, isFetching, isPlaceholderData } =
      contactsQuery
    if (data) {
      return {
        ...data,
        isPending,
        isError,
        error,
        isFetching,
        isPlaceholderData,
      }
    } else {
      return {
        records: [],
        isPending,
        isError,
        error,
        isFetching,
        isPlaceholderData,
      }
    }
  }, [contactsQuery, pageSize])

  const contactsCountQuery = useQuery({
    queryKey: ['getContactsCount', orgId, queryFilter, initialQueryState],
    queryFn: () => {
      if (equals(queryFilter, initialQueryState)) {
        return ContactsApi.getContactsCount(orgId)
      } else {
        let filters = {}
        if (Object.values(queryFilter)) {
          const valueOnlyFilter = noEmptyValues(queryFilter)
          const { event, donation, count, ...rest } = valueOnlyFilter
          const state_abbr = USStates.find(
            stateObj => stateObj.name === rest['state']
          )
            ? USStates.find(stateObj => stateObj.name === rest['state']).state
            : undefined
          const current_donations_total = rest['donation_capacity']
          filters = noEmptyValues({
            ...omit(['state', 'donation_capacity'], rest),
            state_abbr,
            current_donations_total,
          })
        }
        return ContactsApi.getContactsCount(orgId, filters)
      }
    },
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!orgId,
  })
  const contactsCount = useMemo(() => {
    const { data, isFetching } = contactsCountQuery
    if (data) {
      return {
        count: data.count,
        pageCount: Math.ceil(data.count / pageSize),
        isFetching,
      }
    } else {
      return {
        count: 0,
        pageCount: 1,
        isFetching,
      }
    }
  }, [contactsCountQuery, pageSize])

  const contactsDonationDataQuery = useQuery({
    queryKey: ['filterDonationDataByContactId', orgId, contactRecords],
    queryFn: () => {
      const contactIds = contactRecords.records.map(
        contact => contact.contact_person_id
      )
      return filterDonationsDataByContactId(orgId, contactIds)
    },
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!(orgId && contactRecords?.records?.length),
  })
  const lastDonationMappedContactRecords = useMemo(() => {
    const { data, isFetching } = contactsDonationDataQuery
    if (
      data &&
      contactRecords?.records?.length &&
      contactsCount.count &&
      orgQuery?.data
    ) {
      return {
        records: contactRecords.records.map(contact => {
          const matchingDonationData = data.records.find(
            donation => donation.contact_person_id === contact.contact_person_id
          )
          const renderContactType = () => {
            if (
              matchingDonationData?.donation_date &&
              matchingDonationData?.donation_total
            ) {
              return moment(matchingDonationData?.donation_date).unix() >=
                moment(orgQuery?.data?.fundraisingStartDate).unix()
                ? 'Donor'
                : 'Dormant'
            } else {
              return contact.contact_source_id == contact_source_types.CUSTOMER
                ? 'Lead'
                : contact.contact_source_id == contact_source_types.PURCHASE
                ? 'Provided'
                : 'Staff'
            }
          }
          return {
            ...contact,
            donation_date: matchingDonationData?.donation_date,
            donation_total: matchingDonationData?.donation_total,
            contact_type: renderContactType(),
          }
        }),
        count: contactsCount.count,
        pageCount: contactsCount.pageCount,
        isFetching,
      }
    } else {
      return {
        records: contactRecords.records,
        count: contactsCount.count,
        pageCount: contactsCount.pageCount,
        isFetching,
      }
    }
  }, [contactRecords, contactsCount, contactsDonationDataQuery, orgQuery])

  const handleUpdateData = args => {
    setQueryFilter({
      ...initialQueryState,
      count: queryFilter.count ? queryFilter.count + 1 : 1,
    })
    setContacts(args)
  }

  const ToolbarComponent = () => (
    <Row className="pb-4">
      <Col>
        <Stack gap={3} direction="horizontal">
          <ActionButton
            handleClick={handleOpenFilterModal}
            action="Filters"
            type="filter"
          />
          <ModalFilterContactsJS
            contacts={contacts}
            showFilterModal={showFilterModal}
            handleCloseFilterModal={handleCloseFilterModal}
            applyFilter={applyFilterHandler}
            resetFilter={resetFilterHandler}
            incomingFilter={queryFilter}
            orgUsers={orgUserRecords}
          />
          <Stack gap={3} direction="horizontal">
            {/* {renderDonorEngineModal && (
            <UploadDiv onClick={() => setShowSuggestContactModal(true)}>
              <FaRegMoneyBillAlt size={16} />
              <span>Donor Engine</span>
            </UploadDiv>
          )} */}

            {renderUploadFileModal && (
              <UploadDiv onClick={() => navigate(`/contacts/uploadfile`)}>
                <BsUpload size={16} />
                <span>Upload Contacts</span>
              </UploadDiv>
            )}

            {renderCreateContactModal && (
              <ActionButton
                handleClick={() => setShowCreateContactModal(true)}
                variant="secondary"
                action="Create Contact"
              >
                <BsPlusLg size={12} />
              </ActionButton>
            )}
            <ActionButton
              handleClick={() => resetFilterHandler(true)}
              variant="secondary"
              type="filter"
              action="Clear Filters"
            ></ActionButton>
          </Stack>
        </Stack>
      </Col>
    </Row>
  )

  const columns = contactColumns({
    handleClickEmail,
    orgUsers: orgUserRecords,
    setToastHeader,
    setShowToast,
  })

  const memoizedColumns = useMemo(() => columns, [columns])

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <ToolbarComponent />
        </Row>
        <Row>
          <Col
            sm={12}
            style={{ display: 'flex', justifyContent: 'flex-start' }}
          >
            {orgId && (
              <ContactsTable
                data={
                  lastDonationMappedContactRecords.records
                    ? lastDonationMappedContactRecords.records
                    : contactRecords.records
                }
                setData={handleUpdateData}
                columns={memoizedColumns}
                showGoToPage={false}
                pagination={pagination}
                setPagination={setPagination}
                isLoading={
                  contactRecords.isFetching ||
                  lastDonationMappedContactRecords.isFetching
                }
                pageCount={contactsCount.pageCount}
                currentPage={currentPage}
                setCurrentPage={handleSetCurrentPage}
                pageSize={pageSize}
                setPageSize={handleSetPageSize}
              />
            )}
          </Col>
        </Row>
        {renderDonorEngineModal && (
          <SuggestContactModal
            show={showSuggestContactModal}
            handleClose={() => setShowSuggestContactModal(false)}
          />
        )}
        {renderUploadFileModal && (
          <UploadFileModal
            show={showFileModal}
            handleClose={() => setShowFileModal(false)}
          />
        )}
        {renderCreateContactModal && (
          <CreateContactModal
            show={showCreateContactModal}
            handleClose={() => setShowCreateContactModal(false)}
            handleUpdate={() => setUpdate(update + 1)}
          />
        )}
        <ToastContainer position="top-end" className="p-3">
          <Toast
            placement=""
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">{toastHeader}</strong>
            </Toast.Header>
            <Toast.Body>{sendStatus}</Toast.Body>
          </Toast>
        </ToastContainer>
        <MailboxCompose
          defaultEmailTarget={defaultEmailTarget}
          showCompose={showCompose}
          setComposeEmailPropValue={setComposeEmailPropValue}
          handleClose={handleClose}
          composeEmail={composeEmail}
          handleSendClick={handleSendClick}
          inputChangeHandler={inputChangeHandler}
          isSending={isSending}
        />
        {contactRecords.isFetching ||
        lastDonationMappedContactRecords.isFetching ? (
          <div
            style={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              zIndex: 2,
              transform: 'scale(1.5)',
            }}
          >
            <Spinner animation="border" />
          </div>
        ) : null}
      </Container>
    </Wrapper>
  )
}

ContactsPage.propTypes = {
  renderUploadFileModal: PropTypes.bool,
  renderCreateContactModal: PropTypes.bool,
  renderDonorEngineModal: PropTypes.bool,
  renderPageHeadings: PropTypes.bool,
  organizationId: PropTypes.string,
  organization: PropTypes.object,
}

export default ContactsPage
