import { Breadcrumb } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Link, Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { nanoid } from 'nanoid'
import { getBreadcrumbs, getPageTitle } from '../../utils/state/selectors'

const HeadingsWrapper = styled.div`
  margin-left: 2rem;
`

const HrefLink = ({ href, children }) => <Link to={href}>{children}</Link>

HrefLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
}

const Item = ({ breadcrumb }) => (
  <Breadcrumb.Item
    href={breadcrumb.href}
    active={breadcrumb.active}
    linkAs={HrefLink}
  >
    {breadcrumb.title}
  </Breadcrumb.Item>
)

Item.propTypes = {
  breadcrumb: PropTypes.object,
}

const PageLayout = () => {
  const title = useSelector(getPageTitle)
  const breadcrumbs = useSelector(getBreadcrumbs)

  return (
    <div className="m-3">
      <HeadingsWrapper>
        {title && <div className="h1">{title}</div>}
        {breadcrumbs.length > 0 && (
          <Breadcrumb>
            {breadcrumbs.map(b => (
              <Item key={nanoid()} breadcrumb={b} />
            ))}
          </Breadcrumb>
        )}
      </HeadingsWrapper>
      <Outlet />
    </div>
  )
}

export default PageLayout
