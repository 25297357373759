import { pathOr } from 'ramda'
import { MicrosoftLoginButton } from 'react-social-login-buttons'
import { useSelector } from 'react-redux'
import constants from '../../config/constants'

const AzureLoginButton = () => {
  const isLogingIn = useSelector(
    pathOr(false, ['authentication', 'isLogingIn'])
  )
  const handleClick = () => {
    const authorizeEndpoint = constants.AZURE_AUTHORIZE_ENDPOINT
    const params = new URLSearchParams({
      client_id: constants.AZURE_CLIENT_ID(),
      response_type: 'code',
      redirect_uri: constants.AZURE_REDIRECT_URI(),
      scope: constants.AZURE_SCOPES,
      response_mode: 'query',
      state: `s-${Math.random()}`,
    }).toString()
    const url = `${authorizeEndpoint}?${params}`
    window.location = url
  }

  return (
    <MicrosoftLoginButton
      className="w-100 d-flex flex-row flex-nowrap justify-content-center align-items-center"
      text="Microsoft"
      onClick={handleClick}
      style={{ borderColor: '#0068b3', background: 'white', color: 'black' }}
      disabled={isLogingIn}
    ></MicrosoftLoginButton>
  )
}

export default AzureLoginButton
