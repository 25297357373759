import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
// import GitInfo from 'react-git-info/macro'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import './index.scss'
import App from './App'
import store from './config/store'
import { ErrorBoundary } from 'react-error-boundary'

import { msalConfig } from './config/msalConfig'
import ErrorFallback from './ErrorFallback'

import { datadogRum } from '@datadog/browser-rum'
import { loadEnvJSON } from './redux/envJSON/envJSON'

fetch('/env.json')
  .then(res => res.json())
  .then(data => {
    store.dispatch(loadEnvJSON(data))
    datadogRum.init({
      applicationId: data.DD_APP_ID,
      clientToken: data.DD_CLIENT_TOKEN,
      site: data.DD_SITE,
      service: data.DD_SERVICE,
      env: data.DD_ENV,
      version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      trackViewsManually: true,
      defaultPrivacyLevel: 'mask-user-input',
      proxy: `${data.BASE_ENDPOINT}/ddproxy`,
    })

    datadogRum.startSessionReplayRecording()

    const msalInstance = new PublicClientApplication(msalConfig)
    const root = createRoot(document.getElementById('root'))

    /*const gitInfo = GitInfo()
    console.log(
      `${(gitInfo.branch || '').toUpperCase()} instance, Git version ${
        gitInfo.commit.shortHash
      }`
    )*/

    root.render(
      <Provider store={store}>
        <MsalProvider instance={msalInstance}>
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              // reset the state of your app so the error doesn't happen again
            }}
          >
            <App />
          </ErrorBoundary>
        </MsalProvider>
      </Provider>
    )
  })
  .catch(error => console.log(error))

// const backSlash = (location.pathname.match(/\//g) || []).length
// const returnSlash = Array.from({ length: backSlash - 1 }, () => '../').join('')

// fetch(`${returnSlash}env.json`)
