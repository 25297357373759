import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import { FC } from 'react'

import ActionButton from '../ui-elements/ActionButton'
import { CONTACT_LEVELS } from '../../api/enums'

import { IContact } from '../../api/interfaces'

import moment from 'moment'

import { DATEFORMAT } from '../../utils/timestamp'

import { formatPhoneNumber } from '../../utils/stringsOperations'

const Styledcontainer = styled.div`
  border-radius: 0.25rem;
  padding: 0.5rem;
  border-style: solid;
  border-width: 1px;
  border-color: #d9d9d9;
`

const SpanTitle = styled.span`
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`

const SpanContactTitle = styled.span<{
  isGrey?: boolean
  size?: number
  fw?: number
}>`
  font-family: DM Sans;
  font-weight: ${props => (props.fw ? props.fw : 400)};
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 10px;
  color: ${props => (props.isGrey ? `#79838d;` : `#3b4248;`)};
  font-size: ${props => (props.size ? props.size : 20)};
`

const SpanDetails = styled.span<{ isGrey?: boolean }>`
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${props => (props.isGrey ? `#79838d;` : `#3b4248;`)};
`

interface ContactDetailsProps {
  contact: IContact
  setModalEditShow: (show: boolean) => void
}

const getFullName = (contact: IContact) => {
  let s = ''
  s += contact?.first_name || ''
  if (contact?.middle_name) s += ` ${contact.middle_name}`
  else if (contact?.middle_initial) s += ` ${contact.middle_initial}.`
  if (contact?.last_name) s += ` ${contact.last_name}`
  if (contact?.suffix_name) s += `, ${contact.suffix_name}`
  return s
}

const ContactDetails: FC<ContactDetailsProps> = ({
  contact,
  setModalEditShow,
}) => {
  return (
    <Styledcontainer>
      <Row className="mt-1">
        <Col>
          <SpanTitle>Contact Details</SpanTitle>
        </Col>
        <Col>
          {contact?.level != CONTACT_LEVELS.BUNDLER && (
            <ActionButton
              handleClick={() => setModalEditShow(true)}
              action="Edit"
            />
          )}
        </Col>
      </Row>
      <Row className="mt-2 w-auto">
        <Col md={12}>
          <div className="d-flex flex-wrap w-100">
            <div className="d-flex flex-row ms-2 w-100 flex-wrap">
              {/* Name Info */}
              <div className="w-100">
                <Row>
                  <Col className="mb-2">
                    <SpanContactTitle fw={500} size={24}>
                      NAME
                    </SpanContactTitle>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <SpanContactTitle size={20}>
                      {getFullName(contact)}
                    </SpanContactTitle>
                  </Col>
                </Row>
                <div>
                  {contact?.goes_by && (
                    <Row>
                      <Col md={5}>
                        <SpanContactTitle>Goes By:</SpanContactTitle>
                      </Col>
                      <Col>
                        <SpanDetails>{contact.goes_by}</SpanDetails>
                      </Col>
                    </Row>
                  )}
                  {contact?.prefix_name && (
                    <Row>
                      <Col md={5}>
                        <SpanContactTitle>Prefix:</SpanContactTitle>
                      </Col>
                      <Col>
                        <SpanDetails>{contact.prefix_name}</SpanDetails>
                      </Col>
                    </Row>
                  )}
                  {contact?.formal_salutation && (
                    <Row>
                      <Col md={5}>
                        <SpanContactTitle>Formal:</SpanContactTitle>
                      </Col>
                      <Col>
                        <SpanDetails>{contact.formal_salutation}</SpanDetails>
                      </Col>
                    </Row>
                  )}
                  {contact?.informal_salutation && (
                    <Row>
                      <Col md={5}>
                        <SpanContactTitle>Informal:</SpanContactTitle>
                      </Col>
                      <Col>
                        <SpanDetails>{contact.informal_salutation}</SpanDetails>
                      </Col>
                    </Row>
                  )}
                  {contact?.invite_alias && (
                    <Row>
                      <Col md={5}>
                        <SpanContactTitle>Invite:</SpanContactTitle>
                      </Col>
                      <Col>
                        <SpanDetails>{contact.invite_alias}</SpanDetails>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={5}>
                      <SpanContactTitle>Spouse:</SpanContactTitle>
                    </Col>
                    <Col>
                      <SpanDetails>{contact?.spouse || 'None'}</SpanDetails>
                    </Col>
                  </Row>
                  {contact?.birth_date && (
                    <Row>
                      <Col md={5}>
                        <SpanContactTitle>Birthday:</SpanContactTitle>
                      </Col>
                      <Col>
                        <SpanDetails>
                          {moment(contact?.birth_date).format(
                            DATEFORMAT.DATE_FORMAT
                          )}
                        </SpanDetails>
                      </Col>
                    </Row>
                  )}
                  {(contact?.email ||
                    contact?.work_email ||
                    contact.other_email) && (
                    <>
                      <Row>
                        <Col md={5} className="my-2">
                          <SpanContactTitle fw={500} size={24}>
                            EMAIL
                          </SpanContactTitle>
                        </Col>
                      </Row>
                      {contact?.work_email && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Work:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.work_email}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.email && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Primary:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.email}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.other_email && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Other:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.other_email}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  {(contact?.work_phone_number ||
                    contact?.work_phone_direct ||
                    contact?.cell_phone_number ||
                    contact?.landline_phone_number) && (
                    <>
                      <Row>
                        <Col md={5} className="my-2">
                          <SpanContactTitle fw={500} size={24}>
                            PHONE
                          </SpanContactTitle>
                        </Col>
                      </Row>
                      {contact?.cell_phone_number && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Cell:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>
                              {formatPhoneNumber(contact?.cell_phone_number)}
                            </SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.landline_phone_number && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Home:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>
                              {formatPhoneNumber(
                                contact?.landline_phone_number
                              )}
                            </SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.work_phone_direct && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Work Direct:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>
                              {contact?.work_phone_direct}
                            </SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.work_phone_number && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Work:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>
                              {formatPhoneNumber(contact?.work_phone_number)}
                            </SpanDetails>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  {(contact?.employer ||
                    contact?.title ||
                    contact?.occupation ||
                    contact?.industry) && (
                    <>
                      <Row>
                        <Col md={5} className="my-2">
                          <SpanContactTitle fw={500} size={24}>
                            PROFESSION
                          </SpanContactTitle>
                        </Col>
                      </Row>
                      {contact?.employer && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Employer:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.employer}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.title && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Title:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.title}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.occupation && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Occupation:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.occupation}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.industry && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Industry:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.industry}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  {(contact?.address_1 ||
                    contact?.city ||
                    contact?.state_abbr ||
                    contact?.postal_code ||
                    contact?.county) && (
                    <>
                      <Row>
                        <Col md={5} className="my-2">
                          <SpanContactTitle fw={500} size={24}>
                            HOME ADDRESS
                          </SpanContactTitle>
                        </Col>
                      </Row>
                      {contact?.address_1 && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Home Street:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.address_1}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.address_2 && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Home Street 2:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.address_2}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.city && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Home City:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.city}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.state_abbr && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Home State:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.state_abbr}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.postal_code && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Home Zip:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.postal_code}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.jurisdiction_name && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>County:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>
                              {contact?.jurisdiction_name}
                            </SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.county && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Home County:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.county}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  {(contact?.work_address_1 ||
                    contact?.work_city ||
                    contact?.work_state_abbr ||
                    contact?.work_postal_code) && (
                    <>
                      <div className="my-2">
                        <SpanContactTitle fw={500} size={24}>
                          WORK ADDRESS
                        </SpanContactTitle>
                      </div>
                      {contact?.work_address_1 && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Work Street:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.work_address_1}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.work_city && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Work City:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.work_city}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.work_state_abbr && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Work State:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>
                              {contact?.work_state_abbr}
                            </SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.work_postal_code && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Work Zip:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>
                              {contact?.work_postal_code}
                            </SpanDetails>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                  {(contact?.assistant_name ||
                    contact?.assistant_email ||
                    contact?.assistant_mobile_phone_number ||
                    contact?.assistant_work_phone_number) && (
                    <>
                      <div className="my-2">
                        <SpanContactTitle fw={500} size={24}>
                          ASSISTANT
                        </SpanContactTitle>
                      </div>
                      {contact?.assistant_name && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Work Address:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>{contact?.work_address_1}</SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.assistant_email && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Email:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>
                              {contact?.assistant_email}
                            </SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.assistant_mobile_phone_number && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Cell:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>
                              {contact?.assistant_mobile_phone_number}
                            </SpanDetails>
                          </Col>
                        </Row>
                      )}
                      {contact?.assistant_work_phone_number && (
                        <Row>
                          <Col md={5}>
                            <SpanContactTitle>Work:</SpanContactTitle>
                          </Col>
                          <Col>
                            <SpanDetails>
                              {contact?.assistant_work_phone_number}
                            </SpanDetails>
                          </Col>
                        </Row>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Styledcontainer>
  )
}

export default ContactDetails
