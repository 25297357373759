import PropTypes from 'prop-types'
import { BsReply, BsReplyAll } from 'react-icons/bs'
import styled from 'styled-components'
import MailReplyOptions from './MailReplyOptions'
import ReplyForwardButton from '../ReplyForwardButton'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const MailReplyDropdown = ({ replyForward, updateReplyForward }) => {
  return (
    <Container>
      <>
        {replyForward === 'Reply' || replyForward === '' ? (
          <ReplyForwardButton
            title={'Reply'}
            icon={BsReply}
            updateReplyForward={() => updateReplyForward('Reply')}
            isActive={replyForward === 'Reply' ? true : false}
          />
        ) : (
          <ReplyForwardButton
            title={'Reply All'}
            icon={BsReplyAll}
            updateReplyForward={() => updateReplyForward('Reply All')}
            isActive={replyForward === 'Reply All' ? true : false}
          />
        )}
      </>
      <MailReplyOptions setOptions={updateReplyForward} />
    </Container>
  )
}

MailReplyDropdown.propTypes = {
  replyForward: PropTypes.string,
  updateReplyForward: PropTypes.func,
}

export default MailReplyDropdown
