import { useEffect, useState } from 'react'
import Api from '../../api/contact'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import { useParams } from 'react-router-dom'
import { Col, Container, Row, Button } from 'react-bootstrap'
import { SlUser } from 'react-icons/sl'
import ContactDetails from './ContactDetails'
import { isEmpty, isNil } from 'ramda'
import ContactCallModal from './ContactCallModal'
import ContactDropdown from './ContactDropdown'
import TitleBreadcrumbs from '../ui-elements/TitleBreadcrumbs'
import styled from 'styled-components'
import EditContactModal from './EditContactModal'
import { sendNylasEmail } from '../../api/nylas/mailbox'
import { getUserToken } from '../../utils/dataModels/user'
import {
  getUserFullName,
  getUserOrganizationId,
} from '../../utils/state/selectors'
import { getScheduledEvents, getSchedulerURL } from '../../api/nylas/scheduler'
import EmailSchedulerContactModal from './EmailSchedulerContactModal'
import { useSelector } from 'react-redux'
import { useZoomCredentials } from '../../hooks/useZoomCredentials'
import CenterSpinner from '../CenterSpinner/CenterSpinner'
import ContactTabs from './ContactTabs/ContactTabs'
import constants from '../../config/constants'
import ContactOverview from './ContactOverview'
import TopLevelInfo from './TopLevelInfo'
import ActionButton from '../ui-elements/ActionButton'

const Wrapper = styled.div`
  padding: 1rem;
  width: 100%;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

const SpinnerDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 28rem;
`

const LeftDiv = styled.div`
  width: 30%;
  float: right;
`

const RightDiv = styled.div`
  width: 70%;
  max-width: 3000px;
  float: right;
`

const Tag = tag => (
  <Button variant="outline-dark" size="sm">
    <SlUser className="me-2" />
    {tag.tag}
  </Button>
)

const ContactIdPage = () => {
  const providerAccount = getUserToken()
  const userFullName = getUserFullName()
  const [sendStatus, setSendStatus] = useState('')
  const { contactId } = useParams()
  const [contact, setContact] = useState({})
  const [update, setUpdate] = useState(0)
  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.CONTACTS(false),
    mainBreadcrumbs.CONTACT_ID(contact ?? { contactId, first_name: '' }, true),
  ]
  const [modalShow, setModalShow] = useState(false)
  const [modalEditShow, setModalEditShow] = useState(false)
  const [modalEmailShow, setModalEmailShow] = useState(false)
  const { hasCredentials } = useZoomCredentials()
  const organizationId = useSelector(getUserOrganizationId)

  useEffect(() => {
    let isMounted = true
    Api.getContact(contactId, undefined, true).then(cc =>
      isMounted ? setContact(cc) : 0
    )
    return () => {
      isMounted = false
    }
  }, [update])

  const setStorageZoomData = data =>
    localStorage.setItem('B-ZOOM-METTING-DATA', JSON.stringify(data))

  const openFECPage = contact => {
    const url =
      `https://www.fec.gov/data/receipts/individual-contributions/?contributor_name=${contact?.first_name}+${contact?.last_name}` +
      `&contributor_zip=${contact?.postal_code}&two_year_transaction_period=2014&two_year_transaction_period=2016&` +
      `two_year_transaction_period=2018&two_year_transaction_period=2020&two_year_transaction_period=2022&two_year_transaction_period=2024` +
      `&min_date=01%2F01%2F1990&max_date=12%2F31%2F2024`

    window.open(url, '_blank')
  }

  const zoomMeeting = url => {
    const data = {
      organizationId,
    }
    Api.createZoomLink(data).then(response => {
      setStorageZoomData({
        encryptedPassword: response.encrypted_password,
        meetingNumber: response.meetingNumber,
        joinUrl: response.join_url,
        organizationId,
      })
      window.open(`${window.location.origin}${url}`)
    })
  }

  const handleVideoCallClick = url => {
    if (constants.ZOOM_OAUTH_ENABLED()) {
      const frontendURL = encodeURIComponent(`${window.location.origin}${url}`)
      window.open(
        `${constants.ZOOM_OAUTH_ENDPOINT()}?frontendURL=${frontendURL}`
      )
    } else zoomMeeting(url)
  }

  const callNowClickHandler = () => {
    handleVideoCallClick(`/call-portal/video-call/${contactId}`)
  }

  const onCloseHandler = async (token, scheduledEvents) => {
    const events = await getScheduledEvents(token)
    if (events.length > 0) {
      const newEvents = events.filter(
        obj1 => !scheduledEvents.some(obj2 => obj1.id === obj2.id)
      )
      if (newEvents.length > 0) {
        const { slug } = await getSchedulerURL(token)
        if (slug !== undefined) {
          const from = {
            email: providerAccount?.email || 'benjaminapp.bmail@gmail.com',
            name: userFullName,
          }
          const to = [
            {
              email: contact?.email,
              name: `${contact?.first_name} ${contact?.last_name}`,
            },
          ]
          sendNylasEmail(
            from,
            to,
            'Scheduler Invitation',
            `https://schedule.nylas.com/${slug}`,
            [],
            [],
            providerAccount?.token
          )
            .then(response => {
              if (response.status === 200) {
                setSendStatus('Email was sent successfully')
                setModalEmailShow(true)
              } else {
                setSendStatus('Something is wrong, please try again.')
              }
            })
            .catch(error => {
              setSendStatus('Something is wrong, please try again.')
              console.log(error, sendStatus)
            })
        }
      }
    }
  }

  return (
    <>
      {!(isEmpty(contact) || isNil(contact)) && (
        <Row>
          <Col>
            <TitleBreadcrumbs
              style={{ backgroundColor: 'blue' }}
              breadcrumbs={breadcrumbs}
            >
              <ContactDropdown
                hasCredentials={hasCredentials}
                contact={contact}
                onCall={() => setModalShow(true)}
                onClose={onCloseHandler}
              />
              <ActionButton
                style={{ fontWeight: 1200 }}
                action="History"
                type="no-icon"
                weight={600}
                handleClick={() => openFECPage(contact)}
              ></ActionButton>
            </TitleBreadcrumbs>
          </Col>
        </Row>
      )}
      <Wrapper>
        <Container fluid>
          {isNil(contact) ? (
            <>Contact Not Found</>
          ) : !isEmpty(contact) ? (
            <>
              <Row>
                <LeftDiv>
                  <Row>
                    <Col>
                      <ContactOverview contact={contact} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <TopLevelInfo contact={contact} />
                    </Col>
                  </Row>
                  <Row>
                    {contact?.tags && (
                      <div className="d-flex gap-2 flex-wrap w-100 mb-4">
                        {contact?.tags.map((tag, idx) => (
                          <span key={idx}>
                            <Tag tag={tag}></Tag>
                          </span>
                        ))}
                      </div>
                    )}
                  </Row>
                  <Row>
                    <Col>
                      <ContactDetails
                        contact={contact}
                        setModalEditShow={show => setModalEditShow(show)}
                      />
                    </Col>
                  </Row>
                  {/* Removed due BFI-83, will need to be added with functionality 
                  <Row className="pt-4">
                    <Col>
                      <ContactInteractionsBox />
                    </Col>
                  </Row>*/}
                </LeftDiv>
                <RightDiv>
                  {/* {contact?.level === CONTACT_LEVELS.BUNDLER && (
                    <Row className="pb-4">
                      <Col>
                        <ContactProfileBox
                          level={contact?.level}
                          bundlerId={contact?.userId}
                          orgId={contact?.organizationId}
                        />
                      </Col>
                    </Row>
                  )} */}
                  <ContactTabs contact={contact} />
                  {/*<Row> Removed due BFI-83, will need to be added with functionality 
                    <Col>
                      <ContactRecentActivity isStaff={contact?.level != 'STAFF'} />
                    </Col>
                  </Row>*/}
                </RightDiv>
              </Row>
              <ContactCallModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                onCall={callNowClickHandler}
                contact={contact}
              />
              <EditContactModal
                show={modalEditShow}
                handleClose={() => setModalEditShow(false)}
                contact={contact}
                handleUpdate={() => setUpdate(update + 1)}
                disabledOptionalInputs={false}
                disabledEmailInput={false}
              />
              <EmailSchedulerContactModal
                show={modalEmailShow}
                onHide={() => setModalEmailShow(false)}
                name={`${contact?.first_name} ${contact?.last_name}`}
              />
            </>
          ) : (
            <SpinnerDiv>
              <CenterSpinner />
            </SpinnerDiv>
          )}
        </Container>
      </Wrapper>
    </>
  )
}

export default ContactIdPage
