import axios from '../axios'
import { prop } from 'ramda'
import constants from '../../config/constants'

export const sendNodeMailerEmail = bodyFormData =>
  axios({
    method: 'post',
    url: constants.MAILER_ENDPOINT(),
    data: bodyFormData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'auth-domain': 'benjamin',
    },
  }).then(prop('data'))
