import { nanoid } from 'nanoid'

export const getIsDragDisabled = (key, inviteState) => {
  switch (key) {
    case 'inviteTitle':
    case 'inviteLegend':
    case 'principalHost':
    case 'url':
    case 'legal':
    case 'footer':
    case 'hostList':
    case 'tier':
    case 'location':
    case 'dateAndTime':
    case 'logo':
      return inviteState[key].isDragDisabled
    default:
      return false
  }
}

export const generateDragDataColumns = (inviteState, previewMode) => {
  const dragListItems = Object.keys(inviteState).map(key => ({
    id: nanoid(),
    prefix: 'editor',
    content: key,
    isDragDisabled: getIsDragDisabled(key, inviteState),
  }))
  return [
    {
      header: 'editor',
      isDropDisabled: false,
      isDragDisabled: previewMode,
      hiddenHeader: true,
      dragListItems,
    },
  ]
}

export const generateToogleInitialState = inviteState => {
  return Object.keys(inviteState).reduce((prev, curr) => {
    switch (curr) {
      case 'tier':
      case 'location':
      case 'dateAndTime':
      case 'hostList':
        Object.keys(inviteState[curr])
          .filter(key => key !== 'isDragDisabled')
          .forEach(key => (prev[`${curr}.${key}`] = false))
        break
      default:
        prev[curr] = false
        break
    }
    return prev
  }, {})
}

export const enabledToogleToKey = (key, toogles) => {
  return Object.keys(toogles).reduce((prev, curr) => {
    prev[curr] = curr === key
    return prev
  }, {})
}

export const orderInviteFormat = (inviteState, dragResult) => {
  const inviteStateOrdered = dragResult.editor.reduce((prev, curr) => {
    prev[curr.content] = inviteState[curr.content]
    return prev
  }, {})
  return inviteStateOrdered
}
