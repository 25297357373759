import PropTypes from 'prop-types'
import moment from 'moment'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { BsChevronLeft, BsChevronRight, BsPlusLg } from 'react-icons/bs'
import Editable from './Editable'
import Dot from './Dot'
import StafferViewScope from '../../RoleViewScope/StafferViewScope'
import { useNavigate } from 'react-router-dom'
import constants from '../../../config/constants'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const MonthWrap = styled.div`
  display: inline-block;
  font-size: 1.5rem;
  line-height: 1.953125rem;
`

const Toolbar = ({
  year,
  month,
  nextMonth,
  previousMonth,
  setYear,
  setMonth,
}) => {
  const navigate = useNavigate()
  const today = () => {
    const m = moment()
    setYear(m.year())
    setMonth(m.month())
  }

  const createClickHandler = () => navigate('/events/create')

  return (
    <div className="toolbar d-flex flex-row flex-nowrap justify-content-between align-items-center">
      <div className="d-flex flex-row flex-nowrap gap-2 justify-content-between align-items-center">
        <button
          type="button"
          className="btn btn-outline-primary ml-5 d-inline align-middle"
          onClick={today}
          title="Select today"
        >
          Today
        </button>

        <button
          type="button"
          className="btn"
          onClick={previousMonth}
          title="Previous month"
        >
          <BsChevronLeft />
        </button>

        <button
          type="button"
          className="btn"
          onClick={nextMonth}
          title="Next month"
        >
          <BsChevronRight />
        </button>

        <MonthWrap>{months[month]}</MonthWrap>

        <Editable
          text={year}
          classes="year mb-0 toolbar-year d-inline-block"
          inputProps={{ type: 'number', min: 1900, max: 2100, size: 4 }}
          autoFocus={true}
          onChange={y => setYear(y)}
        />
      </div>

      <div className="d-flex flex-column align-items-end">
        <StafferViewScope>
          <div style={{ marginTop: '-6rem', marginBottom: '3rem' }}>
            <Button
              type="button"
              variant="secondary"
              onClick={createClickHandler}
            >
              <BsPlusLg />
              &nbsp;Create&nbsp;Event
            </Button>
          </div>
        </StafferViewScope>
        <div className="d-flex flex-row flex-nowrap gap-2 justify-content-between align-items-center">
          {Object.keys(constants.CALENDAR_COLORS).map(key => (
            <div key={key}>
              <Dot color={constants.CALENDAR_COLORS[key]} />
              <div className="d-inline-block">{key}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

Toolbar.propTypes = {
  year: PropTypes.string,
  month: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nextMonth: PropTypes.func,
  previousMonth: PropTypes.func,
  setYear: PropTypes.func,
  setMonth: PropTypes.func,
}

export default Toolbar
