import * as Yup from 'yup'
import 'yup-phone-lite'
import { USER_ROL } from '../../api/enums'

export const userValidationSchema = Yup.object().shape({
  name: Yup.string().required('First Name is required'),
  lastName: Yup.string().optional(),
  organization: Yup.string().required('Organization is required'),
  email: Yup.string().required('Email is required').email('Email is invalid'),
  role: Yup.string().required('Role is required'),
  provider: Yup.string().when('role', (values, field) => {
    const [role] = values
    return role && role !== USER_ROL.ADMIN
      ? field.required('Provider is required')
      : field
  }),
  level: Yup.string().when('role', (values, field) => {
    const [role] = values
    return role && role === USER_ROL.BUNDLER
      ? field.required('Level is required')
      : field
  }),
  state: Yup.string().required('State is required'),
  password: Yup.string().when('role', (values, field) => {
    const [role] = values
    return role && role === USER_ROL.ADMIN
      ? field
          .required('Password is required')
          .min(4, 'Password must be at least 4 characters')
          .max(40, 'Password must not exceed 40 characters')
      : field
  }),
  confirmPassword: Yup.string().when('role', (values, field) => {
    const [role] = values
    return role && role === USER_ROL.ADMIN
      ? field
          .required('Confirm Password is required')
          .oneOf([Yup.ref('password')], 'Confirm Password does not match')
      : field
  }),
  phone: Yup.string().optional(),
  picture: Yup.string().optional(),

  deleteUser: Yup.bool().optional(),
  deleteContact: Yup.bool().optional(),
})
