import React from 'react'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import { Container, Col, Row, Toast, ToastContainer } from 'react-bootstrap'
import styled from 'styled-components'
import { PipelineFilter } from './PipelineFilter'
import PipelineHeaderButtons from './PipelineHeaderButtons'
import { columns } from './pipelineColumns'
import AddContactsModal from './AddContactsModal'
import './styles.css'
import CRMTable from './CRMTable'
import MailboxCompose from '../MailBoxPage/MailboxCompose/MailboxCompose'
import { sendNylasEmail } from '../../api/nylas/mailbox'
import { path, omit } from 'ramda'
import {
  addQueueData,
  getQueueData,
  getQueueDataCount,
  updateQueueData,
} from '../../api/queue'
import {
  getCurrentQueuePage,
  getQueuePageCount,
  getRefreshQueue,
  getUserOrganizationId,
  getCurrentQueuePageSize,
  getQueryFilter,
  getPipelineData,
  getPipelineOrgUsers,
  getUser,
} from '../../utils/state/selectors'
import { useDispatch, useSelector } from 'react-redux'
import {
  setPipelineData,
  setRefreshQueue,
  setQueuePageCount,
  setCurrentQueuePage,
  setQueuePageSize,
  setQueryFilter,
  setQueueOrgUsers,
  initialPipelineState,
} from '../../redux/pipeline/pipeline'
import { getUserToken } from '../../utils/dataModels/user'
import { calculateDateDifferenceInDays } from '../../utils/timestamp'
// import OrganizationApi from '../../api/organization'
import UsersApi from '../../api/user'
import moment from 'moment'
import { mandatoryFields } from '../MailBoxPage/MailboxCompose/config'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const initialStateComposeEmail = {
  to: [],
  cc: [],
  bcc: [],
  subject: '',
  body: '',
}

const Wrapper = styled.div`
  padding: 1rem 1.3125rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

function useSkipper() {
  const shouldSkipRef = React.useRef(true)
  const shouldSkip = shouldSkipRef.current

  const skip = React.useCallback(() => {
    shouldSkipRef.current = false
  }, [])

  React.useEffect(() => {
    shouldSkipRef.current = true
  })

  return [shouldSkip, skip]
}

const PipelineCRM = () => {
  const breadcrumbs = [mainBreadcrumbs.HOME(false), mainBreadcrumbs.QUEUE(true)]
  usePageHeadings('Pipeline', breadcrumbs)

  const providerAccount = getUserToken()
  const user = useSelector(getUser())
  const userFullName = `${user.name} ${user.lastName}`
  const userRole = user.role
  const dispatch = useDispatch()
  const [showAddContactsModal, setShowAddContactsModal] = React.useState(false)
  const organizationId = useSelector(getUserOrganizationId)
  const refreshQueue = useSelector(getRefreshQueue)
  const pipelineDataSelector = useSelector(getPipelineData)
  const queuePageCount = useSelector(getQueuePageCount)
  const currentQueuePage = useSelector(getCurrentQueuePage)
  const currentQueuePageSize = useSelector(getCurrentQueuePageSize)
  const queryFilter = useSelector(getQueryFilter)
  const queueOrgUsers = useSelector(getPipelineOrgUsers)

  // React.useEffect(() => {
  //   dispatch(setRefreshQueue(true))
  // }, [])

  /* Email Merge */
  const [showCompose, setShowCompose] = React.useState(false)
  const [composeEmail, setComposeEmail] = React.useState(
    initialStateComposeEmail
  )
  const [isSending, setIsSending] = React.useState(false)
  const [sendStatus, setSendStatus] = React.useState('')
  const [toastHeader, setToastHeader] = React.useState('')
  const [showToast, setShowToast] = React.useState(false)
  const [defaultEmailTarget, setDefaultEmailTarget] = React.useState([])

  /* Pipeline data state */
  const [loadingPipelineData, setLoadingPipelineData] = React.useState(false)
  const [pipelineFilters, setPipelineFilters] = React.useState([])
  const [pipelineRowSelection, setPipelineRowSelection] = React.useState({})
  const [pipelineSorting, setPipelineSorting] = React.useState([])
  const [pipelinePagination, setPipelinePagination] = React.useState({
    pageIndex: 0,
    pageSize: 25,
  })

  const pipelineColumns = React.useMemo(() => columns, [columns])
  const pipelineData = React.useMemo(
    () => pipelineDataSelector,
    [pipelineDataSelector]
  )

  const handleSetCurrentPage = page => {
    dispatch(setCurrentQueuePage(page))
    dispatch(setRefreshQueue(true))
    setPipelineRowSelection({})
  }
  const handleSetPageSize = size => {
    dispatch(setCurrentQueuePage(1))
    dispatch(setQueuePageSize(size))
    dispatch(setRefreshQueue(true))
  }
  const handleSetPipelineData = (rowIndex, columnId, value) => {
    // const updatedObject = {
    //   ...pipelineData[rowIndex],
    //   ...value,
    // }
    // if (columnId === 'status' && value.status === 'Completed') {
    //   dispatch(
    //     setPipelineData([
    //       ...pipelineData.slice(0, rowIndex),
    //       ...pipelineData.slice(rowIndex + 1, pipelineData.length),
    //     ])
    //   )
    // } else {
    //   dispatch(
    //     setPipelineData([
    //       ...pipelineData.slice(0, rowIndex),
    //       updatedObject,
    //       ...pipelineData.slice(rowIndex + 1, pipelineData.length),
    //     ])
    //   )
    // }
    dispatch(
      setQueryFilter({
        ...queryFilter,
        update: queryFilter.update ? queryFilter.update + 1 : 1,
      })
    )
  }
  const handleUpdateRows = (rowIndices, columnId, value) => {
    const mappedPipelineData = pipelineData.map((row, index) => {
      if (rowIndices.includes(index)) {
        return {
          ...row,
          ...value,
        }
      } else {
        return row
      }
    })
    dispatch(setPipelineData(mappedPipelineData))
  }

  /* Pipeline data filtering */
  const handlePipelineFilter = filterValues => {
    let newFilter = []
    Object.entries(filterValues).forEach(entry => {
      const id = entry[0]
      const value = entry[1]
      if (Array.isArray(value)) {
        if (value.every(x => x !== '')) {
          newFilter.push({
            id,
            value,
          })
        }
      } else if (typeof value === 'object') {
        if (Object.values(value).some(v => v)) {
          newFilter.push({
            id,
            value,
          })
        }
      } else if (value !== '') {
        newFilter.push({
          id,
          value,
        })
      }
    })
    const queryObj = newFilter.reduce((resultFilter, filterItem) => {
      let currentFilter = resultFilter
      const id = filterItem.id
      const value = filterItem.value !== undefined ? filterItem.value : ''
      switch (id) {
        case 'status':
          currentFilter['status'] = value
          break
        case 'type':
          currentFilter['queue_type'] = value
          break
        case 'purpose':
          currentFilter[id] = value
          break
        case 'method':
          currentFilter[id] = value
          break
        case 'name':
          currentFilter[id] = value
          break
        case 'capacity':
          currentFilter['capacity'] = {
            [`$${value[0]}`]: parseInt(value[1]),
          }
          break
        case 'region':
          currentFilter['region'] = value
      }
      if (currentFilter.purpose && currentFilter.purpose === 'Event') {
        currentFilter = omit(['purpose'], currentFilter)
        currentFilter['event_id'] = { $exists: true, $ne: '' }
      }
      if (currentFilter['queue_type']) {
        if (currentFilter['queue_type'] === 'Donor') {
          currentFilter['queue_type'] = { $in: ['Deposit', 'Pledge'] }
        }
      }
      if (currentFilter['status']) {
        if (currentFilter['status'] === 'Not Started') {
          currentFilter['donation_total'] = 0
        } else if (currentFilter['status'] === 'Pledged') {
          currentFilter = omit(['status'], currentFilter)
          currentFilter['queue_type'] = 'Pledge'
        } else if (currentFilter['status'] === 'Paid') {
          currentFilter = omit(['status'], currentFilter)
          currentFilter['queue_type'] = 'Deposit'
        }
        let tempStatus = currentFilter['status']
        currentFilter = omit(['status'], currentFilter)
        currentFilter['$and'] = [
          { status: tempStatus },
          { status: { $ne: 'Completed' } },
        ]
      }
      if (id === 'heat') {
        const heatFilterObj = Object.keys(value).reduce((heatFilter, item) => {
          let filterObj = heatFilter
          if (value[item] && item === '0') {
            if (filterObj['heat']) {
              filterObj['heat'] = {
                $in: [...filterObj['heat']['$in'], 0],
              }
            } else {
              filterObj['heat'] = { $in: [0] }
            }
          }
          if (value[item] && item === '1') {
            if (filterObj['heat']) {
              filterObj['heat'] = {
                $in: [...filterObj['heat']['$in'], 1],
              }
            } else {
              filterObj['heat'] = { $in: [1] }
            }
          }
          if (value[item] && item === '2') {
            if (filterObj['heat']) {
              filterObj['heat'] = {
                $in: [...filterObj['heat']['$in'], 2],
              }
            } else {
              filterObj['heat'] = { $in: [2] }
            }
          }
          if (value[item] && item === '3') {
            if (filterObj['heat']) {
              filterObj['heat'] = {
                $in: [...filterObj['heat']['$in'], 3],
              }
            } else {
              filterObj['heat'] = { $in: [3] }
            }
          }
          return filterObj
        }, {})
        if (!Object.values(value).every(item => item)) {
          currentFilter = {
            ...currentFilter,
            ...heatFilterObj,
          }
        }
      }
      return currentFilter
    }, {})
    const completedFilterQueryObj = queryObj['$and']
      ? queryObj
      : {
          ...queryObj,
          status: initialPipelineState.queryFilter.status,
        }
    dispatch(setQueryFilter(completedFilterQueryObj))
    dispatch(setCurrentQueuePage(1))
    dispatch(setRefreshQueue(true))
  }

  const setComposeEmailPropValue = (fieldName, value) => {
    setComposeEmail(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const inputChangeHandler = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
      const fieldName = path(['target', 'id'], e)
      const value = path(['target', 'value'], e)
      setComposeEmailPropValue(fieldName, value)
    }
  }

  const handleCloseMailMerge = () => {
    setComposeEmail(initialStateComposeEmail)
    setShowCompose(false)
  }

  const validFormSentEmail = composeEmail => {
    const { to } = composeEmail
    return to?.length
  }

  const replaceBodyField = (body, contact) => {
    let bodyMerge = body
    mandatoryFields.forEach(mandatoryField => {
      bodyMerge = bodyMerge.replaceAll(
        `[[${mandatoryField.value}]]`,
        contact[mandatoryField.value]
      )
    })
    return bodyMerge
  }

  const handleSendClick = (mailMerge, contacts) => {
    const { to, subject, body, cc, bcc } = composeEmail
    if (!validFormSentEmail(composeEmail)) {
      setSendStatus(
        <strong className="text-danger">Fill at least To field.</strong>
      )
      setToastHeader('Send Email Status')
      setShowToast(true)
      return
    }
    setSendStatus('')
    setIsSending(true)
    const toTarget = to.map(toItem => ({ email: toItem, name: toItem }))
    const ccTarget = cc.map(ccItem => ({ email: ccItem, name: ccItem }))
    const bccTarget = bcc.map(bccItem => ({ email: bccItem, name: bccItem }))
    let email_promises = []
    let update_promises = []
    let updatedRows = []
    if (mailMerge) {
      if (contacts.length) {
        updatedRows = queueRecords.data.reduce(
          (matches, currentItem, index) => {
            let currentMatches = matches
            const contactMatch = toTarget.find(
              target => target.email === currentItem.email
            )
            if (contactMatch) {
              currentMatches.push({
                rowIndex: index,
                _id: currentItem._id,
                event_id: currentItem.event_id,
                ...currentItem,
              })
            }
            return currentMatches
          },
          []
        )

        const currentContacts = [...contacts, ...updatedRows]
        update_promises = updatedRows.map(contact =>
          updateQueueData({
            _id: contact._id,
            heat: contact.heat ? contact.heat : 1,
            status: 'Contacted',
          })
        )
        email_promises = currentContacts.map(contact =>
          sendNylasEmail(
            {
              email: providerAccount?.email || 'thebenjaminapp@gmail.com',
              name: userFullName,
            },
            [{ email: contact.email, name: contact.first_name }],
            subject,
            replaceBodyField(body, contact),
            ccTarget,
            bccTarget,
            providerAccount?.token,
            true,
            organizationId
          )
        )
      } else if (toTarget.length) {
        updatedRows = queueRecords.data.reduce(
          (matches, currentItem, index) => {
            let currentMatches = matches
            const contactMatch = toTarget.find(
              target => target.email === currentItem.email
            )
            if (contactMatch) {
              currentMatches.push({
                rowIndex: index,
                _id: currentItem._id,
                event_id: currentItem.event_id,
                ...currentItem,
              })
            }
            return currentMatches
          },
          []
        )
        update_promises = updatedRows.map(contact =>
          updateQueueData({
            _id: contact._id,
            heat: contact.heat ? contact.heat : 1,
            status: 'Contacted',
          })
        )
        email_promises = updatedRows.map(contact =>
          sendNylasEmail(
            {
              email: providerAccount?.email || 'thebenjaminapp@gmail.com',
              name: userFullName,
            },
            [{ email: contact.email, name: contact.first_name }],
            subject,
            replaceBodyField(body, contact),
            ccTarget,
            bccTarget,
            providerAccount?.token,
            true,
            organizationId
          )
        )
      }
    } else {
      updatedRows = queueRecords.data.reduce((matches, currentItem, index) => {
        let currentMatches = matches
        const contactMatch = toTarget.find(
          target => target.email === currentItem.email
        )
        if (contactMatch) {
          currentMatches.push({
            rowIndex: index,
            id: currentItem._id,
            event_id: currentItem.event_id,
            ...currentItem,
          })
        }
        return currentMatches
      }, [])
      update_promises = updatedRows.map(contact =>
        updateQueueData({
          _id: contact._id,
          heat: contact.heat ? contact.heat : 1,
          status: 'Contacted',
        })
      )
      email_promises = [
        sendNylasEmail(
          {
            email: providerAccount?.email || 'thebenjaminapp@gmail.com',
            name: userFullName,
          },
          toTarget,
          subject,
          body,
          ccTarget,
          bccTarget,
          providerAccount?.token,
          true,
          organizationId
        ),
      ]
    }
    Promise.allSettled(email_promises)
      .then(() => {
        setSendStatus('Email was sent successfully')
        setShowCompose(false)
      })
      .catch(() => {
        setSendStatus('Something is wrong, please try again.')
      })
      .finally(() => {
        setToastHeader('Send Email Status')
        setShowToast(true)
        setIsSending(false)
        // setTimeout(() => setUpdate(update + 1), 500)
      })
    Promise.allSettled(update_promises).then(() => {
      // const rowIndices = updatedRows.map(row => row.rowIndex)
      // handleUpdateRows(rowIndices, 'status', {
      //   status: 'Contacted',
      //   lastTouch: 'Today',
      // })
      dispatch(
        setQueryFilter({
          ...queryFilter,
          update: queryFilter.update ? queryFilter.update + 1 : 1,
        })
      )
    })
  }

  const pipelineHeaderButtonProps = {
    openAddContactsModal: () => setShowAddContactsModal(true),
    openEmailMerge: () => {
      const selectedEmails = Object.keys(pipelineRowSelection).map(key =>
        queueRecords.data[key].email ? queueRecords.data[key].email : undefined
      )
      const filteredEmails = selectedEmails.filter(email => email)
      setDefaultEmailTarget(filteredEmails)
      setShowCompose(true)
    },
  }

  // function filterUniqueById(objects) {
  //   const idCount = {}
  //   objects.forEach(obj => {
  //     if (obj._id) {
  //       idCount[obj._id] = (idCount[obj._id] || 0) + 1
  //     }
  //   })
  //   return objects.filter(obj => idCount[obj._id] === 1)
  // }
  function calculateHeat(donation) {
    if (donation.queue_type === 'Deposit' || donation.queue_type === 'Pledge') {
      return 3
    } else if (
      donation.status === 'Meeting Set' ||
      donation.status === 'In Progress'
    ) {
      return 2
    } else if (donation.status === 'Contacted' || donation.status === 'Asked') {
      return 1
    } else {
      return 0
    }
  }

  const handleAddContacts = async contacts => {
    const pipelineMappedContacts = contacts.map(c => ({
      heat: 0,
      donor_id: c._id,
      first_name: c.first_name,
      last_name: c.last_name,
      capacity: c.donation_capacity,
      region: c.designated_market_area,
      email: c.email,
      state_abbr: c.state_abbr,
      contact_person_id: c.contact_person_id,
      donation_total: 0,
      organizationId: c.organizationId,
      purpose: 'Prospect',
      queue_type: 'prospect',
      method: 'Not Started',
      status: 'Not Started',
      added_by: userFullName,
      assigned_to: c.assignedTo,
      bundlers: [user._id],
    }))
    const addedContacts = await addQueueData(pipelineMappedContacts)
    if (addedContacts) {
      const mappedAddedContacts = addedContacts.map(c => {
        const matchingContact = contacts.find(
          contact => contact.contact_person_id === c.contact_person_id
        )
        const matchingDonorId = matchingContact
          ? matchingContact._id
          : undefined
        const matchingFirstName = matchingContact
          ? matchingContact.first_name
          : undefined
        const matchingLastName = matchingContact
          ? matchingContact.last_name
          : undefined
        const matchingCapacity = matchingContact
          ? matchingContact.donation_capacity
          : undefined
        const matchingRegion = matchingContact
          ? matchingContact.designated_market_area
          : undefined
        return {
          ...c,
          donor_id: matchingDonorId,
          first_name: matchingFirstName,
          last_name: matchingLastName,
          capacity: matchingCapacity,
          status: c.status,
          notes: c.notes,
          queue_type: c.queue_type,
          region: matchingRegion,
          heat: calculateHeat(c),
          orgUsers: queueOrgUsers ? queueOrgUsers : [],
        }
      })
      const updatedPipelineData = [...mappedAddedContacts, ...pipelineData]
      dispatch(setPipelineData(updatedPipelineData))
    }
  }

  const orgUsersQuery = useQuery({
    queryKey: ['getOrgUsers', organizationId, userRole],
    queryFn: () => UsersApi.getUsers(organizationId, userRole),
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!(organizationId && userRole),
  })
  const orgUsers = React.useMemo(() => {
    const { data, isFetching } = orgUsersQuery
    if (data) {
      return {
        orgUsers: data,
        isFetching,
      }
    } else {
      return {
        orgUsers: [],
        isFetching,
      }
    }
  }, [orgUsersQuery])

  const queueDataQuery = useQuery({
    queryKey: [
      'getQueueData',
      refreshQueue,
      organizationId,
      currentQueuePage,
      currentQueuePageSize,
      queryFilter,
    ],
    queryFn: () => {
      const offset =
        currentQueuePage === 0
          ? 0
          : (currentQueuePage - 1) * currentQueuePageSize
      const { name, ...query } = queryFilter
      return getQueueData(
        organizationId,
        offset,
        currentQueuePageSize,
        omit(['update'], query),
        name ? `${name}*` : ''
      )
    },
    placeholderData: keepPreviousData,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!organizationId,
  })
  const queueRecords = React.useMemo(() => {
    const { isPending, isError, error, data, isFetching, isPlaceholderData } =
      queueDataQuery
    if (data && orgUsers) {
      return {
        data: data.data.map(r => ({
          ...r,
          lastTouch: r.last_emailed
            ? calculateDateDifferenceInDays(
                moment(r.last_emailed).unix(),
                moment().unix()
              )
            : undefined,
          orgUsers: orgUsers.orgUsers,
        })),
        queryTime: data.queryTime,
        isPending,
        isError,
        error,
        isFetching,
        isPlaceholderData,
      }
    } else {
      return {
        data: [],
        isPending,
        isError,
        error,
        isFetching,
        isPlaceholderData,
      }
    }
  }, [queueDataQuery, currentQueuePageSize, orgUsers])

  const queueDataCountQuery = useQuery({
    queryKey: ['getQueueDataCount', refreshQueue, organizationId, queryFilter],
    queryFn: () => {
      const { name, ...query } = queryFilter
      return getQueueDataCount(
        organizationId,
        omit(['update'], query),
        name ? `${name}*` : ''
      )
    },
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!organizationId,
  })
  const queueRecordsCount = React.useMemo(() => {
    const { data, isFetching } = queueDataCountQuery
    if (data) {
      return {
        count: data.count,
        pageCount: Math.ceil(data.count / currentQueuePageSize),
        isFetching,
      }
    } else {
      return {
        count: 0,
        pageCount: 1,
        isFetching,
      }
    }
  }, [queueDataCountQuery, currentQueuePageSize])

  const crmTableProps = {
    columns: pipelineColumns,
    data: queueRecords.data,
    loadingData: queueRecords.isFetching,
    setData: handleSetPipelineData,
    columnFilters: pipelineFilters,
    setColumnFilters: setPipelineFilters,
    rowSelection: pipelineRowSelection,
    setRowSelection: setPipelineRowSelection,
    sorting: pipelineSorting,
    setSorting: setPipelineSorting,
    pagination: pipelinePagination,
    // setPagination: setPipelinePagination,
    currentPage: currentQueuePage,
    setCurrentPage: handleSetCurrentPage,
    pageCount: queueRecordsCount.pageCount,
    pageSize: currentQueuePageSize,
    setPageSize: handleSetPageSize,
    showGoToPage: false,
  }

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col lg={3} md={6} sm={6}>
            <PipelineFilter handlePipelineFilter={handlePipelineFilter} />
          </Col>
          <Col lg={9} md={6} sm={6}>
            <div>
              <PipelineHeaderButtons {...pipelineHeaderButtonProps} />
              <CRMTable {...crmTableProps} />
            </div>
          </Col>
        </Row>
        <AddContactsModal
          show={showAddContactsModal}
          onHide={() => setShowAddContactsModal(false)}
          addContacts={handleAddContacts}
        />
        <ToastContainer position="top-end" className="p-3">
          <Toast
            placement=""
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">{toastHeader}</strong>
            </Toast.Header>
            <Toast.Body>{sendStatus}</Toast.Body>
          </Toast>
        </ToastContainer>
        <MailboxCompose
          defaultEmailTarget={defaultEmailTarget}
          showCompose={showCompose}
          setComposeEmailPropValue={setComposeEmailPropValue}
          handleClose={handleCloseMailMerge}
          composeEmail={composeEmail}
          handleSendClick={handleSendClick}
          inputChangeHandler={inputChangeHandler}
          isSending={isSending}
        />
      </Container>
    </Wrapper>
  )
}

export default PipelineCRM
