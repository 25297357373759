import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  apply,
  divide,
  flip,
  identity,
  map,
  min,
  multiply,
  pipe,
  propOr,
  reverse,
  sortBy,
} from 'ramda'
import { makeSetter } from '../../../utils/fp/makeSetter'
import { branch } from '../../../utils/fp/branch'

const getColor = propOr('#e6e6e6', 'color')
const getValue = propOr(0, 'value')
const setValue = makeSetter(['value'])
const divideBy = flip(divide)
const toPercentStr = s => `${s}%`
const setPercentValue = maxVal =>
  pipe(getValue, multiply(100), divideBy(maxVal), min(100), toPercentStr)

const Bar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: var(--width);
  height: 0.75rem;
  background-color: var(--color, #e6e6e6);
  border-radius: 4px;
`

const BarsWrapper = styled.div`
  position: relative;
  height: 0.75rem;
`

const MultiProgressBar = ({ levels, total }) => {
  const sortedLevels = pipe(sortBy(getValue), reverse)(levels)
  const maxVal = getValue(total)
  const percentTotal = setValue('100%', total)
  const percentLevels = map(
    pipe(
      branch([setPercentValue(maxVal), identity], identity),
      apply(setValue)
    ),
    sortedLevels
  )
  const barLevels = [percentTotal, ...percentLevels]

  return (
    <BarsWrapper>
      {barLevels.map((level, index) => (
        <Bar
          key={`bar-${index}`}
          style={{
            '--color': getColor(level),
            '--width': getValue(level),
          }}
        />
      ))}
    </BarsWrapper>
  )
}

MultiProgressBar.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.object).isRequired,
  total: PropTypes.object.isRequired,
}

export default MultiProgressBar
