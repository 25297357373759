import { CONTACT_LEVELS, USER_ROL, PROVIDER_OPTIONS } from '../../api/enums'
import { IOrganization, Levels } from '../../api/interfaces'
import usStates from '../../assets/datasets/us-states.json'
import { capitalizedWord } from '../../utils/stringsOperations'
import { OptionType } from '../GenericForm/SelectForm/SelectForm'
import { toOptionType } from '../GenericForm/utils'

interface UserFormOptions {
  level: OptionType[]
  orgLevel: { [key: string]: OptionType[] }
  role: OptionType[]
  provider: OptionType[]
  state: OptionType[]
  orgOptions: OptionType[]
}

export const userFormOptions: UserFormOptions = {
  level: [
    { value: CONTACT_LEVELS.ADMIN, label: CONTACT_LEVELS.ADMIN },
    { value: CONTACT_LEVELS.OWNER, label: CONTACT_LEVELS.OWNER },
    { value: CONTACT_LEVELS.STAFFER, label: CONTACT_LEVELS.STAFFER },
    { value: CONTACT_LEVELS.BUNDLER, label: CONTACT_LEVELS.BUNDLER },
    { value: CONTACT_LEVELS.CRM, label: CONTACT_LEVELS.CRM },
  ],
  orgLevel: {}, // { orgId: [ { value:'value', label:'label' } ] }
  role: [
    { value: USER_ROL.ADMIN, label: 'App Administrator' },
    { value: USER_ROL.OWNER, label: 'Organization owner' },
    { value: USER_ROL.STAFFER, label: capitalizedWord(USER_ROL.STAFFER) },
    { value: USER_ROL.BUNDLER, label: capitalizedWord(USER_ROL.BUNDLER) },
    { value: USER_ROL.CRM, label: 'Customer Relationship Manager' },
  ],
  provider: PROVIDER_OPTIONS,
  state: [
    { value: '', label: '' },
    ...usStates.map(({ name }) => ({
      value: name,
      label: name,
    })),
  ],
  orgOptions: [],
}

export const getLevelBasedOnRol = (role: USER_ROL) => {
  switch (role) {
    case USER_ROL.ADMIN:
      return CONTACT_LEVELS.ADMIN
    case USER_ROL.STAFFER:
      return CONTACT_LEVELS.STAFFER
    case USER_ROL.BUNDLER:
      return CONTACT_LEVELS.BUNDLER
    case USER_ROL.OWNER:
      return CONTACT_LEVELS.OWNER
    case USER_ROL.CRM:
      return CONTACT_LEVELS.CRM
    default:
      return ''
  }
}

export const getLevelsFromOrganization = (org: IOrganization) => {
  if (!org.levels) return { [USER_ROL.BUNDLER]: 0 }
  return org.levels
}

export const levelsToSelectOptions = (levels: Levels) => {
  return Object.keys(levels).map(key => toOptionType(key, key))
}
