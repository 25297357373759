import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'

import { getAvatarContact } from '../../utils/stringsOperations'
import { FC, useEffect, useState } from 'react'
import UserApi from '../../api/user'
import { IContact } from '../../api/interfaces'

const Styledcontainer = styled.div`
  border-radius: 0.25rem;
  padding: 0.5rem;
  border-style: none;
  margin-bottom: 20px;
  border-color: #d9d9d9;
`

const DivAvatar = styled.div`
  > img {
    height: 7.75rem;
    width: 7.75rem;
    border-radius: 8.75rem;
    margin-left: 10px;
    border: 1px solid rgb(17, 39, 60);
  }
`

const DivName = styled.div`
  font-family: DM Sans;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0em;
  margin-left: 10px;
  margin-top: 0px;
  margin-bottom: 25px;
  text-align: left;
`

const DivProfile = styled.div<{
  mt?: number
  size?: number
}>`
  font-family: DM Sans;
  letter-spacing: 0em;
  line-height: 1.4em;
  text-align: left;
  font-size: ${props => (props.size ? props.size : 24)}px;
  margin-top: ${props => (props.mt ? props.mt : 0)}px;
  align-items: center;
`

interface ContactDetailsProps {
  contact: IContact
}
const ContactOverview: FC<ContactDetailsProps> = ({ contact }) => {
  const [owner, setOwner] = useState('')

  useEffect(() => {
    UserApi.findUserById(contact?.user_id).then(res => {
      setOwner(
        contact?.user_id && res?.name && res.lastName
          ? `${res?.name} ${res?.lastName}`
          : ''
      )
    })
  }, [contact])

  const getFirstLastName = (contact: IContact) => {
    let s = ''
    if (contact?.goes_by) s += `${contact.goes_by}`
    else if (contact?.first_name) s += `${contact.first_name}`
    if (contact?.last_name) s += ` ${contact.last_name}`
    return s
  }

  return (
    <Styledcontainer>
      <Row>
        <Col>
          <DivName>{getFirstLastName(contact)}</DivName>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="d-flex gap-2 flex-wrap w-100 my-auto}">
            <DivAvatar>{getAvatarContact(contact, '9', 'rem')}</DivAvatar>
          </div>
        </Col>
        <Col className="my-auto">
          <DivProfile size={24}>{contact?.title}</DivProfile>
          <DivProfile size={20}>{contact?.employer}</DivProfile>
          <DivProfile size={14} mt={10}>
            {contact?.city ? `${contact.city}, ` : ''}
            {contact?.state_abbr}
          </DivProfile>
          <DivProfile size={14} mt={10}>
            {contact?.designated_market_area}
          </DivProfile>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <div className="d-flex flex-wrap w-100 justify-content-center">
            <DivProfile size={12} mt={20}>
              {owner && <span>Owner: {owner}</span>}
            </DivProfile>
          </div>
        </Col>
      </Row>
    </Styledcontainer>
  )
}

export default ContactOverview
