import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Form, Modal } from 'react-bootstrap'
import { pathOr, propEq } from 'ramda'
//import taskDescriptions from '../../assets/datasets/task-descriptions.json'
import ToastWarningFields from './ToastWarningFields'
import Select from 'react-select'
//import { Value } from 'sass'

const findContactObject = (list, value) => list.find(propEq('value', value))

const RedAst = () => <span style={{ color: 'red' }}>*</span>

const FieldsWrapper = styled.div`
  label {
    display: block;
  }
`
const findOption = (value, options) => options.find(propEq('value', value))

const TaskFormModal = ({
  show,
  onAdd,
  onEdit,
  onClose,
  task,
  isEdit,
  contacts,
}) => {
  const [taskId] = useState(isEdit ? task._id : undefined)
  const [description, setDescription] = useState(isEdit ? task.description : '')
  const [markCompleted, setMarkCompleted] = useState(
    isEdit ? task.markCompleted : false
  )

  const [showToast, setShowToast] = useState(false)
  const [contactsSelectOptions, setContactsSelectOptions] = useState([])
  const [contact, setContact] = useState(
    isEdit
      ? findContactObject(contactsSelectOptions, task.contact)
      : { value: '', label: '' }
  )
  const selectStyles = {
    input: provided => ({
      ...provided,
      border: 'none',
      '> input[type=text]:focus': { outline: 'none !important' },
    }),
  }

  useEffect(() => {
    let options = contacts.map(({ name, lastName, _id }) => ({
      value: _id,
      label: `${name} ${lastName}`,
    }))
    options.unshift({ value: '', label: `Unassigned` })
    setContactsSelectOptions(options)
  }, [contacts])

  const clear = () => {
    setDescription('')
    setContact('')
    setMarkCompleted(false)
  }

  const reset = () => {
    setDescription(isEdit ? task.description : '')
    setContact(isEdit ? task.contact : '')
    setMarkCompleted(isEdit ? task.markCompleted : false)
  }

  const selectChangeHandler = e => {
    setContact(e?.value)
  }

  const switchHandler = setter => e =>
    setter(pathOr(false, ['target', 'checked'], e))

  const checkTiersFields = () => {
    return description != ''
  }

  const addTaskHandler = () => {
    if (checkTiersFields()) {
      onAdd({ description, contact })
      clear()
      onClose()
    } else {
      setShowToast(true)
    }
  }

  const editTaskHandler = () => {
    if (checkTiersFields()) {
      onEdit({ _id: taskId, description, contact, markCompleted })
      clear()
      onClose()
    } else {
      setShowToast(true)
    }
  }

  const actionButtonHandler = isEdit ? editTaskHandler : addTaskHandler

  const cancelHandler = () => {
    clear()
    onClose()
  }

  useEffect(() => {
    reset()
  }, [isEdit, task, show])

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        {isEdit ? 'Edit task' : 'New task'}
      </Modal.Header>
      <Modal.Body>
        <ToastWarningFields show={showToast} setShow={setShowToast} />
        <FieldsWrapper>
          <Form.Group className="mb-4">
            <Form.Label htmlFor="description">Task</Form.Label>
            <Form.Control
              disabled
              autoComplete="off"
              type="text"
              name="description"
              value={description}
              placeholder="Enter task name here"
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label htmlFor="assignee">
              Owner <RedAst />
            </Form.Label>
            <Select
              id="asignee"
              value={findOption(contact, contactsSelectOptions)}
              options={contactsSelectOptions}
              placeholder="Select a task assignee"
              onChange={selectChangeHandler}
              styles={selectStyles}
            />
          </Form.Group>
          <Form.Group>
            <Form.Check
              inline
              type="switch"
              label="Mark as completed"
              name="markCompleted"
              checked={markCompleted}
              onChange={switchHandler(setMarkCompleted)}
            />
          </Form.Group>
        </FieldsWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="light" onClick={cancelHandler}>
          Cancel
        </Button>
        <Button type="button" variant="secondary" onClick={actionButtonHandler}>
          {isEdit ? 'Save Changes' : 'Add Task'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
TaskFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  task: PropTypes.object,
  isEdit: PropTypes.bool,
  contacts: PropTypes.array.isRequired,
}

export default TaskFormModal
