import moment from 'moment'
import { sendNylasEmail } from '../../api/nylas/mailbox'
import { encryptData } from '../../utils/crypto/crypto'

const generateSubject = eventName => `Invitation to ${eventName}`

const generateBody = (eventName, dateAndTime, location, url) => `
  <p>
    I hope this email finds you well. <br/>I am writing to formally invite you to ${eventName}, which will be held on <strong>${dateAndTime}</strong> at ${location}. 
    <br/>To see the event information, simply click <a href="${url}" target="_blank" rel="noopener noreferrer">here</a>.
  </p>
  
  <p>Best regards.</p>
  `

export const sendInviteEvent = async (
  tiers = [],
  eventFormData,
  emailUser,
  userToken,
  eventId
) => {
  if (!tiers.length) return
  try {
    const { name, state, city, day, hour } = eventFormData
    const url = `${
      window.location.origin
    }/public-invite/${eventId}/${encodeURIComponent(encryptData(emailUser))}`
    const dateAndTime = `${moment(day).format('MMMM Do, YYYY')},  ${moment(
      hour,
      'HHmm'
    ).format('h:mm A')}`
    const location = `${state}, ${city}`
    const emailSubject = generateSubject(name)
    const emailBody = generateBody(name, dateAndTime, location, url)

    const toTiers = tiers
      .map(tier =>
        tier.participants.map(participant => ({
          email: participant.primaryEmail,
          name: participant.value,
        }))
      )
      .flat()
    const tierUserIds = tiers
      .map(tier => tier.participants.map(participant => participant.user_id))
      .flat()
    await sendNylasEmail(
      { email: emailUser, name: emailUser },
      [...toTiers],
      emailSubject,
      emailBody,
      [],
      [],
      userToken
    )
    return tierUserIds
  } catch (error) {
    console.log(error)
  }
}
