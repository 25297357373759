import DragList from '../DragV2/DragList/DragList'
import { useEffect, useState } from 'react'
import HostList from './HostList/HostList'
import TextInvite from './TextInvite/TextInvite'
import InviteImage from './ImageInvite/ImageInvite'
import PropTypes from 'prop-types'
import './Invite.css'

import {
  enabledToogleToKey,
  generateDragDataColumns,
  generateToogleInitialState,
  orderInviteFormat,
} from './utils'

const Invite = ({
  format,
  onChange,
  previewMode = false,
  listGridClassName,
  droppableClassName,
  dragItemClassName,
  listItemContainerClassName,
  dragDropContainerClassName,
}) => {
  const [inviteState, setInviteState] = useState(format)

  const [toogles, setToogles] = useState(generateToogleInitialState(format))

  useEffect(() => {
    if (onChange) {
      onChange(inviteState)
    }
  }, [inviteState])

  const setToogleToKey = key => {
    setToogles(enabledToogleToKey(key, toogles))
  }

  const getResource = key => {
    if (
      key === 'inviteTitle' ||
      key === 'inviteLegend' ||
      key === 'url' ||
      key === 'legal' ||
      key === 'footer'
    ) {
      return (
        <>
          <TextInvite
            key={key}
            callBackItemUpdated={inviteItem => {
              setInviteState({
                ...inviteState,
                [key]: inviteItem,
              })
            }}
            text={inviteState[key].text}
            font={inviteState[key].font}
            editControl={!previewMode}
            showToolbar={!previewMode}
            toogle={toogles[key]}
            onChangeToogle={value => {
              if (value) {
                setToogleToKey(key)
              }
            }}
            border={key === 'footer' ? '1px solid black' : null}
            padding={key === 'footer' ? '5px' : null}
          />
        </>
      )
    } else if (key === 'guests') {
      const { items, font, colsNumber } = inviteState[key]
      return (
        <HostList
          items={items}
          font={font}
          colsNumber={colsNumber}
          showToolbar={!previewMode}
          toogle={toogles[`${key}.items`]}
          onChangeToogle={value => {
            if (value) {
              setToogleToKey(`${key}.items`)
            }
          }}
          callBackItemUpdated={inviteItem =>
            setInviteState({
              ...inviteState,
              [key]: {
                ...inviteItem,
                colsNumber: inviteItem.colsNumber || 0,
                isDragDisabled: inviteState[key].isDragDisabled,
              },
            })
          }
        />
      )
    } else if (key === 'hostList') {
      const { items, font, colsNumber } = inviteState[key]
      return (
        <HostList
          items={items}
          font={font}
          colsNumber={colsNumber}
          showToolbar={!previewMode}
          toogle={toogles[`${key}.items`]}
          onChangeToogle={value => {
            if (value) {
              setToogleToKey(`${key}.items`)
            }
          }}
          callBackItemUpdated={inviteItem =>
            setInviteState({
              ...inviteState,
              [key]: {
                ...inviteItem,
                colsNumber: inviteItem.colsNumber || 0,
                isDragDisabled: inviteState[key].isDragDisabled,
              },
            })
          }
        />
      )
    } else if (key === 'tier') {
      const { title, suggestedDonation } = inviteState[key]
      return (
        <>
          <TextInvite
            text={suggestedDonation.text}
            font={suggestedDonation.font}
            callBackItemUpdated={inviteItem =>
              setInviteState({
                ...inviteState,
                [key]: {
                  suggestedDonation: inviteItem,
                  title,
                  isDragDisabled: inviteState[key].isDragDisabled,
                },
              })
            }
            editControl={!previewMode}
            showToolbar={!previewMode}
            toogle={toogles[`${key}.suggestedDonation`]}
            onChangeToogle={value => {
              if (value) {
                setToogleToKey(`${key}.suggestedDonation`)
              }
            }}
          />
          <TextInvite
            text={title.text}
            font={title.font}
            callBackItemUpdated={inviteItem =>
              setInviteState({
                ...inviteState,
                [key]: {
                  title: inviteItem,
                  suggestedDonation,
                  isDragDisabled: inviteState[key].isDragDisabled,
                },
              })
            }
            editControl={!previewMode}
            showToolbar={!previewMode}
            toogle={toogles[`${key}.title`]}
            onChangeToogle={value => {
              if (value) {
                setToogleToKey(`${key}.title`)
              }
            }}
          />
        </>
      )
    } else if (key === 'tierList') {
      const { items, font } = inviteState[key]
      return (
        <>
          <HostList
            items={items}
            font={font}
            showToolbar={!previewMode}
            toogle={toogles[`${key}.items`]}
            onChangeToogle={value => {
              if (value) {
                setToogleToKey(`${key}.items`)
              }
            }}
            disableSelect
          />
        </>
      )
    } else if (key === 'location') {
      const { d1sLine, d2sLine } = inviteState[key]
      return (
        <>
          <TextInvite
            text={d1sLine.text}
            font={d1sLine.font}
            callBackItemUpdated={inviteItem =>
              setInviteState({
                ...inviteState,
                [key]: {
                  d1sLine: inviteItem,
                  ...(d2sLine && { d2sLine }),
                  isDragDisabled: inviteState[key].isDragDisabled,
                },
              })
            }
            editControl={!previewMode}
            showToolbar={!previewMode}
            toogle={toogles[`${key}.d1sLine`]}
            onChangeToogle={value => {
              if (value) {
                setToogleToKey(`${key}.d1sLine`)
              }
            }}
          />
          {d2sLine && (
            <TextInvite
              text={d2sLine.text}
              font={d2sLine.font}
              callBackItemUpdated={inviteItem =>
                setInviteState({
                  ...inviteState,
                  [key]: {
                    d2sLine: inviteItem,
                    d1sLine,
                    isDragDisabled: inviteState[key].isDragDisabled,
                  },
                })
              }
              editControl={!previewMode}
              showToolbar={!previewMode}
              toogle={toogles[`${key}.d2sLine`]}
              onChangeToogle={value => {
                if (value) {
                  setToogleToKey(`${key}.d2sLine`)
                }
              }}
            />
          )}
        </>
      )
    } else if (key === 'dateAndTime') {
      const { date, time } = inviteState[key]
      return (
        <>
          <TextInvite
            text={date.text}
            font={date.font}
            callBackItemUpdated={inviteItem => {
              setInviteState({
                ...inviteState,
                [key]: {
                  date: inviteItem,
                  time,
                  isDragDisabled: inviteState[key].isDragDisabled,
                },
              })
            }}
            editControl={!previewMode}
            showToolbar={!previewMode}
            toogle={toogles[`${key}.date`]}
            onChangeToogle={value => {
              if (value) {
                setToogleToKey(`${key}.date`)
              }
            }}
          />
          <TextInvite
            text={time.text}
            font={time.font}
            callBackItemUpdated={inviteItem =>
              setInviteState({
                ...inviteState,
                [key]: {
                  time: inviteItem,
                  date,
                  isDragDisabled: inviteState[key].isDragDisabled,
                },
              })
            }
            editControl={!previewMode}
            showToolbar={!previewMode}
            toogle={toogles[`${key}.time`]}
            onChangeToogle={value => {
              if (value) {
                setToogleToKey(`${key}.time`)
              }
            }}
          />
        </>
      )
    } else if (key === 'logo') {
      return (
        <div className="text-center">
          <InviteImage
            url={inviteState[key].url}
            editControl={!previewMode}
            callBackItemUpdated={inviteItem => {
              setInviteState({
                ...inviteState,
                [key]: {
                  url: inviteItem.url,
                  isDragDisabled: inviteState[key].isDragDisabled,
                },
              })
            }}
          />
        </div>
      )
    }
  }

  return (
    <>
      <div
        className="d-flex justify-content-center"
        style={{ maxWidth: '950px' }}
      >
        <DragList
          dragComponent={item => (
            <div style={{ position: 'relative' }}>
              {getResource(item.content)}
            </div>
          )}
          dragDataColumns={generateDragDataColumns(inviteState, previewMode)}
          listGridClassName={listGridClassName}
          droppableClassName={droppableClassName}
          dragItemClassName={dragItemClassName}
          listItemContainerClassName={listItemContainerClassName}
          dragDropContainerClassName={dragDropContainerClassName}
          onDragResult={dragResult => {
            setInviteState(orderInviteFormat(inviteState, dragResult))
          }}
        />
      </div>
    </>
  )
}

Invite.propTypes = {
  listGridClassName: PropTypes.string,
  droppableClassName: PropTypes.string,
  dragItemClassName: PropTypes.string,
  listItemContainerClassName: PropTypes.string,
  dragDropContainerClassName: PropTypes.string,
  onChange: PropTypes.func,
  previewMode: PropTypes.bool,
  format: PropTypes.object.isRequired,
}
export default Invite
