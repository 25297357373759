import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface EnvJSONSliceState {
  BASE_ENDPOINT?: string
  CHAT_BASE_ENDPOINT?: string
  CHAT_SOCKET_ENDPOINT?: string
  GOOGLE_CLIENT_ID?: string
  GOOGLE_CLIENT_SECRET?: string
  GOOGLE_REDIRECT_URI?: string
  LINKEDIN_CLIENTID?: string
  ZOOM_OAUTH_ENABLED?: boolean
  ZOOM_SDK_CLIENT_ID?: string
  AZURE_CLIENT_ID?: string
  AZURE_TENANT_ID?: string
  AZURE_CLIENT_SECRET?: string
  AZURE_AUTHORITY?: string
  AZURE_REDIRECT_URI?: string
  SECRET_PASS?: string
  DD_APP_ID?: string
  DD_CLIENT_TOKEN?: string
  DD_SITE?: string
  DD_SERVICE?: string
  DD_ENV?: string
  NULLSTONE_VERSION?: string
}

export const initialState: EnvJSONSliceState = {
  BASE_ENDPOINT: '',
  CHAT_BASE_ENDPOINT: '',
  CHAT_SOCKET_ENDPOINT: '',
  GOOGLE_CLIENT_ID: '',
  GOOGLE_CLIENT_SECRET: '',
  GOOGLE_REDIRECT_URI: '',
  LINKEDIN_CLIENTID: '',
  ZOOM_OAUTH_ENABLED: false,
  ZOOM_SDK_CLIENT_ID: '',
  AZURE_CLIENT_ID: '',
  AZURE_TENANT_ID: '',
  AZURE_CLIENT_SECRET: '',
  AZURE_AUTHORITY: '',
  AZURE_REDIRECT_URI: '',
  SECRET_PASS: '',
  DD_APP_ID: '',
  DD_CLIENT_TOKEN: '',
  DD_SITE: '',
  DD_SERVICE: '',
  DD_ENV: '',
  NULLSTONE_VERSION: '',
}

const envJSONSlice = createSlice({
  name: 'envJSON',
  initialState,
  reducers: {
    loadEnvJSON: (
      state,
      action: PayloadAction<EnvJSONSliceState, string, never>
    ) => {
      state.BASE_ENDPOINT = action.payload.BASE_ENDPOINT
      state.CHAT_BASE_ENDPOINT = action.payload.CHAT_BASE_ENDPOINT
      state.CHAT_SOCKET_ENDPOINT = action.payload.CHAT_SOCKET_ENDPOINT
      state.GOOGLE_CLIENT_ID = action.payload.GOOGLE_CLIENT_ID
      state.GOOGLE_CLIENT_SECRET = action.payload.GOOGLE_CLIENT_SECRET
      state.GOOGLE_REDIRECT_URI = action.payload.GOOGLE_REDIRECT_URI
      state.LINKEDIN_CLIENTID = action.payload.LINKEDIN_CLIENTID
      state.ZOOM_OAUTH_ENABLED = action.payload.ZOOM_OAUTH_ENABLED
      state.ZOOM_OAUTH_ENABLED = action.payload.ZOOM_OAUTH_ENABLED
      state.ZOOM_SDK_CLIENT_ID = action.payload.ZOOM_SDK_CLIENT_ID
      state.AZURE_CLIENT_ID = action.payload.AZURE_CLIENT_ID
      state.AZURE_TENANT_ID = action.payload.AZURE_TENANT_ID
      state.AZURE_CLIENT_SECRET = action.payload.AZURE_CLIENT_SECRET
      state.AZURE_AUTHORITY = action.payload.AZURE_AUTHORITY
      state.AZURE_REDIRECT_URI = action.payload.AZURE_REDIRECT_URI
      state.SECRET_PASS = action.payload.SECRET_PASS
      state.DD_APP_ID = action.payload.DD_APP_ID
      state.DD_CLIENT_TOKEN = action.payload.DD_CLIENT_TOKEN
      state.DD_SITE = action.payload.DD_SITE
      state.DD_SERVICE = action.payload.DD_SERVICE
      state.DD_ENV = action.payload.DD_ENV
      state.NULLSTONE_VERSION = action.payload.NULLSTONE_VERSION
    },
  },
})

export const { loadEnvJSON } = envJSONSlice.actions

export default envJSONSlice.reducer
