//import { propOr } from 'ramda'
import { FC, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserEmail, getUserId } from '../../utils/state/selectors'

import GaugeGraph from '../GaugeGraph/GaugeGraph'
import Card from '../ui-elements/Card'

import ApiEvent from '../../api/event'

import styled from 'styled-components'
import GaugeGraphQuantity from '../GaugeGraph/GaugeGraphQuantity'
import { BundlerTopLines, YourStatusProps } from './type'
import { upToPercentage } from '../TopLines/utilts'

const GraphsGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  width: 90%;
  > div {
    width: 10%;
  }
`

const YourStatus: FC<YourStatusProps> = ({ style }) => {
  const [bundlerTopLines, setBundlerTopLines] = useState<BundlerTopLines>()
  const userId = useSelector(getUserId)
  const useEmail = useSelector(getUserEmail)

  useEffect(() => {
    let isMounted = true
    ApiEvent.getBundlerTopLinesMetric(userId, useEmail)
      .then(res => (isMounted ? setBundlerTopLines(res) : null))
      .catch(err =>
        console.error('error fetching Bundler Toplines totals', err)
      )
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <Card title="YOUR STATUS" style={style}>
      {bundlerTopLines && (
        <GraphsGrid>
          <GaugeGraph
            color="#11273C"
            percent={upToPercentage(
              bundlerTopLines.raisedToDate,
              parseFloat(bundlerTopLines.raisedToDatePercentage)
            )}
            amount={bundlerTopLines.raisedToDate}
            label="Raised to date"
          />
          <GaugeGraphQuantity
            color="#DCE1E5"
            percent={0}
            quantity={bundlerTopLines.donors}
            label="Donors"
          />
          <GaugeGraphQuantity
            color="#DCE1E5"
            percent={0}
            quantity={bundlerTopLines.events}
            label="Events"
          />
          <GaugeGraphQuantity
            color="#DCE1E5"
            percent={0}
            quantity={bundlerTopLines.ask}
            label="Asks"
          />
          <GaugeGraphQuantity
            color="#DCE1E5"
            percent={0}
            quantity={bundlerTopLines.daysLeft}
            label="Days left"
          />
          <GaugeGraphQuantity
            color="#DB8D1A"
            percent={100}
            quantity={bundlerTopLines.nationalRank}
            label="National rank"
          />
          <GaugeGraphQuantity
            color="#DB8D1A"
            percent={100}
            quantity={bundlerTopLines.stateRank}
            label="State rank"
          />
        </GraphsGrid>
      )}
    </Card>
  )
}

export default YourStatus
