import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Stack, Table } from 'react-bootstrap'
import { BsChevronRight, BsTrash } from 'react-icons/bs'
import { propOr } from 'ramda'
import { useState } from 'react'
import GuestFormModal from './GuestFormModal'
import guestImg from '../../assets/illustrations/guests.png'
import SortIconAction from '../ui-elements/SortIconAction'

const OpacityWrapper = styled.div`
  opacity: var(--opacity, 1);
`

const StyledButtonTd = styled.td`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  width: 2.5rem;
`

const GuestRow = ({ guest, onRemove, onEdit }) => {
  const [isMouseOver, setMouseOver] = useState(false)
  const mouseOverHandler = () => setMouseOver(true)
  const mouseOutHandler = () => setMouseOver(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const guestId = propOr('', '_id', guest)
  const guestName = propOr('', 'guestName', guest)
  return (
    <tr onMouseEnter={mouseOverHandler} onMouseLeave={mouseOutHandler}>
      <td>{guestName}</td>
      <StyledButtonTd>
        <OpacityWrapper
          style={{
            '--opacity': isMouseOver ? 1 : 0,
          }}
        >
          <Stack gap={2} direction="horizontal">
            <Button
              onClick={() => setShowEditModal(true)}
              variant="outline-secondary"
            >
              <BsChevronRight />
            </Button>
            <Button
              onClick={() => onRemove(guestId)}
              variant="outline-secondary"
            >
              <BsTrash />
            </Button>
          </Stack>
        </OpacityWrapper>
        <GuestFormModal
          onEdit={onEdit}
          onClose={() => {
            setShowEditModal(false)
            setMouseOver(false)
          }}
          show={showEditModal}
          guest={guest}
          isEdit
        />
      </StyledButtonTd>
    </tr>
  )
}
GuestRow.propTypes = {
  guest: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

const TableWrapper = styled.div`
  > table > tbody > tr > td {
    vertical-align: middle;
    > img,
    > span {
      display: inline-block;
      line-height: 1rem;
      vertical-align: middle;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CenterWrapper = styled.div`
  text-align: center;
  padding-bottom: 1rem;
`

const TextSpan = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: #6c757d;
`
const StyledImg = styled.img`
  height: 8.75rem;
`

const GuestsTable = ({ guests, onEdit, onRemove }) => {
  return (
    <div>
      {guests.length > 0 ? (
        <TableWrapper>
          <Table hover>
            <thead>
              <tr>
                <th>
                  Name{' '}
                  <SortIconAction
                    setFunctState={() => null}
                    stateValue={0}
                    refresh={() => null}
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {guests?.map(guest => (
                <GuestRow
                  key={`guest-row-${guest['_id']}`}
                  guest={guest}
                  onEdit={onEdit}
                  onRemove={onRemove}
                />
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      ) : (
        <Wrapper>
          <CenterWrapper>
            <StyledImg src={guestImg} />
          </CenterWrapper>
          <CenterWrapper>
            <TextSpan>You still have not created any tier</TextSpan>
          </CenterWrapper>
        </Wrapper>
      )}
    </div>
  )
}

GuestsTable.propTypes = {
  guests: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default GuestsTable
