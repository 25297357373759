import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { GoogleLoginButton as GoogleButton } from 'react-social-login-buttons'
import constants from '../../config/constants'
import {
  finishLoginProcess,
  googleLoginAction,
  startLoginProcess,
} from '../../redux/actions/authentication'
import GoogleApi from '../../api/google'
import { useScript } from '../ZoomCall/useScript'
import { useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import types from '../../redux/types/types'

const GoogleLoginButton = () => {
  const dispatch = useDispatch()
  const [oAuthClient, setOAuthClient] = useState()
  const isLogingIn = useSelector(
    pathOr(false, ['authentication', 'isLogingIn'])
  )
  const { google } = useScript(
    'https://accounts.google.com/gsi/client',
    'google'
  )

  const handleGoogleSignIn = data => {
    dispatch(startLoginProcess())
    GoogleApi.getTokens(data.code)
      .then(({ idToken, refreshToken }) =>
        GoogleApi.getUserProfile(idToken, refreshToken)
      )
      .then(profile => dispatch(googleLoginAction(profile)))
      .catch(() =>
        dispatch({
          type: types.LOGIN_ERROR,
          payload: 'Cannot validate user credentials',
        })
      )
      .finally(dispatch(finishLoginProcess()))
  }

  useEffect(() => {
    if (google) {
      const oAuth2Client = google.accounts.oauth2.initCodeClient({
        client_id: constants.GOOGLE_CLIENT_ID(),
        scope: constants.GOOGLE_SCOPES,
        access_type: 'offline',
        ux_mode: 'popup',
        callback: handleGoogleSignIn,
      })
      setOAuthClient(oAuth2Client)
    }
  }, [google])

  return (
    <div>
      <GoogleButton
        type="light"
        className="w-100 d-flex flex-row flex-nowrap justify-content-center align-items-center"
        text="Google"
        onClick={() => {
          oAuthClient.requestCode()
        }}
        disabled={isLogingIn}
      ></GoogleButton>
    </div>
  )
}

export default GoogleLoginButton
