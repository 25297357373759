import ApiOrg from '../../api/organization'
import * as R from 'ramda'
import { IUser } from '../../api/interfaces'
import APIBundlerId from '../../api/bundlerIds'
import axios from 'axios'
const isDefined = (x: string) => !(R.isNil(x) || R.isEmpty(x))

export const getWinRedUrl = async (
  orgId: string,
  name: string,
  user?: IUser
) => {
  let queryParams = `event_id=${name}`
  try {
    const response = await APIBundlerId.getUserBundlerId(user?._id)
    if (response.bundlerId) {
      queryParams += `&bundler_id=${response.bundlerId}`
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.log(error.response?.data)
    }
  }
  const org = await ApiOrg.getOrganization(orgId)
  const donationLink = org.routeDonationLink

  return isDefined(donationLink)
    ? `${org.routeDonationLink}?${queryParams}`
    : null
}
