import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table'
import {
  BsChevronLeft,
  BsChevronRight,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
} from 'react-icons/bs'

import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import Placeholder from 'react-bootstrap/Placeholder'

const TableFooterDiv = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 1rem;
`

import { PropTypes } from 'prop-types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const PAGE_SIZES = [25, 50, 100]

const OpacityWrapper = styled.div`
  opacity: var(--opacity, 1);
`
const ContactsTable = ({
  data,
  setData,
  columns,
  isLoading,
  pagination,
  setPagination,
  currentPage,
  setCurrentPage,
  pageCount,
  pageSize,
  setPageSize,
  showGoToPage = true,
  showPageSize = true,
  expandable = true,
}) => {
  const handleRowOnClick = contact => {
    navigate(`/contact/${contact.contact_person_id}`)
  }

  const navigate = useNavigate()
  const table = useReactTable({
    columns,
    data,
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setData(old =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          })
        )
      },
    },
  })

  const [currentContactId, setCurrentContactId] = useState(false)

  return (
    <>
      <div>
        <table
          {...{
            style: {
              width: table.getTotalSize(),
            },
          }}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      {...{
                        className: 'th',
                        style: {
                          width: header.getSize(),
                          textAlign: 'center',
                          border: 'solid black 1px',
                        },
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          data-tooltip-id={`table-header-tooltip-${header.id}`}
                        >
                          <div
                            {...{
                              style: {
                                display: 'flex',
                                justifyContent: 'center',
                                cursor: header.column.getCanSort()
                                  ? 'pointer'
                                  : 'none',
                              },
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' ↑',
                              desc: ' ↓',
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                        </div>
                      )}
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `resizer ${
                            header.column.getIsResizing() ? 'isResizing' : ''
                          }`,
                        }}
                      />
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length
              ? table.getRowModel().rows.map(row => {
                  return (
                    <tr
                      key={row.id}
                      onMouseEnter={() => setCurrentContactId(row.original._id)}
                      onMouseLeave={() => setCurrentContactId(null)}
                    >
                      {row.getVisibleCells().map(cell => {
                        return (
                          <td
                            key={cell.id}
                            onDoubleClick={() => handleRowOnClick(row.original)}
                            style={{
                              width: cell.column.getSize(),
                              border: 'solid black 1px',
                            }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        )
                      })}
                      <td>
                        <OpacityWrapper
                          style={{
                            opacity:
                              currentContactId === row.original._id ? 1 : 0,
                          }}
                        >
                          {expandable && (
                            <Button
                              onClick={() => handleRowOnClick(row.original)}
                              variant="outline-secondary"
                              aria-label="go-to-contact-details-button"
                            >
                              <BsChevronRight />
                            </Button>
                          )}
                        </OpacityWrapper>
                      </td>
                    </tr>
                  )
                })
              : [...Array(table.getState().pagination.pageSize)].map(
                  (item, idx) => {
                    if (idx === 0) {
                      return (
                        <tr key={item}>
                          <td
                            colSpan={9}
                            style={{
                              padding: '8px',
                              // border: 'solid black 1px',
                            }}
                          >
                            {isLoading ? 'Loading ...' : 'No Records'}
                          </td>
                        </tr>
                      )
                    } else {
                      return (
                        <tr key={item}>
                          <td
                            colSpan={9}
                            style={{
                              padding: '8px',
                              // border: 'solid black 1px',
                            }}
                          >
                            <Placeholder size="lg" />
                          </td>
                        </tr>
                      )
                    }
                  }
                )}
          </tbody>
        </table>
        <TableFooterDiv>
          <div className="page-selection">
            {/* <React.Fragment>
                <IndeterminateCheckbox
                  {...{
                    checked: table.getIsAllPageRowsSelected(),
                    indeterminate: table.getIsSomePageRowsSelected(),
                    onChange: table.getToggleAllPageRowsSelectedHandler(),
                  }}
                />
                <div>SELECT ALL PAGE ROWS</div>
              </React.Fragment> */}
          </div>
          <div style={{ display: 'flex', gap: '8px' }}>
            <button
              className="pagination-button"
              onClick={() => {
                if (setCurrentPage) {
                  setCurrentPage(1)
                } else {
                  table.setPageIndex(0)
                }
              }}
              disabled={
                currentPage ? currentPage === 1 : !table.getCanPreviousPage()
              }
            >
              <BsChevronDoubleLeft />
            </button>
            <button
              className="pagination-button"
              onClick={() => {
                if (setCurrentPage && currentPage) {
                  currentPage > 1 ? setCurrentPage(currentPage - 1) : null
                } else {
                  table.previousPage()
                }
              }}
              disabled={
                currentPage ? currentPage === 1 : !table.getCanPreviousPage()
              }
            >
              <BsChevronLeft />
            </button>
            <button
              className="pagination-button"
              onClick={() => {
                if (setCurrentPage && currentPage && pageCount) {
                  currentPage < pageCount
                    ? setCurrentPage(currentPage + 1)
                    : null
                } else {
                  table.nextPage()
                }
              }}
              disabled={
                currentPage && pageCount
                  ? currentPage === pageCount
                  : !table.getCanNextPage()
              }
            >
              <BsChevronRight />
            </button>
            <button
              className="pagination-button"
              onClick={() => {
                if (setCurrentPage && currentPage && pageCount) {
                  currentPage < pageCount ? setCurrentPage(pageCount) : null
                } else {
                  table.setPageIndex(table.getPageCount() - 1)
                }
              }}
              disabled={
                currentPage && pageCount
                  ? currentPage === pageCount
                  : !table.getCanNextPage()
              }
            >
              <BsChevronDoubleRight />
            </button>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <div>PAGE&nbsp;</div>
              {currentPage
                ? currentPage
                : table.getState().pagination.pageIndex + 1}{' '}
              of&nbsp;
              {pageCount ? pageCount : table.getPageCount()}
            </span>
            {showGoToPage ? (
              <span>
                | GO TO PAGE:&nbsp;
                <input
                  type="number"
                  style={{ width: '60px' }}
                  defaultValue={
                    currentPage && currentPage === 0
                      ? currentPage + 1
                      : currentPage
                      ? currentPage
                      : table.getState().pagination.pageIndex + 1
                  }
                  onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    // Enable delete and replace single-digit value
                    if (setCurrentPage) {
                      if (Number(e.target.value) <= pageCount) {
                        setCurrentPage(Number(e.target.value))
                      }
                    } else if (page !== 0) table.setPageIndex(page)
                  }}
                />
              </span>
            ) : null}
            {showPageSize ? (
              <>
                <select
                  value={
                    pageSize ? pageSize : table.getState().pagination.pageSize
                  }
                  onChange={e => {
                    const sizeValue = Number(e.target.value)
                    if (setPageSize) {
                      setPageSize(sizeValue)
                    } else {
                      table.setPageSize(sizeValue)
                    }
                  }}
                >
                  {PAGE_SIZES.map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize}
                    </option>
                  ))}
                </select>
                <div className="my-auto">records per page</div>
              </>
            ) : null}
          </div>
        </TableFooterDiv>
      </div>
    </>
  )
}

ContactsTable.propTypes = {
  data: PropTypes.array,
  setData: PropTypes.func,
  columns: PropTypes.array,
  isLoading: PropTypes.bool,
  showGoToPage: PropTypes.bool,
  showPageSize: PropTypes.bool,
  expandable: PropTypes.bool,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
}

export default ContactsTable
