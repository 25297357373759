import moment from 'moment'
import React from 'react'
import { Modal } from 'react-bootstrap'
import NewTaskPanel from './NewTaskPanel'

const NewEventProvider = () => {
  return (
    <Modal.Body>
      <NewTaskPanel
        task={{
          title: '',
          fromDate: moment(),
          toDate: moment().add(1, 'hours'),
          duration: 0,
          notes: '',
        }}
        close={() => console.log('close')}
        save={() => console.log('save')}
      />
    </Modal.Body>
  )
}

export default NewEventProvider
