import { prop } from 'ramda'
import constants from '../config/constants'
import axios from './axios'
import { DeleteKnowledge, IDonation, IDonor, IUser } from './interfaces'

const transactionTypes = {
  DEPOSIT: 'deposit',
  PLEDGE: 'pledge',
  LEADER: 'leader',
  VIP: 'vip',
  PROSPECT: 'prospect',
  CONTACTED: 'contacted',
  ASKED: 'asked',
}

const getDonationsByBundler = (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  isAllowed = false
) => {
  let endpoint = `${constants.DONATION_DATA_ENDPOINT()}/bybundler`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios({
    url,
    method: 'GET',
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}

const getBundlerDonationTotals = (
  bundlerId: string,
  organizationId = sessionStorage.getItem('Org-Session') || '',
  isAllowed = false
) => {
  let endpoint = `${constants.DONATIONS_ENDPOINT()}/bundlerId/${bundlerId}`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios({
    url,
    method: 'GET',
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}

// getTransactions:: () -> Promise [DonationTransaction]
const getTransactions = async (
  organizationId = sessionStorage.getItem('Org-Session') || ''
) => {
  const endpoint = constants.DONATIONS_ENDPOINT()
  const url = `${endpoint}`
  const docs = await axios<IDonation[]>({
    url,
    method: 'GET',
    headers: {
      organizationId,
    },
  }).then(prop('data'))
  return docs
}

// getEventTransactions:: eventId -> Promise [DonationTransaction]
const getEventTransactions = async (eventId: string, isAllowed = false) => {
  let endpoint = constants.DONATIONS_ENDPOINT()
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')
  const url = `${endpoint}/eventId/${eventId}`
  const docs = await axios<IDonation[]>({
    url,
    method: 'GET',
  }).then(prop('data'))
  return docs
}

// createTransaction:: (TransactionData, Event, User) -> Promise DonationTransaction
const createTransaction = async (data: Partial<IDonation>, user: IUser) => {
  const userId = prop('_id', user)
  const donorId = prop('donorId', data)
  const amount = prop('amount', data)
  const type = prop('type', data)
  const eventId = prop('eventId', data)
  const bundlers = [userId]
  const newTransaction = {
    eventId,
    bundlers,
    donorId,
    type,
    amount,
    organizationId: sessionStorage.getItem('Org-Session'),
  }
  const endpoint = constants.DONATIONS_ENDPOINT()
  const url = `${endpoint}`
  return axios({
    url,
    method: 'POST',
    data: { donation: newTransaction },
  }).then(prop('data'))
}

// updateTransaction:: (TransactionData, Event, User) -> Promise DonationTransaction
const updateTransaction = async (donation: Partial<IDonation>) => {
  const donationId = prop('_id', donation)
  const endpoint = constants.DONATIONS_ENDPOINT()
  const url = `${endpoint}/${donationId}`
  return axios({
    url,
    method: 'PUT',
    data: { donation },
  }).then(prop('data'))
}

const filterDonations = (query: unknown[] = [], isAllowed = false) => {
  let endpoint = `${constants.DONATIONS_ENDPOINT()}/filter`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios<IDonation[]>({
    url,
    method: 'POST',
    data: { query },
  }).then(prop('data'))
}

const getEventParticipants = async (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  event: string,
  donation: string
) => {
  const endpoint = constants.PARTICIPANTS_ENDPOINT()
  let query = event ? `event=${event}` : ''
  query += donation
    ? query === ''
      ? `donation=${donation}`
      : `&donation=${donation}`
    : ''
  const url = `${endpoint}/?${query}`
  return axios({
    url,
    method: 'GET',
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}

const deleteDonation = (donationId: string, isAllowed = false) => {
  let endpoint = `${constants.DONATIONS_ENDPOINT()}/${donationId}`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios<DeleteKnowledge>({
    url,
    method: 'DELETE',
  }).then(prop('data'))
}

export default {
  transactionTypes,
  getTransactions,
  getEventTransactions,
  getEventParticipants,
  createTransaction,
  updateTransaction,
  filterDonations,
  getDonationsByBundler,
  getBundlerDonationTotals,
  deleteDonation,
}
