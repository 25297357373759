import { nth, omit, pathOr, prop, propOr } from 'ramda'
import constants from '../config/constants'
import axios from './axios'
import AttendeeApi from './attendee'
import TaskApi from './task'

// createEvent:: (EventFormData, User) -> Promise Event
const createEvent = async (formData, user) => {
  const eventData = propOr({}, 'eventData', formData)
  const organizationId = propOr(
    sessionStorage.getItem('Org-Session'),
    'organizationId',
    formData
  )
  const tiers = propOr({}, 'tiers', formData)
  const attendees = pathOr({}, ['aggregates', 'attendees'], formData)
  const tasks = pathOr({}, ['aggregates', 'tasks'], formData)
  const userId = prop('_id', user)
  const newEvent = {
    userId,
    ...eventData,
    tiers,
    organizationId,
  }
  const endpoint = constants.EVENTS_ENDPOINT()
  const url = `${endpoint}`
  const response = await axios({
    url,
    method: 'POST',
    data: JSON.stringify({ event: newEvent }),
  }).then(prop('data'))

  const eventId = prop('_id', response)
  await AttendeeApi.addEventAttendees(eventId, attendees)
  await TaskApi.createEventTasks(tasks, user, eventId)

  return { ...newEvent, _id: eventId }
}

// updateEvent:: (EventFormsData, User) -> Promise Event
const updateEvent = async (payload, user) => {
  //const db = await initDB()
  const eventData = propOr({}, 'eventData', payload)
  const tiers = propOr({}, 'tiers', payload)
  const attendees = pathOr({}, ['aggregates', 'attendees'], payload)
  const tasks = pathOr({}, ['aggregates', 'tasks'], payload)
  const organizationId = propOr(
    sessionStorage.getItem('Org-Session'),
    'organizationId',
    payload
  )

  //const deletedTasks = pathOr({}, ['aggregates', 'deletedTasks'], payload)
  const deletedGuests = pathOr({}, ['aggregates', 'deletedGuests'], payload)
  const eventId = prop('_id', payload)
  const userId = prop('_id', user)
  const updates = {
    userId,
    ...eventData,
    tiers,
    organizationId,
  }

  //await put(db.events(), updatedEvent)
  const endpoint = constants.EVENTS_ENDPOINT()
  const url = `${endpoint}/${eventId}`
  await axios({
    url,
    method: 'PUT',
    data: JSON.stringify({ eventId, updates }),
  }).then(prop('data'))

  await AttendeeApi.addEventAttendees(eventId, attendees)

  const newTasks = []
  const updatedTasks = []
  tasks.forEach(task => {
    const taskData = omit(['isNew'], task)
    if (task.isNew) newTasks.push({ ...taskData, eventId })
    else updatedTasks.push({ ...taskData, eventId })
  })
  if (newTasks.length) await TaskApi.createEventTasks(newTasks, user)
  if (updatedTasks.length) await TaskApi.updateTasks(updatedTasks, user)

  await AttendeeApi.deleteAttendeesByIds(deletedGuests)

  //const deleteTasksResponse = await TaskApi.deleteTasksByIds(deletedTasks)
  /* console.log('Event updated', {
    eventResponse,
    updatedEvent,
    attendeeResponse,
    tasksResponse,
    //deleteTasksResponse,
    deleteGuestResponse,
  }) */

  const result = { _id: eventId, ...updates }
  return result
}

// getEvent:: String -> Promise Event
const getEvent = async (id, isAllowed = false) => {
  let endpoint = constants.EVENTS_ENDPOINT()
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')
  const params = `/${encodeURIComponent(id || '')}`
  const url = `${endpoint}${params}`
  return axios({
    url,
    method: 'GET',
  })
    .then(prop('data'))
    .then(nth(0))
}

// getEvents:: () -> Promise [Event]
const getEvents = async (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  isAllowed = false
) => {
  let url = constants.EVENTS_ENDPOINT()
  if (isAllowed) url = url.replace('/api/benjamin/', '/api/allowed/benjamin/')
  return axios({
    url,
    method: 'GET',
    headers: {
      //Ensure we don't return events for all orgs if org ID is not present
      organizationID: organizationId || 'NONE',
    },
  }).then(prop('data'))
}

// getEvents:: () -> Promise [Event]
const getUpcomingEvents = async (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  limit = 0,
  userId = '',
  isAllowed = false
) => {
  let userIdParam = userId ? `/userid/${userId}` : ''
  let limitQuery = limit ? `?limit=${limit}` : ''
  let url = `${constants.EVENTS_ENDPOINT()}/upcoming${userIdParam}${limitQuery}`
  if (isAllowed) url = url.replace('/api/benjamin/', '/api/allowed/benjamin/')
  return axios({
    url,
    method: 'GET',
    headers: {
      //Ensure we don't return events for all orgs if org ID is not present
      organizationID: organizationId || 'NONE',
    },
  }).then(prop('data'))
}

// getEvents:: () -> Promise [Event]
const getEventsByUser = async (
  user,
  organizationId = sessionStorage.getItem('Org-Session') || ''
) => {
  const userId = propOr('', 'email', user)
  const endpoint = constants.EVENTS_ENDPOINT()
  const params = `/userId/${encodeURIComponent(userId || '')}`
  const url = `${endpoint}${params}`
  return axios({
    url,
    method: 'GET',
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}

// getEvents:: () -> Promise [Event]
const getEventsByState = async (
  state,
  organizationId = sessionStorage.getItem('Org-Session') || ''
) => {
  const endpoint = constants.EVENTS_ENDPOINT()
  const params = `/state/${encodeURIComponent(state)}`
  const url = `${endpoint}${params}`

  return axios({
    url,
    method: 'GET',
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}

/* compute donations totals
 *    if eventId is set, the totals are computed only for that event
 */
//  getConsolidatedSummary:: (User, (String | Nil)) -> {
//    eventId:          String,
//    goal:             Number,
//    totals: {
//      deposited:      Number,
//      pledged:        Number,
//      remaining:      Number,
//      raised:         Number,
//      outstanding:    Number,
//    }
//    userTotals: {
//      deposited:      Number,
//      pledged:        Number,
//      remaining:      Number,
//      raised:         Number,
//      outstanding:    Number,
//    }
//    antendees: {
//      count:          Number,
//      vipCount:       Number,
//      guestCount:     Number,
//    }
//    tasks: {
//      count:          Number,
//      done:           Number,
//    }
//  }

const getConsolidatedSummary = async (
  user,
  eventId = null,
  organizationId = sessionStorage.getItem('Org-Session') || '',
  isAllowed = false
) => {
  const userId = prop('_id', user)
  const base = `${constants.BASE_ENDPOINT()}${
    isAllowed ? '/api/allowed/benjamin' : '/api/benjamin'
  }/events/consolidated`
  const query = `eventId=${encodeURIComponent(
    eventId || ''
  )}&userId=${encodeURIComponent(userId)}&organizationId=${organizationId}`
  const url = `${base}?${query}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

const getTopLinesMetric = async (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  isAllowed = false
) => {
  const base = `${constants.BASE_ENDPOINT()}${
    isAllowed ? '/api/allowed/benjamin' : '/api/benjamin'
  }/events/topLines`
  const query = `organizationId=${organizationId}`
  const url = `${base}?${query}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

const getBundlerTopLinesMetric = async (
  userId,
  userEmail,
  organizationId = sessionStorage.getItem('Org-Session') || '',
  isAllowed = false
) => {
  const base = `${constants.BASE_ENDPOINT()}${
    isAllowed ? '/api/allowed/benjamain' : '/api/benjamin'
  }/events/bundlerTopLines`
  const query = `organizationId=${organizationId}&userId=${userId}&userEmail=${userEmail}`
  const url = `${base}?${query}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

export default {
  createEvent,
  getEvents,
  getUpcomingEvents,
  getEventsByUser,
  getEventsByState,
  getEvent,
  getConsolidatedSummary,
  updateEvent,
  getTopLinesMetric,
  getBundlerTopLinesMetric,
}
