import { FC, useState } from 'react'
import { ReactMultiEmail } from 'react-multi-email'
import 'react-multi-email/dist/style.css'
import { MultiEmailFieldProps } from './types'

const MultiEmailField: FC<MultiEmailFieldProps> = ({
  emails = [],
  onChange,
  style,
  focus = true,
}) => {
  const [, setFocused] = useState(false)

  return (
    <>
      <ReactMultiEmail
        style={style}
        placeholder="Input your email"
        emails={emails}
        onChange={_emails => {
          onChange(_emails)
        }}
        autoFocus={focus}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          )
        }}
      />
    </>
  )
}
export default MultiEmailField
