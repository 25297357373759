import { BsPlusLg } from 'react-icons/bs'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import OrganizationsTable from '../OrganizationsTable/OrganizationsTable'

const Wrapper = styled.div`
  padding: 1rem 3rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

const AdminOrganizationsPage = () => {
  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.ADMIN_ORGANIZATIONS(true),
  ]
  usePageHeadings('Organizations configuration', breadcrumbs)
  const navigate = useNavigate()

  const handleNewOrgClick = () => navigate('/admin/organizations/create')

  return (
    <Wrapper>
      <div className="w-100 d-flex justify-content-end pb-4">
        <button className="btn btn-secondary" onClick={handleNewOrgClick}>
          <BsPlusLg />
          &nbsp;&nbsp;
          <span>Create organization</span>
        </button>
      </div>
      <OrganizationsTable />
    </Wrapper>
  )
}

AdminOrganizationsPage.propTypes = {}

export default AdminOrganizationsPage
