import { useState } from 'react'
import EditModeControl from '../Edit/EditModeControl'
import PropTypes from 'prop-types'

const ImageInvite = ({ url, editControl, callBackItemUpdated }) => {
  const [urlD, setUrlD] = useState(url)

  const setUpdate = item => {
    if (callBackItemUpdated) {
      callBackItemUpdated(item)
    }
  }

  return (
    <>
      {editControl && (
        <EditModeControl
          callBackSetValue={value => {
            setUpdate({ url: value })
            setUrlD(value)
          }}
          changeButtonText="🔍"
        />
      )}
      <img src={urlD} width={400} alt={'logo'} />
    </>
  )
}

ImageInvite.propTypes = {
  url: PropTypes.string.isRequired,
  editControl: PropTypes.bool,
  callBackItemUpdated: PropTypes.func,
}

export default ImageInvite
