import Card from '../ui-elements/Card'
import { useEffect, useState } from 'react'
import { isEmpty } from 'ramda'
import { PropTypes } from 'prop-types'
import { useSelector } from 'react-redux'
import { getUser } from '../../utils/state/selectors'
import { getDonationsForBundler } from '../../api/donations_data'
import IconDonation from '../ui-elements/IconDonation'

const RecentDonations = ({ style }) => {
  const [recentDonations, setRecentDonations] = useState([])
  const userId = useSelector(getUser)._id

  useEffect(() => {
    getDonationsForBundler(undefined, userId).then(res =>
      setRecentDonations(res)
    )
  }, [])

  return (
    <Card title="RECENT DONATIONS" style={style}>
      {isEmpty(recentDonations) ? (
        <div>None</div>
      ) : (
        recentDonations.map(({ name, amount }, index) => (
          <IconDonation
            name={name}
            donation={amount}
            key={`${name}${index}`}
          ></IconDonation>
        ))
      )}
    </Card>
  )
}

RecentDonations.propTypes = {
  style: PropTypes.object,
}

export default RecentDonations
