import styled from 'styled-components'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import Calendar from './Calendar'

const Wrapper = styled.div`
  padding: 0.5rem 1rem 1rem 1rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

const CalendarPage = () => {
  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.CALENDAR(true),
  ]
  usePageHeadings('Calendar', breadcrumbs)

  return (
    <Wrapper>
      <Calendar />
    </Wrapper>
  )
}

export default CalendarPage
