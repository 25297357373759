import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setBreadcrumbs, setPageTitle } from '../redux/actions/ui'

export const usePageHeadings = (title, breadcrumbs) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPageTitle(title))
    dispatch(setBreadcrumbs(breadcrumbs))
    return () => {
      dispatch(setPageTitle(null))
      dispatch(setBreadcrumbs(null))
    }
  }, [title, breadcrumbs])
}
