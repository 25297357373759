import { useMsal } from '@azure/msal-react'
import { prop } from 'ramda'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import constants from '../../config/constants'
import { getAuthProvider } from '../../utils/state/selectors'

const getUserPhotoBlob = accessToken =>
  fetch(`${constants.AZURE_GRAPH_BASE_ENDPOINT}/me/photo/$value`, {
    method: 'GET',
    headers: { Authorization: `Bearer ${accessToken}` },
  })
    .then(response => {
      const reader = response.body.getReader()
      return new ReadableStream({
        start(controller) {
          return pump()
          function pump() {
            return reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close()
                return
              }
              // Enqueue the next data chunk into our target stream
              controller.enqueue(value)
              return pump()
            })
          }
        },
      })
    })
    .then(stream => new Response(stream))
    .then(response => response.blob())
    .then(blob => URL.createObjectURL(blob))

const AzureUserPhoto = () => {
  const authProvider = useSelector(getAuthProvider)
  const [blob, setBlob] = useState(null)
  const { instance, accounts } = useMsal()
  const fallbackPhoto = require('../../assets/profilePictures/unassigned.png')

  useEffect(() => {
    if (['azure', 'graph', 'office365'].includes(authProvider)) {
      instance
        .acquireTokenSilent({
          account: accounts[0],
          scopes: ['User.Read'],
        })
        .then(prop('accessToken'))
        .then(getUserPhotoBlob)
        .then(setBlob)
    }
  }, [])

  return (
    <img
      src={blob === null ? fallbackPhoto : blob}
      referrerPolicy="no-referrer"
    />
  )
}

export default AzureUserPhoto
