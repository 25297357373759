import React, { FC, useEffect, useState } from 'react'
import { Filter, USStatesCitiesType } from '../types'
import USStatesCities from '../../../../assets/datasets/USStatesCities.json'
import { path } from 'ramda'
import { Stack, Form, FormSelect, Button } from 'react-bootstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import EventApi from '../../../../api/event'
import { IEvent } from '../../../../api/interfaces'
import { DONATION_TYPE } from '../../../../api/enums'
import Select, { MultiValue } from 'react-select'
import { FilterContactFormProps } from './types'
import TagApi from '../../../../api/tag'

const _initialState: Filter = {
  state: [],
  city: [],
  birthday: '',
  zipCode: '',
  event: '',
  donationType: '',
  name: [],
  tags: [],
}
const FilterContactForm: FC<FilterContactFormProps> = ({
  initialState,
  contacts,
  onFilterChange,
}) => {
  const [filter, setFilter] = useState<Filter>(initialState || _initialState)
  const [cities, setCities] = useState<string[]>([])
  const [events, setEvents] = useState<IEvent[]>([])
  const [names, setNames] = useState<string[]>([])
  const [tags, setTags] = useState([])

  const fetEvents = () =>
    EventApi.getEvents().then(events => {
      setEvents(events)
    })

  const fetchNames = () =>
    setNames(
      contacts.map(contact => `${contact.first_name} ${contact.last_name}`)
    )

  useEffect(() => {
    const { state, city, ...rest } = filter

    onFilterChange({
      ...rest,
      state: state[0] || '',
      city: city[0] || '',
    })
  }, [filter])

  useEffect(() => {
    fetchTags()
    fetEvents()
    fetchNames()
  }, [])

  useEffect(() => {
    if (filter.state.length) {
      setCities(USStatesCities[filter.state[0] as keyof USStatesCitiesType])
    }
  }, [filter.state])

  const inputChangeHandler = (evt: React.ChangeEvent) => {
    evt.preventDefault()
    evt.stopPropagation()

    const fieldName = path(['target', 'id'], evt)
    const value = path(['target', 'value'], evt)
    setFilter({
      ...filter,
      [fieldName]: value,
    })
  }

  const onChangeTypeHead =
    (key: 'state' | 'city' | 'event') => (selected: string[]) => {
      setFilter({
        ...filter,
        city: filter.state !== selected ? [] : filter.city,
        [key]: selected || '',
      })
    }

  const nameOptions = names.length
    ? names.map(name => ({
        label: name,
        value: name,
      }))
    : []

  type Option = { label: string; value: string }

  const fetchTags = () =>
    TagApi.getTags().then(tags => {
      setTags(tags)
    })
  const selectNameHandler = (newValue: MultiValue<Option>) => {
    const value = newValue.map(v => v.value)
    setFilter({ ...filter, name: value })
  }

  const selectTagHandler = (newValue: MultiValue<Option>) => {
    const value = newValue.map(v => v.value)
    setFilter({
      ...filter,
      tags: value,
    })
  }
  return (
    <>
      <div className="d-flex justify-content-end">
        <Button variant="light btn-sm" onClick={() => setFilter(_initialState)}>
          Reset Filter
        </Button>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplate: '102px / auto auto',
          columnGap: `16px`,
        }}
      >
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">Name</Form.Label>
          <Select
            id="name"
            name="name"
            isMulti
            onChange={selectNameHandler}
            options={nameOptions}
            placeholder="Select By Name"
            value={
              filter.name
                ? filter.name.map(t => ({
                    label: t,
                    value: t,
                  }))
                : null
            }
          />
        </Form.Group>
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">State</Form.Label>
          <Typeahead
            id="states-typeahead-single"
            labelKey="state"
            onChange={selected =>
              onChangeTypeHead('state')(selected as string[])
            }
            options={Object.keys(USStatesCities).sort((a, b) =>
              a < b ? -1 : 1
            )}
            placeholder="Choose a state..."
            selected={filter.state}
          />
        </Form.Group>
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">City</Form.Label>
          <Typeahead
            id="cities-typeahead-single"
            labelKey="city"
            onChange={selected =>
              onChangeTypeHead('city')(selected as string[])
            }
            options={cities}
            placeholder="Choose a city..."
            selected={filter.city}
            disabled={!cities.length}
          />
        </Form.Group>
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">ZIP Code</Form.Label>
          <Form.Control
            autoComplete="off"
            type="text"
            id="zipCode"
            placeholder="Enter ZIP Code"
            value={filter.zipCode}
            onChange={inputChangeHandler}
          />
        </Form.Group>
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">Birthday</Form.Label>
          <Form.Control
            type="date"
            id="birthday"
            value={filter.birthday}
            onChange={inputChangeHandler}
          />
        </Form.Group>
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">Event</Form.Label>
          <FormSelect
            id="event"
            value={filter.event}
            onChange={inputChangeHandler}
          >
            {!!events.length && (
              <>
                <option value={''}>Select event</option>
                {events.map((event, index) => (
                  <option key={`${event.name} + ${index}`} value={event.name}>
                    {event.name}
                  </option>
                ))}
              </>
            )}
          </FormSelect>
        </Form.Group>
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">Donation Type</Form.Label>
          <FormSelect
            disabled={!events.length}
            id="donationType"
            value={filter.donationType}
            onChange={inputChangeHandler}
          >
            <option value={''}>Select type</option>
            {Object.keys(DONATION_TYPE).map((donationType, index) => (
              <option key={`${donationType} + ${index}`} value={donationType}>
                {donationType}
              </option>
            ))}
          </FormSelect>
        </Form.Group>
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">Tags</Form.Label>
          <Select
            id="tags-multi"
            name="tags"
            isMulti
            onChange={selectTagHandler}
            options={tags}
            placeholder="Select Tags"
            value={
              filter.tags
                ? filter.tags.map(t => ({
                    label: t,
                    value: t,
                  }))
                : null
            }
          />
        </Form.Group>
      </div>
    </>
  )
}

export default FilterContactForm
