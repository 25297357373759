import { prop } from 'ramda'
import constants from '../config/constants'
import axios from './axios'

const getTags = async (organizationId, isAllowed = false) => {
  let endpoint = constants.TAGS_ENDPOINT()
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')
  const url = `${endpoint}/${organizationId}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

const createTag = async (newTag, organizationId, isAllowed = false) => {
  let endpoint = constants.TAGS_ENDPOINT()
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')
  const payload = [
    {
      label: newTag,
      value: newTag,
      organizationId,
    },
  ]
  const url = `${endpoint}`
  return axios({
    url,
    method: 'POST',
    data: payload,
  }).then(prop('data'))
}

export default {
  getTags,
  createTag,
}
