import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AddDonationTypeModalProps } from '../../components/EventDetailPage/AddDonationTypeModal/types'
import {
  CreateContactModalProps,
  EditContactModalProps,
  PipelineState,
} from './types'

export const initialPipelineState: PipelineState = {
  refreshConsolidated: false,
  refreshQueue: false,
  refreshDonations: false,
  editContactModal: {
    show: false,
    handleClose: () => null,
    handleUpdate: () => null,
  },
  addDonationTypeModal: {
    show: false,
    onAdd: () => null,
    onClose: () => null,
  },
  createContactModal: {
    show: false,
    handleClose: () => null,
    handleUpdate: () => null,
  },
  queuePageCount: 0,
  currentQueuePage: 1,
  currentQueuePageSize: 25,
  queueContactPage: 1,
  queueContactPageSize: 50,
  queueContactPageCount: 0,
  queryFilter: {
    status: { $ne: 'Completed' },
  },
  pipelineData: [],
  orgUsers: [],
}

const pipelineSlice = createSlice({
  name: 'pipeline',
  initialState: initialPipelineState,
  reducers: {
    // action: function
    setRefreshConsolidated: (state, action: PayloadAction<boolean, string>) => {
      state.refreshConsolidated = action.payload
    },
    setPipelineData: (state, action: PayloadAction<[]>) => {
      state.pipelineData = action.payload
    },
    setRefreshQueue: (state, action: PayloadAction<boolean, string>) => {
      state.refreshQueue = action.payload
    },
    setCurrentQueuePage: (state, action: PayloadAction<number>) => {
      state.currentQueuePage = action.payload
    },
    setQueuePageCount: (state, action: PayloadAction<number>) => {
      state.queuePageCount = action.payload
    },
    setQueuePageSize: (state, action: PayloadAction<number>) => {
      state.currentQueuePageSize = action.payload
    },
    setQueryFilter: (state, action: PayloadAction<object>) => {
      state.queryFilter = action.payload
    },
    resetQueryFilter: state => {
      state.queryFilter = initialPipelineState.queryFilter
    },
    setQueueOrgUsers: (state, action: PayloadAction<[]>) => {
      state.orgUsers = action.payload
    },
    setRefreshDonations: (state, action: PayloadAction<boolean, string>) => {
      state.refreshDonations = action.payload
    },
    setEditContactModal: (
      state,
      action: PayloadAction<EditContactModalProps, string>
    ) => {
      state.editContactModal = action.payload
    },
    setAddDonationTypeModal: (
      state,
      action: PayloadAction<AddDonationTypeModalProps, string>
    ) => {
      state.addDonationTypeModal = action.payload
    },
    setCreateContactModal: (
      state,
      action: PayloadAction<CreateContactModalProps, string>
    ) => {
      state.createContactModal = action.payload
    },
    setQueueContactPage: (state, action: PayloadAction<number>) => {
      state.queueContactPage = action.payload
    },
    setQueueContactPageCount: (state, action: PayloadAction<number>) => {
      state.queueContactPageCount = action.payload
    },
    setQueueContactPageSize: (state, action: PayloadAction<number>) => {
      state.queueContactPageSize = action.payload
    },
  },
})

export const {
  setRefreshConsolidated,
  setPipelineData,
  setRefreshQueue,
  setRefreshDonations,
  setEditContactModal,
  setAddDonationTypeModal,
  setCreateContactModal,
  setCurrentQueuePage,
  setQueuePageCount,
  setQueuePageSize,
  setQueryFilter,
  resetQueryFilter,
  setQueueOrgUsers,
  setQueueContactPage,
  setQueueContactPageCount,
  setQueueContactPageSize,
} = pipelineSlice.actions

export default pipelineSlice.reducer
