import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import Card from '../ui-elements/Card'
import Api from '../../api/event'
import { TopLineProps, TopLines } from './types'
import TopLineItem from './TopLineItem'
import { Row, Col } from 'react-bootstrap'
import { isEmpty } from 'ramda'

const TopLine: FC<TopLineProps> = ({ style }) => {
  const [topLines, setTopLines] = useState<TopLines>({} as TopLines)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let isMounted = true
    Api.getTopLinesMetric()
      .then(res => (isMounted ? setTopLines(res) : null))
      .catch(err => console.error('error fetching top lines totals', err))
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <Card title="RAISED TO DATE" style={style}>
      {isLoading ? (
        <div>Loading...</div>
      ) : isEmpty(topLines) ? (
        <div>No data</div>
      ) : (
        <>
          <Row className="mb-2">
            <Col md={6}>
              <TopLineItem
                value={topLines.raisedToDate}
                percentage={topLines.raisedToDatePercentage}
                count={topLines.raisedToDateCount}
                average={topLines.raisedToDateAvg}
                emphasize={true}
              />
            </Col>
          </Row>
          <hr
            style={{ width: '90%', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <Row>
            <Col>
              <TopLineItem
                title="PRIMARY"
                value={
                  topLines.raisedToDatePrimary === 0
                    ? null
                    : topLines.raisedToDatePrimary
                }
                count={topLines.raisedToDatePrimaryCount}
                average={topLines.raisedToDatePrimaryAvg}
                percentage={null}
              />
            </Col>

            <Col>
              <TopLineItem
                title="GENERAL"
                value={topLines.raisedToDateGeneral}
                count={topLines.raisedToDateGeneralCount}
                average={topLines.raisedToDateGeneralAvg}
                percentage={null}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TopLineItem
                title="QUARTER"
                value={topLines.quarterly}
                percentage={topLines.quarterlyPercentage}
                count={topLines.quarterlyCount}
                average={topLines.quarterlyAvg}
              />
            </Col>
            <Col>
              <TopLineItem
                title="MONTH"
                value={topLines.monthly}
                percentage={topLines.monthlyPercentage}
                count={topLines.monthlyCount}
                average={topLines.monthlyAvg}
              />
            </Col>
            <hr
              style={{
                width: '90%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '20px',
              }}
            />
            <Row>
              <Col md={6}>
                <TopLineItem
                  title="PLEDGES"
                  value={topLines.raisedToDatePledged}
                  percentage={topLines.pledgePercentage}
                />
              </Col>
            </Row>
          </Row>
        </>
      )}
    </Card>
  )
}

export default TopLine
