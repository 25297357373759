import { pathOr } from 'ramda'

export function sortDownUp<T>(
  a: T,
  b: T,
  objValue: keyof T,
  sortMode: 'down' | 'up'
) {
  let sortA = pathOr('', String(objValue).split('.'), a)
  if (typeof sortA === 'string') {
    sortA = sortA.toUpperCase() // ignore upper and lowercase
  }
  let sortB = pathOr('', String(objValue).split('.'), b) // ignore upper and lowercase
  if (typeof sortB === 'string') {
    sortB = sortB.toUpperCase() // ignore upper and lowercase
  }
  if (sortMode === 'up') {
    if ((sortA === '' || !sortA) && sortB.length) return 1
    if (sortA < sortB) return -1
    if (sortA > sortB) return 1
    return 0
  } else {
    if (sortA === '' || !sortA) return 1
    if (sortA > sortB) return -1
    if (sortA < sortB) return 1
    return 0
  }
}

export function sortBySelect<T>(a: T, b: T, sortBy: keyof T, sortStat: number) {
  return sortStat === 1
    ? sortDownUp(a, b, sortBy, 'up')
    : sortStat === 2
    ? sortDownUp(a, b, sortBy, 'down')
    : 0
}
