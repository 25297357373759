import React from 'react'
import useRecordingsList from '../hooks/use-recordings-list'
import { FaTrash } from 'react-icons/fa'
import PropTypes from 'prop-types'
import './recordings-list.css'

const RecordingsList = ({ audio, resetRecorderState }) => {
  const { recordings, deleteAudio } = useRecordingsList(audio)

  return (
    <div className="recordings-container">
      {recordings.length > 0 ? (
        <>
          <div className="recordings-list">
            {recordings.map(record => (
              <div className="record" key={record.key}>
                <div className="col-md-12 embed-responsive embed-responsive-4by3">
                  <audio
                    controls
                    src={record.audio}
                    className="embed-responsive-item"
                  />
                </div>
                <div className="delete-button-container">
                  <button
                    className="delete-button"
                    title="Delete this audio"
                    onClick={() => {
                      resetRecorderState()
                      deleteAudio(record.key)
                    }}
                  >
                    <FaTrash size="1.3rem" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="no-records">
          <small>You dont have record</small>
        </div>
      )}
    </div>
  )
}

RecordingsList.propTypes = {
  audio: PropTypes.object,
  resetRecorderState: PropTypes.func,
}
export default RecordingsList
