import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table } from 'react-bootstrap'
import {
  getDatetime,
  getEventId,
  getLocation,
  getName,
} from '../../utils/dataModels/events'
import TableHeadings from './TableHeadings'
import EventRow from './EventRow'
import { pathOr, pipe, propOr } from 'ramda'

const TableWrapper = styled.div`
  > table > tbody > tr > td {
    vertical-align: middle;
    > img,
    > span {
      display: inline-block;
      line-height: 1rem;
      vertical-align: middle;
    }
  }
`

const makeComparator = (subjectGetter, direction) => (a, b) => {
  const item1 = subjectGetter(a)
  const item2 = subjectGetter(b)
  if (direction === 'asc') return item1 < item2 ? -1 : item1 > item2 ? 1 : 0
  return item2 < item1 ? -1 : item2 > item1 ? 1 : 0
}

const getEventSortComparator = (column, direction) => {
  switch (column) {
    case 'name':
      return makeComparator(pipe(propOr({}, 'event'), getName), direction)
    case 'location':
      return makeComparator(pipe(propOr({}, 'event'), getLocation), direction)
    case 'date':
      return makeComparator(pipe(propOr({}, 'event'), getDatetime), direction)
    case 'raised':
      return makeComparator(
        pathOr(0, ['consolidated', 'totals', 'raised']),
        direction
      )
    case 'tasks':
      return makeComparator(
        pathOr(0, ['consolidated', 'tasks', 'count']),
        direction
      )
    case 'attendees':
      return makeComparator(
        pathOr(0, ['consolidated', 'attendees', 'count']),
        direction
      )
    default:
      return makeComparator(pipe(propOr({}, 'event'), getName), direction)
  }
}

const EventsTable = ({ events }) => {
  const [sortCol, setSortCol] = useState('none')
  const [sortDir, setSortDir] = useState('asc')
  const [sortedEvents, setSortedEvents] = useState(events)

  useEffect(() => {
    const comparator = getEventSortComparator(sortCol, sortDir)
    const sorted = comparator === null ? events : [...events].sort(comparator)
    setSortedEvents(sorted)
  }, [sortCol, sortDir, events])

  return (
    <TableWrapper>
      <Table hover>
        <TableHeadings
          sortCol={sortCol}
          sortDir={sortDir}
          setSortCol={setSortCol}
          setSortDir={setSortDir}
        />
        <tbody>
          {sortedEvents.map((event, index) => (
            <EventRow
              key={`event-row-${index}-${getEventId(
                propOr({}, 'event', event)
              )}-sorted-${sortDir}`}
              event={event}
            />
          ))}
        </tbody>
      </Table>
    </TableWrapper>
  )
}

EventsTable.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object),
}

export default EventsTable
