import styled from 'styled-components'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import UserRegistration from '../UserRegistration/UserRegistration'

const Wrapper = styled.div`
  padding: 1rem 3rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

const AdminUsersPage = () => {
  usePageHeadings('Users Administration')
  return (
    <Wrapper>
      <UserRegistration />
    </Wrapper>
  )
}

AdminUsersPage.propTypes = {}

export default AdminUsersPage
