import constants from '../config/constants'
import { IDonation2, IDonationData } from './interfaces'
import axios from './axios'
import { prop } from 'ramda'

export const filterDonationsDataAggr = (
  query: unknown[] = [],
  isAllowed = false
) => {
  let endpoint = `${constants.DONATION_DATA_ENDPOINT()}/filter`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios<(IDonationData | IDonation2)[]>({
    url,
    method: 'POST',
    data: { query },
  }).then(prop('data'))
}

export const getDonationsForBundler = (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  userId = '',
  isAllowed = false
) => {
  let endpoint = `${constants.DONATION_DATA_ENDPOINT()}/forbundler/${userId}`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios({
    url,
    method: 'GET',
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}

export const filterDonationsData = (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  offset = 0,
  size = 0,
  filters = {},
  text = '',
  isAllowed = false
) => {
  let endpoint = `${constants.DONATION_DATA_ENDPOINT()}/`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}`
  return axios<IDonationData[]>({
    url,
    method: 'POST',
    data: { filters, page: { offset, size }, text },
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}

export const getDonationsDataRanges = (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  isAllowed = false
) => {
  let endpoint = `${constants.DONATION_DATA_ENDPOINT()}/ranges`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}`
  return axios<IDonationData[]>({
    url,
    method: 'GET',
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}

export const addDonationData = (
  donationData: IDonation2[],
  isAllowed = false
) => {
  let endpoint = `${constants.DONATION_DATA_ENDPOINT()}/add`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios<IDonation2[]>({
    url,
    method: 'POST',
    data: { donationData },
  }).then(prop('data'))
}

export const updateDonationData = (
  donationData: IDonation2,
  isAllowed = false
) => {
  let endpoint = `${constants.DONATION_DATA_ENDPOINT()}`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios<IDonation2[]>({
    url,
    method: 'PATCH',
    data: { donationData },
  }).then(prop('data'))
}

export const filterDonationsDataCount = (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  filters = {},
  text = null,
  isAllowed = false
) => {
  let endpoint = `${constants.DONATION_DATA_ENDPOINT()}/count`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}`
  return axios<number>({
    url,
    method: 'POST',
    data: { filters, text },
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}

export const filterDonationsDataByContactId = (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  contactIds = [],
  isAllowed = false
) => {
  let endpoint = `${constants.DONATION_DATA_ENDPOINT()}/latest/byContactId`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}`
  return axios<IDonation2[]>({
    url,
    method: 'POST',
    data: {
      contactIds,
    },
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}
