import PropTypes from 'prop-types'
import { path, propEq } from 'ramda'
import {
  Form,
  InputGroup,
  Image,
  Stack,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap'
import Select from 'react-select'
import styled from 'styled-components'
import usStates from '../../assets/datasets/us-states.json'
//import usCounties from '../../assets/datasets/us-counties.json'
import infoIcon from '../../assets/icons/info-circle.png'
import { NumericFormat } from 'react-number-format'
import { useEffect, useState } from 'react'
import TimeSelect from './TimeSelect'
import Api from '../../api/event'

const findOption = (value, options) => options.find(propEq('value', value))

const Wrapper = styled.div`
  width: 70%;
  padding: 1rem 0.5rem;
  > div {
    width: 100%;
  }
  label {
    display: block;
  }
`

const PropertiesForm = ({ formData, onChange }) => {
  const stateOptions = usStates.map(({ state, name }) => ({
    value: state,
    label: name,
  }))
  /*const citiesOptions = isEmpty(formData.state)
    ? [{ value: '', label: '' }]
    : [
        { value: '', label: '' },
        ...usCounties
          .map(({ us_county, name, state }) => ({
            value: us_county,
            label: name.replace(' County', ''),
            state,
          }))
          .filter(({ state }) => state === formData.state),
      ]*/

  const [hour, setHour] = useState('')
  const [minutes, setMinutes] = useState('')
  const [ampm, setAmpm] = useState('AM')
  const getYear = new Date().getFullYear().toString().slice(-2)
  function incrementLastEvent(event) {
    const eventNum = event.slice(-3)
    const incrementedEventNum = parseInt(eventNum, 10) + 1
    const incrementedString =
      incrementedEventNum < 10
        ? '00' + incrementedEventNum
        : incrementedEventNum < 100
        ? '0' + incrementedEventNum
        : incrementedEventNum
    const incrementedEvent = event.slice(0, -3) + incrementedString
    return incrementedEvent
  }
  useEffect(() => {
    let time = ''
    if (ampm == 'AM') {
      let hourZero = hour.length < 2 ? `0${hour}` : hour
      time = `${hourZero}:${minutes}`
    } else {
      time = `${parseInt(hour) + 12}:${minutes}`
    }
    if (hour != '' && minutes != '') {
      onChange({
        ...formData,
        ['hour']: time,
      })
    } else {
      onChange({
        ...formData,
        ['hour']: '',
      })
    }
  }, [hour, minutes, ampm])

  useEffect(() => {
    let { hour } = formData
    if (hour && hour != '') {
      let arrTime = hour.split(':')
      setMinutes(arrTime[1])
      if (parseInt(arrTime[0]) <= 12) {
        setAmpm('AM')
        setHour(parseInt(arrTime[0]) < 10 ? arrTime[0].slice(1) : arrTime[0])
      } else {
        setAmpm('PM')
        setHour(`${parseInt(arrTime[0]) - 12}`)
      }
    }
  }, [formData])

  const inputChangeHandler = e => {
    e.preventDefault()
    e.stopPropagation()

    const fieldName = path(['target', 'id'], e)
    let value = path(['target', 'value'], e)
    if (fieldName === 'financialGoal' || fieldName === 'suggestedDonation') {
      value = Number(value.replaceAll(',', ''))
    }
    onChange({
      ...formData,
      [fieldName]: value,
    })
  }
  const selectChangeHandler = fieldName => e => {
    const value = path(['value'], e)
    if (fieldName === 'state' && formData.generateEventCode) {
      Api.getEventsByState(value).then(events => {
        const descOrderEvents = events
          .sort((a, b) => b.name.localeCompare(a.name))
          .filter(
            event =>
              event.name.length === 9 && event.name.startsWith(`EV${getYear}`)
          )
        if (descOrderEvents.length) {
          const mostRecentEvent = descOrderEvents[0]
          const placeholderEventName = incrementLastEvent(mostRecentEvent.name)
          onChange({
            ...formData,
            [fieldName]: value,
            name: placeholderEventName,
          })
        } else {
          onChange({
            ...formData,
            [fieldName]: value,
            name: `EV${getYear}${value}001`,
          })
        }
      })
    } else {
      let updates = {
        ...formData,
        [fieldName]: value,
      }
      onChange(updates)
    }
  }

  const selectTimeChangeHandler = fieldName => e => {
    const value = path(['value'], e)
    if (fieldName == 'hour') {
      setHour(value)
    }
    if (fieldName == 'minutes') {
      setMinutes(value)
    }
    if (fieldName == 'ampm') {
      setAmpm(value)
    }
  }

  const financialsFocusHandler = e => {
    e.preventDefault()
    e.stopPropagation()
    const fieldName = path(['target', 'id'], e)
    const value = Number(path(['target', 'value'], e))
    if (value === 0) {
      onChange({
        ...formData,
        [fieldName]: '',
      })
    }
  }
  const financialsBlurHandler = e => {
    e.preventDefault()
    e.stopPropagation()
    const fieldName = path(['target', 'id'], e)
    const value = path(['target', 'value'], e).replaceAll(',', '')
    if (value === '' || Number.isNaN(Number(value))) {
      onChange({
        ...formData,
        [fieldName]: '0',
      })
    }
  }

  const inputCheckBoxChangeHandler = e => {
    const fieldName = path(['target', 'id'], e)
    const value = path(['target', 'checked'], e)
    if (fieldName === 'generateEventCode' && formData.state && value) {
      Api.getEventsByState(formData.state).then(events => {
        const descOrderEvents = events
          .sort((a, b) => b.name.localeCompare(a.name))
          .filter(
            event =>
              event.name.length === 9 && event.name.startsWith(`EV${getYear}`)
          )
        if (descOrderEvents.length) {
          const mostRecentEvent = descOrderEvents[0]
          const placeholderEventName = incrementLastEvent(mostRecentEvent.name)
          onChange({
            ...formData,
            [fieldName]: value,
            name: placeholderEventName,
          })
        } else {
          onChange({
            ...formData,
            [fieldName]: value,
            name: `EV${getYear}${formData.state}001`,
          })
        }
      })
    } else if (fieldName === 'generateEventCode' && formData.state && !value) {
      onChange({
        ...formData,
        [fieldName]: value,
        name: '',
      })
    } else {
      onChange({
        ...formData,
        [fieldName]: value,
      })
    }
  }

  const RedAst = () => <span style={{ color: 'red' }}>*</span>
  const Info = () => <img src={infoIcon} width="16" />
  const selectStyles = {
    input: provided => ({
      ...provided,
      border: 'none',
      '> input[type=text]:focus': { outline: 'none !important' },
    }),
  }

  return (
    <Wrapper>
      <Form.Group style={{ marginBottom: '1rem' }}>
        <Form.Label htmlFor="name">
          Event Name&nbsp;
          <OverlayTrigger
            overlay={
              <Tooltip id="event-name">
                {`Enter name here (e.g. EV${getYear}VA001)`}
              </Tooltip>
            }
          >
            <Image src={infoIcon} width="16" />
          </OverlayTrigger>
          &nbsp;
          <RedAst />
        </Form.Label>
        <Form.Control
          autoComplete="off"
          type="text"
          id="name"
          value={formData.name}
          placeholder="Enter name here"
          onChange={inputChangeHandler}
          disabled={formData.generateEventCode}
        />
      </Form.Group>

      <Form.Group className="mb-3 text-start">
        <Form.Check
          inline
          label="Auto Generated Event Code"
          id="generateEventCode"
          type="switch"
          checked={formData.generateEventCode}
          onChange={inputCheckBoxChangeHandler}
        />
      </Form.Group>

      <div className="h5 mb-4" style={{}}>
        Location Details
      </div>

      <Form.Group
        className="mb-3 text-end"
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '-2.375rem',
        }}
      >
        <Form.Check
          inline
          label="Withold Venue Details"
          id="locationRSVP"
          type="switch"
          checked={formData.locationRSVP}
          onChange={inputCheckBoxChangeHandler}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor="venue">
          Venue <Info />
          &nbsp;
          <RedAst />
        </Form.Label>
        <Form.Control
          autoComplete="off"
          type="text"
          id="venue"
          value={formData.venue}
          placeholder="Enter venue name here"
          onChange={inputChangeHandler}
          disabled={formData.venueTBD}
        />
      </Form.Group>

      <Form.Group className="mb-3 text-start">
        <Form.Check
          inline
          label="Location To Be Determined"
          id="venueTBD"
          type="switch"
          checked={formData.venueTBD}
          onChange={inputCheckBoxChangeHandler}
        />
      </Form.Group>

      <Stack direction="horizontal" gap={3}>
        <Form.Group className="w-50" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">
            City <RedAst />
          </Form.Label>
          <Form.Control
            autoComplete="off"
            type="text"
            id="city"
            value={formData.city}
            placeholder="Enter city here"
            onChange={inputChangeHandler}
          />
        </Form.Group>
        <Form.Group className="w-50" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="state">
            State <RedAst />
          </Form.Label>
          <Select
            id="state"
            value={findOption(formData.state, stateOptions)}
            options={stateOptions}
            placeholder="Select a state"
            onChange={selectChangeHandler('state')}
            styles={selectStyles}
          />
        </Form.Group>
        {/*
        <Form.Group className="w-50" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">
            City <RedAst />
          </Form.Label>
          <Select
            key={`event-city-${formData.state}`}
            id="city"
            value={findOption(formData.city, citiesOptions)}
            options={citiesOptions}
            placeholder="This is a placeholder for instructions"
            onChange={selectChangeHandler('city')}
            styles={selectStyles}
          />
        </Form.Group>
        */}
      </Stack>

      <Stack direction="horizontal" gap={3}>
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="street">Street</Form.Label>
          <Form.Control
            autoComplete="off"
            type="text"
            id="street"
            value={formData.street}
            placeholder="Enter street address here"
            onChange={inputChangeHandler}
          />
        </Form.Group>
      </Stack>

      <div className="mb-3 h5">Schedule</div>

      <Stack direction="horizontal" gap={3}>
        <Form.Group className="w-50" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="day">
            Date <RedAst />
          </Form.Label>
          <Form.Control
            type="date"
            id="day"
            value={formData.day}
            onChange={inputChangeHandler}
            max="9999-12-30"
            min={new Date().toLocaleDateString('en-ca')}
          />
        </Form.Group>

        <Form.Group className="w-50" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="hour">
            Time <RedAst />
          </Form.Label>
          {/*
          <Form.Control
            type="time"
            id="hour"
            value={formData.hour}
            onChange={inputChangeHandler}
            min={new Date().toLocaleTimeString('en-ca')}
          />
          */}
          <TimeSelect
            hour={hour}
            minutes={minutes}
            ampm={ampm}
            selectChangeHandler={selectTimeChangeHandler}
          />
        </Form.Group>
      </Stack>
      <div className="mb-3 h5">Financials</div>

      <Stack direction="horizontal" gap={3}>
        <Form.Group className="mb-3 w-50">
          <Form.Label htmlFor="financialGoal">
            Goal <RedAst />
          </Form.Label>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <NumericFormat
              autoComplete="off"
              type="text"
              id="financialGoal"
              value={formData.financialGoal}
              onChange={inputChangeHandler}
              onFocus={financialsFocusHandler}
              onBlur={financialsBlurHandler}
              min="0"
              className="currency form-control"
              thousandsGroupStyle="thousand"
              thousandSeparator=","
            />
          </InputGroup>
        </Form.Group>

        <Form.Group className="mb-3 w-50">
          <Form.Label htmlFor="suggestedDonation">
            Donation Level <RedAst />
          </Form.Label>
          <InputGroup>
            <InputGroup.Text>$</InputGroup.Text>
            <NumericFormat
              autoComplete="off"
              type="text"
              id="suggestedDonation"
              value={formData.suggestedDonation}
              onChange={inputChangeHandler}
              onFocus={financialsFocusHandler}
              onBlur={financialsBlurHandler}
              min="0"
              className="currency form-control"
              thousandsGroupStyle="thousand"
              thousandSeparator=","
            />
          </InputGroup>
        </Form.Group>
      </Stack>

      <Stack direction="horizontal" gap={3}>
        <div className="w-50">&nbsp;</div>

        <Form.Group className="mb-3 text-end">
          <Form.Check
            type="switch"
            checked={formData.suggestedDonationTBD}
            id="suggestedDonationTBD"
            value={formData.suggestedDonationTBD}
            label="Suggested Donation"
            onChange={inputCheckBoxChangeHandler}
          />
        </Form.Group>
      </Stack>
    </Wrapper>
  )
}

PropertiesForm.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
}

const EventForm = ({ formData, onChange, updateFlag }) => {
  return (
    <>
      {!updateFlag ? <></> : <h2>Update Event</h2>}
      <PropertiesForm formData={formData} onChange={onChange} />
    </>
  )
}

EventForm.propTypes = {
  formData: PropTypes.object,
  onChange: PropTypes.func,
  updateFlag: PropTypes.bool,
}

export default EventForm
