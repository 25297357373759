import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Stack, Table } from 'react-bootstrap'
import {
  BsCheckSquare,
  BsChevronRight,
  BsSquare,
  BsTrash,
} from 'react-icons/bs'
import {
  always,
  identity,
  ifElse,
  isNil,
  join,
  not,
  pipe,
  propOr,
  split,
  take,
} from 'ramda'
import Money from '../ui-elements/Money'
import { useState } from 'react'
import TierFormModal from './TierFormModal'
import tierImg from '../../assets/illustrations/tiers.png'
import SortIconAction from '../ui-elements/SortIconAction'

const cropWords = pipe(
  ifElse(isNil, always(''), identity),
  split(' '),
  take(8),
  join(' ')
)

const CropWrapper = styled.div`
  overflow-wrap: break-word;
`

const CropText = ({ text }) => {
  const [isCropped, setCropped] = useState(true)
  const croppedText = cropWords(text)
  const toggleCropped = () => setCropped(not)
  const showToggle = (text || '').length !== (croppedText || '').length

  return (
    <CropWrapper>
      {isCropped ? croppedText : text}
      {showToggle && (
        <>
          &nbsp;
          <Button variant="link" href="#" onClick={toggleCropped}>
            {isCropped ? 'Show more' : 'Show less'}
          </Button>
        </>
      )}
    </CropWrapper>
  )
}
CropText.propTypes = {
  text: PropTypes.string,
}

const OpacityWrapper = styled.div`
  opacity: var(--opacity, 1);
`

const StyledButtonTd = styled.td`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  width: 2.5rem;
`

const TierRow = ({ tier, onRemove, onEdit }) => {
  const [isMouseOver, setMouseOver] = useState(false)
  const mouseOverHandler = () => setMouseOver(true)
  const mouseOutHandler = () => setMouseOver(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const tierId = propOr('', '_id', tier)
  const tierName = propOr('', 'tierName', tier)
  const participants = propOr('', 'participants', tier)
  const participantsText = participants.reduce(
    (acc, p) => `${acc ? acc : ''}${acc ? ', ' : ''}${propOr('', 'value', p)}`,
    null
  )
  const tierLevel = propOr('', 'tierLevel', tier)
  return (
    <tr onMouseEnter={mouseOverHandler} onMouseLeave={mouseOutHandler}>
      <td style={{ width: '15rem' }}>{tierName}</td>
      <td style={{ width: '20rem' }}>
        <CropText text={participantsText} />
      </td>
      <td style={{ width: '12rem' }}>
        <Money value={Number(tierLevel)} />
      </td>
      <td className="text-center">
        {propOr('', 'isGiveRaise', tier) ? (
          <BsCheckSquare color="#00bdb2" />
        ) : (
          <BsSquare color="#00bdb2" />
        )}
      </td>
      <StyledButtonTd>
        <OpacityWrapper
          style={{
            '--opacity': isMouseOver ? 1 : 0,
          }}
        >
          <Stack gap={2} direction="horizontal">
            <Button
              onClick={() => setShowEditModal(true)}
              variant="outline-secondary"
            >
              <BsChevronRight />
            </Button>
            <Button
              onClick={() => onRemove(tierId)}
              variant="outline-secondary"
            >
              <BsTrash />
            </Button>
          </Stack>
        </OpacityWrapper>
        <TierFormModal
          onEdit={onEdit}
          onClose={() => {
            setShowEditModal(false)
            setMouseOver(false)
          }}
          show={showEditModal}
          tier={tier}
          isEdit
        />
      </StyledButtonTd>
    </tr>
  )
}
TierRow.propTypes = {
  tier: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
}

const TableWrapper = styled.div`
  > table > tbody > tr > td {
    vertical-align: middle;
    > img,
    > span {
      display: inline-block;
      line-height: 1rem;
      vertical-align: middle;
    }
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CenterWrapper = styled.div`
  text-align: center;
  padding-bottom: 1rem;
`

const TextSpan = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: #6c757d;
`
const StyledImg = styled.img`
  height: 8.75rem;
`

const TiersTable = ({ tiers, onEdit, onRemove }) => {
  return (
    <div>
      {tiers.length > 0 ? (
        <TableWrapper>
          <Table hover>
            <thead>
              <tr>
                <th>
                  Tier{' '}
                  <SortIconAction
                    setFunctState={() => null}
                    stateValue={0}
                    refresh={() => null}
                  />
                </th>
                <th>
                  Participants{' '}
                  <SortIconAction
                    setFunctState={() => null}
                    stateValue={0}
                    refresh={() => null}
                  />
                </th>
                <th>
                  Level{' '}
                  <SortIconAction
                    setFunctState={() => null}
                    stateValue={0}
                    refresh={() => null}
                  />
                </th>
                <th>
                  Give or raise{' '}
                  <SortIconAction
                    setFunctState={() => null}
                    stateValue={0}
                    refresh={() => null}
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {tiers?.map((tier, i) => (
                <TierRow
                  key={`tier-row-${i}`}
                  tier={tier}
                  onEdit={onEdit}
                  onRemove={onRemove}
                />
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      ) : (
        <Wrapper>
          <CenterWrapper>
            <StyledImg src={tierImg} />
          </CenterWrapper>
          <CenterWrapper>
            <TextSpan>You still have not created any tier</TextSpan>
          </CenterWrapper>
        </Wrapper>
      )}
    </div>
  )
}

TiersTable.propTypes = {
  tiers: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
}

export default TiersTable
