import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
// import ChatTabHandle from '../ChatTabHandle/ChatTabHandle'

const Wrapper = styled.div`
  position: relative;
  margin: 0;
  padding: 0;
`

const ChatLayout = () => {
  return (
    <Wrapper>
      <Outlet />
      {/*Disable chat client*/}
      {/* <ChatTabHandle /> */}
    </Wrapper>
  )
}

export default ChatLayout
