import { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { pathOr } from 'ramda'
import axios from '../../api/axios'
import nylasAxios from '../../api/api_instance'

const AuthSessionLayout = () => {
  const [initilized, setInitialized] = useState(false)
  const navigate = useNavigate()
  const isAuthenticated = useSelector(
    pathOr(false, ['authentication', 'isAuthenticated'])
  )
  const isAuthenticationError = useSelector(
    pathOr(false, ['authentication', 'isAuthenticationError'])
  )

  useEffect(() => {
    if (isAuthenticationError) {
      navigate('/login')
      sessionStorage.clear()
    }
  }, [isAuthenticationError])

  useEffect(() => {
    if (isAuthenticated && !isAuthenticationError && !initilized) {
      const sessionToken = sessionStorage.getItem('Session-Token')
      axios.interceptors.request.use(config => {
        config.headers['Session-Token'] = sessionToken
        return config
      })
      nylasAxios.interceptors.request.use(config => {
        config.headers['Session-Token'] = sessionToken
        return config
      })
    }
    setInitialized(true)
  }, [isAuthenticated, isAuthenticationError, initilized])

  return <>{initilized && <Outlet />}</>
}

export default AuthSessionLayout
