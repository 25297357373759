import axios from 'axios'
import constants from '../../config/constants'

const graphMe = async tokens => {
  const meResponse = await axios.get(
    `${constants.AZURE_GRAPH_BASE_ENDPOINT}/me`,
    {
      headers: { Authorization: `Bearer ${tokens.access_token}` },
    }
  )
  const userInfo = meResponse.data
  return { tokens, userInfo }
}

export default graphMe
