import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Col, Container, Nav, Row } from 'react-bootstrap'
import { always, gt, lte, pipe, propOr } from 'ramda'
import moment from 'moment'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import { getDatetime, getEventId } from '../../utils/dataModels/events'
import { getUser, getUserOrganizationId } from '../../utils/state/selectors'
import Api from '../../api/event'
import EventsTable from '../EventsTable/EventsTable'
import StafferViewScope from '../RoleViewScope/StafferViewScope'
import CenterSpinner from '../CenterSpinner/CenterSpinner'

const Wrapper = styled.div`
  padding: 1rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

const PillsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: -0.5rem;
  margin-bottom: 2rem;
`

const FilterBar = ({ selectedFilter, onFilterChange }) => {
  const navigate = useNavigate()
  const isAll = selectedFilter === 'all'
  const isUpcoming = selectedFilter === 'upcoming'
  const isPast = selectedFilter === 'past'
  const filterClickHandler = selection => () => onFilterChange(selection)
  const createClickHandler = () => navigate('/events/create')
  return (
    <div>
      <PillsWrapper>
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link
              href="#"
              active={isAll}
              onClick={filterClickHandler('all')}
            >
              All
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#"
              active={isUpcoming}
              onClick={filterClickHandler('upcoming')}
            >
              Upcoming
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              href="#"
              active={isPast}
              onClick={filterClickHandler('past')}
            >
              Past
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <div>
          <StafferViewScope>
            <Button
              type="button"
              variant="secondary"
              onClick={createClickHandler}
            >
              + Create Event
            </Button>
          </StafferViewScope>
        </div>
      </PillsWrapper>
    </div>
  )
}

FilterBar.propTypes = {
  selectedFilter: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
}

const makeEventFilter = type => {
  const currentTimestamp = moment().toISOString()

  switch (type) {
    case 'upcoming':
      return pipe(pipe(propOr({}, 'event'), getDatetime), lte(currentTimestamp))
    case 'past':
      return pipe(pipe(propOr({}, 'event'), getDatetime), gt(currentTimestamp))
    case 'all':
      return always(true)
  }
}

const EventsPage = () => {
  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.EVENTS(true),
  ]
  usePageHeadings('Events', breadcrumbs)

  const user = useSelector(getUser)
  const [allEvents, setAllEvents] = useState([])
  const [events, setEvents] = useState([])
  const [selectedPillFilter, setSelectedPillFilter] = useState('upcoming')

  const [isLoading, setIsLoading] = useState(false)
  const organizationId = useSelector(getUserOrganizationId)

  useEffect(() => {
    setAllEvents([])
    setIsLoading(true)
    Api.getEvents(organizationId)
      .then(async events => {
        const completeEventList = []
        for (let i = 0; i < events.length; i++) {
          const event = events[i]
          const eventId = getEventId(event)
          const consolidated = await Api.getConsolidatedSummary(user, eventId)
          completeEventList.push({ event, consolidated })
        }
        return completeEventList
      })
      .then(events => setAllEvents(events))
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    setEvents(allEvents.filter(makeEventFilter(selectedPillFilter)))
  }, [allEvents, selectedPillFilter])

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col>
            <FilterBar
              selectedFilter={selectedPillFilter}
              onFilterChange={setSelectedPillFilter}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <EventsTable events={events} />
          </Col>
        </Row>
        {isLoading && <CenterSpinner />}
      </Container>
    </Wrapper>
  )
}

export default EventsPage
