import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import ApiOrganization from '../../api/organization'
import { useParams } from 'react-router-dom'
import OrganizationForm from '../CreateOrganizationPage/OrganizationForm'
import { useNavigate } from 'react-router-dom'
import CenterSpinner from '../CenterSpinner/CenterSpinner'

const Wrapper = styled.div`
  padding: 1rem 3rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

const EditOrganizationPage = () => {
  const { orgId } = useParams()
  const [organization, setOrganization] = useState({})
  const [loading, setIsLoading] = useState(false)
  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.ADMIN_ORGANIZATIONS(false),
    mainBreadcrumbs.ORGANIZATION_ID(
      { _id: orgId, name: organization?.name },
      false
    ),
    mainBreadcrumbs.EDIT_ORGANIZATIONS({ _id: orgId }, true),
  ]

  usePageHeadings('Edit Organization', breadcrumbs)
  const navigate = useNavigate()

  useEffect(() => {
    ApiOrganization.getOrganization(orgId)
      .then(setOrganization)
      .catch(console.error)
  }, [])

  const handleSubmit = data => {
    console.log({ data })
    setIsLoading(true)
    ApiOrganization.editOrganization(data, orgId).then(response => {
      setIsLoading(false)
      navigate(`/admin/organizations/${orgId}`)
    })
  }

  return (
    <Wrapper>
      {organization && Object.keys(organization).length !== 0 ? (
        <OrganizationForm
          onSubmit={handleSubmit}
          onCancel={() => navigate(`/admin/organizations/${orgId}`)}
          organization={organization}
          isLoading={loading}
        />
      ) : (
        <CenterSpinner />
      )}
    </Wrapper>
  )
}

export default EditOrganizationPage
