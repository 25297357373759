import styled from 'styled-components'
import PropTypes from 'prop-types'
import GaugeQuantity from './GaugeQuantity'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  max-width: 200px;
  > div {
    margin-bottom: 0.5rem;
  }
`

const GaugeWrapper = styled.div`
  width: 100%;
`

const LabelWrapper = styled.div`
  width: 100%;
  font-family: DM Sans;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: center;
  padding-top: 0.5rem;
`

const GaugeGraphQuantity = ({ percent, label, quantity, color }) => {
  return (
    <Wrapper>
      <GaugeWrapper>
        <GaugeQuantity percent={percent} color={color} quantity={quantity} />
        <LabelWrapper>{label}</LabelWrapper>
      </GaugeWrapper>
    </Wrapper>
  )
}

GaugeGraphQuantity.propTypes = {
  percent: PropTypes.number,
  amount: PropTypes.number,
  label: PropTypes.string,
  quantity: PropTypes.number,
  color: PropTypes.string,
}

export default GaugeGraphQuantity
