import { IContact } from '../api/interfaces'

export const getFLContactById = (contacts: IContact[], _id?: string) => {
  if (contacts && _id) {
    const contactFound = contacts.find(contact => contact._id === _id)
    return `${contactFound?.first_name || ''} ${contactFound?.last_name || ''}`
  }
  return ''
}

export const getContactById = (contacts: IContact[], _id: string) => {
  const contactFound = contacts.find(
    contact => contact.contact_person_id === _id
  )
  return contactFound
}
