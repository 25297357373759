import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import TitleBreadcrumbs from '../ui-elements/TitleBreadcrumbs'
import { Col, Row, Stack } from 'react-bootstrap'
import ActionButton from '../ui-elements/ActionButton'
import OrganizationDetails from './OrganizationDetails'
import { IOrganization } from '../../api/interfaces'
import ApiOrganization from '../../api/organization'
import ContactsPage from '../ContactsPage/ContactsPage'
import CenterSpinner from '../CenterSpinner/CenterSpinner'

const Wrapper = styled.div`
  padding: 1rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`
const Title = styled.div`
  padding-top: 48px;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  color: #000000;
`
const AdminOrganizationDetailPage = () => {
  const { orgId } = useParams()
  const [organization, setOrganization] = useState<IOrganization>()
  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.ADMIN_ORGANIZATIONS(false),
    mainBreadcrumbs.ORGANIZATION_ID(
      { _id: orgId, name: organization?.name },
      true
    ),
  ]
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  const handleEditOrganization = () =>
    navigate(`/admin/organizations/edit/${orgId}`)

  useEffect(() => {
    setLoading(true)
    orgId &&
      ApiOrganization.getOrganization(orgId, true, true)
        .then(org => {
          setOrganization(org)
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => setLoading(false))
  }, [])

  return (
    <>
      {!loading ? (
        <>
          <TitleBreadcrumbs
            title={`${organization?.name}`}
            breadcrumbs={breadcrumbs}
          >
            <ActionButton
              handleClick={() => handleEditOrganization()}
              action="Edit Organization"
            />
            <div />
          </TitleBreadcrumbs>
          <Wrapper>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <OrganizationDetails organization={organization} />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Row className="pb-4 ms-3">
                      <Col sm={3}>
                        <Title>Contact List</Title>
                      </Col>
                    </Row>
                    {orgId && organization && (
                      <ContactsPage
                        renderUploadFileModal={false}
                        renderCreateContactModal={false}
                        renderDonorEngineModal={false}
                        renderPageHeadings={false}
                        organizationId={orgId}
                        organization={organization}
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Wrapper>
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <CenterSpinner />
        </div>
      )}
    </>
  )
}

export default AdminOrganizationDetailPage
