import PropTypes from 'prop-types'

const BoldIcon = ({
  width = '22px',
  height = '22px',
  fillRectangle = 'black',
  fillLetter = 'black',
  handlerClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      onClick={handlerClick}
    >
      <path
        fill={fillRectangle}
        d="M16,496H496V16H16ZM48,48H464V464H48Z"
        className="ci-primary"
      />
      <path
        fill={fillLetter}
        d="M341.6,242.986A79.956,79.956,0,0,0,280,112H112v32h40V368H112v32H320a79.991,79.991,0,0,0,21.6-157.014ZM208,144h48a48,48,0,0,1,0,96H208Zm88,224H208V272h88a48,48,0,0,1,0,96Z"
        className="ci-primary"
      />
    </svg>
  )
}

BoldIcon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fillRectangle: PropTypes.string,
  fillLetter: PropTypes.string,
  handlerClick: PropTypes.func.isRequired,
}
export default BoldIcon
