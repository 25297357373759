import React, { useEffect, useState } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledButton = styled(Button)`
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  height: 1rem;
  width: 1rem;
  color: #4a4a4a;
  &:hover,
  &:active,
  &:focus,
  &:focus-visible {
    color: #4a4a4a !important;
    background-color: transparent !important;
    box-shadow: none !important;
  }
  > svg {
    margin: 0;
    padding: 0;
  }
`

const ToolTipText = ({ placement, description, children, isModalOpen }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  useEffect(() => {
    if (isModalOpen) {
      setShowTooltip(false)
    }
  }, [isModalOpen])

  const handleMouseEnter = () => {
    if (!isModalOpen) {
      setShowTooltip(true)
    }
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }
  return (
    <OverlayTrigger
      key={placement}
      placement={placement}
      overlay={<Tooltip id={`tooltip-${placement}`}>{description}</Tooltip>}
      rootClose={true}
      show={showTooltip}
    >
      <StyledButton
        className="d-flex align-items-center"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </StyledButton>
    </OverlayTrigger>
  )
}

export default ToolTipText

ToolTipText.propTypes = {
  children: PropTypes.element.isRequired,
  placement: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool,
}
