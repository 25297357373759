import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import { BsChevronRight } from 'react-icons/bs'

const StyledTd = styled.td`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  > img {
    width: 40px;
    border-radius: 50%;
  }
  > span {
    padding-left: 5px;
  }
`
const StyledButtonTd = styled.td`
  width: 2.5rem;
`
const OpacityWrapper = styled.div`
  opacity: var(--opacity, 1);
`

const OrganizationRow = ({ organization, handleClick }) => {
  let orgIcon
  try {
    orgIcon = organization?.organizationLogoUrl
      ? organization?.organizationLogoUrl // require(`../../assets/profilePictures/${organization.picture}.png`)
      : require(`../../assets/profilePictures/unassigned.png`)
  } catch (error) {
    orgIcon = require(`../../assets/profilePictures/unassigned.png`)
  }

  const [isMouseOver, setMouseOver] = useState(false)

  const mouseOverHandler = () => setMouseOver(true)
  const mouseOutHandler = () => setMouseOver(false)
  return (
    <tr onMouseEnter={mouseOverHandler} onMouseLeave={mouseOutHandler}>
      <StyledTd onClick={handleClick} style={{ cursor: 'pointer' }}>
        <img src={orgIcon} /> <span>{organization?.name}</span>
      </StyledTd>
      <StyledTd>{organization?.emailAccount?.email} </StyledTd>
      <StyledTd>{organization?.phone} </StyledTd>
      <StyledTd>
        {organization?.owner?.name}&nbsp;{organization?.owner?.lastName}
      </StyledTd>
      <StyledTd>{organization?.owner?.email}</StyledTd>
      <StyledTd>{organization?.contact?.primaryPhoneNumber}</StyledTd>
      <StyledButtonTd>
        <OpacityWrapper
          style={{
            '--opacity': isMouseOver ? 1 : 0,
          }}
        >
          <Button onClick={handleClick} variant="outline-secondary">
            <BsChevronRight />
          </Button>
        </OpacityWrapper>
      </StyledButtonTd>
    </tr>
  )
}

OrganizationRow.propTypes = {
  organization: PropTypes.object,
  handleClick: PropTypes.func,
}

export default OrganizationRow
