import CustomDotsDropdown from '../ui-elements/CustomDotsDropdown'
import MenuItem from '../ui-elements/MenuItem'
import { Stack } from 'react-bootstrap'
import styled from 'styled-components'
import { BsTelephoneForward, BsCalendar4Event } from 'react-icons/bs'
import PropTypes from 'prop-types'
import { getUserToken } from '../../utils/dataModels/user'
import constants from '../../config/constants'
import { getScheduledEvents } from '../../api/nylas/scheduler'
import { useSelector } from 'react-redux'
import { getUserOrganizationId } from '../../utils/state/selectors'
import { handleScheduleCall } from '../MailBoxPage/MailboxCompose/helpers'

export const StyledStack = styled(Stack)`
  padding-right: 6rem;
  width: 100%;
`

const ContactDropdown = ({ contact, hasCredentials, onCall, onClose }) => {
  const organizationId = useSelector(getUserOrganizationId)
  const providerAccount = getUserToken()

  const handleScheduleCallClick = async token => {
    const scheduledEvents = await getScheduledEvents(token)
    handleScheduleCall(
      () => onClose(token, scheduledEvents),
      token,
      organizationId,
      `${constants.SCHEDULER_ENDPOINT()}/${token}?organizationId=${organizationId}&contactId=${
        contact._id
      }`
    )
  }

  const handleCallNow = () => {
    if (!hasCredentials) return
    onCall()
  }

  return (
    <CustomDotsDropdown>
      <MenuItem
        handleOnClick={() => handleScheduleCallClick(providerAccount?.token)}
      >
        <StyledStack gap={2} direction="horizontal">
          <BsCalendar4Event color={'var(--bs-secondary)'} />
          <span>Schedule a call</span>
        </StyledStack>
      </MenuItem>
      <MenuItem handleOnClick={handleCallNow} disabled={!hasCredentials}>
        <StyledStack gap={2} direction="horizontal">
          <BsTelephoneForward color={'var(--bs-secondary)'} />
          <span>Call now</span>
        </StyledStack>
      </MenuItem>
    </CustomDotsDropdown>
  )
}

ContactDropdown.propTypes = {
  hasCredentials: PropTypes.bool,
  contact: PropTypes.object,
  onCall: PropTypes.func,
  onClose: PropTypes.func,
  setEvents: PropTypes.func,
}

export default ContactDropdown
