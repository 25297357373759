import React, { useState } from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  FormLabel,
  Row,
  Spinner,
  Stack,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { loginAction } from '../../redux/actions/authentication'
import logoImg from '../../assets/logos/GOOGLE APP LOGO.png'
import GoogleLoginButton from '../LoginButton/GoogleLoginButton'
import AzureLoginButton from '../LoginButton/AzureLoginButton'
import { pathOr } from 'ramda'
import constants from '../../config/constants'

const Wrapper = styled.div`
  max-width: 795px;
  display: flex;
`

const Card = styled.div`
  background-color: #ffffff;
  @media (max-width: 430px) {
    background-color: #212529;
  }
`

const DivForm = styled.div`
  background-color: #ffffff;
  padding: 1rem;
  @media (max-width: 430px) {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`

const ErrorMessage = styled.p`
  padding-top: 10px;
  padding-bottom: 10px;
  color: red;
`

const SloganTitle = styled.div`
  font-size: 1.625rem;
  font-weight: 700;
  line-height: 2.25rem;
  letter-spacing: 0em;
  text-align: left;
  background-color: rgb(17, 39, 60);
`

const SloganDesc = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  background-color: rgb(17, 39, 60);
`

const TextSpan = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  > a {
    text-decoration-line: underline;
    color: #00bdb2;
  }
`

const LoginTitle = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  padding-bottom: 1rem;
`

const ConnectSpan = styled.span`
  color: #000000;
  font-size: 1rem;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  white-space: nowrap;
`
const HR = styled.hr`
  color: #cccaca;
  background-color: #cccaca;
  height: 2px;
  width: 70%;
`

const FormLabelStyled = styled(FormLabel)`
  color: #79828a;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`
const SpinnerWrapper = styled.div`
  & > div {
    width: 12px;
    height: 12px;
  }
`

const BackgroundDiv = styled.div`
  background-color: rgb(17, 39, 60);
`

const Login = () => {
  const [data, setData] = useState({
    username: '',
    password: '',
  })
  const { isAuthenticationError } = useSelector(state => state.authentication)
  const dispatch = useDispatch()
  const { username, password } = data
  const isLogingIn = useSelector(
    pathOr(false, ['authentication', 'isLogingIn'])
  )

  const changeHandler = e => {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const submitHandler = e => {
    e.preventDefault()
    //dispatch(startLoginProcess())
    dispatch(loginAction(username, password))
  }

  const LeftPanel = () => (
    <BackgroundDiv className="w-100 h-100 pt-0 px-4 text-light rounded">
      <div className="py-4">
        <img src={logoImg} width="250px" />
      </div>
      <div className="mb-3">
        <SloganTitle>A New Era of Fundraising</SloganTitle>
      </div>
      <div className="mb-0">
        <SloganDesc className="mb-5">
          The Power of a National&nbsp;Finance&nbsp;Team in One&nbsp;Platform
        </SloganDesc>
      </div>
      <Button
        type="button"
        name="connect"
        onClick={() => window.open(constants.CS_URL)}
        variant="secondary"
        className="w-100 mt-0"
      >
        Connect with our Team
      </Button>
    </BackgroundDiv>
  )

  return (
    <Wrapper>
      <Card className="pt-3 pb-3 pl-2 pr-2 shadow-lg rounded">
        <Container>
          <Row className="no-gutters">
            <Col sm="12" md="6">
              <LeftPanel />
            </Col>
            <Col sm="12" md="6">
              <DivForm className="rounded">
                <div className="mb-4">
                  <LoginTitle>Login</LoginTitle>
                  <TextSpan>
                    Don&apos;t have an account yet?&nbsp;
                    <a href={constants.REGISTER_URL}>Contact Us</a>
                  </TextSpan>
                </div>
                <Form onSubmit={submitHandler}>
                  <Form.Group className="mb-3">
                    <FormLabelStyled>Username</FormLabelStyled>
                    <Form.Control
                      placeholder="username"
                      autoComplete="username"
                      type="email"
                      name="username"
                      value={username}
                      onChange={changeHandler}
                      required
                      autoFocus
                      disabled={isLogingIn}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <FormLabelStyled>Password</FormLabelStyled>
                    <Form.Control
                      placeholder="password"
                      autoComplete="current-password"
                      name="password"
                      type="password"
                      value={password}
                      onChange={changeHandler}
                      required
                      disabled={isLogingIn}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Button
                      type="submit"
                      name="submit"
                      variant="secondary"
                      className="w-100"
                      disabled={isLogingIn}
                    >
                      {isLogingIn && (
                        <SpinnerWrapper>
                          <Spinner />
                        </SpinnerWrapper>
                      )}
                      {!isLogingIn && 'Login'}
                    </Button>
                  </Form.Group>
                </Form>
                <div className="mb-4 pt-4">
                  <Stack gap={2} direction="horizontal" className="pb-2">
                    <ConnectSpan>Sign in with</ConnectSpan>
                    <HR />
                  </Stack>
                  <Row>
                    <Col>
                      <GoogleLoginButton />
                    </Col>
                    <Col>
                      <AzureLoginButton />
                    </Col>
                  </Row>
                </div>
                {isAuthenticationError && (
                  <ErrorMessage>
                    {' '}
                    The system is unable to log you in. Please check your
                    credentials or notify customer support{' '}
                  </ErrorMessage>
                )}
              </DivForm>
            </Col>
          </Row>
        </Container>
      </Card>
    </Wrapper>
  )
}
export default Login
