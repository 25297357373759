import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Stack, Toast } from 'react-bootstrap'
import { BsExclamationTriangle } from 'react-icons/bs'

const StyledToast = styled(Toast)`
  position: fixed;
  top: 0;
  right: 0;
  width: 18.75rem;
  margin-top: 1rem;
  margin-right: 1rem;
  z-index: 1051;
`

const ToastWarningFields = ({ show, setShow, fields }) => {
  let fieldsData = ''
  if (fields !== undefined && fields.length > 0) {
    fields.forEach(field => (fieldsData += ` ${field},`))
  }
  return (
    <StyledToast
      onClose={() => setShow(false)}
      show={show}
      delay={6000}
      autohide
    >
      <Toast.Header>
        <Stack gap={2} direction="horizontal">
          <BsExclamationTriangle />
          {fields !== undefined ? (
            <span>Warning! The following fields are missing:{fieldsData}</span>
          ) : (
            <span>Warning! There are some missing fields</span>
          )}
        </Stack>
      </Toast.Header>
    </StyledToast>
  )
}

ToastWarningFields.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  fields: PropTypes.array,
}

export default ToastWarningFields
