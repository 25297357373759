import { useState, useEffect } from 'react'
import { startRecording, saveRecording } from '../handlers/recorder-controls'

const initialState = {
  recordingMinutes: 0,
  recordingSeconds: 0,
  initRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,
}

const MAX_RECORDER_TIME = 1 // Constrataint that represent max recorder time in minutes.
const EXECUTION_INTERVAL = 1000 // In milliseconds

// You can read full documentation here https://developer.mozilla.org/en-US/docs/Web/API/MediaStream_Recording_API
export default function useRecorder() {
  const [recorderState, setRecorderState] = useState(initialState)

  useEffect(() => {
    let recordingInterval = null // Saving instance ref to the recording interval object

    if (recorderState.initRecording)
      recordingInterval = setInterval(() => {
        setRecorderState(prevState => {
          const shouldClearInterval =
            prevState.recordingMinutes === MAX_RECORDER_TIME &&
            prevState.recordingSeconds === 0

          if (shouldClearInterval) {
            clearInterval(recordingInterval)
            return prevState
          }

          // Increment recordingSeconds by 1
          const shouldIncrementRecordingSeconds =
            prevState.recordingSeconds >= 0 && prevState.recordingSeconds < 59
          if (shouldIncrementRecordingSeconds)
            return {
              ...prevState,
              recordingSeconds: prevState.recordingSeconds + 1,
            }

          // Increment recordingMinutes by 1
          const shouldIncrementRecordingMinutes =
            prevState.recordingSeconds === 59
          if (shouldIncrementRecordingMinutes)
            return {
              ...prevState,
              recordingMinutes: prevState.recordingMinutes + 1,
              recordingSeconds: 0,
            }
        })
      }, EXECUTION_INTERVAL)
    else clearInterval(recordingInterval)

    return () => clearInterval(recordingInterval) // cleanUp function
  })

  // Triggered when recorderState.mediaStream changes
  useEffect(() => {
    if (recorderState.mediaStream)
      setRecorderState(prevState => {
        return {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream),
        }
      })
  }, [recorderState.mediaStream])

  // Triggered when recorderState.mediaRecorder changes
  useEffect(() => {
    const recorder = recorderState.mediaRecorder
    let chunks = []

    if (recorder && recorder.state === 'inactive') {
      recorder.start()

      /* The dataavailable event is fired when the MediaRecorder delivers media data to your application 
      for its use. The data is provided in a Blob object that contains the data.  
      https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/dataavailable_event */
      recorder.ondataavailable = e => {
        chunks.push(e.data)
      }

      /* The stop event is fired when MediaRecorder.stop() is called, 
      or when the media stream being captured ends. In each case, 
      the stop event is preceded by a dataavailable event, 
      making the Blob captured up to that point available for you to use in your application. 
      https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/stop_event
      */
      recorder.onstop = () => {
        const blob = new Blob(chunks, { type: 'audio/mp3' })
        chunks = []

        setRecorderState(prevState => {
          if (prevState.mediaRecorder)
            return {
              ...initialState,
              audio: window.URL.createObjectURL(blob),
            }
          else return initialState
        })
      }
    }

    // cleanUp function
    return () => {
      if (recorder) {
        // stop all audio tracks inside recorder stream
        recorder.stream.getAudioTracks().forEach(track => track.stop())
      }
    }
  }, [recorderState.mediaRecorder])

  return {
    recorderState,
    startRecording: () => startRecording(setRecorderState),
    cancelRecording: () => setRecorderState(initialState),
    saveRecording: () => saveRecording(recorderState.mediaRecorder),
    resetRecorderState: () => setRecorderState(initialState),
  }
}
