import React, { useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { DragDropContext } from 'react-beautiful-dnd'
import DraggableElement from '../DraggableElement/DraggableElement'
import './DragList.css'

const ListGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  > div {
    width: calc(${p => Math.floor(100 / p.columnCount)}% - 1.5rem);
  }
`

const removeFromList = (list, index) => {
  const result = Array.from(list)
  const [removed] = result.splice(index, 1)
  return [removed, result]
}

const addToList = (list, index, element) => {
  const result = Array.from(list)
  result.splice(index, 0, element)
  return result
}

const DragList = ({
  dragDataColumns,
  dragDataColumnsControls = {},
  dragComponent,
  onDrag,
}) => {
  const converData = () => {
    const dragListItem = {}
    dragDataColumns.forEach(dataColumn => {
      dragListItem[dataColumn.header] = dataColumn.dragListItems
    })
    return dragListItem
  }
  const [elements, setElements] = React.useState(converData())

  useEffect(() => {
    setElements(converData())
  }, [dragDataColumns])

  const onDragEnd = result => {
    if (!result.destination) {
      return
    }
    const listCopy = { ...elements }
    const sourceList = listCopy[result.source.droppableId]
    const [removedElement, newSourceList] = removeFromList(
      sourceList,
      result.source.index
    )
    listCopy[result.source.droppableId] = newSourceList
    const destinationList = listCopy[result.destination.droppableId]
    listCopy[result.destination.droppableId] = addToList(
      destinationList,
      result.destination.index,
      removedElement
    )
    setElements(listCopy)
    if (onDrag) onDrag({ ...result, dragItem: removedElement })
  }

  const isDropDisabled = key => {
    const dataColumn = dragDataColumns.find(
      dataColumn => dataColumn.header === key
    )
    if (dataColumn) {
      return !!dataColumn.isDropDisabled
    }
    return false
  }

  const isDragDisabled = key => {
    const dataColumn = dragDataColumns.find(
      dataColumn => dataColumn.header === key
    )
    if (dataColumn) {
      return !!dataColumn.isDragDisabled
    }
    return false
  }

  const hiddenHeader = key => {
    const dataColumn = dragDataColumns.find(
      dataColumn => dataColumn.header === key
    )
    if (dataColumn) {
      return !!dataColumn.hiddenHeader
    }
    return false
  }

  return (
    <div className="dragdrop-context-container">
      <DragDropContext onDragEnd={onDragEnd}>
        <ListGrid columnCount={Object.keys(elements).length}>
          {Object.keys(elements).map(listKey => (
            <DraggableElement
              elements={elements[listKey]}
              key={`${JSON.stringify(listKey)}-itemCount-${
                elements[listKey].length
              }`}
              prefix={listKey}
              dragComponent={dragComponent}
              isDropDisabled={isDropDisabled(listKey)}
              isDragDisabled={isDragDisabled(listKey)}
              hiddenHeader={hiddenHeader(listKey)}
            >
              {dragDataColumnsControls[listKey]}
            </DraggableElement>
          ))}
        </ListGrid>
      </DragDropContext>
    </div>
  )
}
DragList.propTypes = {
  dragDataColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  dragDataColumnsControls: PropTypes.object,
  dragComponent: PropTypes.func,
  onDrag: PropTypes.func,
}

export default DragList
