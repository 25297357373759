import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { prop, propEq } from 'ramda'
import { Accordion, Button, Col, Container, Row, Stack } from 'react-bootstrap'
import { getUser, getUserOrganizationId } from '../../utils/state/selectors'
import { useParams } from 'react-router-dom'
import Api from '../../api/event'
import TiersTable from '../CreateEvent/TiersTable'
import AddTierForm from '../CreateEvent/AddTierForm'
import AddGuestsForm from '../CreateEvent/AddGuestsForm'
import GuestsTable from '../CreateEvent/GuestsTable'
//import AddTasksForm from '../CreateEvent/AddTasksForm'
import TasksTable from '../CreateEvent/TasksTable'
import TitleBreadcrumbs from '../ui-elements/TitleBreadcrumbs'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import SummaryModal from '../CreateEvent/SummaryModal'
import EventForm from '../CreateEvent/EventForm'
import taskDescriptions from '../../assets/datasets/task-descriptions.json'
import ToastWarningFields from '../CreateEvent/ToastWarningFields'

const initialEventFormData = {
  name: '',
  state: '',
  city: '',
  street: '',
  day: '',
  hour: '',
  venue: '',
  venueTBD: false,
  locationRSVP: false,
  financialGoal: 0,
  suggestedDonation: 0,
  suggestedDonationTBD: false,
}

const Wrapper = styled.div`
  padding: 1rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

const FormsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

/*const formModelToDBModel = ({ eventFormData, tiers, attendees, tasks }) => ({
  eventData: eventFormData,
  tiers,
  aggregates: {
    attendees,
    tasks,
  },
})*/

const EventEditPage = () => {
  const organizationId = useSelector(getUserOrganizationId)

  //usePageHeadings('Create event', [])
  const user = useSelector(getUser)
  const [eventModel, setEventModel] = useState({
    eventFormData: initialEventFormData,
    tiers: [],
    attendees: { staffers: [], bundlers: [], specialGuests: [] },
    tasks: [],
  })
  const [checkEvent, setCheckEvent] = useState(true)
  const [loadedEvent, setLoadedEvent] = useState(false)
  //const [deletedTasks, setDeletedTask] = useState([])
  const [deletedGuests, setDeletedGuests] = useState([])
  const navigate = useNavigate()
  const { eventId } = useParams()
  const [event, setEvent] = useState(null)
  const [consolidated, setConsolidated] = useState(null)

  useEffect(() => {
    let isMounted = true
    Api.getEvent(eventId)
      .then(e => {
        if (isMounted) setEvent(e)
        console.log(e)
      })
      .then(() => Api.getConsolidatedSummary(user, eventId))
      .then(consRes => {
        if (isMounted) setConsolidated(consRes)
      })
    return () => {
      isMounted = false
    }
  }, [])

  useEffect(() => {
    if (event && consolidated) {
      const eventFormInfo = {
        name: event.name,
        state: event.state,
        city: event.city,
        day: event.day,
        hour: event.hour,
        street: event.street,
        venue: event.venue,
        venueTBD: event.venueTBD,
        locationRSVP: event.locationRSVP,
        financialGoal: event.financialGoal,
        suggestedDonation: event.suggestedDonation,
        suggestedDonationTBD: event.suggestedDonationTBD,
      }

      const eventTiersInfo = event?.tiers?.map(tier => {
        return { ...tier, _id: nanoid() }
      })

      let guestsInfo = []
      if (consolidated?.attendees && consolidated?.attendees?.guests) {
        guestsInfo = consolidated?.attendees?.guests.map(
          ({ _id, guestName }) => {
            return {
              _id: _id,
              guestName: guestName,
              isNew: false,
            }
          }
        )
      }

      let taskInfo = []
      if (consolidated.tasks && consolidated.tasks.eventTasks.length > 0) {
        taskInfo = consolidated.tasks.eventTasks.map(
          ({ _id, description, owner, markCompleted }) => {
            return {
              _id: _id,
              description: description,
              contact: owner,
              isNew: false,
              markCompleted: markCompleted,
            }
          }
        )
      } else if (event && consolidated) {
        taskInfo = taskDescriptions.map(task => {
          return {
            _id: nanoid(),
            contact: '',
            description: task,
            isNew: true,
            markCompleted: false,
          }
        })
      }

      const attendeeInfo = {
        ...eventModel.attendees,
        specialGuests: guestsInfo,
      }

      setEventModel(m => ({
        ...m,
        eventFormData: eventFormInfo,
        tiers: eventTiersInfo,
        tasks: taskInfo,
        attendees: attendeeInfo,
      }))
      setLoadedEvent(true)
    }
  }, [event, consolidated])

  useEffect(() => {
    const { eventFormData } = eventModel
    if (eventFormData && loadedEvent) {
      const {
        name,
        state,
        city,
        day,
        hour,
        venue,
        locationRSVP,
        venueTBD,
        financialGoal,
        suggestedDonation,
      } = eventFormData
      const checkEmpty = checkDifferentEmpty({
        name,
        state,
        city,
        day,
        hour,
      })
      const checkInt = financialGoal != 0 && suggestedDonation != 0
      const checkVenue = venue !== '' || locationRSVP || venueTBD
      if (checkEmpty && checkVenue && checkInt) {
        setCheckEvent(true)
      } else {
        setCheckEvent(false)
      }
    }
  }, [eventModel, loadedEvent])

  const checkDifferentEmpty = objFields => {
    return Object.values(objFields).every(value => value != '')
  }

  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.EVENTS(false),
    mainBreadcrumbs.EDIT_EVENT(event, true),
  ]

  const [showToast, setShowToast] = useState(false)

  const [show, setShow] = useState(false)

  const toggleShow = () => setShow(!show)

  const eventFormChangeHandler = updatedData =>
    setEventModel(m => ({
      ...m,
      eventFormData: { ...m.eventFormData, ...updatedData },
    }))

  const addTierHandler = tierData => {
    const newTier = {
      _id: nanoid(),
      ...tierData,
    }
    setEventModel(model => ({
      ...model,
      tiers: [...model.tiers, newTier],
    }))
  }

  const removeTierHandler = tierId =>
    setEventModel(m => ({
      ...m,
      tiers: m.tiers.reduce(
        (acc, tier) => (tier._id === tierId ? acc : [...acc, tier]),
        []
      ),
    }))

  const editTierHandler = updatedTier => {
    const tierId = prop('_id', updatedTier)
    const tierList = eventModel.tiers
    const updateIndex = tierList.findIndex(propEq('_id', tierId))
    const updatedTierList = [
      ...tierList.slice(0, updateIndex),
      updatedTier,
      ...tierList.slice(updateIndex + 1),
    ]
    setEventModel(model => ({
      ...model,
      tiers: updatedTierList,
    }))
  }

  const addGuestHandler = guestData => {
    const newGuest = {
      _id: nanoid(),
      ...guestData,
      isNew: true,
    }
    setEventModel(model => ({
      ...model,
      attendees: {
        ...model.attendees,
        specialGuests: [...model.attendees.specialGuests, newGuest],
      },
    }))
  }

  const removeGuestHandler = guestId => {
    if (eventModel?.attendees?.specialGuests) {
      const guestToBeDeleted = eventModel?.attendees?.specialGuests.find(
        x => x._id === guestId
      )
      if (guestToBeDeleted && !guestToBeDeleted?.isNew) {
        setDeletedGuests(current => [...current, guestId])
      }
    }

    setEventModel(model => ({
      ...model,
      attendees: {
        ...model.attendees,
        specialGuests: model.attendees.specialGuests.reduce(
          (acc, guest) => (guest._id === guestId ? acc : [...acc, guest]),
          []
        ),
      },
    }))
  }

  const editGuestHandler = updatedGuest => {
    const guestId = prop('_id', updatedGuest)
    const guestList = eventModel.attendees.specialGuests
    const updateIndex = guestList.findIndex(propEq('_id', guestId))
    const updatedGuestList = [
      ...guestList.slice(0, updateIndex),
      updatedGuest,
      ...guestList.slice(updateIndex + 1),
    ]
    setEventModel(model => ({
      ...model,
      attendees: {
        ...model.attendees,
        specialGuests: updatedGuestList,
      },
    }))
  }

  /*
  const addTaskHandler = taskData => {
    const newTask = {
      _id: nanoid(),
      isNew: true,
      ...taskData,
    }
    setEventModel(m => ({
      ...m,
      tasks: [...m.tasks, newTask],
    }))
  }

  const removeTaskHandler = taskId => {
    if (eventModel?.tasks) {
      const taskToBeDeleted = eventModel?.tasks.find(x => x._id === taskId)
      if (taskToBeDeleted && !taskToBeDeleted?.isNew) {
        setDeletedTask(current => [...current, taskId])
      }
    }
    setEventModel(m => ({
      ...m,
      tasks: m.tasks.reduce(
        (acc, task) => (task._id === taskId ? acc : [...acc, task]),
        []
      ),
    }))
  }*/

  const editTaskHandler = updatedTask => {
    const taskId = prop('_id', updatedTask)
    const taskList = eventModel.tasks
    const updateIndex = taskList.findIndex(propEq('_id', taskId))
    const updatedTaskList = [
      ...taskList.slice(0, updateIndex),
      updatedTask,
      ...taskList.slice(updateIndex + 1),
    ]
    setEventModel(model => ({
      ...model,
      tasks: updatedTaskList,
    }))
  }

  const onEditAllTaskHandler = updatedTask => {
    const taskId = prop('_id', updatedTask)
    const taskList = eventModel.tasks
    const updateIndex = taskList.findIndex(propEq('_id', taskId))
    const updatedTaskList = [
      ...taskList.slice(0, updateIndex),
      updatedTask,
      ...taskList.slice(updateIndex + 1),
    ]
    setEventModel(model => ({
      ...model,
      tasks: updatedTaskList.map(task => {
        return {
          ...task,
          contact: updatedTask.contact,
        }
      }),
    }))
  }

  const saveHandler = () => {
    if (checkEvent) {
      const newSpecialGuests = eventModel.attendees.specialGuests.filter(
        obj1 =>
          !consolidated?.attendees?.guests.some(obj2 => obj1._id === obj2._id)
      )
      eventModel.attendees.specialGuests = newSpecialGuests
      const eventData = {
        _id: eventId,
        eventData: eventModel.eventFormData, // <-- es el 1er formulario
        tiers: eventModel.tiers, // <-- arreglo de tiers tal cual
        aggregates: {
          attendees: eventModel.attendees,
          tasks: eventModel.tasks,
          deletedTasks: [],
          deletedGuests: deletedGuests,
        },
        organizationId,
      }
      Api.updateEvent(eventData, user)
        .then(prop('_id'))
        .then(id => navigate(`/events/${id}`))
    } else {
      setShowToast(true)
    }
  }

  const cancelHandler = () => navigate(`/events/${eventId}`)

  return (
    <>
      <ToastWarningFields show={showToast} setShow={setShowToast} />
      <TitleBreadcrumbs title={`${event?.name}`} breadcrumbs={breadcrumbs}>
        <Button onClick={() => cancelHandler()} variant="light">
          Cancel
        </Button>
        <Button variant="secondary" onClick={() => toggleShow()}>
          Save Changes
        </Button>
      </TitleBreadcrumbs>
      <Wrapper>
        <Container fluid>
          <Row>
            <Col>
              <FormsWrapper>
                <Accordion defaultActiveKey="0" className="w-100">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>General</Accordion.Header>
                    <Accordion.Body>
                      <EventForm
                        formData={eventModel.eventFormData}
                        onChange={eventFormChangeHandler}
                        updateFlag={false}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Tiers</Accordion.Header>
                    <Accordion.Body>
                      <div className="mb-4" />
                      <TiersTable
                        tiers={eventModel.tiers}
                        onEdit={editTierHandler}
                        onRemove={removeTierHandler}
                      />
                      <div className="mb-1" />
                      <AddTierForm onAddTier={addTierHandler} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Special Guests</Accordion.Header>
                    <Accordion.Body>
                      <div className="mb-4" />
                      <GuestsTable
                        guests={eventModel.attendees.specialGuests}
                        onEdit={editGuestHandler}
                        onRemove={removeGuestHandler}
                      />
                      <div className="mb-1" />
                      <AddGuestsForm onAddGuest={addGuestHandler} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Tasks</Accordion.Header>
                    <Accordion.Body>
                      <div className="mb-4" />
                      <TasksTable
                        tasks={eventModel.tasks}
                        onEdit={editTaskHandler}
                        onEditAll={onEditAllTaskHandler}
                      />
                      <div className="mb-1" />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </FormsWrapper>
            </Col>
          </Row>
          <SummaryModal
            show={show}
            onClose={() => toggleShow()}
            onCreate={() => saveHandler()}
            event={eventModel}
            isUpdate={true}
          />
        </Container>
      </Wrapper>
    </>
  )
}

export default EventEditPage
