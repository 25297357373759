import { Spinner } from 'react-bootstrap'

const CenterSpinner = () => {
  return (
    <center>
      <Spinner animation="border" variant="secondary" />
    </center>
  )
}

export default CenterSpinner
