import styled from 'styled-components'
import Container from 'react-bootstrap/Container'
import Leaderboard from '../Leaderboard/Leaderboard'
import EventsCard from '../EventsCard/EventsCard'
import TopLine from '../TopLines/TopLines'
import TasksBox from '../TasksBox//TasksBox'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import useDimensionScreen from '../../hooks/useDimensionScreen'
import './StafferLandingPage.css'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserOrganizationId } from '../../utils/state/selectors'
import ApiDonation from '../../api/donation'
import ApiUser from '../../api/user'
import ApiQueue from '../../api/queue'
import { Row, Col } from 'react-bootstrap'
import EventsApi from '../../api/event'
import moment from 'moment'
import { formatValue } from '../ui-elements/Money'
import constants from '../../config/constants'

const Wrapper = styled.div`
  padding: 5px;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`
const VersionWrapper = styled.div`
  text-align: end;
  margin-top: 0;
  margin-top: -20px;
  margin-right: 10px;
  color: lightslategray;
`

const MetricLabelDiv = styled.div`
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 26px;
  color: rgb(17, 39, 60);
  /* background-color: pink; */
`

const MetricValueDiv = styled.div`
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 56px;
  font-weight: 800;
  color: rgb(17, 39, 60);
  /* background-color: pink; */
`

const StafferLandingPage = () => {
  usePageHeadings('Dashboard')
  const [dimension] = useDimensionScreen()

  const isVisible = dimension.width > 480
  const leaderboardAdaptiveness = dimension.width > 1600
  const orgId = useSelector(getUserOrganizationId)
  const [bundlers, setBundlers] = useState([])
  const [noDonationBundlers, setNoDonationBundlers] = useState([])
  const [eventCount, setEventCount] = useState(0)
  const [asks, setAsks] = useState(0)
  const [queueValue, setQueueValue] = useState(undefined)

  const MAX_BUNDLERS = 10

  const inBundlers = user => {
    for (const bundler of bundlers) {
      if (user._id == bundler.bundlerInfo._id) return true
    }
    return false
  }

  const loadEvents = () => {
    EventsApi.getEvents().then(events =>
      setEventCount(
        events.filter(event => moment(event.day).isSameOrAfter(moment(), 'day'))
          .length
      )
    )
  }

  const loadQueueInfo = () => {
    ApiQueue.getNumberAsks().then(count => setAsks(count))
    ApiQueue.getQueueValue().then(val => setQueueValue(val))
  }

  // useEffect(() => {
  //   ApiDonation.getDonationsByBundler(orgId)
  //     .then(bundlers => {
  //       setBundlers(bundlers)
  //     })
  //     .catch(err => {
  //       console.log(err)
  //     })
  // }, [])

  useEffect(() => {
    ApiUser.getUsers(orgId, 'bundler')
      .then(users =>
        setNoDonationBundlers(
          users
            .filter(user => !inBundlers(user, bundlers))
            .map(user => ({
              bundlerInfo: {
                name: `${user.name} ${user.lastName}`,
              },
              amount: 0,
            }))
        )
      )
      .catch(err => {
        console.log(err)
      })
  }, [bundlers])

  useEffect(() => {
    loadEvents()
    loadQueueInfo()
  }, [])

  return (
    <Wrapper>
      <Container fluid>
        <Row>
          <Col md={6}>
            {isVisible && (
              <TopLine style={{ marginTop: '5px', fontSize: '28px' }} />
            )}
          </Col>
          <Col className="my-auto">
            <MetricValueDiv>{asks}</MetricValueDiv>
            <MetricLabelDiv>Active Asks</MetricLabelDiv>
          </Col>
          <Col className="my-auto">
            <MetricValueDiv>
              {queueValue !== undefined
                ? formatValue(queueValue, 0, 0, true)
                : '--'}
            </MetricValueDiv>
            <MetricLabelDiv>Queue Value</MetricLabelDiv>
          </Col>
          <Col className="my-auto">
            <MetricValueDiv>{eventCount}</MetricValueDiv>
            <MetricLabelDiv>Active Events</MetricLabelDiv>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <EventsCard style={{ fontSize: '28px' }} />
          </Col>
          <Col>
            {isVisible && (
              <div>
                <TasksBox style={{ fontSize: '28px' }} />
              </div>
            )}
          </Col>
          <Col
            style={{ ...(leaderboardAdaptiveness && { minWidth: '350px' }) }}
          >
            <Leaderboard
              bundlers={[
                ...bundlers,
                ...noDonationBundlers.slice(0, MAX_BUNDLERS - bundlers.length),
              ]}
              style={{ fontSize: '28px' }}
            />
          </Col>
        </Row>
      </Container>
      <VersionWrapper>Build: {constants.VERSION()}</VersionWrapper>
    </Wrapper>
  )
}

export default StafferLandingPage
