import { pathOr, prop } from 'ramda'
import types from '../types/types'

const initialState = {
  emailAccounts: {},
  emailAccountsPages: {},
  folder: 'inbox',
}

export const mailboxReducer = (state = initialState, action) => {
  const email = prop('email', action.payload)
  const limit = prop('limit', action.payload)
  const offset = prop('offset', action.payload)

  switch (action.type) {
    case types.MAILBOX_SET_FOLDER: {
      const folder = prop('folder', action.payload)
      return {
        ...state,
        folder,
      }
    }
    case types.MAILBOX_SET_PAGINATION: {
      return {
        ...state,
        emailAccounts: {
          [email]: { limit, offset },
        },
      }
    }
    case types.MAILBOX_SET_PAGE_DATA: {
      const count = prop('count', action.payload)

      const threads = prop('threads', action.payload)

      const emailPages = prop(email, prop('emailAccountsPages', state))

      const allThreads = [...pathOr([], ['threads'], emailPages)]

      Array.from(
        { length: threads.length === limit ? limit : threads.length },
        (_, k) => k + offset
      ).forEach(index => (allThreads[index] = threads[index - offset]))

      return {
        ...state,
        emailAccountsPages: {
          [email]: { ...emailPages, threads: allThreads, count },
        },
      }
    }
    case types.MAILBOX_SET_OPENED: {
      const emailPages = prop(email, prop('emailAccountsPages', state))
      const opened = prop('opened', action.payload)

      return {
        ...state,
        emailAccountsPages: {
          [email]: { ...emailPages, openedThreads: opened },
        },
      }
    }
    default:
      return state
  }
}

export default mailboxReducer
