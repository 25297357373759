import PropTypes from 'prop-types'

const NextIcon = ({ fill = '#576068' }) => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.484194 0.234682C0.519028 0.19976 0.56041 0.172053 0.605968 0.153148C0.651527 0.134243 0.700368 0.124512 0.749694 0.124512C0.799019 0.124512 0.84786 0.134243 0.893419 0.153148C0.938978 0.172053 0.980359 0.19976 1.01519 0.234682L5.51519 4.73468C5.55012 4.76952 5.57782 4.8109 5.59673 4.85646C5.61563 4.90202 5.62536 4.95086 5.62536 5.00018C5.62536 5.04951 5.61563 5.09835 5.59673 5.14391C5.57782 5.18947 5.55012 5.23085 5.51519 5.26568L1.01519 9.76568C0.944779 9.8361 0.849275 9.87566 0.749694 9.87566C0.650112 9.87566 0.554609 9.8361 0.484194 9.76568C0.413779 9.69527 0.37422 9.59976 0.37422 9.50018C0.37422 9.4006 0.413779 9.3051 0.484194 9.23468L4.71944 5.00018L0.484194 0.765682C0.449271 0.730848 0.421564 0.689466 0.402659 0.643907C0.383754 0.598349 0.374023 0.549508 0.374023 0.500182C0.374023 0.450857 0.383754 0.402016 0.402659 0.356457C0.421564 0.310898 0.449271 0.269516 0.484194 0.234682V0.234682Z"
        fill={fill}
      />
    </svg>
  )
}
NextIcon.propTypes = {
  fill: PropTypes.string,
}
export default NextIcon
