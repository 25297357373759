import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { Link, Outlet } from 'react-router-dom'
import styled from 'styled-components'
import { nanoid } from 'nanoid'

const HeadingsWrapper = styled.div`
  margin-left: 2rem;
`

const HrefLink = ({ href, children }) => <Link to={href}>{children}</Link>

HrefLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
}

const Item = ({ breadcrumb }) => (
  <Breadcrumb.Item
    href={breadcrumb.href}
    active={breadcrumb.active}
    linkAs={HrefLink}
  >
    {breadcrumb.title}
  </Breadcrumb.Item>
)

Item.propTypes = {
  breadcrumb: PropTypes.object,
}

const TitleBreadcrumbs = ({ title, breadcrumbs, children }) => {
  return (
    <>
      <HeadingsWrapper>
        <div className="d-flex">
          <div>
            <div>
              {title && <div className="h1">{title}</div>}
              {breadcrumbs.length > 0 && (
                <Breadcrumb>
                  {breadcrumbs.map(b => (
                    <Item key={nanoid()} breadcrumb={b} />
                  ))}
                </Breadcrumb>
              )}
            </div>
          </div>
          <div className="ms-auto my-auto">
            {children?.map((child, idx) => (
              <div
                style={{ float: 'right', marginLeft: '10px', marginTop: '5px' }}
                key={idx}
              >
                {child}
              </div>
            ))}
          </div>
        </div>
      </HeadingsWrapper>
      <Outlet />
    </>
  )
}

TitleBreadcrumbs.propTypes = {
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
}

export default TitleBreadcrumbs
