import moment from 'moment'
import { isEmpty, propEq, propOr } from 'ramda'
import { Button, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import {
  getCity,
  getDatetime,
  getEventId,
  getFinancialGoal,
  getState,
  getSuggestedDonation,
} from '../../../utils/dataModels/events'
import Money from '../Money'
import { FieldTitle, FieldValue, SectionTitle } from './EventModal'
import usStates from '../../../assets/datasets/us-states.json'
import PropTypes from 'prop-types'
import { getUserRole } from '../../../utils/state/selectors'
import { useSelector } from 'react-redux'
import { checkUserRole } from '../../../utils/state/roleTests'

const FundraisingEvent = ({ event }) => {
  const findStateName = state =>
    propOr('', 'name', usStates.find(propEq('state', state)))

  const navigate = useNavigate()
  const venue = event['venueTBD'] ? 'To be determined' : event['venue']
  const eventDatetime = isEmpty(event) ? Date.now() : getDatetime(event)
  const date = moment(eventDatetime).format('MMMM Do, YYYY')
  const time = moment(eventDatetime).format('h:mmA zz')

  const showMoreClickHandler = () => navigate(`/events/${getEventId(event)}`)

  const userRole = useSelector(getUserRole)

  return (
    <>
      <Modal.Body>
        <SectionTitle>Location Details</SectionTitle>
        <FieldTitle>Venue</FieldTitle>
        <FieldValue>{venue}</FieldValue>
        <div className="d-flex flex-row flex-nowrap justify-content-between">
          <div className="w-50">
            <FieldTitle>City</FieldTitle>
            <FieldValue>{getCity(event)}</FieldValue>
          </div>
          <div className="w-50">
            <FieldTitle>State</FieldTitle>
            <FieldValue>{findStateName(getState(event))}</FieldValue>
          </div>
        </div>

        <SectionTitle>Schedule</SectionTitle>
        <div className="d-flex flex-row flex-nowrap justify-content-between">
          <div className="w-50">
            <FieldTitle>Date</FieldTitle>
            <FieldValue>{date}</FieldValue>
          </div>
          <div className="w-50">
            <FieldTitle>Time</FieldTitle>
            <FieldValue>{time}</FieldValue>
          </div>
        </div>

        <SectionTitle>Financials</SectionTitle>
        <div className="d-flex flex-row flex-nowrap justify-content-between">
          <div className="w-50">
            <FieldTitle>Goal</FieldTitle>
            <FieldValue>
              <Money value={getFinancialGoal(event)} />
            </FieldValue>
          </div>
          <div className="w-50">
            <FieldTitle>Donation Level</FieldTitle>
            <FieldValue>
              <Money value={getSuggestedDonation(event)} />
            </FieldValue>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {checkUserRole(userRole) && (
          <Button
            type="button"
            variant="outline-secondary"
            onClick={showMoreClickHandler}
          >
            Show more
          </Button>
        )}
      </Modal.Footer>
    </>
  )
}
FundraisingEvent.propTypes = {
  event: PropTypes.object.isRequired,
}
export default FundraisingEvent
