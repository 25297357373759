import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Col,
  Container,
  Row,
  Stack,
  Tab,
  Tabs,
  Toast,
  ToastContainer,
} from 'react-bootstrap'
import styled from 'styled-components'
import { useParams, useNavigate } from 'react-router-dom'
import EventApi from '../../api/event'
import ContactApi from '../../api/contact'
import DonationApi from '../../api/donation'
import { addDonationData } from '../../api/donations_data'
import {
  getRefreshConsolidated,
  getUser,
  getRefreshDonations,
  getUserFullName,
  getUserOrganizationId,
} from '../../utils/state/selectors'
import { getUserToken } from '../../utils/dataModels/user'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import TitleBreadcrumbs from '../ui-elements/TitleBreadcrumbs'
import ActionButton from '../ui-elements/ActionButton'
import EventDropdown from './EventDropdown'
import EventTasks from './EventTasks'
import EventSummary from './EventSummary'
import DragBoard from './DragBoard'
import { useDispatch, useSelector } from 'react-redux'
import {
  setRefreshConsolidated,
  setRefreshDonations,
} from '../../redux/pipeline/pipeline'
import './styles.css'
import PipelineHeaderButtons from '../PipelineCRM/PipelineHeaderButtons'
import EventDetailsContainer from './EventDetailsContainer'
import { getAvatarContact } from '../../utils/stringsOperations'
import { getEventId } from '../../utils/dataModels/events'
import CRMTable from '../PipelineCRM/CRMTable'
import columns from './eventDetailColumns'
import { getWinRedUrl } from './utils'
import csvDownload from 'json-to-csv-export'
import AddContactsModal from '../PipelineCRM/AddContactsModal'
import MailboxCompose from '../MailBoxPage/MailboxCompose/MailboxCompose'
import { sendNylasEmail } from '../../api/nylas/mailbox'
import { path, isEmpty } from 'ramda'
import { mandatoryFields } from '../MailBoxPage/MailboxCompose/config'

const initialStateComposeEmail = {
  to: [],
  cc: [],
  bcc: [],
  subject: '',
  body: '',
}

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const Wrapper = styled.div`
  padding: 1rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

function useSkipper() {
  const shouldSkipRef = useRef(true)
  const shouldSkip = shouldSkipRef.current

  const skip = useCallback(() => {
    shouldSkipRef.current = false
  }, [])

  useEffect(() => {
    shouldSkipRef.current = true
  })

  return [shouldSkip, skip]
}

const EventDetailPage = () => {
  const providerAccount = getUserToken()
  const userFullName = getUserFullName()
  const organizationId = useSelector(getUserOrganizationId)
  const dispatch = useDispatch()
  const refreshConsolidated = useSelector(getRefreshConsolidated)
  const refreshDonations = useSelector(getRefreshDonations)
  const { eventId } = useParams()

  /* Event detail state */
  const [event, setEvent] = useState(null)
  const [consolidated, setConsolidated] = useState({})
  const [eventCode, setEventCode] = useState('')

  /* Add Contacts Modal */
  const [showAddContactsModal, setShowAddContactsModal] = useState(false)

  /* Email Merge */
  const [showCompose, setShowCompose] = useState(false)
  const [composeEmail, setComposeEmail] = useState(initialStateComposeEmail)
  const [isSending, setIsSending] = useState(false)
  const [sendStatus, setSendStatus] = useState('')
  const [toastHeader, setToastHeader] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [defaultEmailTarget, setDefaultEmailTarget] = useState([])

  /* Event detail table state */
  const [eventTransactions, setEventTransactions] = useState([])
  const [eventHosts, setEventHosts] = useState([])
  const [loadingEventAttendees, setLoadingEventAttendees] = useState(false)
  const [eventAttendees, setEventAttendees] = useState([])
  const [eventAttendeeFilters, setEventAttendeeFilters] = useState([])
  const [eventAttendeeRowSelection, setEventAttendeeRowSelection] = useState({})
  const [eventAttendeeSorting, setEventAttendeeSorting] = useState([])
  const [eventAttendeePagination, setEventAttendeePagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  })
  const refreshData = () => setEventAttendees(() => [])

  const eventAttendeeColumns = useMemo(() => columns, [columns])

  const crmTableProps = {
    columns: eventAttendeeColumns,
    data: eventAttendees,
    loadingData: loadingEventAttendees,
    setData: setEventAttendees,
    columnFilters: eventAttendeeFilters,
    setColumnFilters: setEventAttendeeFilters,
    rowSelection: eventAttendeeRowSelection,
    setRowSelection: setEventAttendeeRowSelection,
    sorting: eventAttendeeSorting,
    setSorting: setEventAttendeeSorting,
    pagination: eventAttendeePagination,
    setPagination: setEventAttendeePagination,
  }

  const user = useSelector(getUser)
  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.EVENTS(false),
    mainBreadcrumbs.EVENT_PAGE(event ?? { eventId, title: '' }, true),
  ]
  const navigate = useNavigate()
  const shareEventInviteHandler = () => navigate(`/invite/${eventId}`)

  useEffect(() => {
    dispatch(setRefreshConsolidated(true))
  }, [])

  useEffect(() => {
    let isMounted = true

    if (refreshConsolidated) {
      setLoadingEventAttendees(true)
      let tempEvent
      let tempHosts
      EventApi.getEvent(eventId)
        .then(e => {
          tempEvent = e
          if (isMounted) {
            setEvent(e)
            const hosts = e.tiers
              ? e.tiers.reduce((tieredGuests, currentTier) => {
                  return tieredGuests.concat(
                    currentTier.participants.map(p => ({
                      ...p,
                      // donationType: 'paid',
                      donation: currentTier.tierLevel,
                      first_name: p.first_name || p.firstName,
                      last_name: p.last_name || p.lastName,
                      vip: true,
                    }))
                  )
                }, [])
              : []
            tempHosts = hosts
            if (hosts.length) {
              ContactApi.filterContacts([
                {
                  $match: {
                    $or: hosts.map(({ first_name, last_name }) => ({
                      first_name,
                      last_name,
                    })),
                  },
                },
                {
                  $project: {
                    _id: 0,
                    first_name: '$first_name',
                    last_name: '$last_name',
                    donation_capacity: '$donation_capacity',
                  },
                },
              ]).then(filterContactsRes => {
                setEventHosts(tempHosts)
                setEventTransactions(tempEvent.donations)
                setEventAttendees([
                  ...tempEvent.donations
                    .filter(donation => donation.donor)
                    .map(donation => ({
                      ...donation.donor,
                      eventId,
                      donation: donation.donation_total,
                      donationType: donation.donation_type,
                      donationStatus: donation.donation_status,
                      donationNotes: donation.donation_notes,
                      electionType: donation.election_type,
                    })),
                  ...tempHosts.map(host => ({
                    ...host,
                    donation_capacity: filterContactsRes.find(
                      contact =>
                        host.first_name === contact.first_name &&
                        host.last_name === contact.last_name
                    )
                      ? filterContactsRes.find(
                          contact =>
                            host.first_name === contact.first_name &&
                            host.last_name === contact.last_name
                        ).donation_capacity
                      : undefined,
                  })),
                ])
                setLoadingEventAttendees(false)
              })
            } else {
              setEventAttendees(
                tempEvent.donations
                  .filter(donation => donation.donor)
                  .map(donation => ({
                    ...donation.donor,
                    eventId,
                    donation: donation.donation_total,
                    donationType: donation.donation_type,
                    donationStatus: donation.donation_status,
                    donationNotes: donation.donation_notes,
                    electionType: donation.election_type,
                  }))
              )
              setLoadingEventAttendees(false)
            }
          }
        })
        .then(() => EventApi.getConsolidatedSummary(user, eventId))
        .then(consRes => {
          if (isMounted) {
            setConsolidated(consRes)
            dispatch(setRefreshConsolidated(false))
          }
        })
    }
    return () => {
      isMounted = false
    }
  }, [refreshConsolidated])

  // useEffect(() => {
  //   let isMounted = true
  //   ;(async () => {
  //     const transactions = await DonationApi.getEventTransactions(eventId)
  //     if (isMounted) {
  //       setEventTransactions(transactions)
  //     }
  //   })().catch(console.log)
  //   return () => {
  //     isMounted = false
  //   }
  // }, [])

  // useEffect(() => {
  //   let isMounted = true
  //   DonationApi.getEventTransactions(eventId)
  //     .then(transactions => {
  //       if (isMounted && refreshDonations) {
  //         setEventTransactions(transactions)
  //         dispatch(setRefreshDonations(false))
  //       }
  //     })
  //     .catch(console.log)
  //   return () => {
  //     isMounted = false
  //   }
  // }, [refreshDonations])

  useEffect(() => {
    if (event) {
      setEventCode(event.name)
    }
  }, [event])

  /* Event attendee data filtering */
  const handleStatusFilter = status => {
    if (status.length > 0) {
      setEventAttendeeFilters([
        {
          id: 'status',
          value: status,
        },
      ])
    } else setEventAttendeeFilters([])
  }
  const handleCheckIn = () => {
    const dataToConvert = {
      data: eventAttendees.map(attendee => ({
        attend: '',
        first_name: attendee.first_name,
        last_name: attendee.last_name,
        paid: attendee.donationType === 'paid' ? attendee.donation : '',
        owes: attendee.donationType === 'pledge' ? attendee.donation : '',
        notes:
          '                                                                              ',
      })),
      filename: 'CheckIn',
      delimiter: ',',
      headers: ['Attend', 'First Name', 'Last Name', 'Paid', 'Owes', 'Notes'],
    }
    csvDownload(dataToConvert)
  }

  const pipelineHeaderButtonProps = {
    openAddContactsModal: () => setShowAddContactsModal(true),
    openEmailMerge: () => {
      const selectedEmails = Object.keys(eventAttendeeRowSelection).map(
        key => eventAttendees[key].email
      )
      const filteredEmails = selectedEmails.filter(email => email)
      setDefaultEmailTarget(filteredEmails)
      setShowCompose(true)
    },
    event: true,
  }

  const setComposeEmailPropValue = (fieldName, value) => {
    setComposeEmail(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const inputChangeHandler = e => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
      const fieldName = path(['target', 'id'], e)
      const value = path(['target', 'value'], e)
      setComposeEmailPropValue(fieldName, value)
    }
  }

  const handleCloseMailMerge = () => {
    setComposeEmail(initialStateComposeEmail)
    setShowCompose(false)
  }

  const validFormSentEmail = composeEmail => {
    const { to } = composeEmail
    return to?.length
  }

  const replaceBodyField = (body, contact) => {
    let bodyMerge = body
    mandatoryFields.forEach(mandatoryField => {
      bodyMerge = bodyMerge.replaceAll(
        `[[${mandatoryField.value}]]`,
        contact[mandatoryField.value]
      )
    })
    return bodyMerge
  }

  const handleSendClick = (mailMerge, contacts) => {
    const { to, subject, body, cc, bcc } = composeEmail
    if (!validFormSentEmail(composeEmail)) {
      setSendStatus(
        <strong className="text-danger">Fill at least To field.</strong>
      )
      setToastHeader('Send Email Status')
      setShowToast(true)
      return
    }
    setSendStatus('')
    setIsSending(true)
    const toTarget = to.map(toItem => ({ email: toItem, name: toItem }))
    const ccTarget = cc.map(ccItem => ({ email: ccItem, name: ccItem }))
    const bccTarget = bcc.map(bccItem => ({ email: bccItem, name: bccItem }))
    let promises = []
    if (mailMerge) {
      if (contacts.length) {
        const matchingPageContacts = eventAttendees.reduce(
          (matches, currentItem) => {
            let currentMatches = matches
            const contactMatch = toTarget.find(
              target => target.email === currentItem.email
            )
            if (contactMatch) {
              currentMatches.push(currentItem)
            }
            return currentMatches
          },
          []
        )
        const currentContacts = [...contacts, ...matchingPageContacts]
        promises = currentContacts.map(contact =>
          sendNylasEmail(
            {
              email: providerAccount?.email || 'thebenjaminapp@gmail.com',
              name: userFullName,
            },
            [{ email: contact.email, name: contact.first_name }], // To
            subject,
            replaceBodyField(body, contact),
            ccTarget,
            bccTarget,
            providerAccount?.token,
            true,
            organizationId
          )
        )
      } else if (toTarget.length) {
        const matchingContacts = eventAttendees.reduce(
          (matches, currentItem) => {
            let currentMatches = matches
            const contactMatch = toTarget.find(
              target => target.email === currentItem.email
            )
            if (contactMatch) {
              currentMatches.push(currentItem)
            }
            return currentMatches
          },
          []
        )
        promises = matchingContacts.map(contact =>
          sendNylasEmail(
            {
              email: providerAccount?.email || 'thebenjaminapp@gmail.com',
              name: userFullName,
            },
            [{ email: contact.email, name: contact.first_name }],
            subject,
            replaceBodyField(body, contact),
            ccTarget,
            bccTarget,
            providerAccount?.token,
            true,
            organizationId
          )
        )
      }
    } else {
      promises = [
        sendNylasEmail(
          {
            email: providerAccount?.email || 'thebenjaminapp@gmail.com',
            name: userFullName,
          },
          toTarget,
          subject,
          body,
          ccTarget,
          bccTarget,
          providerAccount?.token,
          true,
          organizationId
        ),
      ]
    }
    Promise.allSettled(promises)
      .then(() => {
        setSendStatus('Email was sent successfully')
        setShowCompose(false)
      })
      .catch(() => {
        setSendStatus('Something is wrong, please try again.')
      })
      .finally(() => {
        setToastHeader('Send Email Status')
        setShowToast(true)
        setIsSending(false)
        // setTimeout(() => setUpdate(update + 1), 500)
      })
  }

  const handleAddContacts = async contacts => {
    const newDonationData = contacts.map(c => ({
      contact_person_id: c.contact_person_id,
      donation_date: new Date(),
      donation_total: 0,
      organizationId: c.organizationId,
      election_type: 'General',
      committee: '',
      event_id: eventId,
      donation_type: DonationApi.transactionTypes.PROSPECT,
      bundlers: [user._id],
      donation_notes: '',
      donation_status: '',
    }))
    const add = await addDonationData(newDonationData)
    if (add) {
      dispatch(setRefreshConsolidated(true))
    }
  }

  return (
    <>
      <TitleBreadcrumbs
        title={eventCode.slice(0, 15)}
        breadcrumbs={breadcrumbs}
      >
        <div className="three-dot-event-dropdown">
          <EventDropdown
            onShareEvent={() => shareEventInviteHandler()}
            editEvent={() => navigate(`/events/${eventId}/edit`)}
          />
        </div>
        <div />
      </TitleBreadcrumbs>
      <Wrapper>
        <Container fluid>
          <Row>
            <>
              <Col lg={3} md={6} sm={6}>
                <EventDetailsContainer
                  consolidated={consolidated}
                  event={event}
                  eventCode={eventCode}
                  invite={shareEventInviteHandler}
                  handleEventDetailsFilter={handleStatusFilter}
                  handleCheckIn={handleCheckIn}
                />
              </Col>
              <Col lg={9} md={6} sm={6}>
                <div>
                  <PipelineHeaderButtons {...pipelineHeaderButtonProps} />
                  <div
                    style={{
                      display: 'flex',
                      gap: '8px',
                      overflowX: 'auto',
                      marginTop: '-8px',
                      marginBottom: '12px',
                    }}
                  >
                    {eventHosts.map((p, idx) => (
                      <div
                        key={idx}
                        style={{
                          border: 'solid 2px #ededed',
                          borderRadius: '4px',
                          padding: '8px',
                        }}
                      >
                        <div className="event-name-cell">
                          {getAvatarContact(p, '30')}
                          <div key={idx}>{p.value}</div>
                        </div>
                        <div
                          style={{
                            textAlign: 'center',
                            fontWeight: 'bold',
                          }}
                        >
                          {moneyFormatter.format(p.donation)}
                        </div>
                      </div>
                    ))}
                  </div>
                  <CRMTable {...crmTableProps} />
                </div>
              </Col>
            </>
          </Row>
        </Container>
      </Wrapper>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          placement=""
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">{toastHeader}</strong>
          </Toast.Header>
          <Toast.Body>{sendStatus}</Toast.Body>
        </Toast>
      </ToastContainer>
      <AddContactsModal
        show={showAddContactsModal}
        onHide={() => setShowAddContactsModal(false)}
        addContacts={handleAddContacts}
      />
      <MailboxCompose
        defaultEmailTarget={defaultEmailTarget}
        showCompose={showCompose}
        setComposeEmailPropValue={setComposeEmailPropValue}
        handleClose={handleCloseMailMerge}
        composeEmail={composeEmail}
        handleSendClick={handleSendClick}
        inputChangeHandler={inputChangeHandler}
        isSending={isSending}
      />
    </>
  )
}

export default EventDetailPage
