import { LinkedInCallback } from 'react-linkedin-login-oauth2'
import { Navigate, Route, Routes } from 'react-router-dom'
import DownloadInvite from '../components/DownloadInvite/DownloadInvite'
import LoginLayout from '../components/Layout/LoginLayout'
import SimpleLayout from '../components/Layout/SimpleLayout'
import Login from '../components/Login/Login'
import OAuth2Callback from '../components/LoginButton/OAuth2Calback'
import InvitePage from '../components/InvitePage/InvitePage'

const AuthenticationRouter = () => {
  return (
    <Routes>
      <Route path="/">
        <Route element={<SimpleLayout />}>
          <Route
            path="/download-invite/:eventId"
            element={<DownloadInvite />}
          />
          <Route
            path="/public-invite/:eventId/:userEmail"
            element={<InvitePage />}
          />
        </Route>
        <Route element={<LoginLayout />}>
          <Route path="/login" element={<Login />} />
          <Route path="/linkedin" element={<LinkedInCallback />} />
          <Route path="/oauth2callback" element={<OAuth2Callback />} />
          <Route index element={<Navigate to="/login" replace />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Route>
      </Route>
    </Routes>
  )
}

export default AuthenticationRouter
