import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FormGroup, Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import IndeterminateCheckbox from './IndeterminateCheckbox'
import heat_icon from '../../assets/icons/heat_icon.svg'
import heat_icon_2 from '../../assets/icons/heat_icon_2.svg'
import heat_icon_3 from '../../assets/icons/heat_icon_3.svg'
import no_heat_icon from '../../assets/icons/no_heat_icon.svg'
import {
  methodOptions,
  purposeOptions,
  statusOptions,
  typeOptions,
} from './PipelineFilter'
import { getAvatarContact } from '../../utils/stringsOperations'
import { useDispatch } from 'react-redux'
import { setAddDonationTypeModal } from '../../redux/pipeline/pipeline'
import { updateQueueData } from '../../api/queue'
import ContactsApi from '../../api/contact'
import Select from 'react-select'

const dropdownStyle = {
  border: 'none',
  color: '#11273C',
  textAlign: 'center',
  fontFamily: 'Inter, sans-serif',
  fontSize: '10px',
  padding: '2px 0px',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
}

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const renderIcons = idx => {
  switch (idx) {
    case 0:
      return <img src={no_heat_icon} style={{ height: '13px' }} />
    case 1:
      return <img src={heat_icon} style={{ height: '18px' }} />
    case 2:
      return <img src={heat_icon_2} style={{ height: '18px' }} />
    case 3:
      return <img src={heat_icon_3} style={{ height: '18px' }} />
    default:
      return <img src={no_heat_icon} style={{ height: '13px' }} />
  }
}

const mockColumns = [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
    size: 35,
  },
  {
    accessorKey: 'heat',
    header: 'HEAT',
    id: 'heat',
    cell: ({ getValue }) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {renderIcons(getValue())}
      </div>
    ),
    size: 65,
    filterFn: (row, columnId, filterValue) => {
      const currentHeat = row.original.heat
      if (filterValue) {
        if (currentHeat) {
          return filterValue[currentHeat]
        } else {
          return false
        }
      }
    },
    footer: props => props.column.id,
  },
  {
    accessorFn: row => row.first_name + ' ' + row.last_name,
    header: 'NAME',
    id: 'name',
    cell: ({ row, getValue }) => {
      return (
        <div className="pipeline-name-cell">
          {getAvatarContact(row.original, '30')}
          {getValue()}
        </div>
      )
    },
    size: 200,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'capacity',
    header: 'ASK/CAPACITY',
    id: 'capacity',
    cell: ({ getValue }) => {
      const moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            cursor: 'default',
          }}
        >
          {getValue() ? moneyFormatter.format(getValue()) : null}
        </div>
      )
    },
    size: 135,
    filterFn: (row, columnId, filterValue) => {
      const currentCapacity = row.original.capacity
      if (filterValue) {
        if (currentCapacity) {
          const comparison = filterValue[0]
          const value = parseInt(filterValue[1])
          switch (comparison) {
            case 'gte':
              return currentCapacity >= value
            case 'lte':
              return currentCapacity <= value
            case 'equals':
              return currentCapacity == value
          }
        } else row
      }
    },
    footer: props => props.column.id,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    id: 'status',
    cell: ({ getValue }) => {
      const currentStatus = statusOptions.find(s => s.label === getValue())
      return (
        <Dropdown
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Dropdown.Toggle
            style={{
              ...dropdownStyle,
              backgroundColor: currentStatus ? currentStatus.color : 'unset',
              width: `calc(var(--header-status-size) * 1px)`,
            }}
          >
            {currentStatus ? currentStatus.label : null}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {statusOptions.map(s => (
              <Dropdown.Item
                key={s.label}
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '10px',
                }}
              >
                {s.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )
    },
    size: 100,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'region',
    header: 'REGION',
    id: 'region',
    cell: info => info.getValue(),
    size: 160,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'type',
    header: 'TYPE',
    id: 'type',
    cell: ({ getValue }) => {
      const currentType = typeOptions.find(t => t.label === getValue())
      return (
        <Dropdown
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Dropdown.Toggle
            style={{
              ...dropdownStyle,
              backgroundColor: currentType ? currentType.color : 'unset',
              width: `calc(var(--header-type-size) * 1px)`,
            }}
          >
            {currentType ? currentType.label : null}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {typeOptions
              .filter(t => t.label === 'VIP' || t.label === 'Declined')
              .map(t => (
                <Dropdown.Item
                  key={t.label}
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '10px',
                  }}
                >
                  {t.label}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      )
    },
    size: 100,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'purpose',
    header: 'PURPOSE',
    id: 'purpose',
    cell: ({ getValue }) => {
      const navigate = useNavigate()
      return (
        <div
          style={{
            backgroundColor: '#1468B62B',
            color: '#11273C',
            cursor: 'default',
          }}
          className="table-dropdown-button"
          onClick={() =>
            getValue() === 'EV23SC001'
              ? navigate('/events/64fb572522cfb012215ed055')
              : null
          }
        >
          {getValue()}
        </div>
      )
    },
    size: 100,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'method',
    header: 'METHOD',
    id: 'method',
    cell: ({ getValue }) => {
      return (
        <div
          style={{
            backgroundColor:
              getValue() === 'Not Started' ? '#6265642B' : '#14B6852B',
            color: '#11273C',
            cursor: 'default',
          }}
          className="table-dropdown-button"
        >
          {getValue()}
        </div>
      )
    },
    size: 100,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'lastTouch',
    header: 'LAST TOUCH',
    id: 'lastTouch',
    cell: ({ getValue }) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          cursor: 'default',
        }}
      >
        {!getValue()
          ? 'Not Started'
          : `${getValue()} ${getValue() === 1 ? 'day' : 'days'}`}
      </div>
    ),
    filterFn: (row, columnId, filterValue) => {
      const currentLastTouch = row.original.lastTouch
      if (filterValue) {
        if (currentLastTouch) {
          const comparison = filterValue[0]
          const value = parseInt(filterValue[1])
          switch (comparison) {
            case 'gte':
              return currentLastTouch >= value
            case 'lte':
              return currentLastTouch <= value
            case 'equals':
              return currentLastTouch == value
          }
        } else row
      }
    },
    size: 120,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'notes',
    header: 'NOTES',
    id: 'notes',
    cell: ({ getValue, row: { index }, column: { id }, table }) => {
      const initialValue = getValue()
      const [value, setValue] = React.useState(initialValue)

      const onBlur = () => {
        table.options.meta?.updateData(index, id, value)
      }

      React.useEffect(() => {
        setValue(initialValue)
      }, [initialValue])

      return (
        <input
          value={value}
          onChange={e => setValue(e.target.value)}
          onBlur={onBlur}
        />
      )
    },
    footer: props => props.column.id,
  },
]

const columns = [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
    size: 35,
  },
  {
    accessorKey: 'heat',
    header: 'HEAT',
    id: 'heat',
    cell: ({ getValue }) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {renderIcons(getValue())}
      </div>
    ),
    size: 65,
    filterFn: (row, columnId, filterValue) => {
      const currentHeat = row.original.heat
      if (filterValue) {
        if (currentHeat) {
          return filterValue[currentHeat]
        } else if (currentHeat === 0) {
          return filterValue[currentHeat]
        } else {
          return false
        }
      }
    },
    footer: props => props.column.id,
  },
  {
    accessorFn: row => row.first_name + ' ' + row.last_name,
    header: 'NAME',
    id: 'name',
    cell: ({ row, getValue }) => {
      return (
        <Link
          to={
            row.original.contact_person_id
              ? `/contact/${row.original.contact_person_id}`
              : `/contacts/`
          }
          style={{ textDecoration: 'none' }}
        >
          <div className="event-name-cell">
            {getAvatarContact(row.original, '30')}
            {getValue()}
          </div>
        </Link>
      )
    },
    // cell: info => info.getValue(),
    size: 240,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'capacity',
    header: 'ASK/CAPACITY',
    id: 'capacity',
    cell: ({ getValue }) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            cursor: 'default',
          }}
        >
          {typeof getValue() === 'number'
            ? moneyFormatter.format(getValue())
            : getValue()}
        </div>
      )
    },
    size: 135,
    filterFn: (row, columnId, filterValue) => {
      const currentCapacity = row.original.capacity
      if (filterValue) {
        if (currentCapacity) {
          const comparison = filterValue[0]
          const value = parseInt(filterValue[1])
          switch (comparison) {
            case 'gte':
              return currentCapacity >= value
            case 'lte':
              return currentCapacity <= value
            case 'equals':
              return currentCapacity == value
          }
        } else {
          return false
        }
      }
    },
    footer: props => props.column.id,
  },
  {
    accessorKey: 'status',
    header: 'STATUS',
    id: 'status',
    cell: ({ row, column, table }) => {
      const dispatch = useDispatch()
      const handleAddClose = () =>
        dispatch(
          setAddDonationTypeModal({
            show: false,
            onAdd: () => null,
            onClose: () => null,
          })
        )
      const handleAddDeposit = async data => {
        const depositUpdates = {
          donation_date: new Date(),
          donation_total: data.amount,
          election_type: data.election_type,
          queue_type: 'Deposit',
          status: 'Paid',
          heat: 3,
        }
        const update = await updateQueueData({
          _id: row.original._id,
          ...depositUpdates,
        })
        if (update) {
          table.options.meta?.updateData(row.index, column.id, depositUpdates)
          handleAddClose()
        }
      }
      const handleAddPledge = async data => {
        const pledgeUpdates = {
          donation_date: new Date(),
          donation_total: data.amount,
          election_type: data.election_type,
          queue_type: 'Pledge',
          status: 'Pledged',
          heat: 3,
        }
        const update = await updateQueueData({
          _id: row.original._id,
          ...pledgeUpdates,
        })
        if (update) {
          table.options.meta?.updateData(row.index, column.id, pledgeUpdates)
          handleAddClose()
        }
      }
      const currentStatus = statusOptions.find(s => {
        if (row.original.vip) return s.label === 'VIP'
        if (row.original.queue_type === 'Deposit') return s.label === 'Paid'
        if (row.original.queue_type === 'Pledge') return s.label === 'Pledged'
        if (row.original.status) return s.label === row.original.status
        if (
          row.original.queue_type &&
          s.label.toLowerCase().includes(row.original.queue_type.toLowerCase())
        )
          return s
        if (row.original.queue_type === 'prospect')
          return s.label === 'Not Started'
      })
      if (
        row.original.queue_type === 'prospect' ||
        row.original.queue_type === 'Pledge' ||
        !row.original.queue_type
      ) {
        return (
          <Dropdown
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onSelect={async value => {
              if (value === 'Pledged' || value === 'Paid') {
                dispatch(
                  setAddDonationTypeModal({
                    show: true,
                    onAdd:
                      value === 'Paid' ? handleAddDeposit : handleAddPledge,
                    onClose: handleAddClose,
                    defaultContactOption: row.original.contact_person_id,
                    disabledContactsDropdown: true,
                    canCreateContact: false,
                    canSelectElectionType: true,
                    donationType: value === 'Paid' ? 'Deposit' : undefined,
                    defaultTitle:
                      currentStatus &&
                      currentStatus.label === 'Pledged' &&
                      value === 'Pledged'
                        ? 'Edit Pledge'
                        : currentStatus &&
                          currentStatus.label === 'Pledged' &&
                          value === 'Paid'
                        ? 'Add Donation'
                        : undefined,
                    defaultDonationAmount: row.original.donation_total,
                    defaultElectionType: row.original.election_type,
                  })
                )
              } else {
                let updateObj = {
                  status: value,
                }
                if (value === 'Asked' || value === 'Contacted') {
                  updateObj = {
                    ...updateObj,
                    heat: 1,
                  }
                } else if (value === 'Meeting Set' || value === 'In Progress') {
                  updateObj = {
                    ...updateObj,
                    heat: 2,
                  }
                }
                const update = await updateQueueData({
                  _id: row.original._id,
                  ...updateObj,
                })
                if (update) {
                  table.options.meta?.updateData(
                    row.index,
                    column.id,
                    updateObj
                  )
                }
              }
            }}
          >
            <Dropdown.Toggle
              style={{
                ...dropdownStyle,
                backgroundColor: currentStatus ? currentStatus.color : 'unset',
                width: `calc(var(--header-status-size) * 1px)`,
              }}
            >
              {currentStatus ? currentStatus.label : null}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {statusOptions
                .filter(t => {
                  if (
                    currentStatus &&
                    currentStatus.label &&
                    currentStatus.label === 'Pledged'
                  ) {
                    return (
                      t.label === 'Pledged' ||
                      t.label === 'Paid' ||
                      t.label === 'Completed'
                    )
                  } else
                    return (
                      t.label !== 'Not Started' &&
                      t.label !== 'Paid' &&
                      t.label !== 'VIP'
                    )
                })
                .map(s => (
                  <Dropdown.Item
                    key={s.label}
                    eventKey={s.label}
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '10px',
                    }}
                  >
                    {currentStatus &&
                    currentStatus.label === 'Pledged' &&
                    s.label === 'Pledged'
                      ? 'Edit Pledge'
                      : s.label === 'Pledged'
                      ? 'Add Pledge'
                      : s.label}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        )
      } else {
        return (
          <div
            style={{
              backgroundColor: currentStatus ? currentStatus.color : 'unset',
              color: '#11273C',
            }}
            className="table-dropdown-button"
          >
            {currentStatus ? currentStatus.label : null}
          </div>
        )
      }
    },
    size: 120,
    filterFn: (row, columnId, filterValue) => {
      if (filterValue) {
        if (
          row.original.status === filterValue &&
          row.original.donation_total === 0
        ) {
          return row.original.status === filterValue
        } else if (
          filterValue === 'Not Started' &&
          row.original.status === '' &&
          row.original.donation_total === 0
        ) {
          return true
        } else if (
          filterValue === 'Paid' &&
          row.original.queue_type === 'Deposit'
        ) {
          return true
        } else if (
          filterValue === 'Pledged' &&
          row.original.queue_type === 'Pledge'
        ) {
          return true
        } else if (row.original.vip && filterValue === 'VIP') {
          return true
        } else {
          return false
        }
      }
    },
    footer: props => props.column.id,
  },
  {
    accessorKey: 'region',
    header: 'REGION',
    id: 'region',
    cell: info => info.getValue(),
    size: 160,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'queue_type',
    header: 'TYPE',
    id: 'queue_type',
    cell: ({ getValue, row, column, table }) => {
      const currentType = typeOptions.find(t => {
        if (
          row.original.queue_type === 'Deposit' ||
          row.original.queue_type === 'Pledge'
        ) {
          return t.label === 'Donor'
        } else if (row.original.queue_type === 'prospect') {
          return t.label === 'Provided'
        } else {
          return row.original.queue_type === t.label
        }
      })
      return (
        <Dropdown
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          onSelect={async value => {
            const update = await updateQueueData({
              _id: row.original._id,
              queue_type: value,
            })
            if (update) {
              table.options.meta?.updateData(row.index, column.id, {
                queue_type: value,
              })
            }
          }}
        >
          <Dropdown.Toggle
            style={{
              ...dropdownStyle,
              backgroundColor: currentType ? currentType.color : 'unset',
              width: `calc(var(--header-queue_type-size) * 1px)`,
            }}
          >
            {currentType ? currentType.label : undefined}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {typeOptions
              .filter(t => t.label === 'Declined' || t.label === 'VIP')
              .map(t => (
                <Dropdown.Item
                  key={t.label}
                  eventKey={t.label}
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '10px',
                  }}
                >
                  {t.label}
                </Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      )
    },
    size: 120,
    filterFn: (row, columnId, filterValue) => {
      if (filterValue) {
        if (filterValue === 'Donor') {
          return (
            row.original.queue_type === 'Deposit' ||
            row.original.queue_type === 'Pledge'
          )
        } else if (filterValue === 'Provided') {
          return row.original.queue_type === 'prospect'
        } else if (filterValue === row.original.queue_type) {
          return true
        } else {
          return false
        }
      }
    },
    footer: props => props.column.id,
  },
  {
    accessorKey: 'purpose',
    header: 'PURPOSE',
    id: 'purpose',
    cell: ({ row, getValue, column, table }) => {
      const navigate = useNavigate()
      const currentPurpose = purposeOptions.find(
        purpose => getValue() === purpose
      )
      const eventId =
        row.original.event_id && row.original.event_id != ''
          ? row.original.event_id
          : undefined
      if (eventId) {
        return (
          <div
            style={{
              backgroundColor: '#1468B62B',
              color: '#11273C',
              cursor: 'default',
            }}
            className="table-dropdown-button"
            onClick={() => navigate(`/events/${eventId}`)}
          >
            {eventId ? 'Event' : getValue()}
          </div>
        )
      } else {
        return (
          <Dropdown
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onSelect={async value => {
              const update = await updateQueueData({
                _id: row.original._id,
                purpose: value,
              })
              if (update) {
                table.options.meta?.updateData(row.index, column.id, {
                  purpose: value,
                })
              }
            }}
          >
            <Dropdown.Toggle
              style={{
                ...dropdownStyle,
                backgroundColor: '#1468B62B',
                width: `calc(var(--header-purpose-size) * 1px)`,
              }}
            >
              {currentPurpose ? currentPurpose : `Prospect`}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {purposeOptions.map(purpose => (
                <Dropdown.Item
                  key={purpose}
                  eventKey={purpose}
                  style={{
                    fontFamily: 'Inter, sans-serif',
                    fontSize: '10px',
                  }}
                >
                  {purpose}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        )
      }
    },
    size: 100,
    filterFn: (row, columnId, filterValue) => {
      if (filterValue) {
        if (row.original.purpose === filterValue) {
          return true
        } else if (filterValue === 'Prospect' && !row.original.purpose) {
          return true
        } else if (filterValue === 'Event' && row.original.event_id) {
          return true
        } else {
          return false
        }
      }
    },
    footer: props => props.column.id,
  },
  {
    accessorKey: 'method',
    header: 'METHOD',
    id: 'method',
    cell: ({ row, getValue, column, table }) => {
      const currentMethod = methodOptions.find(method => getValue() === method)
      return (
        <Dropdown
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          onSelect={async value => {
            const update = await updateQueueData({
              _id: row.original._id,
              method: value,
            })
            if (update) {
              table.options.meta?.updateData(row.index, column.id, {
                method: value,
              })
            }
          }}
        >
          <Dropdown.Toggle
            style={{
              ...dropdownStyle,
              backgroundColor: '#14B6852B',
              width: `calc(var(--header-method-size) * 1px)`,
            }}
          >
            {currentMethod ? currentMethod : `Not Started`}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {methodOptions.map(method => (
              <Dropdown.Item
                key={method}
                eventKey={method}
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontSize: '10px',
                }}
              >
                {method}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )
    },
    size: 100,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'lastTouch',
    header: 'LAST TOUCH',
    id: 'lastTouch',
    cell: ({ getValue }) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          cursor: 'default',
        }}
      >
        {!getValue() ? 'Not Started' : getValue()}
      </div>
    ),
    filterFn: (row, columnId, filterValue) => {
      const currentLastTouch = row.original.lastTouch
      if (filterValue) {
        if (currentLastTouch) {
          const comparison = filterValue[0]
          const value = parseInt(filterValue[1])
          switch (comparison) {
            case 'gte':
              return currentLastTouch >= value
            case 'lte':
              return currentLastTouch <= value
            case 'equals':
              return currentLastTouch == value
          }
        } else row
      }
    },
    size: 120,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'assigned_to',
    header: 'ASSIGNEE',
    id: 'assigned_to',
    cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
      const orgUsers = original.orgUsers
      const assignedToOptions = orgUsers?.map(user => {
        const name = `${user.name} ${user.lastName}`
        return { label: name, value: name }
      })
      const selection = assignedToOptions
        ? assignedToOptions.find(obj => getValue() && obj.value === getValue())
        : undefined
      const handleAssignContact = async (contactId, assignedTo) => {
        const updatedContact = await ContactsApi.updateContact({
          contact_person_id: contactId,
          body: {
            contact: {
              assignedTo,
            },
          },
        })
        const updatedQueue = await updateQueueData({
          _id: original._id,
          assigned_to: assignedTo,
        })
        if (updatedContact && updatedQueue) {
          table.options.meta?.updateData(index, id, {
            assigned_to: assignedTo,
          })
        }
      }
      return (
        <FormGroup className="mb-3">
          <Select
            value={selection}
            options={assignedToOptions}
            placeholder="Select..."
            onChange={o =>
              handleAssignContact(original.contact_person_id, o.value)
            }
          />
        </FormGroup>
      )
    },
    size: 250,
  },
  {
    accessorKey: 'added_by',
    header: 'ADDED BY',
    id: 'added_by',
    cell: info => info.getValue(),
    size: 250,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'notes',
    header: 'NOTES',
    id: 'notes',
    cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
      const initialValue = getValue()
      const [value, setValue] = React.useState(initialValue)
      React.useEffect(() => {
        setValue(initialValue)
      }, [initialValue])

      const handleAddNotes = async () => {
        const updateNotesObject = {
          notes: value,
        }
        const update = await updateQueueData({
          _id: original._id,
          ...updateNotesObject,
        })
        if (update) {
          table.options.meta?.updateData(index, id, updateNotesObject)
        }
      }

      return (
        <textarea
          value={value}
          onChange={e => setValue(e.target.value)}
          onBlur={handleAddNotes}
          placeholder={`Enter Notes...`}
          rows={1}
        />
      )
    },
    footer: props => props.column.id,
  },
]

export { mockColumns, columns }
