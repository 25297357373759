const Home = () => {
  return (
    <div>
      <center>
        <header>
          <p>404 Staffer landing page under construction</p>
        </header>
      </center>
    </div>
  )
}

export default Home
