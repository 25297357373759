import { ReactNode } from 'react'
import { Form } from 'react-bootstrap'
import Select, { StylesConfig } from 'react-select'
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager'
import { Controller, Path, UseFormReturn } from 'react-hook-form'
import { ObjectSchema, AnyObject } from 'yup'
import RedAsterisk from '../../ui-elements/RedAsterisk'
import { isFieldRequired, isValid } from '../utils'

export interface OptionType {
  value: string
  label: string
}
// Read more https://react-select.com/home
interface SelectFormProps<T extends AnyObject>
  extends Omit<StateManagerProps, 'id' | 'required'> {
  id: keyof T
  hookForm: UseFormReturn<T>
  validationSchema: ObjectSchema<T>
  options: OptionType[]
  label?: string
  labelClassName?: string
  labelComponent?: ReactNode
  containerClassName?: string
  selectStyles?: StylesConfig
}

export function SelectForm<T extends AnyObject>({
  id,
  hookForm,
  validationSchema,
  label,
  labelClassName,
  labelComponent,
  options,
  containerClassName = '',
  selectStyles,
  ...rest
}: SelectFormProps<T>) {
  const { formState, getValues, control } = hookForm
  const { errors, dirtyFields, touchedFields } = formState
  const registerId = id as Path<T>

  const noLabel = !label
  const noLabelComponent = !labelComponent

  const isDirty = !!dirtyFields[registerId]
  const isTouched = !!touchedFields[registerId]

  const applyIf = isDirty || isTouched

  type OptionType = (typeof options)[0]

  const customStyles: StylesConfig = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused
        ? '#ddd'
        : isValid(id, formState)
        ? applyIf
          ? 'var(--bs-success)'
          : 'var(--bs-gray-300)'
        : applyIf
        ? 'var(--bs-danger)'
        : 'var(--bs-gray-300)',
    }),
    ...selectStyles,
  }

  return (
    <Form.Group className={containerClassName}>
      {label && noLabelComponent && (
        <Form.Label htmlFor={String(id)} className={labelClassName}>
          {label}
          {isFieldRequired(validationSchema, id, getValues) && <RedAsterisk />}
        </Form.Label>
      )}
      {noLabel && labelComponent && labelComponent}
      <Controller
        control={control}
        name={registerId}
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          return (
            <Select
              id={registerId}
              name={name}
              ref={ref}
              options={options}
              value={options.find(option => option.value === value) || ''}
              onChange={val => onChange((val as OptionType)?.value)}
              styles={customStyles}
              onBlur={onBlur}
              {...rest}
            />
          )
        }}
      />
      {errors[id]?.message && (
        <Form.Control.Feedback type="invalid" className="d-inline">
          {String(errors[id]?.message)}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export default SelectForm
