import constants from '../../config/constants'
import axiosInstance from '../api_instance'
const API_URL = 'bcontact'

export const filterBContacts = (token, body = {}) => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance
    .post(`${API_URL}/contacts/filter`, body, {
      headers: { token },
      baseURL,
    })
    .then(data => data.data)
}

export const syncBundlerContacts = (token, userId, organizationId) => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance
    .post(
      `${API_URL}/bundler`,
      { userId, organizationId },
      {
        headers: { token },
        baseURL,
      }
    )
    .then(data => data.data)
}
