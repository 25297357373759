import { useEffect, useState } from 'react'
import Card from '../ui-elements/Card'
import { Stack } from 'react-bootstrap'
import TaskCheckText from './TaskCheckText'
import ApiTask from '../../api/task'
import { useSelector } from 'react-redux'
import { getUserId } from '../../utils/state/selectors'
import { PropTypes } from 'prop-types'

const getEventNameById = (events, eventId) => {
  if (!Array.isArray(events) || typeof eventId !== 'string') {
    return ' '
  }
  const event = events.find(event => event._id === eventId)
  return event?.name || ''
}

const TasksBox = ({ style }) => {
  const [tasks, setTasks] = useState([])
  const [events, setEvents] = useState([])
  const userId = useSelector(getUserId)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (userId && userId != '') {
      ApiTask.getUserTopTasks(userId).then(tasks => {
        setTasks(tasks?.tasks)
        setEvents(tasks?.events)

        setLoading(false)
      })
    }
  }, [])

  return (
    <Card title="Tasks" style={style}>
      <Stack gap={3} style={{ overflow: 'auto', height: '500px' }}>
        {tasks?.map((task, index) => (
          <TaskCheckText
            key={`task-widget-${index}`}
            title={task?.description}
            description={getEventNameById(events, task?.eventId)}
            check={task?.markCompleted}
          />
        ))}
        {loading && <p>Loading...</p>}
      </Stack>
    </Card>
  )
}

TasksBox.propTypes = {
  style: PropTypes.object,
}

export default TasksBox
