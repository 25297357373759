import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { getUserOrganizationId, getUser } from '../../utils/state/selectors'
import OrgApi from '../../api/organization'

const NameDiv = styled.div`
  height: 50px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-weight: 800;
`

const OrgName = () => {
  const orgId = useSelector(getUserOrganizationId)
  const user = useSelector(getUser)
  const [organization, setOrganization] = useState()

  useEffect(() => {
    OrgApi.getOrganization(orgId).then(org => {
      setOrganization(org)
    })
  }, [orgId])
  return user?.metrics_group !== 'admin' ? null : (
    <NameDiv>{organization?.name}</NameDiv>
  )
}

export default OrgName
