import { prop, propOr } from 'ramda'
import constants from '../config/constants'
import { getEventId } from '../utils/dataModels/events'
import axios from './axios'

const createEventTasks = async (taskList, user, _eventId) => {
  const userId = prop('_id', user)
  const tasks = taskList.map(task => {
    const eventId = prop('eventId', task) || _eventId
    const owner = propOr('', 'contact', task)
    const description = propOr('', 'description', task)
    const markCompleted = propOr(false, 'markCompleted', task)
    return {
      userId,
      eventId,
      owner,
      description,
      markCompleted,
    }
  })

  const endpoint = constants.TASKS_ENDPOINT()
  const url = `${endpoint}`
  return axios({
    url,
    method: 'POST',
    data: JSON.stringify(tasks),
  }).then(prop('data'))
}

const updateTasks = async (taskList, user) => {
  const updates = taskList.map(task => {
    const userId = prop('_id', user)
    const eventId = prop('eventId', task)
    const owner = propOr('', 'contact', task)
    const description = propOr('', 'description', task)
    const markCompleted = propOr(false, 'markCompleted', task)
    const _id = prop('_id', task)
    return {
      _id,
      userId,
      eventId,
      owner,
      description,
      markCompleted,
    }
  })

  const endpoint = constants.TASKS_ENDPOINT()
  const url = `${endpoint}`
  await axios({
    url,
    method: 'PUT',
    data: JSON.stringify(updates),
  }).then(prop('data'))

  return updates
}

const updateTask = async (task, user) => {
  return updateTasks([task], user)
}

/* const deleteTasksByIds = async tasks => {
  const db = await initDB()
  const allTasks = await getAllDocs(db.tasks())
  const filteredTasks = allTasks.filter(({ _id }) => {
    return tasks.includes(_id)
  })
  const removedTasks = await Promise.all(
    filteredTasks.map(
      async ({ _id, _rev }) => await remove(db.tasks())(_id, _rev)
    )
  )
  return removedTasks
} */

const getEventTasks = async (event, isAllowed = false) => {
  const eventId = getEventId(event)
  let endpoint = constants.TASKS_ENDPOINT()
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}/eventId/${eventId}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

const getUserTasks = async user => {
  const userId = prop('_id', user)
  const endpoint = constants.TASKS_ENDPOINT()
  const url = `${endpoint}/eventId/${userId}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

const getUserTopTasks = async userId => {
  const endpoint = constants.TASKS_ENDPOINT()
  const url = `${endpoint}/topTasks/${userId}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

export default {
  createEventTasks,
  updateTask,
  updateTasks,
  getEventTasks,
  getUserTasks,
  getUserTopTasks,
  //deleteTasksByIds,
}
