import classNames from 'classnames'
import { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BThread, IContact, Label } from '../../../../api/interfaces'
import { getThreads } from '../../../../api/nylas/mailbox'
import { getUserToken } from '../../../../utils/dataModels/user'
import { capitalizedWord } from '../../../../utils/stringsOperations'
import { DATEFORMAT, timestampToDateString } from '../../../../utils/timestamp'
import {
  compressLongString,
  navigateThreadClick,
} from '../../../MailBoxPage/helpers'
import { FOLDERS } from '../../../MailBoxPage/MailBoxPage'

export interface InteractionProps {
  contact: IContact
}

const Interaction: FC<InteractionProps> = ({ contact }) => {
  const navigate = useNavigate()
  const providerAccount = getUserToken()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [threads, setThreads] = useState<BThread[]>([])

  const getContactEmails = () => {
    const emails: string[] = []
    if (contact.email) {
      emails.push(contact.email)
    }
    if (contact.secondary_email) {
      emails.push(contact.secondary_email)
    }
    if (contact.assistant_email) {
      emails.push(contact.assistant_email)
    }
    return emails
  }

  const filterThreads = () => {
    const emails = getContactEmails()
    if (!emails.length) {
      setIsLoading(false)
      return
    }
    getThreads({
      folder: FOLDERS.INBOX,
      not_in: 'promotions',
      token: providerAccount?.token,
      limit: 15,
      offset: 0,
      any_email: emails.join(','),
    })
      .then(response => setThreads(response.data))
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    setIsLoading(true)
    filterThreads()
  }, [])

  const handleThreadClick = (
    threadId: string,
    messageIds: string[],
    subject: string
  ) => {
    navigateThreadClick(threadId, messageIds, subject, navigate)
  }

  const hasLabelName = (labels: Label[], hasLabel: string) =>
    labels.some(label => label.name === hasLabel) ? (
      <small
        className="border p-1 rounded bg-light text-muted text-center"
        style={{ width: '40px' }}
      >
        {capitalizedWord(hasLabel)}
      </small>
    ) : null

  const PlaceHolderEmails = () => (
    <div className="container mt-4">
      {Array.from({ length: 6 }, (_, i) => i + 1).map((_, index) => (
        <div key={index} style={{ marginBottom: '40px' }}>
          <div className="d-flex justify-content-between">
            <span className="placeholder col-6 opacity-25"></span>
            <span className="placeholder col-2 opacity-25"></span>
          </div>
          <div className="mt-2">
            <span className="placeholder w-100 opacity-25"></span>
            <span className="placeholder w-100 opacity-25"></span>
          </div>
        </div>
      ))}
    </div>
  )

  const noInteractions = !threads.length && !isLoading
  return (
    <div
      className={classNames(
        'd-flex bd-highlight mb-1 gap-2 flex-column position-relative overflow-scroll',
        { 'justify-content-center': noInteractions }
      )}
      style={{
        maxHeight: '1200px',
        minHeight: `${noInteractions ? '125px' : '325px'}`,
      }}
    >
      {isLoading && <PlaceHolderEmails />}
      {noInteractions && <center className="text-muted">None Yet.</center>}

      {threads.map(thread => (
        <div
          key={thread.id}
          className={`mt-1 p-2 w-auto bd-highlight border-bottom  position-relative ${
            thread?.unread ? 'fw-bold' : 'fw-normal'
          }`}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            handleThreadClick(thread.id, thread.message_ids, thread.subject)
          }
        >
          <p className="d-flex flex-column w-100 h-50 gap-2">
            <div className="d-flex justify-content-between align-items-center">
              <span className="">{compressLongString(thread.subject, 60)}</span>
              <small className="text-muted fw-normal">
                {timestampToDateString(
                  thread.last_message_timestamp,
                  DATEFORMAT.DATETIME4D3MHA
                ).toString()}
              </small>
            </div>

            <small className="text-muted">{thread.snippet}</small>
          </p>
          <div
            className="d-flex gap-1 fw-normal end-0 me-4 bottom-0"
            style={{ fontSize: '0.7rem' }}
          >
            {hasLabelName(thread.labels, FOLDERS.INBOX)}
            {hasLabelName(thread.labels, FOLDERS.SENT)}
          </div>
        </div>
      ))}
    </div>
  )
}

export default Interaction
