export const initialState = {
  to: [],
  cc: [],
  bcc: [],
}
export const mandatoryFields = [
  {
    label: 'First Name',
    value: 'first_name',
  },
  {
    label: 'Last Name',
    value: 'last_name',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'State',
    value: 'state_abbr',
  },
]
export const mentionFields = mandatoryFields.map(mandatoryField => ({
  text: mandatoryField.label,
  value: `[${mandatoryField.value}]]`,
  url: ' ',
}))

export const mentionConfig = (mailMerge: boolean) => ({
  separator: ' ',
  trigger: '[',
  suggestions: mailMerge ? mentionFields : [],
})
