import styled from 'styled-components'
import { Stack } from 'react-bootstrap'
import PropTypes from 'prop-types'
import Money from './Money'

const LeaderWrapper = styled.div`
  margin-bottom: 1rem;

  > div {
    line-height: 1.5rem;
    font-family: DM Sans;
    font-size: 0.875rem;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
  }
`

const DivIcon = styled.div`
  height: 40px;
`

const MoneySpan = styled.span`
  font-family: DM Sans;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
`

const IconDonation = ({ name, donation, children }) => (
  <LeaderWrapper>
    <Stack direction="horizontal" gap={3}>
      <DivIcon>{children}</DivIcon>
      <div>
        <Stack>
          <div>{name}</div>
          <MoneySpan>
            <Money value={donation} />
          </MoneySpan>
        </Stack>
      </div>
    </Stack>
  </LeaderWrapper>
)

IconDonation.propTypes = {
  name: PropTypes.string.isRequired,
  donation: PropTypes.number.isRequired,
  children: PropTypes.element,
}

export default IconDonation
