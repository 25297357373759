import React from 'react'
import { Dropdown } from 'react-bootstrap'
import PropTypes from 'prop-types'

const MenuItem = ({ children, handleOnClick, disabled = false }) => {
  return (
    <Dropdown.Item
      eventKey="1"
      onClick={() => handleOnClick()}
      disabled={disabled}
    >
      <div className="w-100 d-flex flex-row flex-nowrap justify-content-between align-items-center">
        <div className="w-100 text-right">{children}</div>
      </div>
    </Dropdown.Item>
  )
}

MenuItem.propTypes = {
  children: PropTypes.node,
  handleOnClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default MenuItem
