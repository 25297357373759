import PropTypes from 'prop-types'
import styled from 'styled-components'
import MultiProgressBar from './MultiProgressBar/MultiProgressBar'
import { getDoneTasksCount, getTasksCount } from '../../utils/dataModels/events'
import InfoTooltip from './InfoTooltip'
import { Stack } from 'react-bootstrap'
import { useState } from 'react'

const AmountsWrapper = styled.div`
  position: relative;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 0.75rem;
`

const Amounts = ({ tasksCount, doneTasksCount }) => (
  <AmountsWrapper>
    {doneTasksCount}&nbsp;/&nbsp;
    <span style={{ color: '#a6b3be' }}>{tasksCount}</span>
  </AmountsWrapper>
)
Amounts.propTypes = {
  tasksCount: PropTypes.number,
  doneTasksCount: PropTypes.number,
}

const SummaryTooltip = ({ tasksCount, doneTasksCount, offset }) => {
  return (
    <InfoTooltip offset={offset}>
      <Stack>
        <Stack direction="horizontal" gap={3}>
          <span className="fw-bold">Done&nbsp;tasks:</span>
          <span>{doneTasksCount}</span>
        </Stack>
        <Stack direction="horizontal" gap={3}>
          <span className="fw-bold">Total&nbsp;tasks:</span>
          <span>{tasksCount}</span>
        </Stack>
      </Stack>
    </InfoTooltip>
  )
}

SummaryTooltip.propTypes = {
  tasksCount: PropTypes.number,
  doneTasksCount: PropTypes.number,
  offset: PropTypes.object.isRequired,
}

const Wrapper = styled.div`
  position: relative;
  width: 13rem;
`

const TaskProgressBar = ({ consolidated }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipOffset, setTooltipOffset] = useState({ x: 0, y: 0 })
  const tasksCount = getTasksCount(consolidated)
  const doneTasksCount = getDoneTasksCount(consolidated)
  const total = {
    label: 'Total tasks',
    color: '#e6e6e6',
    value: tasksCount,
  }
  const levels =
    tasksCount > 0
      ? [
          {
            label: 'Done',
            color: '#00bdb2',
            value: doneTasksCount,
          },
        ]
      : []

  const mouseOverHandler = e => {
    if (!showTooltip)
      setTooltipOffset({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
    setShowTooltip(true)
  }

  const mouseOutHandler = () => setShowTooltip(false)

  return (
    <Wrapper onMouseOver={mouseOverHandler} onMouseOut={mouseOutHandler}>
      <MultiProgressBar total={total} levels={levels} />
      <Amounts tasksCount={tasksCount} doneTasksCount={doneTasksCount} />
      {showTooltip && (
        <SummaryTooltip
          offset={tooltipOffset}
          tasksCount={tasksCount}
          doneTasksCount={doneTasksCount}
        />
      )}
    </Wrapper>
  )
}

TaskProgressBar.propTypes = {
  event: PropTypes.object.isRequired,
  consolidated: PropTypes.object.isRequired,
}

export default TaskProgressBar
