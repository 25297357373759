import React from 'react'
import { Button, Col, Modal, Row, Stack, ModalBody } from 'react-bootstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BsCheckSquare, BsInfoCircle, BsSquare } from 'react-icons/bs'
import moment from 'moment'
import Money from '../ui-elements/Money'

const TitleSpan = styled.span`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.313rem;
  letter-spacing: 0em;
  text-align: left;
`

const TextDiv = styled.div`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.313rem;
  letter-spacing: 0em;
  text-align: left;
`

const TextSpan = styled.span`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.313rem;
  letter-spacing: 0em;
  text-align: center;
`

const StyledModalBody = styled(ModalBody)`
  padding: 0.9rem 0.9rem 0.9rem 0.9rem !important;
`

const TagDiv = styled.div`
  background-color: #0d6efd;
  border-radius: 6.25rem;

  padding: 0.25rem 0.5rem 0.25rem 0.5rem;

  font-family: DM Sans;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.75rem;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
`

const StyledChecked = styled(BsCheckSquare)`
  color: #75bd00;
  height: 1rem;
  width: 1rem;
  margin-top: 0.1rem;
`

const StyledUnChecked = styled(BsSquare)`
  color: #a6b3be;
  height: 1rem;
  width: 1rem;
  margin-top: 0.1rem;
`

const StackAlignStart = styled(Stack)`
  align-items: flex-start;
`

const SummaryModal = ({ event, show, onCreate, onClose, isUpdate = false }) => {
  const getDateFormat = (day, hour) => {
    const eventDatetime = moment(`${day} ${hour}`).toISOString()
    let date = moment(eventDatetime).format('MMMM Do, YYYY')
    let time = moment(eventDatetime).format('h:mmA zz')
    return `${date} at ${time}`
  }

  return (
    <Modal
      show={show}
      onHide={onClose}
      aria-labelledby="contained-modal-title-vcenter"
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Summary</Modal.Title>
      </Modal.Header>
      <StyledModalBody className="show-grid">
        <Row>
          <Col sm={6}>
            <Stack gap={3}>
              <Stack gap={2}>
                <TitleSpan>Location</TitleSpan>
                <StackAlignStart gap={2} direction="horizontal">
                  <BsInfoCircle
                    style={{ marginTop: '0.1rem' }}
                    color="#A6B3BE"
                    size={16}
                  />
                  {event?.eventFormData?.city ? (
                    <TextDiv>{event?.eventFormData?.city}</TextDiv>
                  ) : (
                    <TextSpan>To be determined</TextSpan>
                  )}
                </StackAlignStart>
              </Stack>
              <Stack gap={2}>
                <TitleSpan>Schedule</TitleSpan>
                <StackAlignStart gap={2} direction="horizontal">
                  <BsInfoCircle
                    style={{ marginTop: '0.1rem' }}
                    color="#A6B3BE"
                    size={16}
                  />
                  {event?.eventFormData?.day && event?.eventFormData?.hour ? (
                    <TextDiv>
                      {getDateFormat(
                        event?.eventFormData?.day,
                        event?.eventFormData?.hour
                      )}
                    </TextDiv>
                  ) : (
                    <TextSpan>To be determined</TextSpan>
                  )}
                </StackAlignStart>
              </Stack>
              <Stack gap={2}>
                <TitleSpan>Venue</TitleSpan>
                <StackAlignStart gap={2} direction="horizontal">
                  {event?.eventFormData?.locationRSVP ? (
                    <StyledChecked />
                  ) : (
                    <StyledUnChecked />
                  )}
                  <TextSpan>Withhold details</TextSpan>
                </StackAlignStart>
                <StackAlignStart gap={2} direction="horizontal">
                  {event?.eventFormData?.venueTBD ? (
                    <StyledChecked />
                  ) : (
                    <StyledUnChecked />
                  )}
                  <TextSpan>To be determined</TextSpan>
                </StackAlignStart>
                {event?.eventFormData?.venue && (
                  <StackAlignStart gap={2} direction="horizontal">
                    <BsInfoCircle
                      style={{ marginTop: '0.1rem' }}
                      color="#A6B3BE"
                      size={16}
                    />
                    <TextDiv>{event?.eventFormData?.venue}</TextDiv>
                  </StackAlignStart>
                )}
              </Stack>
            </Stack>
          </Col>
          <Col sm={6}>
            <Stack gap={3}>
              <Stack gap={2}>
                <TitleSpan>Financials</TitleSpan>
                <StackAlignStart gap={2} direction="horizontal">
                  <BsInfoCircle
                    style={{ marginTop: '0.1rem' }}
                    color="#A6B3BE"
                    size={16}
                  />
                  <TextDiv>
                    {`Goal set as `}
                    <Money value={event?.eventFormData?.financialGoal} />
                  </TextDiv>
                </StackAlignStart>
                <StackAlignStart gap={2} direction="horizontal">
                  <BsInfoCircle
                    style={{ marginTop: '0.1rem' }}
                    color="#A6B3BE"
                    size={16}
                  />
                  <TextDiv>
                    {`Donation level set as `}
                    <Money value={event?.eventFormData?.suggestedDonation} />
                  </TextDiv>
                </StackAlignStart>
                <StackAlignStart gap={2} direction="horizontal">
                  {event?.eventFormData?.suggestedDonationTBD ? (
                    <StyledChecked />
                  ) : (
                    <StyledUnChecked />
                  )}
                  <TextDiv>Suggested donation</TextDiv>
                </StackAlignStart>
              </Stack>
              <Stack gap={2}>
                {event?.tiers?.length === 0 &&
                event?.eventFormData?.suggestedDonation ? (
                  <>
                    <TitleSpan>Tiers</TitleSpan>
                    <StackAlignStart gap={2} direction="horizontal">
                      <StyledChecked />
                      <TextDiv>
                        {`Chair set as `}
                        <Money
                          value={event?.eventFormData?.suggestedDonation}
                        />
                      </TextDiv>
                    </StackAlignStart>
                  </>
                ) : event?.tiers?.length < 3 && event?.tiers?.length > 0 ? (
                  <>
                    <TitleSpan>Tiers</TitleSpan>
                    {event?.tiers?.map(
                      ({ _id, tierName, tierLevel, isGiveRaise }) => {
                        return (
                          <StackAlignStart
                            key={`tierSummary-${_id}`}
                            gap={2}
                            direction="horizontal"
                          >
                            {isGiveRaise ? (
                              <StyledChecked />
                            ) : (
                              <StyledUnChecked />
                            )}
                            <TextDiv>
                              {`${tierName} set as `}
                              <Money value={tierLevel} />
                            </TextDiv>
                          </StackAlignStart>
                        )
                      }
                    )}
                  </>
                ) : event?.tiers?.length > 2 ? (
                  <Stack gap={2}>
                    <Stack gap={2} direction="horizontal">
                      <TagDiv>{event?.tiers?.length}</TagDiv>
                      <TitleSpan>Tiers</TitleSpan>
                    </Stack>
                    <StackAlignStart gap={2} direction="horizontal">
                      {event?.tiers?.some(tier => tier.isGiveRaise) ? (
                        <StyledChecked />
                      ) : (
                        <StyledUnChecked />
                      )}
                      <TextDiv>Give or raise allowed</TextDiv>
                    </StackAlignStart>
                  </Stack>
                ) : (
                  <TitleSpan>Tiers</TitleSpan>
                )}
              </Stack>
              <Stack gap={2}>
                {event?.attendees?.specialGuests?.length < 3 ? (
                  <>
                    <TitleSpan>Special guests</TitleSpan>
                    {event?.attendees?.specialGuests.map(
                      ({ _id, guestName }) => {
                        return (
                          <StackAlignStart
                            key={`guestSummary-${_id}`}
                            gap={2}
                            direction="horizontal"
                          >
                            <BsInfoCircle
                              style={{ marginTop: '0.1rem' }}
                              color="#A6B3BE"
                              size={16}
                            />
                            <TextDiv>{guestName}</TextDiv>
                          </StackAlignStart>
                        )
                      }
                    )}
                  </>
                ) : (
                  <Stack gap={2} direction="horizontal">
                    <TagDiv>{event?.attendees?.specialGuests?.length}</TagDiv>
                    <TitleSpan>Special guests</TitleSpan>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Col>
        </Row>
      </StyledModalBody>
      <Modal.Footer>
        <Button variant="light" onClick={onClose}>
          Continue editing
        </Button>
        <Button variant="secondary" onClick={onCreate}>
          {!isUpdate ? 'Create Event' : 'Update Event'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

SummaryModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool,
  checkEvent: PropTypes.bool,
  ShowUpToast: PropTypes.func,
}

export default SummaryModal
