import { pathOr } from 'ramda'
import React, { FC } from 'react'
import { IDonation, IDonation2 } from '../../../api/interfaces'
import { DATEFORMAT, timestampToDateString } from '../../../utils/timestamp'
import { BTableHeaders } from '../../BTable/types'
import { formatValue } from '../../ui-elements/Money'
import { StyledTd } from '../styles'
import moment from 'moment'
import { DonationRowProps, DonationRowProps2 } from './type'

const DonationRow: FC<DonationRowProps> = ({ donation, rowKeys, onClick }) => {
  const getRowComponent = (rowKey: BTableHeaders<IDonation>, index: number) => {
    const rowKeyAccess = rowKey.key
    let rowValue = ''
    if (Array.isArray(rowKeyAccess)) {
      rowValue = rowKeyAccess.reduce(
        (prev, curr) => prev + ' ' + pathOr('', curr.split('.'), donation),
        ''
      )
    } else {
      rowValue = pathOr('', rowKeyAccess.split('.'), donation)
      if (rowKeyAccess === 'amount') {
        const formattedValue = formatValue(rowValue, 0, 0, true)
        rowValue = formattedValue
          ? `${formatValue(rowValue, 0, 0, true)}`
          : rowValue
      }
      if (rowValue) {
        rowValue = moment(rowValue).format(DATEFORMAT.DATE_FORMAT)
      }
      return <StyledTd key={`${rowValue} ${index}`}>{rowValue} </StyledTd>
    }
    return <StyledTd key={`${rowValue} ${index}`}>{rowValue} </StyledTd>
  }

  return (
    <tr
      role={onClick ? 'button' : 'cell'}
      onClick={() => onClick && onClick(donation)}
    >
      {rowKeys.map((rowKey, index) => getRowComponent(rowKey, index))}
    </tr>
  )
}

const DonationRow2: FC<DonationRowProps2> = ({
  donation,
  rowKeys,
  onClick,
}) => {
  const getRowComponent = (
    rowKey: BTableHeaders<IDonation2>,
    index: number
  ) => {
    const rowKeyAccess = rowKey.key
    let rowValue = ''
    if (Array.isArray(rowKeyAccess)) {
      rowValue = rowKeyAccess.reduce(
        (prev, curr) => prev + ' ' + pathOr('', curr.split('.'), donation),
        ''
      )
    } else {
      rowValue = pathOr('', rowKeyAccess.split('.'), donation)
      if (rowKeyAccess === 'donation_total') {
        const formattedValue = formatValue(rowValue, 0, 0, true)
        rowValue = formattedValue
          ? `${formatValue(rowValue, 0, 0, true)}`
          : rowValue
      }
      if (
        rowValue &&
        (rowKeyAccess === 'createdAt' ||
          rowKeyAccess === 'updatedAt' ||
          rowKeyAccess === 'donation_date')
      ) {
        rowValue = moment(rowValue).format(DATEFORMAT.DATE_FORMAT)
      }
      return <StyledTd key={`${rowValue} ${index}`}>{rowValue} </StyledTd>
    }
    return <StyledTd key={`${rowValue} ${index}`}>{rowValue} </StyledTd>
  }

  return (
    <tr
      role={onClick ? 'button' : 'cell'}
      onClick={() => onClick && onClick(donation)}
    >
      {rowKeys.map((rowKey, index) => getRowComponent(rowKey, index))}
    </tr>
  )
}

export { DonationRow, DonationRow2 }
