import { useReducer, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isNil } from 'ramda'

import {
  addTask,
  // deleteTask,
  updateTask,
  selectTask,
  nextMonth,
  previousMonth,
  setMonth,
  setYear,
} from './actions'
import { reducer, initialState } from './reducers'
import { compose } from './util'
import { emptyTask } from './helpers'

import Calendar from './Calendar'
import TaskPanel from './TaskPanel'
import Toolbar from './Toolbar'
// import NewTaskPanel from './NewTaskPanel'
// import SelectNewTaskPanel from './SelectNewTaskTypePanel'

import './TasksCalendar.css'
import EventModal from './EventModal'
import {
  getUserOrganizationId,
  getUserRole,
} from '../../../utils/state/selectors'
import { useSelector } from 'react-redux'
import { getUserToken } from '../../../utils/dataModels/user'
// import EditEventTaskPanel from './EditEventTaskPanel'

const ExistingTaskPanel = ({ task, savePanelTask, onClose }) => (
  <>
    <TaskPanel
      key={`task-${task.id}-${task.date.format()}`}
      task={task}
      save={savePanelTask}
      close={onClose}
    />
    {/* <EditEventTaskPanel task={task} /> */}
  </>
)

ExistingTaskPanel.propTypes = {
  task: PropTypes.object,
  savePanelTask: PropTypes.func,
  completePanelTask: PropTypes.func,
  deletePanelTask: PropTypes.func,
  onClose: PropTypes.func,
}

/**
 * Identificador de un CalendarTask
 * @typedef {number|string} TaskId
 */

/**
 * Tarea del calendario
 *
 * @typedef CalendarTask
 * @property {TaskId}        CalendarTask#id        - Identificador de la tarea
 * @property {string}        CalendarTask#title     - Título de la tarea
 * @property {string|Date}   CalendarTask#date      - Fecha y hora de la tarea
 * @property {number}        CalendarTask#duration  - Duración de la tarea en minutos
 * @property {bool}          CalendarTask#completed - La tarea está completada
 * @property {string}        [CalendarTask#img]     - URL del icono de la tarea
 * @property {string}        [CalendarTask#notes]   - Notas sobre la tarea
 */

/**
 * Calendario de tareas
 *
 * @param {string|Date}    [date]  - primer día a mostrar (se muestra a partir del lunes inmediato anterior)
 * @param {CalendarTask[] -> Callback} [onCreateTask] - callback para crear una nueva tarea
 * @param {TaskId -> Callback}         [onReadTask]   - callback para crear una nueva tarea
 * @param {CalendarTask -> Callback}   [onUpdateTask] - callback para crear una nueva tarea
 * @param {TaskId -> Callback}         [onDeleteTask] - callback para crear una nueva tarea
 */
const TasksCalendar = ({
  date = new Date(),
  onReadTasks,
  onUpdateTask,
  // onCreateTask,
  // onDeleteTask,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState(date))
  const { tasks, currentTask, year, month } = state
  const [showPanel, setShowPanel] = useState(false)
  const [newTaskTypePanel, setNewTaskTypePanel] = useState(false)
  const [panelYear, setPanelYear] = useState(0)
  const [panelMonth, setPanelMonth] = useState(0)
  const [panelDay, setPanelDay] = useState(0)
  const organizationId = useSelector(getUserOrganizationId)
  const userRole = useSelector(getUserRole)
  const providerAccount = getUserToken()

  const panelVisible = showPanel || !isNil(currentTask)
  const calendarClasses = `calendarWrap2 col-12 col-md-12`
  const panelTask = currentTask
    ? currentTask
    : emptyTask(panelYear, panelMonth + 1, panelDay)
  const d = moment(
    `${state.year}-${(state.month + 101 + '').slice(1)}-01`,
    'YYYY-MM-DD'
  )

  const openPanel = (y, m, d, isNew = false) => {
    setPanelYear(y)
    setPanelMonth(m)
    setPanelDay(d)
    setShowPanel(true)
    console.log(isNew)
    setNewTaskTypePanel(false)
    dispatch(selectTask(null))
    // setShowSelectNewTaskTypePanel(true)
  }

  // const savePanelTask = task => {
  //   if (currentTask) {
  //     compose(dispatch, updateTask)(task)
  //     onUpdateTask(task)
  //   } else {
  //     onCreateTask(
  //       task,
  //       compose(() => setShowPanel(false), dispatch, addTask)
  //     )
  //   }
  //   dispatch(setYear(task.date.year()))
  //   dispatch(setMonth(task.date.month()))
  // }

  // const completePanelTask = task => {
  //   compose(dispatch, updateTask)(task)
  //   onUpdateTask(task)
  // }

  // const deletePanelTask = id => {
  //   onDeleteTask(id)
  //   dispatch(deleteTask(id))
  //   dispatch(selectTask(null))
  //   setShowPanel(false)
  // }

  const closePanel = () => {
    setShowPanel(false)
    // setShowSelectNewTaskTypePanel(false)
    dispatch(selectTask(null))
  }

  useEffect(() => {
    const from = moment(d).startOf('month').startOf('week').toDate()
    const to = moment(d).endOf('month').endOf('week').toDate()
    onReadTasks(
      from,
      to,
      organizationId,
      userRole,
      providerAccount?.token,
      ts => ts.map(compose(dispatch, addTask))
    )
  }, [year, month])

  return (
    <div className="tasksCalendar">
      <div className="row">
        <div className="col-12">
          <Toolbar
            year={year.toString()}
            month={month}
            nextMonth={compose(dispatch, nextMonth)}
            previousMonth={compose(dispatch, previousMonth)}
            setYear={compose(dispatch, setYear)}
            setMonth={compose(dispatch, setMonth)}
          />
        </div>

        <div className="calendarWrap col-12">
          <div className="row">
            <div className={calendarClasses}>
              <Calendar
                date={d}
                tasks={tasks.filter(t => !t.completed)}
                showPanel={openPanel}
                updateTask={t => {
                  compose(dispatch, updateTask)(t)
                  onUpdateTask(t)
                }}
                selectTask={compose(
                  // () => setShowSelectNewTaskTypePanel(false),
                  dispatch,
                  selectTask
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <EventModal
        event={panelTask['event']}
        show={panelVisible}
        onHide={closePanel}
        newTaskTypePanel={newTaskTypePanel}
      />
    </div>
  )
}

TasksCalendar.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onCreateTask: PropTypes.func,
  onReadTasks: PropTypes.func,
  onUpdateTask: PropTypes.func,
  onDeleteTask: PropTypes.func,
}

export default TasksCalendar
