import { FC, useEffect, useState } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { numericFormatter } from 'react-number-format'
import ContactsApi from '../../../api/contact'
import { useSelector } from 'react-redux'
import { getUser } from '../../../utils/state/selectors'
import { getUserOrganizationId } from '../../../utils/state/selectors'
import { prop } from 'ramda'
import { BsPlusSquare } from 'react-icons/bs'
import '../modalDisplay.css'
import { CONTACT_LEVELS, DONATION_TYPE } from '../../../api/enums'
import useDimensionScreen from '../../../hooks/useDimensionScreen'
import APiContact from '../../../api/contact'
import ApiOrganization from '../../../api/organization'
import { isBundler } from '../../../utils/state/roleTests'
import { IContact, IEvent, IUser } from '../../../api/interfaces'
import { AddDonationTypeModalProps, DonationTypeSubmitForm } from './types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { donationTypeValidationSchema } from './donationTypeForm-yup-config'
import SelectForm, { OptionType } from '../../GenericForm/SelectForm/SelectForm'
import NumericFormatForm from '../../GenericForm/NumeriFormat/NumericFormatForm'
import { StylesConfig } from 'react-select'
import { capitalizedWord } from '../../../utils/stringsOperations'
import { useDispatch } from 'react-redux'
import {
  initialPipelineState,
  setCreateContactModal,
  setEditContactModal,
} from '../../../redux/pipeline/pipeline'
import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { getContactById } from '../../../utils/contactFunctions'
import APiEvent from '../../../api/event'
import { PayloadDonationType } from '../../../redux/pipeline/types'

const fullName = ({ first_name: first_name, last_name: last_name }: IContact) =>
  `${first_name} ${last_name}`

const initialDonationTypeForm: DonationTypeSubmitForm = {
  donor: '',
  donation: '',
  eventId: '',
}

const beforeToSaveDefault = (
  formData: DonationTypeSubmitForm,
  contacts: IContact[],
  donationType: keyof typeof DONATION_TYPE,
  dispatch: Dispatch<AnyAction>,
  onSave: () => void,
  user?: IUser
) => {
  const handleCloseEditContactModal = () => {
    dispatch(setEditContactModal(initialPipelineState.editContactModal))
  }
  const checkOrgContact = async (contact: IContact) => {
    if (contact.hidden === true) {
      contact.hidden = false
      await APiContact.updateContact({
        contact_person_id: contact.contact_person_id,
        body: { contact },
      })
    }
  }

  const isValidContact = (contact: IContact) => {
    return contact.first_name && contact.last_name && contact.email
  }

  if (donationType === 'PLEDGE' || isBundler(user?.role)) {
    const contact = getContactById(contacts, formData.donor)
    if (contact) {
      if (!isValidContact(contact)) {
        dispatch(
          setEditContactModal({
            show: true,
            handleClose: handleCloseEditContactModal,
            handleUpdate: () => {
              onSave()
              handleCloseEditContactModal()
            },
            contact,
          })
        )
        return false
      } else {
        checkOrgContact(contact)
      }
      onSave()
    }
  } else {
    onSave()
  }
}
const AddDonationTypeModal: FC<AddDonationTypeModalProps> = ({
  donationType = 'PLEDGE',
  show,
  donation,
  onAdd,
  onClose,
  beforeToSave,
  getContactsFn,
  canSelectEvent,
  canCreateContact = true,
  canSelectElectionType,
  mutateEventOptions,
  disabledContactsDropdown,
  disabledEventsDropdown,
  defaultEventOption,
  defaultContactOption,
  defaultTitle,
  defaultDonationAmount,
  defaultElectionType,
}) => {
  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const orgId = useSelector(getUserOrganizationId) || ''
  const [dimension] = useDimensionScreen()

  const [loadingContacts, setLoadingContacts] = useState(false)
  const [display, setDisplay] = useState(true)
  const [contacts, setContacts] = useState<IContact[]>([])
  const [bundlerLevels, setBundlerLevels] = useState<string[]>()
  const [contactsOptions, setContactsOptions] = useState<OptionType[]>([])
  const [eventsOptions, setEventsOptions] = useState<OptionType[]>([])
  const [loadingEvents, setLoadingEvents] = useState(false)

  const donationTypeForm = useForm<DonationTypeSubmitForm>({
    shouldUnregister: false,
    resolver: yupResolver(donationTypeValidationSchema),
    mode: 'all',
    defaultValues: initialDonationTypeForm,
  })

  const { handleSubmit, setValue, reset } = donationTypeForm

  const selectStyles: StylesConfig = {
    input: provided => ({
      ...provided,
      width: '100%',
      border: 'none',
      '> input[type=text]:focus': { outline: 'none !important' },
    }),
  }

  const savePledge = (formData: DonationTypeSubmitForm) => {
    const payload: PayloadDonationType = {
      donorId: formData.donor,
      amount: Number(formData.donation.replaceAll(',', '')),
      eventId: formData.eventId,
      election_type: formData.election_type,
    }
    onAdd(payload)
  }

  const closeHandler = () => {
    reset(initialDonationTypeForm)
    onClose()
  }

  const formatContact = (contactList: IContact[]) => {
    // const filtList = contactList.filter(
    //   ({ level, donation_capacity, email }) =>
    //     level?.toLowerCase() == CONTACT_LEVELS.CONTACT.toLowerCase() ||
    //     bundlerLevels?.includes(level?.toLowerCase()) ||
    //     (donation_capacity && email)
    // )
    setContacts(contactList)

    const contactOptions = contactList.map(c => ({
      value: prop('contact_person_id', c),
      label: fullName(c),
    }))
    const contactOption = defaultContactOption
      ? contactOptions.find(option => option.value === defaultContactOption)
      : undefined
    setContactsOptions([...contactOptions])
    if (defaultContactOption && contactOption) {
      donationTypeForm.setValue('donor', contactOption.value)
      if (defaultDonationAmount) {
        donationTypeForm.setValue('donation', defaultDonationAmount)
      }
      if (defaultElectionType) {
        donationTypeForm.setValue('election_type', defaultElectionType)
      }
    }
  }

  const loadEvents = (isMounted: boolean) => {
    APiEvent.getEvents().then((events: IEvent[]) => {
      if (isMounted) {
        let eventOptions = events.map(event => ({
          label: event.name,
          value: event._id,
        }))
        eventOptions = mutateEventOptions
          ? mutateEventOptions(eventOptions)
          : eventOptions

        const eventOption = defaultEventOption
          ? eventOptions.find(option => option.value === defaultEventOption)
          : undefined

        setEventsOptions(eventOptions)
        setLoadingEvents(false)
        // Setting the Default Value
        if (defaultEventOption && eventOption) {
          donationTypeForm.setValue('eventId', eventOption.value)
        }
      }
    })
  }

  const loadContacts = (isMounted: boolean) => {
    if (getContactsFn) {
      if (isMounted) {
        formatContact(getContactsFn())
        setLoadingContacts(false)
      }
    } else if (defaultContactOption) {
      ContactsApi.getContact(defaultContactOption, undefined, true)
        .then((result: any) => {
          if (isMounted) {
            formatContact([result])
          }
        })
        .catch(console.log)
        .finally(() => setLoadingContacts(false))
    } else {
      ContactsApi.getContacts(user)
        .then((list: IContact[]) => {
          if (isMounted) {
            formatContact(list)
          }
        })
        .catch(console.log)
        .finally(() => setLoadingContacts(false))
    }
  }

  useEffect(() => {
    ApiOrganization.getBundlerLevelNames(orgId).then(levels =>
      setBundlerLevels(levels.map(l => l.toLowerCase()))
    )
  }, [orgId])

  useEffect(() => {
    let isMounted = true
    if (display) {
      setLoadingContacts(true)
      loadContacts(isMounted)
      if (canSelectEvent) {
        setLoadingEvents(true)
        loadEvents(isMounted)
      }
    }

    return () => {
      isMounted = false
    }
  }, [display, bundlerLevels])

  // Setting default select contact
  useEffect(() => {
    if (contacts.length && donation) {
      const formatedAmount = numericFormatter(String(donation.amount), {
        thousandsGroupStyle: 'thousand',
        thousandSeparator: ',',
      })
      setValue('donation', formatedAmount)
      setValue('donor', donation.donorId)
    }
  }, [contacts])

  const removeTagIfPledgeOrPaid = async (formData: DonationTypeSubmitForm) => {
    if (
      donationType !== DONATION_TYPE.PLEDGE.toUpperCase() &&
      donationType !== DONATION_TYPE.DEPOSIT.toUpperCase()
    ) {
      return
    }
    const contact = getContactById(contacts, formData.donor)

    const contactTags = contact?.tags.filter(tag => tag !== 'prospect')
    await APiContact.updateContact({
      contact_person_id: formData.donor,
      body: { contact: { tags: contactTags || [] } },
    })
  }

  const addTagIfProspect = async (formData: DonationTypeSubmitForm) => {
    if (donationType !== DONATION_TYPE.PROSPECT.toUpperCase()) {
      return
    }
    const contact = getContactById(contacts, formData.donor)
    await APiContact.updateContact({
      contact_person_id: formData.donor,
      body: { contact: { tags: [...(contact?.tags || []), 'prospect'] } },
    })
  }

  const onSubmit = async (formData: DonationTypeSubmitForm) => {
    const onSave = async () => {
      try {
        await removeTagIfPledgeOrPaid(formData)
        await addTagIfProspect(formData)
      } catch (error) {
        console.log(error)
      }
      savePledge(formData)
    }

    if (beforeToSave) {
      beforeToSave(formData, onSave)
    } else {
      beforeToSaveDefault(
        formData,
        contacts,
        donationType,
        dispatch,
        onSave,
        user
      )
    }
  }

  const donationTitle = capitalizedWord(donationType.toLowerCase())

  const handleCloseCreateContactModal = () => {
    setDisplay(true)
    dispatch(
      setCreateContactModal({
        ...initialPipelineState.createContactModal,
        show: false,
      })
    )
  }
  const showCreateContactModal = () =>
    dispatch(
      setCreateContactModal({
        handleClose: handleCloseCreateContactModal,
        handleUpdate: () => null,
        show: true,
      })
    )

  return (
    <>
      <Modal
        show={show}
        onHide={closeHandler}
        className={display ? 'show-modal' : 'hide-modal'}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Header closeButton>
            {defaultTitle
              ? defaultTitle
              : donation
              ? `Edit ${donationTitle}`
              : `Add ${donationTitle}`}
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <div className="d-flex flex-row gap-2 align-items-center">
                <div style={{ width: '100%' }}>
                  {/* Donor */}
                  <SelectForm<DonationTypeSubmitForm>
                    id="donor"
                    label="Donor"
                    hookForm={donationTypeForm}
                    validationSchema={donationTypeValidationSchema}
                    placeholder="Select..."
                    options={contactsOptions}
                    selectStyles={selectStyles}
                    isDisabled={loadingContacts || disabledContactsDropdown}
                  />
                  {dimension?.width <= 430 && canCreateContact ? (
                    <div
                      className="d-flex gap-2 pt-2 align-items-center"
                      onClick={() => {
                        setDisplay(false)
                        showCreateContactModal()
                      }}
                      style={{
                        cursor: 'pointer',
                        fontSize: '14px',
                      }}
                    >
                      <BsPlusSquare size={15} color={'#1675CF'} />
                      <span style={{ color: '#1675CF' }}>Add contact</span>
                    </div>
                  ) : null}
                </div>
                {dimension?.width > 430 && canCreateContact ? (
                  <BsPlusSquare
                    onClick={() => {
                      setDisplay(false)
                      showCreateContactModal()
                    }}
                    size={37}
                    color={'#D6E4EE'}
                    style={{ cursor: 'pointer', marginTop: '31px' }}
                  />
                ) : null}
              </div>
              {loadingContacts && (
                <small className="text-muted">Loading contacts...</small>
              )}
            </div>

            {/* Donation Amount */}
            <div className="mb-3">
              <NumericFormatForm<DonationTypeSubmitForm>
                autoComplete="off"
                className="currency form-control"
                id="donation"
                label="Donation"
                hookForm={donationTypeForm}
                validationSchema={donationTypeValidationSchema}
                thousandsGroupStyle="thousand"
                thousandSeparator=","
                type="text"
                aheadComponent={<InputGroup.Text>$</InputGroup.Text>}
                placeholder="0"
              />
            </div>
            {canSelectEvent && (
              <div className="mb-2">
                {/* Event */}
                <SelectForm<DonationTypeSubmitForm>
                  id="eventId"
                  label="Event"
                  hookForm={donationTypeForm}
                  validationSchema={donationTypeValidationSchema}
                  placeholder="Select..."
                  options={eventsOptions}
                  selectStyles={selectStyles}
                  isDisabled={loadingEvents || disabledEventsDropdown}
                />
                {loadingEvents && (
                  <small className="text-muted">Loading events...</small>
                )}
              </div>
            )}
            {canSelectElectionType && (
              <div className="mb-2">
                {/* Election Type */}
                <SelectForm<DonationTypeSubmitForm>
                  id="election_type"
                  label="Election Type"
                  hookForm={donationTypeForm}
                  validationSchema={donationTypeValidationSchema}
                  placeholder="Select..."
                  options={['General', 'Primary'].map(item => ({
                    label: item,
                    value: item,
                  }))}
                  selectStyles={selectStyles}
                />
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="light" onClick={closeHandler}>
              Cancel
            </Button>
            <Button variant="secondary" type="submit">
              {defaultTitle
                ? defaultTitle
                : donation
                ? `Edit ${donationTitle}`
                : `Add ${donationTitle}`}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  )
}

export default AddDonationTypeModal
