/* eslint-disable no-unused-vars */
import types from '../types/types'

export const startLoading = () => dispatch =>
  dispatch({
    type: types.UI_START_LOADING,
  })

export const finishLoading = () => dispatch =>
  dispatch({
    type: types.UI_FINISH_LOADING,
  })

export const setPageTitle = title => dispatch =>
  dispatch({
    type: types.UI_SET_PAGE_TITLE,
    payload: title,
  })

export const setBreadcrumbs = breadcrumbs => dispatch =>
  dispatch({
    type: types.UI_SET_PAGE_BREADCRUMBS,
    payload: breadcrumbs,
  })
