/* eslint-disable */
import PropTypes from 'prop-types'
import { Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import arrowIcon from '../../assets/icons/right-arrow-black.png'
import { BsBuilding /*, BsPeople, BsPersonCheck*/ } from 'react-icons/bs'

const Wrapper = styled.div`
  width: 100%;
  padding: 1rem 3rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
  & .card-header,
  & .card-footer {
    background-color: white;
    border-top: none !important;
    border-bottom: none !important;
  }
  & .card-header svg {
    color: #00988f;
    margin-right: 1rem;
  }
`

const StyledBsCard = styled(Card)`
  padding: 1rem;
  background: #ffffff;
  border: 1px solid #dce1e6;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  min-width: 25rem;
  max-width: 25rem;
  color: ${p => (p.disabled ? 'gray' : 'inherit')};
`

const ArrowWrapper = styled.div`
  font-size: 1.2rem;
  font-weight: normal;
  > img {
    width: 15px;
  }
  &:hover {
    cursor: pointer;
  }
`

const ArrowLink = ({ href }) => {
  const navigate = useNavigate()

  return (
    <ArrowWrapper onClick={() => navigate(href)}>
      <img src={arrowIcon} />
    </ArrowWrapper>
  )
}
ArrowLink.propTypes = {
  href: PropTypes.string,
}

const MenuCard = ({ title, contents, href, icon, disabled = false }) => {
  return (
    <StyledBsCard disabled={disabled}>
      <Card.Header>
        {icon}
        {title}
      </Card.Header>
      <Card.Body>{contents}</Card.Body>
      <Card.Footer className="justify-content-end">
        {href && <ArrowLink href={href} />}
      </Card.Footer>
    </StyledBsCard>
  )
}

const AdminLandingPage = () => {
  usePageHeadings('Admin Portal')
  const navigate = useNavigate()

  return (
    <Wrapper>
      <div className="w-100 d-flex flex-row gap-5 m-3 justify-content-center">
        <MenuCard
          title="Organizations configuration"
          contents="Create and edit organizations"
          href="/admin/organizations"
          icon={<BsBuilding />}
        />
        {/*
        <MenuCard
          title="Staffers management"
          contents="Update staffers info. Assign them to or remove them from an organization and more."
          /* href="/admin/staffers" */
        /*icon={<BsPersonCheck />}
          disabled
        />

        <MenuCard
          title="Bundlers management"
          contents="Update bundlers info. Assign them to or remove them from an event and more."
          /* href="/admin/bundlers" */
        /*icon={<BsPeople />}
          disabled
        />*/}
      </div>
    </Wrapper>
  )
}

AdminLandingPage.propTypes = {}

export default AdminLandingPage
