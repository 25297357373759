import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IContact } from '../../api/interfaces'
import { apiCallBegan } from '../middleware/actions'
import { ContactsState } from './type'
import Api from '../../api/contact'
import { ApiMidPayload } from '../middleware/types'

const initialState: ContactsState = {
  contacts: [],
  loadingContacts: false,
  error: null,
  contactsPageCount: 0,
  currentContactsPage: 1,
  currentContactsPageSize: 50,
  showFilterModal: false,
}

const contactSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    // action: function
    apiRequested: state => {
      state.loadingContacts = true
    },
    apiRequestFailed: state => {
      state.loadingContacts = false
    },
    getContacts: (state, action: PayloadAction<IContact[], string, never>) => {
      state.contacts = action.payload
      state.loadingContacts = false
    },
    setCurrentContactPage: (state, action: PayloadAction<number>) => {
      state.currentContactsPage = action.payload
    },
    setContactPageCount: (state, action: PayloadAction<number>) => {
      state.contactsPageCount = action.payload
    },
    setContactPageSize: (state, action: PayloadAction<number>) => {
      state.currentContactsPageSize = action.payload
    },
    setContactPageShowFilter: (state, action: PayloadAction<boolean>) => {
      state.showFilterModal = action.payload
    },
  },
})
export const loadContacts = (): { type: string; payload: ApiMidPayload } => ({
  type: apiCallBegan.type,
  payload: {
    callApi: Api.getContacts,
    onStart: apiRequested.type,
    onSuccess: getContacts.type,
    onError: apiRequestFailed.type,
  },
})

export const {
  apiRequested,
  apiRequestFailed,
  getContacts,
  setContactPageCount,
  setContactPageSize,
  setCurrentContactPage,
  setContactPageShowFilter,
} = contactSlice.actions
export default contactSlice.reducer
