import { Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'
import ListItem from '../ListItem/ListItem'
import { useStrictDroppable } from '../useStrictDroppable'
import './DraggableElement.css'

const DEFAULT_CLASSNAME = 'list-item-container'
const DraggableElement = ({
  prefix,
  elements,
  dragComponent,
  isDropDisabled,
  isDragDisabled,
  hiddenHeader,
  children,
  droppableClassName = 'droppable',
  dragItemClassName,
  listItemContainerClassName = DEFAULT_CLASSNAME,
}) => {
  const [enabled] = useStrictDroppable(false)

  return (
    <div className={droppableClassName}>
      {!hiddenHeader && <div className="column-header">{prefix}</div>}

      {children}

      {enabled && (
        <Droppable droppableId={`${prefix}`} isDropDisabled={isDropDisabled}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{
                background:
                  listItemContainerClassName === DEFAULT_CLASSNAME
                    ? 'initial'
                    : snapshot.isDraggingOver
                    ? 'lightblue'
                    : 'initial',
              }}
              className={listItemContainerClassName}
            >
              {elements.map((item, index) => (
                <ListItem
                  key={`draggable-item-${item.id}-${index}`}
                  item={item}
                  index={index}
                  dragComponent={dragComponent}
                  isDragDisabled={isDragDisabled}
                  dragItemClassName={dragItemClassName}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      )}
    </div>
  )
}

DraggableElement.propTypes = {
  prefix: PropTypes.string,
  elements: PropTypes.array,
  dragComponent: PropTypes.func,
  isDropDisabled: PropTypes.bool,
  isDragDisabled: PropTypes.bool,
  hiddenHeader: PropTypes.bool,
  children: PropTypes.node,
  droppableClassName: PropTypes.string,
  dragItemClassName: PropTypes.string,
  listItemContainerClassName: PropTypes.string,
}

export default DraggableElement
