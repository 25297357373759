import { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import moment from 'moment'
import { Button, Stack } from 'react-bootstrap'
import { BsChevronRight } from 'react-icons/bs'
import EventProgressBar from '../ui-elements/EventProgressBar'
import TaskProgressBar from '../ui-elements/TaskProgressBar'
import AttendeesInfo from './AttendeesInfo'
import {
  getColor,
  getDatetime,
  getEventId,
  getLocation,
  getName,
  getState,
} from '../../utils/dataModels/events'
import { propOr } from 'ramda'
import ImagePlaceHolder from '../ui-elements/ImagePlaceHolder'
import { getUserRole, getUser } from '../../utils/state/selectors'
import { useSelector } from 'react-redux'
import { checkUserRole, isBundler } from '../../utils/state/roleTests'
import EventDropdown from '../EventDetailPage/EventDropdown'
import DonationApi from '../../api/donation'
import { useDispatch } from 'react-redux'
import {
  initialPipelineState,
  setAddDonationTypeModal,
  setRefreshConsolidated,
  setRefreshDonations,
} from '../../redux/pipeline/pipeline'

const FormattedDate = ({ date }) => {
  const formattedStr = moment(date).format('MMM Do, YYYY')
  return <>{formattedStr}</>
}

FormattedDate.propTypes = {
  date: PropTypes.string,
}

const ImgWrapper = styled.div`
  margin-right: 1rem;
  > div {
    display: table-cell;
    width: 5.5rem;
    height: 3rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.5rem;
    vertical-align: middle;
  }
`

const OpacityWrapper = styled.div`
  opacity: var(--opacity, 1);
`

const HandCursorHover = styled.div`
  &:hover {
    cursor: ${props => (props.pointer ? 'pointer' : 'default')};
  }
`

const StyledButtonTd = styled.td`
  width: 2.5rem;
`
const EventRow = ({ event }) => {
  const dispatch = useDispatch()

  const userRole = useSelector(getUserRole)
  const user = useSelector(getUser)
  const navigate = useNavigate()
  const originalEvent = propOr({}, 'event', event)
  const consolidated = propOr({}, 'consolidated', event)
  const eventId = getEventId(originalEvent)
  const [isMouseOver, setMouseOver] = useState(false)

  const mouseOverHandler = () => setMouseOver(true)
  const mouseOutHandler = () => setMouseOver(false)
  const shareEventInviteHandler = () => navigate(`/invite/${eventId}`)
  const handleAddClick = () =>
    dispatch(
      setAddDonationTypeModal({
        show: true,
        onAdd: handleNewPledge,
        onClose: handleAddClose,
      })
    )
  const handleAddClose = () =>
    dispatch(setAddDonationTypeModal(initialPipelineState.addDonationTypeModal))

  const handleNewPledge = data => {
    const payload = {
      ...data,
      type: DonationApi.transactionTypes.PLEDGE,
      eventId: data.eventId || eventId,
    }
    DonationApi.createTransaction(payload, user).then(() => {
      dispatch(setRefreshDonations(true))
      handleAddClose()
      dispatch(setRefreshConsolidated(true))
    })
  }

  const isOnTierParticipants = () => {
    const eventRef = event.event
    if (!eventRef.tiers?.length) return false
    if (!isBundler(user.role)) return false
    let exist = false
    for (const tier of eventRef.tiers) {
      exist = tier.participants.some(
        participant => participant.email === user.email
      )
      if (exist) break
    }
    return exist
  }

  return (
    <tr onMouseEnter={mouseOverHandler} onMouseLeave={mouseOutHandler}>
      <td>
        <HandCursorHover pointer={checkUserRole(userRole)}>
          <Stack
            direction="horizontal"
            onClick={() => {
              const isOnTiers = isOnTierParticipants()
              if (checkUserRole(userRole) || isOnTiers) {
                let urlTo = `/events/${eventId}`
                urlTo = isOnTiers ? `${urlTo}?isOnTiers=true` : urlTo
                navigate(urlTo)
              }
            }}
          >
            <div>
              <ImgWrapper>
                <ImagePlaceHolder
                  text={getState(originalEvent)}
                  options={{
                    isHoverable: checkUserRole(userRole),
                    color: getColor(originalEvent),
                    colorBarPosition: 'left',
                    imageSize: 'small',
                  }}
                />
              </ImgWrapper>
            </div>
            <div>{getName(originalEvent)}</div>
          </Stack>
        </HandCursorHover>
      </td>
      <td>{getLocation(originalEvent)}</td>
      <td>
        <FormattedDate date={getDatetime(originalEvent)} />
      </td>
      {checkUserRole(userRole) ? (
        <>
          <td>
            <EventProgressBar
              event={originalEvent}
              consolidated={consolidated}
            />
          </td>
          <td>
            <TaskProgressBar
              event={originalEvent}
              consolidated={consolidated}
            />
          </td>
          <td className="text-center">
            <AttendeesInfo event={originalEvent} consolidated={consolidated} />
          </td>
          <StyledButtonTd>
            <OpacityWrapper
              style={{
                '--opacity': isMouseOver ? 1 : 0,
              }}
            >
              <Button
                variant="outline-secondary"
                onClick={() => navigate(`/events/${eventId}`)}
              >
                <BsChevronRight />
              </Button>
            </OpacityWrapper>
          </StyledButtonTd>
        </>
      ) : userRole === 'bundler' ? (
        <>
          <td>
            <EventDropdown
              onShareEvent={() => shareEventInviteHandler()}
              onAddPledge={handleAddClick}
            />
          </td>
        </>
      ) : null}
    </tr>
  )
}

EventRow.propTypes = {
  event: PropTypes.object,
}

export default EventRow
