import { NavigateFunction } from 'react-router-dom'

export const navigateThreadClick = (
  threadId: string,
  messageIds: string[],
  subject: string,
  navigate: NavigateFunction
) => {
  navigate(`/mailbox/${threadId}`, {
    state: { messageIds: messageIds.join(','), subject },
  })
}

export const compressLongString = (str: string, maxLength = 50) => {
  if (str.length > maxLength) return `${str.substring(0, maxLength)}...`
  return str
}
