import { FC } from 'react'
import Stack from 'react-bootstrap/Stack'
import styled from 'styled-components'
import { BsGripVertical } from 'react-icons/bs'
import Money from '../ui-elements/Money'
import makeProfilePicUrl from '../../utils/makeProfilePicUrl'
import ImageInitials from '../ContactsPage/ImageInitials'
import PipelineCardSettings from './PipelineCardSettings'
import { IContact, IDonation } from '../../api/interfaces'
import { getInitialLettersContact } from '../../utils/stringsOperations'

const StyledMainStack = styled(Stack)`
  min-width: 170px;
  height: 63px;
  border-style: solid;
  border-width: 1px 1px 1px 4px;
  border-radius: 4px;
  border-color: #dfe4e9 #dfe4e9 #dfe4e9 var(--color);
  font-size: 0.8rem;
  user-select: none;
  .user-avatar {
    margin-left: 0.1rem;
    user-select: none;
  }
  .user-avatar > img {
    width: 40px;
    border-radius: 50%;
    user-select: none;
  }
`

const NameSpan = styled.span`
  padding-top: 8px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #3b4248;
`
const DonationSpan = styled.span`
  font-family: DM Sans;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #a6b3be;
`

interface PipelineCardProps {
  contact: IContact
  donation: IDonation
  color: string
  picture: string
}
const PipelineCard: FC<PipelineCardProps> = ({
  contact,
  donation,
  color,
  picture,
}) => {
  const hasEmptyPicture =
    picture === undefined || picture === '' || picture === 'unassigned'
  const userIcon = makeProfilePicUrl({ picture })

  const initials = getInitialLettersContact(contact)

  return (
    <StyledMainStack
      gap={2}
      direction="horizontal"
      style={{ '--color': color }}
    >
      <BsGripVertical size={14} color={'#A6B3BE'} />
      <div className="user-avatar">
        {!hasEmptyPicture && <img src={userIcon} draggable={false} />}
        {hasEmptyPicture && (
          <ImageInitials
            initials={initials}
            size={'40'}
            sizeUnit={'px'}
            bgColor={'var(--bs-avatar)'}
            fColor={'white'}
          />
        )}
      </div>
      <Stack gap={1}>
        <NameSpan>{`${contact?.first_name || ''} ${
          contact?.last_name || ''
        }`}</NameSpan>
        <DonationSpan>
          {donation.amount && <Money value={donation.amount} />}
        </DonationSpan>
      </Stack>
      {donation.type === 'pledge' && (
        <PipelineCardSettings donation={donation} />
      )}
    </StyledMainStack>
  )
}

export default PipelineCard
