import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col, Row } from 'react-bootstrap'
import CustomAccordion from '../ui-elements/CustomAccordion'
import { propOr } from 'ramda'

const defaultBio = ''

const Styledcontainer = styled.div`
  border-radius: 0.25rem;
  padding: 1rem;
  border-style: solid;
  border-width: 1px;
  border-color: #d9d9d9;
`

const SpanTitle = styled.span`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`
export const SpanDescription = styled.span`
  font-family: DM Sans;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${props => (props.isGrey ? `#79838d;` : `#3b4248;`)};
`

const getBio = propOr(defaultBio, 'aboutThisGuy')

const ContactAboutMe = ({ contact }) => {
  return (
    <Styledcontainer>
      <Row className="pb-4">
        <Col>
          <SpanTitle>About Me</SpanTitle>
        </Col>
      </Row>
      {getBio(contact) != '' ? (
        <>
          <Row className="pb-4">
            <Col>
              <SpanDescription>{getBio(contact)}</SpanDescription>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="pb-4">
          <Col>
            <SpanDescription isGrey>{`Contact's Biography`}</SpanDescription>
          </Col>
        </Row>
      )}
    </Styledcontainer>
  )
}

ContactAboutMe.propTypes = {
  contact: PropTypes.object,
}

export default ContactAboutMe
