import { useEffect, useState } from 'react'
import {
  Button,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Modal,
  Row,
  Col,
} from 'react-bootstrap'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { path, pluck, propEq, either } from 'ramda'
import usStates from '../../assets/datasets/us-states.json'
import USStatesCities from '../../assets/datasets/USStatesCities.json'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { defaultContactValues } from '../ContactUploadFilePage/utilsContactData'
import { useSelector } from 'react-redux'
import {
  getUser,
  getUserOrganizationId,
  getUserRole,
} from '../../utils/state/selectors'
import ApiContacts from '../../api/contact'
import TagApi from '../../api/tag'
import { CONTACT_LEVELS } from '../../api/enums'
import ApiOrg from '../../api/organization'

export const RedSup = styled.sup`
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  color: #dc3545;
`

const findOption = (value, options) =>
  options.find(either(propEq('value', value), propEq('label', value)))

const DEFAULT_LEVEL_OPTIONS = [
  { value: CONTACT_LEVELS.CONTACT, label: CONTACT_LEVELS.CONTACT },
]

const CreateContactModal = ({ show, handleClose, handleUpdate }) => {
  const [contactModel, setContactModel] = useState({ ...defaultContactValues })
  const [error, setError] = useState(null)
  const [validation, setValidation] = useState(false)
  const [created, setCreated] = useState(false)
  const [tags, setTags] = useState([])
  const [levelOptions, setLevelOptions] = useState(DEFAULT_LEVEL_OPTIONS)
  const user = useSelector(getUser)
  const userOrgId = useSelector(getUserOrganizationId)
  const userRole = useSelector(getUserRole())

  useEffect(() => {
    if (show) {
      setContactModel({ ...defaultContactValues })
      setError('')
      setCreated(false)
    }
  }, [show])

  useEffect(() => {
    ApiOrg.getBundlerLevelNames(userOrgId).then(levels => {
      if (userRole !== 'bundler') {
        setLevelOptions(data => {
          levels.forEach(level => {
            data = data.filter(entry => entry.label !== level)
            data.push({ label: level, value: level })
          })
          return data
        })
      }
    })
  }, [])

  const stateOptions = [
    { value: '', label: '' },
    ...usStates.map(({ name, state }) => ({
      value: state,
      label: name,
    })),
  ]
  const cityOptions = contactModel.state?.length
    ? USStatesCities[contactModel.state]?.map(city => ({
        label: city,
        value: city,
      }))
    : []

  const fetchTags = () =>
    TagApi.getTags(userOrgId).then(tags => {
      setTags(tags)
    })

  const createTag = tag =>
    TagApi.createTag(tag, userOrgId)
      .then(() => {
        setContactModel(prevState => {
          if (prevState.tags) {
            return {
              ...prevState,
              tags: [...prevState.tags, tag],
            }
          } else {
            return {
              ...prevState,
              tags: [tag],
            }
          }
        })
        fetchTags()
      })
      .catch(error => {
        setError('Error creating new tag ', error)
      })

  useEffect(() => {
    setValidation(validateRequiredFields())
  }, [contactModel])

  useEffect(() => {
    fetchTags()
  }, [])
  const selectStyles = {
    input: provided => ({
      ...provided,
      border: 'none',
      '> input[type=text]:focus': { outline: 'none !important' },
    }),
  }

  const changeHandler = e => {
    setError('')
    const fieldName = path(['target', 'name'], e)
    const value = path(['target', 'value'], e)
    setContactModel(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const changeHandlerLetters = e => {
    setError('')
    const fieldName = path(['target', 'name'], e)
    let value = path(['target', 'value'], e)
    if (fieldName !== 'inviteAlias') {
      value = value.replace(/[^a-zA-Z]+/g, '')
    }
    setContactModel(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const changeHandlerPhone = e => {
    setError('')
    let formattedValue = ''
    const fieldName = path(['target', 'name'], e)
    let value = path(['target', 'value'], e)
    value = value.replace(/\D/g, '')
    if (value.length > 10) {
      value = value.slice(0, 10)
    }
    if (value.length >= 7) {
      formattedValue = `(${value.slice(0, 3)}) - ${value.slice(
        3,
        6
      )} ${value.slice(6)}`
    } else if (value.length >= 4) {
      formattedValue = `(${value.slice(0, 3)}) - ${value.slice(3)}`
    } else {
      formattedValue = value
    }
    setContactModel(prevState => ({
      ...prevState,
      [fieldName]: formattedValue,
    }))
  }

  const selectChangeHandler = fieldName => e => {
    const value = path(['value'], e)
    setContactModel(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const selectTagsHandler = e => {
    const value = pluck('value', e)
    setContactModel(prevState => ({
      ...prevState,
      tags: value,
    }))
  }

  const handleFileChange = event => {
    const file = event.target.files[0]
    setContactModel(prevState => ({
      ...prevState,
      picture: file,
    }))
  }

  const isValidEmail = email => {
    return /\S+@\S+\.\S+/.test(email)
  }

  const validateRequiredFields = () => {
    if (
      contactModel?.first_name === '' ||
      contactModel?.last_Name === '' ||
      !isValidEmail(contactModel?.email)
    ) {
      return false
    }

    return true
  }

  const handleOnSubmit = () => {
    if (
      contactModel?.primaryEmail &&
      !isValidEmail(contactModel.primaryEmail)
    ) {
      setError('Please enter a valid email address.')
    }
    if (validateRequiredFields()) {
      const data = {
        contacts: [
          { ...contactModel, user_id: user?._id, organizationId: userOrgId },
        ],
      }
      ApiContacts.createContacts(data)
        .then(() => {
          setCreated(true)
          handleUpdate()
        })
        .catch(error => {
          setError('Error creating the contact ', error)
        })
    }
  }

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {!created ? 'Create Contact' : 'Contact created'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!created ? (
          <>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup className="mb-3">
                    <FormLabel>
                      Name <RedSup>*</RedSup>
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="John"
                      autoComplete="off"
                      name="first_name"
                      value={contactModel?.first_name}
                      onChange={changeHandlerLetters}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="mb-3">
                    <FormLabel>
                      Last Name <RedSup>*</RedSup>
                    </FormLabel>
                    <FormControl
                      type="text"
                      placeholder="Doe"
                      autoComplete="off"
                      name="last_name"
                      value={contactModel?.last_name}
                      onChange={changeHandlerLetters}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup className="mb-3">
                <FormLabel>Email</FormLabel>
                <FormControl
                  type="email"
                  placeholder="johndoe@email.com"
                  autoComplete="off"
                  name="email"
                  value={contactModel?.email}
                  onChange={changeHandler}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel>Phone Number</FormLabel>
                <FormControl
                  type="tel"
                  placeholder="(000) - 000 0000"
                  autoComplete="off"
                  name="primaryPhoneNumber"
                  value={contactModel?.primaryPhoneNumber}
                  onChange={changeHandlerPhone}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel htmlFor="address">State</FormLabel>
                <Select
                  id="state"
                  value={
                    contactModel?.state &&
                    findOption(contactModel?.state, stateOptions)
                  }
                  options={stateOptions}
                  placeholder="Select state"
                  onChange={selectChangeHandler('state')}
                  styles={selectStyles}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel htmlFor="address">City</FormLabel>
                <CreatableSelect
                  id="city"
                  isDisabled={!contactModel.state_abbr.length > 0}
                  value={
                    contactModel.city
                      ? {
                          label: contactModel.city,
                          value: contactModel.city,
                        }
                      : null
                  }
                  options={cityOptions}
                  placeholder="Select city"
                  onChange={selectChangeHandler('city')}
                  styles={selectStyles}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel htmlFor="level">Level</FormLabel>
                <Select
                  id="level"
                  defaultValue={{
                    label: CONTACT_LEVELS.CONTACT,
                    value: CONTACT_LEVELS.CONTACT,
                  }}
                  options={levelOptions}
                  placeholder="Select Level"
                  onChange={selectChangeHandler('level')}
                  styles={selectStyles}
                  isDisabled={userRole === 'bundler' ? 'disabled' : ''}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel>Invite Alias</FormLabel>
                <FormControl
                  type="text"
                  placeholder="John"
                  autoComplete="off"
                  name="inviteAlias"
                  value={contactModel?.invite_alias}
                  onChange={changeHandlerLetters}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <FormLabel htmlFor="tags">Tags</FormLabel>
                <CreatableSelect
                  name="tags"
                  closeMenuOnSelect
                  isMulti
                  onChange={selectTagsHandler}
                  S
                  options={tags}
                  onCreateOption={createTag}
                  openMenuOnFocus
                  placeholder="Select Tags"
                  styles={selectStyles}
                  value={
                    contactModel.tags
                      ? contactModel.tags?.map(t => ({
                          label: t,
                          value: t,
                        }))
                      : null
                  }
                />
              </FormGroup>
              <FormGroup controlId="formFile" className="mb-3">
                <FormLabel>Upload a Profile Picture (optional)</FormLabel>
                <FormControl
                  type="file"
                  onChange={handleFileChange}
                  accept=".jpg, .jpeg, .png, .svg"
                />
              </FormGroup>
            </Form>
            {error}
          </>
        ) : (
          <span>{`${contactModel?.first_name} ${contactModel?.last_name}  was added to your contacts`}</span>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!created && (
          <>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleOnSubmit}
              disabled={!validation}
            >
              Create Contact
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  )
}

CreateContactModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  handleUpdate: PropTypes.func,
}

export default CreateContactModal
