/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form } from 'react-bootstrap'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import BTable from '../../../BTable/BTable'
import { IContact } from '../../../../api/interfaces'
import { BTableHeaders } from '../../../BTable/types'
import { FC, useEffect, useState } from 'react'
import { CheckContact, FilterContactResultsProps } from './types'

const HEADERS: BTableHeaders<IContact>[] = [
  { title: 'Name', key: ['first_name', 'last_name'] },
  { title: 'Email', key: 'email' },
  { title: '', key: 'email', disabledSort: false },
]

const FilterContactResults: FC<FilterContactResultsProps> = ({
  contacts,
  onChange,
}) => {
  const [checkList, setCheckList] = useState<CheckContact>({})
  const [checkAll, setCheckAll] = useState<boolean>(false)

  const checkedContacts = () => {
    if (checkAll) {
      onChange(contacts)
      return
    }
    onChange(contacts.filter(contact => checkList[contact._id]))
  }

  useEffect(() => checkedContacts(), [checkAll, checkList])

  useEffect(() => {
    setCheckList(
      contacts.reduce<CheckContact>((prev, curr) => {
        prev[curr._id] = checkAll
        return prev
      }, {})
    )
  }, [checkAll])

  const renderRow = (row: IContact) => (
    <tr key={row._id}>
      <td>
        <small>
          {row.first_name} {row.last_name}
        </small>
      </td>
      <td>
        <small>{row.email}</small>
      </td>
      <td>
        <Form.Check
          type="checkbox"
          checked={checkList[row._id]}
          onChange={() =>
            setCheckList({
              ...checkList,
              [row._id]: !checkList[row._id],
            })
          }
        />
      </td>
    </tr>
  )
  return (
    <div className="overflow-scroll">
      <BTable<IContact>
        pageLength={5}
        data={contacts as []}
        headers={HEADERS}
        renderHeader={(header, index, defaultRender) =>
          header.title === ''
            ? defaultRender(
                <Form.Check
                  disabled={!contacts.length}
                  key={index}
                  type="checkbox"
                  label="All"
                  checked={checkList.selectAll}
                  onChange={() => setCheckAll(!checkAll)}
                />
              )
            : defaultRender()
        }
        renderRow={renderRow}
      />
    </div>
  )
}

export default FilterContactResults
