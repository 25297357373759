import { FC } from 'react'
import { Col, Row } from 'react-bootstrap'

interface ReportPageContainerProps {
  children: JSX.Element | JSX.Element[]
}
const BTableWrapper: FC<ReportPageContainerProps> = ({ children }) => {
  return (
    <Row>
      <Col sm={12}>{children}</Col>
    </Row>
  )
}

export default BTableWrapper
