import React, { FC, useEffect, useState } from 'react'
import BTable from '../BTable/BTable'

import ApiDonation from '../../api/donation'
import ApiEvent from '../../api/event'
import { Col, Container, FormSelect, Row, Stack } from 'react-bootstrap'
import { pathOr } from 'ramda'
import {
  DotNestedKeys,
  IDonation,
  IEvent,
  KeyValue,
} from '../../api/interfaces'
import { DonationRow } from './DonationRow/DonationRow'
import { LinkSpan } from '../ContactUploadFilePage/ContactUploadFilePage/styles'
import csvDownload from 'json-to-csv-export'
import { BTableHeaders } from '../BTable/types'
import { Wrapper } from '../ContactsPage/ContactsPage'
import { formatValue } from '../ui-elements/Money'
import { DATEFORMAT, timestampToDateString } from '../../utils/timestamp'
import { getEventId } from '../../utils/dataModels/events'
import BTableWrapper from './ReportPageContainer/BTableWrapper'
import { ReportPageProps } from './types'
import { filterAggregate } from './config'
import { useSelector } from 'react-redux'
import { getUserOrganizationId } from '../../utils/state/selectors'

const OPTION_ALL: IEvent = {
  _id: 'all',
  userId: '',
  name: 'All',
  state: '',
  city: '',
  street: '',
  day: '',
  hour: '',
  venue: '',
  venueTBD: false,
  addressTBD: false,
  dayTBD: false,
  hourTBD: false,
  locationRSVP: false,
  financialGoal: 0,
  suggestedDonation: 0,
  tiers: [],
  organizationId: '',
  createdAt: 0,
  updatedAt: 0,
}
const HEADERS: BTableHeaders<IDonation>[] = [
  { title: 'Firstame', key: 'donor.first_name' },
  { title: 'LastName', key: 'donor.last_name' },
  { title: 'MI', key: 'donor.middle_initial' },
  { title: 'Amount', key: 'amount' },
  { title: 'Committee', key: 'committee' },
  { title: 'Fund', key: 'fund' },
  { title: 'Type', key: 'type' },
  // { title: 'DonorId', key: 'donor._id' },
  { title: 'Election', key: 'election' },
  // { title: 'EventId', key: 'event._id' },
  { title: 'State', key: 'event.state' },
  { title: 'Committee', key: 'committee' },
  { title: 'Created At', key: 'createdAt' },
  { title: 'Updated At', key: 'updatedAt' },
]

const ReportPage: FC<ReportPageProps> = ({
  contact,
  hideToolbar = false,
  headers = HEADERS,
  donationRowProps,
  setBTableWrapper,
}) => {
  const [isLoading, setIsLoading] = useState(true)

  const [events, setEvents] = useState<IEvent[]>([])
  const [donations, setDonation] = useState<IDonation[]>([])
  const [eventStates, setEventStates] = useState<string[]>([])

  const [eventSelect, setEventSelect] = useState<IEvent>()
  const [eventStateSelect, setEventStateSelect] = useState<string>()

  const organizationId = useSelector(getUserOrganizationId)

  const isAllEvents = eventSelect && eventSelect._id === 'all'
  const loadAllDonations = () => {
    const matchQuery: KeyValue = contact ? { donorId: contact?._id } : {}
    const aggregateQuery = [
      {
        $match: {
          ...matchQuery,
          organizationId,
        },
      },
      ...filterAggregate,
    ]
    ApiDonation.filterDonations(aggregateQuery)
      .then((donations: IDonation[]) => {
        setDonation(donations)
      })
      .finally(() => setIsLoading(false))
  }

  const loadDonationsFromEvents = (promises: Promise<IDonation[]>[]) =>
    Promise.all(promises)
      .then(response => setDonation(response.flat() as IDonation[]))
      .finally(() => setIsLoading(false))

  useEffect(() => {
    if (events.length) {
      setEventSelect(OPTION_ALL)
      loadEventStates(eventSelect)
    }
  }, [events])

  useEffect(() => {
    if (isAllEvents) {
      setIsLoading(true)
      if (eventStateSelect) {
        // All  - NY/ML
        const eventsByState = events.filter(
          event => event.state === eventStateSelect
        )
        const promises = eventsByState.map<Promise<IDonation[]>>(
          event =>
            new Promise(resolve =>
              resolve(ApiDonation.getEventTransactions(getEventId(event)))
            )
        )
        loadDonationsFromEvents(promises)
      } else {
        // All - Select State (init)
        loadAllDonations()
      }
    } else {
      setIsLoading(true)
      setEventStateSelect(eventSelect?.state)
      // Event1 - NY
      eventSelect &&
        loadDonationsFromEvents([
          new Promise(resolve =>
            resolve(ApiDonation.getEventTransactions(getEventId(eventSelect)))
          ),
        ])
    }
  }, [eventSelect, eventStateSelect])

  // Load event states
  const loadEventStates = (eventSelect: IEvent | undefined) => {
    const uniqueEventStates = events.reduce<string[]>((prev, curr) => {
      if (prev.indexOf(curr.state) === -1) {
        prev.push(curr.state)
      }
      return prev
    }, [])

    setEventStates(uniqueEventStates)
    setEventStateSelect(eventSelect?.state)
  }

  // First Render
  useEffect(() => {
    setIsLoading(true)
    ApiEvent.getEvents().then((events: IEvent[]) => {
      const eventWithDonations = events.filter(event => event.donations?.length)
      setEvents([...[OPTION_ALL], ...eventWithDonations])
    })
  }, [])

  const selectChangeHandler =
    (type: 'event' | 'state') => (e: React.ChangeEvent) => {
      e.preventDefault()
      e.stopPropagation()
      const value = pathOr<string>('', ['target', 'value'], e)
      if (type === 'event') {
        setEventSelect(events.find(event => event._id === value))
      }
      if (type == 'state') {
        setEventStateSelect(value)
      }
    }

  const getDonationRowValue = (
    rowKey: DotNestedKeys<IDonation> | DotNestedKeys<IDonation>[],
    donation: IDonation
  ) => {
    let rowValue = ''
    if (Array.isArray(rowKey)) {
      rowValue = rowKey.reduce(
        (prev, curr) =>
          `${prev} ${pathOr<string>('', curr.split('.'), donation)}`,
        ''
      )
    } else {
      rowValue = pathOr<string>('', rowKey.split('.'), donation)
      if (rowKey === 'amount') {
        rowValue = `${formatValue(rowValue, 0, 0, true)}`
      }
      if (rowValue && (rowKey === 'createdAt' || rowKey === 'updatedAt')) {
        const dateFormatted = timestampToDateString(
          rowValue,
          DATEFORMAT.DATETIME4D3MHA
        )
        if (typeof dateFormatted === 'string') {
          rowValue = dateFormatted
        }
      }
    }
    return rowValue
  }

  const getRowKey = (
    rowKey: DotNestedKeys<IDonation> | DotNestedKeys<IDonation>[]
  ) => {
    if (Array.isArray(rowKey)) {
      return rowKey[0]
    }
    return rowKey
  }

  const getDonationRow = (donation: IDonation) => {
    return headers
      .map(header => header.key)
      .reduce<KeyValue>((prev, curr) => {
        prev[getRowKey(curr)] = getDonationRowValue(curr, donation)
        return prev
      }, {})
  }
  const handleClickExport = () => {
    const dataToConvert = {
      data: donations.map(donation => getDonationRow(donation)),
      filename: 'DonationsReport',
      delimiter: ',',
      headers: headers.map(header => header.title),
    }
    csvDownload(dataToConvert)
  }
  const ToolbarComponent = (
    <Row className="pb-4">
      <Col sm={3}>
        {/* Dropdown Events */}
        <Stack gap={2}>
          <label>
            <strong>Filter by</strong>
          </label>
          <FormSelect
            id="state"
            value={eventSelect?._id}
            onChange={selectChangeHandler('event')}
            disabled={isLoading}
          >
            {!events.length && <option value={''}>All</option>}
            {events
              .filter(event => event.name !== '')
              .map(({ state, name, _id }, index) => (
                <option key={state + index} value={_id}>
                  {name}
                </option>
              ))}
          </FormSelect>
        </Stack>
      </Col>
      <Col sm={3}>
        {/* Dropdown Event's States */}
        <Stack gap={2}>
          <label>
            <strong>Group by</strong>
          </label>
          <FormSelect
            id="state"
            value={eventStateSelect}
            onChange={selectChangeHandler('state')}
            disabled={isLoading || eventSelect?._id !== 'all'}
          >
            <option value={''}>Select a state</option>
            {eventStates
              .filter(eventState => eventState !== '')
              .map((eventStateName, index) => (
                <option key={eventStateName + index} value={eventStateName}>
                  {eventStateName}
                </option>
              ))}
          </FormSelect>
        </Stack>
      </Col>
      <Col className="d-flex align-items-end">
        <LinkSpan
          onClick={isLoading ? undefined : handleClickExport}
          className="mt-2"
        >
          Export Report
        </LinkSpan>
      </Col>
    </Row>
  )

  const BTableRender = (
    <BTable<IDonation>
      isLoading={isLoading}
      ToolbarComponent={hideToolbar ? undefined : ToolbarComponent}
      data={donations as []}
      headers={headers}
      renderRow={(row, index) => (
        <DonationRow
          {...donationRowProps}
          key={`row-${row._id}-${index}`}
          donation={row as IDonation}
          rowKeys={headers}
        />
      )}
    />
  )
  return (
    /* This report  was originally named Donation Report, and a new view
    named Donation report was created. Identifiers in the legacy code
    were not altered to relfect the name change.*/
    <Wrapper>
      <Container fluid>
        {!hideToolbar && <h1>Fundraising Report</h1>}
        {setBTableWrapper ? (
          setBTableWrapper(BTableRender)
        ) : (
          <BTableWrapper>{BTableRender}</BTableWrapper>
        )}
      </Container>
    </Wrapper>
  )
}

export default ReportPage
