import { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import Api from '../../api/contact'
import './ZoomCall.css'
import constants from '../../config/constants'
import RightPanel from './RightPanel'
import RightBar from './RightBar'
import { useSelector } from 'react-redux'
import { getUserFullName } from '../../utils/state/selectors'
import { useScript } from './useScript'
import api from '../../api/contact'

import { sendNodeMailerEmail } from '../../api/nodemailer/nodemailer'
import { useParams } from 'react-router-dom'
import { useZoomCredentials } from '../../hooks/useZoomCredentials'

const getStorageZoomData = () =>
  JSON.parse(localStorage.getItem('B-ZOOM-METTING-DATA'))

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
`

const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${p =>
    p.isPanelCollapsed ? 'calc(100vw - 6rem)' : 'calc(100vw - 24.625rem)'};
  height: 100vh;
  transition: width 0.5s ease;
  overflow: hidden;
  background-color: #242424;
`

const RightBarWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`

const RightPanelWrapper = styled.div`
  position: absolute;
  top: 0;
  right: ${p => (p.isCollapsed ? '-18.625rem' : '6rem')};
  transition: right 0.5s ease;
`

const EndButton = styled(Button)`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 0.8rem;
  font-weight: bold;
  transition: position 0.5s ease;
`

const Loading = () => (
  <div className="w-100 h-100 d-flex align-items-center justify-content-center">
    Loading ...
  </div>
)

let client
const ZoomCall = () => {
  const { contactId } = useParams()
  const [contact, setContact] = useState({})
  const { encryptedPassword, meetingNumber, joinUrl, organizationId } =
    getStorageZoomData()
  const signatureEndpoint = constants.ZOOM_SIGNCALL_ENDPOINT()
  const role = 1
  const userEmail = ''
  const registrantToken = ''
  const fullname = useSelector(getUserFullName)

  const [meetingSignature, setSignature] = useState('')
  const [mount, setMount] = useState(false)
  const [selectedView, setSelectedView] = useState('vertical-panel')
  const isPanelCollapsed = selectedView === 'collapsed-panel'

  const { zoomCredentials } = useZoomCredentials()

  const [isLoading, setIsLoading] = useState(false)
  const [libsLoaded, setLibsLoaded] = useState(false)

  const { React } = useScript(
    'https://source.zoom.us/2.17.0/lib/vendor/react.min.js',
    'React'
  )

  const { ReactDOM } = useScript(
    'https://source.zoom.us/2.17.0/lib/vendor/react-dom.min.js',
    'ReactDOM'
  )

  const { Redux } = useScript(
    'https://source.zoom.us/2.17.0/lib/vendor/redux.min.js',
    'Redux'
  )

  const { ReduxThunk } = useScript(
    'https://source.zoom.us/2.17.0/lib/vendor/redux-thunk.min.js',
    'ReduxThunk'
  )

  const { _ } = useScript(
    'https://source.zoom.us/2.17.0/lib/vendor/lodash.min.js',
    '_'
  )

  const { ZoomMtgEmbedded } = useScript(
    'https://source.zoom.us/2.17.0/zoom-meeting-embedded-2.17.0.min.js',
    'ZoomMtgEmbedded',
    libsLoaded
  )

  const { ZoomMtg } = useScript(
    'https://source.zoom.us/2.17.0/zoom-meeting-2.17.0.min.js',
    'ZoomMtg',
    libsLoaded
  )

  useEffect(() => {
    if (contactId && contactId != '') {
      let isMounted = true
      Api.getContact(contactId).then(cc => (isMounted ? setContact(cc) : 0))
      return () => {
        isMounted = false
      }
    }
  }, [contactId])

  const startMeeting = useCallback(
    async signature => {
      setIsLoading(false)
      try {
        const bodyFormData = new FormData()
        bodyFormData.append(
          'data',
          JSON.stringify({
            to: contact.primaryEmail,
            subject: `[Benjamin App] (Meeting Invite) ${fullname} sent you a New Zoom call`,
            text: '',
            html: `<strong>Please join to zoom call using url: </strong> ${joinUrl}`,
          })
        )
        await sendNodeMailerEmail(bodyFormData)
      } catch (error) {
        console.error(error)
      }
      const meetingSDKElement = document.getElementById('meetingSDKElement')
      const parentElem = meetingSDKElement.parentElement
      const { /*width: containerWidth*/ height: containerHeight } =
        parentElem.getBoundingClientRect()
      const clientDimensions = {
        width: 1.777777778 * containerHeight - 142.22222224,
        height: containerHeight - 80,
      }
      try {
        await client.init({
          zoomAppRoot: meetingSDKElement,
          language: 'en-US',
          customize: {
            meetingInfo: [
              'topic',
              'host',
              'mn',
              'pwd',
              'telPwd',
              'invite',
              'participant',
              'dc',
              'enctype',
            ],
            /* toolbar: {
              buttons: [
                {
                  text: 'Custom Button',
                  className: 'CustomButton',
                  onClick: () => {
                    console.log('custom button')
                  },
                },
              ],
            }, */
            video: {
              isResizable: true,
              popper: {
                disableDraggable: false,
              },
              viewSizes: {
                default: clientDimensions,
                ribbon: {
                  width: 600,
                  height: 300,
                },
              },
            },
          },
        })
      } catch (error) {
        console.log(error)
        return
      }
      try {
        client
          .join({
            sdkKey: zoomCredentials.zoomSDKClientId,
            signature,
            meetingNumber: Number(meetingNumber),
            password: encryptedPassword,
            userName: 'Zoom meeting',
            userEmail,
            tk: registrantToken,
          })
          .then(() => {
            try {
              client.on('connection-change', async payload => {
                if (payload.state === 'Closed') {
                  try {
                    await client.endMeeting()
                  } catch (error) {
                    console.log(error)
                  }
                  try {
                    await ZoomMtg.endMeeting({
                      success: payload => console.log(payload),
                      error: payload => console.log(payload),
                    })
                  } catch (error) {
                    console.log(error)
                  }
                  setMount(false)

                  window.open('', '_self')
                  window.close()
                }
              })
            } catch (error) {
              console.log('ON=>error', error)
            }
          })
          .then(() => {
            try {
              const ch1 = meetingSDKElement.querySelector('div:first-child')
              const ch2 = ch1.querySelector('div:first-child')
              const ch3 = ch2.querySelector('div:first-child')
              const speakerView = meetingSDKElement
                .querySelectorAll('#suspension-view-tabpanel-speaker')
                .item(1)
              const ch4 = speakerView.querySelector('div:first-child')
              const windowControls = meetingSDKElement.querySelector(
                '.zmwebsdk-MuiTabs-scroller.zmwebsdk-MuiTabs-fixed'
              )
              ch1.style.width = '100%'
              ch2.style.width = '100%'
              ch3.style.width = '100%'
              ch4.style.width = '100%'
              ch4.style.justifyContent = 'center'
              windowControls.style.opacity = 0
            } catch (err) {
              console.log('error resizing', err)
            }
          })
          .catch(err => console.error(err))
      } catch (error) {
        console.log('joining error', error)
      }
    },
    [
      registrantToken,
      zoomCredentials.zoomSDKClientId,
      userEmail,
      client,
      encryptedPassword,
      meetingNumber,
      joinUrl,
    ]
  )

  const initMeeting = async () => {
    try {
      await client.endMeeting()
    } catch (error) {
      console.log(error)
    }
    try {
      startMeeting(meetingSignature)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (mount && meetingSignature) {
      initMeeting()
    }
  }, [mount, meetingSignature, startMeeting])

  const getSignature = useCallback(() => {
    const data = {
      meetingNumber,
      role,
      organizationId,
    }
    api
      .signZoomLink(data)
      .then(response => {
        setMount(true)
        setSignature(response.signature)
      })
      .catch(error => {
        console.error(error)
      })
  }, [meetingNumber, role, signatureEndpoint])

  useEffect(() => {
    if (React && ReactDOM && Redux && ReduxThunk && _) {
      setTimeout(() => setLibsLoaded(true), 700)
    }
  }, [React, ReactDOM, Redux, ReduxThunk, _])

  useEffect(() => {
    if (
      encryptedPassword &&
      meetingNumber &&
      joinUrl &&
      ZoomMtg !== {} &&
      ZoomMtg &&
      ZoomMtgEmbedded !== {} &&
      ZoomMtgEmbedded &&
      zoomCredentials.zoomSDKClientId
    ) {
      setTimeout(() => {
        try {
          ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av')
          ZoomMtg.preLoadWasm()
          ZoomMtg.prepareWebSDK()
          // // loads language files, also passes any error messages to the ui
          ZoomMtg.i18n.load('en-US')
          ZoomMtg.i18n.reload('en-US')
          client = ZoomMtgEmbedded.createClient()
          setIsLoading(true)
          getSignature()
        } catch (err) {
          console.log('Error preparing Zoom SDK', err)
        }
        console.log('preparing zoom sdk... done')
      }, 500)
    }
  }, [
    getSignature,
    encryptedPassword,
    joinUrl,
    meetingNumber,
    ZoomMtg,
    ZoomMtgEmbedded,
    zoomCredentials.zoomSDKClientId,
  ])

  useEffect(() => {
    const handleTabClose = async event => {
      event.preventDefault()
      try {
        await client.endMeeting()
      } catch (error) {
        console.log(error)
      }
      return true
    }

    window.addEventListener('beforeunload', handleTabClose)

    return () => {
      window.removeEventListener('beforeunload', handleTabClose)
    }
  }, [client])

  const endMeetingHandler = async () => {
    try {
      await client.endMeeting()
    } catch (error) {
      console.log(error)
    }
    window.open('', '_self')
    window.close()
  }

  return (
    <Wrapper>
      <ContentWrapper isPanelCollapsed={isPanelCollapsed}>
        <div className="h-100 m-0 p-0">
          {mount && (
            <div id="meetingSDKElement">
              {/* Zoom Meeting SDK Component View Rendered Here */}
            </div>
          )}
          {isLoading && <Loading />}
          {!isLoading && (
            <EndButton
              type="button"
              variant="danger"
              onClick={endMeetingHandler}
            >
              End
            </EndButton>
          )}
        </div>
      </ContentWrapper>
      <RightPanelWrapper isCollapsed={isPanelCollapsed}>
        <RightPanel
          selectedView={selectedView}
          contact={contact}
          onClose={() => setSelectedView('collapsed-panel')}
        />
      </RightPanelWrapper>
      <RightBarWrapper>
        <RightBar
          selectedOption={selectedView}
          onSelectOption={setSelectedView}
        />
      </RightBarWrapper>
    </Wrapper>
  )
}

export default ZoomCall
