import PropTypes from 'prop-types'

const PreviousIcon = ({ fill = '#576068' }) => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.51519 0.234682C5.55012 0.269516 5.57782 0.310898 5.59673 0.356457C5.61563 0.402016 5.62536 0.450857 5.62536 0.500182C5.62536 0.549508 5.61563 0.598349 5.59673 0.643907C5.57782 0.689466 5.55012 0.730848 5.51519 0.765682L1.27994 5.00018L5.51519 9.23468C5.58561 9.3051 5.62517 9.4006 5.62517 9.50018C5.62517 9.59976 5.58561 9.69527 5.51519 9.76568C5.44478 9.8361 5.34928 9.87566 5.24969 9.87566C5.15011 9.87566 5.05461 9.8361 4.98419 9.76568L0.484194 5.26568C0.449271 5.23085 0.421564 5.18947 0.402659 5.14391C0.383754 5.09835 0.374023 5.04951 0.374023 5.00018C0.374023 4.95086 0.383754 4.90202 0.402659 4.85646C0.421564 4.8109 0.449271 4.76952 0.484194 4.73468L4.98419 0.234682C5.01903 0.19976 5.06041 0.172053 5.10597 0.153148C5.15153 0.134243 5.20037 0.124512 5.24969 0.124512C5.29902 0.124512 5.34786 0.134243 5.39342 0.153148C5.43898 0.172053 5.48036 0.19976 5.51519 0.234682Z"
        fill={fill}
      />
    </svg>
  )
}
PreviousIcon.propTypes = {
  fill: PropTypes.string,
}
export default PreviousIcon
