const types = {
  LOGIN: '[authentication] Login',
  START_LOGIN_PROCESS: '[authentication] Start Login Process',
  FINISH_LOGIN_PROCESS: '[authentication] Finish Login Process',
  LOGOUT: '[authentication] Logout',
  LOGIN_ERROR: '[authentication] Login error',
  AZURE_LOGIN: '[authentication] Azure Login',
  GOOGLE_LOGIN: '[authentication] Google Login',
  LINKEDIN_LOGIN: '[authentication] LinkedIn Login',
  UI_START_LOADING: '[ui] start loading',
  UI_FINISH_LOADING: '[ui] finish loading',
  UI_SET_PAGE_TITLE: '[ui] set page title',
  UI_SET_PAGE_BREADCRUMBS: '[ui] set page breadcrumbs',
  MAILBOX_SET_PAGINATION:
    '[mailbox] set offset and limit for pagination mailbox',
  MAILBOX_SET_PAGE_DATA:
    '[mailbox] set cache of range of data for specific mailbox',
  MAILBOX_SET_FOLDER: '[mailbox] set folder selected by specific email mailbox',
  MAILBOX_SET_OPENED: '[mailbox] set opened email threads',
  CHAT_SETROOM: '[chat] set room into the chat',
}

export default types
