import React from 'react'
import { Placeholder } from 'react-bootstrap'
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table'
import {
  BsChevronLeft,
  BsChevronRight,
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
} from 'react-icons/bs'
import PropTypes from 'prop-types'
import IndeterminateCheckbox from './IndeterminateCheckbox'
import Spinner from 'react-bootstrap/Spinner'

function useSkipper() {
  const shouldSkipRef = React.useRef(true)
  const shouldSkip = shouldSkipRef.current

  const skip = React.useCallback(() => {
    shouldSkipRef.current = false
  }, [])

  React.useEffect(() => {
    shouldSkipRef.current = true
  })

  return [shouldSkip, skip]
}

const CRMTable = ({
  columns,
  data,
  loadingData,
  setData,
  columnFilters,
  setColumnFilters,
  rowSelection,
  setRowSelection,
  sorting,
  setSorting,
  pagination,
  setPagination,
  showGoToPage = true,
  showPageSize = true,
  currentPage,
  setCurrentPage,
  pageCount,
  pageSize,
  setPageSize,
}) => {
  /* Table state */
  // const [columnFilters, setColumnFilters] = React.useState([])
  // const [rowSelection, setRowSelection] = React.useState({})
  // const [sorting, setSorting] = React.useState([])
  // const [pagination, setPagination] = React.useState({
  //   pageIndex: 0,
  //   pageSize: 25,
  // })
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper()

  // React.useEffect(() => {
  //   if (incomingFilters.length > 0) {
  //     setColumnFilters(incomingFilters)
  //   } else {
  //     setColumnFilters([])
  //   }
  // }, [incomingFilters])

  // React.useEffect(() => {
  //   if (incomingSort.length > 0) {
  //     setColumnFilters(incomingSort)
  //   } else {
  //     setColumnFilters([])
  //   }
  // }, [incomingSort])

  /* Table instance */
  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      rowSelection,
      sorting,
      pagination,
    },
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        skipAutoResetPageIndex()
        setData(rowIndex, columnId, value)
      },
    },
    onPaginationChange: setPagination,
    columnResizeMode: 'onChange',
    enableRowSelection: true,
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  /* Column resizing */
  const columnSizeVars = React.useMemo(() => {
    const headers = table.getFlatHeaders()
    const colSizes = {}
    for (let i = 0; i < headers.length; i++) {
      const header = headers[i]
      colSizes[`--header-${header.id}-size`] = header.getSize()
      colSizes[`--col-${header.column.id}-size`] = header.column.getSize()
    }
    return colSizes
  }, [table.getState().columnSizingInfo])

  return (
    <>
      <div className="table-wrapper">
        <table
          {...{
            className: 'pipeline-table',
            style: {
              ...columnSizeVars,
              width: table.getTotalSize(),
            },
          }}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      {...{
                        key: header.id,
                        className: 'th',
                        style: {
                          width: `calc(var(--header-${header.id}-size) * 1px)`,
                        },
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          data-tooltip-id={`table-header-tooltip-${header.id}`}
                        >
                          <div
                            {...{
                              style: {
                                display: 'flex',
                                justifyContent: 'center',
                                cursor:
                                  header.column.getCanSort() ||
                                  header.column.columnDef.tooltip
                                    ? 'pointer'
                                    : 'default',
                              },
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' ↑',
                              desc: ' ↓',
                            }[header.column.getIsSorted()] ?? null}
                          </div>
                          {/* <ReactTooltip
                            id={`table-header-tooltip-${header.id}`}
                            place="top"
                            content={header.column.columnDef.tooltip}
                            style={{
                              background: '#fff',
                              color: '#1f2d54',
                              fontSize: '13px',
                              fontWeight: 400,
                              lineHeight: '16px',
                              fontFamily: 'Inter, sans-serif',
                              boxShadow: '0px 0px 12px 5px #e6e9f1',
                            }}
                          /> */}
                        </div>
                      )}
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `resizer ${
                            header.column.getIsResizing() ? 'isResizing' : ''
                          }`,
                        }}
                      />
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.length
              ? table
                  .getRowModel()
                  .rows.map(row => {
                    return (
                      <tr key={row.id}>
                        {row.getVisibleCells().map(cell => {
                          return (
                            <td key={cell.id}>
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })
                  .concat(
                    [
                      ...Array(
                        table.getState().pagination.pageSize -
                          table.getRowModel().rows.length
                      ),
                    ].map(item => (
                      <tr key={item}>
                        <td
                          colSpan={11}
                          style={{
                            padding: '8px',
                            zIndex: '-1',
                          }}
                        >
                          <Placeholder size="lg" />
                        </td>
                      </tr>
                    ))
                  )
              : [...Array(table.getState().pagination.pageSize)].map(
                  (item, idx) => {
                    if (idx === 0) {
                      return (
                        <tr key={item}>
                          <td colSpan={11} style={{ padding: '8px' }}>
                            {loadingData ? 'Loading ...' : 'No Records'}
                          </td>
                        </tr>
                      )
                    } else {
                      return (
                        <tr key={item}>
                          <td colSpan={11} style={{ padding: '8px' }}>
                            <Placeholder size="lg" />
                          </td>
                        </tr>
                      )
                    }
                  }
                )}
          </tbody>
        </table>
        {loadingData ? (
          <div
            style={{
              position: 'fixed',
              top: '45%',
              left: '62%',
              zIndex: 2,
              transform: 'scale(1.5)',
            }}
          >
            <Spinner animation="border" />
          </div>
        ) : null}
      </div>
      <div className="table-footer">
        {/* <div className="page-selection">
          <React.Fragment>
            <IndeterminateCheckbox
              {...{
                checked: table.getIsAllPageRowsSelected(),
                indeterminate: table.getIsSomePageRowsSelected(),
                onChange: table.getToggleAllPageRowsSelectedHandler(),
              }}
            />
            <div>SELECT ALL PAGE ROWS</div>
          </React.Fragment>
        </div> */}
        <div style={{ display: 'flex', gap: '8px' }}>
          <button
            className="pagination-button"
            onClick={() => {
              if (setCurrentPage) {
                setCurrentPage(1)
              } else {
                table.setPageIndex(0)
              }
            }}
            disabled={
              currentPage ? currentPage === 1 : !table.getCanPreviousPage()
            }
          >
            <BsChevronDoubleLeft />
          </button>
          <button
            className="pagination-button"
            onClick={() => {
              if (setCurrentPage && currentPage) {
                currentPage > 1 ? setCurrentPage(currentPage - 1) : null
              } else {
                table.previousPage()
              }
            }}
            disabled={
              currentPage ? currentPage === 1 : !table.getCanPreviousPage()
            }
          >
            <BsChevronLeft />
          </button>
          <button
            className="pagination-button"
            onClick={() => {
              if (setCurrentPage && currentPage && pageCount) {
                currentPage < pageCount ? setCurrentPage(currentPage + 1) : null
              } else {
                table.nextPage()
              }
            }}
            disabled={
              currentPage && pageCount
                ? currentPage === pageCount
                : !table.getCanNextPage()
            }
          >
            <BsChevronRight />
          </button>
          <button
            className="pagination-button"
            onClick={() => {
              if (setCurrentPage && currentPage && pageCount) {
                currentPage < pageCount ? setCurrentPage(pageCount) : null
              } else {
                table.setPageIndex(table.getPageCount() - 1)
              }
            }}
            disabled={
              currentPage && pageCount
                ? currentPage === pageCount
                : !table.getCanNextPage()
            }
          >
            <BsChevronDoubleRight />
          </button>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <div>PAGE&nbsp;</div>
            {currentPage
              ? currentPage
              : table.getState().pagination.pageIndex + 1}{' '}
            of&nbsp;
            {pageCount ? pageCount : table.getPageCount()}
          </span>
          {showGoToPage ? (
            <span>
              | GO TO PAGE:&nbsp;
              <input
                type="number"
                style={{ width: '60px' }}
                // defaultValue={table.getState().pagination.pageIndex + 1}
                value={table.getState().pagination.pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  table.setPageIndex(page)
                }}
              />
            </span>
          ) : null}
          {showPageSize ? (
            <select
              disabled={loadingData}
              value={pageSize ? pageSize : table.getState().pagination.pageSize}
              onChange={e => {
                const sizeValue = Number(e.target.value)
                if (setPageSize) {
                  setPageSize(sizeValue)
                } else {
                  table.setPageSize(sizeValue)
                }
              }}
            >
              {[25, 50, 100].map(size => (
                <option key={size} value={size}>
                  Show {size}
                </option>
              ))}
            </select>
          ) : null}
        </div>
      </div>
    </>
  )
}

CRMTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loadingData: PropTypes.bool,
  setData: PropTypes.func,
  columnFilters: PropTypes.array,
  setColumnFilters: PropTypes.func,
  rowSelection: PropTypes.object,
  setRowSelection: PropTypes.func,
  sorting: PropTypes.array,
  setSorting: PropTypes.func,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  showGoToPage: PropTypes.bool,
  showPageSize: PropTypes.bool,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
}

export default CRMTable
