import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { BsChevronDown } from 'react-icons/bs'
import {
  getUserFullName,
  getPicture,
  getAuthProvider,
  getUserEmail,
  isFederatedUser,
  getFederatedPictureUrl,
} from '../../utils/state/selectors'
import { Dropdown } from 'react-bootstrap'
import { logoutAction } from '../../redux/actions/authentication'
import AzureUserPhoto from './AzureUserPhoto'
import { useMsal } from '@azure/msal-react'

const Wrapper = styled.a`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  font-size: 0.875rem;
  &:hover {
    cursor: pointer;
    text-decoration: none;
  }
`

const UserIconWrapper = styled.div`
  width: 24px;
  max-width: 24px;
  height: 24px;
  max-height: 24px;
  margin-right: 0.5rem;
  border: 1px solid #a6b3be;
  border-radius: 100%;
  box-sizing: content-box;
  overflow: hidden;
  > img,
  svg,
  jpg {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`

const ArrowIconWrapper = styled.div`
  padding-left: 0.6rem;
  > img,
  svg {
    margin: 0;
    padding: 0;
  }
`

const SignOutWrapper = styled.div`
  font-weight: 500;
  text-align: right;
`

const CustomToggle = React.forwardRef(function CustomToggle(
  { children, onClick, picture },
  ref
) {
  const provider = useSelector(getAuthProvider)
  const isFederated = useSelector(isFederatedUser)
  const federatedPictureUrl = useSelector(getFederatedPictureUrl)
  const avatarPicture = isFederated
    ? federatedPictureUrl
    : require(`../../assets/profilePictures/${picture}.png`)

  return (
    <Wrapper
      href="#"
      ref={ref}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
        onClick(e)
      }}
    >
      <UserIconWrapper>
        {provider !== 'azure' && (
          <img src={avatarPicture} referrerPolicy="no-referrer" />
        )}
        {provider === 'azure' && <AzureUserPhoto />}
      </UserIconWrapper>
      <div>{children}</div>
      <ArrowIconWrapper>
        <BsChevronDown size={13} />
      </ArrowIconWrapper>
    </Wrapper>
  )
})

CustomToggle.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  picture: PropTypes.string.isRequired,
}

const MenuItem = ({
  children,
  showUserIcon = true,
  picture,
  handleOnClick,
}) => {
  const provider = useSelector(getAuthProvider)
  const isMicrosoft = ['azure', 'graph', 'office365'].includes(provider)
  const isFederated = useSelector(isFederatedUser)
  const federatedPictureUrl = useSelector(getFederatedPictureUrl)
  const avatarPicture = isFederated
    ? federatedPictureUrl
    : picture
    ? require(`../../assets/profilePictures/${picture}.png`)
    : require(`../../assets/profilePictures/unassigned.png`)

  return (
    <Dropdown.Item onClick={handleOnClick} eventKey="1">
      <div className="w-100 d-flex flex-row flex-nowrap justify-content-between align-items-center">
        {showUserIcon && (
          <UserIconWrapper>
            {!isMicrosoft && (
              <img src={avatarPicture} referrerPolicy="no-referrer" />
            )}
            {isMicrosoft && <AzureUserPhoto />}
          </UserIconWrapper>
        )}
        <div className="w-100 text-right">{children}</div>
      </div>
    </Dropdown.Item>
  )
}

MenuItem.propTypes = {
  children: PropTypes.node,
  showUserIcon: PropTypes.bool,
  picture: PropTypes.string,
  handleOnClick: PropTypes.func,
}

const UserMenu = () => {
  const dispatch = useDispatch()
  //const isFederated = useSelector(isFederatedUser)
  const authProvider = useSelector(getAuthProvider)
  const isGoogle = authProvider === 'google'
  const isLinkedIn = authProvider === 'linkedin'
  const isAzure = authProvider === 'azure'
  const fullname = useSelector(getUserFullName)
  const email = useSelector(getUserEmail)
  const picture = useSelector(getPicture)
  const { instance: msalInstance } = useMsal()
  const googleSignOut = () => dispatch(logoutAction())
  const linkedInSignOut = () => {
    try {
      const linkedinLogoutWindow = window.open(
        'https://linkedin.com/m/logout',
        '_blank',
        'toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no, width=200, height=300, visible=none'
      )
      setTimeout(() => {
        linkedinLogoutWindow.close()
      }, 2000)
    } catch (err) {
      console.log('Error @ linkedin logout', err)
    }
  }
  const azureSignOut = async () => {
    const account = msalInstance.getAccountByUsername(email)
    return msalInstance.logoutRedirect({
      account,
    })
  }

  const logoutHandler = async e => {
    e.preventDefault()
    e.stopPropagation()
    if (isGoogle) {
      googleSignOut()
      return
    }
    if (isLinkedIn) {
      linkedInSignOut()
    }
    if (isAzure) {
      azureSignOut()
    }
    dispatch(logoutAction())
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          as={CustomToggle}
          picture={picture}
          id="dropdown-custom-components"
        >
          {fullname}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <MenuItem
            handleOnClick={logoutHandler}
            picture={picture.picture}
            showUserIcon={false}
          >
            <SignOutWrapper>
              <span>Sign out</span>
            </SignOutWrapper>
          </MenuItem>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default UserMenu
