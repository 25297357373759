import PropTypes from 'prop-types'
import { propEq } from 'ramda'
import { Stack } from 'react-bootstrap'
import Select from 'react-select'
import styled from 'styled-components'

const StyledDiv = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 0.75rem;
  display: flex;
  justify-content: center;
`

const TimeContainerDiv = styled.div`
  width: 90%;
`

const findOption = (value, options) => options.find(propEq('value', value))

const TimeSelect = ({ hour, minutes, ampm, selectChangeHandler }) => {
  const selectStyles = {
    input: provided => ({
      ...provided,
      border: 'none',
      '> input[type=text]:focus': { outline: 'none !important' },
    }),
  }

  const hourOptions = [
    { value: '', label: '' },
    ...[...Array(12).keys()].map((_, i) => ({
      value: `${i + 1}`,
      label: `${i + 1}`,
    })),
  ]
  /*
  const hourOptions = [
    { value: '', label: '' },
    { value: '00', label: '00' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
    { value: '07', label: '07' },
    { value: '08', label: '08' },
    { value: '09', label: '09' },
    { value: '10', label: '10' },
    { value: '11', label: '11' },
    { value: '12', label: '12' },
  ]*/

  const minutesOptions = [
    { value: '', label: '' },
    { value: '00', label: '00' },
    { value: '15', label: '15' },
    { value: '30', label: '30' },
    { value: '45', label: '45' },
  ]

  const ampmOptions = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
  ]

  return (
    <Stack gap={1} direction="horizontal">
      <TimeContainerDiv>
        <Select
          id="hour"
          value={findOption(hour, hourOptions)}
          options={hourOptions}
          placeholder="Hour"
          onChange={selectChangeHandler('hour')}
          styles={selectStyles}
        />
      </TimeContainerDiv>
      <StyledDiv>
        <span>:</span>
      </StyledDiv>
      <TimeContainerDiv>
        <Select
          id="minutes"
          value={findOption(minutes, minutesOptions)}
          options={minutesOptions}
          placeholder="Minutes"
          onChange={selectChangeHandler('minutes')}
          styles={selectStyles}
        />
      </TimeContainerDiv>
      <TimeContainerDiv>
        <Select
          id="ampm"
          value={findOption(ampm, ampmOptions)}
          options={ampmOptions}
          placeholder="Select AM or PM"
          onChange={selectChangeHandler('ampm')}
          styles={selectStyles}
        />
      </TimeContainerDiv>
    </Stack>
  )
}

TimeSelect.propTypes = {
  hour: PropTypes.string.isRequired,
  minutes: PropTypes.string.isRequired,
  ampm: PropTypes.string.isRequired,
  selectChangeHandler: PropTypes.func.isRequired,
}

export default TimeSelect
