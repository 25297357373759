import { AxiosError } from 'axios'
import { apiCallBegan } from './actions'
import { Dispatch, PayloadAction } from '@reduxjs/toolkit'
import { ApiMidPayload } from './types'

const api =
  ({ dispatch }: { dispatch: Dispatch }) =>
  (next: (params: PayloadAction<ApiMidPayload>) => void) =>
  async (action: PayloadAction<ApiMidPayload>) => {
    if (action.type !== apiCallBegan.type) {
      return next(action)
    }
    const { onStart, onSuccess, onError, callApi } = action.payload
    if (onStart) dispatch({ type: onStart })

    try {
      const response = await callApi()
      dispatch({ type: onSuccess, payload: response })
    } catch (error) {
      const axiosError = error as AxiosError
      dispatch({ type: onError, payload: axiosError.message })
    }
  }

export default api
