import { initStore } from '../redux/store'

/*
    Moved redux store to its own module.
    Doing so made it global

*/

const store = initStore

export default store
