import { Button, Dropdown, Form, FormCheck, Offcanvas } from 'react-bootstrap'
import EditorConvertToHTML from '../EditorConvertToHTML/EditorConvertToHTML'
import MultiEmailCustom from '../MultiEmailField/MultiEmailCustom/MultiEmailCustom'
import MultiEmailField from '../MultiEmailField/MultiEmailField'
import { BsFilter } from 'react-icons/bs'
import { FC, useEffect, useState } from 'react'
import ModalFilterContacts from '../ModalFilterContacts/ModalFilterContacts'
import './MailBoxCompose.css'
import { IContact } from '../../../api/interfaces'
import { EmailTarget, MailboxComposeProps } from './types'
import { useDispatch } from 'react-redux'
import { addToast } from '../../../redux/toasts/toasts'
import { EditorState } from 'draft-js'
import { addMention } from '../EditorConvertToHTML/helpers'
import { initialState, mandatoryFields, mentionConfig } from './config'
import { handleScheduleCall } from './helpers'
import { getUserToken } from '../../../utils/dataModels/user'
import { getUserOrganizationId } from '../../../utils/state/selectors'
import { useSelector } from 'react-redux'
import { MdOutlineSchedule } from 'react-icons/md'
import { FilterQuery } from '../ModalFilterContacts/types'
import { isEmpty } from 'ramda'
const MailboxCompose: FC<MailboxComposeProps> = ({
  defaultEmailTarget,
  showCompose,
  isSending,
  handleClose,
  handleSendClick,
  inputChangeHandler,
  setComposeEmailPropValue,
}) => {
  const dispatch = useDispatch()
  const providerAccount = getUserToken()
  const organizationId = useSelector(getUserOrganizationId)

  const [filterQuery, setFilterQuery] = useState<FilterQuery>()
  const [showFilter, setShowFilter] = useState(false)
  const [emailsTarget, setEmailsTarget] = useState<EmailTarget>(initialState)

  const [emailContacts, setEmailContacts] = useState<IContact[]>([])

  const [mailMerge, setMailMerge] = useState<boolean>(false)

  const useEditorState = useState(EditorState.createEmpty())

  const [editorState, setEditorState] = useEditorState
  const handleCloseFilter = () => setShowFilter(false)
  const handleShowFilter = () => setShowFilter(true)
  const handleApplyFilter = (
    contacts: IContact[],
    filterQuery?: FilterQuery
  ) => {
    setFilterQuery(filterQuery)
    const uniqueEmailContacts = [...emailContacts]
    contacts.forEach(contact => {
      if (
        !uniqueEmailContacts.find(
          emailContact => emailContact.email === contact.email
        )
      ) {
        uniqueEmailContacts.push(contact)
      }
    })

    setEmailsTarget({
      ...emailsTarget,
      to: [
        ...emailsTarget.to,
        ...uniqueEmailContacts.map(contact => contact.email),
      ],
    })
    setEmailContacts(uniqueEmailContacts)
    setShowFilter(false)
  }

  useEffect(() => {
    if (defaultEmailTarget && defaultEmailTarget.length) {
      setEmailsTarget({
        ...emailsTarget,
        to: defaultEmailTarget,
      })
    }
  }, [defaultEmailTarget])

  useEffect(() => {
    setComposeEmailPropValue('to', emailsTarget.to)
    setComposeEmailPropValue('cc', emailsTarget.cc)
    setComposeEmailPropValue('bcc', emailsTarget.bcc)
  }, [emailsTarget])

  useEffect(() => {
    if (!showCompose) {
      setEmailsTarget(initialState)
      setMailMerge(false)
      setEditorState(EditorState.createEmpty())
    }
  }, [showCompose])

  const handlerClickField = (fieldName: string, fieldKey: string) => {
    setEditorState(addMention(fieldName, fieldKey, editorState))
  }

  const applyScrollStyle = emailsTarget.to.length > 4

  useEffect(() => {
    if (mailMerge && !emailContacts.length && !emailTargetsSet()) {
      dispatch(
        addToast({
          position: 'middle-center',
          delay: 3000,
          message:
            'Please add email addresses first, by clicking the fitler icon next to the To field',
          title: 'Information',
        })
      )
      setMailMerge(false)
    }
  }, [mailMerge])

  // Handling when emailsTarget.to change then update emailContacts array
  useEffect(() => {
    if (emailsTarget.to.length !== emailContacts.length) {
      const emailContactsFiltered = emailContacts.filter(emailContact =>
        emailsTarget.to.some(emailT => emailT === emailContact.email)
      )
      setEmailContacts(emailContactsFiltered)
    }
  }, [emailsTarget.to])

  const emailTargetsSet = () =>
    !(isEmpty(emailsTarget.to) && isEmpty(emailsTarget.bcc))

  return (
    <Offcanvas
      show={showCompose}
      onHide={() => {
        setFilterQuery(undefined)
        handleClose()
      }}
      placement="end"
      className="offcanvas-custom"
    >
      <ModalFilterContacts
        initialQueryFilter={filterQuery}
        show={showFilter}
        handleApply={handleApplyFilter}
        handleShow={handleShowFilter}
        handleClose={handleCloseFilter}
      />
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>New Email</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form.Group className="mt-2 mb-3 d-flex gap-2 align-items-baseline">
          <Form.Label htmlFor="to">To</Form.Label>
          <MultiEmailField
            emails={emailsTarget.to}
            style={{
              overflowY: 'scroll',
              maxHeight: applyScrollStyle ? '200px' : '',
              height: applyScrollStyle ? '70px' : 'auto',
              flex: '1 1',
            }}
            onChange={emails =>
              setEmailsTarget({ ...emailsTarget, to: emails })
            }
          />
          <BsFilter
            size={'1.8rem'}
            className="align-self-center"
            onClick={() => setShowFilter(true)}
          />
        </Form.Group>
        <Form.Group className="mb-3 d-flex gap-2 align-items-baseline">
          <Form.Label htmlFor="cc">Cc</Form.Label>
          <MultiEmailField
            emails={emailsTarget.cc}
            onChange={emails =>
              setEmailsTarget({
                ...emailsTarget,
                cc: emails,
              })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3 d-flex gap-2 align-items-baseline">
          <Form.Label htmlFor="bcc">Bcc</Form.Label>
          <MultiEmailField
            emails={emailsTarget.bcc}
            onChange={emails =>
              setEmailsTarget({
                ...emailsTarget,
                bcc: emails,
              })
            }
          />
        </Form.Group>
        <Form.Group className="mb-3 d-flex gap-2 align-items-baseline">
          <Form.Label htmlFor="subject">Subject</Form.Label>
          <MultiEmailCustom id="subject" onChange={inputChangeHandler} />
        </Form.Group>
        <Form.Group className="mb-3">
          <EditorConvertToHTML
            useEditorState={useEditorState}
            toolbarCustomButtons={[
              <div className="p-2" key="merge-option">
                <FormCheck
                  label="Mail Merge"
                  checked={mailMerge}
                  onChange={evt => {
                    evt.stopPropagation()
                    setMailMerge(!mailMerge)
                  }}
                ></FormCheck>
              </div>,
              <div className="rdw-inline-wrapper" key="dropdown-add-field">
                <Dropdown>
                  <Dropdown.Toggle
                    size="sm"
                    variant="ligth"
                    id="dropdown-add-field"
                    disabled={!mailMerge}
                  >
                    Add Field
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {mandatoryFields.map(mandatoryField => (
                      <Dropdown.Item
                        key={mandatoryField.value}
                        onClick={() =>
                          handlerClickField(
                            mandatoryField.label,
                            `[[${mandatoryField.value}]]`
                          )
                        }
                      >
                        {mandatoryField.label}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  onClick={() =>
                    handleScheduleCall(
                      () => null,
                      providerAccount?.token,
                      organizationId
                    )
                  }
                  className="ms-2 d-flex align-items-center gap-1"
                  variant="secondary"
                  size="sm"
                >
                  Schedule <MdOutlineSchedule />
                </Button>
              </div>,
            ]}
            mention={mentionConfig(mailMerge)}
            onChange={htmlValue => {
              setComposeEmailPropValue('body', htmlValue)
            }}
          />
        </Form.Group>
        <Form.Group className="mt-4">
          <button
            className={`btn btn-secondary ${isSending && 'disabled'}`}
            onClick={() => handleSendClick(mailMerge, emailContacts)}
            disabled={isSending}
          >
            {' '}
            {isSending ? 'Sending...' : 'Send'}{' '}
          </button>
        </Form.Group>
        <br />
      </Offcanvas.Body>
    </Offcanvas>
  )
}

export default MailboxCompose
