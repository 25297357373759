import types from '../types/types'
import { startLoading, finishLoading } from './ui'
import api from '../../api/authentication'
import { path, prop } from 'ramda'
import constants from '../../config/constants'
import getUserProfile from '../../api/google/getUserProfile'
import graphMe from '../../api/microsoft/graphMe'

export const startLoginProcess = () => dispatch =>
  dispatch({ type: types.START_LOGIN_PROCESS })

export const finishLoginProcess = () => dispatch =>
  dispatch({ type: types.FINISH_LOGIN_PROCESS })

export const loginAction = (username, password) => dispatch => {
  dispatch(startLoginProcess())
  api
    .login(username, password)
    .then(res => {
      dispatch({
        type: types.LOGIN,
        payload: res,
      })
      dispatch(finishLoading())
      sessionStorage.setItem('Org-Session', res.organizationId)
      sessionStorage.setItem('Session-Token', res.sessionToken)
      sessionStorage.setItem('expiration', res.expiration)
    })
    .catch(error => {
      dispatch({
        type: types.LOGIN_ERROR,
        payload: error.message,
      })
    })
    .finally(() => dispatch(finishLoginProcess()))
}

export const sessionAction = sessionToken => dispatch => {
  dispatch(startLoginProcess())
  api
    .session(sessionToken)
    .then(res => {
      const { isFederated, provider, providerAccounts } = res

      if (isFederated && ['gmail', 'google'].includes(provider)) {
        // restore google login
        const account =
          providerAccounts.find(
            a => a.email === res.email && a.provider === 'gmail'
          ) || {}
        const {
          providerTokens: { refreshToken, idToken },
        } = account
        getUserProfile(idToken, refreshToken).then(profile =>
          dispatch(googleLoginAction(profile))
        )
      } else if (
        isFederated &&
        ['graph', 'azure', 'office365'].includes(provider)
      ) {
        // restore graph login
        const account = providerAccounts.find(a => a.email === res.email) || {}
        const { providerTokens } = account
        graphMe(providerTokens).then(profile =>
          dispatch(azureLoginAction(profile))
        )
      } else {
        // restore custom login
        dispatch({
          type: types.LOGIN,
          payload: res,
        })
      }
      // set session
      sessionStorage.setItem('Org-Session', res.organizationId)
      sessionStorage.setItem('Session-Token', res.sessionToken)
      sessionStorage.setItem('expiration', res.expiration)
    })
    .catch(error => {
      dispatch({
        type: types.LOGIN_ERROR,
        payload: error.message,
      })
    })
    .finally(() => dispatch(finishLoginProcess()))
}

export const googleLoginAction = googleUserProfile => async dispatch => {
  const username = googleUserProfile.email
  const { idToken, refreshToken } = googleUserProfile

  dispatch(startLoginProcess())
  let federatedUser
  try {
    federatedUser = await api.signInFederatedUser(username, {
      ...googleUserProfile,
      tokens: { idToken, refreshToken },
    })
  } catch (error) {
    dispatch(finishLoginProcess())
    throw error
  }
  const role = federatedUser['role']
  const metrics_group = federatedUser['metrics_group']

  if (federatedUser === null) {
    dispatch({
      type: types.LOGIN_ERROR,
      payload: 'User not registered',
    })
    return
  }

  const payload = {
    googleUserProfile,
    federatedUser,
    role,
    metrics_group,
  }
  dispatch({
    type: types.GOOGLE_LOGIN,
    payload,
  })
  dispatch(finishLoginProcess())
  sessionStorage.setItem('Org-Session', federatedUser.organizationId)
  sessionStorage.setItem('Session-Token', federatedUser.sessionToken)
  sessionStorage.setItem('expiration', federatedUser.expiration)
}

export const linkedInLoginAction = token => async dispatch => {
  const url = constants.LINKEDIN_USER_INFO_ENDPOINT()
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'auth-domain': 'benjamin',
    },
    body: JSON.stringify({
      code: token,
    }),
  }
  dispatch(startLoginProcess())
  fetch(url, options)
    .then(response => {
      return response.json()
    })
    .then(async response => {
      const username = path(
        ['emailData', 'elements', 0, 'handle~', 'emailAddress'],
        response
      )
      const userLocaleCountry = path(
        ['userData', 'lastName', 'preferredLocale', 'country'],
        response
      )
      const userLocaleLanguage = path(
        ['userData', 'lastName', 'preferredLocale', 'language'],
        response
      )
      const userLocale = `${userLocaleLanguage}_${userLocaleCountry}`
      const lastName = path(
        ['userData', 'lastName', 'localized', userLocale],
        response
      )
      const firstName = path(
        ['userData', 'name', 'localized', userLocale],
        response
      )
      const profilePicture = path(
        [
          'userData',
          'profilePicture',
          'displayImage~',
          'elements',
          0,
          'identifiers',
          0,
          'identifier',
        ],
        response
      )
      const federatedUser = await api.signInFederatedUser(username)

      if (federatedUser === null) {
        dispatch({
          type: types.LOGIN_ERROR,
          payload: 'User not registered',
        })
        return
      }

      const role = federatedUser['role']
      const linkedInUserProfile = {
        lastName,
        firstName,
        username,
        email: username,
        id: username,
        profilePicture,
        chatId: federatedUser.chatId,
      }
      const payload = {
        linkedInUserProfile,
        role,
      }
      dispatch({
        type: types.LINKEDIN_LOGIN,
        payload,
      })
      dispatch(finishLoginProcess())
    })
}

export const azureLoginAction = azurePayload => async dispatch => {
  const username = path(['userInfo', 'userPrincipalName'], azurePayload)
  const lastName = path(['userInfo', 'surname'], azurePayload)
  const firstName = path(['userInfo', 'givenName'], azurePayload)
  const refreshToken = path(['tokens', 'refresh_token'], azurePayload)
  const tokens = path(['tokens'], azurePayload)

  dispatch(startLoginProcess())
  const federatedUser = await api.signInFederatedUser(username, {
    provider: 'graph',
    name: `${firstName} ${lastName}`.trim(),
    username,
    email: username,
    refreshToken,
    tokens,
  })

  if (!federatedUser) {
    dispatch({
      type: types.LOGIN_ERROR,
      payload: 'User not registered',
    })
    return
  }

  const role = prop('role', federatedUser)
  const azureUserProfile = {
    lastName,
    firstName,
    username,
    email: username,
    id: username,
    profilePicture: prop('picture', federatedUser),
    chatId: prop('chatId', federatedUser),
  }
  const payload = {
    azureUserProfile,
    federatedUser,
    role,
  }
  dispatch({
    type: types.AZURE_LOGIN,
    payload,
  })
  sessionStorage.setItem('Org-Session', federatedUser.organizationId)

  dispatch(finishLoading())
  sessionStorage.setItem('Session-Token', federatedUser.sessionToken)
  sessionStorage.setItem('expiration', federatedUser.expiration)
}

export const logoutAction = () => dispatch => {
  dispatch(startLoading())
  dispatch({ type: types.LOGOUT })
  dispatch(finishLoginProcess())
  sessionStorage.clear()
}
