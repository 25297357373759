export function FundraisingProgressBar({
  value,
  margin,
}: {
  value: number
  margin?: number
}) {
  const colorMap = (percentage: number) =>
    percentage <= 40 ? 'red' : percentage <= 70 ? 'yellow' : 'green'
  return (
    <svg
      style={{
        width: '50px',
        height: '15px',
        marginRight: margin && `${margin}px`,
        marginLeft: margin && `${margin}px`,
      }}
    >
      <rect
        id="percent"
        width="50"
        height="10"
        style={{ fill: colorMap(value) }}
      ></rect>
    </svg>
  )
}
