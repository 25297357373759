import BoldIcon from '../BoldIcon/BoldIcon'
import PropTypes from 'prop-types'

const ToolBar = ({ handlerInc, handlerDec, handlerBold, bold }) => {
  return (
    <div className="d-flex align-items-center gap-1 mx-3">
      <div className="d-flex">
        <button className="btn btn-light btn-sm" onClick={handlerInc}>
          ➕
        </button>
        <button className="btn btn-light btn-sm" onClick={handlerDec}>
          ➖
        </button>
      </div>
      <BoldIcon
        fillRectangle={bold ? 'black' : 'white'}
        handlerClick={handlerBold}
      />
    </div>
  )
}

ToolBar.propTypes = {
  color: PropTypes.string.isRequired,
  bold: PropTypes.bool.isRequired,
  changeColor: PropTypes.func,
  handlerBold: PropTypes.func.isRequired,
  handlerInc: PropTypes.func.isRequired,
  handlerDec: PropTypes.func.isRequired,
}

export default ToolBar
