import constants from '../../config/constants'
import axiosInstance from '../api_instance'

const API_URL = 'scheduler'

export const getSchedulerURL = async token => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance
    .get(`${API_URL}/getURL`, {
      headers: { token },
      baseURL,
    })
    .then(payload => payload.data)
}

export const getScheduledEvents = async token => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance
    .get(`${API_URL}/getEvents`, {
      headers: { token },
      baseURL,
    })
    .then(payload => payload.data)
}
