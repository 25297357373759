import styled from 'styled-components'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import OrganizationForm from './OrganizationForm'
import ApiOrganization from '../../api/organization'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const Wrapper = styled.div`
  padding: 1rem 3rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

const CreateOrganizationPage = () => {
  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.ADMIN_ORGANIZATIONS(false),
    mainBreadcrumbs.CREATE_ORGANIZATIONS(true),
  ]
  usePageHeadings('Organizations configuration', breadcrumbs)
  const navigate = useNavigate()
  const [loading, setIsLoading] = useState(false)

  const handleSubmit = data => {
    console.log({ data })
    setIsLoading(true)
    ApiOrganization.createOrganization(data).then(response => {
      console.log('org resp', response)
      setIsLoading(false)
      navigate('/admin/organizations')
    })
  }
  return (
    <Wrapper>
      <OrganizationForm
        onSubmit={handleSubmit}
        onCancel={() => navigate('/admin/organizations')}
        isLoading={loading}
      />
    </Wrapper>
  )
}

export default CreateOrganizationPage
