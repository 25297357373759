import React, { useEffect, useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'
import MultiEmailField from '../../MailBoxPage/MultiEmailField/MultiEmailField'

NewTaskPanel.propTypes = {
  task: PropTypes.object.isRequired,
  save: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default function NewTaskPanel({ task, save, close }) {
  const [taskValue, setTaskValue] = useState({
    title: task.title,
    fromDate: task.fromDate.format('YYYY-MM-DD'),
    fromTime: task.fromDate.format('HH:mm:ss'),
    // toDate: task.toDate.format('YYYY-MM-DD'),
    toTime: task.toDate.format('HH:mm:ss'),
    duration: task.duration,
  })

  const [duration, setDuration] = useState('')

  useEffect(() => {
    if (taskValue.toDate && taskValue.fromDate) {
      setDuration(
        moment
          .duration(
            moment(`${taskValue.toDate} ${taskValue.toTime}`).diff(
              moment(`${taskValue.fromDate} ${taskValue.fromTime}`)
            )
          )
          .asHours()
      )
    }
  }, [taskValue.fromDate, taskValue.toDate])

  console.log(duration)

  const saveDisabled = !taskValue.title || !taskValue.date || !taskValue.time //|| duration < 0
  const saveTask = () => {
    save({
      ...task,
      title: taskValue.title,
      date: moment(`${taskValue.date} ${taskValue.time}:00`),
      // duration: duration,
    })
  }
  const setTaskPropValue = (fieldName, value) => {
    setTaskValue({
      ...taskValue,
      [fieldName]: value,
    })
  }

  return (
    <div className="container mt-1">
      <Form.Group className="mb-3">
        <Form.Label htmlFor="name">Title</Form.Label>
        <Form.Control
          autoComplete="off"
          type="text"
          id="title"
          value={taskValue.title}
          placeholder="Event title"
          onChange={e => setTaskPropValue('title', e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label htmlFor="name">Date</Form.Label>
        <Form.Control
          autoComplete="off"
          type="date"
          id="fromDate"
          value={taskValue.fromDate}
          placeholder=""
          onChange={e => setTaskPropValue('fromDate', e.target.value)}
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label htmlFor="name">Duration</Form.Label>
        {/* <Form.Control
          autoComplete="off"
          type="date"
          id="toDate"
          value={taskValue.toDate}
          placeholder=""
          onChange={e => setTaskPropValue('toDate', e.target.value)}
        /> */}
        <br />
        <p className="mt-2">
          <small>From</small>
        </p>
        <Form.Control
          autoComplete="off"
          type="time"
          id="fromTime"
          value={taskValue.fromTime}
          placeholder=""
          onChange={e => setTaskPropValue('fromTime', e.target.value)}
        />
        <p className="mt-2">
          <small>To</small>
        </p>

        <Form.Control
          autoComplete="off"
          type="time"
          id="toTime"
          value={taskValue.toTime}
          placeholder=""
          onChange={e => setTaskPropValue('toTime', e.target.value)}
        />
      </Form.Group>
      {/* <Form.Label htmlFor="name">Duration (minutes) </Form.Label> */}

      {/* <Form.Group className="mb-3">
        <Form.Label htmlFor="name">Duration (minutes)</Form.Label>
        <Form.Control
          autoComplete="off"
          type="number"
          id="duration"
          value={taskValue.duration}
          placeholder=""
          onChange={e => setTaskPropValue('duration', parseInt(e.target.value))}
        />
      </Form.Group> */}

      <Form.Group className="mb-3">
        <Form.Label htmlFor="name">Guests</Form.Label>
        {/* <Form.Control
          autoComplete="off"
          type="number"
          id="duration"
          value={duration}
          placeholder=""
          onChange={onChangeDuration}
        /> */}
        <MultiEmailField
          onChange={emails => setTaskPropValue('guests', emails)}
        />
      </Form.Group>

      {/* <div className="mb-3">
        <div className="small">Título:</div>
        <input
          key={`${task.id}-title`}
          autoComplete="off"
          type="text"
          size="10"
          value={title}
          autoFocus={true}
          onChange={onChangeTitle}
        />
      </div> */}

      {/* <div className="mb-2">
        <div className="small">Fecha:</div>
        <input
          key={`${task.id}-date`}
          type="date"
          value={date}
          onChange={onChangeDate}
        />
      </div> */}

      {/* <div className="mb-2">
        <div className="small">Hora:</div>
        <input
          key={`${task.id}-time`}
          type="time"
          value={time}
          onChange={onChangeTime}
        />
      </div> */}

      {/* <div className="mb-2">
        <div className="small">Duración (minutos):</div>
        <input
          autoComplete="off"
          key={`${task.id}-duration`}
          type="number"
          value={duration}
          size="4"
          min="0"
          onChange={onChangeDuration}
        />
      </div> */}

      {/* <div className="mb-2">
        <div className="small">Notas:</div>
        <textarea
          key={`${task.id}-notes`}
          value={notes}
          className="border border-light"
          cols="10"
          rows="3"
          onChange={onChangeNotes}
        />
      </div> */}

      {!task.id && (
        <div className="d-flex gap-2 justify-content-center">
          <button
            type="button"
            className="mt-3 mr-2 btn btn-secondary"
            onClick={close}
          >
            Cancelar
          </button>
          <button
            type="button"
            className="mt-3 btn btn-primary"
            disabled={saveDisabled}
            onClick={saveTask}
          >
            Guardar
          </button>
        </div>
      )}
    </div>
  )
}
