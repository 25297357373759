import PropTypes from 'prop-types'
import styled from 'styled-components'
import Money from '../ui-elements/Money'

const AmountPercentWrapper = styled.div`
  /* max-width: 15rem;
  max-height: 9.125rem; */
  padding: 0.5rem 2rem;
  /* padding-right: 2rem;
  padding-left: 2rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & .amount {
    font-size: 1.4rem;
    font-weight: bold;
  }
  & .label {
    font-size: 1.2rem;
  }

  ${({ flag }) =>
    flag &&
    `
    border-style: solid;
    border-width: 0px 1px 0px 0px;
    border-color: #dce1e6;
  `}
`

const AmountPercentDisplay = ({ label, amount, flag, width }) => (
  <div style={{ width: '100%' }}>
    <AmountPercentWrapper flag={flag}>
      <div className="amount text-center" style={{ minWidth: width }}>
        <Money value={amount} />
      </div>
      <div className="label text-center" style={{ minWidth: width }}>
        {label}
      </div>
    </AmountPercentWrapper>
  </div>
)
AmountPercentDisplay.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  amount: PropTypes.number.isRequired,
  flag: PropTypes.bool.isRequired,
  width: PropTypes.number,
}

export default AmountPercentDisplay
