import { useEffect, useState } from 'react'
import { Col, Pagination, Row, Stack, Table } from 'react-bootstrap'
import { BTableHeaders, BTableProps } from './types'
import SortIconAction from '../ui-elements/SortIconAction'
import { BTableStyledTh } from './styles'
import { sortBySelect } from './utils'
import { DotNestedKeys, KeyValue } from '../../api/interfaces'
import CenterSpinner from '../CenterSpinner/CenterSpinner'

const BTable = <T,>({
  headers,
  data,
  ToolbarComponent,
  renderHeader,
  renderRow,
  isLoading,
  pageLength = 10,
  defaultSort,
}: BTableProps<T>): JSX.Element => {
  const initSortRef: KeyValue = headers
    .map(header => header.key)
    .reduce((prev: KeyValue, curr) => {
      prev[getHeaderKey(curr)] = 0
      return prev
    }, {})

  const [filterBackup, setFilterBackup] = useState<T[]>([])
  const [filterData, setFilterData] = useState<T[]>([])

  // Manage Sorting
  const [sortRef, setSortRef] = useState<KeyValue>(initSortRef)

  //Manage Pagination
  const [offset, setOffset] = useState(0)
  const [pageItems, setPageItems] = useState<JSX.Element[]>([])
  const [sliceCount, setSliceCount] = useState(0)

  const updateSetSort =
    (key: string | string[] = '') =>
    (sortValue: number) => {
      if (Array.isArray(key)) {
        setSortRef({ ...initSortRef, [key[0]]: sortValue })
      } else {
        setSortRef({ ...initSortRef, [key]: sortValue })
      }
    }

  const restoreFilterBackup = () => setFilterData([...filterBackup])

  function selectSort<T>(a: T, b: T) {
    const keyCurrentSorted =
      Object.keys(sortRef).find(key => !!sortRef[key]) || ''
    return sortBySelect<T>(
      a,
      b,
      keyCurrentSorted as keyof T,
      Number(sortRef[keyCurrentSorted] || 0)
    )
  }

  // Set Filter Data/Backup
  useEffect(() => {
    setFilterData([...data])
    setFilterBackup([...data])
  }, [data])

  // Generate Pagination Items
  useEffect(() => {
    const contactsLen = Math.ceil(filterData.length / pageLength)
    const upLimit = contactsLen - (contactsLen % pageLength)
    if (offset >= sliceCount + pageLength) {
      setSliceCount(sliceCount + pageLength)
    } else if (offset < sliceCount) {
      setSliceCount(sliceCount - pageLength)
    }
    if (offset === contactsLen - 1) {
      setSliceCount(upLimit)
    }
    if (offset === 0) {
      setSliceCount(0)
    }
  }, [offset])

  useEffect(() => {
    const items: JSX.Element[] = []
    for (
      let number = 0;
      number < Math.ceil(filterData.length / pageLength);
      number++
    ) {
      items.push(
        <Pagination.Item
          onClick={() => setOffset(number)}
          key={number}
          active={number === offset}
        >
          {number + 1}
        </Pagination.Item>
      )
    }
    setPageItems(
      items.length > pageLength
        ? items.slice(sliceCount, sliceCount + pageLength)
        : items
    )
  }, [filterData, offset, sliceCount])

  useEffect(() => {
    if (defaultSort) {
      const defaultSortRef = headers
        .map(header => header.key)
        .reduce((prev: KeyValue, curr) => {
          if (defaultSort && getHeaderKey(curr) === defaultSort.field) {
            if (defaultSort.order === 'asc') {
              prev[getHeaderKey(curr)] = 1
            } else if (defaultSort.order === 'desc') {
              prev[getHeaderKey(curr)] = 2
            }
          } else {
            prev[getHeaderKey(curr)] = 0
          }
          return prev
        }, {})
      setSortRef(defaultSortRef)
    }
  }, [defaultSort])

  const handlePrevOnClick = () => {
    offset - 1 < 0
      ? setOffset(Math.ceil(filterData.length / pageLength) - 1)
      : setOffset(offset - 1)
  }

  const handleNextOnClick = () => {
    offset + 1 > Math.ceil(filterData.length / pageLength) - 1
      ? setOffset(0)
      : setOffset(offset + 1)
  }

  const handlePrevElipsisOnClick = () => {
    offset - pageLength < 0
      ? setOffset(Math.ceil(filterData.length / pageLength) - 1)
      : setOffset(offset - pageLength)
  }

  const handleNextElipsisOnClick = () => {
    offset + pageLength > Math.ceil(filterData.length / pageLength) - 1
      ? setOffset(Math.ceil(filterData.length / pageLength) - 1)
      : setOffset(offset + pageLength)
  }

  function getHeaderKey(
    headerKey: DotNestedKeys<KeyValue> | DotNestedKeys<KeyValue>[]
  ) {
    if (Array.isArray(headerKey)) {
      return headerKey[0]
    }
    return headerKey
  }

  function sortedData(data: T[]) {
    return data
      ?.sort((a, b) => selectSort<T>(a, b))
      ?.slice(offset * pageLength, offset * pageLength + pageLength)
      .map((row, index) => renderRow(row, index))
  }

  const renderDefaultHeader = (
    header: BTableHeaders<T>,
    index: number,
    content?: JSX.Element | JSX.Element[]
  ) => (
    <BTableStyledTh key={`${index}-${header.title}`}>
      {content ? (
        content
      ) : (
        <>
          {header.title}
          {!header?.disabledSort && (
            <SortIconAction
              setFunctState={updateSetSort(getHeaderKey(header.key))}
              stateValue={Number(sortRef[getHeaderKey(header.key)])}
              refresh={restoreFilterBackup}
            />
          )}
        </>
      )}
    </BTableStyledTh>
  )
  return (
    <>
      {/* ToolBar */}
      {ToolbarComponent && ToolbarComponent}
      {/* Table  */}
      <Row>
        <Col className="table-responsive">
          {isLoading ? (
            <CenterSpinner />
          ) : (
            <Table>
              <thead>
                <tr>
                  {headers.map((header, index) =>
                    renderHeader
                      ? renderHeader(
                          header,
                          index,
                          (content?: JSX.Element | JSX.Element[]) =>
                            renderDefaultHeader(header, index, content)
                        )
                      : renderDefaultHeader(header, index)
                  )}
                </tr>
              </thead>
              <tbody>
                {sortedData(filterData)}
                {!isLoading && filterData.length === 0 && (
                  <tr>
                    <td
                      colSpan={headers.length}
                      className="text-center pt-4 text-muted"
                    >
                      No records found.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
      {/* Pagination */}
      <Row className="justify-content-md-center pt-4">
        <Col sm={4}>
          <Stack direction="horizontal" className="justify-content-md-center">
            <Pagination>
              {filterData.length > pageLength && !isLoading && (
                <>
                  <Pagination.First onClick={() => setOffset(0)} />
                  <Pagination.Prev onClick={() => handlePrevOnClick()} />
                  {sliceCount >= pageLength && (
                    <Pagination.Ellipsis
                      onClick={() => handlePrevElipsisOnClick()}
                    />
                  )}
                  {pageItems}
                  {sliceCount + pageLength <
                    Math.ceil(filterData.length / pageLength) - 1 && (
                    <Pagination.Ellipsis
                      onClick={() => handleNextElipsisOnClick()}
                    />
                  )}
                  <Pagination.Next onClick={() => handleNextOnClick()} />
                  <Pagination.Last
                    onClick={() =>
                      setOffset(Math.ceil(filterData.length / pageLength) - 1)
                    }
                  />
                </>
              )}
            </Pagination>
          </Stack>
        </Col>
      </Row>
    </>
  )
}

export default BTable
