import { useEffect, useState, useMemo } from 'react'
import {
  Container,
  ListGroup,
  Toast,
  ToastContainer,
  Col,
} from 'react-bootstrap'
import {
  getCountView,
  getThreads,
  sendNylasEmail,
  getOpenedEmails,
} from '../../api/nylas/mailbox'
import { DATEFORMAT, timestampToDateString } from '../../utils/timestamp'
import { useNavigate } from 'react-router-dom'
import './MailBoxPage.css'
import {
  setFolder,
  setPageData,
  setPagination,
  setOpenedThreads,
} from '../../redux/actions/mailbox'
import { useDispatch } from 'react-redux'
import {
  getFolder,
  getUser,
  getPage,
  getPageCount,
  getPagination,
  getUserFullName,
  getUserOrganizationId,
  getOpenedThreads,
} from '../../utils/state/selectors'
import { useSelector } from 'react-redux'
import { path, pathOr } from 'ramda'
import ComposeIcon from './Icons/ComposeIcon'
import VariantsExample from './MailButton/MailButton'
import InboxIcon from './Icons/InboxIcon'
import SentIcon from './Icons/SentIcon'
import NextIcon from './Icons/NextIcon'
import PreviousIcon from './Icons/PreviousIcon'
import SyncIcon from './Icons/SyncIcon'
import { Wrapper } from '../ContactsPage/ContactsPage'
import { getUserToken } from '../../utils/dataModels/user'
import { filterBContacts } from '../../api/nylas/bcontact'
import DonationsApi from '../../api/donation'
import { IoIosContacts } from 'react-icons/io'
import MailboxCompose from './MailboxCompose/MailboxCompose'
import moment from 'moment'
import BContactTableContainer from './BContactTableContainer'
import ContactRow from '../ContactsPage/ContactRow'
import ApiOrg from '../../api/organization'
import { BsFillCheckCircleFill, BsFilter } from 'react-icons/bs'
import ModalFilterContactsJS, {
  initialQueryState,
} from './ModalFilterContacts/ModalFilterContactsJS'
import { mandatoryFields } from './MailboxCompose/config'
import { navigateThreadClick } from './helpers'
import ContactsTable from '../ContactsPage/ContactsTable'
import contactColumns from '../ContactsPage/ContactColumns'

export const FOLDERS = { INBOX: 'inbox', SENT: 'sent' }

const CONTACT_TAB = 'Contacts'

const PAG_BTN_COLOR = {
  true: '#A5B1BD',
  false: '#576068',
}
const INITIAL_PAGE_SIZE = 50

const initialStateComposeEmail = {
  to: [],
  cc: [],
  bcc: [],
  subject: '',
  body: '',
}
const MailBoxPage = () => {
  const providerAccount = getUserToken()
  const userFullName = getUserFullName()
  const orgId = useSelector(getUserOrganizationId)
  const [org, setOrg] = useState()

  const [bContacts, setBContacts] = useState([])
  const [loading, setLoading] = useState(true)
  const [showCompose, setShowCompose] = useState(false)

  const [showFilterModal, setShowFilterModal] = useState(false)
  const [queryFilter, setQueryFilter] = useState(initialQueryState)
  const [filterContacts, setFilterContacts] = useState([])
  const [contactsPagination, setContactsPagination] = useState({
    pageIndex: 0,
    pageSize: INITIAL_PAGE_SIZE,
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector(getUser)

  const folder = useSelector(getFolder)

  const PAG_KEY = `${providerAccount?.email}${folder}`

  const pagination = useSelector(getPagination(PAG_KEY))

  const emailPages = useSelector(getPage(PAG_KEY))
  const emailPagesLength = emailPages.length
  const countPages = useSelector(getPageCount(PAG_KEY))
  const openedThreads = useSelector(getOpenedThreads(PAG_KEY))

  const [isSending, setIsSending] = useState(false)
  const [sendStatus, setSendStatus] = useState('')

  const [initialPageLoad, setInitialPageLoad] = useState(false)

  const [showToast, setShowToast] = useState(false)
  const [composeEmail, setComposeEmail] = useState(initialStateComposeEmail)

  const lazyInitialization = () =>
    timestampToDateString(moment().unix(), DATEFORMAT.DATETIME4D3MHA)
  const [lastSyncMessage, setLastSyncMessage] = useState(lazyInitialization)

  const refreshBmailPage = () => {
    setLoading(true)
    loadBmailPage()
  }
  const loadBmailPage = () => {
    getCountView('THREADS', folder, 'promotions', providerAccount?.token)
      .then(({ data: { count } }) => {
        getThreads({
          ...pagination,
          folder,
          not_in: 'promotions',
          token: providerAccount?.token,
        })
          .then(({ data: threads }) => {
            setLastSyncMessage(
              timestampToDateString(
                moment().unix() * 1000,
                DATEFORMAT.DATETIME4D3MHA
              )
            )
            dispatch(
              setPageData({
                ...pagination,
                email: PAG_KEY,
                threads,
                count,
              })
            )
            if (folder === 'sent') {
              getOpenedEmails(
                threads[0].account_id,
                threads.map(thread => thread.message_ids).flat(),
                orgId
              ).then(({ data: opened }) => {
                dispatch(
                  setOpenedThreads({
                    email: PAG_KEY,
                    opened: opened.map(thread => ({
                      count: thread.metadata.count,
                      messageId: thread.metadata.message_id,
                    })),
                  })
                )
              })
            }
          })
          .catch(error => console.log(error))
          .finally(() => setLoading(false))
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    let isMounted = true
    if (orgId) {
      ApiOrg.getOrganization(orgId, true, true).then(org =>
        isMounted ? setOrg(org) : 0
      )
    }
    return () => {
      isMounted = false
    }
  }, [orgId])

  useEffect(() => {
    if (!pagination) {
      dispatch(
        setPagination({
          limit: 10,
          offset: 0,
          email: PAG_KEY,
        })
      )
      return
    }
    if (pagination.offset < emailPagesLength) {
      setLoading(false)
      return
    }
    loadBmailPage()
  }, [pagination])

  useEffect(() => {
    setIsSending(true)
    setInitialPageLoad(true)
    filterBContacts(providerAccount?.token).then(response => {
      setBContacts(response)
      setIsSending(false)
    })
  }, [providerAccount])

  const noEmptyValues = obj =>
    Object.fromEntries(
      Object.entries(obj).filter(
        ([, v]) => v != null && v != '' && v != undefined
      )
    )

  const handleBContactsClick = () => {
    dispatch(setFolder({ folder: '' }))
    setIsSending(true)
    setInitialPageLoad(true)
    filterBContacts(providerAccount?.token).then(response => {
      setBContacts(response)
      setIsSending(false)
    })
  }

  const resetFilterHandler = () => {
    setQueryFilter(initialQueryState)
    setFilterContacts(bContacts)
  }
  const handleOpenFilterModal = () => {
    resetFilterHandler()
    setShowFilterModal(true)
  }
  const handleCloseFilterModal = () => setShowFilterModal(false)

  const applyFilterHandler = async filter => {
    if (Object.values(filter)) {
      setQueryFilter(filter)
      const valueOnlyFilter = noEmptyValues(filter)
      const { ...rest } = valueOnlyFilter
      const fields = Object.keys(rest)
      const fieldFilteredContacts = bContacts.filter(contact =>
        fields.every(field => {
          if (field == 'name') {
            return rest.name?.includes(
              `${contact.first_name} ${contact.last_name}`
            )
          } else if (field == 'email') {
            return contact.email?.includes(rest?.email)
          } else if (Array.isArray(rest[field]) && contact[field]) {
            return contact[field].some(item => rest[field].includes(item))
          } else {
            return contact[field] === rest[field]
          }
        })
      )
      setFilterContacts(fieldFilteredContacts)
    }
    handleCloseFilterModal()
  }

  const handleThreadClick = (threadId, messageIds, subject) => {
    navigateThreadClick(threadId, messageIds, subject, navigate)
  }

  const handleNextClick = () => {
    setLoading(true)
    dispatch(
      setPagination({
        limit: pagination.limit,
        offset: pagination.offset + pagination.limit,
        email: PAG_KEY,
      })
    )
  }

  const handlePreviousClick = () => {
    setLoading(true)
    dispatch(
      setPagination({
        limit: pagination.limit,
        offset: Math.max(pagination.offset - pagination.limit, 0),
        email: PAG_KEY,
      })
    )
  }

  const handleFolderClick = _folder => {
    setBContacts([])
    dispatch(setFolder({ folder: _folder }))
    setLoading(folder !== _folder)
  }

  const getCurrentPage = (emailPages, pagination) => {
    const offset = pathOr(0, ['offset'], pagination)
    const limit = pathOr(10, ['limit'], pagination)

    return emailPages
      .slice(offset, offset + limit + 1)
      .filter(thread => !!thread)
  }

  const currentOffsetTotal = () => {
    const offset = pathOr(0, ['offset'], pagination)
    const limit = pathOr(10, ['limit'], pagination)
    return offset + limit
  }
  const getOffsetPage = (pagination, emailPagesCount) => {
    const cursorTotal = currentOffsetTotal(pagination)
    return cursorTotal > emailPagesCount ? emailPagesCount : cursorTotal
  }

  const noPaginationOffset = !pagination?.offset

  const handleClose = () => {
    setComposeEmail(initialStateComposeEmail)
    setShowCompose(false)
  }
  const handleShow = () => setShowCompose(true)

  const inputChangeHandler = e => {
    e.preventDefault()
    e.stopPropagation()
    const fieldName = path(['target', 'id'], e)
    let value = path(['target', 'value'], e)
    setComposeEmailPropValue(fieldName, value)
  }

  const setComposeEmailPropValue = (fieldName, value) => {
    setComposeEmail(prevState => ({
      ...prevState,
      [fieldName]: value,
    }))
  }

  const validFormSentEmail = composeEmail => {
    const { to } = composeEmail
    return to?.length
  }

  const replaceBodyField = (body, contact) => {
    let bodyMerge = body
    mandatoryFields.forEach(mandatoryField => {
      bodyMerge = bodyMerge.replaceAll(
        `[[${mandatoryField.value}]]`,
        contact[mandatoryField.value]
      )
    })
    return bodyMerge
  }
  const handleSendClick = (mailMerge, contacts) => {
    const { to, subject, body, cc, bcc } = composeEmail
    if (!validFormSentEmail(composeEmail)) {
      setSendStatus(
        <strong className="text-danger">Fill at least To field.</strong>
      )
      setShowToast(true)
      return
    }
    setSendStatus('')
    setIsSending(true)
    const toTarget = to.map(toItem => ({ email: toItem, name: toItem }))
    const ccTarget = cc.map(ccItem => ({ email: ccItem, name: ccItem }))
    const bccTarget = bcc.map(bccItem => ({ email: bccItem, name: bccItem }))
    let promises = []

    if (mailMerge) {
      promises = contacts.map(contact =>
        sendNylasEmail(
          {
            email: providerAccount?.email || 'benjaminapp.bmail@gmail.com',
            name: userFullName,
          },
          [{ email: contact.primaryEmail, name: contact.first_name }], // To
          subject,
          replaceBodyField(body, contact),
          ccTarget,
          bccTarget,
          providerAccount?.token,
          true,
          orgId
        )
      )
    } else {
      promises = [
        sendNylasEmail(
          {
            email: providerAccount?.email || 'benjaminapp.bmail@gmail.com',
            name: userFullName,
          },
          toTarget,
          subject,
          body,
          ccTarget,
          bccTarget,
          providerAccount?.token,
          true,
          orgId
        ),
      ]
    }

    Promise.allSettled(promises)
      .then(() => {
        setSendStatus('Email was sent successfully')
        setShowCompose(false)
      })
      .catch(() => {
        setSendStatus('Something is wrong, please try again.')
      })
      .finally(() => {
        setShowToast(true)
        setIsSending(false)
      })
  }
  const disabledNext = loading || currentOffsetTotal(pagination) >= countPages
  const disabledPrevious = loading || noPaginationOffset

  const dateAndTimeString = timestamp => {
    const dateTime = timestampToDateString(timestamp)
    return dateTime.date
  }

  const offsetPage = getOffsetPage(pagination, emailPagesLength)

  const columns = contactColumns({ showSendEmail: false }).filter(
    c => c.private
  )

  const memoizedColumns = useMemo(() => columns, [columns])

  return (
    <Wrapper>
      <Container fluid>
        <ToastContainer position="top-end" className="p-3">
          <Toast
            placement=""
            onClose={() => setShowToast(false)}
            show={showToast}
            delay={3000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">Send Email Status</strong>
            </Toast.Header>
            <Toast.Body>{sendStatus}</Toast.Body>
          </Toast>
        </ToastContainer>
        <MailboxCompose
          showCompose={showCompose}
          setComposeEmailPropValue={setComposeEmailPropValue}
          handleClose={handleClose}
          composeEmail={composeEmail}
          handleSendClick={handleSendClick}
          inputChangeHandler={inputChangeHandler}
          isSending={isSending}
        />
        <h1>BMail</h1>
        <div className="row">
          <div className="w-15 minw-15">
            <div className="position-relative start-0 mt-1">
              <button className="btn btn-compose mb-4" onClick={handleShow}>
                <ComposeIcon />
                &nbsp;&nbsp;
                <span className="text-white">New email</span>
              </button>
              <div className="row">
                <div className="col">
                  <VariantsExample
                    className="w-100"
                    active={folder === FOLDERS.INBOX}
                    onClick={() => handleFolderClick(FOLDERS.INBOX)}
                  >
                    <span aria-hidden="true">
                      <InboxIcon className="mx-1" /> Inbox
                    </span>
                  </VariantsExample>
                </div>
              </div>
              <div className="row mt-1">
                <div className="col">
                  <VariantsExample
                    className={'w-100'}
                    active={folder === FOLDERS.SENT}
                    onClick={() => handleFolderClick(FOLDERS.SENT)}
                  >
                    <span aria-hidden="true">
                      <SentIcon className="mx-1" /> Sent
                    </span>
                  </VariantsExample>
                </div>
              </div>
            </div>
            <hr />
            <h3>Contacts</h3>
            <div className="row mt-1">
              <div className="col">
                <VariantsExample
                  className={'w-100'}
                  active={bContacts.length}
                  onClick={() => handleBContactsClick(CONTACT_TAB)}
                >
                  <span aria-hidden="true">
                    <IoIosContacts className="mx-1" /> BContacts
                  </span>
                </VariantsExample>
              </div>
            </div>
          </div>
          <div className="w-75">
            {folder === '' ? (
              <Col sm={3}>
                <button
                  className="btn btn-compose mb-4"
                  onClick={handleOpenFilterModal}
                >
                  <BsFilter />
                  &nbsp;&nbsp;
                  <span className="text-white">Filter Contacts</span>
                </button>
                {showFilterModal ? (
                  <ModalFilterContactsJS
                    contacts={bContacts}
                    showFilterModal={showFilterModal}
                    handleCloseFilterModal={handleCloseFilterModal}
                    applyFilter={applyFilterHandler}
                    resetFilter={resetFilterHandler}
                    incomingFilter={queryFilter}
                    bContacts={true}
                  />
                ) : null}
              </Col>
            ) : null}
            {folder === '' ? (
              bContacts.length ? (
                <ContactsTable
                  data={
                    Object.entries(noEmptyValues(queryFilter)).length
                      ? filterContacts
                      : bContacts
                  }
                  setData={setBContacts}
                  columns={memoizedColumns}
                  isLoading={isSending}
                  expandable={false}
                  pagination={contactsPagination}
                  setPagination={setContactsPagination}
                />
              ) : isSending ? (
                <>Loading contacts...</>
              ) : initialPageLoad ? (
                <>No contacts found.</>
              ) : null
            ) : (
              <>
                <div>
                  {/* <div className="input-group mb-3 w-50">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search emails..."
                      aria-label="Recipient's username"
                      aria-describedby="button-addon2"
                    />
                    <button
                      className="btn btn-outline-info"
                      type="button"
                      id="button-addon2"
                    >
                      <SearchIcon />
                    </button>
                  </div> */}
                </div>
                <div className="d-flex gap-4 justify-content-end mb-4">
                  <div className="d-flex gap-2 align-items-center flex-grow-1">
                    <SyncIcon onClick={() => refreshBmailPage()} />
                    <span className="last-sync">
                      Last Sync: {lastSyncMessage}
                    </span>
                  </div>
                  {countPages && (
                    <div className="page-item">
                      <div className="text-center">
                        <small className="pag-text-color">
                          {`${Math.min(
                            offsetPage - pathOr(0, ['limit'], pagination) + 1,
                            emailPagesLength
                          )}-${offsetPage}`}{' '}
                          of {countPages}
                        </small>
                      </div>{' '}
                    </div>
                  )}
                  <div className="d-flex gap-4">
                    <button
                      className={`page-link pag-button ${
                        disabledPrevious ? 'disabled text-muted' : ''
                      }`}
                      aria-label="Previous"
                      onClick={handlePreviousClick}
                      disabled={disabledPrevious}
                    >
                      <span aria-hidden="true">
                        <PreviousIcon fill={PAG_BTN_COLOR[disabledPrevious]} />
                      </span>
                    </button>
                    <button
                      className={`page-link pag-button ${
                        disabledNext ? 'disabled text-muted' : ''
                      }`}
                      aria-label="Next"
                      onClick={handleNextClick}
                      disabled={disabledNext}
                    >
                      <span aria-hidden="true">
                        <NextIcon fill={PAG_BTN_COLOR[disabledNext]} />
                      </span>
                    </button>
                  </div>
                </div>

                {loading && <span>Loading...</span>}
                <ListGroup as="ol">
                  {!loading &&
                    getCurrentPage(emailPages, pagination).map(thread => {
                      const thread_message_ids = thread.message_ids
                      const opened_count =
                        openedThreads &&
                        openedThreads.find(msg =>
                          thread_message_ids.includes(msg.messageId)
                        )
                      return (
                        <div
                          key={thread.id}
                          className="d-flex bd-highlight mb-1 gap-2 border-top"
                          onClick={() =>
                            handleThreadClick(
                              thread.id,
                              thread.message_ids,
                              thread.subject
                            )
                          }
                        >
                          <div className="mt-3 p-1  w-15 bd-highlight long-with-dots">
                            <span
                              className={
                                thread?.unread ? 'fw-bold' : 'fw-normal'
                              }
                            >
                              {thread.participants[0].name}{' '}
                            </span>
                          </div>
                          <div
                            className={`d-flex mt-3 p-1  w-70 bd-highlight long-with-dots${
                              thread?.unread ? 'fw-bold' : 'fw-normal'
                            }`}
                          >
                            <p className="long-with-dots">
                              {opened_count ? (
                                <>
                                  <span>{opened_count.count}</span>
                                  &nbsp;&nbsp;
                                  <BsFillCheckCircleFill
                                    size={16}
                                    color="#198754"
                                    style={{
                                      position: 'relative',
                                      bottom: '1px',
                                    }}
                                  />
                                  &nbsp;&nbsp;&nbsp;&nbsp;
                                </>
                              ) : null}
                              <span>{thread.subject}</span> {' - '}{' '}
                              <span className="text-muted">
                                {thread.snippet}
                              </span>
                            </p>
                          </div>
                          <div className="mt-3 p-1  w-15 bd-highlight text-end">
                            <small>
                              {dateAndTimeString(
                                thread.last_message_timestamp * 1000
                              )}
                            </small>
                          </div>
                        </div>
                      )
                    })}
                </ListGroup>
              </>
            )}
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}

export default MailBoxPage
