import { Col, Row, Table } from 'react-bootstrap'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import EventTaskRow from './EventTaskRow'
import { useEffect, useState } from 'react'
import { propEq } from 'ramda'
import SortIconActionTwo from '../ui-elements/SortIconActionTwo'
import Api from '../../api/user'

const StyledTh = styled.th`
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`

const EventTasks = ({ tasks }) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    let isMounted = true
    Api.getUsers().then(cc => (isMounted ? setUsers(cc) : 0))
    return () => {
      isMounted = false
    }
  }, [])

  const [sortTask, setSortTask] = useState(1)
  const [sortAssignee, setSortAssignee] = useState(0)

  const sortUp = (a, b, objValue) => {
    let sortA = a[objValue]
    let sortB = b[objValue]
    if (objValue == 'owner') {
      sortA = getContactfullNameById(sortA)
      sortB = getContactfullNameById(sortB)
    }
    sortA = sortA.toUpperCase()
    sortB = sortB.toUpperCase()
    if (sortA < sortB) {
      return -1
    }
    if (sortA > sortB) {
      return 1
    }
    return 0
  }

  const sortDown = (a, b, objValue) => {
    let sortA = a[objValue]
    let sortB = b[objValue]
    if (objValue == 'owner') {
      sortA = getContactfullNameById(sortA)
      sortB = getContactfullNameById(sortB)
    }
    sortA = sortA.toUpperCase()
    sortB = sortB.toUpperCase()
    if (sortA > sortB) {
      return -1
    }
    if (sortA < sortB) {
      return 1
    }
    return 0
  }

  const fullName = ({ name, lastName }) => `${name || ''} ${lastName || ''}`

  const getContactfullNameById = id => {
    const contact = users.find(propEq('_id', id))
    if (contact) {
      return fullName(contact)
    }
    return ''
  }

  const sortBySelect = (a, b, sortBy, sortStat) => {
    return sortStat === 1
      ? sortUp(a, b, sortBy)
      : sortStat === 2
      ? sortDown(a, b, sortBy)
      : null
  }

  const selectSort = (a, b) => {
    let selectedSort = null
    if (sortTask != 0) {
      selectedSort = sortBySelect(a, b, 'description', sortTask)
    } else if (sortAssignee != 0) {
      selectedSort = sortBySelect(a, b, 'owner', sortAssignee)
    }
    return selectedSort
  }

  const updateSetSortTask = value => {
    setSortTask(value)
    setSortAssignee(0)
  }

  const updateSetSortAssignee = value => {
    setSortTask(0)
    setSortAssignee(value)
  }

  return (
    <Row>
      <Col>
        <Table hover>
          <thead>
            <tr>
              <StyledTh>
                Task{' '}
                <SortIconActionTwo
                  setFunctState={updateSetSortTask}
                  stateValue={sortTask}
                />
              </StyledTh>
              <StyledTh>
                Owner{' '}
                <SortIconActionTwo
                  setFunctState={updateSetSortAssignee}
                  stateValue={sortAssignee}
                />
              </StyledTh>
              <StyledTh />
            </tr>
          </thead>
          <tbody>
            {tasks &&
              tasks
                ?.sort((a, b) => selectSort(a, b))
                .map((task, i) => {
                  return users.length ? (
                    <EventTaskRow
                      key={`row-${i}`}
                      task={task}
                      contacts={users}
                    />
                  ) : null
                })}
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

EventTasks.propTypes = {
  tasks: PropTypes.array.isRequired,
}

export default EventTasks
