import { IContact } from '../api/interfaces'
import ImageInitials from '../components/ContactsPage/ImageInitials'
import makeProfilePicUrl from './makeProfilePicUrl'
import React from 'react'

export const capitalizedWord = (word: string) =>
  word.charAt(0).toUpperCase() + word.slice(1)

export const getLetterByIndex = (word = '', index: number) => {
  if (word.length > index) {
    return word[index]
  }
  return ''
}

export const getInitialLettersContact = (contact: IContact | any) => {
  let initials = getLetterByIndex(contact?.first_name, 0)
  if (contact?.last_name !== '') {
    initials += getLetterByIndex(contact?.last_name, 0)
  } else {
    initials += getLetterByIndex(contact?.first_name, 1)
  }
  return initials
}

export const getAvatarContact = (
  contact: IContact | any,
  size = '40',
  sizeUnit = 'px'
): JSX.Element => {
  if (
    contact.picture === undefined ||
    contact.picture === '' ||
    contact.picture === 'unassigned'
  ) {
    const initials = getInitialLettersContact(contact)

    return (
      <>
        <ImageInitials
          initials={initials}
          size={size}
          sizeUnit={sizeUnit}
          bgColor={'#11273c'}
          fColor={'white'}
        />
      </>
    )
  } else {
    return (
      <>
        <img src={makeProfilePicUrl(contact)} />
      </>
    )
  }
}

export const parseCellToString = (cell: unknown) => {
  if (!cell) return ''
  try {
    return String(cell)
  } catch (error) {
    return ''
  }
}

export const formatPhoneNumber = (phone: string) => {
  if (!phone) return ''
  let value = phone.replace(/^\+1+/, '').replace(/\D/g, '')
  let formattedValue = ''
  if (value.length > 10) {
    value = value.slice(0, 10)
  }
  if (value.length >= 7) {
    formattedValue = `(${value.slice(0, 3)}) ${value.slice(3, 6)} ${value.slice(
      6
    )}`
  } else if (value.length >= 4) {
    formattedValue = `(${value.slice(0, 3)}) - ${value.slice(3)}`
  } else {
    formattedValue = value
  }
  return formattedValue
}

export const isValidEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}
