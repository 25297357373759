import { identity, pathOr, pipe, propEq, propOr } from 'ramda'
import moment from 'moment'
import { branch } from '../fp/branch'
import usStates from '../../assets/datasets/us-states.json'

// findState:: String -> USAState
const findState = id => usStates.find(propEq('state', id))

// getEventId:: Event -> String
export const getEventId = propOr('', '_id')

// getName:: Event -> String
export const getName = propOr('', 'name')

// getState:: Event -> USAState
export const getState = propOr('', 'state')

// getCity:: Event -> USAState
export const getCity = propOr('', 'city')

// getStateName:: Event -> String
export const getStateName = pipe(getState, findState, propOr('', 'name'))

// getLocation:: Event -> String
export const getLocation = event => `${getCity(event)}, ${getState(event)}`

// getDay:: Event -> String
export const getDay = propOr('', 'day')

// getTime:: Event -> String
export const getTime = propOr('', 'hour')

// getFinancialGoal:: Event -> Number
export const getFinancialGoal = propOr(0, 'financialGoal')

// getSuggestedDonation:: Event -> Number
export const getSuggestedDonation = propOr(0, 'suggestedDonation')

// getTotalRemaining:: EventConsolidatedSummary -> Number
export const getTotalRemaining = pathOr(0, ['totals', 'remaining'])

// getTotalDeposited:: EventConsolidatedSummary -> Number
export const getTotalDeposited = pathOr(0, ['totals', 'deposited'])

// getTotalPledged:: EventConsolidatedSummary -> Number
export const getTotalPledged = pathOr(0, ['totals', 'pledged'])

// getTotalExpected:: EventConsolidatedSummary -> Number
export const getTotalExpected = pathOr(0, ['totals', 'raised'])

// getAttendeeCount:: EventConsolidatedSummary -> Number
export const getAttendeesCount = pathOr(0, ['attendees', 'count'])

// getVipAttendeesCount:: EventConsolidatedSummary -> Number
export const getVipAttendeesCount = pathOr(0, ['attendees', 'vipCount'])

// getSpecialGuestCount:: EventConsolidatedSummary -> Number
export const getSpecialGuestCount = pathOr(0, ['attendees', 'guestCount'])

// getTasksCount:: EventConsolidatedSummary -> Number
export const getTasksCount = pathOr(0, ['tasks', 'count'])

// getDoneTasksCount:: EventConsolidatedSummary -> Number
export const getDoneTasksCount = pathOr(0, ['tasks', 'done'])

// getDatetime:: Event -> String
export const getDatetime = pipe(
  branch([propOr('', 'day'), propOr('', 'hour')], identity),
  ([d, h]) => moment(`${d} ${h}`).toISOString()
)

// getColor:: Event -> String
export const getColor = propOr('', 'color')

// Event Provider
// getUrlMeeting:: Event -> String
export const getUrlMeeting = pathOr('', ['conferencing', 'details', 'url'])
export const getCallProvider = pathOr('', ['conferencing', 'provider'])
export const getZoomCode = pathOr('', [
  'conferencing',
  'details',
  'meeting_code',
])
export const getZoomPass = pathOr('', ['conferencing', 'details', 'password'])
export const getParticipants = pathOr([], ['participants'])
export const getParticipantName = pathOr([], ['name'])
export const getParticipantEmail = pathOr([], ['email'])
export const getParticipantStatus = pathOr('noreply', ['status'])
export const getContactIdZoomMeeting = pathOr('', ['metadata', 'contactId'])
