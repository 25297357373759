import PropTypes from 'prop-types'
import styled from 'styled-components'

const Triangle = styled.div`
  background-color: #e6e6e6;
  clip-path: polygon(0 100%, 50% 0, 100% 100%);
  width: 0.5rem;
  height: 0.5rem;
  margin: 0 auto;
`

const TooltipWrapper = styled.div`
  position: absolute;
  top: var(--top, 50%);
  left: var(--left, 50%);
  transform: translate(-50%, 1rem);
  z-index: 10;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
`

const TooltipInnerWrapper = styled.div`
  padding: 0.5rem;
  background-color: #e6e6e6;
  border-radius: 4px;
  font-size: 0.75rem;
  z-index: 10;
`

const InfoTooltip = ({ offset, children }) => {
  return (
    <TooltipWrapper
      style={{
        '--top': `${offset.y}px`,
        '--left': `${offset.x}px`,
      }}
    >
      <Triangle />
      <TooltipInnerWrapper>{children}</TooltipInnerWrapper>
    </TooltipWrapper>
  )
}

InfoTooltip.propTypes = {
  offset: PropTypes.object.isRequired,
  children: PropTypes.node,
}

export default InfoTooltip
