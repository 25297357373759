import React, { FC, forwardRef } from 'react'
import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { BsThreeDots } from 'react-icons/bs'

const IconWrapper = styled.div`
  padding: 0.3rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  background-color: #f7f8f8;

  > img,
  svg {
    margin: 0;
    padding: 0;
  }
`

interface CustomToggleProps {
  icon: JSX.Element
  onClick: (evt: React.MouseEvent<HTMLDivElement> | undefined) => void
}
const CustomToggle = forwardRef<HTMLDivElement, CustomToggleProps>(
  function CustomToggle({ onClick, icon }, ref) {
    return (
      <IconWrapper
        ref={ref}
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
          onClick(e)
        }}
      >
        {icon}
      </IconWrapper>
    )
  }
)

interface CustomDotsDropdownProps {
  children: React.ReactNode
  icon?: JSX.Element
}

const CustomDotsDropdown: FC<CustomDotsDropdownProps> = ({
  children,
  icon = <BsThreeDots size={12} />,
}) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        as={CustomToggle}
        id="dropdown-dots-custom-components"
        icon={icon}
      />
      <Dropdown.Menu rootCloseEvent="mousedown">{children}</Dropdown.Menu>
    </Dropdown>
  )
}

export default CustomDotsDropdown
