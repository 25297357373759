import { useState } from 'react'
import EditMode from './EditMode'
import PropTypes from 'prop-types'

const EditModeControl = ({
  initialValue = '',
  changeButtonText = 'Edit',
  okButtonText,
  cancelButtonText,
  editionDisabledClass = 'row justify-content-end align-items-center',
  editionEnabledClass = 'row justify-content-center align-items-center',
  placeholder = '',
  RenderText,
  editContainerClass,
  editButtonClass,
  callBackSetValue,
  callBackEditMode,
}) => {
  const [editionMode, setEditionMode] = useState(false)

  return (
    <>
      <div className={editionMode ? editionEnabledClass : editionDisabledClass}>
        {editionMode ? (
          <>
            <EditMode
              okButtonText={okButtonText}
              cancelButtonText={cancelButtonText}
              placeholder={placeholder}
              callBackEdit={value => callBackSetValue(value)}
              callBackEditMode={mode => {
                callBackEditMode && callBackEditMode(mode)
                setEditionMode(mode)
              }}
              text={initialValue}
            />
          </>
        ) : (
          <div className={editContainerClass || 'col-auto'}>
            {RenderText || null}
            <button
              className={editButtonClass || 'btn btn-light'}
              onClick={() => setEditionMode(true)}
            >
              {changeButtonText}
            </button>
          </div>
        )}
      </div>
    </>
  )
}
EditModeControl.propTypes = {
  editionEnabledClass: PropTypes.string,
  editionDisabledClass: PropTypes.string,
  initialValue: PropTypes.string,
  changeButtonText: PropTypes.string,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  placeholder: PropTypes.string,
  RenderText: PropTypes.element,
  editContainerClass: PropTypes.string,
  editButtonClass: PropTypes.string,
  callBackSetValue: PropTypes.func.isRequired,
  callBackEditMode: PropTypes.func,
}
export default EditModeControl
