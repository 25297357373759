import { nth, prop } from 'ramda'
import constants from '../config/constants'
import axios from './axios'
import { IUser } from './interfaces'

const findUser = async (username: string) => {
  const endpoint = constants.USERS_ENDPOINT()
  const params = `/username/${encodeURIComponent(username || '')}`
  const url = `${endpoint}${params}`
  return axios<IUser[]>({
    url,
    method: 'GET',
  })
    .then(prop('data'))
    .then(nth(0))
}

const findUserById = async (userId: string) => {
  const endpoint = constants.USERS_ENDPOINT()
  const params = `/${encodeURIComponent(userId || '')}`
  const url = `${endpoint}${params}`
  return axios<IUser[]>({
    url,
    method: 'GET',
  })
    .then(prop('data'))
    .then(nth(0))
}

const getUsers = async (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  role = ''
) => {
  const endpoint = constants.USERS_ENDPOINT()
  const url = `${endpoint}`
  return axios<IUser[]>({
    url,
    method: 'GET',
    headers: {
      organizationId,
      role,
    },
  }).then(prop('data'))
}

// updateUSer:: {user: User, userId: User$=#._id} => Promise [db result]
const updateUser = async (payload: {
  user: Partial<IUser>
  userId: string | undefined
}) => {
  const endpoint = constants.USERS_ENDPOINT()
  const url = `${endpoint}`
  return axios({
    url,
    method: 'PUT',
    data: JSON.stringify(payload),
  }).then(prop('data'))
}

const createUser = async (payload: { user: Partial<IUser> }) => {
  const endpoint = constants.USERS_ENDPOINT()
  const url = `${endpoint}`
  return axios({
    url,
    method: 'POST',
    data: JSON.stringify(payload),
  }).then(prop('data'))
}

export default {
  findUser,
  findUserById,
  getUsers,
  updateUser,
  createUser,
}
