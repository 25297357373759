import styled from 'styled-components'
import { Nav } from 'react-bootstrap'
// import NotificationsMenu from './NotificationsMenu'
// import SearchBox from './SearchBox'
import UserMenu from './UserMenu'
import OrgName from './OrgName'

const Wrapper = styled.div`
  padding: 1.25rem 0.5rem;
  border-bottom: 1pt solid #d9d9d9;
`

const StyledNavItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

const RightControls = () => (
  <>
    {/* <Nav.Item>
      <SearchBox />
    </Nav.Item>
    <Nav.Item>
      <NotificationsMenu />
    </Nav.Item> */}
    <StyledNavItem>
      <OrgName />
    </StyledNavItem>
    <StyledNavItem>
      <UserMenu />
    </StyledNavItem>
  </>
)

const TopBar = () => {
  return (
    <Wrapper>
      <Nav className="justify-content-end align-items-center">
        <RightControls />
      </Nav>
    </Wrapper>
  )
}

export default TopBar
