import constants from '../../../config/constants'
declare global {
  interface Window {
    nylas: { scheduler: { show: (...args: unknown[]) => Window } }
  }
}
export const handleScheduleCall = async (
  onClose: () => void,
  token?: string,
  organizationId?: string,
  thankYouRedirect?: string
) => {
  if (!token || !organizationId) return
  const instance = window.nylas.scheduler.show({
    auth: {
      // Account <ACCESS_TOKEN> with active calendar scope
      accessToken: token,
    },
    style: {
      // Style the schdule editor
      tintColor: '#32325d',
      backgroundColor: 'white',
    },
    defaults: {
      event: {
        title: '30-min Coffee Meeting',
        duration: 30,
      },
      appearance: {
        company_name: 'Benjamin',
        // "logo": "https://assets.site.com/logos/company_name.png"
        thank_you_redirect:
          thankYouRedirect ||
          `${constants.SCHEDULER_ENDPOINT()}/${token}?organizationId=${organizationId}`,
      },
    },
  })
  instance.addEventListener('close', () => onClose())
}
