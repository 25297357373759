import PropTypes from 'prop-types'
import { prop, propEq, propOr } from 'ramda'
import styled from 'styled-components'
// import * as flags from '../../assets/usa-flags'

const colorPalette = [
  '#9651D9',
  '#2C5EFF',
  '#00EAA5',
  '#00CBE7',
  '#BB1F10',
  '#FF584E',
  '#6A748C',
  '#6300AA',
  '#2900CC',
  '#5172FF',
  '#00A4C0',
  '#FF9415',
  '#F70000',
  '#243159',
]

export const colorFromString = text => {
  const colorCount = colorPalette.length
  const textNumber = (text || '')
    .split('')
    .map(c => c.charCodeAt(0))
    .reduce((acc, val) => acc + val, 0)
  const index = textNumber % colorCount
  return colorPalette[index]
}

const PlaceHolder = styled.div`
  height: 100%;
  border-style: solid;
  border-width: ${p => (p.colorBarTop ? '6px 0px 0px 0px' : '0px 0px 0px 6px')};
  border-color: var(--color);
  color: #4a4a4a;
  background-color: #f7f8fa;
  & .placeholderContent {
    height: 100%;
    padding: var(--vertical-padding) var(--horizontal-padding);
    font-size: var(--font-size);
    font-weight: 700;
    line-height: 1.938rem;
    text-align: center;
    border-top: ${p => (p.colorBarTop ? 'none' : '1px solid #DCE1E6')};
    border-bottom: ${p => (p.colorBarTop ? 'none' : '1px solid #DCE1E6')};
    border-right: ${p => (p.colorBarTop ? 'none' : '1px solid #DCE1E6')};
    transition: color 200ms ease;
    transition: border-color 200ms ease;
  }
`

const ImagePlaceHolder = ({ text, options, onClick }) => {
  const color = propOr(colorFromString(text), 'color', options)
  const isHoverable = prop('isHoverable', options)
  const hasTopColorBar = propEq('colorBarPosition', 'top', options)
  const imageSize = prop('imageSize', options)
  // const hasLargeText = prop('hasLargeText', options)
  const flagHeight =
    imageSize === 'large' ? 88 : imageSize === 'medium' ? 40 : 32
  const verticalPadding =
    imageSize === 'large' ? '29px' : imageSize === 'medium' ? '27px' : '8px'
  const horizontalPadding =
    imageSize === 'large' ? '29px' : imageSize === 'medium' ? '22px' : '14px'
  const styles = {
    cursor: isHoverable ? 'pointer' : 'initial',
    '--color': color,
    '--font-size': imageSize === 'large' ? '3rem' : '2rem',
    '--vertical-padding': verticalPadding,
    '--horizontal-padding': horizontalPadding,
    height: 'auto',
  }
  const clickHandler =
    isHoverable && onClick
      ? onClick
      : () => {
          console.log('onClick')
        }

  return (
    <PlaceHolder
      colorBarTop={hasTopColorBar}
      style={styles}
      onClick={clickHandler}
    >
      <div className="placeholderContent">
        <img
          src={`/usa-flags/${text}.svg`}
          height={flagHeight}
          width="62.89"
          onError={({ currentTarget }) => {
            currentTarget.onerror = null // prevents looping
            currentTarget.src = 'placeholder.svg'
          }}
        />
      </div>
    </PlaceHolder>
  )
}

ImagePlaceHolder.propTypes = {
  text: PropTypes.string,
  options: PropTypes.shape({
    color: PropTypes.string.isRequired,
    colorBarPosition: PropTypes.oneOf(['top', 'left']).isRequired,
    imageSize: PropTypes.oneOf(['small', 'medium', 'large']).isRequired,
    isHoverable: PropTypes.bool,
  }).isRequired,
  onClick: PropTypes.func,
}

export default ImagePlaceHolder
