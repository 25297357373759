import constants from '../config/constants'
import axios from './axios'
import { prop } from 'ramda'
import { IQueue } from './interfaces'

const getNumberAsks = async (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  isAllowed = false
) => {
  const query = `organizationId=${organizationId}`
  let endpoint = constants.QUEUE_ENDPOINT()
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')
  const url = `${endpoint}/asks?${query}`
  return axios<number>({
    url,
    headers: {
      organizationId,
    },
    method: 'GET',
  }).then(prop('data'))
}

const getQueueValue = async (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  isAllowed = false
) => {
  const query = `organizationId=${organizationId}`
  let endpoint = constants.QUEUE_ENDPOINT()
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')
  const url = `${endpoint}/value?${query}`
  return axios<number>({
    url,
    headers: {
      organizationId,
    },
    method: 'GET',
  }).then(prop('data'))
}

export const getQueueData = (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  offset = 0,
  size = 0,
  filters = {},
  name = '',
  isAllowed = false
) => {
  let endpoint = `${constants.QUEUE_ENDPOINT()}/`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}`
  return axios<IQueue[]>({
    url,
    method: 'POST',
    data: { filters, offset, size, name },
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}

export const getQueueDataCount = (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  filters = {},
  name = '',
  isAllowed = false
) => {
  let endpoint = `${constants.QUEUE_ENDPOINT()}/count`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}`
  return axios<IQueue[]>({
    url,
    method: 'POST',
    data: { filters, name },
    headers: {
      organizationId,
    },
  }).then(prop('data'))
}

export const addQueueData = (data: IQueue[], isAllowed = false) => {
  let endpoint = `${constants.QUEUE_ENDPOINT()}/add`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios<IQueue[]>({
    url,
    method: 'POST',
    data,
  }).then(prop('data'))
}

export const updateQueueData = (data: IQueue, isAllowed = false) => {
  let endpoint = `${constants.QUEUE_ENDPOINT()}`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios<IQueue[]>({
    url,
    method: 'PATCH',
    data,
  }).then(prop('data'))
}

export default {
  getNumberAsks,
  getQueueValue,
  getQueueData,
  getQueueDataCount,
  addQueueData,
  updateQueueData,
}
