import styled from 'styled-components'
import PropTypes from 'prop-types'
import CardBorder from './CardBorder'
import arrowIcon from '../../assets/icons/right-arrow-black.png'
import { useNavigate } from 'react-router-dom'

const Top = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
`

const Title = styled.div`
  margin-bottom: 2rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
`

const Arrow = styled.div`
  font-size: 1.2rem;
  font-weight: normal;
  > img {
    width: 15px;
  }
`

const Card = ({ style, title, children, flag, url }) => {
  const navigate = useNavigate()

  const handleClick = url => {
    navigate(url)
  }
  return (
    <CardBorder>
      {!flag ? (
        <Top>
          <Title style={style}>{title}</Title>
        </Top>
      ) : (
        <Top>
          <Title
            onClick={() => handleClick(url)}
            style={Object.assign({ cursor: 'pointer' }, style)}
          >
            {title}{' '}
          </Title>
          <Arrow onClick={() => handleClick(url)} style={{ cursor: 'pointer' }}>
            <img src={arrowIcon} />
          </Arrow>
        </Top>
      )}

      {children}
    </CardBorder>
  )
}

Card.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  flag: PropTypes.bool,
  url: PropTypes.string,
  children: PropTypes.node,
}

export default Card
