import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form } from 'react-bootstrap'
import styled from 'styled-components'
import heat_icon from '../../assets/icons/heat_icon.svg'
import heat_icon_2 from '../../assets/icons/heat_icon_2.svg'
import heat_icon_3 from '../../assets/icons/heat_icon_3.svg'
import no_heat_icon from '../../assets/icons/no_heat_icon.svg'
import { useDispatch } from 'react-redux'
import {
  resetQueryFilter,
  setCurrentQueuePage,
  setRefreshQueue,
} from '../../redux/pipeline/pipeline'

const FilterContainer = styled.div`
  // border: 1px solid #00000040;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  min-width: 200px;
  height: auto;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const FilterHeader = styled.div`
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
`

const FilterSubHeader = styled.div`
  font-family: DM Sans;
  font-size: 10px;
  font-weight: 400;
  line-height: 13px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
`

const subHeaderStyle = {
  fontFamily: 'DM Sans',
  fontSize: '10px',
  fontWeight: 400,
  lineHeight: '13px',
  letterSpacing: '0em',
  textAlign: 'left',
  color: '#000000',
}

export const statusOptions = [
  {
    label: 'Not Started',
    color: '#6265642B',
  },
  {
    label: 'Asked',
    color: '#00FFE02B',
  },
  {
    label: 'Contacted',
    color: '#00FFE02B',
  },
  {
    label: 'Meeting Set',
    color: '#00FFE02B',
  },
  {
    label: 'In Progress',
    color: '#00FFE02B',
  },
  {
    label: 'Pledged',
    color: '#00FF1A2B',
  },
  {
    label: 'Paid',
    color: '#00FF1A2B',
  },
  {
    label: 'VIP',
    color: '#26FF0257',
  },
  {
    label: 'Completed',
    color: '#00FF1A2B',
  },
]

export const typeOptions = [
  {
    label: 'New',
    color: '#B32CE217',
  },
  {
    label: 'Donor',
    color: '#E8FBF0',
  },
  {
    label: 'Lead',
    color: '#F6F2DF',
  },
  {
    label: 'Dormant',
    color: '#E6ECFF',
  },
  {
    label: 'VIP',
    color: '#F9EBE7',
  },
  {
    label: 'Declined',
    color: '#FFE8F3',
  },
  {
    label: 'Host',
    color: '#FAD61640',
  },
  {
    label: 'Attendee',
    color: '#FAD61640',
  },
  {
    label: 'Contact',
    color: '#FAD61640',
  },
  {
    label: 'Provided',
    color: '#FAD61640',
  },
  {
    label: 'Bundler',
    color: '#FAD61640',
  },
]

export const purposeOptions = ['Renewal', 'Upsell', 'Prospect', 'Check In']
export const methodOptions = [
  'Sequence',
  'Call',
  'Voicemail',
  'Text',
  'Not Started',
]

const renderIcons = idx => {
  switch (idx) {
    case 0:
      return <img src={no_heat_icon} style={{ height: '11.5px' }} />
    case 1:
      return <img src={heat_icon} style={{ height: '16px' }} />
    case 2:
      return <img src={heat_icon_2} style={{ height: '16px' }} />
    case 3:
      return <img src={heat_icon_3} style={{ height: '16px' }} />
  }
}

const initialPipelineFilterState = {
  name: '',
  heat: {
    0: false,
    1: false,
    2: false,
    3: false,
  },
  capacity: ['', ''],
  region: '',
  lastTouch: ['', ''],
  status: '',
  type: '',
  purpose: '',
  method: '',
  // event: '',
}

export const PipelineFilter = ({ handlePipelineFilter }) => {
  const dispatch = useDispatch()
  const [filter, setFilter] = React.useState(initialPipelineFilterState)
  const clearFilter = () => {
    setFilter(initialPipelineFilterState)
    dispatch(resetQueryFilter())
    dispatch(setCurrentQueuePage(1))
    dispatch(setRefreshQueue(true))
  }
  return (
    <FilterContainer>
      <Button onClick={clearFilter} className="clear-filter-button">
        CLEAR FILTER
      </Button>
      <FilterHeader>Filter</FilterHeader>
      <Form.Group
        onChange={e => {
          setFilter({
            ...filter,
            name: e.target.value,
          })
          const timeout = setTimeout(() => {
            handlePipelineFilter({
              ...filter,
              name: e.target.value,
            })
          }, 1000)
          return () => clearTimeout(timeout)
        }}
      >
        <Form.Label style={subHeaderStyle}>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. Harvey Specter"
          value={filter.name}
          style={{
            height: '25px',
            fontSize: '10px',
            borderRadius: '4px',
          }}
        />
      </Form.Group>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: '8px',
        }}
      >
        <div>
          <FilterSubHeader>Heat</FilterSubHeader>
          <div className="heat-rating">
            {Array.from({ length: 4 }, (_, index) => (
              <div
                className="heat-rating-item"
                key={index}
                style={{ maxHeight: '1.25rem' }}
              >
                <input
                  type="checkbox"
                  id={`heat-${index}`}
                  checked={filter.heat[index]}
                  onChange={e => {
                    setFilter({
                      ...filter,
                      heat: {
                        ...filter.heat,
                        [index]: !filter.heat[index],
                      },
                    })
                    handlePipelineFilter({
                      ...filter,
                      heat: {
                        ...filter.heat,
                        [index]: !filter.heat[index],
                      },
                    })
                  }}
                />
                <label
                  htmlFor={`heat-${index}`}
                  style={{
                    marginLeft: '4px',
                    position: 'relative',
                    bottom: '2px',
                  }}
                >
                  {renderIcons(index)}
                </label>
              </div>
            ))}
          </div>
        </div>
        <div>
          <FilterSubHeader>Ask/Capacity</FilterSubHeader>
          <div style={{ maxHeight: '1.25rem' }}>
            <input
              type="radio"
              id="gte"
              name="capacity-comparison"
              value="gte"
              checked={filter.capacity[0] === 'gte'}
              // disabled={
              //   filter.capacity[0] ? filter.capacity[0] !== 'gte' : false
              // }
              onClick={e => {
                setFilter({
                  ...filter,
                  capacity:
                    filter.capacity[0] === 'gte'
                      ? ['', filter.capacity[1]]
                      : ['gte', filter.capacity[1]],
                })
                handlePipelineFilter({
                  ...filter,
                  capacity:
                    filter.capacity[0] === 'gte'
                      ? ['', filter.capacity[1]]
                      : ['gte', filter.capacity[1]],
                })
              }}
            />
            <label
              htmlFor="gte"
              style={{
                marginLeft: '4px',
                position: 'relative',
                bottom: '3px',
                fontSize: '10px',
              }}
            >
              At least
            </label>
          </div>
          <div style={{ maxHeight: '1.25rem' }}>
            <input
              type="radio"
              id="lte"
              name="capacity-comparison"
              value="lte"
              checked={filter.capacity[0] === 'lte'}
              // disabled={
              //   filter.capacity[0] ? filter.capacity[0] !== 'lte' : false
              // }
              onClick={e => {
                setFilter({
                  ...filter,
                  capacity:
                    filter.capacity[0] === 'lte'
                      ? ['', filter.capacity[1]]
                      : ['lte', filter.capacity[1]],
                })
                handlePipelineFilter({
                  ...filter,
                  capacity:
                    filter.capacity[0] === 'lte'
                      ? ['', filter.capacity[1]]
                      : ['lte', filter.capacity[1]],
                })
              }}
            />
            <label
              htmlFor="lte"
              style={{
                marginLeft: '4px',
                position: 'relative',
                bottom: '3px',
                fontSize: '10px',
              }}
            >
              At most
            </label>
          </div>
          <div style={{ maxHeight: '1.25rem' }}>
            <input
              type="radio"
              id="eq"
              name="capacity-comparison"
              value="eq"
              checked={filter.capacity[0] === 'eq'}
              // disabled={
              //   filter.capacity[0] ? filter.capacity[0] !== 'eq' : false
              // }
              onClick={e => {
                setFilter({
                  ...filter,
                  capacity:
                    filter.capacity[0] === 'eq'
                      ? ['', filter.capacity[1]]
                      : ['eq', filter.capacity[1]],
                })
                handlePipelineFilter({
                  ...filter,
                  capacity:
                    filter.capacity[0] === 'eq'
                      ? ['', filter.capacity[1]]
                      : ['eq', filter.capacity[1]],
                })
              }}
            />
            <label
              htmlFor="eq"
              style={{
                marginLeft: '4px',
                position: 'relative',
                bottom: '3px',
                fontSize: '10px',
              }}
            >
              Equals
            </label>
          </div>
          <input
            type="number"
            placeholder="e.g. 500"
            style={{
              width: '100%',
              height: '20px',
              fontSize: '10px',
              padding: '2px 3px',
            }}
            value={filter.capacity[1]}
            onChange={e => {
              setFilter({
                ...filter,
                capacity: [filter.capacity[0], e.target.value],
              })
              const timeout = setTimeout(() => {
                handlePipelineFilter({
                  ...filter,
                  capacity: [filter.capacity[0], e.target.value],
                })
              }, 1000)
              return () => clearTimeout(timeout)
            }}
          />
        </div>
      </div>
      <Form.Group
        onChange={e => {
          setFilter({
            ...filter,
            region: e.target.value,
          })
          const timeout = setTimeout(() => {
            handlePipelineFilter({
              ...filter,
              region: e.target.value,
            })
          }, 1000)
          return () => clearTimeout(timeout)
        }}
      >
        <Form.Label style={subHeaderStyle}>Region</Form.Label>
        <Form.Control
          type="text"
          value={filter.region}
          placeholder="e.g. NYC Metro"
          style={{
            height: '25px',
            fontSize: '10px',
            borderRadius: '4px',
          }}
        />
      </Form.Group>
      <div>
        <FilterSubHeader>Status</FilterSubHeader>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '8px',
            gridTemplateRows: 'auto',
            rowGap: '5px',
            marginTop: '4px',
          }}
        >
          {statusOptions
            .filter(
              status => status.label !== 'VIP' && status.label !== 'Completed'
            )
            .map((status, idx) => (
              <div
                key={`status-${idx}`}
                style={{
                  // boxShadow:
                  //   filter.status === status.label
                  //     ? '1px 2px 4px #a0a0a0'
                  //     : '1px 2px 4px #ededed',
                  backgroundColor:
                    filter.status !== '' && filter.status !== status.label
                      ? `${status.color}40`
                      : status.color,
                  color:
                    filter.status !== '' && filter.status !== status.label
                      ? '#11273C40'
                      : '#11273C',
                }}
                className="toggle-filter-button"
                onClick={e => {
                  setFilter({
                    ...filter,
                    status: filter.status === status.label ? '' : status.label,
                  })
                  handlePipelineFilter({
                    ...filter,
                    status: filter.status === status.label ? '' : status.label,
                  })
                }}
              >
                {status.label}
              </div>
            ))}
        </div>
      </div>
      <div>
        <FilterSubHeader>Type</FilterSubHeader>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '8px',
            gridTemplateRows: 'auto',
            rowGap: '5px',
            marginTop: '4px',
          }}
        >
          {typeOptions
            .filter(
              t =>
                t.label !== `New` &&
                t.label !== `Host` &&
                t.label !== `Attendee` &&
                t.label !== `Contact`
            )
            .map((typeObj, idx) => (
              <div
                key={`type-${idx}`}
                style={{
                  // boxShadow:
                  //   filter.type === typeObj.label
                  //     ? '1px 2px 4px #a0a0a0'
                  //     : '1px 2px 4px #ededed',
                  backgroundColor:
                    filter.type !== '' && filter.type !== typeObj.label
                      ? `${typeObj.color}40`
                      : typeObj.color,
                  color:
                    filter.type !== '' && filter.type !== typeObj.label
                      ? '#11273C40'
                      : '#11273C',
                }}
                className="toggle-filter-button"
                onClick={e => {
                  setFilter({
                    ...filter,
                    type: filter.type === typeObj.label ? '' : typeObj.label,
                  })
                  handlePipelineFilter({
                    ...filter,
                    type: filter.type === typeObj.label ? '' : typeObj.label,
                  })
                }}
              >
                {typeObj.label}
              </div>
            ))}
        </div>
      </div>
      <div>
        <FilterSubHeader>Purpose</FilterSubHeader>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '8px',
            gridTemplateRows: 'auto',
            rowGap: '5px',
            marginTop: '4px',
          }}
        >
          {purposeOptions.concat('Event').map((purpose, idx) => (
            <div
              key={`type-${idx}`}
              style={{
                backgroundColor:
                  filter.purpose !== '' && filter.purpose !== purpose
                    ? `#1468B62B40`
                    : `#1468B62B`,
                color:
                  filter.purpose !== '' && filter.purpose !== purpose
                    ? '#11273C40'
                    : '#11273C',
              }}
              className="toggle-filter-button"
              onClick={e => {
                setFilter({
                  ...filter,
                  purpose: filter.purpose === purpose ? '' : purpose,
                })
                handlePipelineFilter({
                  ...filter,
                  purpose: filter.purpose === purpose ? '' : purpose,
                })
              }}
            >
              {purpose}
            </div>
          ))}
        </div>
      </div>
      <div>
        <FilterSubHeader>Method</FilterSubHeader>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '8px',
            gridTemplateRows: 'auto',
            rowGap: '5px',
            marginTop: '4px',
          }}
        >
          {methodOptions.map((method, idx) => (
            <div
              key={`type-${idx}`}
              style={{
                backgroundColor:
                  filter.method !== '' && filter.method !== method
                    ? `#14B6852B40`
                    : `#14B6852B`,
                color:
                  filter.method !== '' && filter.method !== method
                    ? '#11273C40'
                    : '#11273C',
              }}
              className="toggle-filter-button"
              onClick={e => {
                setFilter({
                  ...filter,
                  method: filter.method === method ? '' : method,
                })
                handlePipelineFilter({
                  ...filter,
                  method: filter.method === method ? '' : method,
                })
              }}
            >
              {method}
            </div>
          ))}
        </div>
      </div>
      {/* <Form.Group
        onChange={e => {
          setFilter({
            ...filter,
            event: e.target.value,
          })
          const timeout = setTimeout(() => {
            handlePipelineFilter({
              ...filter,
              event: e.target.value,
            })
          }, 1000)
          return () => clearTimeout(timeout)
        }}
      >
        <Form.Label style={subHeaderStyle}>Event</Form.Label>
        <Form.Control
          type="text"
          placeholder="e.g. EV23SC001"
          style={{
            height: '25px',
            fontSize: '10px',
            borderRadius: '4px',
          }}
        />
      </Form.Group> */}
      <div style={{ marginBottom: '12px' }}>
        <FilterSubHeader>Last Touched</FilterSubHeader>
        <div style={{ maxHeight: '1.25rem' }}>
          <input
            type="radio"
            id="gte"
            name="touch-comparison"
            value="gte"
            checked={filter.lastTouch[0] === 'gte'}
            // disabled={
            //   filter.capacity[0] ? filter.capacity[0] !== 'gte' : false
            // }
            onClick={e => {
              setFilter({
                ...filter,
                lastTouch:
                  filter.lastTouch[0] === 'gte'
                    ? ['', filter.lastTouch[1]]
                    : ['gte', filter.lastTouch[1]],
              })
              handlePipelineFilter({
                ...filter,
                lastTouch:
                  filter.lastTouch[0] === 'gte'
                    ? ['', filter.lastTouch[1]]
                    : ['gte', filter.lastTouch[1]],
              })
            }}
          />
          <label
            htmlFor="gte"
            style={{
              marginLeft: '4px',
              position: 'relative',
              bottom: '3px',
              fontSize: '10px',
            }}
          >
            At least
          </label>
        </div>
        <div style={{ maxHeight: '1.25rem' }}>
          <input
            type="radio"
            id="lte"
            name="touch-comparison"
            value="lte"
            checked={filter.lastTouch[0] === 'lte'}
            // disabled={
            //   filter.capacity[0] ? filter.capacity[0] !== 'lte' : false
            // }
            onClick={e => {
              setFilter({
                ...filter,
                lastTouch:
                  filter.lastTouch[0] === 'lte'
                    ? ['', filter.lastTouch[1]]
                    : ['lte', filter.lastTouch[1]],
              })
              handlePipelineFilter({
                ...filter,
                lastTouch:
                  filter.lastTouch[0] === 'lte'
                    ? ['', filter.lastTouch[1]]
                    : ['lte', filter.lastTouch[1]],
              })
            }}
          />
          <label
            htmlFor="lte"
            style={{
              marginLeft: '4px',
              position: 'relative',
              bottom: '3px',
              fontSize: '10px',
            }}
          >
            At most
          </label>
        </div>
        <div style={{ maxHeight: '1.25rem' }}>
          <input
            type="radio"
            id="eq"
            name="touch-comparison"
            value="eq"
            checked={filter.lastTouch[0] === 'eq'}
            // disabled={
            //   filter.capacity[0] ? filter.capacity[0] !== 'eq' : false
            // }
            onClick={e => {
              setFilter({
                ...filter,
                lastTouch:
                  filter.lastTouch[0] === 'eq'
                    ? ['', filter.lastTouch[1]]
                    : ['eq', filter.lastTouch[1]],
              })
              handlePipelineFilter({
                ...filter,
                lastTouch:
                  filter.lastTouch[0] === 'eq'
                    ? ['', filter.lastTouch[1]]
                    : ['eq', filter.lastTouch[1]],
              })
            }}
          />
          <label
            htmlFor="eq"
            style={{
              marginLeft: '4px',
              position: 'relative',
              bottom: '3px',
              fontSize: '10px',
            }}
          >
            Equals
          </label>
        </div>
        <input
          type="number"
          placeholder="i.e. Days since last touch"
          style={{
            width: '100%',
            height: '20px',
            fontSize: '10px',
            padding: '2px 3px',
          }}
          value={filter.lastTouch[1]}
          onChange={e => {
            setFilter({
              ...filter,
              lastTouch: [filter.lastTouch[0], e.target.value],
            })
            const timeout = setTimeout(() => {
              handlePipelineFilter({
                ...filter,
                lastTouch: [filter.lastTouch[0], e.target.value],
              })
            }, 1000)
            return () => clearTimeout(timeout)
          }}
        />
      </div>
    </FilterContainer>
  )
}

PipelineFilter.propTypes = {
  handlePipelineFilter: PropTypes.func,
}
