import React from 'react'
import PropTypes from 'prop-types'

const getSeparators = () => {
  const separators = parseFloat(111000.11)
    .toLocaleString('en-US')
    .match(/[^\d]/g)
  return { thousandsSeparator: separators[0], decimalSeparator: separators[1] }
}

const testNaN = n => Number.isNaN(Number(n))

const localeString = n =>
  (Math.round((parseFloat(n) + Number.EPSILON) * 100) / 100).toLocaleString(
    'en-US'
  )
/* Removed decimals
const formatDecimalPart = (value, decimalSeparator) => {
  const stringValue = localeString(value)
  const parts = stringValue.split(decimalSeparator)
  if (parts.length === 1) return '00'
  const decimalPart = parts[1]
  if (decimalPart.length >= 2) return decimalPart.slice(0, 2)
  if (decimalPart.length === 0) return '00'
  return `${decimalPart}0`
}*/

const formatIntegerPart = (value, thousandsSeparator, decimalSeparator) => {
  const stringValue = localeString(value)
  const parts = stringValue.split(decimalSeparator)
  const integerPart = parts[0].replace(/[^\d]/g, '')

  let formattedInteger = ''
  if (integerPart.length > 3) {
    let reminder = integerPart
    while (reminder.length > 3) {
      const tail = reminder.slice(-3, Number.POSITIVE_INFINITY)
      formattedInteger = `${thousandsSeparator}${tail}${formattedInteger}`
      reminder = reminder.slice(0, -3)
    }
    formattedInteger = `${reminder}${formattedInteger}`
  } else {
    formattedInteger = integerPart
  }
  return formattedInteger
}

export const formatValue = (
  value,
  minFractionDigits = 0,
  maxFractionDigits,
  currency = false
) => {
  const isNaN = testNaN(value)
  if (isNaN) return ''
  const formatter = new Intl.NumberFormat('en-US', {
    style: currency ? 'currency' : 'decimal',
    currency: 'USD',

    // These options enable rounding as followsd
    minimumFractionDigits: minFractionDigits, // Will represent 2500.10 as 2,500.1
    maximumFractionDigits: maxFractionDigits, // Will represent 2500.99 as 2,501
  })

  return formatter.format(value)
}

const Money = ({ value }) => {
  const formattedValue = formatValue(value, 0, 0, true)
  return <>{formattedValue}</>
}

Money.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
}

export default Money
