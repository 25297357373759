import { Outlet } from 'react-router-dom'
import styled from 'styled-components'
import MenuBar from '../MenuBar/MenuBar'
import TopBar from '../TopBar/TopBar'

const OuterWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  overflow-x: hidden;
  overflow-y: auto;
`

const InnerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0 auto;
  padding: 0;
  border: none;
  justify-content: flex-end;
`

const OutletWrapper = styled.div`
  width: calc(100% - 95px);
  height: 100%;
  overflow-x: scroll;
`

const MainLayout = () => (
  <OuterWrapper>
    <InnerWrapper>
      <MenuBar />
      <OutletWrapper>
        <TopBar />
        <Outlet />
      </OutletWrapper>
    </InnerWrapper>
  </OuterWrapper>
)

export default MainLayout
