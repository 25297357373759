import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Stack } from 'react-bootstrap'
import InfoTooltip from '../ui-elements/InfoTooltip'
import infoCircle from '../../assets/icons/info-circle.png'
import {
  getAttendeesCount,
  getSpecialGuestCount,
} from '../../utils/dataModels/events'

const AttendeesInfoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
`

const AttendeesInfo = ({ event, consolidated }) => {
  const [isAtendeesMouseOver, setAtendeesMouseOver] = useState(false)
  const [tooltipOffset, setTooltipOffset] = useState({ x: 0, y: 0 })

  const atendeesMouseOverHandler = e => {
    setTooltipOffset({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
    setAtendeesMouseOver(true)
  }
  const atendeesMouseOutHandler = () => setAtendeesMouseOver(false)
  const totalAttendeeCount = event
    ? event.tiers.reduce((tieredGuestsCount, currentTier) => {
        return tieredGuestsCount + currentTier.participants.length
      }, 0) + event.donations.filter(d => d.donor).length
    : 0

  return (
    <AttendeesInfoWrapper
      onMouseEnter={atendeesMouseOverHandler}
      onMouseLeave={atendeesMouseOutHandler}
    >
      <span>{totalAttendeeCount}</span>
      &nbsp;
      <img src={infoCircle} width="16" />
      {isAtendeesMouseOver && (
        <AttendeesTooltip
          event={event}
          offset={tooltipOffset}
          consolidated={consolidated}
          totalAttendees={totalAttendeeCount}
        />
      )}
    </AttendeesInfoWrapper>
  )
}
AttendeesInfo.propTypes = {
  event: PropTypes.object,
  consolidated: PropTypes.object,
}

const AttendeesTooltip = ({ consolidated, event, offset, totalAttendees }) => {
  return (
    <InfoTooltip offset={offset}>
      <Stack>
        <Stack direction="horizontal" gap={3}>
          <span className="fw-bold">Donors:</span>
          <span>{event.donations ? event.donations.length : 0}</span>
        </Stack>
        <Stack direction="horizontal" gap={3}>
          <span className="fw-bold">Special&nbsp;guests:</span>
          <span>{getSpecialGuestCount(consolidated)}</span>
        </Stack>
        <Stack direction="horizontal" gap={3}>
          <span className="fw-bold">Total&nbsp;atendees:</span>
          <span>{totalAttendees}</span>
        </Stack>
      </Stack>
    </InfoTooltip>
  )
}
AttendeesTooltip.propTypes = {
  consolidated: PropTypes.object,
  event: PropTypes.object,
  offset: PropTypes.object,
  totalAttendees: PropTypes.number,
}

export default AttendeesInfo
