import React from 'react'
import { useAsyncDebounce } from 'react-table'
import { matchSorter } from 'match-sorter'
import moment from 'moment'
import { isEmpty, isNil } from 'ramda'
const _ = require('lodash')

const DATE_FORMAT = 'YYYY-MM-DD'

const FILTER_SETTER_DEBOUNCE_INTERVAL_MSEC = 500

const INITIAL_FILTERS = {
  donationDate: {},
  source: {},
  donationAmount: {},
  name: {},
}

class Filters {
  constructor() {
    this.filters = Object.assign({}, INITIAL_FILTERS)
    this.setFilterValues = () => {
      console.warn('Filter setter function not set')
    }
    this.setSearchValue = () => {
      console.warn('Search value setter function not set')
    }
    this.donationAmountFilter = {}
  }

  clearFilters = () => {
    this.filters = Object.assign({}, INITIAL_FILTERS)
    this.setSearchValue('')
  }

  mergeFilter = filter => {
    this.filters = Object.assign(this.filters, filter)
    const mainFilter = {}
    Object.values(this.filters).forEach(v => {
      if (!isEmpty(v)) {
        Object.assign(mainFilter, v)
      }
    })
    this.setFilterValues(mainFilter)
  }

  setFilterValuesSetterFunction = func => {
    this.setFilterValues = _.debounce(
      func,
      FILTER_SETTER_DEBOUNCE_INTERVAL_MSEC
    )
  }

  setSearchValueSetterFunction = func => {
    this.setSearchValue = _.debounce(func, FILTER_SETTER_DEBOUNCE_INTERVAL_MSEC)
  }

  GlobalFilter = props => {
    const { preGlobalFilteredRows, globalFilter, setGlobalFilter } = props
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
    }, 200)

    return (
      <span>
        Search...{' '}
        <input
          value={value || ''}
          onChange={e => {
            setValue(e.target.value)
            onChange(e.target.value)
          }}
          placeholder={`${count} records...`}
          style={{
            fontSize: '1.1rem',
            border: '0',
          }}
        />
      </span>
    )
  }

  DonorColumnFilter = ({ column: { filterValue, setFilter } }) => {
    const filterName = val => {
      this.setSearchValue(val)
    }
    return (
      <>
        <input
          value={filterValue || ''}
          style={{ width: '90%' }}
          onChange={async e => {
            if (isNil(e.target.value) || isEmpty(e.target.value)) {
              this.setSearchValue('')
              setFilter(e.target.value)
            } else {
              setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
              filterName(e.target.value)
            }
          }}
          placeholder={`First or last name (* as wildcard)`}
        />
        {/* <button
          style={{ marginLeft: '5px' }}
          onClick={() => filterName()}
          disabled={
            isNil(filterValue) || isEmpty(filterValue) ? 'disabled' : ''
          }
        >
          Find
        </button> */}
      </>
    )
  }

  SelectColumnFilter = (options, filterName) => {
    const func = ({ column: { filterValue, setFilter, id } }) => {
      return (
        <select
          style={{ width: '90%' }}
          disabled={isEmpty(options) ? 'disabled' : ''}
          value={filterValue}
          onChange={e => {
            const val = e.target.value
            setFilter(val)
            this.mergeFilter(
              val === 'all'
                ? { [filterName]: {} }
                : { [filterName]: { [id]: e.target.value } }
            )
          }}
        >
          <option value="all">{isEmpty(options) ? 'Select...' : 'All'}</option>
          {Object.entries(options).map(([k, v], i) => (
            <option key={i} value={v}>
              {k}
            </option>
          ))}
        </select>
      )
    }
    return func
  }

  SliderColumnFilter = ({
    column: { filterValue, setFilter, preFilteredRows, id },
  }) => {
    // if (filterValue) filterValue = Number(filterValue.replace(/[^0-9.-]+/g, ''))

    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
      preFilteredRows.forEach(row => {
        min = Math.min(row.values[id], min)
        max = Math.max(row.values[id], max)
      })
      return [min, max]
    }, [id, preFilteredRows])

    return (
      <>
        <input
          type="range"
          min={min}
          max={max}
          value={filterValue || min}
          onChange={e => {
            setFilter(parseInt(Number(e.target.value), 10))
          }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
      </>
    )
  }

  NumberRangeColumnFilter = (filterName, dataRange) => {
    const func = ({ column: { setFilter, filterValue = [], id } }) => {
      const { min, max } = dataRange
      this[`${filterName}Filter`] = this[`${filterName}Filter`] || {}
      const filterVals = this[`${filterName}Filter`]
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <input
            value={filterValue[0] || ''}
            type="number"
            onChange={e => {
              const val = e.target.value
              let query = { [id]: {} }
              setFilter((old = []) => [
                val ? parseInt(val, 10) : undefined,
                old[1],
              ])
              if (e.target.value !== '') {
                filterVals.min = e.target.value
              } else {
                delete filterVals.min
              }
              if (!filterVals.min && !filterVals.max)
                this.mergeFilter({ [filterName]: {} })
              else {
                if (filterVals.min) query[id].$gte = parseInt(filterVals.min)
                if (filterVals.max) query[id].$lt = parseInt(filterVals.max) + 1
                this.mergeFilter({ [filterName]: query })
              }
            }}
            placeholder={isNil(min) ? 'min' : `${min}`}
            style={{
              width: '130px',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
            }}
          />
          to
          <input
            value={filterValue[1] || ''}
            type="number"
            onChange={e => {
              const val = e.target.value
              let query = { [id]: {} }
              setFilter((old = []) => [
                old[0],
                val ? parseInt(val, 10) : undefined,
              ])
              if (e.target.value !== '') {
                filterVals.max = e.target.value
              } else {
                delete filterVals.max
              }
              if (!filterVals.min && !filterVals.max)
                this.mergeFilter({ [filterName]: {} })
              else {
                if (filterVals.min) query[id].$gte = parseInt(filterVals.min)
                if (filterVals.max) query[id].$lt = parseInt(filterVals.max) + 1
                this.mergeFilter({ [filterName]: query })
              }
            }}
            placeholder={isNil(max) ? 'max' : `${max}`}
            style={{
              width: '130px',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
            }}
          />
        </div>
      )
    }
    return func
  }

  DateRangeColumnFilter = (filterName, dataRange) => {
    const func = ({ column: { setFilter, filterValue = [], id } }) => {
      const { min, max } = dataRange
      this[`${filterName}Filter`] = this[`${filterName}Filter`] || {}
      const filterVals = this[`${filterName}Filter`]
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <input
            value={
              filterValue[0] ? moment(filterValue[0]).format(DATE_FORMAT) : ''
            }
            type="text"
            data-date-format={DATE_FORMAT}
            onChange={e => {
              let query = { [id]: {} }
              const val = e.target.value
              setFilter((old = []) => [val ? val : undefined, old[1]])
              if (e.target.value !== '') {
                filterVals.min = e.target.value
              } else {
                delete filterVals.min
              }
              if (!filterVals.min && !filterVals.max)
                this.mergeFilter({ [filterName]: {} })
              else {
                if (filterVals.min)
                  query[id].$gte = {
                    $toDate: filterVals.min,
                  }
                if (filterVals.max) {
                  query[id].$lte = {
                    $toDate: filterVals.max,
                  }
                }
                this.mergeFilter({ [filterName]: query })
              }
            }}
            onFocus={e => {
              e.target.type = 'date'
            }}
            onBlur={e => {
              e.target.type = 'text'
              e.target['data-date-format'] = DATE_FORMAT
            }}
            placeholder={isNil(min) ? 'min' : moment(min).format(DATE_FORMAT)}
            style={{
              width: '130px',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
            }}
          />
          to
          <input
            value={
              filterValue[1] ? moment(filterValue[1]).format(DATE_FORMAT) : ''
            }
            type="text"
            data-date-format={DATE_FORMAT}
            onChange={e => {
              let query = { [id]: {} }
              const val = e.target.value
              setFilter((old = []) => [old[0], val ? val : undefined])
              if (e.target.value !== '') {
                filterVals.max = e.target.value
              } else {
                delete filterVals.max
              }
              if (!filterVals.min && !filterVals.max)
                this.mergeFilter({ [filterName]: {} })
              else {
                if (filterVals.min) {
                  query[id].$gte = {
                    $toDate: filterVals.min,
                  }
                }
                if (filterVals.max) {
                  query[id].$lte = {
                    $toDate: filterVals.max,
                  }
                }
                this.mergeFilter({ [filterName]: query })
              }
            }}
            onFocus={e => {
              e.target.type = 'date'
            }}
            onBlur={e => {
              e.target.type = 'text'
              e.target['data-date-format'] = DATE_FORMAT
            }}
            placeholder={isNil(max) ? 'max' : moment(max).format(DATE_FORMAT)}
            style={{
              width: '130px',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
            }}
          />
        </div>
      )
    }
    return func
  }

  fuzzyTextFilterFn = (rows, id, filterValue) => {
    return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
  }

  filterGreaterThan = (rows, id, filterValue) => {
    return rows.filter(row => {
      const rowValue = row.values[id]
      return rowValue >= filterValue
    })
  }

  dateBetweenFilterFn = (rows, id, filterValues) => {
    let sd = new Date(filterValues[0])
    let ed = new Date(filterValues[1])
    console.log(rows, id, filterValues)
    return rows.filter(r => {
      var time = new Date(r.values[id])
      console.log(time, ed, sd)
      if (filterValues.length === 0) return rows
      return time >= sd && time <= ed
    })
  }

  getFilterComponents = () => {
    return {
      GlobalFilter: this.GlobalFilter,
      DonorColumnFilter: this.DonorColumnFilter,
      SelectColumnFilter: this.SelectColumnFilter,
      SliderColumnFilter: this.SliderColumnFilter,
      NumberRangeColumnFilter: this.NumberRangeColumnFilter,
      DateRangeColumnFilter: this.DateRangeColumnFilter,
    }
  }

  getFilterFunctions = () => {
    return {
      fuzzyTextFilterFn: this.fuzzyTextFilterFn,
      filterGreaterThan: this.filterGreaterThan,
      dateBetweenFilterFn: this.dateBetweenFilterFn,
    }
  }
}

export default Filters
