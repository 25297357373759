import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Stack } from 'react-bootstrap'
import MultiProgressBar from './MultiProgressBar/MultiProgressBar'
import Money from './Money'
import InfoTooltip from './InfoTooltip'
import {
  getFinancialGoal,
  getTotalDeposited,
  getTotalPledged,
} from '../../utils/dataModels/events'

const AmountsWrapper = styled.div`
  position: relative;
  padding-top: 0.5rem;
  text-align: center;
  font-size: 0.75rem;
`

const Amounts = ({ raised, total }) => (
  <AmountsWrapper>
    <Money value={raised} />
    &nbsp;/&nbsp;
    <span style={{ color: '#a6b3be' }}>
      <Money value={total} />
    </span>
  </AmountsWrapper>
)
Amounts.propTypes = {
  raised: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const SummaryTooltip = ({ levels, total, offset }) => {
  return (
    <InfoTooltip offset={offset}>
      <Stack>
        {levels.map((level, index) => (
          <Stack
            key={`levels-stack-level-${index}`}
            direction="horizontal"
            gap={3}
          >
            <div className="fw-bold">{level.label}:</div>
            <Money value={level.value} />
          </Stack>
        ))}
        <Stack direction="horizontal" gap={3}>
          <div className="fw-bold">{total.label}:</div>
          <Money value={total.value} />
        </Stack>
      </Stack>
    </InfoTooltip>
  )
}

SummaryTooltip.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.object).isRequired,
  total: PropTypes.object.isRequired,
  offset: PropTypes.object.isRequired,
}

const Wrapper = styled.div`
  position: relative;
  width: 13rem;
`

const EventProgressBar = ({ event, consolidated }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltipOffset, setTooltipOffset] = useState({ x: 0, y: 0 })
  const goal = getFinancialGoal(event)
  // const deposited = getTotalDeposited(consolidated)
  // const outstanding = getTotalPledged(consolidated)
  const total = {
    label: 'Total',
    color: '#e6e6e6',
    value: goal,
  }

  const mouseOverHandler = e => {
    if (!showTooltip)
      setTooltipOffset({ x: e.nativeEvent.offsetX, y: e.nativeEvent.offsetY })
    setShowTooltip(true)
  }

  const mouseOutHandler = () => setShowTooltip(false)

  const totalPledged = event
    ? event.donations
        .filter(d => d.donation_type === 'Pledge')
        .reduce((acc, curr) => acc + curr.donation_total, 0)
    : 0

  const totalDeposited = event
    ? event.donations
        .filter(d => d.donation_type === 'Deposit')
        .reduce((acc, curr) => acc + curr.donation_total, 0)
    : 0

  const levels = [
    {
      label: 'Deposited',
      color: '#0c243c',
      value: totalDeposited,
    },
    {
      label: 'Outstanding',
      color: '#00bdb2',
      value: totalPledged,
    },
  ]

  return (
    <Wrapper onMouseOver={mouseOverHandler} onMouseOut={mouseOutHandler}>
      <MultiProgressBar total={total} levels={levels} />
      <Amounts raised={totalDeposited + totalPledged} total={goal} />
      {showTooltip && (
        <SummaryTooltip offset={tooltipOffset} total={total} levels={levels} />
      )}
    </Wrapper>
  )
}

EventProgressBar.propTypes = {
  event: PropTypes.object.isRequired,
  consolidated: PropTypes.object.isRequired,
}

export default EventProgressBar
