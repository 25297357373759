import { ReactNode } from 'react'
import { Form, FormCheckProps } from 'react-bootstrap'
import RedAsterisk from '../../ui-elements/RedAsterisk'
import { Path, UseFormReturn } from 'react-hook-form'
import { ObjectSchema, AnyObject } from 'yup'
import { isFieldRequired, isValid } from '../utils'

// To FormCheckProps Read https://react-bootstrap.github.io/forms/checks-radios/
interface CheckFormProps<T extends AnyObject>
  extends Omit<FormCheckProps, 'id' | 'type' | 'required'> {
  id: keyof T
  hookForm: UseFormReturn<T>
  validationSchema: ObjectSchema<T>
  label?: string
  labelClassName?: string
  labelComponent?: ReactNode
  containerClassName?: string
}

export function CheckForm<T extends AnyObject>({
  id,
  hookForm,
  validationSchema,
  label,
  labelComponent,
  labelClassName = 'form-check-label',
  containerClassName = '',
  ...rest
}: CheckFormProps<T>) {
  const { formState, register, getValues } = hookForm
  const { errors } = formState
  const registerId = id as Path<T>

  const noLabel = !label
  const noLabelComponent = !labelComponent

  return (
    <Form.Group className={containerClassName}>
      <Form.Check
        id={String(id)}
        isInvalid={!!errors[id]}
        isValid={isValid(id, formState)}
        {...rest}
        {...register(registerId)}
        label={
          <>
            {label && noLabelComponent && (
              <Form.Label bsPrefix={labelClassName}>
                {label}
                {isFieldRequired(validationSchema, id, getValues) && (
                  <RedAsterisk />
                )}
              </Form.Label>
            )}
            {noLabel && labelComponent && labelComponent}
          </>
        }
      />

      {errors[id]?.message && (
        <Form.Control.Feedback type="invalid" className="d-inline">
          {String(errors[id]?.message)}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
export default CheckForm
