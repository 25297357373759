/*
Feel free to read this Official API Documentation 
https://developer.mozilla.org/en-US/docs/Web/API/Navigator/mediaDevices */

/**
 * Return Promise<void>.
 *
 * @param {function} setRecorderState represents an updated function to set recorderState.
 */
export async function startRecording(setRecorderState) {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
    setRecorderState(prevState => {
      return {
        ...prevState,
        initRecording: true,
        mediaStream: stream,
      }
    })
  } catch (err) {
    console.log(err)
  }
}

/**
 * Return void.
 *
 * @param {function} recorder represents the recorderState.
 */
export function saveRecording(recorder) {
  if (recorder.state !== 'inactive') recorder.stop()
}
