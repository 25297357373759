import moment from 'moment'
import { getDatetime, getEventId, getName } from './events'

export const eventToTask = event => {
  return {
    type: event.type || 'event',
    id: getEventId(event),
    title: getName(event),
    date: moment(getDatetime(event)),
    duration: 60,
    allDay: false,
    completed: false,
    img: 'fas fa-igloo',
    notes: '',
    event,
  }
}
