import React, { FC, useLayoutEffect, useState } from 'react'
import { convertToRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import draftToHtml from 'draftjs-to-html'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './EditorConvertToHTML.css'

export interface EditorConvertToHTMLProps {
  toolbarCustomButtons?: React.ReactElement<
    HTMLElement,
    string | React.JSXElementConstructor<unknown>
  >[]
  useEditorState?: [
    EditorState,
    React.Dispatch<React.SetStateAction<EditorState>>
  ]
  mention?: object
  onChange: (htmlString: string) => void
}

interface ToolBarButtonStyle {
  class: string
  title: string
}
const toolbarButtons: ToolBarButtonStyle[] = [
  { class: '.rdw-colorpicker-wrapper', title: 'Text Color' },
]
const EditorConvertToHTML: FC<EditorConvertToHTMLProps> = ({
  toolbarCustomButtons,
  useEditorState,
  mention,
  onChange,
}) => {
  const [editorState, setEditorState] =
    useEditorState || useState(EditorState.createEmpty())

  const changeTitleHoverTo = (toolbarButton: ToolBarButtonStyle) => {
    const toolbarButtonRef = document.querySelector(
      toolbarButton.class
    ) as HTMLDivElement

    if (toolbarButtonRef) {
      toolbarButtonRef.title = toolbarButton.title
    }
  }

  useLayoutEffect(() => {
    toolbarButtons.map(toolbarButton => changeTitleHoverTo(toolbarButton))
  }, [])

  const onEditorStateChange = (editorState: EditorState) => {
    onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    setEditorState(editorState)
  }

  return (
    <div>
      <Editor
        mention={mention}
        toolbar={{
          options: ['inline', 'fontSize', 'fontFamily', 'colorPicker'],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline'],
          },
          colorPicker: {
            className: 'myclass',
            component: undefined,
            popupClassName: undefined,
          },
        }}
        toolbarCustomButtons={toolbarCustomButtons}
        editorState={editorState}
        wrapperClassName="demo-wrapper form-control"
        editorClassName="demo-editor"
        onEditorStateChange={onEditorStateChange}
      />
    </div>
  )
}

export default EditorConvertToHTML
