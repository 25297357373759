import axios from 'axios'
import constants from '../config/constants'

const axiosInstance = axios.create({
  baseURL: `${constants.BASE_ENDPOINT()}/api/b`,
  headers: {
    //  Authorization: `<Your Auth Token>`,
    'Content-Type': 'application/json',
    'auth-domain': 'benjamin',
    timeout: 1000,
  },
  // .. other options
})

export default axiosInstance
