const routes = [
  { path: '/login' },
  { path: '/calendar' },
  { path: '/events/all' },
  { path: '/events' },
  { path: '/contacts/' },
  { path: '/contacts/uploadfile' },
  { path: '/pipeline/' },
  { path: '/contact' },
  { path: '/invite' },
  { path: '/mailbox' },
  { path: '/mailbox' },
  { path: '/events/create' },
  { path: '/events' },
  { path: '/events/create' },
  { path: '/events/edit' },
  { path: '/admin/staffers' },
  { path: '/admin/bundlers' },
  { path: '/admin/organizations' },
  { path: '/admin/organizations' },
  { path: '/admin/organizations/create' },
  { path: '/admin/organizations/edit' },
  { path: '/reports' },
  { path: '/call-portal/video-call' },
]

export default routes
