import { FC, useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Filter, FilterQuery, ModalFilterContactsProps } from './types'
import { IContact, KeyValue, IDonor } from '../../../api/interfaces'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import FilterContactForm from './FilterContact/FilterContactForm'
import FilterContactResults from './FilterResults/FilterContactResults'
import ContactApi from '../../../api/contact'
import DonationsApi from '../../../api/donation'

import { useSelector } from 'react-redux'
import { getUser, getUserOrganizationId } from '../../../utils/state/selectors'
import {
  addFieldToAggregate,
  matchQueryBasedOnUserRol,
} from '../../../utils/state/roleTests'
import { addToast, removeToast } from '../../../redux/toasts/toasts'
import { useDispatch } from 'react-redux'

const dialogToastId = 'tw-modalFilter'
const ModalFilterContacts: FC<ModalFilterContactsProps> = ({
  initialQueryFilter,
  show,
  handleClose,
  handleApply,
}) => {
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const [isFiltering, setIsFiltering] = useState<boolean>(false)
  const [queryFilter, setQueryFilter] = useState<FilterQuery>()
  const [filteredContacts, setFilteredContacts] = useState<IContact[]>([])
  const [checkedContacts, setCheckedContacts] = useState<IContact[]>([])
  const [contacts, setContacts] = useState<IContact[]>([])
  const orgId = useSelector(getUserOrganizationId)
  const fetchFilterDonations = (query: KeyValue = {}) =>
    DonationsApi.filterDonations([{ $match: query }]) // https://www.mongodb.com/docs/manual/aggregation/

  // const filterContactsByDonations = (
  //   contacts: IContact[],
  //   event?: string,
  //   donationType?: string
  // ) => {
  //   if (!event && !donationType) {
  //     return new Promise<IContact[]>(resolve => resolve(contacts))
  //   }
  //   return fetchFilterDonations({
  //     ...(event && { eventId: event }),
  //     ...(donationType && {
  //       type: donationType.toLocaleLowerCase(),
  //     }),
  //   }).then(donations =>
  //     contacts.filter(contact =>
  //       donations.find(donation => donation.donorId === contact._id)
  //     )
  //   )
  // }

  const fetchEventParticipants = (event: string, donation: string) =>
    DonationsApi.getEventParticipants(orgId, event, donation)

  const filterContactsByDonations = async (
    contacts: IContact[],
    event: string,
    donation: string
  ) => {
    if (!event && !donation) {
      return new Promise<IContact[]>(resolve => resolve(contacts))
    }
    return await fetchEventParticipants(event, donation).then(donors =>
      contacts.filter(contact =>
        donors.find((donor: IDonor) => donor.id === contact._id)
      )
    )
  }

  const fetchFilterContacts = (
    query: Partial<FilterQuery>,
    didMount?: boolean
  ) => {
    const { event, donationType, name, tags = [], ...rest } = query

    ContactApi.filterContacts([
      ...addFieldToAggregate(),
      {
        $match: {
          ...rest,
          ...matchQueryBasedOnUserRol(user),
          ...(tags.length && { tags: { $in: tags } }),
        },
      },
    ])
      .then((contacts: IContact[]) => {
        if (didMount) {
          setContacts(contacts)
        }
        return filterContactsByDonations(
          contacts,
          event || '',
          donationType?.toLocaleLowerCase() || ''
        )
      })
      .then((contacts: IContact[]) => {
        let filteredByName = contacts
        if (name && name.length > 0) {
          filteredByName = filteredByName.filter(contact =>
            name.includes(`${contact.first_name} ${contact.last_name}`)
          )
        }
        return setFilteredContacts(filteredByName)
      })
      .finally(() => setIsFiltering(false))
  }

  const noEmptyValues = (queryFilter?: Omit<FilterQuery, 'name'>) => {
    if (!queryFilter) return {}
    return Object.keys(queryFilter).reduce<Partial<FilterQuery>>(
      (prev, currKey) => {
        const key = currKey as keyof Omit<FilterQuery, 'name'>
        const keyHasValue = queryFilter[key]
        if (key !== 'tags') {
          if (keyHasValue) prev[key] = queryFilter[key]
        } else {
          if (keyHasValue && keyHasValue.length) prev[key] = queryFilter[key]
        }

        return prev
      },
      {}
    )
  }

  useEffect(() => {
    setQueryFilter(initialQueryFilter)
    fetchFilterContacts(noEmptyValues(initialQueryFilter), true)
  }, [])

  useEffect(() => {
    setQueryFilter(queryFilter)
    fetchFilterContacts(noEmptyValues(queryFilter))
  }, [queryFilter])

  const onSearchClickHandler = () => {
    setIsFiltering(true)
    if (queryFilter) {
      fetchFilterContacts(noEmptyValues(queryFilter))
    }
  }

  const thereAreEmptyEmails = (
    withEmptyEmail: IContact[],
    withNoEmptyEmail: IContact[]
  ) => {
    dispatch(
      addToast({
        id: dialogToastId,
        persist: true,
        position: 'top-center',
        title: 'Warning Message',
        message: (
          <div className="overflow-scroll" style={{ height: '400px' }}>
            <p>
              The following people do not have an email address in their contact
              record. Would you like to proceed with the mail merge without
              these contacts?
              <br />
              <strong>
                {withEmptyEmail
                  .map(contact => `${contact.first_name} ${contact.last_name}`)
                  .join(',')}
              </strong>
            </p>
          </div>
        ),
        buttons: (
          <Modal.Footer>
            <div className="d-flex gap-2">
              <Button
                variant="outline-secondary"
                onClick={() => {
                  handleCloseDialog()
                  callHandleApply(withNoEmptyEmail)
                }}
              >
                Yes
              </Button>
              <Button variant="outline-primary" onClick={handleCloseDialog}>
                No
              </Button>
            </div>
          </Modal.Footer>
        ),
      })
    )
  }
  const handleCloseDialog = () => {
    dispatch(removeToast({ id: dialogToastId }))
  }

  const callHandleApply = (contacts: IContact[]) => {
    handleApply(contacts, queryFilter)
  }

  const transformQueryFilterToFilter = (
    queryFilter?: FilterQuery
  ): Filter | undefined => {
    if (!queryFilter) return undefined
    const { state, city, tags, ...others } = queryFilter
    return {
      ...others,
      state: state ? [state] : [],
      city: city ? [city] : [],
      tags: tags || [],
    }
  }

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filter Contacts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FilterContactForm
            initialState={transformQueryFilterToFilter(initialQueryFilter)}
            contacts={contacts}
            onFilterChange={setQueryFilter}
          />
          <div className="text-center">
            <Button
              disabled={isFiltering}
              variant="secondary"
              size="sm"
              onClick={onSearchClickHandler}
            >
              {isFiltering ? 'Filtering...' : 'Filter'}
            </Button>
          </div>
          <div className="mt-2">
            <FilterContactResults
              contacts={filteredContacts}
              onChange={contacts => setCheckedContacts(contacts)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={!checkedContacts.length}
            variant="secondary"
            onClick={() => {
              const withNoEmptyEmail: IContact[] = []
              const withEmptyEmail: IContact[] = []

              checkedContacts.forEach(checkedContact => {
                if (checkedContact.email) {
                  withNoEmptyEmail.push(checkedContact)
                } else {
                  withEmptyEmail.push(checkedContact)
                }
              })
              if (withEmptyEmail.length) {
                thereAreEmptyEmails(withEmptyEmail, withNoEmptyEmail)
                return
              }
              callHandleApply(checkedContacts)
            }}
          >
            Apply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalFilterContacts
