/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import ReactDomServer from 'react-dom/server'
import { Modal, Button, Form, Stack } from 'react-bootstrap'
import styled from 'styled-components'
import RecorderControls from '../AudioRecorder/recorder-controls/recorder-controls'
import useRecorder from '../AudioRecorder/hooks/useRecorder'
import RecordingsList from '../AudioRecorder/recordings-list/recordings-list'
import { sendNodeMailerEmail } from '../../api/nodemailer/nodemailer'
import UsersApi from '../../api/user'
import { USER_ROL } from '../../api/enums'
import { IContact } from '../../api/interfaces'

const RedAst = () => <span style={{ color: 'red' }}>*</span>

const Wrapper = styled.div`
  margin: 0;
  padding: 0;
  & .modal-body,
  & .modal-footer {
    padding: 1rem !important;
  }
`

interface ContactCallModal {
  show: boolean
  onHide: () => void
  onCall: () => void
  contact: IContact
}

const ContactCallModal: React.FC<ContactCallModal> = ({
  show,
  onHide,
  onCall,
  contact,
}) => {
  const name = `${contact.first_name} ${contact.last_name}`
  const { recorderState, resetRecorderState, ...handlers } = useRecorder()
  const { audio } = recorderState

  const [isSending, setIsSending] = useState(false)
  const initialState = {
    ask: `Donate to our cause
Host an event
Help me recruit other financial executives`,
    callDuration: '00 hours 06 minutes 30 seconds',
    voiceMemo: null,
    finalNotes: '',
  }
  const [formState, setFormState] = useState(initialState)

  useEffect(() => {
    setFormState({ ...formState, voiceMemo: audio })
  }, [audio])

  const [isCalling, setIsCalling] = useState(false)

  const setFormValue = (e: React.ChangeEvent<unknown> | undefined) =>
    e &&
    setFormState({
      ...formState,
      [(e.target as HTMLFormElement).name]: (e.target as HTMLFormElement).value,
    })

  const beforeToHide = () => {
    resetRecorderState()
    onHide()
  }

  const callNowHandleClick = (
    event: React.MouseEvent<HTMLButtonElement> | undefined
  ) => {
    if (event) event.currentTarget.disabled = true
    onCall()
    setTimeout(() => {
      if (event && event.currentTarget) event.currentTarget.disabled = false
      setIsCalling(true)
    }, 2000)
  }

  const onSubmitCallReport = async () => {
    setIsSending(true)
    const { callDuration, ask, voiceMemo, finalNotes } = formState

    const isValidForm = !!ask && !!callDuration
    if (isValidForm) {
      const bodyFormData = new FormData()
      if (voiceMemo) {
        const blobVoiceMemo = await fetch(voiceMemo).then(r => r.blob())
        bodyFormData.append('files', blobVoiceMemo, 'voicememo.mp3')
      }

      const user = await UsersApi.getUsers(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        sessionStorage.getItem('Org-Session')!,
        USER_ROL.OWNER
      )

      const html = (
        <div>
          <strong>Call Duration</strong>
          <p>${callDuration}</p>
          <strong>Ask</strong>
          {ask.split('\n').map((line, i) => (
            <p key={i}>{line}</p>
          ))}
          <strong>Final Notes</strong>
          <p>${finalNotes || ''}</p>
        </div>
      )

      bodyFormData.append(
        'data',
        JSON.stringify({
          to: user[0].email,
          subject: `${name} Call Report`,
          text: '',
          html: ReactDomServer.renderToStaticMarkup(html),
        })
      )
      sendNodeMailerEmail(bodyFormData)
        .then(response => {
          if (response.accepted.length) {
            onHide()
            resetRecorderState()
          }
        })
        .catch(error => console.log(error))
        .finally(() => setIsSending(false))
    } else {
      setIsSending(false)
    }
  }
  return (
    <Modal
      show={show}
      onHide={beforeToHide}
      onShow={() => {
        setIsSending(false)
        setIsCalling(false)
        setFormState({ ...initialState })
      }}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Wrapper>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Call {name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: '1rem !important' }}>
          <Form>
            <Form.Group style={{ marginBottom: '.8rem' }}>
              <Form.Check
                type="switch"
                id="custom-switch"
                label="Set timer"
                defaultChecked
              />
            </Form.Group>
            <Form.Group className="mb-4" controlId="formBasicPassword">
              <Form.Label>
                Call duration <RedAst />
              </Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                placeholder="duration"
                name="callDuration"
                defaultValue={formState.callDuration}
                value={formState.callDuration}
                onChange={setFormValue}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>
                Ask <RedAst />
              </Form.Label>
              <Stack gap={2} direction="horizontal">
                <Form.Control
                  as="textarea"
                  placeholder="Enter requests made during the call"
                  rows={3}
                  name="ask"
                  defaultValue={formState.ask}
                  value={formState.ask}
                  onChange={setFormValue}
                />
              </Stack>
            </Form.Group>
            {isCalling && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Voice Memo</Form.Label>
                  <Stack gap={2} direction="horizontal">
                    <div className="form-control">
                      {audio ? (
                        <RecordingsList
                          audio={audio}
                          resetRecorderState={resetRecorderState}
                        />
                      ) : (
                        <RecorderControls
                          recorderState={recorderState}
                          handlers={handlers}
                        />
                      )}
                    </div>
                  </Stack>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Notes</Form.Label>
                  <Stack gap={2} direction="horizontal">
                    <Form.Control
                      as="textarea"
                      placeholder="Enter notes here"
                      rows={3}
                      defaultValue={''}
                      name="finalNotes"
                      value={formState.finalNotes}
                      onChange={setFormValue}
                    />
                  </Stack>
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={beforeToHide} variant="light">
            Cancel
          </Button>
          {isCalling ? (
            <>
              <Button
                onClick={onSubmitCallReport}
                variant="secondary"
                style={{ marginRight: '0' }}
                disabled={
                  isSending || !formState.ask || !formState.callDuration
                }
              >
                {isSending ? 'Saving Report...' : 'Save Call Report'}
              </Button>
            </>
          ) : (
            <Button
              onClick={callNowHandleClick}
              variant="secondary"
              style={{ marginRight: '0' }}
            >
              Call now
            </Button>
          )}
        </Modal.Footer>
      </Wrapper>
    </Modal>
  )
}

export default ContactCallModal
