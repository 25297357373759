import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { nanoid } from 'nanoid'
import { ToastStateItem, ToastState } from './type'

const initialState: ToastState = {
  list: [],
}

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    // action: function
    // PayloadAction<payloadType, type>
    addToast: (state, action: PayloadAction<ToastStateItem, string>) => {
      const noPersist = !action.payload.persist
      const defaultDelay = 2000
      const delay = noPersist ? action.payload.delay || defaultDelay : undefined
      const id = action.payload.id || nanoid()
      state.list.push({ ...action.payload, id, delay })
    },
    removeToast: (state, action: PayloadAction<{ id: string }, string>) => {
      const index = state.list.findIndex(
        toast => toast.id === action.payload.id
      )
      if (index !== -1) {
        state.list.splice(index, 1)
      }
    },
  },
})

export const { addToast, removeToast } = toastSlice.actions
export default toastSlice.reducer
