import { FaMicrophone, FaStopCircle, FaTimes } from 'react-icons/fa'
import PropTypes from 'prop-types'
import './recorder-controls.css'
import { formatMinutes, formatSeconds } from '../utils/format-time'

const RecorderControls = ({ recorderState, handlers }) => {
  const { recordingMinutes, recordingSeconds, initRecording } = recorderState
  const { startRecording, saveRecording, cancelRecording } = handlers

  return (
    <div className="controls-container">
      <div className="recorder-display">
        <div className="recording-time">
          {initRecording && <div className="recording-indicator"></div>}
          <span>{formatMinutes(recordingMinutes)}</span>
          <span>:</span>
          <span>{formatSeconds(recordingSeconds)}</span>
        </div>
        {initRecording && (
          <div className="cancel-button-container">
            <div
              className="cancel-button"
              title="Cancel recording"
              onClick={cancelRecording}
            >
              <FaTimes size="1.3rem" />
            </div>
          </div>
        )}
      </div>
      <div className="start-button-container">
        {initRecording ? (
          <div
            className="start-button"
            title="Save recording"
            disabled={recordingSeconds === 0}
            onClick={saveRecording}
          >
            <FaStopCircle size="1.3rem" />
          </div>
        ) : (
          <div
            className="start-button"
            title="Start recording"
            onClick={startRecording}
          >
            <FaMicrophone size="1.3rem" />
          </div>
        )}
      </div>
    </div>
  )
}

RecorderControls.propTypes = {
  recorderState: PropTypes.object,
  handlers: PropTypes.object,
}
export default RecorderControls
