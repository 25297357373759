import { ObjectSchema, AnyObject } from 'yup'
import { PatternFormat, PatternFormatProps } from 'react-number-format'
import { Path, Controller, UseFormReturn } from 'react-hook-form'
import { ReactNode } from 'react'
import classNames from 'classnames'
import { isFieldRequired, isValid } from '../utils'
import { Form } from 'react-bootstrap'
import RedAsterisk from '../../ui-elements/RedAsterisk'

// Read more https://s-yadav.github.io/react-number-format/docs/pattern_format/
interface PatternFormatFormProps<T extends AnyObject>
  extends Omit<PatternFormatProps, 'id' | 'required'> {
  id: keyof T
  hookForm: UseFormReturn<T>
  validationSchema: ObjectSchema<T>
  label?: string
  labelClassName?: string
  labelComponent?: ReactNode
  containerClassName?: string
}
export function PatternFormatForm<T extends AnyObject>({
  id,
  label,
  labelComponent,
  validationSchema,
  hookForm,
  labelClassName = '',
  containerClassName = '',
  ...rest
}: PatternFormatFormProps<T>) {
  const { formState, control, getValues } = hookForm
  const { errors } = formState
  const registerId = id as Path<T>

  const noLabel = !label
  const noLabelComponent = !labelComponent

  return (
    <Form.Group className={containerClassName}>
      {label && noLabelComponent && (
        <Form.Label htmlFor={'name'} className={labelClassName}>
          {label}
          {isFieldRequired(validationSchema, id, getValues) && <RedAsterisk />}
        </Form.Label>
      )}
      {noLabel && labelComponent && labelComponent}
      <Controller
        control={control}
        name={registerId}
        render={({ field: { onChange, onBlur, value, name, ref } }) => {
          return (
            <PatternFormat
              className={classNames('form-control', {
                'is-valid': isValid(id, formState),
                'is-invalid': !!errors[id],
              })}
              value={value}
              id={name}
              onChange={onChange}
              onBlur={onBlur}
              getInputRef={ref}
              {...rest}
            />
          )
        }}
      />
      {errors[id]?.message && (
        <Form.Control.Feedback type="invalid" className="d-inline">
          {String(errors[id]?.message)}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}

export default PatternFormatForm
