import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { isEmpty, pathOr } from 'ramda'
import { getColor, getState } from '../../utils/dataModels/events'
import GaugeGraphNoText from '../GaugeGraph/GaugeGraphNoText'
import AmountPercentDisplay from './AmountPercentDisplay'
import ImagePlaceHolder from '../ui-elements/ImagePlaceHolder'
import { useSelector } from 'react-redux'
import { getUserRole } from '../../utils/state/selectors'
import { isBundler } from '../../utils/state/roleTests'
import CenterSpinner from '../CenterSpinner/CenterSpinner'

const INITIAL_WIDTH = 76
const PLUS_PER_LETTER = 5

const ImgPlaceHolderDiv = styled.div`
  min-width: 14.5rem;
  border-radius: 0px;
  > div {
    font-size: 2.5rem;
  }
  margin-top: 1rem;
`

const Summary = ({ event }) => (
  <ImgPlaceHolderDiv className="mb-3">
    <ImagePlaceHolder
      options={{
        color: getColor(event),
        colorBarPosition: 'left',
        imageSize: 'large',
      }}
      text={getState(event)}
    />
  </ImgPlaceHolderDiv>
)

Summary.propTypes = {
  event: PropTypes.object.isRequired,
}

const GraphsContainer = styled.div`
  width: 100%;
  padding-left: 0rem;
`

const GraphContainer = styled.div`
  width: 15%;
  max-width: 120px;
  max-height: 120px;
  margin-bottom: 4px;
  margin-left: 2rem;
  /* margin-top: 0.5rem; */
  /* margin-left: 0.5rem; */
  .percent-wrapper {
    font-size: 2rem !important;
  }
`

const Grid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`

const GraphSummary = ({ consolidated, event }) => {
  const role = useSelector(getUserRole)
  const goal = pathOr(0, ['goal'], consolidated)
  const pledged = pathOr(0, ['totals', 'pledged'], consolidated)
  const deposited = pathOr(0, ['totals', 'deposited'], consolidated)
  const raised = deposited + pledged
  //const remaining = goal - deposited
  const userPledged = pathOr(0, ['userTotals', 'pledged'], consolidated)
  const userDeposited = pathOr(0, ['userTotals', 'deposited'], consolidated)

  const [minWidth, setMinWidth] = useState(INITIAL_WIDTH)
  const roundAmountDueZero = amount => {
    return amount > 0 && amount < 1 ? 1 : amount
  }

  //const percentPledged = roundAmountDueZero((100 * pledged) / goal)
  const percentDeposited = roundAmountDueZero((100 * deposited) / goal)
  //const percentRaised = parseInt(percentDeposited) + parseInt(percentPledged)
  //const percentRemaining = roundAmountDueZero(100 - parseInt(percentDeposited))

  const calculateWidth = (
    goal = 0,
    raised = 0,
    deposited = 0,
    pledged = 0,
    userDeposited = 0,
    userPledged = 0
  ) => {
    const maxLength = Math.max(
      goal.toString().length,
      raised.toString().length,
      deposited.toString().length,
      pledged.toString().length,
      userDeposited.toString().length,
      userPledged.toString().length
    )
    return maxLength * PLUS_PER_LETTER + INITIAL_WIDTH
  }

  useEffect(() => {
    setMinWidth(calculateWidth(goal, raised, deposited, pledged))
  }, [goal, raised, deposited, pledged, userDeposited, userPledged])

  return (
    <GraphsContainer>
      {isEmpty(consolidated) && <CenterSpinner />}
      {!isEmpty(consolidated) && (
        <Grid>
          <Summary event={event} />
          <GraphContainer>
            <GaugeGraphNoText
              color="#11273c"
              percent={Number.isNaN(percentDeposited) ? 0 : percentDeposited}
            />
          </GraphContainer>
          <AmountPercentDisplay
            amount={goal}
            label="Goal"
            flag={true}
            width={minWidth}
          />
          <AmountPercentDisplay
            amount={raised}
            label={<>Total&nbsp;Expect</>}
            flag={true}
            width={minWidth}
          />
          <AmountPercentDisplay
            amount={deposited}
            label="Paid"
            flag={true}
            width={minWidth}
          />
          <AmountPercentDisplay
            amount={pledged}
            label="Pledged"
            flag={isBundler(role)}
            width={minWidth}
          />
          {isBundler(role) && (
            <>
              <AmountPercentDisplay
                amount={userDeposited}
                label={<>My&nbsp;Paid</>}
                flag={true}
                width={minWidth}
              />
              <AmountPercentDisplay
                amount={userPledged}
                label={<>My&nbsp;Pledges</>}
                flag={false}
                width={minWidth}
              />
            </>
          )}
        </Grid>
      )}
    </GraphsContainer>
  )
}

GraphSummary.propTypes = {
  consolidated: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
}

export default GraphSummary
