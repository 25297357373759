import { useEffect } from 'react'
import DonorForm from '../../assets/documents/DonorForm.pdf'
import { triggerDownloadFile } from '../../utils/downloadFile'

const DownloadInvite = () => {
  useEffect(() => {
    triggerDownloadFile('DonorForm.pdf', DonorForm, true)
  }, [])

  return (
    <div className="container p-3 d-flex align-items-center  justify-content-center">
      <div className="text-center">... downloading</div>
    </div>
  )
}

export default DownloadInvite
