import { FC } from 'react'
import { IContact } from '../../api/interfaces'
import BTable from '../BTable/BTable'
import { BTableProps } from '../BTable/types'

const BContactTableContainer: FC<BTableProps<IContact>> = props => {
  return <BTable {...props} />
}

export default BContactTableContainer
