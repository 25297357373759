import styled from 'styled-components'

const Dot = styled.div`
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0.5rem;
  padding: 0;
  vertical-align: top;
  background-color: ${p => p.color};
  border-radius: 100%;
`

export default Dot
