import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Form } from 'react-bootstrap'

const AskWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 0.5rem;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`

const AskListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 0.5rem;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1rem;
  gap: 1rem;
  align-self: stretch;
`

const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
`

const AskChildren = styled.div`
  font-size: 0.875rem;
`

const Ask = ({ children }) => (
  <AskWrapper>
    <div>
      <Form.Check />
    </div>
    <AskChildren>{children}</AskChildren>
  </AskWrapper>
)

Ask.propTypes = {
  children: PropTypes.node,
}

const Asks = () => (
  <Section>
    <SectionTitle>Asks</SectionTitle>
    <AskListWrapper>
      <Ask>Donate to our case</Ask>
      <Ask>Host an event</Ask>
      <Ask>Help me recruit other financial executives</Ask>
    </AskListWrapper>
  </Section>
)

export default Asks
