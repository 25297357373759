import { useEffect } from 'react'
import axios from '../../api/axios'
import constants from '../../config/constants'
import { useDispatch } from 'react-redux'
import {
  azureLoginAction,
  finishLoginProcess,
} from '../../redux/actions/authentication'
import { useNavigate } from 'react-router-dom'
import graphMe from '../../api/microsoft/graphMe'

const OAuth2Callback = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    const currentPageParams = new URLSearchParams(window.location.search)
    const code = currentPageParams.get('code')
    const state = currentPageParams.get('state')

    if (!(state && code && code !== '' && state !== '')) {
      // this is not an azure call back
      throw new Error('invalid redirection')
    }

    const params = {
      code,
      state,
    }
    axios
      .get(`${constants.BASE_ENDPOINT()}/auth/oauth2callback`, {
        params,
      })
      .then(async tokenResponse => {
        const tokens = tokenResponse.data
        return graphMe(tokens)
      })
      .then(azurePayload => dispatch(azureLoginAction(azurePayload)))
      .catch(err => {
        console.error(err)
        navigate('/login')
      })
      .finally(() => {
        dispatch(finishLoginProcess())
        navigate('/login')
      })
  }, [])

  return <></>
}

export default OAuth2Callback
