import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import leftArrowBlack from '../../assets/icons/left-arrow-black.png'

const StyledButton = styled.button`
  margin-bottom: 1rem;
  background: none;
  border: none;
  & > img {
    width: 2rem;
  }
  &:hover {
    cursor: pointer;
  }
`

const BackButton = () => {
  const navigate = useNavigate()
  const clickHandler = () => {
    navigate(-1)
  }
  return (
    <StyledButton title="Go back" onClick={clickHandler}>
      <img src={leftArrowBlack} />
    </StyledButton>
  )
}

export default BackButton
