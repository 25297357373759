/**
 * Return void.
 *
 * @param {string} audioKey represents an specific audio key value.
 * @param {function} setRecordings represents an updater function to set recording State.
 */
export function deleteAudio(audioKey, setRecordings) {
  setRecordings(prevState =>
    prevState.filter(record => record.key !== audioKey)
  )
}
