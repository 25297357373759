import {
  SchemaDescription,
  ObjectSchema,
  AnyObject,
  Maybe,
  ValidationError,
} from 'yup'
import { FormState, UseFormGetValues } from 'react-hook-form'
import { OptionType } from './SelectForm/SelectForm'

export function noValidationRules<T extends Maybe<AnyObject>>(
  validationSchema: ObjectSchema<T>,
  id: keyof T
) {
  return !(validationSchema.describe().fields[id] as SchemaDescription).tests
    .length
}
export function isFieldRequired<T extends AnyObject>(
  validationSchema: ObjectSchema<T>,
  id: keyof T,
  getValues: UseFormGetValues<T>
): boolean {
  let isSchemaRequired = (
    validationSchema.describe().fields[id] as SchemaDescription
  ).tests.some(({ name }) => name === 'required')

  if (!isSchemaRequired) {
    try {
      validationSchema.validateSyncAt(String(id), {
        ...getValues(),
      })
    } catch (error) {
      if (error instanceof ValidationError) {
        isSchemaRequired = error.type === 'required'
      }
    }
  }
  return isSchemaRequired
}

export function isValid<T extends AnyObject>(
  id: keyof T,
  formState: FormState<T>
): boolean | undefined {
  const { errors, dirtyFields, touchedFields } = formState
  const isDirty = !!dirtyFields[id.toString()]
  const isTouched = !!touchedFields[id.toString()]

  return (isDirty || isTouched) && !errors[id.toString()]
}

export const toOptionType = (label: string, value: string): OptionType => ({
  label,
  value,
})
