import moment from 'moment'

export enum DATEFORMAT {
  DATETIME4D3MHA = 'dddd D MMM, hh:mm A',
  DATE_FORMAT = 'MM/DD/YY',
}
export const timestampToDateString = (
  timestamp: number | string,
  format: string
) => {
  if (format) {
    return moment(timestamp).format(format)
  }
  return {
    date: moment(timestamp).format('MM/DD/YYYY'),
    time: moment(timestamp).format('hh:mm A'),
  }
}

export const dateToTimeStamp = (date: Date | string) => {
  try {
    return moment(date).format('x')
  } catch (error) {
    return ''
  }
}

export const calculateDateDifferenceInDays = (
  date1Unix: number,
  date2Unix: number
): string => {
  const date1 = moment.unix(date1Unix)
  const date2 = moment.unix(date2Unix)
  const diffInDays = date2.diff(date1, 'days')
  const resultString =
    diffInDays > 0
      ? `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'}`
      : 'Today'
  return resultString
}
