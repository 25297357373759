import { prop, keys } from 'ramda'
import constants from '../config/constants'
import axios from './axios'
import { IOrganization } from './interfaces'

// getOrganization:: (String) => Promise Organization
const getOrganization = async (
  id: string,
  excludeContacts = false,
  excludeOwner = false
) => {
  const endpoint = constants.ORGANIZATIONS_ENDPOINT()
  const query = `excludeContacts=${excludeContacts}&excludeOwner=${excludeOwner}`
  const url = `${endpoint}/${id}?${query}`
  return axios<IOrganization>({
    url,
    method: 'GET',
  }).then(prop('data'))
}

// getOrganizations:: (User | Nil) => Promise [Organization]
const getOrganizations = async () => {
  const endpoint = constants.ORGANIZATIONS_ENDPOINT()
  const url = `${endpoint}`
  return axios<IOrganization[]>({
    url,
    method: 'GET',
  }).then(prop('data'))
}

const getBundlerLevelNames = async (id: string) => {
  return await getOrganization(id).then(prop('levels')).then(keys)
}

const getOrgLevels = async (id: string) => {
  return await getOrganization(id).then(prop('levels'))
}

const createOrganization = async (organizationData: Partial<IOrganization>) => {
  const endpoint = constants.ORGANIZATIONS_ENDPOINT()
  const url = `${endpoint}`
  const response = await axios({
    url,
    method: 'POST',
    data: organizationData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'auth-domain': 'benjamin',
    },
  })
    .then(prop('data'))
    .catch(err => {
      console.log(err)
    })
  return { ...organizationData, _id: response.insertedId }
}

const editOrganization = async (
  organizationData: Partial<IOrganization>,
  id: string
) => {
  const endpoint = constants.ORGANIZATIONS_ENDPOINT()
  const url = `${endpoint}/${id}`
  const response = await axios({
    url,
    method: 'PUT',
    data: organizationData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'auth-domain': 'benjamin',
    },
  })
    .then(prop('data'))
    .catch(err => {
      console.log(err)
    })
  return { ...organizationData, _id: response.insertedId }
}

export default {
  getOrganization,
  getOrganizations,
  getBundlerLevelNames,
  getOrgLevels,
  createOrganization,
  editOrganization,
}
