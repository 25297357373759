import { find, propEq } from 'ramda'
import { useSelector } from 'react-redux'
import { ProviderAccount } from '../../api/interfaces'
import { getUserEmail, getUserProviderAccounts } from '../state/selectors'

export const getUserToken = () => {
  const emailAccount = useSelector(getUserEmail)
  const providerAccounts = useSelector(getUserProviderAccounts)
  return providerAccounts
    ? (find(propEq('email', emailAccount))(providerAccounts) as
        | ProviderAccount
        | undefined)
    : undefined
}
