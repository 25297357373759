import { Form } from 'react-bootstrap'
import { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import MultiEmailField from '../MultiEmailField/MultiEmailField'
import MultiEmailCustom from '../MultiEmailField/MultiEmailCustom/MultiEmailCustom'
import EditorConvertToHTML from '../EditorConvertToHTML/EditorConvertToHTML'
import { path } from 'ramda'
import { Toast, ToastContainer } from 'react-bootstrap'
import { getUserToken } from '../../../utils/dataModels/user'
import {
  getUserFullName,
  getUserOrganizationId,
} from '../../../utils/state/selectors'
import { useSelector } from 'react-redux'
import { sendNylasEmailReplyTo } from '../../../api/nylas/mailbox'

const initialStateComposeEmail = {
  to: [],
  cc: [],
  bcc: [],
  subject: '',
  body: '',
}

const MailReplyForwardCompose = ({ title, message, reloadPage }) => {
  const providerAccount = getUserToken()
  const userFullName = getUserFullName()
  const userOrganizationId = useSelector(getUserOrganizationId)
  const [composeEmail, setComposeEmail] = useState(initialStateComposeEmail)
  const [isSending, setIsSending] = useState(false)
  const [sendStatus, setSendStatus] = useState('')
  const [showToast, setShowToast] = useState(false)

  const inputChangeHandler = e => {
    e.preventDefault()
    e.stopPropagation()

    const fieldName = path(['target', 'id'], e)
    let value = path(['target', 'value'], e)
    setComposeEmailPropValue(fieldName, value)
  }

  const setComposeEmailPropValue = (fieldName, value) => {
    setComposeEmail({
      ...composeEmail,
      [fieldName]: value,
    })
  }

  useEffect(() => {
    if (message !== null) {
      let emailSubject = message?.subject
      if (title === 'Forward') {
        emailSubject = 'FW:' + emailSubject
      }
      const emailTo = getEmailsToFromMessage()
      const emailCC = getEmailsCCFromMessage()
      const objFromMessage = {
        to: emailTo,
        cc: emailCC,
        subject: emailSubject,
      }

      setComposeEmail({ ...composeEmail, ...objFromMessage })
    }
  }, [message])

  const validFormSentEmail = composeEmail => {
    const { to, body } = composeEmail
    return to?.length || body
  }

  const handleSendClick = () => {
    const { to, subject, body, cc, bcc } = composeEmail
    if (!validFormSentEmail(composeEmail)) {
      setSendStatus(
        <strong className="text-danger">Fill at least To field.</strong>
      )
      setShowToast(true)
      return
    }
    setSendStatus('')
    setIsSending(true)
    sendNylasEmailReplyTo(
      {
        email: providerAccount?.email || 'benjaminapp.bmail@gmail.com',
        name: userFullName,
      },
      to.map(toItem => ({ email: toItem, name: toItem })),
      subject,
      body,
      cc.map(ccItem => ({ email: ccItem, name: ccItem })),
      bcc.map(bccItem => ({ email: bccItem, name: bccItem })),
      providerAccount?.token,
      message?.id,
      true,
      userOrganizationId
    )
      .then(response => {
        if (response.status === 200) {
          setSendStatus('Email was sent successfully')
          reloadPage()
        } else {
          setSendStatus('Something is wrong, please try again.')
        }
      })
      .catch(error => {
        setSendStatus('Something is wrong, please try again.')
        console.log(error)
      })
      .finally(() => {
        setShowToast(true)
        setIsSending(false)
      })
    // Will be the new reply/forward nylas method
  }

  const getEmailsToFromMessage = () => {
    if (title === 'Reply' || title === 'Reply All') {
      if (message?.reply_to?.length > 0) {
        return message?.reply_to
          ?.map(contact => contact?.email)
          .filter(mail => mail !== providerAccount?.email)
      } else if (message?.from?.length > 0) {
        return message?.from
          ?.map(contact => contact?.email)
          .filter(mail => mail !== providerAccount?.email)
      }
    }
    return []
  }

  const getEmailsCCFromMessage = () => {
    if (title === 'Reply All') {
      if (message?.cc.length > 0) {
        return message?.cc?.map(contact => contact?.email)
      }
    }
    return []
  }

  return (
    <>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          placement=""
          onClose={() => setShowToast(false)}
          show={showToast}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">Send Email Status</strong>
          </Toast.Header>
          <Toast.Body>{sendStatus}</Toast.Body>
        </Toast>
      </ToastContainer>
      <div className="container">
        <div className="card">
          <div className="card-header">
            <p>{title}</p>
          </div>
          <div className="card-body">
            <Form.Group className="mt-2 mb-3 d-flex gap-2 align-items-baseline">
              <Form.Label htmlFor="to">To</Form.Label>
              <MultiEmailField
                emails={getEmailsToFromMessage()}
                onChange={emails => setComposeEmailPropValue('to', emails)}
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex gap-2 align-items-baseline">
              <Form.Label htmlFor="cc">Cc</Form.Label>
              <MultiEmailField
                emails={getEmailsCCFromMessage()}
                onChange={emails => setComposeEmailPropValue('cc', emails)}
                focus={false}
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex gap-2 align-items-baseline">
              <Form.Label htmlFor="bcc">Bcc</Form.Label>
              <MultiEmailField
                onChange={emails => setComposeEmailPropValue('bcc', emails)}
                focus={false}
              />
            </Form.Group>
            <Form.Group className="mb-3 d-flex gap-2 align-items-baseline">
              <Form.Label htmlFor="subject">Subject</Form.Label>
              <MultiEmailCustom
                id="subject"
                onChange={inputChangeHandler}
                value={composeEmail?.subject}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <EditorConvertToHTML
                onChange={htmlValue =>
                  setComposeEmailPropValue('body', htmlValue)
                }
              />
            </Form.Group>
            <Form.Group className="mt-4">
              <button
                className={`btn btn-secondary ${isSending && 'disabled'}`}
                onClick={handleSendClick}
                disabled={isSending}
              >
                {' '}
                {isSending ? 'Sending...' : 'Send'}{' '}
              </button>
            </Form.Group>
            <br />
          </div>
        </div>
      </div>
    </>
  )
}

MailReplyForwardCompose.propTypes = {
  title: PropTypes.string,
  message: PropTypes.object,
  reloadPage: PropTypes.func,
}

export default MailReplyForwardCompose
