import { nth, prop, propOr } from 'ramda'
import constants from '../config/constants'
import { isBundler } from '../utils/state/roleTests'
import axios from './axios'

// getContact:: String -> Promise Contact
const getContact = async (
  id,
  isAllowed = false,
  withDonationInfo = false,
  organizationId = sessionStorage.getItem('Org-Session')
) => {
  let endpoint = constants.CONTACTS_ENDPOINT()
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  let url = `${endpoint}/${id}`
  let query = `?organizationId=${organizationId}`
  if (withDonationInfo) query += '&withDonationInfo=true'
  url += query
  return axios({
    url,
    method: 'GET',
  })
    .then(prop('data'))
    .then(nth(0))
}

const getRegions = async (
  id,
  organizationId = sessionStorage.getItem('Org-Session'),
  isAllowed = false
) => {
  let endpoint = constants.CONTACTS_ENDPOINT()
  let query = `organizationId=${organizationId}&hidden=false`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}/regions?${query}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

const getDistrictName = async (state, district, isAllowed = false) => {
  let endpoint = constants.DISTRICT_ENDPOINT()
  let query = `state=${state}&district=${district}`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}?${query}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

//getContactByEmail:: String -> Promise Contact
const getContactByEmail = async (email, isAllowed = false) => {
  let endpoint = constants.CONTACTS_ENDPOINT()
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const data = { query: [{ $match: { email: email } }] }

  const url = `${endpoint}/filter`
  return axios({
    url,
    data,
    method: 'POST',
  })
    .then(prop('data'))
    .then(nth(0))
}

// returns user contacts only for bundler users
// getContacts:: (User | Nil) => Promise [Contact]
const getContacts = async (
  user,
  isAllowed = false,
  organizationId = sessionStorage.getItem('Org-Session'),
  withDonationInfo = false,
  offset,
  size,
  additionalFilters
) => {
  const userId = propOr('', '_id', user || {})
  const role = propOr('', 'role', user || {})

  if (userId === '' || !isBundler(role))
    return getAllContacts(
      isAllowed,
      organizationId,
      withDonationInfo,
      offset,
      size,
      additionalFilters
    )
  return getUserContacts(user, isAllowed, organizationId)
}

const getQueueContacts = async (
  orgId = sessionStorage.getItem('Org-Session') || '',
  offset = 0,
  size = 50,
  hidden = false,
  isAllowed = false
) => {
  let endpoint = `${constants.CONTACTS_ENDPOINT()}/queue`
  let query = `organizationId=${orgId}&hidden=${hidden}&offset=${offset}&size=${size}`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}?${query}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

const getQueueContactsCount = async (
  orgId = sessionStorage.getItem('Org-Session') || '',
  hidden = false,
  isAllowed = false
) => {
  let endpoint = `${constants.CONTACTS_ENDPOINT()}/queue/count`
  let query = `organizationId=${orgId}&hidden=${hidden}`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}?${query}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

const getQueueContactsByName = async (
  orgId = sessionStorage.getItem('Org-Session') || '',
  name,
  hidden = false,
  isAllowed = false
) => {
  let endpoint = `${constants.CONTACTS_ENDPOINT()}/queue/name`
  let query = `organizationId=${orgId}&hidden=${hidden}&name=${name}`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}?${query}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

// returns user contacts only for bundler users
// getContacts:: (User | Nil) => Promise [Contact]
const getAllContacts = async (
  isAllowed = false,
  organizationId = sessionStorage.getItem('Org-Session'),
  withDonationInfo = false,
  offset,
  size,
  additionalFilters
) => {
  let endpoint = `${constants.CONTACTS_ENDPOINT()}/get`
  let query = `organizationId=${organizationId}&hidden=false`
  if (withDonationInfo) query += '&withDonationInfo=true'
  if (offset) query += `&offset=${offset}`
  if (size) query += `&size=${size}`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = `${endpoint}?${query}`
  return axios({
    url,
    method: 'POST',
    data: additionalFilters,
  }).then(prop('data'))
}

const getContactsCount = async (
  organizationId = sessionStorage.getItem('Org-Session') || '',
  filters,
  user,
  isAllowed = false
) => {
  const userId = propOr(undefined, '_id', user || {})
  let endpoint = `${constants.CONTACTS_ENDPOINT()}/count`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')
  const url = `${endpoint}`
  return axios({
    url,
    method: 'POST',
    data: {
      filters,
    },
    headers: {
      organizationId,
      userId,
    },
  }).then(prop('data'))
}

// getUserContacts:: User => Promise [Contact]
const getUserContacts = async (
  user,
  isAllowed = false,
  organizationId = sessionStorage.getItem('Org-Session')
) => {
  const userId = propOr('', '_id', user || {})
  let endpoint = constants.CONTACTS_ENDPOINT()
  let query = `organizationId=${organizationId}`

  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  let url = `${endpoint}/userId/${userId}?${query}`

  if (isAllowed) url = url.replace('/api/benjamin/', '/api/allowed/benjamin/')
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

const updateContact = contact => {
  const endpoint = constants.CONTACTS_ENDPOINT()
  const url = `${endpoint}/${contact?.contact_person_id}`

  const formData = new FormData()
  if (contact.body.contact.picture != undefined) {
    formData.append('files', contact.body.contact.picture)
    delete contact.body.contact.picture
  }
  formData.append('data', JSON.stringify(contact?.body))

  return axios({
    url,
    method: 'PUT',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'auth-domain': 'benjamin',
    },
  }).then(prop('data'))
}

const updateContactLevel = contact => {
  const endpoint = constants.CONTACTS_ENDPOINT()
  const url = `${endpoint}/level/${contact?._id}`

  return axios({
    url,
    method: 'PUT',
    data: JSON.stringify(contact?.body),
  })
    .then(prop('data'))
    .catch(prop('response'))
}

const createContacts = contacts => {
  const endpoint = constants.CONTACTS_ENDPOINT()

  const formData = new FormData()
  if (contacts.contacts[0].picture != undefined) {
    formData.append('files', contacts.contacts[0].picture)
  }
  formData.append('data', JSON.stringify(contacts))

  return axios({
    url: `${endpoint}`,
    method: 'POST',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      'auth-domain': 'benjamin',
    },
  }).then(prop('data'))
}

const createZoomLink = data => {
  const endpoint = constants.ZOOM_MEETING_ENDPOINT()
  return axios({
    url: `${endpoint}`,
    method: 'POST',
    data: data,
  }).then(prop('data'))
}

const signZoomLink = data => {
  const endpoint = constants.ZOOM_SIGNCALL_ENDPOINT()
  return axios({
    url: `${endpoint}`,
    method: 'POST',
    data: data,
  }).then(prop('data'))
}

const recentlyCalled = ({ contactId, ...rest }) => {
  const endpoint = constants.CONTACTS_ENDPOINT()
  const url = `${endpoint}/insertCallTime/${contactId}`
  return axios({
    url,
    method: 'POST',
    data: rest,
  }).then(prop('data'))
}

const recentlyTextMessaged = ({ contactId, ...rest }) => {
  const endpoint = constants.CONTACTS_ENDPOINT()
  const url = `${endpoint}/insertTextTime/${contactId}`
  return axios({
    url,
    method: 'POST',
    data: rest,
  }).then(prop('data'))
}

/**
 * Returns Promise [IContact[]].
 *
 * @param {Object} query The query object to filter contacts https://www.mongodb.com/docs/manual/reference/operator/query-logical/.
 * @param {boolean} isAllowed To pass proxy authentication without token by default=false.
 * @return {Promise [IContact[]]} filtered contact list based on query object.
 */
const filterAllContacts = (query = {}, isAllowed = false) => {
  let endpoint = `${constants.CONTACTS_ENDPOINT()}/filter`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios({
    url,
    method: 'POST',
    data: { query },
  }).then(prop('data'))
}

/**
 * Returns Promise [IContact[]].
 *
 * @param {Object} query The aggregate query object to filter contacts https://www.mongodb.com/docs/manual/reference/operator/aggregation/.
 * @param {boolean} isAllowed To pass proxy authentication without token by default=false.
 * @param {string} organizationId Organization identifier to which the user belongs.
 * @return {Promise [IContact[]]} filtered contact list based on query object.
 */
const filterContacts = (
  query = [],
  isAllowed = false,
  organizationId = sessionStorage.getItem('Org-Session')
) => {
  return filterAllContacts(query, isAllowed, organizationId)
}

export const retrieveContactWealthInfo = (query = [], isAllowed = false) => {
  let endpoint = `${constants.CONTACTS_WEALTH_INFO_ENDPOINT()}/filter`
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')

  const url = endpoint
  return axios({
    url,
    method: 'POST',
    data: { query },
  }).then(prop('data'))
}

export default {
  getContact,
  getRegions,
  getDistrictName,
  getContactByEmail,
  getContacts,
  getAllContacts,
  getUserContacts,
  updateContact,
  updateContactLevel,
  createContacts,
  createZoomLink,
  signZoomLink,
  filterContacts,
  filterAllContacts,
  recentlyCalled,
  recentlyTextMessaged,
  getQueueContacts,
  getQueueContactsCount,
  getQueueContactsByName,
  getContactsCount,
}
