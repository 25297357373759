import Axios from 'axios'
import constants from '../../config/constants'

const getUserProfile = (idToken, refreshToken) => {
  return Axios.get(constants.GOOGLE_OAUTH_TOKEN_VALIDATION_URL, {
    params: {
      id_token: idToken,
    },
  })
    .then(body => {
      const { email, given_name, family_name, name, picture } = body.data
      return {
        email,
        givenName: given_name,
        familyName: family_name,
        name,
        imageUrl: picture,
        idToken,
        refreshToken,
        provider: 'google',
      }
    })
    .catch(err => {
      return Promise.reject(`Error validating Google token: ${err.message}`)
    })
}

export default getUserProfile
