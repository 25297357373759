import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import InputForm from '../GenericForm/InputForm/InputForm'
import SelectForm, { OptionType } from '../GenericForm/SelectForm/SelectForm'
import ApiOrganization from '../../api/organization'
import { userValidationSchema } from './userForm-yup-config'
import { UserSubmitForm } from './types'
import {
  getLevelBasedOnRol,
  getLevelsFromOrganization,
  levelsToSelectOptions,
  userFormOptions,
} from './userForm-config'
import { CONTACT_LEVELS, PROVIDER_ACCOUNT, USER_ROL } from '../../api/enums'
import { toOptionType } from '../GenericForm/utils'
import PatternFormatForm from '../GenericForm/PatternFormatForm/PatternFormatForm'
import CheckForm from '../GenericForm/CheckForm/CheckForm'
import ApiUser from '../../api/user'
import ApiContact from '../../api/contact'
import { IContact, IUser } from '../../api/interfaces'
import { useSelector } from 'react-redux'
import { getUser } from '../../utils/state/selectors'

const initialUserForm: UserSubmitForm = {
  name: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  organization: '',
  provider: '',
  role: '',
  level: '',
  state: '',
  phone: '',
  picture: '',
  deleteContact: false,
  deleteUser: false,
}

const UserRegistration = () => {
  const loggedInUser = useSelector(getUser)
  const [msg, setMsg] = useState<string>()

  const [isSaving, setIsSaving] = useState<boolean>()
  const userForm = useForm<UserSubmitForm>({
    shouldUnregister: false,
    resolver: yupResolver(userValidationSchema),
    mode: 'all',
    defaultValues: initialUserForm,
  })
  const { handleSubmit, reset, watch, resetField, setValue } = userForm

  const [_userFormOptions, setUserFormOptions] = useState(userFormOptions)
  const watchRole = watch('role')
  const watchOrganization = watch('organization')

  useEffect(() => {
    let isMounted = true
    ApiOrganization.getOrganizations().then(organizations => {
      if (isMounted) {
        const orgOptions: OptionType[] = []
        const orgLevel: { [key: string]: OptionType[] } = {}
        organizations.forEach(org => {
          const { _id, name } = org
          orgLevel[String(_id)] = levelsToSelectOptions(
            getLevelsFromOrganization(org)
          )
          orgOptions.push(toOptionType(name, String(_id)))
        })
        setUserFormOptions({ ..._userFormOptions, orgOptions, orgLevel })
      }
    })
    return () => {
      isMounted = false
    }
  }, [])

  const isRolAdmin = watchRole === USER_ROL.ADMIN
  const isRolBundler = watchRole === USER_ROL.BUNDLER

  const showProvider = watchRole && !isRolAdmin
  const showPassword = watchRole && isRolAdmin

  useEffect(() => {
    const userRol = watchRole as USER_ROL
    if (watchRole) {
      if (isRolBundler) {
        resetField('level')
      } else {
        setValue('level', getLevelBasedOnRol(userRol))
      }
    }
  }, [watchRole])

  const getContactPayload = (userForm: UserSubmitForm) => {
    const {
      name,
      lastName,
      organization,
      email,
      picture,
      level,
      phone,
      state,
    } = userForm

    const contact: Partial<IContact> = {
      first_name: name,
      last_name: lastName,
      organizationId: organization,
      email: email,
      picture,
      level,
      primary_phone_number: phone,
      state_abbr: state,
    }
    return contact
  }

  const onSubmit = async (formData: UserSubmitForm) => {
    setIsSaving(true)
    const {
      deleteUser,
      deleteContact,
      email,
      level,
      role,
      organization,
      provider,
      password,
      name,
      lastName,
    } = formData
    const user = await ApiUser.findUser(email)
    let userId = user?._id
    const contact = await (ApiContact.getContactByEmail(
      email
    ) as Promise<IContact>)
    let contactId = contact?._id
    let msg = ''
    const warnRecs = []
    const stopRecs = []
    if (user) {
      if (deleteUser === true) {
        warnRecs.push('User')
      } else {
        stopRecs.push('User')
      }
    }
    if (
      contact &&
      level !== CONTACT_LEVELS.ADMIN &&
      level !== CONTACT_LEVELS.OWNER
    ) {
      if (deleteContact === true) {
        warnRecs.push('Contact')
      } else {
        stopRecs.push('Contact')
      }
    }
    if (stopRecs.length) {
      msg += `A ${stopRecs.join(' and ')}
         record exists with the same email address of the user you're rtying to add. Please check the box to delete existing records before adding this user.`
    } else if (warnRecs.length) {
      msg += `The existing ${warnRecs.join(
        ' and '
      )} record was deleted before adding this user.`
    }
    setMsg(msg)
    if (!stopRecs.length) {
      const newUser: Partial<IUser> = {
        organizationId: organization,
        role: role as USER_ROL,
        picture: 'unassigned',
        isFederated: !!provider, // if provider then isFederated
        password,
        name: name,
        lastName,
        email,
        provider: provider as PROVIDER_ACCOUNT,
        active: true,
      }
      if (warnRecs.includes('User')) {
        await ApiUser.updateUser({ user: newUser, userId })
      } else {
        const resp = await ApiUser.createUser({ user: newUser })
        userId = resp?.insertedId
      }
      if (level !== CONTACT_LEVELS.ADMIN && level !== CONTACT_LEVELS.OWNER) {
        const newContact = getContactPayload(formData)
        newContact.user_id = loggedInUser?._id
        if (warnRecs.includes('Contact')) {
          ApiContact.updateContact({
            contact_person_id: contact.contact_person_id,
            body: { contact: newContact },
          })
        } else {
          const resp = await ApiContact.createContacts({
            contacts: [newContact],
          })
          const ids = resp?.insertedIds
          contactId = 0 in ids ? ids[0] : ''
        }
        await ApiUser.updateUser({
          user: { ...newUser, contactId },
          userId,
        })
      }
      reset(initialUserForm)
      setIsSaving(false)
    } else {
      setIsSaving(false)
    }
  }

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-row flex-nowrap justify-content-between">
          <div className="">
            <h4>User registration</h4>
          </div>
          <div className="form-group">
            <Button
              variant="secondary"
              type="submit"
              className="mx-2"
              disabled={isSaving}
            >
              {isSaving ? 'Creating user...' : 'Create user'}
            </Button>
            <Button
              variant="outline-secondary"
              type="button"
              onClick={() => {
                setIsSaving(false)
                reset()
              }}
            >
              Reset Form
            </Button>
          </div>
        </div>
        <hr className="mb-4" />
        <Row className="gy-4">
          <Col md="4">
            {/* Name */}
            <InputForm<UserSubmitForm>
              id="name"
              label="First Name"
              hookForm={userForm}
              validationSchema={userValidationSchema}
            />
          </Col>
          <Col md="4">
            {/* Last Name */}
            <InputForm<UserSubmitForm>
              id="lastName"
              label="Last Name"
              hookForm={userForm}
              validationSchema={userValidationSchema}
            />
          </Col>

          <Col md="4">
            {/* Email */}
            <InputForm<UserSubmitForm>
              id="email"
              label="Email"
              hookForm={userForm}
              validationSchema={userValidationSchema}
            />
          </Col>
          <Col md="4">
            {/* Phone */}
            <PatternFormatForm<UserSubmitForm>
              id="phone"
              label="Phone"
              hookForm={userForm}
              validationSchema={userValidationSchema}
              mask="_"
              format="(###) ### ####"
            />
          </Col>
          {!!_userFormOptions.orgOptions.length && (
            <Col md="3">
              {/* Organization */}
              <SelectForm<UserSubmitForm>
                id="organization"
                label="Organization"
                hookForm={userForm}
                validationSchema={userValidationSchema}
                placeholder=""
                options={_userFormOptions.orgOptions}
              />
            </Col>
          )}
          <Col md="3">
            {/* Role */}
            <SelectForm<UserSubmitForm>
              id="role"
              label="Role"
              hookForm={userForm}
              validationSchema={userValidationSchema}
              placeholder=""
              options={_userFormOptions.role}
            />
          </Col>
          <Col>
            {/* Level */}
            <SelectForm<UserSubmitForm>
              id="level"
              label="Level"
              hookForm={userForm}
              validationSchema={userValidationSchema}
              placeholder=""
              options={
                isRolBundler
                  ? watchOrganization
                    ? _userFormOptions.orgLevel[watchOrganization]
                    : []
                  : _userFormOptions.level
              }
              isDisabled={!isRolBundler || !watchOrganization}
            />
            {isRolBundler && !watchOrganization && (
              <small className="text-secondary">
                You must first select an organization
              </small>
            )}
          </Col>
          <Col md="3">
            {/* State */}
            <SelectForm<UserSubmitForm>
              id="state"
              label="State"
              hookForm={userForm}
              validationSchema={userValidationSchema}
              placeholder=""
              options={_userFormOptions.state}
            />
          </Col>
          {showProvider && (
            <Col md="3">
              {/* Provider */}
              <SelectForm<UserSubmitForm>
                id="provider"
                label="Provider"
                hookForm={userForm}
                validationSchema={userValidationSchema}
                placeholder=""
                options={_userFormOptions.provider}
              />
            </Col>
          )}
        </Row>
        {showPassword && (
          <Row className="mt-4">
            <Col md="4">
              {/* Password */}
              <InputForm<UserSubmitForm>
                id="password"
                label="Password"
                type="password"
                hookForm={userForm}
                validationSchema={userValidationSchema}
              />
            </Col>

            <Col md="4">
              {/* Confirm Password */}
              <InputForm<UserSubmitForm>
                id="confirmPassword"
                label="Confirm Password"
                type="password"
                hookForm={userForm}
                validationSchema={userValidationSchema}
              />
            </Col>
          </Row>
        )}
        <Row className="mt-2">
          <Col md="6">
            {/* Picture */}
            <InputForm<UserSubmitForm>
              id="picture"
              label="Profile picture"
              hookForm={userForm}
              validationSchema={userValidationSchema}
            />
          </Col>
        </Row>
        <br />

        <Row>
          <Col md="12">
            <Form.Label>
              <div className="pe-5">
                Delete existing records with the same email address before
                adding:
              </div>
            </Form.Label>
            {/* Delete User */}
            <CheckForm<UserSubmitForm>
              id="deleteUser"
              label="Users"
              hookForm={userForm}
              validationSchema={userValidationSchema}
              containerClassName="w-100"
            />
            {/* Delete Contact */}
            <CheckForm<UserSubmitForm>
              id="deleteContact"
              label="Contacts"
              hookForm={userForm}
              validationSchema={userValidationSchema}
            />
          </Col>
          <Row>{msg && <Alert>{msg}</Alert>}</Row>
        </Row>
      </Form>
    </>
  )
}

export default UserRegistration
