import { Card, Dropdown, Form } from 'react-bootstrap'
import PropTypes from 'prop-types'
import fieldMapOptions from './fieldMapOptions'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'
import DropdownItem from 'react-bootstrap/esm/DropdownItem'

const FieldMapDropdown = ({ exampleData, field, fieldMap, setFieldMap }) => {
  const onChange = e => {
    if (e.target.value) setFieldMap({ ...fieldMap, [field]: e.target.value })
    else {
      const newFieldMap = { ...fieldMap }
      delete newFieldMap[field]
      setFieldMap({ ...newFieldMap })
    }
  }

  return (
    <Card bg={fieldMap[field] ? 'info' : 'light'}>
      <Card.Header className={fieldMap[field] ? 'fw-bold' : 'fw-normal'}>
        {field}
      </Card.Header>
      <Card.Body>
        <Form.Select value={fieldMap[field]} onChange={onChange}>
          <option value="">-- Select field --</option>
          {Object.keys(fieldMapOptions).map(key => {
            const keys = Object.keys(fieldMap)
            const values = Object.values(fieldMap)
            const validIndex = keys.indexOf(field)

            if (!values.includes(key) || values.indexOf(key) === validIndex)
              return (
                <option key={key} value={key}>
                  {fieldMapOptions[key].label}
                </option>
              )
          })}
        </Form.Select>
      </Card.Body>
      <Card.Footer>
        {exampleData?.[field]?.toString() && (
          <Dropdown size="sm" className="w-100">
            <Dropdown.Toggle variant="light" className="w-100 text-end">
              <small
                style={{
                  display: 'inline-flex',
                  maxWidth: '80%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                Ex:&nbsp;<i>{exampleData?.[field][0]?.toString()}</i>
              </small>
            </Dropdown.Toggle>
            <DropdownMenu>
              {exampleData?.[field].map(data => (
                <DropdownItem key={data}>
                  <small>{data.toString()}</small>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
        {!exampleData?.[field]?.toString() && (
          <Dropdown size="sm" className="w-100">
            <Dropdown.Toggle
              variant="light"
              className="w-100 text-end"
              disabled
            >
              <small>Column is apparently empty</small>
            </Dropdown.Toggle>
          </Dropdown>
        )}
      </Card.Footer>
    </Card>
  )
}

FieldMapDropdown.propTypes = {
  exampleData: PropTypes.object,
  field: PropTypes.string,
  fieldMap: PropTypes.object,
  setFieldMap: PropTypes.func,
}

FieldMapDropdown.defaultProps = {
  exampleData: {},
}

export default FieldMapDropdown
