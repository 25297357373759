import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import YourStatus from '../YourStatus/YourStatus'
import RecentDonations from '../RecentDonations/RecentDonations'
import EventsCard from '../EventsCard/EventsCard'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import useDimensionScreen from '../../hooks/useDimensionScreen'
import './BundlerLandingPage.css'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getUser } from '../../utils/state/selectors'
import { syncBundlerContacts } from '../../api/nylas/bcontact'
import { isBundler } from '../../utils/state/roleTests'

const Wrapper = styled.div`
  padding: 1rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

const BundlerLandingPage = () => {
  usePageHeadings('Dashboard')
  const user = useSelector(getUser)

  const [dimension] = useDimensionScreen()

  const isVisible = dimension.width > 480

  useEffect(() => {
    const { organizationId, providerAccounts, _id, role } = user
    if (isBundler(role)) {
      const [googleProvider] = providerAccounts
      syncBundlerContacts(googleProvider.token, _id, organizationId)
    }
  }, [])

  return (
    <Wrapper>
      <Container fluid>
        <div className="d-flex flex-wrap justify-content-xxl-around gap-2 bundler-parent-flex">
          <div className="h-100 d-flex flex-column justify-content-between flex-md-grow-1 gap-2">
            {isVisible && <YourStatus style={{ fontSize: '28px' }} />}
            <div
              className={
                !isVisible
                  ? 'h-100 d-flex flex-column justify-content-between gap-2'
                  : 'row'
              }
            >
              <div className={!isVisible ? '' : 'col-sm-8'}>
                <EventsCard
                  style={{
                    maxWidth: '1100px',
                    minWidth: '300px',
                    fontSize: '28px',
                  }}
                  userId={useSelector(getUser)?._id}
                />
              </div>
              <div className={!isVisible ? '' : 'col-sm-4'}>
                <RecentDonations style={{ fontSize: '28px' }} />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Wrapper>
  )
}

export default BundlerLandingPage
