import { configureStore } from '@reduxjs/toolkit'
import api from './middleware/api'
import authenticationReducer from './reducers/authenticationReducer'
import envJSONReducer from './envJSON/envJSON'
import chatReducer from './reducers/chatReducer'
import mailboxReducer from './reducers/mailboxReducer'
import uiReducer from './reducers/uiReducer'
import contactReducer from './contacts/contacts'
import toastReducer from './toasts/toasts'
import pipelineReducer from './pipeline/pipeline'

export const initStore = configureStore({
  reducer: {
    authentication: authenticationReducer,
    envJSON: envJSONReducer,
    ui: uiReducer,
    mailbox: mailboxReducer,
    chat: chatReducer,
    contacts: contactReducer,
    toasts: toastReducer,
    pipeline: pipelineReducer,
  },
  middleware: getDefaultMiddleware => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    api,
  ],
})
