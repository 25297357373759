import PropTypes from 'prop-types'
import { Draggable } from 'react-beautiful-dnd'

const ListItem = ({
  item,
  index,
  dragComponent,
  isDragDisabled,
  dragItemClassName = 'drag-item',
}) => {
  const _isDragDisabled = item.isDragDisabled || isDragDisabled

  return (
    <Draggable
      draggableId={item.id}
      index={index}
      isDragDisabled={_isDragDisabled}
    >
      {provided => {
        return (
          <div
            className={
              (_isDragDisabled
                ? `${dragItemClassName}-disabled`
                : dragItemClassName) || dragItemClassName
            }
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            {dragComponent(item)}
          </div>
        )
      }}
    </Draggable>
  )
}

ListItem.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  dragComponent: PropTypes.func,
  isDragDisabled: PropTypes.bool,
  dragItemClassName: PropTypes.string,
}

export default ListItem
