import { BsSortUpAlt, BsSortUp } from 'react-icons/bs'
import PropTypes from 'prop-types'

const SortIconAction = ({ setFunctState, stateValue, refresh }) => {
  const handleSortClick = () => {
    if (stateValue == 2) {
      setFunctState(0)
      refresh()
    } else {
      setFunctState(stateValue + 1)
    }
  }

  return (
    <>
      {(stateValue === 0 || stateValue === 1) && (
        <BsSortUpAlt
          onClick={() => handleSortClick()}
          size={15}
          color={stateValue === 0 ? '#A6B3BE' : 'blue'}
        />
      )}
      {stateValue === 2 && (
        <BsSortUp onClick={() => handleSortClick()} size={15} color={'blue'} />
      )}
    </>
  )
}

SortIconAction.propTypes = {
  setFunctState: PropTypes.func,
  stateValue: PropTypes.number,
  refresh: PropTypes.func,
}

export default SortIconAction
