import { useEffect, useState } from 'react'

const useDimensionScreen = () => {
  const [dimension, setDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const listener = window.addEventListener('resize', () =>
      setDimension({
        ...dimension,
        width: window.innerWidth,
        height: window.innerHeight,
      })
    )

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  return [dimension]
}

export default useDimensionScreen
