import { always, equals } from 'ramda'
import { USER_ROL } from '../../api/enums'
import { IUser } from '../../api/interfaces'

export const isStaffer = (role: string | undefined) =>
  equals(role, USER_ROL.STAFFER)

export const isBundler = (role: string | undefined) =>
  equals(role, USER_ROL.BUNDLER)

export const isAdmin = (role: string | undefined) =>
  equals(role, USER_ROL.ADMIN)

export const isOwner = (role: string | undefined) =>
  equals(role, USER_ROL.OWNER)

export const isCRM = (role: string | undefined) => equals(role, USER_ROL.CRM)

export const isStafferOrOwner = (role: string | undefined) =>
  isStaffer(role) || isOwner(role)

export const isStafferOrOwnerOrCRM = (role: string | undefined) =>
  isStaffer(role) || isOwner(role) || isCRM(role)

export const isStafferOrOwnerOrAdmin = (role: string | undefined) =>
  isStaffer(role) || isOwner(role) || isAdmin(role)

export const anyRole = always(true)

export const checkUserRole = (userRole: string) => {
  if (userRole === USER_ROL.BUNDLER) {
    return false
  } else if (
    userRole === USER_ROL.STAFFER ||
    userRole === USER_ROL.ADMIN ||
    userRole === USER_ROL.OWNER ||
    userRole === USER_ROL.CRM
  ) {
    return true
  }
  return false
}

export const addFieldToAggregate = () => {
  return [
    {
      $addFields: {
        _userId: { $toString: '$userId' }, // Convert userId toString and save on _userId
      },
    },
  ]
}
export const matchQueryBasedOnUserRol = (user?: IUser) => {
  return {
    ...(isStaffer(user?.role) && {
      hidden: { $not: { $eq: isStaffer(user?.role) } },
    }), // To get contacts that hidden is not false only if is staffer
    organizationId: sessionStorage.getItem('Org-Session'),
    ...(isBundler(user?.role) && { _userId: user?._id }),
  }
}
