import { Navigate, Route, Routes } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { isNil, isEmpty } from 'ramda'
import { useSelector } from 'react-redux'
import MainLayout from '../components/Layout/MainLayout'
import Home from '../components/Home/Home'
import {
  getUserRole,
  getUser,
  getUserOrganizationId,
  getUserFullName,
} from '../utils/state/selectors'
import { isBundler } from '../utils/state/roleTests'
import BundlerLandingPage from '../components/BundlerLandingPage/BundlerLandingPage'
import StafferLandingPage from '../components/StafferLandingPage/StafferLandingPage'
import CreateEvent from '../components/CreateEvent/CreateEvent'
import EventsPage from '../components/EventsPage/EventsPage'
import EventDetailPage from '../components/EventDetailPage/EventDetailPage'
import EventEditPage from '../components/EventEditPage/EventEditPage'
import ContactsPage from '../components/ContactsPage/ContactsPage'
import PageLayout from '../components/Layout/PageLayout'
import CalendarPage from '../components/CalendarPage/CalendarPage'
import PipelinePage from '../components/PipelinePage/PipelinePage'
import ContactIdPage from '../components/ContactIdPage/ContactIdPage.js'
import InvitePage from '../components/InvitePage/InvitePage'
import ChatLayout from '../components/Layout/ChatLayout'
import MailBoxPage from '../components/MailBoxPage/MailBoxPage'
import MailBoxMessagePage from '../components/MailBoxPage/MailBoxMessagePage'
import ContactUploadFilePage from '../components/ContactUploadFilePage/ContactUploadFilePage/ContactUploadFilePage'
import AdminLandingPage from '../components/AdminLandingPage/AdminLandingPage'
import AuthSessionLayout from '../components/Layout/AuthSessionLayout'
import AdminUsersPage from '../components/AdminUsersPage/AdminUsersPage'
import AdminOrganizationsPage from '../components/AdminOrganizationsPage/AdminOrganizationsPage'
import ZoomCall from '../components/ZoomCall/ZoomCall'
import SimpleLayout from '../components/Layout/SimpleLayout'
import CreateOrganizationPage from '../components/CreateOrganizationPage/CreateOrganizationPage'
import AdminOrganizationDetailPage from '../components/AdminOrganizationDetailPage/AdminOrganizationDetailPage'
import EditOrganizationPage from '../components/EditOrganizationPage/EditOrganizationPage'
import ReportPage from '../components/ReportPage/ReportPage'
import DonationsPage from '../components/DonationsPage/DonationsPage'
import { USER_ROL } from '../api/enums'
import ApiOrg from '../api/organization'
import routes from './routes'
import { useSearchParams, useLocation, matchRoutes } from 'react-router-dom'
import { datadogRum } from '@datadog/browser-rum'
import constants from '../config/constants'
import ZoomOAuthCall from '../components/ZoomCall/ZoomCallOAuth'
import PipelineCRM from '../components/PipelineCRM/PipelineCRM'

const isDefined = x => !(isNil(x) || isEmpty(x))

const getRoleLayout = role => {
  switch (role) {
    case USER_ROL.PRINCIPAL:
    case USER_ROL.STAFFER:
    case USER_ROL.BUNDLER:
    case USER_ROL.OWNER:
    case USER_ROL.CRM:
    default:
      return MainLayout
  }
}

const getRoleLandingPage = role => {
  switch (role) {
    case USER_ROL.PRINCIPAL:
    case USER_ROL.OWNER:
    case USER_ROL.STAFFER:
      return StafferLandingPage
    case USER_ROL.BUNDLER:
      return BundlerLandingPage
    case USER_ROL.ADMIN:
      return AdminLandingPage
    case USER_ROL.CRM:
      return StafferLandingPage
    default:
      return Home
  }
}

const getRoleEventDetailPage = (role, isOnTiers) => {
  if (isOnTiers === 'true') return EventDetailPage
  switch (role) {
    case USER_ROL.STAFFER:
      return EventDetailPage
    case USER_ROL.OWNER:
      return EventDetailPage
    case USER_ROL.CRM:
      return EventDetailPage
    case USER_ROL.BUNDLER:
    default:
      return BundlerLandingPage
  }
}

const getMetricsGroup = (user, orgName) => {
  if (!user || !orgName) return null
  const mgroup = user.metrics_group
  return isDefined(mgroup)
    ? mgroup
    : user.role === 'admin'
    ? 'admin'
    : isDefined(orgName)
    ? orgName
    : null
}

const MainRouter = () => {
  const [searchParams] = useSearchParams()

  const userRole = useSelector(getUserRole)
  const user = useSelector(getUser)
  const userName = useSelector(getUserFullName)
  const orgId = useSelector(getUserOrganizationId)
  const RoleLayout = getRoleLayout(userRole)
  const RoleLandingPage = getRoleLandingPage(userRole)
  const RoleEventDetailPage = getRoleEventDetailPage(
    userRole,
    searchParams.get('isOnTiers')
  )
  const [orgName, setOrgName] = useState('')

  useEffect(() => {
    ApiOrg.getOrganization(orgId).then(org => setOrgName(org.name))
  }, [orgId])

  useEffect(() => {
    const mgroup = getMetricsGroup(user, orgName)
    const userType = isBundler(userRole) ? 'bundler' : 'general'
    if (userType && userName && mgroup && orgName) {
      datadogRum.setUser({
        id: userType, //Provides metrics facet to identify bundler users
        name: userName,
        email: mgroup, //Provides metrics facet to group sessions/views by org
        org: orgName,
      })
      // console.log(JSON.stringify(datadogRum.getUser(), null, 2))
    }
  }, [user, userRole, userName, orgName])

  //Datadog manual view generation
  let location = useLocation()
  useEffect(() => {
    const routeMatches = matchRoutes(routes, location.pathname)
    const viewName = routeMatches && computeViewName(routeMatches)
    if (viewName) {
      datadogRum.startView({ name: viewName })
    }
  }, [location.pathname])

  // Compute view name out of routeMatches
  function computeViewName(routeMatches) {
    let viewName = ''
    for (let index = 0; index < routeMatches.length; index++) {
      const routeMatch = routeMatches[index]
      const path = routeMatch.route.path
      // Skeep pathless routes
      if (!path) {
        continue
      }

      if (path.startsWith('/')) {
        // Handle absolute child route paths
        viewName = path
      } else {
        // Handle route paths ending with "/"
        viewName += viewName.endsWith('/') ? path : `/${path}`
      }
    }

    return viewName || '/'
  }

  return (
    <Routes>
      <Route element={<AuthSessionLayout />}>
        <Route element={<RoleLayout />}>
          <Route path="/login" element={<Navigate to="/" replace />} />
          <Route element={<PageLayout />}>
            <Route element={<ChatLayout />}>
              <Route path="/" element={<RoleLandingPage />} />
              <Route path="/calendar" element={<CalendarPage />} />
              <Route path="/events/all" element={<EventsPage />} />
              <Route
                path="/events/:eventId"
                setOrgName
                element={<RoleEventDetailPage />}
              />
              <Route
                path="/contacts/"
                element={
                  <ContactsPage renderUploadFileModal={isBundler(userRole)} />
                }
              />
              <Route
                path="/contacts/uploadfile"
                element={<ContactUploadFilePage />}
              />
              <Route path="/pipeline/" element={<PipelineCRM />} />
              <Route path="/contact/:contactId" element={<ContactIdPage />} />
              <Route path="/invite/:eventId" element={<InvitePage />} />
              <Route path="/mailbox" element={<MailBoxPage />} />
              <Route path="/mailbox/:id" element={<MailBoxMessagePage />} />
              <Route path="/events/create" element={<CreateEvent />} />
              <Route path="/events/:eventId/edit" element={<EventEditPage />} />
            </Route>
            <Route path="/events/create" element={<CreateEvent />} />
            <Route path="/events/:eventId/edit" element={<EventEditPage />} />
            <Route path="/admin/staffers" element={<AdminUsersPage />} />
            <Route path="/admin/bundlers" element={<AdminUsersPage />} />
            <Route
              path="/admin/organizations"
              element={<AdminOrganizationsPage />}
            />
            <Route
              path="/admin/organizations/:orgId"
              element={<AdminOrganizationDetailPage />}
            />
            <Route
              path="/admin/organizations/create"
              element={<CreateOrganizationPage />}
            />
            <Route
              path="/admin/organizations/edit/:orgId"
              element={<EditOrganizationPage />}
            />
            <Route
              path="/reports"
              element={<ReportPage message="!Hellow world" />}
            ></Route>
            <Route path="/donations" element={<DonationsPage />}></Route>
          </Route>
        </Route>
        <Route element={<SimpleLayout />}>
          <Route
            path="/call-portal/video-call/:contactId"
            element={
              constants.ZOOM_OAUTH_ENABLED() ? <ZoomOAuthCall /> : <ZoomCall />
            }
          />
        </Route>
      </Route>
    </Routes>
  )
}

export default MainRouter
