import { useEffect, useState } from 'react'
import { usePageHeadings } from '../../hooks/usePageHeadings'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import { Col, Row, Stack, FormSelect, Container, Button } from 'react-bootstrap'
import styled from 'styled-components'
import PipelineCard from './PipelineCard'
import DragList from '../DragAndDrop/DragList/DragList'
import { groupBy, isNil, path, prop, sortBy } from 'ramda'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import {
  getRefreshDonations,
  getUser,
  getUserRole,
} from '../../utils/state/selectors'
import {
  addFieldToAggregate,
  isBundler,
  matchQueryBasedOnUserRol,
} from '../../utils/state/roleTests'
import DonationApi from '../../api/donation'
import CenterSpinner from '../CenterSpinner/CenterSpinner'
import {
  initialPipelineState,
  setAddDonationTypeModal,
  setRefreshDonations,
} from '../../redux/pipeline/pipeline'
import { useDispatch } from 'react-redux'
import ContactApi from '../../api/contact'
import { DONATION_TYPE } from '../../api/enums'
import EventApi from '../../api/event'

const DEFAULT_GENERAL_EVENT = { label: 'General', value: 'general' }

const Wrapper = styled.div`
  padding: 1rem 1.3125rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

const FilterSpan = styled.span`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #79828a;
`

const SelectStyled = styled(FormSelect)`
  height: 38px;
  width: 169px;
  left: 0px;
  top: 26px;
  border-radius: 4px;
  padding: 6px 12px 6px 12px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`

const aggregateOperations = [
  {
    $match: {
      $or: [{ type: DONATION_TYPE.PLEDGE }, { type: DONATION_TYPE.DEPOSIT }],
    },
  },
  // filter by type
  { $group: { _id: '$donorId' } }, // Getting unique donors by Ids
]
const Header = ({ onUpdateState, eventList }) => {
  const selectChangeHandler = e => {
    e.preventDefault()
    e.stopPropagation()
    const value = path(['target', 'value'], e)
    onUpdateState(value)
  }

  return (
    <Row style={{ paddingBottom: '1.875rem' }}>
      <Col sm="3">
        <Stack gap={2}>
          <FilterSpan>Filter By</FilterSpan>
          <SelectStyled id="eventId" onChange={selectChangeHandler}>
            <option value={'all'}>All Events</option>
            <option value={'general'}>General</option>
            {eventList.map(({ _id: eventId, name }) => (
              <option key={`filter-event-list-item-${eventId}`} value={eventId}>
                {name}
              </option>
            ))}
          </SelectStyled>
        </Stack>
      </Col>
    </Row>
  )
}

Header.propTypes = {
  onUpdateState: PropTypes.func,
  eventList: PropTypes.array,
}

const getTypeHeader = type => {
  switch (type) {
    case DonationApi.transactionTypes.DEPOSIT:
      return 'Donated'
    case DonationApi.transactionTypes.LEADER:
      return 'Host leaderboard'
    case DonationApi.transactionTypes.PLEDGE:
      return 'Pledged'
    case DonationApi.transactionTypes.VIP:
      return 'VIP'
    case DonationApi.transactionTypes.PROSPECT:
      return 'Prospects'
    case DonationApi.transactionTypes.CONTACTED:
      return 'Contacted'
    case DonationApi.transactionTypes.ASKED:
      return 'Asked'
  }
}

const typeGroupToDragCol = (role, type, transactions) => {
  return {
    header: getTypeHeader(type),
    isDropDisabled:
      isBundler(role) && type === DonationApi.transactionTypes.DEPOSIT,
    isDragDisabled:
      isBundler(role) && type === DonationApi.transactionTypes.DEPOSIT,
    dragListItems: transactions
      .filter(item => item !== undefined)
      .map(tran => ({
        id: tran['_id'],
        prefix: '',
        content: {
          user: tran['donor'],
          donation: tran['amount'],
          picture: (tran['donor'] || {})['picture'],
          eventId: tran['eventId'],
          color: tran['color'],
          transaction: tran,
        },
      })),
  }
}

const transactionsToDragData = (role, transactions) => {
  const typeSorted = sortBy(prop('type'), transactions)
  const typeGrouped = groupBy(prop('type'), typeSorted)
  const prospects = isNil(typeGrouped['prospect'])
    ? {
        header: 'Prospects',
        isDropDisabled: false,
        isDragDisabled: false,
        dragListItems: [],
      }
    : typeGroupToDragCol(role, 'prospect', typeGrouped['prospect'])
  const contacted = isNil(typeGrouped['contacted'])
    ? {
        header: 'Contacted',
        isDropDisabled: false,
        isDragDisabled: false,
        dragListItems: [],
      }
    : typeGroupToDragCol(role, 'contacted', typeGrouped['contacted'])
  const asked = isNil(typeGrouped['asked'])
    ? {
        header: 'Asked',
        isDropDisabled: false,
        isDragDisabled: false,
        dragListItems: [],
      }
    : typeGroupToDragCol(role, 'asked', typeGrouped['asked'])
  const pledged = isNil(typeGrouped['pledge'])
    ? {
        header: 'Pledged',
        isDropDisabled: false,
        isDragDisabled: false,
        dragListItems: [],
      }
    : typeGroupToDragCol(role, 'pledge', typeGrouped['pledge'])
  const paid = isNil(typeGrouped['deposit'])
    ? {
        header: 'Donated',
        isDropDisabled: false,
        isDragDisabled: false,
        dragListItems: [],
      }
    : typeGroupToDragCol(role, 'deposit', typeGrouped['deposit'])
  const cols = [prospects, contacted, asked, pledged, paid]
  return cols
}

const columnToTransactionType = destColumn =>
  destColumn === 'Prospects'
    ? DonationApi.transactionTypes.PROSPECT
    : destColumn === 'VIP'
    ? 'vip'
    : destColumn === 'Paid'
    ? 'deposit'
    : destColumn === 'Asked'
    ? DonationApi.transactionTypes.ASKED
    : destColumn === 'Donated'
    ? DonationApi.transactionTypes.DEPOSIT
    : destColumn === 'Contacted'
    ? DonationApi.transactionTypes.CONTACTED
    : DonationApi.transactionTypes.PLEDGE

const Pipeline = ({ eventSelect, setEventList }) => {
  const dispatch = useDispatch()
  const role = useSelector(getUserRole)
  const [eventTransactions, setEventTransactions] = useState([])
  const dataColumns = transactionsToDragData(role, eventTransactions)
  const entitledData = dataColumns
  const [filterData, setFilterData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const refreshDonations = useSelector(getRefreshDonations)
  const user = useSelector(getUser)

  const [launchingModal, setLauchingModal] = useState(false)

  const handleDrag = dragResult => {
    const {
      source,
      destination,
      reason,
      dragItem: {
        content: { transaction },
      },
    } = dragResult
    if (source.droppableId === destination.droppableId || reason !== 'DROP')
      return

    const destColumn = destination.droppableId
    const newTransactionType = columnToTransactionType(destColumn)
    const updatedDonation = {
      ...transaction,
      type: newTransactionType,
    }
    DonationApi.updateTransaction(updatedDonation)
  }

  useEffect(() => {
    dispatch(setRefreshDonations(true))
  }, [])

  const handleCloseDonationTypeClick = () =>
    dispatch(setAddDonationTypeModal(initialPipelineState.addDonationTypeModal))

  const handleNewProspect = data => {
    // PayloadDonationType
    const payload = {
      ...data,
      eventId:
        data.eventId !== DEFAULT_GENERAL_EVENT.value ? data.eventId : undefined,
      type: DonationApi.transactionTypes.PROSPECT,
    }
    DonationApi.createTransaction(payload, user).then(() => {
      dispatch(setRefreshDonations(true))
      handleCloseDonationTypeClick()
    })
  }
  const handleAddProspectClick = async () => {
    try {
      setLauchingModal(true)
      const donations = await DonationApi.filterDonations(aggregateOperations)
      const uniqueDonors = donations.map(donation => donation._id)

      const aggregate = [
        ...addFieldToAggregate(),
        {
          $addFields: {
            _idString: { $toString: '$_id' }, // Convert donorId toString and save on _idString,
          },
        },
        {
          $match: {
            _idString: { $nin: uniqueDonors }, // Not in donations pledge or paid
            ...matchQueryBasedOnUserRol(user),
          },
        },
      ]
      const contacts = await ContactApi.filterContacts(aggregate)
      setLauchingModal(false)
      dispatch(
        setAddDonationTypeModal({
          show: true,
          donationType: 'PROSPECT',
          canSelectEvent: true,
          onAdd: handleNewProspect,
          onClose: handleCloseDonationTypeClick,
          getContactsFn: () => contacts,
          mutateEventOptions: options => {
            options.unshift({ ...DEFAULT_GENERAL_EVENT })
            return options
          },
          defaultEventOption: 'general',
        })
      )
    } catch (error) {
      setLauchingModal(false)
    }
  }
  const columnControls = {
    Prospects: (
      <>
        <Button
          type="button"
          variant="outline-secondary"
          className="w-100 mb-2"
          onClick={handleAddProspectClick}
          disabled={launchingModal}
        >
          + Add Prospect
        </Button>
      </>
    ),
  }

  useEffect(() => {
    EventApi.getEvents().then(events => {
      const sorted = sortBy(prop('name'), events)
      setEventList(sorted)
    })
  }, [])

  useEffect(() => {
    let isMounted = true
    if (refreshDonations) {
      DonationApi.getTransactions()
        .then(transactions => {
          if (isMounted) {
            setEventTransactions(transactions)
          }
        })
        .catch(console.log)
        .finally(() => {
          dispatch(setRefreshDonations(false))
          setIsLoading(false)
        })
    }
    return () => {
      isMounted = false
    }
  }, [refreshDonations])

  const pipelineBasedOnEventSelected = (eventSelect, entitledData) => {
    switch (eventSelect) {
      case 'all':
        return entitledData
      case 'general':
        return entitledData.map(
          p =>
            p && {
              ...p,
              dragListItems: p.dragListItems.filter(
                f => f.content.eventId === undefined
              ),
            }
        )
      default:
        return entitledData.map(
          p =>
            p && {
              ...p,
              dragListItems: p.dragListItems.filter(
                f => f.content.eventId === eventSelect
              ),
            }
        )
    }
  }
  useEffect(() => {
    if (entitledData) {
      setFilterData(pipelineBasedOnEventSelected(eventSelect, entitledData))
    }
  }, [eventSelect, eventTransactions])

  return (
    <Row>
      <Col>
        {isLoading && <CenterSpinner />}
        {!isLoading && filterData.length > 0 && (
          <DragList
            key={`DragList-Pipeline-${eventTransactions.length}`}
            dragDataColumns={filterData}
            dragDataColumnsControls={columnControls}
            onDrag={handleDrag}
            dragComponent={item => (
              <PipelineCard
                contact={item.content.user}
                donation={item.content.transaction}
                color={item.content.color}
                picture={item.content.picture}
              />
            )}
          ></DragList>
        )}
      </Col>
    </Row>
  )
}

Pipeline.propTypes = {
  eventSelect: PropTypes.string,
  setEventList: PropTypes.func,
}

const PipelinePage = () => {
  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.PIPELINE(true),
  ]
  usePageHeadings('Pipeline', breadcrumbs)
  const [eventSelect, setEventSelect] = useState('all')
  const [eventList, setEventList] = useState([])
  return (
    <Wrapper>
      <Container fluid>
        <Header onUpdateState={setEventSelect} eventList={eventList} />
        <Pipeline eventSelect={eventSelect} setEventList={setEventList} />
      </Container>
    </Wrapper>
  )
}

export default PipelinePage
