import React from 'react'
import styled from 'styled-components'
import { Col, Row, Stack } from 'react-bootstrap'
import PropTypes from 'prop-types'
import {
  BsEnvelope,
  BsPhone,
  BsTelephone,
  BsPinMap,
  BsLinkedin,
  BsGlobe,
  BsCash,
  BsPerson,
} from 'react-icons/bs'
import CustomAccordion from '../ui-elements/CustomAccordion'

const Styledcontainer = styled.div`
  border-radius: 0.25rem;
  padding: 1rem;
  border-style: solid;
  border-width: 1px;
  border-color: #d9d9d9;
`

const SpanTitle = styled.span`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`

const DivProfile = styled.div`
  > img {
    height: 7.75rem;
    width: 7.75rem;
    border-radius: 8.75rem;
  }
`
const SpanDetails = styled.span`
  font-family: DM Sans;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${props => (props.isGrey ? `#79838d;` : `#3b4248;`)};
`

const SpanLink = styled.a`
  font-family: DM Sans;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  letter-spacing: 0em;
  color: #06988f;
  text-decoration: underline;
`

const EmptyDiv = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

export const SpanDescription = styled.span`
  font-family: DM Sans;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${props => (props.isGrey ? `#79838d;` : `#3b4248;`)};
`

const OrganizationDetails = ({ organization }) => {
  const userIcon = organization?.organizationLogoUrl
    ? organization?.organizationLogoUrl //require(`../../assets/profilePictures/${organization?.picture}.png`)
    : require(`../../assets/profilePictures/unassigned.png`)
  return (
    <Styledcontainer>
      <Row className="pb-4">
        <Col sm={4}>
          <SpanTitle>Organization Details</SpanTitle>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col sm={2}>
              <DivProfile>
                <img src={userIcon} />
              </DivProfile>
            </Col>
            <Col>
              <Row>
                <Col sm={3}>
                  <Stack gap={2}>
                    <Stack gap={2} direction="horizontal">
                      <BsEnvelope size={16} color="#00BDB2" />
                      <SpanDetails>
                        {organization?.emailAccount?.email}
                      </SpanDetails>
                    </Stack>
                    <Stack gap={2} direction="horizontal">
                      <BsTelephone size={16} color="#00BDB2" />
                      {organization?.phone ? (
                        <SpanDetails>{organization?.phone}</SpanDetails>
                      ) : (
                        <SpanDetails isGrey>{`Primary Phone`}</SpanDetails>
                      )}
                    </Stack>
                    <Stack gap={2} direction="horizontal">
                      <BsPhone size={16} color="#00BDB2" />
                      {organization?.phone ? (
                        <SpanDetails>{organization?.phone}</SpanDetails>
                      ) : (
                        <SpanDetails isGrey>{`Secondary Phone`}</SpanDetails>
                      )}
                    </Stack>
                    <Stack gap={2} direction="horizontal">
                      <BsGlobe size={16} color="#00BDB2" />
                      {organization?.url ? (
                        <SpanLink target="_blank" href={organization?.url}>
                          {organization?.url}
                        </SpanLink>
                      ) : (
                        <SpanDetails isGrey>{`Organization URL`}</SpanDetails>
                      )}
                    </Stack>
                  </Stack>
                </Col>
                <Col sm={3}>
                  <Stack gap={2}>
                    <Stack gap={2} direction="horizontal">
                      <BsPinMap size={16} color="#00BDB2" />
                      <SpanDetails>{organization?.state}</SpanDetails>
                    </Stack>
                    <Stack gap={2} direction="horizontal">
                      <EmptyDiv />
                      {organization?.address ? (
                        <SpanDetails>{organization?.address}</SpanDetails>
                      ) : (
                        <SpanDetails isGrey>{`Address`}</SpanDetails>
                      )}
                    </Stack>
                    <Stack gap={2} direction="horizontal">
                      <EmptyDiv />
                      {organization?.address ? (
                        <SpanDetails>{organization?.address2}</SpanDetails>
                      ) : (
                        <SpanDetails isGrey>{`Address 2`}</SpanDetails>
                      )}
                    </Stack>
                    <Stack gap={2} direction="horizontal">
                      <EmptyDiv />
                      {organization?.zipCode ? (
                        <SpanDetails>{organization?.zipCode}</SpanDetails>
                      ) : (
                        <SpanDetails isGrey>{`Zip Code`}</SpanDetails>
                      )}
                    </Stack>
                    {/*<Stack gap={2} direction="horizontal">
                      <EmptyDiv />
                      <SpanLink>View on Google Maps</SpanLink>
                    </Stack>*/}
                    {organization?.linkedInUrl &&
                      organization?.linkedInUrl !== '' && (
                        <Stack gap={2} direction="horizontal">
                          <BsLinkedin size={16} color="#00BDB2" />
                          <SpanLink
                            target="_blank"
                            href={organization?.linkedInUrl}
                          >
                            LinkedIn Profile
                          </SpanLink>
                        </Stack>
                      )}
                    {organization?.routeDonationLink &&
                      organization?.routeDonationLink !== '' && (
                        <Stack gap={2} direction="horizontal">
                          <BsCash size={16} color="#00BDB2" />
                          <SpanLink
                            target="_blank"
                            href={organization?.routeDonationLink}
                          >
                            Donation URL
                          </SpanLink>
                        </Stack>
                      )}
                  </Stack>
                </Col>
                <Col sm={3}>
                  <Stack gap={2}>
                    <Stack gap={2} direction="horizontal">
                      <BsPerson size={16} color="#00BDB2" />
                      {organization?.owner?.name ? (
                        <SpanDetails>{organization?.owner?.name}</SpanDetails>
                      ) : (
                        <SpanDetails isGrey>{`Name`}</SpanDetails>
                      )}
                    </Stack>
                    <Stack gap={2} direction="horizontal">
                      <BsEnvelope size={16} color="#00BDB2" />
                      {organization?.owner?.email ? (
                        <SpanDetails>{organization?.owner?.email}</SpanDetails>
                      ) : (
                        <SpanDetails isGrey>{`Email`}</SpanDetails>
                      )}
                    </Stack>
                    <Stack gap={2} direction="horizontal">
                      <BsTelephone size={16} color="#00BDB2" />
                      {organization?.contact?.primaryPhoneNumber ? (
                        <SpanDetails>
                          {organization?.contact?.primaryPhoneNumber}
                        </SpanDetails>
                      ) : (
                        <SpanDetails isGrey>{`Primary Phone`}</SpanDetails>
                      )}
                    </Stack>
                  </Stack>
                </Col>
                <Col sm={3} />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="pt-4">
        <CustomAccordion showContentsOnTop>
          <Col>
            <Row className="pb-2">
              <Col sm={12}>
                <SpanTitle>About the Organization </SpanTitle>
              </Col>
            </Row>
            <Row className="pb-2">
              <Col sm={12}>
                {organization?.about ? (
                  <SpanDescription>{organization?.about}</SpanDescription>
                ) : (
                  <SpanDescription isGrey>{`Bio`}</SpanDescription>
                )}
              </Col>
            </Row>
          </Col>
        </CustomAccordion>
      </Row>
    </Styledcontainer>
  )
}

OrganizationDetails.propTypes = {
  organization: PropTypes.object,
}

export default OrganizationDetails
