import PropTypes from 'prop-types'
import { isStafferOrOwnerOrCRM } from '../../utils/state/roleTests'
import RoleViewScope from './RoleViewScope'

const StafferViewScope = ({ children }) => (
  <RoleViewScope roleTestCriteria={isStafferOrOwnerOrCRM}>
    {children}
  </RoleViewScope>
)

StafferViewScope.propTypes = {
  children: PropTypes.node,
}

export default StafferViewScope
