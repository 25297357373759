const ComposeIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2554_224852)">
        <rect
          width="16"
          height="16"
          transform="translate(0 0.5)"
          fill="white"
          fillOpacity="0.01"
        />
        <g clipPath="url(#clip1_2554_224852)">
          <path
            d="M12.1458 0.644941C12.1923 0.598377 12.2474 0.561435 12.3082 0.536228C12.3689 0.511022 12.434 0.498047 12.4998 0.498047C12.5656 0.498047 12.6307 0.511022 12.6914 0.536228C12.7522 0.561435 12.8074 0.598377 12.8538 0.644941L15.8538 3.64494C15.9004 3.69139 15.9373 3.74656 15.9625 3.80731C15.9877 3.86805 16.0007 3.93317 16.0007 3.99894C16.0007 4.06471 15.9877 4.12983 15.9625 4.19057C15.9373 4.25132 15.9004 4.3065 15.8538 4.35294L5.85381 14.3529C5.80582 14.4006 5.74867 14.438 5.68581 14.4629L0.685806 16.4629C0.594942 16.4993 0.495402 16.5082 0.399526 16.4885C0.303649 16.4689 0.215653 16.4215 0.146447 16.3523C0.0772403 16.2831 0.0298668 16.1951 0.0101994 16.0992C-0.0094681 16.0033 -0.000564594 15.9038 0.0358061 15.8129L2.03581 10.8129C2.06073 10.7501 2.09816 10.6929 2.14581 10.6449L12.1458 0.644941ZM11.2068 2.99894L13.4998 5.29194L14.7928 3.99894L12.4998 1.70594L11.2068 2.99894ZM12.7928 5.99894L10.4998 3.70594L3.99981 10.2059V10.4989H4.49981C4.63241 10.4989 4.75959 10.5516 4.85336 10.6454C4.94713 10.7392 4.99981 10.8663 4.99981 10.9989V11.4989H5.49981C5.63241 11.4989 5.75959 11.5516 5.85336 11.6454C5.94713 11.7392 5.99981 11.8663 5.99981 11.9989V12.4989H6.29281L12.7928 5.99894ZM3.03181 11.1739L2.92581 11.2799L1.39781 15.1009L5.21881 13.5729L5.32481 13.4669C5.22943 13.4313 5.1472 13.3674 5.08912 13.2838C5.03104 13.2001 4.99988 13.1008 4.99981 12.9989V12.4989H4.49981C4.3672 12.4989 4.24002 12.4463 4.14625 12.3525C4.05248 12.2587 3.99981 12.1315 3.99981 11.9989V11.4989H3.49981C3.39799 11.4989 3.29862 11.4677 3.21498 11.4096C3.13135 11.3515 3.06744 11.2693 3.03181 11.1739Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_2554_224852">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
        <clipPath id="clip1_2554_224852">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ComposeIcon
