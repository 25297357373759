import { always, ifElse, prop } from 'ramda'
import PropTypes from 'prop-types'
import { keyframes, css } from 'styled-components'
import styled from 'styled-components'
import { useNavigate, useLocation } from 'react-router-dom'
import { Stack } from 'react-bootstrap'
import companyLogo from '../../assets/logos/logo-long.png'
import {
  BsFillArrowLeftCircleFill,
  BsHouseDoor,
  BsEnvelope,
  BsPerson,
  // BsCurrencyDollar,
  BsCalendar,
  // BsBarChart,
  BsCalendarWeek,
  BsBullseye,
  BsCurrencyDollar,
  BsCash,
} from 'react-icons/bs'
import { getUserRole } from '../../utils/state/selectors'
import { useSelector } from 'react-redux'
import { isStaffer, isCRM } from '../../utils/state/roleTests'

const enter = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`

const update = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`
const MenuLeftHandleBarFill = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  background-color: #11273c;
  color: #ffffff;
  &:hover {
    cursor: pointer;
  }
`
MenuLeftHandleBarFill.propTypes = {
  isMenuVisible: PropTypes.bool,
}

const TopIconWrapper = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  padding-top: 30px;
  padding-bottom: 20px;
  img {
    height: 30px;
  }
  animation: ${({ toggle }) =>
    toggle
      ? css`
          ${update} 1s ease-out forwards
        `
      : css`
          ${enter} 3s ease-out forwards
        `};
`
const IconWrapper = styled.div`
  padding: 10px 105px 10px 12px;
  border-radius: 5px;
  background-color: ${ifElse(
    prop('isActive'),
    always('#233c50'),
    always('#11273c')
  )};
  span {
    color: ${ifElse(prop('isActive'), always('white'), always('#7e919f'))};
  }
  svg {
    color: ${ifElse(prop('isActive'), always('white'), always('#7e919f'))};
  }
  animation: ${({ toggle }) =>
    toggle
      ? css`
          ${update} 1s ease-out forwards
        `
      : css`
          ${enter} 3s ease-out forwards
        `};
`
IconWrapper.propTypes = {
  isActive: PropTypes.bool,
}

const ArrowIconWrapper = styled.div`
  padding-top: 22px;
  display: inline-block;
  vertical-align: top;
  color: white;
  svg {
    filter: drop-shadow(1px 1px 1px #7e919f);
  }
  animation: ${({ toggle }) =>
    toggle
      ? css`
          ${update} 1s ease-out forwards
        `
      : css`
          ${enter} 3s ease-out forwards
        `};
`

const OptionLink = styled.div`
  text-decoration: none;
  &:hover {
    color: white;
  }
  color: #7e919f;
`

const MenuBarContent = ({ onClose, isMenuVisible }) => {
  let { pathname } = useLocation()
  const navigate = useNavigate()

  const userRole = useSelector(getUserRole)

  const handleOptionClick = url => {
    navigate(url)
    onClose()
  }

  return (
    <>
      <MenuLeftHandleBarFill>
        <Stack direction="horizontal">
          <Stack gap={1} style={{ paddingTop: '0px', paddingLeft: '10px' }}>
            <TopIconWrapper toggle={isMenuVisible}>
              <OptionLink onClick={() => handleOptionClick('/')}>
                <img src={companyLogo} />
              </OptionLink>
            </TopIconWrapper>
            <IconWrapper toggle={isMenuVisible} isActive={pathname === '/'}>
              <OptionLink onClick={() => handleOptionClick('/')}>
                <Stack gap={2} direction="horizontal">
                  <BsHouseDoor /> <span>Dashboard</span>
                </Stack>
              </OptionLink>
            </IconWrapper>
            <IconWrapper toggle={isMenuVisible}>
              <OptionLink onClick={() => handleOptionClick('/mailbox')}>
                <Stack gap={2} direction="horizontal">
                  <BsEnvelope /> <span>Email</span>
                </Stack>
              </OptionLink>
            </IconWrapper>
            <IconWrapper
              toggle={isMenuVisible}
              isActive={pathname === '/contacts/'}
            >
              <OptionLink onClick={() => handleOptionClick('/contacts/')}>
                <Stack gap={2} direction="horizontal">
                  <BsPerson /> <span>Contacts</span>
                </Stack>
              </OptionLink>
            </IconWrapper>
            {(isStaffer(userRole) || isCRM(userRole)) && (
              <>
                <IconWrapper toggle={isMenuVisible}>
                  <OptionLink onClick={() => handleOptionClick('/donations/')}>
                    <Stack gap={2} direction="horizontal">
                      <BsCurrencyDollar /> <span>Donations</span>
                    </Stack>
                  </OptionLink>
                </IconWrapper>
              </>
            )}
            <IconWrapper
              toggle={isMenuVisible}
              isActive={pathname === '/calendar/'}
            >
              <OptionLink onClick={() => handleOptionClick('/calendar/')}>
                <Stack gap={2} direction="horizontal">
                  <BsCalendar /> <span>Calendar</span>
                </Stack>
              </OptionLink>
            </IconWrapper>
            {/* <IconWrapper toggle={isMenuVisible}>
              <OptionLink>
                <Stack gap={2} direction="horizontal">
                  <BsBarChart /> <span>Reports</span>
                </Stack>
              </OptionLink>
            </IconWrapper> */}
            <IconWrapper
              toggle={isMenuVisible}
              isActive={pathname === '/events/all'}
            >
              <OptionLink onClick={() => handleOptionClick('events/all')}>
                <Stack gap={2} direction="horizontal">
                  <BsCalendarWeek /> <span>Events</span>
                </Stack>
              </OptionLink>
            </IconWrapper>
            <IconWrapper
              toggle={isMenuVisible}
              isActive={pathname === '/pipeline'}
            >
              <OptionLink onClick={() => handleOptionClick('pipeline')}>
                <Stack gap={2} direction="horizontal">
                  <BsBullseye /> <span>Pipeline</span>
                </Stack>
              </OptionLink>
            </IconWrapper>
          </Stack>
          <Stack>
            <ArrowIconWrapper toggle={isMenuVisible}>
              <BsFillArrowLeftCircleFill onClick={onClose} size={24} />
            </ArrowIconWrapper>
          </Stack>
        </Stack>
      </MenuLeftHandleBarFill>
    </>
  )
}

MenuBarContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  isMenuVisible: PropTypes.bool.isRequired,
}

export default MenuBarContent
