import { ReactNode } from 'react'
import { Form, FormControlProps } from 'react-bootstrap'
import RedAsterisk from '../../ui-elements/RedAsterisk'
import { Path, UseFormReturn } from 'react-hook-form'
import { ObjectSchema, AnyObject } from 'yup'
import { isFieldRequired, isValid } from '../utils'
import { HTMLInputTypeAttribute } from 'react'

// To FormControlProps Read https://react-bootstrap.github.io/forms/form-control/

interface InputFormProps<T extends AnyObject>
  extends Omit<FormControlProps, 'id' | 'type' | 'required'> {
  id: keyof T
  type?: HTMLInputTypeAttribute | 'PatternFormat' // The HTML input type, which is only relevant if as is 'input' (the default).
  hookForm: UseFormReturn<T>
  validationSchema: ObjectSchema<T>
  label?: string
  labelClassName?: string
  labelComponent?: ReactNode
  containerClassName?: string
}

// Works with HTMLInputElement | HTMLTextAreaElement;
export function InputForm<T extends AnyObject>({
  id,
  hookForm,
  label,
  labelComponent,
  validationSchema,
  labelClassName = '',
  containerClassName = '',
  ...rest
}: InputFormProps<T>) {
  const { formState, getValues, register } = hookForm
  const { errors } = formState
  const registerId = id as Path<T>

  const noLabel = !label
  const noLabelComponent = !labelComponent

  return (
    <Form.Group className={containerClassName}>
      {label && noLabelComponent && (
        <Form.Label htmlFor={String(id)} className={labelClassName}>
          {label}
          {isFieldRequired(validationSchema, id, getValues) && <RedAsterisk />}
        </Form.Label>
      )}
      {noLabel && labelComponent && labelComponent}

      <Form.Control
        id={String(id)}
        isInvalid={!!errors[id]}
        isValid={isValid(id, formState)}
        {...rest}
        {...register(registerId)}
      />

      {errors[id]?.message && (
        <Form.Control.Feedback type="invalid">
          {String(errors[id]?.message)}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  )
}
export default InputForm
