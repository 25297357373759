export enum USER_ROL {
  ADMIN = 'admin',
  BUNDLER = 'bundler',
  STAFFER = 'staffer',
  PRINCIPAL = 'principal',
  OWNER = 'owner',
  CRM = 'crm',
}
export enum PROVIDER_ACCOUNT {
  GOOGLE = 'gmail',
  GRAPH = 'graph',
}

export const PROVIDER_OPTIONS = [
  { value: 'google', label: 'Google' },
  { value: 'azure', label: 'Microsoft' },
]

export const CONTACT_LEVELS = {
  CONTACT: 'Contact',
  BUNDLER: 'Bundler',
  STAFFER: 'Staffer',
  OWNER: 'Owner',
  ADMIN: 'Admin',
  CRM: 'CRM',
}

export enum DONATION_TYPE {
  DEPOSIT = 'deposit',
  PLEDGE = 'pledge',
  LEADER = 'leader',
  VIP = 'vip',
  PROSPECT = 'prospect',
  CONTACTED = 'contacted',
  ASKED = 'asked',
}

export enum DONATION_STATUS {
  NOT_STARTED = 'not started',
  ASKED = 'asked',
  CONTACTED = 'contacted',
  MEETING_SET = 'meeting set',
  IN_PROGRESS = 'in progress',
  PLEDGED = 'pledged',
  PAID = 'paid',
  COMPLETED = 'completed',
}

export enum contact_source_types {
  CUSTOMER = 'CUSTOMER',
  PURCHASE = 'PURCHASE',
}
