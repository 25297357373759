import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { flatten, map, pipe, prop, propEq } from 'ramda'
import {
  Accordion,
  Button,
  Col,
  Container,
  Row,
  Stack,
  Toast,
} from 'react-bootstrap'
import {
  getUser,
  getUserId,
  getUserOrganizationId,
} from '../../utils/state/selectors'
import Api from '../../api/event'
import EventForm from './EventForm'
import TiersTable from './TiersTable'
import AddTierForm from './AddTierForm'
import AddGuestsForm from './AddGuestsForm'
import GuestsTable from './GuestsTable'
//import AddTasksForm from './AddTasksForm'
import TasksTable from './TasksTable'
import TitleBreadcrumbs from '../ui-elements/TitleBreadcrumbs'
import { mainBreadcrumbs } from '../../config/mainBreadcrumbs'
import SummaryModal from './SummaryModal'
import taskDescriptions from '../../assets/datasets/task-descriptions.json'
import ToastWarningFields from './ToastWarningFields'
import { sendInviteEvent } from './InviteEventEmail'
import { getUserToken } from '../../utils/dataModels/user'
import { createRoomSendMessage } from '../../services/chatroom.service'
import UsersApi from '../../api/user'
import { useDispatch } from 'react-redux'
import { setChatRoom } from '../../redux/actions/chat'
import { USER_ROL } from '../../api/enums'

const initialEventFormData = {
  name: '',
  state: '',
  city: '',
  street: '',
  day: '',
  hour: '',
  venue: '',
  venueTBD: false,
  locationRSVP: false,
  financialGoal: 0,
  suggestedDonation: 0,
  suggestedDonationTBD: false,
}
const Wrapper = styled.div`
  padding: 1rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`
const FormsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`

const formModelToDBModel = ({ eventFormData, tiers, attendees, tasks }) => ({
  eventData: eventFormData,
  tiers,
  aggregates: {
    attendees,
    tasks,
  },
})

const CreateEvent = () => {
  const organizationId = useSelector(getUserOrganizationId)
  const userProvider = getUserToken()
  const dispatch = useDispatch()

  //usePageHeadings('Create event', [])
  const user = useSelector(getUser)
  const userId = useSelector(getUserId)
  const userEmail = userProvider?.email || 'benjaminapp.bmail@gmail.com'
  const [recentEvents, setRecentEvents] = useState([])
  const [uniqueNameAlert, setUniqueNameAlert] = useState(false)
  const [eventModel, setEventModel] = useState({
    eventFormData: initialEventFormData,
    tiers: [],
    attendees: { staffers: [], bundlers: [], specialGuests: [] },
    tasks: [],
  })
  const [checkEvent, setCheckEvent] = useState(false)
  const [missingField, setMissingField] = useState([])
  const navigate = useNavigate()

  const breadcrumbs = [
    mainBreadcrumbs.HOME(false),
    mainBreadcrumbs.EVENTS(false),
    mainBreadcrumbs.CREATE_EVENT(true),
  ]

  const [showToast, setShowToast] = useState(false)

  const [show, setShow] = useState(false)

  const toggleShow = () => setShow(!show)

  useEffect(() => {
    setEventModel(model => ({
      ...model,
      tasks: taskDescriptions.map(task => {
        return {
          _id: nanoid(),
          contact: '',
          description: task,
          markCompleted: false,
        }
      }),
    }))
  }, [taskDescriptions])

  useEffect(() => {
    setMissingField([])
    const { eventFormData } = eventModel
    if (eventFormData) {
      const {
        name,
        state,
        city,
        day,
        hour,
        venue,
        locationRSVP,
        venueTBD,
        financialGoal,
        suggestedDonation,
      } = eventFormData
      const checkEmpty = checkDifferentEmpty({
        name,
        state,
        city,
        day,
        hour,
      })
      const checkInt = financialGoal != 0 && suggestedDonation != 0
      const checkVenue = venue !== '' || locationRSVP || venueTBD
      const nameExists = recentEvents.find(event => event.name === name)
      if (checkEmpty && checkVenue && checkInt) {
        setCheckEvent(true)
      } else {
        if (nameExists) {
          setUniqueNameAlert(true)
        } else {
          setUniqueNameAlert(false)
        }
        if (name == '') {
          setMissingField(oldArray => [...oldArray, 'Event Name'])
        }
        if (venue === '' && !locationRSVP && !venueTBD) {
          setMissingField(oldArray => [
            ...oldArray,
            'Withhold Venue Details OR Venue OR Location to be determined',
          ])
        }
        if (city == '') {
          setMissingField(oldArray => [...oldArray, 'City'])
        }
        if (state == '') {
          setMissingField(oldArray => [...oldArray, 'State'])
        }
        if (day == '') {
          setMissingField(oldArray => [...oldArray, 'Date'])
        }
        if (hour == '') {
          setMissingField(oldArray => [...oldArray, 'Time'])
        }
        if (financialGoal == 0) {
          setMissingField(oldArray => [...oldArray, 'Goal'])
        }
        if (suggestedDonation == 0) {
          setMissingField(oldArray => [...oldArray, 'Donation Level'])
        }
        setCheckEvent(false)
      }
    }
  }, [eventModel])

  const checkDifferentEmpty = objFields => {
    return Object.values(objFields).every(value => value != '')
  }

  const eventFormChangeHandler = updatedData =>
    setEventModel(m => ({
      ...m,
      eventFormData: { ...m.eventFormData, ...updatedData },
    }))

  const addTierHandler = tierData => {
    const newTier = {
      //_id: nanoid(),
      ...tierData,
    }
    setEventModel(model => ({
      ...model,
      tiers: [...model.tiers, newTier],
    }))
  }

  const removeTierHandler = tierId =>
    setEventModel(m => ({
      ...m,
      tiers: m.tiers.reduce(
        (acc, tier) => (tier._id === tierId ? acc : [...acc, tier]),
        []
      ),
    }))

  const editTierHandler = updatedTier => {
    const tierId = prop('_id', updatedTier)
    const tierList = eventModel.tiers
    const updateIndex = tierList.findIndex(propEq('_id', tierId))
    const updatedTierList = [
      ...tierList.slice(0, updateIndex),
      updatedTier,
      ...tierList.slice(updateIndex + 1),
    ]
    setEventModel(model => ({
      ...model,
      tiers: updatedTierList,
    }))
  }

  const addGuestHandler = guestData => {
    const newGuest = {
      //_id: nanoid(),
      ...guestData,
    }
    setEventModel(model => ({
      ...model,
      attendees: {
        ...model.attendees,
        specialGuests: [...model.attendees.specialGuests, newGuest],
      },
    }))
  }

  const removeGuestHandler = guestId =>
    setEventModel(model => ({
      ...model,
      attendees: {
        ...model.attendees,
        specialGuests: model.attendees.specialGuests.reduce(
          (acc, guest) => (guest._id === guestId ? acc : [...acc, guest]),
          []
        ),
      },
    }))

  const editGuestHandler = updatedGuest => {
    const guestId = prop('_id', updatedGuest)
    const guestList = eventModel.attendees.specialGuests
    const updateIndex = guestList.findIndex(propEq('_id', guestId))
    const updatedGuestList = [
      ...guestList.slice(0, updateIndex),
      updatedGuest,
      ...guestList.slice(updateIndex + 1),
    ]
    setEventModel(model => ({
      ...model,
      attendees: {
        ...model.attendees,
        specialGuests: updatedGuestList,
      },
    }))
  }

  /*const addTaskHandler = taskData => {
    const newTask = {
      _id: nanoid(),
      ...taskData,
    }
    setEventModel(m => ({
      ...m,
      tasks: [...m.tasks, newTask],
    }))
  }

  const removeTaskHandler = taskId =>
    setEventModel(m => ({
      ...m,
      tasks: m.tasks.reduce(
        (acc, task) => (task._id === taskId ? acc : [...acc, task]),
        []
      ),
    }))*/

  const editTaskHandler = updatedTask => {
    const taskId = prop('_id', updatedTask)
    const taskList = eventModel.tasks
    const updateIndex = taskList.findIndex(propEq('_id', taskId))
    const updatedTaskList = [
      ...taskList.slice(0, updateIndex),
      updatedTask,
      ...taskList.slice(updateIndex + 1),
    ]
    setEventModel(model => ({
      ...model,
      tasks: updatedTaskList,
    }))
  }

  const onEditAllTaskHandler = updatedTask => {
    const taskId = prop('_id', updatedTask)
    const taskList = eventModel.tasks
    const updateIndex = taskList.findIndex(propEq('_id', taskId))
    const updatedTaskList = [
      ...taskList.slice(0, updateIndex),
      updatedTask,
      ...taskList.slice(updateIndex + 1),
    ]
    setEventModel(model => ({
      ...model,
      tasks: updatedTaskList.map(task => {
        return {
          ...task,
          contact:
            !task.contact || task.contact === ''
              ? updatedTask.contact
              : task.contact,
        }
      }),
    }))
  }

  const createChatRoom = async (eventName, userId) => {
    try {
      const users = await Promise.all([
        (UsersApi.getUsers(
          sessionStorage.getItem('Org-Session'),
          USER_ROL.BUNDLER
        ), // get bundler role users
        UsersApi.getUsers(
          sessionStorage.getItem('Org-Session'),
          USER_ROL.STAFFER
        )), // get staffer role users
      ])
      const userIds = pipe(flatten, map(prop('_id')))(users)
      await createRoomSendMessage({
        userList: [userId, ...userIds],
        userOriginId: userId,
        chatName: `Event ${eventName}`,
        message: `Welcome to ${eventName} chat`,
      }).then(chat => {
        if (chat?.chatName) {
          let currChat = {
            picture: 'GroupChat',
            name: chat.chatName,
            chat: { ...chat },
            isGroup: true,
          }
          dispatch(setChatRoom(currChat))
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const saveHandler = () => {
    if (checkEvent) {
      const event = { ...formModelToDBModel(eventModel), organizationId }
      Api.createEvent(event, user)
        .then(prop('_id'))
        .then(async id => {
          // send email to participants
          try {
            sendInviteEvent(
              eventModel.tiers,
              eventModel.eventFormData,
              userEmail,
              userProvider?.token,
              id
            )
          } catch (error) {
            console.log(error)
          }
          return id
        })
        // .then(id => {
        //   try {
        //     // create chat room group with bundlers and staffers that belong to organization
        //     createChatRoom(event.eventData.name, userId)
        //   } catch (error) {
        //     console.log(error)
        //   }
        //   return id
        // })
        .then(id => navigate(`/events/${id}`))
        .catch(err => console.log(err))
    } else {
      console.log(missingField)
      setShowToast(true)
    }
  }

  const cancelHandler = () => navigate('/', { replace: true })

  useEffect(() => {
    if (!recentEvents.length) {
      Api.getEvents().then(events => {
        setRecentEvents(events)
      })
    }
    setEventModel({
      ...eventModel,
      eventFormData: {
        ...eventModel.eventFormData,
        generateEventCode: true,
      },
    })
  }, [])

  return (
    <>
      <ToastWarningFields
        show={showToast}
        setShow={setShowToast}
        fields={missingField}
      />
      <TitleBreadcrumbs title={'Create Event'} breadcrumbs={breadcrumbs}>
        <Button onClick={() => cancelHandler()} variant="light">
          Cancel
        </Button>
        <Button
          variant="secondary"
          onClick={() => toggleShow()}
          disabled={uniqueNameAlert}
        >
          Create
        </Button>
      </TitleBreadcrumbs>
      <Wrapper>
        <Container fluid>
          <Row>
            <Col>
              <FormsWrapper>
                <Accordion defaultActiveKey="0" className="w-100">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>General</Accordion.Header>
                    <Accordion.Body>
                      <Toast show={uniqueNameAlert}>
                        <Toast.Header closeButton={false}>
                          The event name&nbsp;
                          <strong>{eventModel.eventFormData.name}</strong>
                          &nbsp;is already taken.
                        </Toast.Header>
                      </Toast>
                      <EventForm
                        formData={eventModel.eventFormData}
                        onChange={eventFormChangeHandler}
                        updateFlag={false}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Tiers</Accordion.Header>
                    <Accordion.Body>
                      <div className="mb-4" />
                      <TiersTable
                        tiers={eventModel.tiers}
                        onEdit={editTierHandler}
                        onRemove={removeTierHandler}
                      />
                      <div className="mb-1" />
                      <AddTierForm onAddTier={addTierHandler} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Special Guests</Accordion.Header>
                    <Accordion.Body>
                      <div className="mb-4" />
                      <GuestsTable
                        guests={eventModel.attendees.specialGuests}
                        onEdit={editGuestHandler}
                        onRemove={removeGuestHandler}
                      />
                      <div className="mb-1" />
                      <AddGuestsForm onAddGuest={addGuestHandler} />
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Tasks</Accordion.Header>
                    <Accordion.Body>
                      <div className="mb-4" />
                      <TasksTable
                        tasks={eventModel.tasks}
                        onEdit={editTaskHandler}
                        onEditAll={onEditAllTaskHandler}
                      />
                      <div className="mb-1" />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </FormsWrapper>
            </Col>
          </Row>
          <SummaryModal
            show={show}
            onClose={() => toggleShow()}
            onCreate={() => saveHandler()}
            event={eventModel}
          />
        </Container>
      </Wrapper>
    </>
  )
}

export default CreateEvent
