import PropTypes from 'prop-types'
import { always, ifElse, prop } from 'ramda'
import { ToggleButton } from 'react-bootstrap'
import { BsInfoCircle, BsPersonSquare } from 'react-icons/bs'
import styled from 'styled-components'
import logo from '../../assets/logos/logo.svg'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  width: 6rem;
  height: 100vh;
  margin: 0;
  padding: 2rem 0;
  background-color: #0c243c;
`

const BarButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  padding: 2rem 0;
`
const StyledToggleButton = styled(ToggleButton)`
  background-color: ${ifElse(
    prop('isActive'),
    always('#113C65'),
    always('#0c243c')
  )} !important;

  border-radius: 4px;
`

StyledToggleButton.propTypes = {
  isActive: PropTypes.bool,
}

const RightBar = ({ selectedOption, onSelectOption }) => {
  const changeHandler = e => onSelectOption(e.target.value)
  const isPanelCollapsed = selectedOption === 'collapsed-panel'

  return (
    <Wrapper>
      <div>
        <img src={logo} width="26" />
      </div>
      <BarButtonsWrapper>
        <StyledToggleButton
          type="checkbox"
          id="viewToggle-1"
          name="viewToggle"
          value="collapsed-panel"
          checked={isPanelCollapsed}
          active={isPanelCollapsed}
          onChange={changeHandler}
          className="mb-1"
        >
          <BsPersonSquare />
        </StyledToggleButton>
        <StyledToggleButton
          type="checkbox"
          id="viewToggle-2"
          name="viewToggle"
          value="vertical-panel"
          checked={!isPanelCollapsed}
          active={!isPanelCollapsed}
          onChange={changeHandler}
        >
          <BsInfoCircle />
        </StyledToggleButton>
      </BarButtonsWrapper>
    </Wrapper>
  )
}

RightBar.propTypes = {
  selectedOption: PropTypes.string.isRequired,
  onSelectOption: PropTypes.func.isRequired,
}

export default RightBar
