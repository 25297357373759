import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getUserRole } from '../../utils/state/selectors'

const RoleViewScope = ({ roleTestCriteria, children }) => {
  const role = useSelector(getUserRole)
  const authorized = roleTestCriteria(role) || false
  return authorized ? children : null
}

RoleViewScope.propTypes = {
  roleTestCriteria: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default RoleViewScope
