export const filterAggregate = [
  {
    $addFields: {
      donorRef: { $toObjectId: '$donorId' },
    },
  },
  {
    $lookup: {
      from: 'contacts',
      localField: 'donorRef',
      foreignField: '_id',
      as: 'donor',
    },
  },
  {
    $addFields: {
      eventRef: { $toObjectId: '$eventId' },
    },
  },
  {
    $lookup: {
      from: 'events',
      localField: 'eventRef',
      foreignField: '_id',
      as: 'event',
    },
  },
  {
    $project: {
      event: { $arrayElemAt: ['$event', 0] },
      amount: 1,
      bundlers: 1,
      color: 1,
      createdAt: 1,
      date: 1,
      donor: { $arrayElemAt: ['$donor', 0] },
      donorId: 1,
      eventId: 1,
      organizationId: 1,
      type: 1,
      _id: 1,
      updatedAt: 1,
    },
  },
]
