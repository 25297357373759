import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BsChevronRight, BsPerson } from 'react-icons/bs'
import { Button, CloseButton } from 'react-bootstrap'
import Countdown from 'react-countdown'
import Asks from './Asks'
import { useEffect, useState } from 'react'

const RightPanelWrapper = styled.div`
  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  gap: 1rem;
  width: 18.625rem;
  min-width: 18.625rem;
  max-width: 18.625rem;
  height: 100vh;
  background: #fff;
  border: 1px solid #dfe4e9;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
  overflow: scroll;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1rem;
  gap: 1rem;
  align-self: stretch;
`

const SectionTitle = styled.div`
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
`

const CountdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 0.5rem;
  align-self: stretch;
  width: 100%;
`

const CountSeparator = styled.div`
  width: 1px;
  height: 3.875rem;
  background: #e6e6e6;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`

const CountItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 0.5rem;
  flex: none;
  flex-grow: 1;
`

const CountValue = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.9375rem;
  display: flex;
  align-items: center;
  text-align: center;
  flex: none;
  flex-grow: 1;
  color: ${p => p.color};
`

const CountDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`

const Completionist = () => (
  <CountdownWrapper>
    <CountItem>
      <CountValue color={'red'}>{`00`}</CountValue>
      <CountDescription>Hours</CountDescription>
    </CountItem>
    <CountSeparator />
    <CountItem>
      <CountValue color={'red'}>{`00`}</CountValue>
      <CountDescription>Minutes</CountDescription>
    </CountItem>
    <CountSeparator />
    <CountItem>
      <CountValue color={'red'}>{`00`}</CountValue>
      <CountDescription>Seconds</CountDescription>
    </CountItem>
  </CountdownWrapper>
)

// Renderer callback with condition
const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
  const color = minutes < 5 ? '#E65454' : 'inherit'
  if (completed) {
    // Render a completed state
    return <Completionist />
  } else {
    // Render a countdown
    return (
      <CountdownWrapper>
        <CountItem>
          <CountValue color={color}>
            {hours.toString().length === 1 ? '0' + hours : hours}
          </CountValue>
          <CountDescription>Hours</CountDescription>
        </CountItem>
        <CountSeparator />
        <CountItem>
          <CountValue color={color}>
            {minutes.toString().length === 1 ? '0' + minutes : minutes}
          </CountValue>
          <CountDescription>Minutes</CountDescription>
        </CountItem>
        <CountSeparator />
        <CountItem>
          <CountValue color={color}>
            {seconds.toString().length === 1 ? '0' + seconds : seconds}
          </CountValue>
          <CountDescription>Seconds</CountDescription>
        </CountItem>
      </CountdownWrapper>
    )
  }
}

const SectionName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 1rem;
  gap: 0.5rem;
  align-self: stretch;
`

const StateDiv = styled.div`
  font-size: 0.875rem;
`

const getAddressInfo = contact => {
  const { state, city, address } = contact
  const addressParts = [state, city, address].filter(Boolean)
  return addressParts.join(' ')
}

const getFullName = contact => {
  const { first_name, last_name } = contact
  const addressParts = [first_name, last_name].filter(Boolean)
  return addressParts.join(' ')
}

const NameState = ({ contact }) => {
  return (
    <SectionName>
      <SectionTitle>{getFullName(contact)}</SectionTitle>
      <StateDiv>{getAddressInfo(contact)}</StateDiv>
    </SectionName>
  )
}

NameState.propTypes = {
  contact: PropTypes.object,
}

const TagList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 0px;
  gap: 0.5rem;
  align-self: stretch;
`

const Tag = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.16rem 0.5rem;
  gap: 0.25rem;
  background: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 4px;
  flex: none;
  flex-grow: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 150%;
  color: #212529;
`

const BioWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  flex: none;
  align-self: stretch;
  flex-grow: 0;
`

const About = ({ contact }) => (
  <Section>
    <SectionTitle>About {getFullName(contact)} </SectionTitle>
    <TagList>
      {contact?.tags &&
        contact?.tags.map(tag => (
          <Tag key={tag}>
            <BsPerson /> {tag}
          </Tag>
        ))}
    </TagList>
    <BioWrapper>{contact?.aboutThisGuy && contact?.aboutThisGuy}</BioWrapper>
  </Section>
)

About.propTypes = {
  contact: PropTypes.object,
}

const ShowMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 1rem;
  align-self: stretch;
  a {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #00bdb2 !important;
  }
`

const ShowMore = () => (
  <ShowMoreWrapper>
    <Button variant="link" href="#">
      <BsChevronRight />
      &nbsp; Show more
    </Button>
  </ShowMoreWrapper>
)

const RightPanel = ({ selectedView, contact, onClose }) => {
  const [duration, setDuration] = useState(Date.now() + 330000)
  useEffect(() => {
    setDuration(Date.now() + 390000)
  }, [])
  const panelWidth =
    selectedView === 'collapsed-panel'
      ? '0px !important'
      : '18.625rem !important'
  return (
    <RightPanelWrapper panelWidth={panelWidth}>
      <div className="w-100 d-flex flex-row flex-nowrap justify-content-between">
        <div>Call details</div>
        <CloseButton onClick={onClose} />
      </div>
      <Section>
        <SectionTitle>Remaining time</SectionTitle>
        <Countdown date={duration} renderer={countdownRenderer} />
      </Section>
      <NameState contact={contact} />
      <Asks />
      <About contact={contact} />
      <ShowMore />
    </RightPanelWrapper>
  )
}

RightPanel.propTypes = {
  selectedView: PropTypes.string.isRequired,
  contact: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

export default RightPanel
