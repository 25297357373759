import styled from 'styled-components'
import PropTypes from 'prop-types'
import Money from '../ui-elements/Money'
import Gauge from './Gauge'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  max-width: 200px;
  > div {
    margin-bottom: 0.5rem;
  }
`

const GaugeWrapper = styled.div`
  width: 100%;
`

const AmountWrapper = styled.div`
  width: 100%;
  font-weight: 700;
  text-align: center;
  line-height: 18.23px;
  font-size: 1.038vw;
`

const LabelWrapper = styled.div`
  width: 100%;
  font-weight: normal;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
`

const GaugeGraph = ({ percent, amount, label, color }) => {
  return (
    <Wrapper>
      <GaugeWrapper>
        <Gauge percent={percent} color={color} />
      </GaugeWrapper>
      <AmountWrapper>
        <Money value={amount} />
      </AmountWrapper>
      <LabelWrapper className="label-graph">{label}</LabelWrapper>
    </Wrapper>
  )
}

GaugeGraph.propTypes = {
  percent: PropTypes.number,
  amount: PropTypes.number,
  label: PropTypes.string,
  color: PropTypes.string,
}

export default GaugeGraph
