import { FC, Fragment, useState, useMemo } from 'react'
import styled from 'styled-components'
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { BsChevronRight } from 'react-icons/bs'
import { RiMailSendFill } from 'react-icons/ri'
import LevelConfirmationModal from './LevelConfirmationModal'
import usStates from '../../assets/datasets/us-states.json'
import { propEq, either, isNil, isEmpty } from 'ramda'
import moment from 'moment'
import { getAvatarContact } from '../../utils/stringsOperations'
import { getNewLevel } from '../../utils/enumFunctions'
import { sendEmailLevelUpdate } from '../../utils/sendEmailLevelUpdate'
import { getUserRole } from '../../utils/state/selectors'
import { useSelector } from 'react-redux'
import { IContact, IOrganization, IUser } from '../../api/interfaces'
import { isStafferOrOwnerOrAdmin } from '../../utils/state/roleTests'
import ToolTipText from '../ui-elements/ToolTipText'
import { calculateDateDifferenceInDays } from '../../utils/timestamp'
import TextAreaModal from './TextAreaModal'
import { CONTACT_LEVELS } from '../../api/enums'

export const StyledTd = styled.td`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  > img {
    width: 40px;
    border-radius: 50%;
  }
  .name {
    padding-left: 10px;
  }
`
const StyledButtonTd = styled.td`
  width: 2.5rem;
`
const OpacityWrapper = styled.div`
  opacity: var(--opacity, 1);
`

const DivContainer = styled.div`
  display: flex;
  gap: 1rem;
`

const isDefined = (x: any) => !(isNil(x) || isEmpty(x))

export interface ContactRowProps {
  contactPage?: boolean
  contact: IContact
  handleClick: () => void
  handleUpdate: () => void
  organization: IOrganization
  expandable?: boolean
  handleClickEmail?: (email: string | undefined) => void
  handleRecentlyCalled?: (contact: IContact | undefined) => void
  handleRecentlyMessaged?: (contact: IContact | undefined) => void
  orgUsers?: IUser[]
  assignContact: (contact: IContact, value: string | null) => void
  crmUser?: boolean
}

const ContactRow: FC<ContactRowProps> = ({
  contactPage,
  contact,
  handleClick,
  handleUpdate,
  organization,
  expandable = true,
  handleClickEmail = () => {},
  // handleRecentlyCalled = () => {},
  // handleRecentlyMessaged = () => {},
  orgUsers = [],
  assignContact,
  crmUser,
}) => {
  const lastEmail = useMemo(
    () =>
      contact.lastEmailSent
        ? calculateDateDifferenceInDays(
            moment(contact.lastEmailSent.date).unix(),
            moment().unix()
          )
        : '',
    [contact.lastEmailSent]
  )
  // const lastCall = useMemo(() => {
  //   if (contact.LatestCall) {
  //     const descCalls = contact.LatestCall.sort((a, b) => (a < b ? 1 : -1))
  //     const mostRecent = descCalls[0]
  //     return calculateDateDifferenceInDays(mostRecent.time, moment().unix())
  //   } else return ''
  // }, [contact.LatestCall])
  // const lastText = useMemo(() => {
  //   if (contact.LatestText) {
  //     const descTexts = contact.LatestText.sort((a, b) => (a < b ? 1 : -1))
  //     const mostRecent = descTexts[0]
  //     return calculateDateDifferenceInDays(mostRecent.time, moment().unix())
  //   } else return ''
  // }, [contact.LatestText])

  // const randomScore = useMemo(() => Math.round(Math.random() * 100), [])
  // const propensity = contact.donation_capacity
  //   ? contact.donation_capacity
  //   : randomScore
  const [isMouseOver, setMouseOver] = useState(false)

  const mouseOverHandler = () => setMouseOver(true)
  const mouseOutHandler = () => setMouseOver(false)

  const url = `${window.location.origin}/login`

  const sendEmail = (levelUpdate: string) => {
    sendEmailLevelUpdate(
      contact?.email,
      organization.name,
      organization.emailAccount.email,
      levelUpdate,
      url
    )
  }
  const emailClickHandler = () => {
    const email = contact?.email
    if (email) {
      handleClickEmail(email)
    }
  }
  const userRole = useSelector(getUserRole)

  const [showChangeLevel, setShowChangeLevel] = useState(false)
  const [showTextModal, setShowTextModal] = useState(false)
  const [textModalProps, setTextModalProps] = useState({
    title: '',
    property: '',
    placeholder: '',
  })

  const getLevels = (org: IOrganization) => {
    let levels = [{}]
    if (contact.level === CONTACT_LEVELS.CONTACT) {
      if (org.levels && Object.keys(org.levels)) {
        levels = [
          ...levels,
          ...Object.keys(org.levels).map(levelKey => ({
            label: levelKey,
            value: levelKey,
          })),
        ]
      }
    } else {
      levels.push({
        label: CONTACT_LEVELS.CONTACT,
        value: CONTACT_LEVELS.CONTACT,
      })
    }
    return levels
  }

  const stateName = (x: string) =>
    usStates.find(
      either(propEq('state' as string, x), propEq('name' as string, x))
    )?.name

  const getPhoneNumber = (contact: IContact) =>
    contact.cell_phone_number
      ? `${contact.cell_phone_number} (Cell)`
      : contact.landline_phone_number
      ? `${contact.landline_phone_number} (Home)`
      : contact.work_phone_direct
      ? `${contact.work_phone_direct} (Business)`
      : contact.work_phone_number
      ? `${contact.work_phone_number} (Business)`
      : ''

  return (
    <tr onMouseEnter={mouseOverHandler} onMouseLeave={mouseOutHandler}>
      <StyledTd
        onClick={handleClick}
        style={{ cursor: 'pointer', display: 'flex', flexWrap: 'wrap' }}
      >
        {getAvatarContact(contact)}
        <span className="name" aria-label="contact-row-name">
          {contact.first_name + ' ' + contact.last_name}
        </span>
      </StyledTd>
      {contactPage && crmUser ? (
        <Fragment>
          <StyledTd style={{ backgroundColor: '#d9d9d9' }}>
            <div style={{ width: '50px' }}>
              {/* <Gauge percent={propensity} color="#11273C" size="70%" /> */}
            </div>
          </StyledTd>
          <StyledTd>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {isDefined(contact.email) ? contact.email : ''}
              &nbsp;&nbsp;
              {isDefined(contact.email) ? (
                <ToolTipText placement="top" description="Send Email">
                  <RiMailSendFill
                    color="#11273C"
                    cursor="pointer"
                    onClick={emailClickHandler}
                  />
                </ToolTipText>
              ) : null}
            </div>
          </StyledTd>
          <StyledTd>
            {contact.cell_phone_number}
            {/* {isDefined(contact.cell_phone_number) ? (
              <DivContainer>
                <ToolTipText placement="top" description="Call">
                  <a href={`tel:${contact.primary_phone_number}`}>
                    <BsFillTelephoneFill
                      color="#11273C"
                      cursor="pointer"
                      onClick={() => handleRecentlyCalled(contact)}
                    />
                  </a>
                </ToolTipText>
                <ToolTipText placement="top" description="Message">
                  <a href={`sms:${contact.primary_phone_number}`}>
                    <BsChatTextFill
                      color="#11273C"
                      cursor="pointer"
                      onClick={() => handleRecentlyMessaged(contact)}
                    />
                  </a>
                </ToolTipText>
              </DivContainer>
            ) : null} */}
          </StyledTd>
          <StyledTd>
            {contact.state_abbr && stateName(contact?.state_abbr)}
          </StyledTd>
          <StyledTd>{`${lastEmail}`}</StyledTd>
          <StyledTd>
            <Button
              onClick={() => {
                setShowTextModal(true)
                setTextModalProps({
                  title: 'Results of Contact',
                  property: 'contactResults',
                  placeholder: 'Add outreach results ...',
                })
              }}
              variant={
                contact.contactResults && contact.contactResults !== ''
                  ? 'outline-primary'
                  : 'outline-secondary'
              }
            >
              {contact.contactResults && contact.contactResults !== ''
                ? 'View Outreach'
                : 'Add Outreach'}
            </Button>
          </StyledTd>
          <StyledTd>
            <DropdownButton
              id="assign-to-dropdown"
              title={
                contact.assignedTo && contact.assignedTo !== ''
                  ? contact.assignedTo
                  : 'Assign'
              }
              variant={
                contact.assignedTo && contact.assignedTo !== ''
                  ? 'primary'
                  : 'secondary'
              }
              onSelect={value =>
                value !== contact.assignedTo
                  ? assignContact(contact, value)
                  : null
              }
            >
              <Dropdown.Item
                eventKey=""
                disabled={!contact.assignedTo || contact.assignedTo === ''}
              >
                Unassign
              </Dropdown.Item>
              {orgUsers.map((user, index) => {
                const value = `${user.name} ${user.lastName}`
                return (
                  <Dropdown.Item
                    key={index}
                    eventKey={value}
                    disabled={contact.assignedTo === value}
                  >
                    {value}
                  </Dropdown.Item>
                )
              })}
            </DropdownButton>
          </StyledTd>
          {/* <StyledTd>
            {lastCall !== '' ? (
              `${lastCall}`
            ) : (
              <DropdownButton
                id="call-log-dropdown"
                title="Add Call Log"
                variant="secondary"
                onSelect={value => {
                  if (value === 'Today') {
                    handleRecentlyCalled(contact)
                  }
                }}
              >
                <Dropdown.Item eventKey="Today">Today</Dropdown.Item>
                <Dropdown.Item eventKey="Clear" disabled>
                  Clear
                </Dropdown.Item>
              </DropdownButton>
            )}
          </StyledTd>
          <StyledTd>
            {lastText !== '' ? (
              `${lastText}`
            ) : (
              <DropdownButton
                id="text-log-dropdown"
                title="Add Text Log"
                variant="secondary"
                onSelect={value => {
                  if (value === 'Today') {
                    handleRecentlyMessaged(contact)
                  }
                }}
              >
                <Dropdown.Item eventKey="Today">Today</Dropdown.Item>
                <Dropdown.Item eventKey="Clear" disabled>
                  Clear
                </Dropdown.Item>
              </DropdownButton>
            )}
          </StyledTd> */}
          <StyledTd>{contact.level && contact.level}</StyledTd>
          <StyledTd>
            <Button
              onClick={() => {
                setShowTextModal(true)
                setTextModalProps({
                  title: 'Notes',
                  property: 'notes',
                  placeholder: 'Add notes ...',
                })
              }}
              variant="outline-secondary"
            >
              View Notes
            </Button>
          </StyledTd>
        </Fragment>
      ) : (
        <Fragment>
          <StyledTd aria-label="non-crm-contact-row-email">
            {contact.email}
          </StyledTd>
          <StyledTd aria-label="non-crm-contact-row-phone">
            {getPhoneNumber(contact)}
          </StyledTd>
          <StyledTd aria-label="non-crm-contact-row-state">
            {contact.state_abbr && stateName(contact?.state_abbr)}
          </StyledTd>
          <StyledTd aria-label="non-crm-contact-row-level">
            {contact.level && contact.level}
          </StyledTd>
          <StyledTd>
            <DivContainer>
              {isStafferOrOwnerOrAdmin(userRole) && (
                <Button
                  onClick={() => setShowChangeLevel(true)}
                  variant="outline-secondary"
                  aria-label="change-contact-level-button"
                >
                  {`Change ${getNewLevel(contact.level)}`}
                </Button>
              )}
            </DivContainer>
          </StyledTd>
        </Fragment>
      )}
      {/* <StyledTd>
        <DivContainer>
          {isStafferOrOwnerOrAdmin(userRole) && (
            <Button
              onClick={() => setShowChangeLevel(true)}
              variant="outline-secondary"
            >
              {`Change ${getNewLevel(contact.level)}`}
            </Button>
          )}
        </DivContainer>
      </StyledTd> */}
      <StyledButtonTd>
        <OpacityWrapper
          style={{
            opacity: isMouseOver ? 1 : 0,
          }}
        >
          {expandable && (
            <Button
              onClick={handleClick}
              variant="outline-secondary"
              aria-label="go-to-contact-details-button"
            >
              <BsChevronRight />
            </Button>
          )}
        </OpacityWrapper>
        <LevelConfirmationModal
          levels={getLevels(organization)}
          show={showChangeLevel}
          handleClose={() => setShowChangeLevel(false)}
          contact={contact}
          handleUpdate={handleUpdate}
          sendEmail={sendEmail}
        />
        <TextAreaModal
          contact={contact}
          handleClose={() => {
            setShowTextModal(false)
            setTextModalProps({
              title: '',
              property: '',
              placeholder: '',
            })
          }}
          show={showTextModal}
          title={textModalProps.title}
          property={textModalProps.property}
          placeholder={textModalProps.placeholder}
          handleUpdate={handleUpdate}
        />
      </StyledButtonTd>
    </tr>
  )
}

export default ContactRow
