import { FC } from 'react'
import styled from 'styled-components'
import { TopLineItemProps } from './types'
import Money, { formatValue } from '../ui-elements/Money'
import { FundraisingProgressBar } from '../ui-elements/FundraisingProgressBar'

const WrapperDiv = styled.div`
  width: 100%;
`

const ContainerDiv = styled.div`
  display: flex;
  /* background-color: red; */
  flex-direction: row;
  width: 100%;
  margin-top: 0;
  padding: 0;
  row-gap: 0px;
  column-gap: 0px;
  justify-content: stretch;
  align-items: flex-start;
  gap: 0;
  box-sizing: border-box;
`

const ValueDiv = styled.div<{
  fw?: number
  size?: number
  td?: string
  mt?: number
  bc?: string
}>`
  display: flex;
  flex-direction: column;
  vertical-align: bottom;
  width: 100%;
  color: '#11273C';
  /* background-color: yellow; */
  align-self: flex-start;
  font-weight: ${props => (props.fw ? props.fw : 400)};
  font-size: ${props => `${props.size ? props.size : 24}px`};
  text-decoration: ${props => (props.td ? props.td : '')};
  margin-top: ${props => `${props.mt || props.mt === 0 ? props.mt : 0}px`};
  background-color: ${props => (props.bc ? props.bc : null)};
`

const PercentageDiv = styled.div<{
  fw?: number
  size?: number
  td?: string
  mt?: number
  bc?: string
}>`
  width: 60%;
  display: flex;
  color: '#11273C';
  /* background-color: pink; */
  align-self: flex-start;
  /* margin-left: 90px; */
  flex-direction: column;
  font-weight: ${props => (props.fw ? props.fw : 400)};
  font-size: ${props => `${props.size ? props.size : 20}px`};
  text-decoration: ${props => (props.td ? props.td : '')};
  margin-top: ${props => `${props.mt || props.mt === 0 ? props.mt : 0}px`};
  background-color: ${props => (props.bc ? props.bc : null)};
`

const AverageDiv = styled.div<{
  fw?: number
  size?: number
  td?: string
  mt?: number
}>`
  color: '#11273C';
  /* background-color: pink; */
  display: flex;
  text-align: start;
  vertical-align: top;
  width: 100%;
  font-weight: ${props => (props.fw ? props.fw : 400)};
  font-size: ${props => `${props.size ? props.size : 20}px`};
  text-decoration: ${props => (props.td ? props.td : '')};
  margin-top: ${props => `${props.mt || props.mt === 0 ? props.mt : 0}px`};
  margin-right: 0;
  margin-left: 0;
`
const CountDiv = styled.div<{
  fw?: number
  size?: number
  td?: string
  mt?: number
}>`
  /* margin-right: 20px; */
  width: 100%;
  display: flex;
  color: '#11273C';
  /* background-color: pink; */
  text-align: start;
  margin: 0;
  padding: 0;
  font-weight: ${props => (props.fw ? props.fw : 400)};
  font-size: ${props => `${props.size ? props.size : 20}px`};
  text-decoration: ${props => (props.td ? props.td : '')};
  margin-top: ${props => `${props.mt || props.mt === 0 ? props.mt : 0}px`};
`

const TopLineItem: FC<TopLineItemProps> = ({
  title,
  value,
  percentage,
  count,
  average,
  emphasize,
}) => {
  return (
    <WrapperDiv>
      {title ? (
        <ValueDiv size={20} mt={10}>
          {title}
        </ValueDiv>
      ) : null}
      <ContainerDiv>
        <ValueDiv fw={800} size={emphasize ? 24 : 20}>
          <div>{!value && value !== 0 ? 'N/A' : Money({ value })}</div>
          <div>
            {average && count ? (
              <ContainerDiv>
                <AverageDiv fw={400} size={16}>
                  {`Avg: ${formatValue(Math.round(average || 0), 0, 0, true)}`}
                </AverageDiv>
                <CountDiv fw={400} size={16}>
                  {`#: ${formatValue(count)}`}
                </CountDiv>
              </ContainerDiv>
            ) : null}
          </div>
        </ValueDiv>
        {percentage || percentage === 0 ? (
          <PercentageDiv fw={800} size={emphasize ? 24 : 20}>
            {Math.round(Number(percentage)).toFixed(0)}%
            <FundraisingProgressBar value={percentage} />
          </PercentageDiv>
        ) : (
          <div style={{ width: '100%' }} />
        )}
      </ContainerDiv>
    </WrapperDiv>
  )
}

export default TopLineItem
