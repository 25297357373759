import React, { FC } from 'react'
import { IDonation2 } from '../../../api/interfaces'
import { DATEFORMAT } from '../../../utils/timestamp'
import moment from 'moment'
import { formatValue } from '../../ui-elements/Money'

interface TabDonationItemProps {
  donation: IDonation2
}
const TabDonationItem: FC<TabDonationItemProps> = ({ donation }) => {
  const formattedAmount = formatValue(donation.donation_total, 0, 0, true)
  return (
    <ul className="list-group list-group-flush">
      <li className="list-group-item">
        <>
          <b>Date:</b>{' '}
          {moment(donation.donation_date).format(DATEFORMAT.DATE_FORMAT)}
        </>
      </li>
      <li className="list-group-item">
        <b>Amount:</b>{' '}
        {formattedAmount ? `${formattedAmount}` : donation.donation_total}
      </li>
    </ul>
  )
}

export default TabDonationItem
