import * as R from 'ramda'

const urlRE =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b\/?/

const makeProfilePicUrl = personProfile => {
  const picture = R.propOr('', 'picture', personProfile)
  return picture.match(urlRE)
    ? picture
    : picture === ''
    ? require(`../assets/profilePictures/unassigned.png`)
    : require(`../assets/profilePictures/${picture}.png`)
}

export default makeProfilePicUrl
