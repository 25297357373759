import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useReactToPrint } from 'react-to-print'
import { getUser, getUserOrganizationId } from '../../utils/state/selectors'
import Api from '../../api/event'
import ApiContact from '../../api/contact'
import { formatValue } from '../ui-elements/Money'

import moment from 'moment'
import { BsEye, BsPencil } from 'react-icons/bs'
import Invite from '../InviteTemplate/Invite/Invite'
import DonorForm from './donorForm.jpg'
import './InvitePage.css'
import { decryptData } from '../../utils/crypto/crypto'
import ApiOrg from '../../api/organization'
import CenterSpinner from '../CenterSpinner/CenterSpinner'
import { getWinRedUrl } from '../EventDetailPage/utils'
import * as R from 'ramda'

const isDefined = x => !(R.isNil(x) || R.isEmpty(x))

const getContactFromId = (contacts, _id) => {
  const contactFound = contacts.find(contact => contact._id === _id)
  return contactFound
}

const generateFormat = (event, guests, contacts, organization, winRedUrl) => {
  const { name, day, hour, tiers } = event

  const guestNames =
    guests.length > 0 ? guests.map(guest => guest.guestName).slice(0, 2) : ''

  const maxGuestNameLength = guestNames
    ? Math.max(...guestNames.map(name => name.length))
    : 0

  let participants = tiers.length
    ? tiers[0].participants
        .flat()
        .map(({ _id }) => getContactFromId(contacts, _id))
        .filter(contact => contact)
        .map(
          contact =>
            contact.invite_alias || `${contact.first_name} ${contact.last_name}`
        )
    : []

  return {
    logo: {
      url: organization.organizationEventLogoUrl,
      isDragDisabled: true,
    },
    hostList: {
      items: participants.length > 0 ? participants : [],
      font: {
        fontFamily: 'Calibri',
        color: '#5086c1',
        fontSize: 20,
        fontWeight: 'bold',
      },
      colsNumber: 0,
      isDragDisabled: false,
    },
    inviteLegend: {
      text:
        participants.length > 0
          ? `Invite${participants.length === 1 ? 's' : ''} you to a reception${
              guestNames.length ? ' with special guest' : ''
            }`
          : `You are invited to a reception${
              guestNames.length
                ? ` with special guest${guestNames.length === 1 ? 's' : ''}`
                : ''
            }`,
      font: {
        fontFamily: 'Calibri',
        color: '#5086c1',
        fontSize: 17,
        fontWeight: 'bold',
      },
      isDragDisabled: true,
    },
    guests: {
      items: guestNames.length > 0 ? guestNames : [],
      font: {
        fontFamily: 'Calibri',
        color: '#5086c1',
        fontSize:
          maxGuestNameLength > 15 ? 44 - (maxGuestNameLength / 2) * 0.2 : 44,
        fontWeight: 'bold',
      },
      isDragDisabled: true,
    },
    dateAndTime: {
      time: {
        text: moment(hour, 'HHmm').format('h:mm A'),
        font: {
          fontFamily: 'Calibri',
          color: '#5086c1',
          fontSize: 17,
          fontWeight: '',
        },
      },
      date: {
        text: moment(day).format('MMMM Do, YYYY'),
        font: {
          fontFamily: 'Calibri',
          color: '#5086c1',
          fontSize: 17,
          fontWeight: '',
        },
      },
      isDragDisabled: false,
    },
    location: {
      d1sLine: {
        text: event.locationRSVP
          ? 'Address provided upon RSVP'
          : event.TBD
          ? 'Venue TBD'
          : event.venue,
        font: {
          fontFamily: 'Calibri',
          color: '#5086c1',
          fontSize: 17,
          fontWeight: 'bold',
        },
      },
      ...(!event.locationRSVP && {
        d2sLine: {
          text: event.street,
          font: {
            fontFamily: 'Calibri',
            color: '#5086c1',
            fontSize: 20,
            fontWeight: '',
          },
        },
      }),
      isDragDisabled: false,
    },
    tier: {
      title: {
        text: 'Suggested Contribution',
        font: {
          fontFamily: 'Calibri',
          color: '#5086c1',
          fontSize: 16,
          fontWeight: 'bold',
        },
      },
      suggestedDonation: {
        text: `${formatValue(event.suggestedDonation)} Per Person`,
        font: {
          fontFamily: 'Calibri',
          color: '#5086c1',
          fontSize: 16,
          fontWeight: 'bold',
        },
      },
    },
    tierList: {
      items:
        event.tiers.length > 0
          ? event.tiers.map(
              tier =>
                `${tier.tierName}: ${formatValue(tier.tierLevel, 0, 0, false)}${
                  tier.isGiveRaise ? ' (Give or Raise)' : ''
                }`
            )
          : [],
      font: {
        fontSize: 16,
        color: '#5086c1',
        fontWeight: '',
        fontFamily: 'Calibri',
      },
      isDragDisabled: false,
    },
    url: {
      text: isDefined(winRedUrl)
        ? `To contribute online please visit: ${winRedUrl}`
        : '',
      font: {
        fontFamily: 'Calibri',
        color: '#5086c1',
        fontSize: 17,
        fontWeight: 'bold',
      },
      isDragDisabled: true,
    },
    legal: {
      text: 'Contributions are not deductible as charitable donations.<br />  Contributions from foreign nationals and foreign corporations are prohibited.',
      font: {
        fontFamily: 'Calibri',
        color: '#5086c1',
        fontSize: 14,
        fontWeight: '',
      },
      isDragDisabled: true,
    },
    footer: {
      text: `Paid for by ${organization.name}`,
      font: {
        fontFamily: 'Calibri',
        color: '#5086c1',
        fontSize: 15,
        fontWeight: '',
      },
      border: 'solid black 1',
      isDragDisabled: true,
    },
  }
}
const InvitePage = () => {
  const componentRef = useRef()

  const { eventId, userEmail } = useParams()
  const [event, setEvent] = useState(null)
  const [guests, setGuests] = useState(null)
  const [contacts, setContacts] = useState(null)

  const [inviteFormat, setInviteFormat] = useState(null)

  const isAllowed = !!userEmail
  const user = useSelector(getUser) || decryptData(userEmail)

  const [preview, setPreview] = useState(true)
  const [printMode, setPrintMode] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [organization, setOrganization] = useState([])
  const userOrgId = useSelector(getUserOrganizationId)

  const [winRedUrl, setWinRedUrl] = useState('')

  useEffect(() => {
    getWinRedUrl(event?.organizationId, event?.name, user).then(url => {
      setWinRedUrl(url.replaceAll(/\s/g, '_'))
    })
  }, [event])

  useEffect(() => {
    if (event && guests && contacts) {
      setInviteFormat(
        generateFormat(event, guests, contacts, organization, winRedUrl)
      )
    }
  }, [event, guests, contacts, organization, winRedUrl])

  useEffect(() => {
    ApiOrg.getOrganization(userOrgId, true, true).then(org => {
      setOrganization(org)
    })
  }, [])

  useEffect(() => {
    let isMounted = true
    setIsLoading(true)
    Api.getEvent(eventId, isAllowed)
      .then(e => {
        if (isMounted) setEvent(e)
      })
      .then(() => Api.getConsolidatedSummary(user, eventId, '', isAllowed))
      .then(consRes => {
        if (isMounted) {
          setGuests(consRes?.attendees?.guests || [])
        }
      })
      .finally(() => setIsLoading(false))
    ApiContact.getContacts('', isAllowed).then(cc => {
      setContacts(cc)
    })

    return () => {
      isMounted = false
    }
  }, [])

  const addDonorForm = containerToPrint => {
    if (!containerToPrint || containerToPrint?.querySelector('#donor-form')) {
      return
    }
    var clnContainerToPrint = containerToPrint.cloneNode(true)
    const containerParent = document.createElement('div')
    const containerImage = document.createElement('div')
    containerImage.id = 'donor-form'
    const imgElement = document.createElement('img')
    imgElement.setAttribute('src', DonorForm)
    imgElement.setAttribute('height', '1000')
    imgElement.setAttribute('width', '100%')
    imgElement.setAttribute('alt', 'Flower')
    containerImage.appendChild(imgElement)

    const pageBreak = document.createElement('div')
    pageBreak.className = 'page-break'
    containerParent.appendChild(clnContainerToPrint)
    containerParent.appendChild(pageBreak)
    containerParent.appendChild(containerImage)
    return containerParent
  }

  const handlePrint = useReactToPrint({
    content: () => {
      if (componentRef.current) {
        const finded = componentRef.current.querySelector(
          '.drag-drop-container-preview'
        )
        const containerToPrint = finded?.children[0]
        setPrintMode(false)
        return containerToPrint
        /* A test donor form is hard-coded. If desired, modify the Create Organization form
           to allow uploading a donor form during onboarding. Then un-comment line below */
        // return addDonorForm(containerToPrint)
      }
      return null
    },
    onAfterPrint: () => {
      setPreview(isAllowed)
    },
    onPrintError: () => {
      setPreview(false)
    },
  })

  const setMargin = () => {
    const MAX_HEIGHT = 1043
    const droppableDiv = document.querySelector(
      '.droppable-custom.droppable-preview'
    )
    if (droppableDiv?.offsetHeight && droppableDiv.offsetHeight < MAX_HEIGHT) {
      const difference = MAX_HEIGHT - droppableDiv.offsetHeight
      const marginTop = Math.ceil(difference * 0.15)
      const marginBottom = Math.ceil(difference * 0.05)
      droppableDiv.style.marginTop = marginTop > 0 ? marginTop : 0
      droppableDiv.style.marginBottom = marginBottom > 0 ? marginBottom : 0
    }
  }
  useEffect(() => {
    if (preview) {
      setMargin()
    }
    if (preview && printMode) {
      handlePrint()
    }
  }, [preview, printMode, handlePrint])

  return (
    <>
      {isLoading && <CenterSpinner />}
      {event ? (
        <div
          className="d-flex justify-content-center align-items-center"
          ref={componentRef}
          style={{
            flexDirection: 'column',
          }}
        >
          {inviteFormat && (
            <Invite
              format={inviteFormat}
              onChange={value => value}
              previewMode={preview}
              listGridClassName={
                preview ? 'preview-list-grid' : 'custom-list-grid'
              }
              dragDropContainerClassName={
                preview
                  ? 'drag-drop-container-preview'
                  : 'drag-drop-container-custom'
              }
              droppableClassName={
                preview
                  ? 'droppable-custom droppable-preview'
                  : 'droppable-custom'
              }
              dragItemClassName={
                preview ? 'drag-item-custom-clear' : 'drag-item-custom'
              }
            />
          )}
          <div className="d-flex flex-row flex-nowrap align-items-center gap-3 m-3 mb-4">
            {!userEmail && (
              <>
                <button onClick={() => null} className="btn btn-primary">
                  Save
                </button>{' '}
                <button
                  onClick={() => {
                    setPreview(!preview)
                  }}
                  className="btn btn-secondary"
                >
                  <div className="d-flex flex-row align-items-center gap-2">
                    {preview ? (
                      <>
                        <BsPencil />
                        Edit
                      </>
                    ) : (
                      <>
                        <BsEye />
                        Preview
                      </>
                    )}
                  </div>
                </button>{' '}
              </>
            )}
            <button
              className="btn btn-primary"
              onClick={() => {
                setPreview(true)
                setPrintMode(true)
              }}
            >
              Print
            </button>{' '}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default InvitePage
