import styled from 'styled-components'
import { useState } from 'react'
import { Offcanvas } from 'react-bootstrap'
import MenuLeftHandleBar from './MenuLeftHandleBar'
import MenuBarContent from './MenuBarContent'
import { useSelector } from 'react-redux'
import { getUserRole } from '../../utils/state/selectors'
import { isAdmin } from '../../utils/state/roleTests'
import MenuBarContentAdmin from './MenuBarContentAdmin'
import MenuLeftHandleBarAdmin from './MenuLeftHandleBarAdmin'

const OffCanvaStyled = styled(Offcanvas)`
  max-width: 250px;
  background-color: #11273c;
`

const MenuBar = () => {
  const userRole = useSelector(getUserRole)
  const [isMenuVisible, setMenuVisible] = useState(false)
  const handleBarClickHandler = () => setMenuVisible(visible => !visible)
  const LeftHandleBarComponent = isAdmin(userRole)
    ? MenuLeftHandleBarAdmin
    : MenuLeftHandleBar
  const ContentComponent = isAdmin(userRole)
    ? MenuBarContentAdmin
    : MenuBarContent

  return (
    <>
      <LeftHandleBarComponent
        isMenuVisible={isMenuVisible}
        onClick={handleBarClickHandler}
      />
      <OffCanvaStyled show={isMenuVisible} onHide={handleBarClickHandler}>
        <Offcanvas.Body>
          <ContentComponent
            isMenuVisible={isMenuVisible}
            onClose={handleBarClickHandler}
          />
        </Offcanvas.Body>
      </OffCanvaStyled>
    </>
  )
}

export default MenuBar
