import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  overflow-x: hidden;
  overflow-y: auto;
`

const SimpleLayout = () => (
  <Wrapper>
    <Outlet />
  </Wrapper>
)

export default SimpleLayout
