import Card from '../ui-elements/Card'
import { BsTrophy } from 'react-icons/bs'
import IconDonation from '../ui-elements/IconDonation'
import { PropTypes } from 'prop-types'

const Leaderboard = ({ bundlers, style }) => {
  const ret = bundlers.length
    ? bundlers.map((entry, idx) => (
        <IconDonation
          key={idx}
          name={entry.bundlerInfo.name}
          donation={entry.amount}
        >
          <BsTrophy color={entry.bundlerInfo.color} />
        </IconDonation>
      ))
    : null

  return (
    <Card title="Leaderboard" style={style}>
      <div style={{ overflow: 'scroll', height: '500px' }}>{ret}</div>
    </Card>
  )
}

Leaderboard.propTypes = {
  bundlers: PropTypes.array,
  style: PropTypes.object,
}
export default Leaderboard
