import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Doughnut } from 'react-chartjs-2'
import { Chart, ArcElement } from 'chart.js'
import { useRef, useState, useEffect } from 'react'

Chart.register(ArcElement)

const Wrapper = styled.div`
  position: relative;
`

const PercentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 700;
  font-size: var(--size, 1.779vw);
`

const Gauge = ({ percent, color, size }) => {
  const chartRef = useRef()
  const [chartData, setChartData] = useState({
    datasets: [],
  })
  const actualPercent = Number.isNaN(percent) ? 0 : Number(percent)
  const data = {
    datasets: [
      {
        data: [actualPercent, 100 - actualPercent],
      },
    ],
  }
  const options = {
    responsive: true,
    maintainAspectRatio: true,
    borderWidth: 0,
    cutout: '80%',
    rotation: 180,
  }
  const createGradient = ctx => {
    const gradient = ctx.createLinearGradient(0, 200, 0, 0)
    gradient.addColorStop(0, 'white')
    gradient.addColorStop(0.4, '#55C3FF')
    gradient.addColorStop(1, color)
    return gradient
  }

  useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    const chartData = {
      ...data,
      datasets: data.datasets.map(dataset => ({
        ...dataset,
        backgroundColor: [
          createGradient(chart.ctx, chart.chartArea),
          '#e6e6e6',
        ],
      })),
    }
    setChartData(chartData)
  }, [percent, color, size])

  return (
    <Wrapper>
      <Doughnut ref={chartRef} data={chartData} options={options} />
      <PercentWrapper
        className="percent-wrapper"
        style={{ '--size': size }}
      >{`${Math.floor(percent)}%`}</PercentWrapper>
    </Wrapper>
  )
}

Gauge.propTypes = {
  percent: PropTypes.number,
  color: PropTypes.string,
  size: PropTypes.string,
}

export default Gauge
