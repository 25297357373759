import { FC, useState } from 'react'
import { MultiEmailCustomProps } from './types'

const MultiEmailCustom: FC<MultiEmailCustomProps> = ({
  id,
  value,
  onChange,
}) => {
  const [focus, setFocus] = useState(false)
  return (
    <div className={`react-multi-email ${focus && ' focused'}`}>
      <input
        autoComplete="off"
        type="text"
        id={id}
        value={value}
        placeholder=""
        onChange={onChange}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
      />
    </div>
  )
}
export default MultiEmailCustom
