import { useState, useEffect, memo } from 'react'
import PropTypes from 'prop-types'
import { Form, Modal, Button } from 'react-bootstrap'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import USStatesCities from '../../../assets/datasets/USStatesCities.json'
import path from 'ramda/src/path'
import { DONATION_TYPE } from '../../../api/enums'
import EventApi from '../../../api/event'
import TagApi from '../../../api/tag'
import ContactApi from '../../../api/contact'
import styled from 'styled-components'
import useDebounce from '../../../utils/useDebounce'
import { isValidEmail } from '../../../utils/stringsOperations'

export const initialQueryState = {
  state: null,
  city: null,
  event: null,
  donation: null,
  tags: null,
  birth_date: '',
  postal_code: '',
  name: '',
  designated_market_area: '',
  assitgnedTo: '',
  contact_type: null,
  available_capacity: '',
  donation_capacity: '',
  email: '',
}

const CapacityScopeRadioDiv = styled.div`
  display: flex;
  margin-top: 10px;
  flex-direction: row;
`

const CapacityDiv = styled.div`
  display: flex;
  flex-direction: column;
`

export const FilterContactsFormJS = ({
  resetFilter,
  setQueryFilter,
  orgUsers,
  // contacts,
  queryFilter,
  showDonationTypes,
  showEvents,
  showCapacityFilter,
  bContacts,
}) => {
  // Getters
  const fetchEvents = () =>
    EventApi.getEvents().then(events => {
      setEvents(events)
    })
  const fetchTags = () =>
    TagApi.getTags().then(tags => {
      setTags(tags)
    })
  const fetchRegionValues = () =>
    ContactApi.getRegions().then(regions => {
      setRegions(regions)
    })
  // const fetchNames = () =>
  //   setNames(
  //     contacts.map(contact => `${contact.first_name} ${contact.last_name}`)
  //   )

  // Effects
  useEffect(() => {
    fetchEvents()
    fetchTags()
    fetchRegionValues()
  }, [])
  // useEffect(() => {
  //   fetchNames()
  // }, [contacts])

  const [events, setEvents] = useState([])
  const [tags, setTags] = useState([])
  const [regions, setRegions] = useState([])
  const [names, setNames] = useState([])
  const [capacityScope, setCapacityScope] = useState('available')
  const [postalCode, setPostalCode] = useState('')
  const [contactName, setContactName] = useState('')
  const [email, setEmail] = useState('')
  const [donationTotal, setDonationTotal] = useState('')
  const [capacityTotal, setCapacityTotal] = useState('')

  const debouncedPostalCode = useDebounce(postalCode, 700)
  const debouncedContactName = useDebounce(contactName, 700)
  const debouncedEmail = useDebounce(email, 700)
  const debouncedDonationTotal = useDebounce(donationTotal, 700)
  const debouncedCapacityTotal = useDebounce(capacityTotal, 700)

  const contactTypes = [
    'Donor',
    'Dormant',
    'Lead',
    'Provided',
    'VIP',
    'Declined',
  ]

  // Handlers
  const selectHandler = (newValue, actionType) => {
    const fieldName = actionType.name
    const value = Array.isArray(newValue)
      ? newValue.map(v => v.value)
      : newValue.value
    setQueryFilter({
      ...queryFilter,
      [fieldName]: value,
    })
  }
  const inputChangeHandler = event => {
    event.preventDefault()
    event.stopPropagation()

    let fieldName = path(['target', 'id'], event)
    const value =
      fieldName === 'name'
        ? [path(['target', 'value'], event)]
        : path(['target', 'value'], event)
    if (fieldName === 'available_capacity' && capacityScope === 'total') {
      fieldName = 'donation_capacity'
    }
    setQueryFilter({
      ...queryFilter,
      [fieldName]: value,
    })
  }

  /* Filter fields available_capacity and donation_capacity are assigned an array value.
     The first element is the comparison operator, and the second is the value being compared */
  const radioChangeHandler = event => {
    const fieldName = path(['target', 'id'], event)
    const value = path(['target', 'value'], event)
    if (fieldName === 'available_capacity') {
      setQueryFilter({
        ...queryFilter,
        ['donation_capacity']: ['', queryFilter.donation_capacity[1]],
        [fieldName]:
          queryFilter.available_capacity[0] === value
            ? ['', queryFilter.available_capacity[1]]
            : [value, queryFilter.available_capacity[1]],
      })
    } else {
      setQueryFilter({
        ...queryFilter,
        ['available_capacity']: ['', queryFilter.available_capacity[1]],
        [fieldName]:
          queryFilter.donation_capacity[0] === value
            ? ['', queryFilter.donation_capacity[1]]
            : [value, queryFilter.donation_capacity[1]],
      })
    }
  }

  const handleCapacityScopeChange = scope => {
    let fieldName = ''
    let val = ''
    if (scope === 'total') {
      val = queryFilter['available_capacity']
      queryFilter.available_capacity = ''
      fieldName = 'donation_capacity'
    } else if (scope === 'available') {
      val = queryFilter['donation_capacity']
      queryFilter.donation_capacity = ''
      fieldName = 'available_capacity'
    } else {
      return
    }
    setQueryFilter({
      ...queryFilter,
      [fieldName]: val,
    })
    setCapacityScope(scope)
  }

  const resetFilterState = () => {
    setQueryFilter(initialQueryState)
    setDonationTotal('')
    setEmail('')
    setCapacityTotal('')
    setContactName('')
    setPostalCode('')
    resetFilter()
  }
  // Select options
  const stateOptions = Object.keys(USStatesCities)
    .map(state => ({
      label: state,
      value: state,
    }))
    .sort((a, b) => {
      return a.label <= b.label ? -1 : 1
    })
  const cityOptions = queryFilter.state
    ? USStatesCities[queryFilter.state].map(city => ({
        label: city,
        value: city,
      }))
    : []
  const eventOptions = events.length
    ? events.map(obj => ({
        label: obj.name,
        value: obj.name,
        id: obj._id,
      }))
    : []
  const regionOptions = regions.length
    ? regions.map(region => ({
        label: region,
        value: region,
      }))
    : []
  const donationOptions = Object.keys(DONATION_TYPE).map(type => ({
    label: type,
    value: type,
  }))

  useEffect(() => {
    if (debouncedDonationTotal !== '') {
      setQueryFilter({
        ...queryFilter,
        ['current_donations_total']: debouncedDonationTotal,
      })
    }
    if (debouncedEmail !== '' && isValidEmail(debouncedEmail)) {
      setQueryFilter({
        ...queryFilter,
        ['email']: debouncedEmail,
      })
    }
    if (debouncedCapacityTotal !== '') {
      setQueryFilter({
        ...queryFilter,
        available_capacity: [
          queryFilter.available_capacity[0],
          debouncedCapacityTotal,
        ],
        donation_capacity: [
          queryFilter.donation_capacity[0],
          debouncedCapacityTotal,
        ],
      })
    }
    if (debouncedContactName !== '') {
      setQueryFilter({
        ...queryFilter,
        name: `${debouncedContactName}*`,
      })
    }
    if (debouncedPostalCode !== '' && debouncedPostalCode.length === 5) {
      setQueryFilter({
        ...queryFilter,
        postal_code: debouncedPostalCode,
      })
    }
  }, [
    setQueryFilter,
    debouncedDonationTotal,
    debouncedEmail,
    debouncedCapacityTotal,
    debouncedContactName,
    debouncedPostalCode,
  ])

  return (
    <>
      <div className="d-flex justify-content-end">
        <Button variant="light btn-sm" onClick={resetFilterState}>
          Reset Filter
        </Button>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplate: 'auto / auto auto',
          columnGap: `16px`,
        }}
      >
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">State</Form.Label>
          <Select
            id="states-typeahead-single"
            name="state"
            onChange={selectHandler}
            options={stateOptions}
            placeholder="Choose a state..."
            value={
              queryFilter.state
                ? {
                    label: queryFilter.state,
                    value: queryFilter.state,
                  }
                : null
            }
          />
        </Form.Group>
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">City</Form.Label>
          <CreatableSelect
            id="cities-typeahead-single"
            name="city"
            onChange={selectHandler}
            options={cityOptions}
            placeholder="Choose a city..."
            isDisabled={!queryFilter.state}
            value={
              queryFilter.city
                ? {
                    label: queryFilter.city,
                    value: queryFilter.city,
                  }
                : null
            }
          />
        </Form.Group>
        {!bContacts ? (
          <>
            <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
              <Form.Label htmlFor="place">Postal Code</Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                id="postal_code"
                maxLength={5}
                placeholder="Enter ZIP Code"
                value={postalCode}
                onChange={event => setPostalCode(event.target.value)}
              />
            </Form.Group>
            <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
              <Form.Label htmlFor="place">Birth Date</Form.Label>
              <Form.Control
                type="date"
                id="birth_date"
                value={queryFilter.birth_date}
                onChange={inputChangeHandler}
              />
            </Form.Group>
          </>
        ) : null}
        {showEvents ? (
          <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
            <Form.Label htmlFor="place">Event</Form.Label>
            <Select
              id="event-single"
              name="event"
              onChange={selectHandler}
              options={eventOptions}
              placeholder="Select Event"
              value={
                queryFilter.event
                  ? {
                      label: queryFilter.event,
                      value: queryFilter.event,
                    }
                  : null
              }
            />
          </Form.Group>
        ) : null}
        {showDonationTypes ? (
          <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
            <Form.Label htmlFor="place">Donation Type</Form.Label>
            <Select
              id="donation-single"
              name="donation"
              onChange={selectHandler}
              options={donationOptions}
              placeholder="Select Donation Type"
              value={
                queryFilter.donation
                  ? {
                      label: queryFilter.donation,
                      value: queryFilter.donation,
                    }
                  : null
              }
            />
          </Form.Group>
        ) : null}
        {!bContacts ? (
          <>
            <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
              <Form.Label htmlFor="place">Market/Region</Form.Label>
              <Select
                id="dma"
                name="designated_market_area"
                onChange={selectHandler}
                options={regionOptions}
                placeholder="Select Region"
                value={
                  queryFilter.designated_market_area
                    ? {
                        label: queryFilter.designated_market_area,
                        value: queryFilter.designated_market_area,
                      }
                    : null
                }
              />
            </Form.Group>
            <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
              <Form.Label htmlFor="place">Tags</Form.Label>
              <Select
                id="tags-multi"
                name="tags"
                isMulti
                onChange={selectHandler}
                options={tags}
                placeholder="Select Tags"
                value={
                  queryFilter.tags
                    ? queryFilter.tags.map(t => ({
                        label: t,
                        value: t,
                      }))
                    : null
                }
              />
            </Form.Group>
          </>
        ) : null}
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">Name</Form.Label>
          <Form.Control
            type="text"
            id="name"
            placeholder="Enter name"
            value={contactName}
            onChange={event => setContactName(event.target.value)}
          />
        </Form.Group>
        {!bContacts ? (
          <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
            <Form.Label htmlFor="place">Contact Type</Form.Label>
            <Select
              id="contactType"
              name="contact_type"
              onChange={selectHandler}
              options={contactTypes.map(type => ({
                label: type,
                value: type,
              }))}
              placeholder="Select Contact Type"
              value={
                queryFilter.contact_type
                  ? {
                      label: queryFilter.contact_type,
                      value: queryFilter.contact_type,
                    }
                  : null
              }
            />
          </Form.Group>
        ) : null}
        {!bContacts && orgUsers && (
          <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
            <Form.Label htmlFor="place">Assigned To</Form.Label>
            <Select
              id="assignedTo"
              name="assignedTo"
              onChange={selectHandler}
              options={orgUsers.map(u => ({
                label: `${u.name} ${u.lastName}`,
                value: `${u.name} ${u.lastName}`,
              }))}
              placeholder="Select Assigned User"
              value={
                queryFilter.assignedTo
                  ? {
                      label: queryFilter.assignedTo,
                      value: queryFilter.assignedTo,
                    }
                  : null
              }
            />
          </Form.Group>
        )}
        <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
          <Form.Label htmlFor="place">Email Address</Form.Label>
          <Form.Control
            type="text"
            id="email"
            placeholder="Enter email"
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
        </Form.Group>
        {showCapacityFilter && !bContacts ? (
          <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
            <Form.Label htmlFor="place">Donations Total</Form.Label>
            <Form.Control
              type="number"
              id="current_donations_total"
              value={donationTotal}
              onChange={event => setDonationTotal(event.target.value)}
              placeholder="e.g. 500 minimum"
            />
          </Form.Group>
        ) : null}
        {!showCapacityFilter && !bContacts ? (
          <Form.Group
            className="w-100"
            style={{
              marginBottom: '2rem',
              height: '100px',
            }}
          >
            <Form.Label htmlFor="place">Minimum Capacity</Form.Label>
            <Form.Control
              autoComplete="off"
              type="number"
              id="available_capacity"
              placeholder="Enter Min Capacity"
              value={
                queryFilter.available_capacity || queryFilter.donation_capacity
              }
              onChange={inputChangeHandler}
            />
            <CapacityDiv>
              <CapacityScopeRadioDiv>
                <Form.Check
                  type="radio"
                  id="donation_capacity_scope"
                  label="Available"
                  value="available"
                  checked={capacityScope === 'available'}
                  onChange={() => handleCapacityScopeChange('available')}
                  style={{ marginRight: '10px' }}
                />
                <Form.Check
                  type="radio"
                  id="donation_capacity_scope"
                  value="total"
                  checked={capacityScope == 'total'}
                  label="Total"
                  onChange={() => handleCapacityScopeChange('total')}
                />
              </CapacityScopeRadioDiv>
            </CapacityDiv>
          </Form.Group>
        ) : null}
        {showCapacityFilter ? (
          <>
            <Form.Group className="w-100" style={{ marginBottom: '2rem' }}>
              <Form.Label htmlFor="place">Ask/Capacity</Form.Label>
              <Form.Check
                type="radio"
                id="donation_capacity"
                label="At least"
                value="gte"
                checked={queryFilter.donation_capacity[0] === 'gte'}
                onClick={() => {
                  if (queryFilter.donation_capacity[0] === 'gte') {
                    setQueryFilter({
                      ...queryFilter,
                      donation_capacity: ['', queryFilter.donation_capacity[1]],
                    })
                  }
                }}
                onChange={radioChangeHandler}
              />
              <Form.Check
                type="radio"
                id="donation_capacity"
                value="lte"
                checked={queryFilter.donation_capacity[0] === 'lte'}
                label="At most"
                onClick={() => {
                  if (queryFilter.donation_capacity[0] === 'lte') {
                    setQueryFilter({
                      ...queryFilter,
                      donation_capacity: ['', queryFilter.donation_capacity[1]],
                    })
                  }
                }}
                onChange={radioChangeHandler}
              />
              <Form.Check
                type="radio"
                id="donation_capacity"
                value="eq"
                checked={queryFilter.donation_capacity[0] === 'eq'}
                label="Equals"
                onClick={() => {
                  if (queryFilter.donation_capacity[0] === 'eq') {
                    setQueryFilter({
                      ...queryFilter,
                      donation_capacity: ['', queryFilter.donation_capacity[1]],
                    })
                  }
                }}
                onChange={radioChangeHandler}
              />
              <Form.Check
                type="radio"
                id="available_capacity"
                value="gte"
                checked={queryFilter.available_capacity[0] === 'gte'}
                label="Available"
                onClick={() => {
                  if (queryFilter.available_capacity[0] === 'gte') {
                    setQueryFilter({
                      ...queryFilter,
                      available_capacity: [
                        '',
                        queryFilter.available_capacity[1],
                      ],
                    })
                  }
                }}
                onChange={radioChangeHandler}
              />
              <Form.Control
                type="number"
                placeholder="e.g. 500"
                value={capacityTotal}
                onChange={event => setCapacityTotal(event.target.value)}
              />
            </Form.Group>
          </>
        ) : null}
      </div>
    </>
  )
}

FilterContactsFormJS.propTypes = {
  resetFilter: PropTypes.func,
  setQueryFilter: PropTypes.func,
  orgUsers: PropTypes.array,
  contacts: PropTypes.arrayOf(PropTypes.object),
  queryFilter: PropTypes.object,
  showDonationTypes: PropTypes.bool,
  showEvents: PropTypes.bool,
  showCapacityFilter: PropTypes.bool,
  bContacts: PropTypes.bool,
}

const ModalFilterContactsJS = ({
  contacts,
  showFilterModal,
  handleCloseFilterModal,
  resetFilter,
  incomingFilter = initialQueryState,
  applyFilter,
  orgUsers,
  bContacts = false,
}) => {
  // State hooks
  const [queryFilter, setQueryFilter] = useState(initialQueryState)

  useEffect(() => {
    Object.values(incomingFilter).length
      ? setQueryFilter(incomingFilter)
      : setQueryFilter(initialQueryState)
  }, [incomingFilter])

  const filterContactsFormProps = {
    contacts,
    orgUsers,
    resetFilter,
    queryFilter,
    setQueryFilter,
    bContacts,
  }

  return (
    <Modal show={showFilterModal} onHide={handleCloseFilterModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Filter Contacts</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FilterContactsFormJS {...filterContactsFormProps} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleCloseFilterModal}>
          Close
        </Button>
        <Button variant="secondary" onClick={() => applyFilter(queryFilter)}>
          Apply Filter
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

ModalFilterContactsJS.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.object),
  showFilterModal: PropTypes.bool,
  handleCloseFilterModal: PropTypes.func,
  applyFilter: PropTypes.func,
  resetFilter: PropTypes.func,
  incomingFilter: PropTypes.object,
  orgUsers: PropTypes.array,
  bContacts: PropTypes.bool,
}

export default memo(ModalFilterContactsJS)
