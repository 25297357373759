import { Fragment, useCallback, useEffect, useState } from 'react'
import ToolBar from '../ToolBar/ToolbarBar'
import PropTypes from 'prop-types'
import './HostList.css'

const defaultOption = 'Automatic'

const HostList = ({
  items,
  font: { fontFamily, color, fontSize, fontWeight },
  disableSelect,
  colsNumber = 0,
  showToolbar = true,
  callBackItemUpdated,
  toogle = false,
  onChangeToogle,
}) => {
  const [_toogle, setToogle] = useState(false)

  const [gridItems, setGridItems] = useState()
  const [_colsNumber, setColsNumber] = useState(0)

  const [_fontSize, setFontSize] = useState(fontSize)
  const [bold, setBold] = useState(fontWeight ? true : false)
  const [_color] = useState(color || 'black')

  const setUpdate = item => {
    if (callBackItemUpdated) {
      callBackItemUpdated(item)
    }
  }

  useEffect(() => {
    if (onChangeToogle) {
      onChangeToogle(_toogle)
    }
  }, [_toogle])

  useEffect(() => {
    setToogle(toogle)
  }, [toogle])

  useEffect(() => {
    if (_colsNumber) {
      const rows = []
      const divider = Math.ceil(items.length / _colsNumber)
      if (_colsNumber === 1) {
        rows.push(items.slice(0, items.length))
      }
      if (_colsNumber === 2) {
        rows.push(items.slice(0, divider))
        rows.push(items.slice(divider, items.length))
      }

      if (_colsNumber === 3) {
        rows.push(items.slice(0, divider))
        rows.push(items.slice(divider, divider * 2))
        rows.push(items.slice(divider * 2, items.length))
      }

      setGridItems(rows)
    }
  }, [_colsNumber, items])

  const calculateCols = useCallback(() => {
    setColsNumber(items.length < 6 ? 1 : items.length < 11 ? 2 : 3)
  }, [items])

  // After load component
  useEffect(() => {
    if (!colsNumber) {
      calculateCols()
    } else {
      setColsNumber(colsNumber)
    }
  }, [items, colsNumber, calculateCols])

  return (
    <div className="text-center position-relative">
      {showToolbar && (
        <div className="row justify-content-end">
          <div
            className={
              disableSelect ? 'd-flex justify-content-end' : 'col-auto'
            }
          >
            {_toogle && (
              <>
                <ToolBar
                  handlerInc={() => {
                    setUpdate({
                      items: gridItems?.flat() || [],
                      font: {
                        fontFamily,
                        color: _color,
                        fontSize: _fontSize,
                        fontWeight: bold ? 'bold' : '',
                      },
                      colsNumber: _colsNumber,
                    })
                    setFontSize(_fontSize + 1)
                  }}
                  handlerDec={() => {
                    setUpdate({
                      items: gridItems?.flat() || [],
                      font: {
                        fontFamily,
                        color: _color,
                        fontSize: _fontSize,
                        fontWeight: bold ? 'bold' : '',
                      },
                      colsNumber: _colsNumber,
                    })
                    setFontSize(_fontSize - 1)
                  }}
                  handlerBold={() => {
                    setUpdate({
                      items: gridItems?.flat() || [],
                      font: {
                        fontFamily,
                        color: _color,
                        fontSize: _fontSize,
                        fontWeight: bold ? 'bold' : '',
                      },
                      colsNumber: _colsNumber,
                    })
                    setBold(!bold)
                  }}
                  bold={bold}
                  color={_color}
                />

                {!disableSelect && (
                  <select
                    className="form-select mt-1"
                    aria-label="Default select example"
                    value={_colsNumber}
                    onChange={e => {
                      if (e?.target.value) {
                        if (e?.target.value !== defaultOption) {
                          setUpdate({
                            items: gridItems?.flat() || [],
                            font: {
                              fontFamily,
                              color: _color,
                              fontSize: _fontSize,
                              fontWeight: bold ? 'bold' : '',
                            },
                            colsNumber: Number(e?.target.value),
                          })
                          setColsNumber(Number(e?.target.value))
                        } else {
                          setUpdate({
                            items: gridItems?.flat() || [],
                            font: {
                              fontFamily,
                              color: _color,
                              fontSize: _fontSize,
                              fontWeight: bold ? 'bold' : '',
                            },
                            colsNumber:
                              items.length < 6 ? 1 : items.length < 11 ? 2 : 3,
                          })
                          calculateCols()
                        }
                      }
                    }}
                  >
                    <option>{defaultOption}</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                )}
              </>
            )}
            <div
              className={
                !disableSelect
                  ? 'position-absolute top-0 end-0 ' + (_toogle ? ' px-2' : '')
                  : 'd-flex align-items-center'
              }
            >
              <span role="button" onClick={() => setToogle(!_toogle)}>
                {_toogle ? '▹' : '☰'}
              </span>
            </div>
          </div>
        </div>
      )}

      <div className="row justify-content-md-center m-2">
        {(gridItems || []).map((items, index) => (
          <div className="col-auto mx-auto" key={`items-${index}`}>
            {items.map((item, index) => (
              <Fragment key={`${item}${index}`}>
                <span
                  style={{
                    fontFamily,
                    fontSize: `${_fontSize}px`,
                    color: _color,
                    fontWeight: bold ? 'bold' : 'normal',
                  }}
                >
                  {item}
                </span>
                <br />
              </Fragment>
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

HostList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  font: PropTypes.shape({
    fontFamily: PropTypes.string,
    fontSize: PropTypes.number,
    color: PropTypes.string,
    fontWeight: PropTypes.string,
  }).isRequired,
  showToolbar: PropTypes.bool,
  colsNumber: PropTypes.number,
  disableSelect: PropTypes.bool,
  callBackItemUpdated: PropTypes.func,
  toogle: PropTypes.bool,
  onChangeToogle: PropTypes.func,
}

export default HostList
