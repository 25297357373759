import { prop } from 'ramda'
import constants from '../config/constants'
import axios from './axios'

const addEventAttendees = async (
  eventId,
  { bundlers, staffers, specialGuests }
) => {
  const bundlersRef = bundlers.map(contactId => ({
    eventId,
    contactId,
    isVip: false,
    isBundler: true,
    isStaffer: false,
    isGuest: false,
  }))
  const staffersRef = staffers.map(contactId => ({
    eventId,
    contactId,
    isVip: false,
    isBundler: false,
    isStaffer: true,
    isGuest: false,
  }))
  const specialGuestsRef = specialGuests.map(({ guestName }) => ({
    eventId,
    guestName: guestName,
    isVip: false,
    isBundler: false,
    isStaffer: false,
    isGuest: true,
  }))
  const attendees = [...bundlersRef, ...staffersRef, ...specialGuestsRef]

  const url = `${constants.ATTENDEES_ENDPOINT()}`
  return axios({
    url,
    method: 'POST',
    data: JSON.stringify(attendees),
  }).then(prop('data'))
}

const deleteAttendeesByIds = async attendees => {
  const url = `${constants.ATTENDEES_ENDPOINT()}`
  return axios({
    url,
    method: 'DELETE',
    data: attendees,
  }).then(prop('data'))
}

const getEventAttendees = async (eventId, user, isAllowed = false) => {
  let endpoint = constants.ATTENDEES_ENDPOINT()
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')
  const userIdParam = user ? `?userId=${encodeURIComponent(user._id)}` : ''
  const params = `/eventId/${encodeURIComponent(eventId || '')}${userIdParam}`
  const url = `${endpoint}${params}`
  return axios({
    url,
    method: 'GET',
  }).then(prop('data'))
}

export default {
  addEventAttendees,
  getEventAttendees,
  deleteAttendeesByIds,
}
