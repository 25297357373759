import { EditorState, Entity, Modifier } from 'draft-js'

export const getCurrentBlock = (editorState: EditorState) => {
  const currentSelection = editorState.getSelection()
  console.log(
    currentSelection.getStartOffset(),
    currentSelection.getAnchorOffset(),
    currentSelection.getStartKey(),
    currentSelection.getFocusKey()
  )
  const blockKey = currentSelection.getStartKey()
  return editorState.getCurrentContent().getBlockForKey(blockKey)
}

export const getCurrentText = (editorState: EditorState) => {
  const currentBlock = getCurrentBlock(editorState)
  const blockText = currentBlock.getText()
  return blockText
}

export const addMention = (
  fieldName: string,
  fieldKey: string,
  editorState: EditorState
) => {
  const currentContent = editorState.getCurrentContent()
  const selection = editorState.getSelection()
  const entityKey = Entity.create('MENTION', 'IMMUTABLE', {
    text: fieldName,
    value: fieldKey,
    url: ' ',
  })
  const textWithEntity = Modifier.insertText(
    currentContent,
    selection,
    fieldKey,
    undefined,
    entityKey
  )
  return EditorState.push(editorState, textWithEntity, 'insert-characters')
}
