import React, { FC } from 'react'
import { Toast, ToastContainer } from 'react-bootstrap'
import { ToastPosition } from 'react-bootstrap/esm/ToastContainer'

export interface ToastMessageProps {
  position: ToastPosition
  showToast: boolean
  delay?: number
  title?: React.ReactNode
  message?: React.ReactNode
  buttons?: React.ReactNode
  onClose?: (
    e?:
      | React.MouseEvent<Element, MouseEvent>
      | React.KeyboardEvent<Element>
      | undefined
  ) => void
}
// https://react-bootstrap.github.io/docs/components/toasts
const defaultTitle = <strong className="me-auto">Message</strong>
const ToastMessage: FC<ToastMessageProps> = ({
  position,
  showToast,
  title = defaultTitle,
  delay,
  onClose,
  message,
  buttons,
}) => {
  return (
    <ToastContainer position={position}>
      <Toast
        show={showToast}
        onClose={onClose}
        delay={delay}
        autohide={!!delay}
      >
        <Toast.Header>
          {typeof title === 'string' ? (
            <strong className="me-auto">{title}</strong>
          ) : (
            title
          )}
        </Toast.Header>
        <Toast.Body style={{ background: '#f8f9fa' }}>
          {message}
          {buttons}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default ToastMessage
