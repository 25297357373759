import axios from './axios'
import { prop } from 'ramda'
import constants from '../config/constants'

const signInFederatedUser = async (username, providerProfile = {}) => {
  const endpoint = constants.FEDERATED_USERS_ENDPOINT()
  const url = `${endpoint}`
  return axios({
    url,
    method: 'POST',
    data: { username, ...providerProfile },
  })
    .then(prop('data'))
    .catch(err => {
      console.error('error at login', err)
      const error = new Error(`Invalid credentials: ${username}`)
      error.type = 'INVALID_CREDENTIALS'
      throw error
    })
}

const login = async (username, password) => {
  const endpoint = constants.LOGIN_ENDPOINT()
  const url = `${endpoint}`
  return axios({
    url,
    method: 'POST',
    headers: { 'content-type': 'application/json', 'auth-doman': 'benjamin' },
    data: JSON.stringify({ username, password }),
  })
    .then(prop('data'))
    .catch(err => {
      console.error('error at login', err)
      const error = new Error(`Invalid credentials: ${username}`)
      error.type = 'INVALID_CREDENTIALS'
      throw error
    })
}

const session = async sessionToken => {
  const endpoint = constants.SESSION_ENDPOINT()
  const url = `${endpoint}`
  return axios({
    url,
    method: 'POST',
    headers: { 'Session-Token': sessionToken },
  })
    .then(prop('data'))
    .catch(err => {
      console.error('error at session', err)
    })
}

export default {
  login,
  session,
  signInFederatedUser,
}
