import {
  getParticipantEmail,
  getParticipants,
  getParticipantStatus,
  getUrlMeeting,
  getCallProvider,
  getZoomCode,
  getZoomPass,
  getContactIdZoomMeeting,
} from '../../../utils/dataModels/events'
import PropTypes from 'prop-types'
import constants from '../../../config/constants'
import { FieldTitle, FieldValue, SectionTitle } from './EventModal'
import { HiOutlineUsers } from 'react-icons/hi'
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineQuestionCircle,
} from 'react-icons/ai'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { getUserOrganizationId } from '../../../utils/state/selectors'
import { useZoomCredentials } from '../../../hooks/useZoomCredentials'

const EventProvider = ({ event }) => {
  const urlMeeting = getUrlMeeting(event)
  const participants = getParticipants(event)
  const callProvider = getCallProvider(event) === 'Zoom Meeting'
  const organizationId = useSelector(getUserOrganizationId)
  const { hasCredentials } = useZoomCredentials()

  const setStorageZoomData = data =>
    localStorage.setItem('B-ZOOM-METTING-DATA', JSON.stringify(data))

  const handleOnClickCallPortal = () => {
    if (!hasCredentials) return
    const zoomCode = getZoomCode(event)
    const zoomPass = getZoomPass(event)
    const zoomContactId = getContactIdZoomMeeting(event)
    setStorageZoomData({
      encryptedPassword: zoomPass,
      meetingNumber: zoomCode,
      joinUrl: urlMeeting,
      organizationId,
    })
    window.open(
      `${window.location.origin}/call-portal/video-call/${zoomContactId}`
    )
  }

  const participantStatusIcon = status => {
    switch (status) {
      case constants.PARTICIPANT_STATUS.yes:
        return <AiOutlineCheckCircle fill="#1675cf" />
      case constants.PARTICIPANT_STATUS.no:
        return <AiOutlineCloseCircle fill="#F60040" />
      default:
        return <AiOutlineQuestionCircle fill="#6c757d" />
    }
  }
  return (
    <>
      <Modal.Body>
        {urlMeeting && (
          <>
            <SectionTitle>Conferencing</SectionTitle>
            <div className="d-flex flex-row flex-nowrap justify-content-between">
              <div className="w-100">
                {!callProvider && <FieldTitle>Details</FieldTitle>}
                <FieldValue>
                  {!callProvider && (
                    <a href={urlMeeting} target="_blank" rel="noreferrer">
                      {urlMeeting}
                    </a>
                  )}
                  {callProvider && (
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleOnClickCallPortal}
                      disabled={!hasCredentials}
                    >
                      Launch Call Portal
                    </button>
                  )}
                </FieldValue>
              </div>
            </div>
          </>
        )}
        {!!participants.length && (
          <div className="d-flex flex-row flex-nowrap justify-content-between">
            <div className="w-100">
              <FieldTitle>
                Guests <HiOutlineUsers />
              </FieldTitle>
              <FieldValue className="d-flex flex-row gap-2 flex-wrap">
                {participants.map(participant => (
                  <small key={getParticipantEmail(participant)}>
                    {getParticipantEmail(participant)}{' '}
                    {participantStatusIcon(getParticipantStatus(participant))}
                  </small>
                ))}
              </FieldValue>
            </div>
          </div>
        )}
      </Modal.Body>
    </>
  )
}

EventProvider.propTypes = {
  event: PropTypes.object.isRequired,
}
export default EventProvider
