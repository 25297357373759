import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col, Row, Stack } from 'react-bootstrap'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { propOr } from 'ramda'

const defaultNotes = ``

const Styledcontainer = styled.div`
  border-radius: 0.25rem;
  padding: 1rem;
  border-style: solid;
  border-width: 1px;
  border-color: #d9d9d9;
`

const SpanTitle = styled.span`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`
export const SpanDescription = styled.span`
  font-family: DM Sans;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${props => (props.isGrey ? `#79838d;` : `#3b4248;`)};
`

const SpanText = styled.span`
  color: #00bdb2;
  font-family: DM Sans;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.125rem;
  letter-spacing: 0em;
  text-align: left;
  width: 6rem;
`

const HR = styled.hr`
  color: #00bdb2;
  background-color: #00bdb2;
  height: 1;
  width: 100%;
`

const StyledStack = styled(Stack)`
  &:hover {
    cursor: pointer;
  }
`

const getNotes = propOr(defaultNotes, 'notes')

const ContactNotes = ({ contact }) => {
  const [showMore, setShowMore] = React.useState(false)

  return (
    <Styledcontainer>
      <Row className="pb-4">
        <Col>
          <SpanTitle>Notes</SpanTitle>
        </Col>
      </Row>
      {getNotes(contact) != '' ? (
        <>
          <Row className="pb-4">
            <Col
              style={{
                maxHeight: showMore ? `none` : `10.25rem`,
                overflow: `hidden`,
              }}
            >
              <SpanDescription>{getNotes(contact)}</SpanDescription>
            </Col>
          </Row>
        </>
      ) : (
        <Row className="pb-4">
          <Col>
            <SpanDescription isGrey>{`Contact Notes`}</SpanDescription>
          </Col>
        </Row>
      )}
    </Styledcontainer>
  )
}

ContactNotes.propTypes = {
  contact: PropTypes.object,
}

export default ContactNotes
