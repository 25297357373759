import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import IndeterminateCheckbox from '../PipelineCRM/IndeterminateCheckbox'
import heat_icon from '../../assets/icons/heat_icon.svg'
import heat_icon_2 from '../../assets/icons/heat_icon_2.svg'
import heat_icon_3 from '../../assets/icons/heat_icon_3.svg'
import no_heat_icon from '../../assets/icons/no_heat_icon.svg'
import { statusOptions, typeOptions } from '../PipelineCRM/PipelineFilter'
import { getAvatarContact } from '../../utils/stringsOperations'
import { useDispatch, useSelector } from 'react-redux'
import {
  setAddDonationTypeModal,
  setRefreshConsolidated,
} from '../../redux/pipeline/pipeline'
import { updateDonationData } from '../../api/donations_data'
import { getUser } from '../../utils/state/selectors'

const dropdownStyle = {
  border: 'none',
  color: '#11273C',
  textAlign: 'center',
  fontFamily: 'Inter, sans-serif',
  fontSize: '10px',
  padding: '2px 0px',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
}

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
})

const renderIcons = idx => {
  switch (idx) {
    case 0:
      return <img src={no_heat_icon} style={{ height: '13px' }} />
    case 1:
      return <img src={heat_icon} style={{ height: '18px' }} />
    case 2:
      return <img src={heat_icon_2} style={{ height: '18px' }} />
    case 3:
      return <img src={heat_icon_3} style={{ height: '18px' }} />
  }
}

const columns = [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
    size: 35,
  },
  {
    accessorFn: row => row.first_name + ' ' + row.last_name,
    header: 'NAME',
    id: 'name',
    cell: ({ row, getValue }) => {
      return (
        <Link
          to={`/contact/${row.original.contact_person_id}`}
          style={{ textDecoration: 'none' }}
        >
          <div className="event-name-cell">
            {getAvatarContact(row.original, '30')}
            {getValue()}
          </div>
        </Link>
      )
    },
    // cell: info => info.getValue(),
    size: 240,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'donationStatus',
    header: 'STATUS',
    id: 'status',
    cell: ({ row }) => {
      const dispatch = useDispatch()
      const user = useSelector(getUser())
      const handleAddClose = () =>
        dispatch(
          setAddDonationTypeModal({
            show: false,
            onAdd: () => null,
            onClose: () => null,
          })
        )
      const handleAddDeposit = async data => {
        const update = await updateDonationData({
          contact_person_id: row.original.contact_person_id,
          donation_date: new Date(),
          donation_total: data.amount,
          organizationId: row.original.organizationId,
          election_type: data.election_type,
          committee: '',
          event_id: row.original.eventId,
          donation_type: 'Deposit',
          bundlers: [user._id],
          donation_notes: row.original.donationNotes,
          donation_status: 'Donor',
        })
        if (update) {
          dispatch(setRefreshConsolidated(true))
          handleAddClose()
        }
      }
      const handleAddPledge = async data => {
        const update = await updateDonationData({
          contact_person_id: row.original.contact_person_id,
          donation_date: new Date(),
          donation_total: data.amount,
          organizationId: row.original.organizationId,
          election_type: data.election_type,
          committee: '',
          event_id: row.original.eventId,
          donation_type: 'Pledge',
          bundlers: [user._id],
          donation_notes: row.original.donationNotes,
          donation_status: row.original.donationStatus,
        })
        if (update) {
          dispatch(setRefreshConsolidated(true))
          handleAddClose()
        }
      }
      const currentStatus = statusOptions.find(s => {
        if (row.original.vip) return s.label === 'VIP'
        if (row.original.donationType === 'Deposit') return s.label === 'Paid'
        if (row.original.donationType === 'Pledge') return s.label === 'Pledged'
        if (row.original.donationStatus)
          return s.label === row.original.donationStatus
        if (
          s.label
            .toLowerCase()
            .includes(row.original.donationType.toLowerCase())
        )
          return s
        if (row.original.donationType === 'prospect')
          return s.label === 'Not Started'
      })
      if (
        row.original.donationType === 'prospect' ||
        row.original.donationType === 'Pledge'
      ) {
        return (
          <Dropdown
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onSelect={async value => {
              if (value === 'Pledged' || value === 'Paid') {
                dispatch(
                  setAddDonationTypeModal({
                    show: true,
                    onAdd:
                      value === 'Paid' ? handleAddDeposit : handleAddPledge,
                    onClose: handleAddClose,
                    defaultContactOption: row.original.contact_person_id,
                    disabledContactsDropdown: true,
                    canCreateContact: false,
                    canSelectElectionType: true,
                    donationType: value === 'Paid' ? 'Deposit' : undefined,
                    defaultTitle:
                      currentStatus &&
                      currentStatus.label === 'Pledged' &&
                      value === 'Pledged'
                        ? 'Edit Pledge'
                        : currentStatus &&
                          currentStatus.label === 'Pledged' &&
                          value === 'Paid'
                        ? 'Add Donation'
                        : undefined,
                    defaultDonationAmount: row.original.donation,
                    defaultElectionType: row.original.electionType,
                  })
                )
              } else {
                const update = await updateDonationData({
                  contact_person_id: row.original.contact_person_id,
                  donation_date: new Date(),
                  donation_total: row.original.donation,
                  organizationId: row.original.organizationId,
                  election_type: row.original.election_type,
                  committee: '',
                  event_id: row.original.eventId,
                  donation_type: row.original.donationType,
                  bundlers: [user._id],
                  donation_notes: row.original.donationNotes,
                  donation_status: value,
                })
                if (update) {
                  dispatch(setRefreshConsolidated(true))
                }
              }
            }}
          >
            <Dropdown.Toggle
              style={{
                ...dropdownStyle,
                backgroundColor: currentStatus ? currentStatus.color : 'unset',
                width: `calc(var(--header-status-size) * 1px)`,
              }}
            >
              {currentStatus ? currentStatus.label : null}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {statusOptions
                .filter(t => {
                  if (
                    currentStatus &&
                    currentStatus.label &&
                    currentStatus.label === 'Pledged'
                  ) {
                    return t.label === 'Pledged' || t.label === 'Paid'
                  } else
                    return (
                      t.label !== 'Not Started' &&
                      t.label !== 'Paid' &&
                      t.label !== 'VIP'
                    )
                })
                .map(s => (
                  <Dropdown.Item
                    key={s.label}
                    eventKey={s.label}
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '10px',
                    }}
                  >
                    {currentStatus &&
                    currentStatus.label === 'Pledged' &&
                    s.label === 'Pledged'
                      ? 'Edit Pledge'
                      : s.label === 'Pledged'
                      ? 'Add Pledge'
                      : s.label}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        )
      } else {
        return (
          <div
            style={{
              backgroundColor: currentStatus ? currentStatus.color : 'unset',
              color: '#11273C',
            }}
            className="table-dropdown-button"
          >
            {currentStatus ? currentStatus.label : null}
          </div>
        )
      }
    },
    size: 120,
    filterFn: (row, columnId, filterValue) => {
      if (filterValue) {
        if (
          row.original.donationStatus === filterValue &&
          row.original.donation === 0
        ) {
          return true
        } else if (
          filterValue === 'Not Started' &&
          row.original.donationStatus === '' &&
          row.original.donation === 0
        ) {
          return true
        } else if (
          filterValue === 'Paid' &&
          row.original.donationType === 'Deposit'
        ) {
          return true
        } else if (
          filterValue === 'Pledged' &&
          row.original.donationType === 'Pledge'
        ) {
          return true
        } else if (row.original.vip && filterValue === 'VIP') {
          return true
        } else {
          return false
        }
      }
    },
    footer: props => props.column.id,
  },
  {
    accessorKey: 'donation',
    header: 'DONATION',
    id: 'donation',
    cell: ({ getValue, row }) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            cursor: 'default',
            fontWeight:
              row.original.donationType &&
              row.original.donationType === 'Deposit'
                ? 700
                : 'unset',
          }}
        >
          {getValue() ? moneyFormatter.format(getValue()) : null}
        </div>
      )
    },
    size: 135,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'donation_capacity',
    header: 'ASK/CAPACITY',
    id: 'capacity',
    cell: ({ getValue }) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            cursor: 'default',
          }}
        >
          {typeof getValue() === 'number'
            ? moneyFormatter.format(getValue())
            : getValue()}
        </div>
      )
    },
    size: 135,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'type',
    header: 'TYPE',
    id: 'type',
    cell: ({ getValue, row }) => {
      const currentType = typeOptions.find(t => {
        if (row.original.vip) return t.label === 'Host'
        if (row.original.donation) return t.label === 'Donor'
        if (row.original.donation === 0) return t.label === 'New'
      })
      if (row.original.donation === 0) {
        return (
          <Dropdown
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Dropdown.Toggle
              style={{
                ...dropdownStyle,
                backgroundColor: currentType ? currentType.color : 'unset',
                width: `calc(var(--header-type-size) * 1px)`,
              }}
            >
              {currentType ? currentType.label : null}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {typeOptions
                .filter(t => t.label === 'Declined')
                .map(t => (
                  <Dropdown.Item
                    key={t.label}
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontSize: '10px',
                    }}
                  >
                    {t.label}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        )
      } else {
        return (
          <div
            style={{
              backgroundColor: currentType ? currentType.color : 'unset',
              color: '#11273C',
            }}
            className="table-dropdown-button"
          >
            {currentType ? currentType.label : null}
          </div>
        )
      }
    },
    size: 120,
    footer: props => props.column.id,
  },
  {
    accessorFn: row => row.first_name + ' ' + row.last_name,
    header: 'HOST',
    id: 'host',
    cell: ({ row, getValue }) => {
      return (
        <Link
          to={`/contact/${row.original.contact_person_id}`}
          style={{ textDecoration: 'none' }}
        >
          <div className="event-name-cell">
            {getAvatarContact(row.original, '30')}
            {getValue()}
          </div>
        </Link>
      )
    },
    // cell: info => info.getValue(),
    size: 240,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'donationNotes',
    header: 'NOTES',
    id: 'notes',
    cell: ({ getValue, row: { index, original }, column: { id }, table }) => {
      const user = useSelector(getUser())
      const dispatch = useDispatch()
      const initialValue = getValue()
      const [value, setValue] = React.useState(initialValue)
      React.useEffect(() => {
        setValue(initialValue)
      }, [initialValue])

      const handleAddNotes = async () => {
        const update = await updateDonationData({
          contact_person_id: original.contact_person_id,
          donation_date: new Date(),
          donation_total: original.donation,
          organizationId: original.organizationId,
          election_type: original.election_type,
          committee: '',
          event_id: original.eventId,
          donation_type: original.donationType,
          bundlers: [user._id],
          donation_notes: value,
          donation_status: original.donationStatus,
        })
        if (update) {
          dispatch(setRefreshConsolidated(true))
        }
      }

      return (
        <textarea
          value={value}
          onChange={e => setValue(e.target.value)}
          onBlur={handleAddNotes}
          placeholder={`Enter Notes...`}
          rows={1}
        />
      )
    },
    footer: props => props.column.id,
  },
]

export default columns
