import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as R from 'ramda'
import './App.css'
import MainRouter from './routers/MainRouter'
import AuthenticationRouter from './routers/AuthenticationRouter'
import { useDispatch } from 'react-redux'
import { sessionAction } from './redux/actions/authentication'
import {
  getAddDonationTypeModalPayload,
  getCreateContactModalPayload,
  getEditContactModalPayload,
  getToastList,
} from './utils/state/selectors'
import ToastMessage from './components/ui-elements/ToastMessage'
import { removeToast } from './redux/toasts/toasts'
import EditContactModal from './components/ContactIdPage/EditContactModal'
import AddDonationTypeModal from './components/EventDetailPage/AddDonationTypeModal/AddDonationTypeModal'
import CreateContactModal from './components/ContactsPage/CreateContactModal'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

const App = () => {
  const dispatch = useDispatch()
  const isAuthenticated = useSelector(
    R.pathOr(false, ['authentication', 'isAuthenticated'])
  )
  const sessionToken = sessionStorage.getItem('Session-Token')
  const expiration = sessionStorage.getItem('expiration')

  const toastList = useSelector(getToastList)
  const editContactModalPayload = useSelector(getEditContactModalPayload)
  const addDonationTypeModalPayload = useSelector(
    getAddDonationTypeModalPayload
  )
  const createContactModalPayload = useSelector(getCreateContactModalPayload)

  useEffect(() => {
    // check if a valid session exists & restore it
    const now = Date.now()
    const hasValidExpiration = expiration - now > 0
    if (sessionToken && hasValidExpiration && !isAuthenticated) {
      dispatch(sessionAction(sessionToken))
    }
  }, [])

  return (
    <BrowserRouter>
      {toastList.map(toast => (
        <ToastMessage
          delay={toast.delay}
          key={toast.id}
          title={toast.title}
          message={toast.message}
          position={toast.position}
          showToast={true}
          onClose={evt => {
            dispatch(removeToast({ id: toast.id }))
            toast?.onCloseCallback && toast.onCloseCallback(evt)
          }}
          buttons={toast.buttons}
        />
      ))}

      {editContactModalPayload.show && (
        <EditContactModal {...editContactModalPayload} />
      )}
      {addDonationTypeModalPayload.show && (
        <AddDonationTypeModal {...addDonationTypeModalPayload} />
      )}
      {createContactModalPayload.show && (
        <CreateContactModal {...createContactModalPayload} />
      )}

      {isAuthenticated && (
        <QueryClientProvider client={queryClient}>
          <MainRouter />
        </QueryClientProvider>
      )}
      {!isAuthenticated && !sessionToken && <AuthenticationRouter />}
    </BrowserRouter>
  )
}

export default App
