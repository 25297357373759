import { useState, useEffect } from 'react'

export const useScript = (url, name, load = true) => {
  const [lib, setLib] = useState({})

  useEffect(() => {
    if (load) {
      let script = document.getElementById(name)
      if (!script) {
        script = document.createElement('script')
        script.id = name
        script.src = url
        script.async = true
        script.onload = () => {
          setLib({ [name]: window[name] })
        }
        script.onerror = () => {
          console.log('error')
        }
        document.body.appendChild(script)
      }

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [url, name, load])

  return lib
}
