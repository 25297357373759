import { prop } from 'ramda'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import axios from '../../api/axios'
import constants from '../../config/constants'
import CenterSpinner from '../CenterSpinner/CenterSpinner'
import FtpUploadTable from './FtpUploadTable'

const FtpUpload = () => {
  const [data, setData] = useState(null)
  const [loading, setIsLoading] = useState(true)

  const url = constants.FTP_ENDPOINT()
  const options = {
    url,
    method: 'POST',
    data: {},
  }

  useEffect(() => {
    setIsLoading(true)
    axios(options)
      .then(prop('data'))
      .then(data => {
        setData(Object.values(data).map(item => ({ ...item })))
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }, [])

  const loadFTPdata = () => {
    setIsLoading(true)
    axios(options)
      .then(prop('data'))
      .then(data => {
        setData(Object.values(data).map(item => ({ ...item })))
        setIsLoading(false)
      })
      .catch(error => {
        console.log(error)
        setIsLoading(false)
      })
  }

  return (
    <Container>
      <Row>
        <Col sm={2}>
          <Button onClick={loadFTPdata}>Load data</Button>
        </Col>
      </Row>
      <Row>
        <Col className="pt-4">
          {data && !loading ? (
            <FtpUploadTable data={data} />
          ) : loading ? (
            <CenterSpinner />
          ) : (
            <p>Can&lsquo;t find any data, check your FTP server.</p>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default FtpUpload
