import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Col, Row, Stack } from 'react-bootstrap'
import GraphSummary from './GraphSummary'
import * as R from 'ramda'
import { getWinRedUrl } from './utils'
import { LinkSpan } from '../ContactUploadFilePage/ContactUploadFilePage/styles'
import { useSelector } from 'react-redux'
import { decryptData } from '../../utils/crypto/crypto'
import { getUser, getUserEmail } from '../../utils/state/selectors'

const Styledcontainer = styled.div`
  border-radius: 0.25rem;
  padding: 1rem;
  border-style: solid;
  border-width: 1px;
  border-color: #d9d9d9;
  margin-bottom: 1.7rem;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.08);
`
const TagDiv = styled.div`
  background-color: #0d6efd;
  border-radius: 6.25rem;
  height: 1.275rem;
  width: 4.725rem;

  padding: 0.25rem 0.5rem 0.25rem 0.5rem;

  font-family: DM Sans;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 0.75rem;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
`

const SpanDetails = styled.span`
  font-family: DM Sans;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.313rem;
  letter-spacing: 0em;
  text-align: left;
`

const isDefined = x => !(R.isNil(x) || R.isEmpty(x))

const EventSummary = ({ event, consolidated }) => {
  const user = useSelector(getUser) || decryptData(getUserEmail)

  const [winRedUrl, setWinRedUrl] = useState(null)
  useEffect(() => {
    getWinRedUrl(event?.organizationId, event?.name, user).then(url => {
      setWinRedUrl(url)
    })
  }, [event])
  const getFormatTime = time => {
    return moment(time).format('MMM Do, YYYY')
  }

  return (
    <Styledcontainer>
      <Row className="pb-4">
        <Col>
          <Stack gap={3} direction="horizontal">
            <TagDiv>Upcoming</TagDiv>
            <SpanDetails>
              {event &&
                `${event?.city}, ${event?.state} - ${getFormatTime(
                  event?.day
                )}`}
            </SpanDetails>
            {isDefined(winRedUrl) && (
              <>
                {' — '}
                <LinkSpan
                  fontSize={'1.125rem'}
                  marginTop={'0.8rem'}
                  onClick={() => {
                    window.open(winRedUrl, '_blanl', 'noreferrer')
                  }}
                >
                  {winRedUrl}
                </LinkSpan>
              </>
            )}
          </Stack>
        </Col>
      </Row>
      <Row>
        <Col>
          {event && <GraphSummary event={event} consolidated={consolidated} />}
        </Col>
      </Row>
    </Styledcontainer>
  )
}

EventSummary.propTypes = {
  event: PropTypes.object.isRequired,
  consolidated: PropTypes.object.isRequired,
}

export default EventSummary
