import React, { useEffect, useState } from 'react'
import EditModeControl from '../Edit/EditModeControl'
import ToolBar from '../ToolBar/ToolbarBar'
import PropTypes from 'prop-types'

const TextInvite = ({
  text,
  font: { fontFamily, color, fontSize, fontWeight },
  callBackItemUpdated,
  editControl,
  showToolbar = true,
  toogle = false,
  onChangeToogle,
  border = null,
  padding = null,
}) => {
  const [_toogle, setToogle] = useState(false)

  const [_text, setText] = useState(text)
  const [_fontSize, setFontSize] = useState(fontSize)
  const [bold, setBold] = useState(fontWeight ? true : false)
  const [_color, setColor] = useState(color || 'black')

  const setUpdate = item => {
    if (callBackItemUpdated) {
      callBackItemUpdated(item)
    }
  }

  const urlify = text => {
    const urlRegex = /(https?:\/\/[^\s]+)/g
    return {
      __html: text.replace(
        urlRegex,
        `<a style="text-decoration:none; color: ${_color}" href="$1" target="_blank">$1</a>`
      ),
    }
  }

  useEffect(() => {
    if (onChangeToogle) {
      onChangeToogle(_toogle)
    }
  }, [_toogle])

  useEffect(() => {
    setToogle(toogle)
  }, [toogle])

  return (
    <div className="text-center">
      {showToolbar && (
        <div className="row justify-content-end">
          <div className="col-auto">
            <div className="d-flex">
              {_toogle && (
                <ToolBar
                  handlerInc={() => {
                    setFontSize(_fontSize + 1)
                    setUpdate({
                      text: _text,
                      font: {
                        fontFamily,
                        color: _color,
                        fontSize: _fontSize + 1,
                        fontWeight: bold ? 'bold' : '',
                      },
                    })
                  }}
                  handlerDec={() => {
                    setUpdate({
                      text: _text,
                      font: {
                        fontFamily,
                        color: _color,
                        fontSize: _fontSize - 1,
                        fontWeight: bold ? 'bold' : '',
                      },
                    })
                    setFontSize(_fontSize - 1)
                  }}
                  handlerBold={() => {
                    setUpdate({
                      text: _text,
                      font: {
                        fontFamily,
                        color: _color,
                        fontSize: _fontSize + 1,
                        fontWeight: !bold ? 'bold' : '',
                      },
                    })
                    setBold(!bold)
                  }}
                  changeColor={event => {
                    setColor(event.target.value)
                  }}
                  color={_color}
                  bold={bold}
                />
              )}
              <div className="d-flex align-items-center">
                <span
                  role="button"
                  onClick={() => {
                    setToogle(!_toogle)
                  }}
                >
                  {_toogle ? '▹' : '☰'}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row justify-content-center">
        <div className="col col-md-12">
          {editControl && (
            <EditModeControl
              editionDisabledClass="row justify-content-center align-items-center"
              callBackSetValue={text => {
                setUpdate({
                  text,
                  font: {
                    fontFamily,
                    color: _color,
                    fontSize: _fontSize + 1,
                    fontWeight: !bold ? 'bold' : '',
                  },
                })
                setText(text)
              }}
              initialValue={_text}
              changeButtonText="✎"
              okButtonText="✓"
              cancelButtonText="✗"
              RenderText={
                <span
                  style={{
                    fontFamily,
                    fontSize: `${_fontSize}px`,
                    color: _color,
                    fontWeight: bold ? 'bold' : 'normal',
                  }}
                >
                  {_text}
                </span>
              }
              editContainerClass="col-auto d-flex align-items-center"
              editButtonClass="btn"
            />
          )}
          {!editControl && (
            <span
              style={{
                fontFamily,
                fontSize: `${_fontSize}px`,
                color: _color,
                fontWeight: bold ? 'bold' : 'normal',
                border: `${border}`,
                padding: `${padding}`,
              }}
              dangerouslySetInnerHTML={urlify(_text)}
            ></span>
          )}
        </div>
      </div>
    </div>
  )
}

TextInvite.propTypes = {
  text: PropTypes.string.isRequired,
  font: PropTypes.shape({
    fontFamily: PropTypes.string,
    fontSize: PropTypes.number,
    color: PropTypes.string,
    fontWeight: PropTypes.string,
  }).isRequired,
  editControl: PropTypes.bool,
  showToolbar: PropTypes.bool,
  toogle: PropTypes.bool,
  callBackItemUpdated: PropTypes.func,
  onChangeToogle: PropTypes.func,
  border: PropTypes.string,
  padding: PropTypes.string,
}

export default TextInvite
