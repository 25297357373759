import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Col, Form, Row } from 'react-bootstrap'
import styled from 'styled-components'
import imagePlaceholder from '../../assets/illustrations/image-placeholder.png'
import uploadIcon from '../../assets/illustrations/upload-transparent.png'
import RedAsterisk from '../ui-elements/RedAsterisk'
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs'
import * as R from 'ramda'
import CenterSpinner from '../CenterSpinner/CenterSpinner'
import { formatPhoneNumber } from '../../utils/stringsOperations'

const urlRE =
  /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b\/?/
const emailRE = /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/
//const isNumber = x => x !== '' && !Number.isNaN(Number(x))
//const allNumbers = s => s.length > 0 && s.split('').every(isNumber)
const isPhone = s => s.length >= 12

const validateFile = files =>
  files &&
  files.length === 1 &&
  files[0].type.startsWith('image/') &&
  files[0].size < 1024 * 1024

const isDefined = x => !(R.isNil(x) || R.isEmpty(x))

const isFinite = x => !(R.isNil(x) || R.max(Number(x), 0) === 0)

const StyledForm = styled(Form)`
  margin-bottom: 2rem;
`

const ControlsWrapper = styled.div`
  max-width: 1200px;
`

// Round frame
const LogoWrapper = styled.div`
  position: relative;
  width: 15rem;
  height: 15rem;
  border-radius: 100%;
  border: 1px solid #a6b3be;
  margin-bottom: 5px;
  & > button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: none;
    & .uploadIcon {
      transition: all 200ms ease;
      opacity: 0.7;
    }
    & .uploadIcon:hover {
      opacity: 1;
    }
  }
  & img.company-logo {
    width: 15rem;
    height: 15rem;
    border-radius: 100%;
  }
`

//Square frame
const LogoWrapper2 = styled.div`
  position: relative;
  width: 15rem;
  height: 15rem;
  border: 1px solid #a6b3be;
  margin-bottom: 5px;
  & > button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border: none;
    & .uploadIcon {
      transition: all 200ms ease;
      opacity: 0.7;
    }
    & .uploadIcon:hover {
      opacity: 1;
    }
  }
  & img.company-logo {
    width: 15rem;
    height: 15rem;
  }
`

const FormSectionHeading = styled.div`
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 120%;
`

const PlusCircleButton = styled(BsPlusCircle)`
  color: #06988f;
  height: 1.5rem;
  width: 1.5rem;
  left: 0px;
  top: 0px;
  border-radius: 0rem;
  margin-top: 2.5rem;
`

const DashCircleButton = styled(BsDashCircle)`
  color: #06988f;
  height: 1.5rem;
  width: 1.5rem;
  left: 0px;
  top: 0px;
  border-radius: 0rem;
  margin-top: 2.5rem;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'default')};
`

const UploadButton = ({
  inputName,
  accept,
  onChange,
  buttonProps = {},
  children,
}) => {
  const inputRef = useRef(null)

  const clickHandler = () => {
    inputRef.current?.click()
  }

  const fileChangeHandler = () => {
    onChange(inputRef.current.files)
  }

  return (
    <>
      <input
        ref={inputRef}
        type="file"
        name={inputName}
        accept={accept}
        className="d-none"
        onChange={fileChangeHandler}
      />
      <button {...buttonProps} onClick={clickHandler}>
        {children}
      </button>
    </>
  )
}
UploadButton.propTypes = {
  inputName: PropTypes.string,
  accept: PropTypes.string,
  buttonProps: PropTypes.object,
  onChange: PropTypes.func,
  children: PropTypes.node,
}

const LogoInput = ({ onLoadFile, logo, Wrapper }) => {
  const [imageSrc, setImageSrc] = useState(imagePlaceholder)

  const changeHandler = fileList => {
    const isValid = validateFile(fileList)
    // console.log(fileList, isValid)
    if (isValid) {
      onLoadFile(fileList[0])
      const reader = new FileReader()
      reader.addEventListener('load', e => {
        setImageSrc(e.target.result)
      })
      reader.readAsDataURL(fileList[0])
    } else {
      console.error('Invalid logo image')
    }
  }
  return (
    <Wrapper>
      {imageSrc == imagePlaceholder ? (
        logo != undefined ? (
          <img src={logo} className="company-logo" />
        ) : (
          <img src={imageSrc} className="company-logo" />
        )
      ) : (
        <img src={imageSrc} className="company-logo" />
      )}
      <UploadButton
        inputName="files"
        accept="image/*"
        buttonProps={{
          type: 'button',
          name: 'uploadLogoButton',
          value: 'uploadLogoButton',
        }}
        onChange={changeHandler}
      >
        <img src={uploadIcon} className="uploadIcon" />
      </UploadButton>
    </Wrapper>
  )
}

LogoInput.propTypes = {
  onLoadFile: PropTypes.func,
  logo: PropTypes.string,
  Wrapper: PropTypes.object,
}

const InputGroup = ({
  label,
  controlName,
  controlType,
  value,
  onChange,
  required = false,
  hideLabel = false,
  maxLength,
  disabled,
}) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor="masterEmail">
        {!hideLabel && (
          <>
            {label}
            {required ? <RedAsterisk /> : <></>}
          </>
        )}
      </Form.Label>
      <Form.Control
        name={controlName}
        type={controlType}
        value={value}
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
      />
    </Form.Group>
  )
}
InputGroup.propTypes = {
  controlName: PropTypes.string,
  controlType: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  hideLabel: PropTypes.bool,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
}

const defaultFormData = {
  organizationName: '',
  organizationUrl: '',
  organizationLogoFile: '',
  organizationEventLogoFile: '',
  organizationLogoFilename: '',
  organizationEventLogoFilename: '',
  organizationLinkedin: '',
  organizationRouteDonationLink: '',
  organizationEmail: '',
  organizationEmail2: '',
  organizationEmailType: '',
  organizationEmailProvider: '',
  organizationPhone: '',
  organizationPhone2: '',
  organizationPhoneType: '',
  organizationCountry: '',
  organizationState: '',
  organizationCity: '',
  organizationZipcode: '',
  organizationAddress: '',
  organizationAddress2: '',
  organizationAbout: '',
  ownerFirstName: '',
  ownerLastName: '',
  ownerEmail: '',
  ownerEmail2: '',
  ownerEmailType: '',
  ownerPhone: '',
  ownerPhone2: '',
  ownerPhoneType: '',
  fundraisingEndDate: '',
  fundraisingStartDate: '',
  fundraisingGoal: '',
  maxOutstandingPledge: '',
  zoomAccountId: '',
  zoomClientId: '',
  zoomClientSecret: '',
  zoomSDKClientId: '',
  zoomSDKClientSecret: '',
}

const OrganizationForm = ({ onSubmit, onCancel, organization, isLoading }) => {
  //React will mutate the object passed to the useState hook. If that happens,
  //the next organization created will have the bundler levels form the previous one
  const [formData, setFormData] = useState({ ...defaultFormData })
  const [createDisabled, setCreateDisabled] = useState(true)
  const formRef = useRef(null)
  const [isEdit, setIsEdit] = useState(false)
  const [isSaveReady, setIsSaveReady] = useState(true)
  const [isSecEmail, setIsSecEmail] = useState(false)
  const [isSecPhone, setIsSecPhone] = useState(false)
  const [isSecOwnerEmail, setIsSecOwnerEmail] = useState(false)
  const [isSecOwnerPhone, setIsSecOwnerPhone] = useState(false)
  const [numLevels, setNumLevels] = useState(0)

  const hasValidFormData = data => {
    const hasRequired =
      data.organizationName !== '' &&
      data.organizationEmail !== '' &&
      (data.organizationLogoFile !== '' || isEdit) &&
      (data.organizationEventLogoFile !== '' || isEdit) &&
      data.organizationEmailProvider !== '' &&
      data.organizationPhone !== '' &&
      data.ownerFirstName !== '' &&
      data.ownerLastName !== '' &&
      data.ownerEmail !== '' &&
      data.ownerPhone !== '' &&
      data.fundraisingStartDate !== '' &&
      data.fundraisingEndDate !== '' &&
      isFinite(data.fundraisingGoal) &&
      isFinite(data.maxOutstandingPledge) //&&
    //data.organizationLogoUrl !== ''
    const hasValidUrls = urlRE.test(data.organizationUrl)
    const hasValidEmails =
      emailRE.test(data.organizationEmail) && emailRE.test(data.ownerEmail)
    const hasValidPhones =
      isPhone(data.organizationPhone) && isPhone(data.ownerPhone)
    const hasValidLogoFile = data.organizationLogoFile !== '' || isEdit
    const hasValidEventLogoFile =
      data.organizationEventLogoFile !== '' || isEdit

    console.log({
      hasRequired,
      hasValidUrls,
      hasValidEmails,
      hasValidPhones,
      hasValidLogoFile,
      hasValidEventLogoFile,
    })

    const valid =
      hasRequired &&
      hasValidUrls &&
      hasValidEmails &&
      hasValidPhones &&
      hasValidLogoFile &&
      hasValidEventLogoFile

    setIsSaveReady(!!valid)
    return valid
  }

  const setLevelVals = () => {
    const _levels = {}
    let i = 1
    let label_el = `level_name_${i}`
    while (formData[label_el]) {
      const val_el = label_el.replace('name', 'val')
      if (formData[val_el] && isDefined(formData[val_el])) {
        _levels[formData[label_el]] = formData[val_el]
      } else {
        delete _levels[formData[label_el]]
      }
      label_el = label_el.replace(i, ++i)
    }
    return _levels
  }

  const handleInputChange = (controlName, type, maxLength) => event => {
    let value = event.target.value
    if (
      controlName === 'organizationPhone' ||
      controlName === 'organizationPhone2' ||
      controlName === 'ownerPhone' ||
      controlName === 'ownerPhone2'
    ) {
      value = formatPhoneNumber(value)
    } else if (controlName === 'organizationZipcode') {
      value = value.replace(/[^0-9]/g, '')
    } else if (
      controlName === 'ownerFirstName' ||
      controlName === 'ownerLastName'
    ) {
      value = value.replace(/[^a-zA-Z]+/g, '')
    } else if (
      controlName === 'organizationCountry' ||
      controlName === 'organizationState' ||
      controlName === 'organizationCity'
    ) {
      value = value.replace(/[^a-zA-Z\s]+/g, '')
    }
    if (type === 'number' && maxLength && value) {
      value = Math.max(0, parseInt(value)).toString().slice(0, maxLength)
    }
    setFormData(data => ({
      ...data,
      [controlName]: value,
    }))
    console.log(formData)
  }

  const handleOnLoadLogoFile = file => {
    setFormData(data => ({
      ...data,
      organizationLogoFile: file,
      organizationLogoFilename: file.name,
    }))
  }

  const handleOnLoadEventLogoFile = file => {
    setFormData(data => ({
      ...data,
      organizationEventLogoFile: file,
      organizationEventLogoFilename: file.name,
    }))
  }

  const handleSubmit = e => {
    e.preventDefault()
    e.stopPropagation()
    const data = new FormData(formRef.current)
    data.append(
      'organizationEmailProvider',
      formData?.organizationEmailProvider
    )
    data.append('organizationEmailType', formData?.organizationEmailType)
    data.append('organizationPhoneType', formData?.organizationPhoneType)
    if (organization && Object.keys(organization).length !== 0) {
      data.append('organizationId', organization?._id)
      data.append('ownerId', organization?.ownerId)
      data.append('ownerContactId', organization?.contact?._id)
      data.append('organizationEmail', formData?.organizationEmail)
      data.append('ownerEmail', formData?.ownerEmail)
      data.append('ownerPhone', formData?.ownerPhone)
      data.append('ownerFirstName', formData?.ownerFirstName)
      data.append('ownerLastName', formData?.ownerLastName)
    }
    !isSecEmail && data.append('organizationEmail2', '')
    !isSecPhone && data.append('organizationPhone2', '')
    !isSecOwnerEmail && data.append('ownerEmail2', '')
    !isSecOwnerPhone && data.append('ownerPhone2', '')
    data.delete('files')
    //We must send two files, in a set order, or the server will not know which file is
    //for which logo if only one is updated
    if (formData.organizationLogoFile) {
      data.append('organizationLogoFilename', formData.organizationLogoFilename)
      data.append(
        'files',
        formData.organizationLogoFile,
        formData.organizationLogoFilename
      )
    }
    if (formData.organizationEventLogoFile) {
      data.append(
        'organizationEventLogoFilename',
        formData.organizationEventLogoFilename
      )
      data.append(
        'files',
        formData.organizationEventLogoFile,
        formData.organizationEventLogoFilename
      )
    }
    data.append('levels', JSON.stringify(setLevelVals()))
    onSubmit(data)
  }

  const setLevelFields = levels => {
    Object.entries(levels).forEach((level, idx) => {
      formData[`level_name_${idx + 1}`] = level[0]
      formData[`level_val_${idx + 1}`] = level[1]
    })
  }
  isEdit
  useEffect(() => {
    if (organization && Object.keys(organization).length !== 0) {
      setIsEdit(true)
      let orgFormat = {
        organizationName: organization?.name || '',
        organizationUrl: organization?.url || '',
        organizationLogoUrl: organization?.organizationLogoUrl || '',
        organizationEventLogoUrl: organization?.organizationEventLogoUrl || '',
        organizationLinkedin: organization?.linkedInUrl || '',
        organizationRouteDonationLink: organization?.routeDonationLink || '',
        organizationEmail: organization?.emailAccount?.email || '',
        organizationEmail2: organization?.secondaryEmail || '',
        organizationEmailType: organization?.emailAccount?.emailType || '',
        organizationEmailProvider: organization?.emailAccount?.provider || '',
        organizationPhone: organization?.phone || '',
        organizationPhone2: organization?.secondaryPhone || '',
        organizationPhoneType: organization?.phoneType || '',
        organizationCountry: organization?.country || '',
        organizationState: organization?.state || '',
        organizationCity: organization?.city || '',
        organizationZipcode: organization?.zipCode || '',
        organizationAddress: organization?.address || '',
        organizationAddress2: organization?.address2 || '',
        organizationAbout: organization?.about || '',
        ownerFirstName: organization?.owner?.name || '',
        ownerLastName: organization?.owner?.lastName || '',
        ownerEmail: organization?.owner?.email || '',
        ownerEmail2: organization?.contact?.secondaryEmail || '',
        ownerPhone: organization?.contact?.primaryPhoneNumber || '',
        ownerPhone2: organization?.contact?.secondaryPhoneNumber || '',
        fundraisingGoal: organization.fundraisingGoal || '',
        fundraisingStartDate: organization.fundraisingStartDate || '',
        fundraisingEndDate: organization.fundraisingEndDate || '',
        maxOutstandingPledge: organization.maxOutstandingPledge || '',
        levels: organization.levels || {},
        zoomAccountId: organization.zoomAccountId || '',
        zoomClientId: organization.zoomClientId || '',
        zoomClientSecret: organization.zoomClientSecret || '',
        zoomSDKClientId: organization.zoomSDKClientId || '',
        zoomSDKClientSecret: organization.zoomSDKClientSecret || '',
      }
      if (organization?.secondaryEmail != '') {
        setIsSecEmail(true)
      }
      if (organization?.secondaryPhone != '') {
        setIsSecPhone(true)
      }
      if (organization?.contact?.secondaryEmail != '') {
        setIsSecOwnerEmail(true)
      }
      if (organization?.contact?.secondaryPhoneNumber != '') {
        setIsSecOwnerPhone(true)
      }
      setFormData(prevState => {
        return { ...prevState, ...orgFormat }
      })
      setNumLevels(Object.keys(organization.levels || {}).length)
      setLevelFields(organization.levels || {})
    }
  }, [organization])

  useEffect(() => {
    setCreateDisabled(!hasValidFormData(formData))
  }, [formData])

  const updateSecBool = (label, handleUpdate) => {
    handleUpdate()
    setFormData(data => ({
      ...data,
      [label]: '',
    }))
  }

  return (
    <StyledForm
      ref={formRef}
      formEncType="multipart/form-data"
      onSubmit={handleSubmit}
    >
      <Row>
        <Col>
          <div className="d-flex flex-row flex-nowrap justify-content-end">
            {!isLoading ? (
              <>
                <Button variant="gray" onClick={onCancel}>
                  Cancel
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant="secondary"
                  type="submit"
                  disabled={createDisabled}
                  name="formSubmit"
                  value="formSubmit"
                >
                  {!isEdit
                    ? `Create Organization`
                    : isSaveReady
                    ? `Save Organization`
                    : `Edit Organization`}
                </Button>
              </>
            ) : (
              <CenterSpinner />
            )}
          </div>
        </Col>
      </Row>

      <ControlsWrapper>
        <Row className="pb-2">
          <Col sm="4">
            <LogoInput
              onLoadFile={handleOnLoadLogoFile}
              logo={formData.organizationLogoUrl}
              Wrapper={LogoWrapper}
            />
            Organization Logo
            <RedAsterisk />
          </Col>
          <Col sm={4}>
            <InputGroup
              controlName="organizationName"
              controlType="text"
              label="Name"
              value={formData.organizationName}
              onChange={handleInputChange('organizationName')}
              required
            />
            <InputGroup
              controlName="organizationUrl"
              controlType="text"
              label="Web Page URL"
              value={formData.organizationUrl}
              onChange={handleInputChange('organizationUrl')}
              required
            />
            {/*<InputGroup
              controlName="organizationLogoUrl"
              controlType="text"
              label="Logo image URL"
              value={formData.organizationLogoUrl}
              onChange={handleInputChange('organizationLogoUrl')}
              required
            />*/}
            <InputGroup
              controlName="organizationRouteDonationLink"
              controlType="text"
              label="Route Donation Link"
              value={formData.organizationRouteDonationLink}
              onChange={handleInputChange('organizationRouteDonationLink')}
            />
          </Col>
          <Col sm={{ offset: 1, size: 4 }}>
            <LogoInput
              onLoadFile={handleOnLoadEventLogoFile}
              logo={formData.organizationEventLogoUrl}
              Wrapper={LogoWrapper2}
            />
            Event Logo
            <RedAsterisk />
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <InputGroup
              controlName="organizationLinkedin"
              controlType="text"
              label="LinkedIn Profile"
              value={formData.organizationLinkedin}
              onChange={handleInputChange('organizationLinkedin')}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="8">
            <InputGroup
              controlName="organizationEmail"
              controlType="text"
              label="Email"
              value={formData.organizationEmail}
              onChange={handleInputChange('organizationEmail')}
              required
              disabled={isEdit}
            />
          </Col>
          <Col sm="3">
            <Form.Label>Labeled as</Form.Label>
            <Form.Select
              value={formData.organizationEmailType}
              onChange={handleInputChange('organizationEmailType')}
            >
              <option value="Primary">Primary</option>
            </Form.Select>
          </Col>
          <Col sm="1">
            {!isSecEmail ? (
              <PlusCircleButton
                onClick={() =>
                  updateSecBool('organizationEmail2', () =>
                    setIsSecEmail(!isSecEmail)
                  )
                }
              />
            ) : (
              <DashCircleButton
                onClick={() =>
                  updateSecBool('organizationEmail2', () =>
                    setIsSecEmail(!isSecEmail)
                  )
                }
              />
            )}
          </Col>
        </Row>
        {isSecEmail && (
          <Row>
            <Col sm="8">
              <InputGroup
                controlName="organizationEmail2"
                controlType="text"
                label="Secondary Email"
                value={formData.organizationEmail2}
                onChange={handleInputChange('organizationEmail2')}
              />
            </Col>
            <Col sm="3">
              <Form.Label>Labeled as</Form.Label>
              <Form.Select>
                <option value="Secondary">Secondary</option>
              </Form.Select>
            </Col>
            <Col sm="1" />
          </Row>
        )}
        <Row>
          <Col sm="12">
            <Form.Group className="mb-3">
              <Form.Label>
                Organization Email Provider <RedAsterisk />
              </Form.Label>
              <Form.Select
                value={formData.organizationEmailProvider}
                onChange={handleInputChange('organizationEmailProvider')}
              >
                <option value="">Select your Provider</option>
                <option value="Google">Google</option>
                <option value="Microsoft">Microsoft</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col sm="8">
            <InputGroup
              controlName="organizationPhone"
              controlType="text"
              label="Phone"
              value={formData.organizationPhone}
              onChange={handleInputChange('organizationPhone')}
              required
            />
          </Col>
          <Col sm="3">
            <Form.Label>Labeled as</Form.Label>
            <Form.Select
              value={formData.organizationPhoneType}
              onChange={handleInputChange('organizationPhoneType')}
            >
              <option value="Primary">Primary</option>
            </Form.Select>
          </Col>
          <Col sm="1">
            {!isSecPhone ? (
              <PlusCircleButton
                onClick={() =>
                  updateSecBool('organizationPhone2', () =>
                    setIsSecPhone(!isSecPhone)
                  )
                }
              />
            ) : (
              <DashCircleButton
                onClick={() =>
                  updateSecBool('organizationPhone2', () =>
                    setIsSecPhone(!isSecPhone)
                  )
                }
              />
            )}
          </Col>
        </Row>
        {isSecPhone && (
          <Row>
            <Col sm="8">
              <InputGroup
                controlName="organizationPhone2"
                controlType="text"
                label="Secondary Phone"
                value={formData.organizationPhone2}
                onChange={handleInputChange('organizationPhone2')}
              />
            </Col>
            <Col sm="3">
              <Form.Label>Labeled as</Form.Label>
              <Form.Select>
                <option value="Secondary">Secondary</option>
              </Form.Select>
            </Col>
            <Col sm="1" />
          </Row>
        )}

        <Row>
          <Col sm={6}>
            <InputGroup
              controlName="organizationCountry"
              controlType="text"
              label="Country"
              value={formData.organizationCountry}
              onChange={handleInputChange('organizationCountry')}
            />
          </Col>
          <Col sm={6}>
            <InputGroup
              controlName="organizationState"
              controlType="text"
              label="State"
              value={formData.organizationState}
              onChange={handleInputChange('organizationState')}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={6}>
            <InputGroup
              controlName="organizationCity"
              controlType="text"
              label="City"
              value={formData.organizationCity}
              onChange={handleInputChange('organizationCity')}
            />
          </Col>
          <Col sm={6}>
            <InputGroup
              controlName="organizationZipcode"
              maxLength={5}
              controlType="text"
              label="Zipcode"
              value={formData.organizationZipcode}
              onChange={handleInputChange('organizationZipcode')}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <InputGroup
              controlName="organizationAddress"
              controlType="text"
              label="Address"
              value={formData.organizationAddress}
              onChange={handleInputChange('organizationAddress')}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <InputGroup
              controlName="organizationAddress2"
              controlType="text"
              label="Address Line 2"
              value={formData.organizationAddress2}
              onChange={handleInputChange('organizationAddress2')}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <FormSectionHeading>Fundraising Profile</FormSectionHeading>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <InputGroup
              controlName="fundraisingStartDate"
              controlType="date"
              label="Fundraising Start Date"
              value={formData.fundraisingStartDate}
              required
              onChange={handleInputChange('fundraisingStartDate')}
            />
          </Col>
          <Col sm={6}>
            <InputGroup
              controlName="fundraisingEndDate"
              controlType="date"
              label="Fundraising End Date"
              value={formData.fundraisingEndDate}
              required
              onChange={handleInputChange('fundraisingEndDate')}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <InputGroup
              controlName="fundraisingGoal"
              onKeyPress={e => {
                formData.fundingGoal.length === 8 && e.preventDefault()
              }}
              controlType="number"
              label="Fundraising Goal"
              value={formData.fundraisingGoal}
              required
              onChange={handleInputChange('fundraisingGoal', 'number', 8)}
            />
          </Col>
          <Col sm={6}>
            <InputGroup
              controlName="maxOutstandingPledge"
              controlType="number"
              label="Max Pledge Receivables"
              value={formData.maxOutstandingPledge}
              required
              onChange={handleInputChange('maxOutstandingPledge', 'number', 6)}
            />
          </Col>
        </Row>
        {numLevels === 0 && (
          <Row>
            <Col sm={12}>
              <Button
                variant="secondary"
                name="addEmptyLevel"
                onClick={() => setNumLevels(1)}
              >
                Add Bundler Levels
              </Button>
            </Col>
          </Row>
        )}
        {Array(numLevels)
          .fill()
          .map((_, i) => (
            <Row key={i + 1}>
              <Col sm={5}>
                <InputGroup
                  controlName={`level_name_${i + 1}`}
                  maxLength={36}
                  controlType="text"
                  label={`Bundler Level #${i + 1}`}
                  value={formData[`level_name_${i + 1}`]}
                  onChange={handleInputChange(`level_name_${i + 1}`)}
                />
              </Col>
              <Col sm={5}>
                <InputGroup
                  controlName={`level_val_${i + 1}`}
                  controlType="text"
                  label="Ammount"
                  value={formData[`level_val_${i + 1}`]}
                  onChange={handleInputChange(
                    `level_val_${i + 1}`,
                    'number',
                    7
                  )}
                />
              </Col>
              <Col>
                {i == 0 && (
                  <PlusCircleButton
                    className="ms-1 me-2"
                    onClick={() => {
                      formData[`level_name_${numLevels + 1}`] = ''
                      formData[`level_val_${numLevels + 1}`] = ''
                      setNumLevels(numLevels + 1)
                    }}
                  />
                )}
                {i == 0 && (
                  <DashCircleButton
                    className="ms-3"
                    onClick={() => {
                      delete formData[`level_name_${numLevels}`]
                      delete formData[`level_val_${numLevels}`]
                      setNumLevels(numLevels - 1)
                    }}
                  />
                )}
              </Col>
            </Row>
          ))}

        <Row>
          <Col sm={12}>
            <FormSectionHeading>About the Company</FormSectionHeading>
            <Form.Group className="mb-3">
              <Form.Label></Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                autoComplete="off"
                name="organizationAbout"
                style={{ resize: 'none' }}
                value={formData?.organizationAbout}
                onChange={handleInputChange('organizationAbout')}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <FormSectionHeading>Zoom Account Info</FormSectionHeading>
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <InputGroup
              controlName="zoomClientId"
              controlType="text"
              label="Client ID"
              value={formData.zoomClientId}
              onChange={handleInputChange('zoomClientId')}
            />
          </Col>
          <Col sm="4">
            <InputGroup
              controlName="zoomClientSecret"
              controlType="text"
              label="Client Secret"
              value={formData.zoomClientSecret}
              onChange={handleInputChange('zoomClientSecret')}
            />
          </Col>
          <Col sm="4">
            <InputGroup
              controlName="zoomAccountId"
              controlType="text"
              label="Account ID"
              value={formData.zoomAccountId}
              onChange={handleInputChange('zoomAccountId')}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="4">
            <InputGroup
              controlName="zoomSDKClientId"
              controlType="text"
              label="SDK Client ID"
              value={formData.zoomSDKClientId}
              onChange={handleInputChange('zoomSDKClientId')}
            />
          </Col>
          <Col sm="4">
            <InputGroup
              controlName="zoomSDKClientSecret"
              controlType="text"
              label="SDK Client Secret"
              value={formData.zoomSDKClientSecret}
              onChange={handleInputChange('zoomSDKClientSecret')}
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <FormSectionHeading>Principal details</FormSectionHeading>
          </Col>
        </Row>

        <Row>
          <Col sm="6">
            <InputGroup
              controlName="ownerFirstName"
              controlType="text"
              label="First Name"
              value={formData.ownerFirstName}
              onChange={handleInputChange('ownerFirstName')}
              required
              disabled={isEdit}
            />
          </Col>
          <Col sm="6">
            <InputGroup
              controlName="ownerLastName"
              controlType="text"
              label="Last Name"
              value={formData.ownerLastName}
              onChange={handleInputChange('ownerLastName')}
              required
              disabled={isEdit}
            />
          </Col>
        </Row>

        <Row>
          <Col sm="8">
            <InputGroup
              controlName="ownerEmail"
              controlType="text"
              label="Email"
              value={formData.ownerEmail}
              onChange={handleInputChange('ownerEmail')}
              disabled={isEdit}
              required
            />
          </Col>
          <Col sm="3">
            <Form.Label>Labeled as</Form.Label>
            <Form.Select
              value={formData.ownerEmailType}
              onChange={handleInputChange('ownerEmailType')}
              disabled={isEdit}
            >
              <option value="Primary">Primary</option>
            </Form.Select>
          </Col>
          <Col sm="1">
            {!isSecOwnerEmail ? (
              <PlusCircleButton
                onClick={() =>
                  updateSecBool('ownerEmail2', () =>
                    setIsSecOwnerEmail(!isSecOwnerEmail)
                  )
                }
              />
            ) : (
              <DashCircleButton
                onClick={() =>
                  updateSecBool('ownerEmail2', () =>
                    setIsSecOwnerEmail(!isSecOwnerEmail)
                  )
                }
              />
            )}
          </Col>
        </Row>
        {isSecOwnerEmail && (
          <Row>
            <Col sm="8">
              <InputGroup
                controlName="ownerEmail2"
                controlType="text"
                label="Secondary Email"
                value={formData.ownerEmail2}
                onChange={handleInputChange('ownerEmail2')}
                disabled={isEdit}
              />
            </Col>
            <Col sm="3">
              <Form.Label>Labeled as</Form.Label>
              <Form.Select>
                <option value="Secondary">Secondary</option>
              </Form.Select>
            </Col>
            <Col sm="1" />
          </Row>
        )}
        <Row>
          <Col sm="8">
            <InputGroup
              controlName="ownerPhone"
              controlType="text"
              label="Phone"
              value={formData.ownerPhone}
              onChange={handleInputChange('ownerPhone')}
              required
              disabled={isEdit}
            />
          </Col>
          <Col sm="3">
            <Form.Label>Labeled as</Form.Label>
            <Form.Select
              value={formData.ownerPhoneType}
              onChange={handleInputChange('ownerPhoneType')}
              disabled={isEdit}
            >
              <option value="Primary">Primary</option>
            </Form.Select>
          </Col>
          <Col sm="1">
            {!isSecOwnerPhone ? (
              <PlusCircleButton
                onClick={() =>
                  updateSecBool('ownerPhone2', () =>
                    setIsSecOwnerPhone(!isSecOwnerPhone)
                  )
                }
              />
            ) : (
              <DashCircleButton
                onClick={() =>
                  updateSecBool('ownerPhone2', () =>
                    setIsSecOwnerPhone(!isSecOwnerPhone)
                  )
                }
              />
            )}
          </Col>
        </Row>
        {isSecOwnerPhone && (
          <Row>
            <Col sm="8">
              <InputGroup
                controlName="ownerPhone2"
                controlType="text"
                label="Secondary Phone"
                value={formData.ownerPhone2}
                onChange={handleInputChange('ownerPhone2')}
                disabled={isEdit}
              />
            </Col>
            <Col sm="3">
              <Form.Label>Labeled as</Form.Label>
              <Form.Select>
                disabled={isEdit}
                <option value="Secondary">Secondary</option>
              </Form.Select>
            </Col>
            <Col sm="1" />
          </Row>
        )}
      </ControlsWrapper>
    </StyledForm>
  )
}

OrganizationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  organization: PropTypes.object,
  isLoading: PropTypes.bool,
}

export default OrganizationForm
