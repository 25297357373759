import { prop } from 'ramda'
import types from '../types/types'

const initialState = {
  isLoading: false,
  currentPage: {
    title: null,
    breadcrumbs: [],
  },
}

export const uiReducer = (state = initialState, action) => {
  const currentPage = prop('currentPage', state)

  switch (action.type) {
    case types.UI_START_LOADING:
      return {
        ...state,
        isLoading: true,
      }
    case types.UI_FINISH_LOADING:
      return {
        ...state,
        isLoading: false,
      }
    case types.UI_SET_PAGE_TITLE:
      return {
        ...state,
        currentPage: {
          ...currentPage,
          title: action.payload,
        },
      }
    case types.UI_SET_PAGE_BREADCRUMBS:
      return {
        ...state,
        currentPage: {
          ...currentPage,
          breadcrumbs: action.payload ?? [],
        },
      }
    default:
      return state
  }
}

export default uiReducer
