import React, { FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { isEmpty } from 'ramda'

import {
  ColumnDef,
  PaginationState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import Container from 'react-bootstrap/Container'
import Card from '../ui-elements/Card'
import EventsApi from '../../api/event'
import { filterDonationsData } from '../../api/donations_data'
import { formatValue } from '../ui-elements/Money'
import moment from 'moment'
import { DATEFORMAT } from '../../utils/timestamp'

import { IEvent, IDonationData } from '../../api/interfaces'
import { EventsCardProps } from './type'

import { FundraisingProgressBar } from '../ui-elements/FundraisingProgressBar'

const Styles = styled.div`
  padding: 1rem;
  width: 100%;

  table > thead > tr > th {
    background-color: white;
  }

  table {
    display: inline-block;
    border-spacing: 0;
    thead {
      background-color: white;
      font-weight: bold;
    }

    td {
      text-indent: 10px;
      white-space: nowrap;
      overflow-x: hidden;
      overflow-y: hidden;
    }
  }
`
const EventsCard: FC<EventsCardProps> = ({ style, userId }) => {
  const [data, setData] = useState<IEvent[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [donations, setDonations] = useState<IDonationData[]>([])

  const getDonationTotal = (event_id: string) => {
    let total = 0
    donations
      .filter(d => d.event_id?.toString() === event_id)
      .forEach(d => (total += d.donation_total))
    return total
  }

  const loadEvents = () => {
    setIsLoading(true)

    EventsApi.getUpcomingEvents(
      sessionStorage.getItem('Org-Session') || '',
      5,
      userId
    )
      .then(events => {
        setData(events)
        filterDonationsData(undefined, 0, 0, {
          event_id: { $in: events.map((evt: IEvent) => evt._id) },
        })
          .then(donations => {
            setDonations(donations)
            setIsLoading(false)
          })
          .catch(err => {
            console.log(`Error loading donations: ${err}`)
            setIsLoading(false)
          })
      })
      .catch(err => {
        console.log(`Error loading events: ${err}`)
        setIsLoading(false)
      })
  }

  const columns = React.useMemo<ColumnDef<IEvent>[]>(
    () => [
      {
        accessorFn: row => moment(row.day).format(DATEFORMAT.DATE_FORMAT),
        id: 'day',
        header: () => 'Date',
        size: 100,
      },
      {
        accessorFn: row => `${row.city}, ${row.state}`,
        id: 'location',
        header: () => <span>Location</span>,
        size: 200,
      },
      {
        accessorFn: row =>
          `${formatValue(
            getDonationTotal(row._id.toString()),
            0,
            0,
            true
          )} / ${formatValue(row.financialGoal, 0, 0, true)}`,
        id: 'amountRaised',
        header: () => 'Amount Raised',
        size: 150,
      },
      {
        header: 'Status',
        id: 'status',
        cell: info => {
          const val = Math.round(
            (getDonationTotal(info.row.original._id.toString()) /
              info.row.original.financialGoal) *
              100
          )
          /* Ensure right-alignment of value regardless of # of digits*/
          return (
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <span>{`${val}%`}</span>
              <FundraisingProgressBar margin={5} value={val} />
            </span>
          )
        },
        size: 150,
      },
    ],
    [donations, data]
  )

  useEffect(() => {
    loadEvents()
  }, [])

  return (
    <Card title="UPCOMING EVENTS" flag url="/events/all" style={style}>
      <Container fluid>
        <Styles>
          <EventsTable
            data={data}
            columns={columns}
            isLoading={isLoading}
          ></EventsTable>
        </Styles>
        {/* <hr />
        <div>
          <button onClick={() => loadEvents()}>Reload Events</button>
        </div> */}
      </Container>
    </Card>
  )
}

function EventsTable({
  data,
  columns,
  isLoading,
}: {
  data: IEvent[]
  columns: ColumnDef<IEvent>[]
  isLoading: boolean
}) {
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  })

  const table = useReactTable({
    columns,
    data,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    //no need to pass pageCount or rowCount with client-side pagination as it is calculated automatically
    state: {
      pagination,
    },
  })

  return (
    <div className="p-2">
      {!isEmpty(data) && !isLoading ? (
        <table
          {...{
            style: {
              width: table.getTotalSize(),
            },
          }}
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      {...{
                        className: 'th',
                        style: {
                          width: header.getSize(),
                          textAlign: 'center',
                          border: 'solid black 1px',
                        },
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          data-tooltip-id={`table-header-tooltip-${header.id}`}
                        >
                          <div
                            {...{
                              style: {
                                display: 'flex',
                                justifyContent: 'center',
                                cursor: header.column.getCanSort()
                                  ? 'pointer'
                                  : 'none',
                              },
                              onClick: header.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: ' ↑',
                              desc: ' ↓',
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </div>
                      )}
                      <div
                        {...{
                          onDoubleClick: () => header.column.resetSize(),
                          onMouseDown: header.getResizeHandler(),
                          onTouchStart: header.getResizeHandler(),
                          className: `resizer ${
                            header.column.getIsResizing() ? 'isResizing' : ''
                          }`,
                        }}
                      />
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td
                        key={cell.id}
                        style={{
                          width: cell.column.getSize(),
                          border: 'solid black 1px',
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      ) : (
        <div>
          {isLoading === true ? 'Loading Events...' : 'No upcoming events'}
        </div>
      )}
    </div>
  )
}

export default EventsCard
