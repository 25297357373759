import { Tab, Tabs } from 'react-bootstrap'
import { EventKey } from '@restart/ui/types'
import Interaction from './Interactions/Interactions'
import { FC, ReactNode } from 'react'
import { IContact } from '../../../api/interfaces'
import ContactNotes from '../ContactNotes'
import ContactAboutMe from '../ContactAboutMe'
import TabDonations from './Donations/TabDonations'

interface ITab {
  eventKey: EventKey
  title: React.ReactNode
  tabContent: (contact: IContact) => ReactNode
}

const CONFIG_TABS: ITab[] = [
  {
    eventKey: 'interactions',
    title: 'Interactions',
    tabContent: (contact: IContact) => <Interaction contact={contact} />,
  },
  {
    eventKey: 'donations',
    title: 'Donations',
    tabContent: (contact: IContact) => {
      return <TabDonations contact={contact} />
    },
  },
  {
    eventKey: 'notes',
    title: 'Notes',
    tabContent: (contact: IContact) => {
      return <ContactNotes contact={contact} />
    },
  },
  {
    eventKey: 'bio',
    title: 'Bio',
    tabContent: (contact: IContact) => {
      return <ContactAboutMe contact={contact} />
    },
  },
  // {
  //   eventKey: 'notes',
  //   title: 'Notes',
  //   tabContent: <Notes />,
  // },
]

const ContactTabs: FC<{ contact: IContact }> = ({ contact }) => {
  return (
    <Tabs
      defaultActiveKey="interactions"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      {CONFIG_TABS.map(({ eventKey, title, tabContent }) => (
        <Tab
          key={eventKey}
          eventKey={eventKey}
          title={title}
          className="border border-1 rounded border-dark border-opacity-25"
        >
          <>{tabContent(contact)}</>
        </Tab>
      ))}
    </Tabs>
  )
}

export default ContactTabs
