import React from 'react'
import PropTypes from 'prop-types'
import CustomChevronDownDropdown from '../../ui-elements/CustomChevronDownDropdown'
import MenuItem from '../../ui-elements/MenuItem'

const MailReplyForwardOptions = ({ setOptions }) => {
  return (
    <CustomChevronDownDropdown>
      <MenuItem
        handleOnClick={() => {
          setOptions('Reply')
        }}
      >
        Reply
      </MenuItem>

      <MenuItem
        handleOnClick={() => {
          setOptions('Reply All')
        }}
      >
        Reply All
      </MenuItem>
    </CustomChevronDownDropdown>
  )
}

MailReplyForwardOptions.propTypes = {
  setOptions: PropTypes.func,
}

export default MailReplyForwardOptions
