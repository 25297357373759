import React from 'react'
import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { BsChevronDown } from 'react-icons/bs'
import PropTypes from 'prop-types'
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu'

const IconWrapper = styled.div`
  > img,
  svg {
    margin: 0;
    padding: 0;
  }
`

const StyledDropdownMenu = styled(DropdownMenu)`
  max-height: 21.125rem;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #00bdb2;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #0c243c;
  }
`

const CustomToggle = React.forwardRef(function CustomToggle({ onClick }, ref) {
  return (
    <IconWrapper
      href="#"
      ref={ref}
      onClick={e => {
        e.preventDefault()
        onClick(e)
      }}
    >
      <BsChevronDown size={13} />
    </IconWrapper>
  )
})

CustomToggle.propTypes = {
  onClick: PropTypes.func,
}

const CustomChevronDownDropdown = ({ children }) => {
  return (
    <Dropdown style={{ display: 'inline-block', paddingLeft: '0.5rem' }}>
      <Dropdown.Toggle
        as={CustomToggle}
        id={`dropdown-chevron-down-components`}
      />
      <StyledDropdownMenu>{children}</StyledDropdownMenu>
    </Dropdown>
  )
}

CustomChevronDownDropdown.propTypes = {
  children: PropTypes.node,
}

export default CustomChevronDownDropdown
