import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import TierFormModal from './TierFormModal'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ControlsWrapper = styled.div`
  text-align: center;
`

const StyledButton = styled(Button)`
  height: 2.375rem;
  border-radius: 0.25rem;
  padding: 6px 12px 6px 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
`

const AddTierForm = ({ onAddTier }) => {
  const [showModal, setShowModal] = useState(false)
  return (
    <Wrapper>
      <ControlsWrapper>
        <StyledButton
          type="button"
          variant="outline-secondary"
          onClick={() => setShowModal(true)}
          title="Create Tier"
        >
          Create Tier
        </StyledButton>
        <TierFormModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onAdd={onAddTier}
        />
      </ControlsWrapper>
    </Wrapper>
  )
}

AddTierForm.propTypes = {
  onAddTier: PropTypes.func.isRequired,
}

export default AddTierForm
