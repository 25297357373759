export const optionsContact = [
  {
    label: 'Address',
    value: 'address_1',
  },
  {
    label: `Assistant's Email`,
    value: 'assistant_email',
  },
  {
    label: `Assistant's Name`,
    value: 'assistant_name',
  },
  {
    label: `Assistant's Phone`,
    value: 'assistant_phone_number',
  },
  {
    label: 'Biography',
    value: 'about_this_guy',
  },
  {
    label: 'Birthday',
    value: 'birth_date',
  },
  {
    label: 'City',
    value: 'city',
  },
  {
    label: 'Employer',
    value: 'employer',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Job',
    value: 'title',
  },
  {
    label: 'Last Name',
    value: 'last_name',
  },
  {
    label: 'Name',
    value: 'first_name',
  },
  {
    label: 'Phone',
    value: 'primary_phone_number',
  },
  {
    label: 'Prefix',
    value: 'prefix_name',
  },
  {
    label: 'Secondary Email',
    value: 'secondary_email',
  },
  {
    label: 'Secondary Phone',
    value: 'secondary_phone_number',
  },
  {
    label: 'State',
    value: 'state_abbr',
  },
  {
    label: 'Zip',
    value: 'postal_code',
  },
  {
    label: 'Tags',
    value: 'tags',
  },
]

export const getLabelContactByValue = value => {
  const option = optionsContact.find(option => option?.value === value)
  return option ? option.label : ''
}

export const defaultContactValues = {
  prefix_name: '',
  first_name: '',
  last_name: '',
  title: '',
  employer: '',
  email: '',
  primary_phone_number: '',
  secondary_phone_number: '',
  address_1: '',
  city: '',
  postal_code: '',
  birth_date: '',
  assistant_name: '',
  assistant_email: '',
  assistant_phone_number: '',
  about_this_guy: '',
  level: 'Contact',
  state_abbr: '',
  invite_alias: '',
  linkedin_url: '',
  tags: [],
}
