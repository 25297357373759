import React from 'react'
import { Modal } from 'react-bootstrap'
import PropTypes from 'prop-types'

const EmailSchedulerContactModal = ({ show, onHide, name }) => {
  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Header closeButton>
        <Modal.Title>Email Sent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        The email notification has been sent to <strong>{name}</strong>
      </Modal.Body>
    </Modal>
  )
}

EmailSchedulerContactModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
}

export default EmailSchedulerContactModal
