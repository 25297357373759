import CryptoJS from 'crypto-js'
import constants from '../../config/constants'

const secretPass = constants.SECRET_PASS()

export const encryptData = text =>
  CryptoJS.AES.encrypt(JSON.stringify(text), secretPass).toString()

export const decryptData = text => {
  const bytes = CryptoJS.AES.decrypt(text, secretPass)
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
}
