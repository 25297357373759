import PropTypes from 'prop-types'
import SortIconAction from '../ui-elements/SortIconAction'
import { useSelector } from 'react-redux'
import { getUserRole } from '../../utils/state/selectors'
import { checkUserRole } from '../../utils/state/roleTests'
import styled from 'styled-components'

const ContextHeader = styled.th`
  width: 33px;
`

const TableHeadings = ({ sortCol, setSortCol, sortDir, setSortDir }) => {
  const userRole = useSelector(getUserRole)

  const sortState = col => (sortCol !== col ? 0 : sortDir === 'asc' ? 1 : 2)
  const funcSortState = row => n => {
    if (n === 0) setSortCol('none')
    else setSortCol(row)
    setSortDir(n > 1 ? 'desc' : 'asc')
  }
  const refresh = () => setSortCol('none')

  return (
    <thead>
      <tr>
        <th>
          Name&nbsp;
          <SortIconAction
            stateValue={sortState('name')}
            setFunctState={funcSortState('name')}
            refresh={refresh}
          />
        </th>
        <th>
          Location&nbsp;
          <SortIconAction
            stateValue={sortState('location')}
            setFunctState={funcSortState('location')}
            refresh={refresh}
          />
        </th>
        <th>
          Date&nbsp;
          <SortIconAction
            stateValue={sortState('date')}
            setFunctState={funcSortState('date')}
            refresh={refresh}
          />
        </th>
        {checkUserRole(userRole) ? (
          <>
            <th>
              Raised funds&nbsp;
              <SortIconAction
                stateValue={sortState('raised')}
                setFunctState={funcSortState('raised')}
                refresh={refresh}
              />
            </th>
            <th>
              Tasks&nbsp;
              <SortIconAction
                stateValue={sortState('tasks')}
                setFunctState={funcSortState('tasks')}
                refresh={refresh}
              />
            </th>
            <th>
              Attendees&nbsp;
              <SortIconAction
                stateValue={sortState('atendees')}
                setFunctState={funcSortState('atendees')}
                refresh={refresh}
              />
            </th>
            <th>&nbsp;</th>
          </>
        ) : userRole === 'bundler' ? (
          <>
            <ContextHeader>&nbsp;</ContextHeader>
          </>
        ) : null}
      </tr>
    </thead>
  )
}

TableHeadings.propTypes = {
  sortCol: PropTypes.string,
  setSortCol: PropTypes.func,
  sortDir: PropTypes.string,
  setSortDir: PropTypes.func,
}

export default TableHeadings
