import { FC, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { IContact, IDonation2 } from '../../../../api/interfaces'
import { getUserOrganizationId } from '../../../../utils/state/selectors'
import BTable from '../../../BTable/BTable'
import { BTableHeaders } from '../../../BTable/types'
import { initStore } from '../../../../redux/store'
import { DonationRow2 } from '../../../ReportPage/DonationRow/DonationRow'
import { DATEFORMAT } from '../../../../utils/timestamp'
import { addToast, removeToast } from '../../../../redux/toasts/toasts'
import TabDonationItem from '../../TabDonationItem/TabDonationItem'
import { Button, Modal } from 'react-bootstrap'
import { filterDonationsDataAggr } from '../../../../api/donations_data'
import styled from 'styled-components'
import { formatValue } from '../../../ui-elements/Money'

const HEADERS: BTableHeaders<IDonation2>[] = [
  { title: 'Date', key: 'donation_date', disabledSort: false },
  // { title: 'Committee', key: 'committee', disabledSort: true },
  { title: 'Amount', key: 'donation_total', disabledSort: false },
]

export const TableWrapper = styled.div`
  padding: 1rem 1.3125rem;
  overflow-x: auto;
  > .container-fluid {
    margin-bottom: 2rem;
  }
  display: flex;
  flex-direction: column;
  gap: 3;
  justify-content: flex-start;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3;
`

const Heading = styled.div`
  text-indent: 30px;
  font-weight: 1000;
`
const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid black;
`

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`

const TotalsEntry = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin-top: 6px;
`

const LeftDiv = styled.div`
  width: 100%;
  text-indent: 10px;
`

const RightDiv = styled.div`
  width: 100%;
  text-indent: 10px;
  font-weight: 800;
`
interface DonationsProps {
  contact: IContact
}
const TabDonations: FC<DonationsProps> = ({ contact }) => {
  const organizationId = useSelector(getUserOrganizationId)
  const [donations, setDonation] = useState<IDonation2[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const handleCloseDialog = (dialogToastId: string) => {
    initStore.dispatch(removeToast({ id: dialogToastId }))
  }

  const handlerClickDonationItem = (donation: IDonation2) => {
    handleCloseDialog('dialogToastId')
    initStore.dispatch(
      addToast({
        id: 'dialogToastId',
        persist: true,
        position: 'middle-center',
        title: 'Donation Information',
        message: <TabDonationItem donation={donation} />,
        buttons: (
          <Modal.Footer>
            <div className="d-flex gap-2">
              <Button
                className="m-2"
                variant="outline-primary"
                onClick={() => handleCloseDialog('dialogToastId')}
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        ),
      })
    )
  }

  const loadAllDonations = async () => {
    const aggregateQueryDD = [
      {
        $match: {
          contact_person_id: contact?.contact_person_id || 'NO_MATCH',
          organizationId,
          donation_total: { $ne: 0 },
        },
      },
      {
        $project: {
          donation_total: 1,
          donation_date: 1,
        },
      },
    ]
    let fullDonations: IDonation2[] = []
    const donationsData = await filterDonationsDataAggr(aggregateQueryDD)
    fullDonations = [...fullDonations, ...(donationsData as IDonation2[])]
    setDonation(fullDonations)
    setIsLoading(false)
  }

  useEffect(() => {
    loadAllDonations()
  }, [])

  return (
    <>
      <Wrapper>
        <LeftColumn>
          <Heading>Donation History</Heading>
          <TableWrapper>
            <BTable<IDonation2>
              isLoading={isLoading}
              data={donations as []}
              headers={HEADERS}
              renderRow={(row, index) => (
                <DonationRow2
                  dateFormat={DATEFORMAT.DATE_FORMAT}
                  onClick={handlerClickDonationItem}
                  key={`row-${row._id}-${index}`}
                  donation={row as IDonation2}
                  rowKeys={HEADERS}
                />
              )}
            />
          </TableWrapper>
        </LeftColumn>
        <RightColumn>
          <Heading>Total Contributions</Heading>
          <TableWrapper>
            <TotalsEntry>
              <LeftDiv>Current Period</LeftDiv>
              <RightDiv>
                {formatValue(contact.current_donations_total, 0, 0, true)}
              </RightDiv>
            </TotalsEntry>
            <TotalsEntry>
              <LeftDiv>All Time</LeftDiv>
              <RightDiv>
                {formatValue(contact.all_donations_total, 0, 0, true)}
              </RightDiv>
            </TotalsEntry>
            <TotalsEntry>
              <LeftDiv>Available Capacity</LeftDiv>
              <RightDiv>
                {formatValue(contact.available_capacity, 0, 0, true)}
              </RightDiv>
            </TotalsEntry>
          </TableWrapper>
        </RightColumn>
      </Wrapper>
    </>
  )
}

export default TabDonations
