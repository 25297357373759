import { propOr } from 'ramda'

let idx = 0

export const mainBreadcrumbs = {
  HOME: active => ({ title: 'Home', href: '/', active, key: idx++ }),
  EVENTS: active => ({
    title: 'Events',
    href: '/events/all',
    active,
    key: idx++,
  }),
  CREATE_EVENT: active => ({
    title: 'Create Event',
    href: '/events/create',
    active,
    key: idx++,
  }),
  EDIT_EVENT: (event, active) => ({
    title: propOr('', 'name', event).slice(0, 15),
    href: `/events/${propOr('', '_id', event)}/edit`,
    active,
    key: idx++,
  }),
  EVENT_PAGE: (event, active) => ({
    title: propOr('', 'name', event).slice(0, 15),
    href: `/events/${propOr('', '_id', event)}`,
    active,
    key: idx++,
  }),
  CALENDAR: active => ({
    title: 'Calendar',
    href: '/calendar',
    active,
    key: idx++,
  }),
  PIPELINE: active => ({
    title: 'Pipeline',
    href: '/pipeline',
    active,
    key: idx++,
  }),
  QUEUE: active => ({
    title: 'Queue',
    href: '/pipeline',
    active,
    key: idx++,
  }),
  CONTACTS: active => ({
    title: 'Contacts',
    href: '/contacts',
    active,
    key: idx++,
  }),
  ADMIN_ORGANIZATIONS: active => ({
    title: 'Organization configuration',
    href: '/admin/organizations',
    active,
    key: idx++,
  }),
  ORGANIZATION_ID: (org, active) => ({
    title: propOr('', 'name', org),
    href: `/admin/organizations/${propOr('', '_id', org)}`,
    active,
    key: idx++,
  }),
  CREATE_ORGANIZATIONS: active => ({
    title: 'Create organization',
    href: '/admin/organizations/create',
    active,
    key: idx++,
  }),
  EDIT_ORGANIZATIONS: (org, active) => ({
    title: 'Edit organization',
    href: `/admin/organizations/edit/${propOr('', '_id', org)}/`,
    active,
    key: idx++,
  }),
  UPLOADFILE: active => ({
    title: 'Upload File',
    href: '/contacts/uploadfile',
    active,
    key: idx++,
  }),
  CONTACT_ID: (contact, active) => ({
    title:
      propOr('', 'firstName', contact) + ' ' + propOr('', 'lastName', contact),
    href: `/events/${propOr('', '_id', contact)}`,
    active,
    key: idx++,
  }),
}
