import * as Yup from 'yup'

export const donationTypeValidationSchema = Yup.object().shape({
  donor: Yup.string().required('Donor is required'),
  donation: Yup.string()
    .required('Donation is required')
    .test(
      'is-gt0',
      () => `Must be greater than 0`,
      value => {
        return Number(String(value).replaceAll(',', '')) > 0
      }
    ),
  eventId: Yup.string(),
  election_type: Yup.string(),
})
