const fieldMapOptions = {
  address_1: {
    label: 'Home Street',
    isRequired: true,
    alias: ['address', 'street'],
  },
  address_2: {
    label: 'Home Street 2',
    isRequired: false,
    alias: ['address2', 'street2'],
  },
  assistant_email: {
    label: 'Assistant Email',
    isRequired: false,
    alias: ['assistantEmail'],
  },
  assistant_email_2: {
    label: 'Assistant Email B',
    isRequired: false,
    alias: ['assistantEmailB'],
  },
  assistant_cell_phone_number: {
    label: 'Assistant Cell Phone',
    isRequired: false,
    alias: ['assistantCellPhone'],
  },
  assistant_work_phone_number: {
    label: 'Assistant Work Phone',
    isRequired: false,
    alias: ['assistantWorkPhone'],
  },
  assistantName: {
    label: 'Assistant Name',
    isRequired: false,
    alias: ['assistantName'],
  },
  birth_date: { label: 'Birthday', isRequired: false, alias: ['birthday'] },
  city: { label: 'Home City', isRequired: true, alias: ['city'] },
  employer: {
    label: 'Employer',
    isRequired: false,
    alias: ['employer', 'company'],
  },
  // estNetWorth: {
  //   label: 'Est. Net Worth',
  //   isRequired: false,
  //   alias: ['estNetWorth'],
  // },
  first_name: { label: 'First Name', isRequired: true, alias: ['first_name'] },
  formal_salutation: {
    label: 'Formal Salutation',
    isRequired: false,
    alias: ['formalSalutation'],
  },
  goes_by: { label: 'Goes By', isRequired: false, alias: ['goesBy'] },
  landline_phone_number: {
    label: 'Home Phone Number',
    isRequired: false,
    alias: ['homePhoneNumber'],
  },
  industry: { label: 'Industry', isRequired: false, alias: ['industry'] },
  informal_salutation: {
    label: 'Informal Salutation',
    isRequired: false,
    alias: ['informalSalutation'],
  },
  invite_alias: {
    label: 'Invite Listing',
    isRequired: false,
    alias: ['invite_alias'],
  },
  jurisdiction_name: {
    label: 'Home County',
    isRequired: false,
    alias: ['jurisname', 'jurisdiction'],
  },
  last_name: { label: 'Last Name', isRequired: true, alias: ['last_name'] },
  desiganted_market_area: {
    label: 'Market/Region',
    isRequired: false,
    alias: ['dma', 'marketArea', 'marketRegion'],
  },
  middle_initial: {
    label: 'Middle Initial',
    isRequired: false,
    alias: ['middleInitial'],
  },
  cell_phone_number: {
    label: 'Mobile Phone Number',
    isRequired: false,
    alias: ['cell', 'mobile', 'cell phone', 'mobile phone'],
  },
  modeled_political_party: {
    label: 'Modeled Party',
    isRequired: false,
    alias: [' modeledParty'],
  },
  modeled_political_spectrum_bracket: {
    label: 'Modeled Spectrum',
    isRequired: false,
    alias: ['modeledSpectrum'],
  },
  position: { label: 'Position', isRequired: false, alias: ['position'] },
  prefix_name: { label: 'Prefix', isRequired: false, alias: ['prefix_name'] },
  email: {
    label: 'Primary Email',
    isRequired: true,
    alias: ['email'],
  },
  primary_phone_number: {
    label: 'Primary Phone Number',
    isRequired: false,
    alias: ['phoneNumber'],
  },
  secondary_email: {
    label: 'Secondary Email',
    isRequired: false,
    alias: ['secondaryEmail'],
  },
  secondary_phone_number: {
    label: 'Secondary Phone Number',
    isRequired: false,
    alias: ['secondary phone number'],
  },
  spouse: { label: 'Spouse', isRequired: false, alias: ['spouse'] },
  state_abbr: { label: 'Home State', isRequired: false, alias: ['state'] },
  state_house_district: {
    label: 'State House District',
    isRequired: false,
    alias: ['stateHouseDistrict'],
  },
  state_senate_district: {
    label: 'State Senate District',
    isRequired: false,
    alias: ['stateSenateDistrict'],
  },
  suffix_name: { label: 'Suffix', isRequired: false, alias: ['suffix'] },
  us_congressional_district: {
    label: 'US House District',
    isRequired: false,
    alias: ['usCongressionalDistrict', 'usHouseDistrict'],
  },
  voting_propensity: {
    label: 'Voting Propensity',
    isRequired: false,
    alias: ['votingPropensity'],
  },
  work_address_1: {
    label: 'Work Street',
    isRequired: false,
    alias: ['workAddress'],
  },
  work_address_2: {
    label: 'Work Street 2',
    isRequired: false,
    alias: ['workAddress2'],
  },
  work_city: { label: 'Work City', isRequired: false, alias: ['workCity'] },
  work_phone_number: {
    label: 'Work Phone Number',
    isRequired: false,
    alias: ['workPhoneNumber'],
  },
  work_phone_direct: {
    label: 'Direct Work Phone Number',
    isRequired: false,
    alias: ['workPhoneDirect'],
  },
  work_state_abbr: {
    label: 'Work State',
    isRequired: false,
    alias: ['workState'],
  },
  work_postal_code: {
    label: 'Work Zip',
    isRequired: false,
    alias: ['workPostalCode', 'workZip', 'workZipCode'],
  },
  postal_code: {
    label: 'Home Zip',
    isRequired: false,
    alias: ['postalCode', 'zip', 'zipCode'],
  },
}

const fieldMapOptionsKeys = Object.keys(fieldMapOptions)

fieldMapOptionsKeys.sort((a, b) => {
  if (fieldMapOptions[a].label < fieldMapOptions[b].label) return -1
  else if (fieldMapOptions[a].label > fieldMapOptions[b].label) return 1
  else return 0
})

export default fieldMapOptionsKeys
  .map(key => {
    return { [key]: fieldMapOptions[key] }
  })
  .reduce((p, c) => {
    return { ...p, ...c }
  }, {})
