import { useState } from 'react'
import PropTypes from 'prop-types'

const EditMode = ({
  text,
  placeholder,
  okButtonText = 'Save',
  cancelButtonText = 'Cancel',
  callBackEdit,
  callBackEditMode,
}) => {
  const [input, setInput] = useState(text)
  return (
    <>
      <>
        <div className="col-auto">
          <input
            value={input}
            placeholder={placeholder}
            className="form-control"
            onChange={e => setInput(e.target.value)}
          />
        </div>
        <div className="col-auto">
          <button
            className="btn btn-primary btn-sm"
            onClick={() => {
              if (input) {
                callBackEdit(input)
                callBackEditMode(false)
              }
            }}
          >
            {okButtonText}
          </button>
          <button
            className="btn btn-light btn-sm"
            onClick={() => callBackEditMode(false)}
          >
            {cancelButtonText}
          </button>
        </div>
      </>
    </>
  )
}

EditMode.propTypes = {
  text: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  callBackEdit: PropTypes.func.isRequired,
  callBackEditMode: PropTypes.func.isRequired,
}

export default EditMode
