import types from '../types/types'

const initialState = {
  isAuthenticated: false,
  user: null,
  isAuthenticationError: false,
  isLogingIn: false,
  errorMessage: '',
}

const authenticationReducer = (state = initialState, action) => {
  let user = null
  let federatedUserData = null

  switch (action.type) {
    case types.START_LOGIN_PROCESS:
      return {
        ...state,
        isLogingIn: true,
      }

    case types.FINISH_LOGIN_PROCESS:
      return {
        ...state,
        isLogingIn: false,
      }

    case types.LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
        isAuthenticationError: false,
        errorMessage: '',
      }

    case types.AZURE_LOGIN:
      federatedUserData = action.payload.azureUserProfile
      user = {
        _id: action.payload.federatedUser._id,
        email: federatedUserData.email,
        name: federatedUserData.firstName,
        lastName: federatedUserData.lastName,
        role: action.payload.role,
        picture: 'profile1',
        chatId: federatedUserData.chatId,
        providerAccounts: action.payload.federatedUser.providerAccounts,
        isFederated: true,
        provider: 'azure',
        organizationId: action.payload.federatedUser.organizationId,
        sessionToken: action.payload.federatedUser.sessionToken,
        expiration: action.payload.federatedUser.expiration,
      }
      return {
        ...state,
        isAuthenticated: true,
        user,
        isAuthenticationError: false,
        errorMessage: '',
        federatedUserData,
      }

    case types.GOOGLE_LOGIN:
      federatedUserData = action.payload.googleUserProfile
      user = {
        _id: action.payload.federatedUser._id,
        email: federatedUserData.email,
        name: federatedUserData.name,
        lastName: '',
        role: action.payload.role,
        metrics_group: action.payload.metrics_group,
        picture: federatedUserData.imageUrl,
        chatId: action.payload.federatedUser.chatId,
        providerAccounts: action.payload.federatedUser.providerAccounts,
        isFederated: true,
        provider: 'google',
        organizationId: action.payload.federatedUser.organizationId,
        sessionToken: action.payload.federatedUser.sessionToken,
        expiration: action.payload.federatedUser.expiration,
      }
      return {
        ...state,
        isAuthenticated: true,
        user,
        isAuthenticationError: false,
        errorMessage: '',
        federatedUserData,
      }

    case types.LINKEDIN_LOGIN:
      federatedUserData = action.payload.linkedInUserProfile
      user = {
        _id: federatedUserData.email,
        email: federatedUserData.email,
        name: federatedUserData.firstName,
        lastName: federatedUserData.lastName,
        role: action.payload.role,
        picture: federatedUserData.profilePicture,
        chatId: federatedUserData.chatId,
      }
      return {
        ...state,
        isAuthenticated: true,
        user,
        isAuthenticationError: false,
        errorMessage: '',
        federatedUserData,
        isFederated: true,
        provider: 'linkedin',
      }

    case types.LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isAuthenticationError: false,
        errorMessage: '',
      }

    case types.LOGIN_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        isAuthenticationError: true,
        errorMessage: action.payload,
      }

    default:
      return state
  }
}

export default authenticationReducer
