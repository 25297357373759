import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import Select from 'react-select'
import { path, pathOr, prop, propEq } from 'ramda'
import ContactsApi from '../../api/contact'
import { useSelector } from 'react-redux'
import { getUser } from '../../utils/state/selectors'
import ToastWarningFields from './ToastWarningFields'
import { NumericFormat } from 'react-number-format'

const findParticipantObject = participantList => value =>
  participantList.find(propEq('value', prop('value', value)))

const RedAst = () => <span style={{ color: 'red' }}>*</span>

const FieldsWrapper = styled.div`
  label {
    display: block;
  }
`

const TierFormModal = ({ show, onAdd, onEdit, onClose, tier, isEdit }) => {
  const user = useSelector(getUser)
  const [contacts, setContacts] = useState([])
  const [contactsSelectOptions, setContactsSelectOptions] = useState([])
  const [tierId, setTierId] = useState(isEdit ? tier._id : undefined)
  const [tierName, setTierName] = useState(isEdit ? tier.tierName : '')

  const [participants, setParticipants] = useState(
    isEdit
      ? tier.participants.map(findParticipantObject(contactsSelectOptions))
      : []
  )
  const [tierLevel, setTierLevel] = useState(isEdit ? tier.tierLevel : 0)
  const [isGiveRaise, setGiveRaise] = useState(
    isEdit ? tier.isGiveRaise : false
  )
  const [showToast, setShowToast] = useState(false)
  const selectStyles = {
    input: provided => ({
      ...provided,
      border: 'none',
      '> input[type=text]:focus': { outline: 'none !important' },
    }),
  }

  const clear = () => {
    setTierId(undefined)
    setTierName('')
    setParticipants([])
    setTierLevel(0)
    setGiveRaise(false)
  }

  const reset = () => {
    const defaultTierName = isEdit ? tier.tierName : ''
    const defaultParticipants = isEdit
      ? tier.participants.map(findParticipantObject(contactsSelectOptions))
      : []
    const defaultTierLevel = isEdit ? tier.tierLevel : 0
    const defaultTierGiveRaise = isEdit ? tier.isGiveRaise : false
    const defaultTierId = isEdit ? tier._id : undefined
    setTierName(defaultTierName)
    setParticipants(defaultParticipants)
    setTierLevel(defaultTierLevel)
    setGiveRaise(defaultTierGiveRaise)
    setTierId(defaultTierId)
  }

  const changeHandler = setter => e =>
    setter(pathOr('', ['target', 'value'], e))

  const switchHandler = setter => e =>
    setter(pathOr(false, ['target', 'checked'], e))

  const participantChangeHandler = e => {
    setParticipants(e)
  }

  const checkTiersFields = () => {
    const tierLevelNumber = Number(tierLevel.toString().replaceAll(',', ''))
    return tierName != '' && tierLevelNumber > 0
  }

  const addTierHandler = () => {
    if (checkTiersFields()) {
      onAdd({
        tierName,
        participants,
        tierLevel: Number(tierLevel.toString().replaceAll(',', '')),
        isGiveRaise,
      })
      clear()
      onClose()
    } else {
      setShowToast(true)
    }
  }

  const editTierHandler = () => {
    if (checkTiersFields()) {
      onEdit({
        _id: tierId,
        tierName,
        participants,
        tierLevel: Number(tierLevel.toString().replaceAll(',', '')),
        isGiveRaise,
      })
      clear()
      onClose()
    } else {
      setShowToast(true)
    }
  }

  const actionButtonHandler = isEdit ? editTierHandler : addTierHandler

  const cancelHandler = () => {
    clear()
    setShowToast(false)
    onClose()
  }

  useEffect(() => {
    if (show && isEdit && tier) reset()
  }, [isEdit, tier, show])

  const levelFocusHandler = e => {
    e.preventDefault()
    e.stopPropagation()
    const value = Number(path(['target', 'value'], e))
    if (value === 0) {
      setTierLevel('')
    }
  }
  const levelBlurHandler = e => {
    e.preventDefault()
    e.stopPropagation()
    const value = path(['target', 'value'], e).replaceAll(',', '')
    if (value === '' || Number.isNaN(Number(value))) {
      setTierLevel('0')
    }
  }

  useEffect(() => {
    const options = contacts
      .filter(({ level }) => level != 'Staff')
      .map(
        ({ _id, first_name, last_name, email, user_id, picture, level }) => ({
          _id,
          value: `${first_name} ${last_name}`,
          label: `${first_name} ${last_name}${level ? ` - ${level}` : ``}`,
          first_name,
          last_name,
          email,
          picture: picture ? picture : 'unassigned',
          user_id,
        })
      )
    setContactsSelectOptions(options)
  }, [contacts])

  useEffect(() => {
    let isMounted = true
    ContactsApi.getContacts(user).then(response => {
      if (isMounted) setContacts(response)
    })
    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      <Modal show={show} onHide={cancelHandler}>
        <Modal.Header closeButton>
          {isEdit ? 'Edit Tier' : 'New Tier'}
        </Modal.Header>
        <Modal.Body>
          <ToastWarningFields show={showToast} setShow={setShowToast} />
          <FieldsWrapper>
            <Form.Group className="mb-2">
              <Form.Label htmlFor="description">
                Tier Name <RedAst />
              </Form.Label>
              <Form.Control
                autoComplete="off"
                type="text"
                name="description"
                onChange={changeHandler(setTierName)}
                value={tierName}
                placeholder="Enter tier name here"
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label htmlFor="give">Participants</Form.Label>
              <Select
                options={contactsSelectOptions}
                value={participants}
                isMulti
                onChange={participantChangeHandler}
                styles={selectStyles}
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label htmlFor="level">
                Level <RedAst />
              </Form.Label>
              <InputGroup>
                <InputGroup.Text>$</InputGroup.Text>
                {/* <Form.Control
                  type="number"
                  name="level"
                  value={tierLevel}
                  onFocus={levelFocusHandler}
                  onBlur={levelBlurHandler}
                  min="0"
                  onChange={changeHandler(setTierLevel)}
                /> */}
                <NumericFormat
                  autoComplete="off"
                  type="text"
                  name="level"
                  value={tierLevel}
                  onFocus={levelFocusHandler}
                  onBlur={levelBlurHandler}
                  min="0"
                  onChange={changeHandler(setTierLevel)}
                  className="currency form-control"
                  thousandsGroupStyle="thousand"
                  thousandSeparator=","
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Check
                inline
                type="switch"
                label="Give or raise"
                name="giveOrRaise"
                checked={isGiveRaise}
                onChange={switchHandler(setGiveRaise)}
              />
            </Form.Group>
          </FieldsWrapper>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="light" onClick={cancelHandler}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="secondary"
            onClick={actionButtonHandler}
          >
            {isEdit ? 'Save Tier' : 'Create Tier'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
TierFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  tier: PropTypes.object,
  isEdit: PropTypes.bool,
}

export default TierFormModal
