import { prop } from 'ramda'
import constants from '../config/constants'
import axios from './axios'
import { IBundlerId } from './interfaces'

const getUserBundlerId = async (
  userId = '',
  userEmail = '',
  isAllowed = false
) => {
  let endpoint = constants.BUNDLERID_ENDPOINT()
  if (isAllowed)
    endpoint = endpoint.replace('/api/benjamin/', '/api/allowed/benjamin/')
  const url = endpoint
  const docs = await axios<IBundlerId>({
    url,
    method: 'POST',
    data: {
      userId,
      userEmail,
    },
  }).then(prop('data'))
  return docs
}

export default { getUserBundlerId }
