import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { getName } from '../../../utils/dataModels/events'
import constants from '../../../config/constants'

import EventProvider from './EventProvider'
import FundraisingEvent from './FundraisingEvent'
import NewEventProvider from './NewEventProvider'
const StyledModal = styled(Modal)`
  & .modal-dialog {
    max-width: 23.125rem;
  }
`
export const SectionTitle = styled.div`
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.3125rem;
`

export const FieldTitle = styled.div`
  margin-bottom: 0.25rem;
  font-size: 1rem;
  line-height: 1.3125rem;
  color: #6c757d;
`

export const FieldValue = styled.div`
  margin-bottom: 1rem;
  font-size: 1rem;
  line-height: 1.3125rem;
  color: initial;
`

const EventModal = ({ show, onHide, event = {}, newTaskTypePanel = false }) => {
  const isProviderEvent = event?.type === constants.EVENT_TYPE.providerEvent
  const conditionalRendering = (isProviderEvent, newTaskTypePanel) => {
    return newTaskTypePanel ? (
      <NewEventProvider />
    ) : isProviderEvent ? (
      <EventProvider event={event} />
    ) : (
      <FundraisingEvent event={event} />
    )
  }

  return (
    <StyledModal show={show} onHide={onHide}>
      <Modal.Header closeButton>{getName(event)}</Modal.Header>
      {conditionalRendering(isProviderEvent, newTaskTypePanel)}
    </StyledModal>
  )
}

EventModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  event: PropTypes.object,
  newTaskTypePanel: PropTypes.bool,
}

export default EventModal
