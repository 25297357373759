import PropTypes from 'prop-types'

const SentIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8542 0.14615C15.9234 0.215482 15.9707 0.303624 15.9903 0.399627C16.0098 0.49563 16.0008 0.595263 15.9642 0.68615L10.1452 15.2331C10.0939 15.3613 10.0083 15.4727 9.89771 15.5553C9.78715 15.6379 9.65596 15.6884 9.51856 15.7012C9.38116 15.714 9.2429 15.6887 9.11897 15.628C8.99505 15.5673 8.89027 15.4736 8.81618 15.3572L5.63818 10.3622L0.643176 7.18415C0.526494 7.11013 0.432527 7.00532 0.371646 6.88128C0.310764 6.75723 0.285334 6.61878 0.298161 6.4812C0.310988 6.34362 0.361574 6.21226 0.444337 6.10161C0.5271 5.99096 0.638822 5.90532 0.767176 5.85415L15.3142 0.0371498C15.4051 0.000562027 15.5047 -0.0085177 15.6007 0.0110385C15.6967 0.0305947 15.7848 0.0779249 15.8542 0.14715V0.14615ZM6.63618 10.0701L9.39718 14.4081L14.1302 2.57615L6.63618 10.0701ZM13.4232 1.86915L1.59118 6.60215L5.93018 9.36215L13.4242 1.86915H13.4232Z"
        fill="#11273C"
      />
    </svg>
  )
}
SentIcon.propTypes = {
  className: PropTypes.string,
}
export default SentIcon
