import styled from 'styled-components'

export const StyledTd = styled.td`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  > img {
    width: 40px;
    border-radius: 50%;
  }
  .name {
    padding-left: 10px;
  }
`

export const StyledButtonTd = styled.td`
  width: 2.5rem;
`
