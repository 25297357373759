import { path, pathOr } from 'ramda'
import {
  Authentication,
  IUser,
  ProviderAccount,
  USER_ROL_TYPE,
} from '../../api/interfaces'
import {
  AddDonationTypeModalProps,
  CreateContactModalProps,
  EditContactModalProps,
} from '../../redux/pipeline/types'
import { ToastStateItem } from '../../redux/toasts/type'

export const getAuthenticated = pathOr(false, [
  'authentication',
  'isAuthenticated',
])

export const getUserId: (auth: Authentication) => string | undefined = path([
  'authentication',
  'user',
  '_id',
])

export const getUser = path<IUser>(['authentication', 'user'])

export const getUserRole: (auth: Authentication) => USER_ROL_TYPE | undefined =
  path<USER_ROL_TYPE | undefined>(['authentication', 'user', 'role'])

export const getUserEmail = pathOr('', ['authentication', 'user', 'email'])

export const getUserOrganizationId: (
  auth: Authentication
) => string | undefined = pathOr('', [
  'authentication',
  'user',
  'organizationId',
])

export const getUserProvider = pathOr('', [
  'authentication',
  'user',
  'provider',
])

export const getUserProviderAccounts: (
  auth: Authentication
) => ProviderAccount[] | undefined = pathOr(undefined, [
  'authentication',
  'user',
  'providerAccounts',
])

export const getUserFullName = (state: { authentication: Authentication }) =>
  `${pathOr('', ['authentication', 'user', 'name'], state)} ${pathOr(
    '',
    ['authentication', 'user', 'lastName'],
    state
  )}`

export const getPicture = pathOr('', ['authentication', 'user', 'picture'])

export const getFederatedPictureUrl = pathOr('', [
  'authentication',
  'federatedUserData',
  'imageUrl',
])

export const getChatId = pathOr('', ['authentication', 'user', '_id'])

export const isFederatedUser = pathOr(false, [
  'authentication',
  'user',
  'isFederated',
])

export const getAuthProvider = pathOr(false, [
  'authentication',
  'user',
  'provider',
])

export const getPageTitle = pathOr(null, ['ui', 'currentPage', 'title'])

export const getBreadcrumbs = pathOr(null, ['ui', 'currentPage', 'breadcrumbs'])

export const getPagination = (email: string) =>
  pathOr(null, ['mailbox', 'emailAccounts', email])

export const getPage = (email: string) =>
  pathOr([], ['mailbox', 'emailAccountsPages', email, 'threads'])

export const getPageCount = (email: string) =>
  pathOr(null, ['mailbox', 'emailAccountsPages', email, 'count'])

export const getOpenedThreads = (email: string) =>
  pathOr(null, ['mailbox', 'emailAccountsPages', email, 'openedThreads'])

export const getFolder = pathOr([], ['mailbox', 'folder'])

export const getChatRoom = pathOr(null, ['chat', 'chatRoomData'])

// Toasts
export const getToastList = pathOr<ToastStateItem[]>([], ['toasts', 'list'])

// Pipeline
export const getRefreshConsolidated = pathOr<boolean>(false, [
  'pipeline',
  'refreshConsolidated',
])

export const getPipelineData = pathOr<boolean>(false, [
  'pipeline',
  'pipelineData',
])

export const getPipelineOrgUsers = pathOr<boolean>(false, [
  'pipeline',
  'orgUsers',
])

export const getRefreshQueue = pathOr<boolean>(false, [
  'pipeline',
  'refreshQueue',
])

export const getQueuePageCount = pathOr<boolean>(false, [
  'pipeline',
  'queuePageCount',
])

export const getCurrentQueuePage = pathOr<boolean>(false, [
  'pipeline',
  'currentQueuePage',
])

export const getCurrentQueuePageSize = pathOr<boolean>(false, [
  'pipeline',
  'currentQueuePageSize',
])

export const getQueryFilter = pathOr<boolean>(false, [
  'pipeline',
  'queryFilter',
])

export const getRefreshDonations = pathOr<boolean>(false, [
  'pipeline',
  'refreshDonations',
])

export const getEditContactModalPayload = path<EditContactModalProps>([
  'pipeline',
  'editContactModal',
])

export const getAddDonationTypeModalPayload = path<AddDonationTypeModalProps>([
  'pipeline',
  'addDonationTypeModal',
])

export const getCreateContactModalPayload = path<CreateContactModalProps>([
  'pipeline',
  'createContactModal',
])

export const getContactsPageCount = pathOr<boolean>(false, [
  'contacts',
  'contactsPageCount',
])

export const getCurrentContactsPage = pathOr<boolean>(false, [
  'contacts',
  'currentContactsPage',
])

export const getCurrentContactsPageSize = pathOr<boolean>(false, [
  'contacts',
  'currentContactsPageSize',
])

export const getQueueContactsPageCount = pathOr<boolean>(false, [
  'pipeline',
  'queueContactPageCount',
])

export const getQueueContactsPage = pathOr<boolean>(false, [
  'pipeline',
  'queueContactPage',
])

export const getQueueContactsPageSize = pathOr<boolean>(false, [
  'pipeline',
  'queueContactPageSize',
])

export const getShowFilterModal = pathOr<boolean>(false, [
  'contacts',
  'showFilterModal',
])
