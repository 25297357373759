import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Stack, Table } from 'react-bootstrap'
import { BsCheckSquare, BsChevronRight, BsSquare } from 'react-icons/bs'
import { pathOr, propEq, propOr } from 'ramda'
import TaskFormModal from './TaskFormModal'
import Api from '../../api/user'
import SortIconAction from '../ui-elements/SortIconAction'
import { getAvatarContact } from '../../utils/stringsOperations'
import { StyledTd } from '../ContactsPage/ContactRow'

const OpacityWrapper = styled.div`
  opacity: var(--opacity, 1);
`

const StyledButtonTd = styled.td`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  width: 2.5rem;
`

const StyledImg = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 50%;
`

const fullName = ({ name, lastName }) => `${name || ''} ${lastName || ''}`

const TaskRow = ({ task, onEdit, users }) => {
  const [isMouseOver, setMouseOver] = useState(false)
  const mouseOverHandler = () => setMouseOver(true)
  const mouseOutHandler = () => setMouseOver(false)
  const [showEditModal, setShowEditModal] = useState(false)
  //const taskId = propOr('', '_id', task)
  const description = propOr('', 'description', task)
  const markCompleted = propOr(false, 'markCompleted', task)
  const contactId = propOr('', 'contact', task)
  const contact = users.find(propEq('_id', contactId))

  const unassignedIcon = require(`../../assets/profilePictures/unassigned.png`)

  return (
    <tr onMouseEnter={mouseOverHandler} onMouseLeave={mouseOutHandler}>
      <td>
        <Stack gap={2} direction="horizontal">
          {markCompleted ? (
            <BsCheckSquare color="#00bdb2" />
          ) : (
            <BsSquare color="#00bdb2" />
          )}
          {description}
        </Stack>
      </td>
      {contact ? (
        <StyledTd
          style={{
            display: 'flex',
            gap: '6px',
            alignItems: 'center',
          }}
        >
          {getAvatarContact(contact)} <span>{fullName(contact)}</span>
        </StyledTd>
      ) : (
        <td>
          <Stack gap={3} direction="horizontal">
            <StyledImg src={unassignedIcon} />
            <span>Unassigned</span>
          </Stack>
        </td>
      )}
      <StyledButtonTd>
        <OpacityWrapper
          style={{
            '--opacity': isMouseOver ? 1 : 0,
          }}
        >
          <Button
            onClick={() => setShowEditModal(true)}
            variant="outline-secondary"
          >
            <BsChevronRight />
          </Button>
        </OpacityWrapper>
        <TaskFormModal
          onEdit={onEdit}
          onClose={() => {
            setShowEditModal(false)
            setMouseOver(false)
          }}
          show={showEditModal}
          task={task}
          contacts={users}
          isEdit
        />
      </StyledButtonTd>
    </tr>
  )
}
TaskRow.propTypes = {
  task: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const TableWrapper = styled.div`
  > table > tbody > tr > td {
    vertical-align: middle;
    > img,
    > span {
      display: inline-block;
      line-height: 1rem;
      vertical-align: middle;
    }
  }
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const CenterWrapper = styled.div`
  text-align: center;
  padding-bottom: 1rem;
`

const TextSpan = styled.span`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
  color: #6c757d;
`

const TasksTable = ({ tasks, onEdit, onEditAll }) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    let isMounted = true
    Api.getUsers().then(cc => (isMounted ? setUsers(cc) : 0))
    return () => {
      isMounted = false
    }
  }, [])

  const sortUp = (a, b) => {
    let sortA = a.description
    let sortB = b.description
    sortA = sortA.toUpperCase()
    sortB = sortB.toUpperCase()
    if (sortA < sortB) {
      return -1
    }
    if (sortA > sortB) {
      return 1
    }
    return 0
  }

  return (
    <div>
      {tasks.length > 0 ? (
        <TableWrapper>
          <Table hover>
            <thead>
              <tr>
                <th>
                  Task{' '}
                  <SortIconAction
                    setFunctState={() => null}
                    stateValue={0}
                    refresh={() => null}
                  />
                </th>
                <th>
                  Owner{' '}
                  <SortIconAction
                    setFunctState={() => null}
                    stateValue={0}
                    refresh={() => null}
                  />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {tasks
                .sort((a, b) => sortUp(a, b))
                .map((task, i) => (
                  <TaskRow
                    key={`task-row-${pathOr(i, ['_id'], task)}`}
                    task={task}
                    onEdit={i != 0 ? onEdit : onEditAll}
                    users={users}
                  />
                ))}
            </tbody>
          </Table>
        </TableWrapper>
      ) : (
        <Wrapper>
          <CenterWrapper>
            <TextSpan>You still have not created any task</TextSpan>
          </CenterWrapper>
        </Wrapper>
      )}
    </div>
  )
}

TasksTable.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object),
  onEdit: PropTypes.func.isRequired,
  onEditAll: PropTypes.func.isRequired,
}

export default TasksTable
