import { prop } from 'ramda'
import constants from '../../config/constants'
import axiosInstance from '../api_instance'

const API_URL = 'bmail'
/**
 * Return all threads. Threads return with the most recently modified first.
 *
 * @param {Object} body - The body who is responsible for the body request.
 * @param {number} [body.limit] The number of objects to return. Defaults to 100. If set too high, requests may fail to prevent excessively large response bodies.
 * @param {number} [body.offset] Zero-based offset from default object sorting. See pagination for more information.
 * @param {string} [body.folder] Used to filter threads, based on folder value.
 * @param {string} [body.not_in] Filter messages not in a given folder or label. The filter supports the name, display, or ID of a folder or label.
 * @param {string} [body.any_email] Return emails that have been sent or received from this comma-separated list of email addresses. For example: mail1@mail.com,mail2@mail.com. A maximum of 25 emails may be specified.
 * @param {string} [body.last_message_before] Return threads whose first message was received before this Unix-based timestamp.
 * @param {string} [body.last_message_after] Return threads whose most recent message was received after this Unix-based timestamp.
 * @param {string} [body.last_updated_after] Return threads whose most recent last updated date is after this Unix-based timestamp.
 * @param {string} [body.token] Token provider account.
 * @return {Promise} Represent an array of threads.
 */
export const getThreads = async ({
  limit = 10,
  offset = 0,
  folder,
  not_in,
  any_email,
  last_message_before,
  last_message_after,
  last_updated_after,
  token,
}) => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance.post(
    `${API_URL}/threads`,
    {
      limit,
      offset,
      folder,
      any_email,
      not_in,
      last_message_before,
      last_message_after,
      last_updated_after,
    },
    {
      headers: { token },
      baseURL,
    }
  )
}

/**
 * Update specific thread.
 *
 * @param {number} threadId Represent thread's id.
 * @param {boolean} unread Mark thread unread value.
 * @return {Promise} Represent an thread updated.
 */
export const updateThread = async (threadId, unread, token) => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance
    .put(
      `${API_URL}/threads/${threadId}`,
      {
        unread,
      },
      {
        headers: { token },
        baseURL,
      }
    )
    .then(prop('data'))
}

/**
 * Return all messages from specific thread.
 *
 * @param {string[]} messageIds Array of message ids.
 * @return {Promise} Represent an array of threads.
 */
export const getThreadMessages = async (messageIds, token) => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance.get(`${API_URL}/messages-thread/${messageIds}`, {
    headers: { token },
    baseURL,
  })
}

/**
 * Return count objects from specific type and folder.
 *
 * @param {string} type enum with value= 'THREAD' | 'MESSAGE'.
 * @param {string} folder to filter threads, based on folder value.
 * @param {string} token token provider account.
 * @param {string} not_in Filter messages not in a given folder or label. The filter supports the name, display, or ID of a folder or label.
 * @return {Promise} Represent an array of threads.
 */
export const getCountView = async (type, folder, not_in, token) => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance.get(`${API_URL}/count-view`, {
    params: { type, folder, not_in },
    headers: { token },
    baseURL,
  })
}

/**
 * Return result from send email operation
 *
 * @param {{email: string, name: string}} from email sender.
 * @param {[{email: string, name: string}]} to list of receivers.
 * @param {string} subject email subject.
 * @param {string} body body email.
 * @param {[{email: string, name: string}]} cc carbon copy list.
 * @param {[{email: string, name: string}]} bcc blind carbon copy list.
 * @param {boolean} tracking to enable track thread sent.
 * @param {string} token Token provider account.
 * @return {Promise} Represent an response after to send email.
 */

export const getOpenedEmails = async (
  accountId,
  messageIds,
  organizationId
) => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance.post(
    `${API_URL}/threads/opened`,
    {
      accountId,
      messageIds,
      organizationId,
    },
    { baseURL }
  )
}

export const sendNylasEmail = async (
  from,
  to,
  subject,
  body,
  cc,
  bcc,
  token,
  tracking = false,
  organizationId
) => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance.post(
    `${API_URL}/sendNylasEmail`,
    {
      from,
      to,
      subject,
      body,
      tracking,
      cc,
      bcc,
      organizationId,
    },
    {
      headers: { token },
      baseURL,
    }
  )
}

/**
 * Return result from send email operation
 *
 * @param {{email: string, name: string}} from email sender.
 * @param {[{email: string, name: string}]} to list of receivers.
 * @param {string} subject email subject.
 * @param {string} body body email.
 * @param {[{email: string, name: string}]} cc carbon copy list.
 * @param {[{email: string, name: string}]} bcc blind carbon copy list.
 * @param {boolean} tracking to enable track thread sent.
 * @param {string} token Token provider account.
 * @return {Promise} Represent an response after to send email.
 */
export const sendNylasEmailReplyTo = async (
  from,
  to,
  subject,
  body,
  cc,
  bcc,
  token,
  messageId,
  tracking = false,
  organizationId
) => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance.post(
    `${API_URL}/sendNylasEmailReplyTo`,
    {
      from,
      to,
      subject,
      body,
      tracking,
      cc,
      bcc,
      messageId,
      organizationId,
    },
    {
      headers: { token },
      baseURL,
    }
  )
}
