export const triggerDownloadFile = (fileName, file, inNewTab = false) => {
  let alink = document.createElement('a')
  alink.href = file
  alink.download = fileName
  alink.click()
  if (inNewTab) {
    window.opener = null
    window.open('about:blank', '_self')
    window.close()
  }
}
