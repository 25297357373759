import types from '../types/types'

const initialState = {
  chatRoomData: {},
}

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CHAT_SETROOM:
      return {
        ...state,
        chatRoomData: {
          ...action.payload,
        },
      }
    default:
      return state
  }
}

export default chatReducer
