import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Stack from 'react-bootstrap/Stack'
import Money from './Money'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ImagePlaceHolder from './ImagePlaceHolder'
import {
  getEventId,
  getFinancialGoal,
  getCity,
  getState,
  getTotalRemaining,
  getDatetime,
  getColor,
} from '../../utils/dataModels/events'
import { getUser, getUserRole } from '../../utils/state/selectors'
import Api from '../../api/event'
import { BsChevronDown } from 'react-icons/bs'
import { GoDiffAdded } from 'react-icons/go'
import moment from 'moment'
import useDimensionScreen from '../../hooks/useDimensionScreen'
import { checkUserRole, isStaffer } from '../../utils/state/roleTests'

export const CardHeader = styled.header`
  padding-top: 8px;
`

export const CardHeading = styled.h1`
  font-size: 12px;

  text-align: center;
`

export const SpanTitle = styled.span`
  font-weight: 700;
`

export const SpanSubTitle = styled.span`
  font-weight: 400;
`

export const SpanCardGrey = styled.span`
  font-weight: 700;
  color: #6f6f6f;
`
/*
const Table = styled.table`
  font-size: 10px;
  width: 100%;
  padding-bottom: 10px;
`

const Row = styled.tr`
  text-align: left;
`

const Column = styled.td`
  text-align: left;
`
*/

const EventLink = styled.a`
  text-decoration: none;
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
`

const EventCard = ({
  event,
  isDashboard = false,
  setEventClicked,
  setShowAddPledgeModal,
}) => {
  const navigate = useNavigate()
  const user = useSelector(getUser)
  const eventId = getEventId(event)
  const [consolidated, setConsolidated] = useState({})
  const [dimension] = useDimensionScreen()
  const [isMobile, setIsMobile] = useState(false)
  const userRole = useSelector(getUserRole)

  useEffect(() => {
    let isMounted = true
    Api.getConsolidatedSummary(user, eventId)
      .then(res => (isMounted ? setConsolidated(res) : null))
      .catch(err => console.error('error fetching consolidated totals', err))
    return () => {
      isMounted = false
    }
  }, [event])

  useEffect(() => {
    if (dimension.width <= 430) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }, [dimension])

  const handleClick = url => e => {
    if (checkUserRole(userRole)) {
      e.preventDefault()
      e.stopPropagation()
      navigate(url)
    }
  }

  const getFormatTime = time => {
    return moment(time).format('MMM Do, YYYY')
  }

  const isProviderEvent = event.type === 'providerEvent'

  return (
    <Stack
      style={{
        maxWidth: '10rem',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '5px',
        borderColor: '#DFE4E9',
      }}
    >
      <EventLink
        href="#"
        onClick={handleClick('/events/' + event._id)}
        pointer={checkUserRole(userRole)}
        className="mh-10"
      >
        <ImagePlaceHolder
          text={getState(event)}
          options={{
            color: getColor(event),
            colorBarPosition: 'top',
            imageSize: 'medium',
            isHoverable: checkUserRole(userRole),
          }}
        />

        <CardHeader>
          <CardHeading>
            <SpanTitle>
              {isProviderEvent
                ? event.location || event.name
                : `${getCity(event)}, ${getState(event)}`}
            </SpanTitle>
            <br />
            <br />
            <SpanSubTitle>{getFormatTime(getDatetime(event))}</SpanSubTitle>
          </CardHeading>
        </CardHeader>
        {!isProviderEvent && (
          <CardHeader>
            <CardHeading>
              <SpanTitle>
                <Money value={getFinancialGoal(event)} />
              </SpanTitle>
              <br />
              <SpanCardGrey>
                <Money value={getTotalRemaining(consolidated)} />
              </SpanCardGrey>
            </CardHeading>
          </CardHeader>
        )}
      </EventLink>
      <EventLink
        href="#"
        onClick={
          isDashboard && isMobile
            ? () => {
                if (checkUserRole(userRole)) {
                  setEventClicked(event)
                  setShowAddPledgeModal(true)
                }
              }
            : handleClick('/events/' + event._id)
        }
        pointer={checkUserRole(userRole)}
      >
        <CardHeader>
          <CardHeading>
            {isDashboard && isStaffer(user.role) && isMobile ? (
              <SpanTitle style={{ color: '#3664b7' }}>
                <GoDiffAdded /> Add pledge
              </SpanTitle>
            ) : (
              <BsChevronDown />
            )}
          </CardHeading>
        </CardHeader>
      </EventLink>
    </Stack>
  )
}

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
  isDashboard: PropTypes.bool,
  setEventClicked: PropTypes.func,
  setShowAddPledgeModal: PropTypes.func,
}

export default EventCard
