import { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { propEq, propOr } from 'ramda'
import { Button, FormCheck, Stack } from 'react-bootstrap'
import { BsChevronRight } from 'react-icons/bs'
import TaskFormModal from '../CreateEvent/TaskFormModal'
import { useSelector } from 'react-redux'
import { getUser } from '../../utils/state/selectors'
import TaskApi from '../../api/task'
import { getAvatarContact } from '../../utils/stringsOperations'
import { useDispatch } from 'react-redux'
import { setRefreshConsolidated } from '../../redux/pipeline/pipeline'

const StyledTd = styled.td`
  font-family: DM Sans;
  font-size: 1rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`

const StyledButtonTd = styled.td`
  width: 2.5rem;
`

const OpacityWrapper = styled.div`
  opacity: var(--opacity, 1);
`

const fullName = ({ name, last_name }) => `${name || ''} ${last_name || ''}`

const EventTaskRow = ({ task, contacts }) => {
  const description = propOr('', 'description', task)
  const contactId = propOr('', 'owner', task)
  const contact = contacts.find(propEq('_id', contactId))
  const markCompleted = propOr(false, 'markCompleted', task)
  const [showEditModal, setShowEditModal] = useState(false)
  const dispatch = useDispatch()
  const [isMouseOver, setMouseOver] = useState(false)

  const mouseOverHandler = () => setMouseOver(true)
  const mouseOutHandler = () => setMouseOver(false)
  const user = useSelector(getUser)
  const onUpdateTask = updatedTask => {
    TaskApi.updateTask({ ...updatedTask, eventId: task.eventId }, user).then(
      res =>
        res ? dispatch(setRefreshConsolidated(true)) : console.log('Error')
    )
  }

  return (
    <tr onMouseEnter={mouseOverHandler} onMouseLeave={mouseOutHandler}>
      <StyledTd>
        <Stack gap={2} direction="horizontal">
          <FormCheck
            checked={markCompleted}
            onClick={() => setShowEditModal(true)}
            readOnly
          />{' '}
          {description}
        </Stack>
      </StyledTd>
      <StyledTd
        style={{
          display: 'flex',
          gap: '6px',
          alignItems: 'center',
        }}
      >
        {contact ? getAvatarContact(contact) : <></>}
        {contact ? fullName(contact) : 'Unassigned'}
      </StyledTd>

      <StyledButtonTd>
        <OpacityWrapper
          style={{
            '--opacity': isMouseOver ? 1 : 0,
          }}
        >
          <Button
            onClick={() => setShowEditModal(true)}
            variant="outline-secondary"
          >
            <BsChevronRight />
          </Button>
        </OpacityWrapper>
        <TaskFormModal
          onEdit={onUpdateTask}
          onClose={() => {
            setShowEditModal(false)
            setMouseOver(false)
          }}
          show={showEditModal}
          task={{
            _id: task._id,
            description: task.description,
            contact: task.owner,
            isNew: false,
            markCompleted: task.markCompleted,
          }}
          contacts={contacts}
          isEdit
        />
      </StyledButtonTd>
    </tr>
  )
}

EventTaskRow.propTypes = {
  task: PropTypes.object,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default EventTaskRow
