import React, { useState, useEffect } from 'react'
import {
  Modal,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
} from 'react-bootstrap'
import readXlsxFile from 'read-excel-file'
import PropTypes from 'prop-types'

import { sendNodeMailerEmail } from '../../api/nodemailer/nodemailer'
import { TextSpan } from '../ContactUploadFilePage/ContactUploadFilePage/styles'

const CONTACT_PRICE = 0.1
const DEFAULT_EMAIL_ADDRESS = 'pedro.garcia@3pillarglobal.com'
const DEFAULT_EMAIL_TEXT = 'Boodle process'
const DEFAULT_EMAIL_SUBJECT = 'Boodle Process'
const DEFAULT_EMAIL_HTML = '<h1>Boodle process has been started</h1>'

const SuggestContactModal = ({ show, handleClose }) => {
  const [data, setData] = useState([])
  const [file, setFile] = useState(null)
  const [validation, setValidation] = useState(false)
  const [contacts, setContacts] = useState(0)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const index = data.findIndex(array => array.length > 3) + 1
    setContacts(data.length - index)
  }, [data, contacts])

  const handleFileChange = event => {
    setData([])
    setFile(event.target.files[0])
    setValidation(true)
  }

  const handleOnSubmit = () => {
    if (file) {
      if (data.length > 1) {
        const emailData = {
          to: DEFAULT_EMAIL_ADDRESS,
          text: DEFAULT_EMAIL_TEXT,
          subject: DEFAULT_EMAIL_SUBJECT,
          html: DEFAULT_EMAIL_HTML,
        }

        const formData = new FormData()

        formData.append('data', JSON.stringify(emailData))
        formData.append('files', file)

        sendNodeMailerEmail(formData)
          .then(response => {
            console.log(response)
            setSuccess(true)
          })
          .catch(error => console.log(error))
      } else {
        readXlsxFile(file, { dateFormat: 'mm/dd/yyyy' }).then(rows => {
          setData(rows)
        })
      }
    }
  }

  const closeFunction = () => {
    handleClose()
    setContacts(0)
    setValidation(false)
    setFile(null)
    setSuccess(false)
    setData([])
  }

  return (
    <Modal show={show} onHide={closeFunction}>
      <Modal.Header closeButton>
        <Modal.Title>
          {data.length > 0 && success
            ? 'Your Donor Recommendations are coming'
            : data.length > 0 && !success
            ? 'Upload was successful'
            : 'Donor Recommendation Engine'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {data.length > 0 && success ? (
          <>
            <TextSpan>
              <p>
                We are going to analyze your contacts and then we will send the
                results to your email
              </p>
            </TextSpan>
          </>
        ) : data.length > 0 && !success ? (
          <>
            <TextSpan>
              <p>
                We found <b>{contacts}</b> contacts. The total price of the
                service would be <b>${(contacts * CONTACT_PRICE).toFixed(2)}</b>
                .
              </p>
            </TextSpan>
            <TextSpan>
              <p>
                Click &quot;Continue&quot; if you want us to analize your
                contacts and give you donor recommendations{' '}
                <b>customized just for you.</b>
              </p>
            </TextSpan>
          </>
        ) : (
          <>
            <TextSpan>
              <p>
                Harness Benjamin&lsquo;s contact recommendations tool to expand
                your LinkedIn network and discover new individuals who are
                likely to identify with your cause and giving requirements.
              </p>
            </TextSpan>
            <TextSpan>
              <p>
                All you have to do is export your LinkedIn contacts as a CSV
                file and upload it here.
              </p>
            </TextSpan>
            <TextSpan>
              <p>
                Price: 10 cents per contact analyzed. Read the Legal Terms{' '}
                <u>Here</u>
              </p>
            </TextSpan>
            <Form>
              <FormGroup className="mb-3">
                <FormLabel>
                  <b>1. Upload your LinkedIn CSV.</b>
                </FormLabel>
                <FormControl
                  type="file"
                  onChange={handleFileChange}
                  accept=".csv"
                />
              </FormGroup>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <>
          <Button
            variant={success ? 'secondary' : 'primary'}
            onClick={closeFunction}
          >
            {success ? 'Done' : 'Cancel'}
          </Button>
          {!success && (
            <Button
              variant="secondary"
              onClick={handleOnSubmit}
              disabled={!validation}
            >
              {data.length > 0 ? 'Continue' : 'Upload CSV'}
            </Button>
          )}
        </>
      </Modal.Footer>
    </Modal>
  )
}

SuggestContactModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
}

export default SuggestContactModal
