import React from 'react'
import PropTypes from 'prop-types'

const InboxIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.97978 1C4.90484 1.00011 4.83088 1.01706 4.76338 1.0496C4.69587 1.08214 4.63654 1.12944 4.58978 1.188L1.53978 5H5.99978C6.13239 5 6.25957 5.05268 6.35334 5.14645C6.4471 5.24021 6.49978 5.36739 6.49978 5.5C6.49978 5.89782 6.65782 6.27936 6.93912 6.56066C7.22043 6.84196 7.60196 7 7.99978 7C8.39761 7 8.77914 6.84196 9.06044 6.56066C9.34175 6.27936 9.49978 5.89782 9.49978 5.5C9.49978 5.36739 9.55246 5.24021 9.64623 5.14645C9.74 5.05268 9.86717 5 9.99978 5H14.4598L11.4098 1.188C11.363 1.12944 11.3037 1.08214 11.2362 1.0496C11.1687 1.01706 11.0947 1.00011 11.0198 1H4.97978ZM14.9338 6H10.4498C10.335 6.56514 10.0284 7.07324 9.58191 7.43819C9.13541 7.80314 8.57646 8.00251 7.99978 8.00251C7.4231 8.00251 6.86416 7.80314 6.41765 7.43819C5.97115 7.07324 5.66454 6.56514 5.54978 6H1.06578L1.38578 8.562C1.40091 8.68308 1.45979 8.79444 1.55134 8.87512C1.64288 8.95579 1.76076 9.00021 1.88278 9H14.1168C14.2386 8.99997 14.3563 8.95544 14.4476 8.87479C14.5389 8.79413 14.5977 8.68291 14.6128 8.562L14.9328 6H14.9338ZM3.80878 0.563C3.94938 0.38724 4.12773 0.245373 4.33061 0.147914C4.53349 0.0504541 4.75571 -9.92793e-05 4.98078 1.46374e-07H11.0188C11.2439 -9.92793e-05 11.4661 0.0504541 11.669 0.147914C11.8718 0.245373 12.0502 0.38724 12.1908 0.563L15.8908 5.188C15.9324 5.24018 15.9631 5.30016 15.9811 5.36439C15.9991 5.42862 16.0041 5.49581 15.9958 5.562L15.6058 8.686C15.5604 9.04889 15.3841 9.38271 15.1099 9.62469C14.8357 9.86667 14.4825 10.0001 14.1168 10H1.88278C1.51707 10.0001 1.16391 9.86667 0.889698 9.62469C0.61549 9.38271 0.43913 9.04889 0.393782 8.686L0.00378209 5.562C-0.00441416 5.49574 0.000742512 5.42851 0.0189479 5.36428C0.0371533 5.30004 0.0680387 5.24011 0.109782 5.188L3.80978 0.563H3.80878Z"
        fill="#11273C"
      />
    </svg>
  )
}
InboxIcon.propTypes = {
  className: PropTypes.string,
}
export default InboxIcon
