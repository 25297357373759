import styled from 'styled-components'
import PropTypes from 'prop-types'
import Gauge from './Gauge'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: space-between;
  max-width: 200px;
  display: flex;
  justify-content: center;
  height: 131px;
  > div {
    margin-bottom: 0.5rem;
  }
`

const GaugeWrapper = styled.div`
  width: 100%;
`

const GaugeGraphNoText = ({ percent, color }) => {
  return (
    <Wrapper>
      <GaugeWrapper>
        <Gauge percent={percent} color={color} />
      </GaugeWrapper>
    </Wrapper>
  )
}

GaugeGraphNoText.propTypes = {
  percent: PropTypes.number,
  amount: PropTypes.number,
  label: PropTypes.string,
  color: PropTypes.string,
}

export default GaugeGraphNoText
