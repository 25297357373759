import React from 'react'
import PropTypes from 'prop-types'

const crmHeaderButtonStyle = {
  backgroundColor: '#00FFE02B',
  color: '#11273C',
  fontFamily: 'Inter, sans-serif',
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  minWidth: '150px',
  height: '36px',
  fontWeight: 700,
  padding: '3px 6px',
  // boxShadow: '1px 2px 4px #ededed',
  cursor: 'pointer',
}

const crmHeaderButtonStyle2 = {
  backgroundColor: '#11273C',
  color: '#fff',
  fontSize: '14px',
  fontFamily: 'Inter, sans-serif',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  minWidth: '150px',
  height: '36px',
  fontWeight: 700,
  padding: '3px 6px',
  // boxShadow: '1px 2px 4px #ededed',
  cursor: 'pointer',
}

const PipelineHeaderButtons = props => {
  return (
    <div
      style={{
        display: 'flex',
        gap: '8px',
        overflowX: 'auto',
        height: '40px',
        padding: '0px 4px',
        marginBottom: '16px',
      }}
    >
      <div
        style={crmHeaderButtonStyle}
        className="bulk-action-filter-button"
        onClick={props.openAddContactsModal}
      >
        ADD
      </div>
      {/* {!props.event && (
        <div style={crmHeaderButtonStyle} className="bulk-action-filter-button">
          BULK EDIT
        </div>
      )} */}
      {/* <div style={crmHeaderButtonStyle} className="bulk-action-filter-button">
        VIEW CALL LIST
      </div>
      <div style={crmHeaderButtonStyle2} className="bulk-action-filter-button">
        ADD TO CALL LIST
      </div>
      <div style={crmHeaderButtonStyle2} className="bulk-action-filter-button">
        SEQUENCE
      </div> */}
      <div
        style={crmHeaderButtonStyle2}
        className="bulk-action-filter-button"
        onClick={props.openEmailMerge}
      >
        EMAIL MERGE
      </div>
      {/* <div style={crmHeaderButtonStyle2} className="bulk-action-filter-button">
        TEXT
      </div> */}
      {/* {!props.event && (
        <div
          style={crmHeaderButtonStyle2}
          className="bulk-action-filter-button"
        >
          CALL SHEET
        </div>
      )} */}
    </div>
  )
}

PipelineHeaderButtons.propTypes = {
  event: PropTypes.bool,
  openAddContactsModal: PropTypes.func,
  openEmailMerge: PropTypes.func,
}

export default PipelineHeaderButtons
