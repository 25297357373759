import { Row } from 'react-bootstrap'
import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 1rem;
  > .container-fluid {
    margin-bottom: 2rem;
  }
`

export const TableContainer = styled.div`
  overflow: scroll;
  min-height: 25rem;
  max-height: 22rem;
  max-width: 87vw;
  ::-webkit-scrollbar {
    width: 0.25rem;
  }

  ::-webkit-scrollbar:horizontal {
    height: 0.25rem;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #00bdb2;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #0c243c;
  }
`

export const StyledTr = styled.tr<{ sticky: boolean; inactive: boolean }>`
  height: 45px !important;
  background-color: ${props => (props.inactive ? '#e8e8e8' : 'white')};
  position: ${props => (props.sticky ? 'sticky' : 'static')};
  top: ${props => (props.sticky ? 0 : 'auto')};

  &:hover {
    background-color: ${props => (props.inactive ? '#e8e8e8' : 'white')};
  }
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ColumContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 84px;
`

export const TitleSpan = styled.span`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 21px;
  color: #1e2226;
`

export const TextSpan = styled.span`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: #000000;
  padding-bottom: 1rem;
`

const LinkSpanDefault: React.CSSProperties = {
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.125rem',
  color: '#0d60af',
  cursor: 'pointer',
  paddingBottom: '1rem',
  textDecoration: 'underline',
}

export const LinkSpan = styled.span<React.CSSProperties>`
  font-family: ${props => props.color || LinkSpanDefault.fontFamily};
  font-style: ${props => props.fontStyle || LinkSpanDefault.fontStyle};
  font-weight: ${props => props.fontWeight || LinkSpanDefault.fontWeight};
  font-size: ${props => props.fontSize || LinkSpanDefault.fontSize};
  line-height: ${props => props.lineHeight || LinkSpanDefault.lineHeight};
  color: ${props => props.color || LinkSpanDefault.color};
  cursor: ${props => props.cursor || LinkSpanDefault.cursor};
  padding-bottom: ${props =>
    props.paddingBottom || LinkSpanDefault.paddingBottom};
  text-decoration: ${props =>
    props.textDecoration || LinkSpanDefault.textDecoration};
  margin-top: ${props => props.marginTop};
`

export const TopBorderRow = styled(Row)`
  border-top: 1px solid rgba(33, 37, 41, 0.25);
  padding-top: 1.5rem;
  padding-bottom: 2.5rem;
`
