import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import OrganizationApi from '../../api/organization'
import OrganizationRow from './OrganizationRow'

const OrganizationsTable = () => {
  const [organizations, setOrganizations] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    let isMounted = true
    OrganizationApi.getOrganizations()
      .then(orgs => {
        if (isMounted) setOrganizations(orgs)
        console.log(orgs)
      })
      .catch(err => {
        console.error(err)
      })

    return () => {
      isMounted = false
    }
  }, [])

  const handleRowOnClick = id => {
    navigate(`/admin/organizations/${id}`)
  }

  return (
    <Table>
      <thead>
        <tr>
          <th style={{ whiteSpace: 'nowrap' }}>Name</th>
          <th style={{ whiteSpace: 'nowrap' }}>Email</th>
          <th style={{ whiteSpace: 'nowrap' }}>Phone</th>
          <th style={{ whiteSpace: 'nowrap' }}>Principal</th>
          <th style={{ whiteSpace: 'nowrap' }}>Principal Email</th>
          <th style={{ whiteSpace: 'nowrap' }}>Principal Phone</th>
          <th style={{ whiteSpace: 'nowrap' }}></th>
        </tr>
      </thead>
      <tbody>
        {organizations.map(org => (
          <OrganizationRow
            key={`org-${org._id}`}
            handleClick={() => handleRowOnClick(org._id)}
            organization={org}
          />
        ))}
      </tbody>
    </Table>
  )
}

export default OrganizationsTable
