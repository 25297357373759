import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './MailButton.css'

VariantsExample.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}
export default function VariantsExample({
  children,
  className,
  active,
  onClick,
}) {
  return (
    <>
      <Button
        className={`${className}${active ? ' mailboxactive fw-bolder' : ''}`}
        variant="mailbox"
        onClick={onClick}
      >
        {children}
      </Button>
    </>
  )
}
