/* eslint-disable */
import React from 'react'
import Dot from './Dot'
import { taskTimes } from './helpers'

const MAX_DESC_LEN = 20

export default function Task({ task, selectTask }) {
  const [start] = taskTimes(task)
  const title =
    (task.title ?? '').length > MAX_DESC_LEN
      ? `${(task.title ?? '').slice(0, MAX_DESC_LEN).trim()}...`
      : task.title

  const drag = e => {
    e.dataTransfer.setData('application/json', JSON.stringify(task))
  }
  const onClick = e => {
    e.stopPropagation()
    selectTask(task.id)
  }

  return (
    <div className="task" draggable={true} onDragStart={drag} onClick={onClick}>
      <Dot color={task.type === 'event' ? '#6EDFF6' : '#f8b88b'} />
      <div className="task-time">{start}</div>
      <div className="task-title">{title}</div>
    </div>
  )
}
