/* eslint-disable no-unused-vars */
import types from '../types/types'

export const setPagination = pagination => dispatch =>
  dispatch({
    type: types.MAILBOX_SET_PAGINATION,
    payload: pagination,
  })

export const setPageData = pagination => dispatch =>
  dispatch({
    type: types.MAILBOX_SET_PAGE_DATA,
    payload: pagination,
  })

export const setFolder = folder => dispatch =>
  dispatch({
    type: types.MAILBOX_SET_FOLDER,
    payload: folder,
  })

export const setOpenedThreads = openedThreads => dispatch =>
  dispatch({
    type: types.MAILBOX_SET_OPENED,
    payload: openedThreads,
  })
