import Select from 'react-select'
import { getAvatarContact } from '../../utils/stringsOperations'
import ToolTipText from '../ui-elements/ToolTipText'
import { RiMailSendFill } from 'react-icons/ri'
import { FormGroup, Button } from 'react-bootstrap'
import { isNil, isEmpty, either, propEq } from 'ramda'
import styled from 'styled-components'
import moment from 'moment'
import { calculateDateDifferenceInDays } from '../../utils/timestamp'
import ContactsApi from '../../api/contact'
import usStates from '../../assets/datasets/us-states.json'
import { formatPhoneNumber } from '../../utils/stringsOperations'
import { formatValue } from '../ui-elements/Money'
import { DATEFORMAT } from '../../utils/timestamp'
import { BsSliders, BsLaptop } from 'react-icons/bs'
import { IconContext } from 'react-icons'

const NameDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;
  margin-left: 10px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`

const AutoTypeDiv = styled.div`
  text-indent: 10px;
  width: 150px;
`
const AssignedToDiv = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`
const TextCellDiv = styled.div`
  text-indent: 10px;
`
const ManualSelectDiv = styled.div`
  width: 150px;
`
const PhoneCellDiv = styled.div`
  text-indent: 10px;
  width: 200px;
`
const LastDonationDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

const TypeDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-right: 20px;
`

const EmailDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  text-indent: 15px;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
`

const isDefined = x => !(isNil(x) || isEmpty(x) || x === 'undefined')

export const contactColumns = ({
  handleClickEmail,
  orgUsers,
  setToastHeader,
  setShowToast,
  showSendEmail = true,
}) => {
  const emailClickHandler = contact => {
    const email = contact?.email
    if (email) {
      handleClickEmail(email)
    }
  }

  const findOption = (value, options) =>
    options.find(either(propEq('value', value), propEq('label', value)))

  const assignedToOptions = orgUsers?.map(user => {
    const name = `${user.name} ${user.lastName}`
    return { label: name, value: name }
  })

  const manualTypeOptions = ['VIP', 'Declined'].map(opt => ({
    label: opt,
    value: opt,
  }))

  const stateName = x =>
    usStates.find(either(propEq('state', x), propEq('name', x)))?.name

  const getPhoneNumber = contact =>
    contact.cell_phone_number
      ? `${formatPhoneNumber(contact.cell_phone_number)} (Cell)`
      : contact.landline_phone_number
      ? `${formatPhoneNumber(contact.landline_phone_number)} (Home)`
      : contact.work_phone_direct
      ? `${formatPhoneNumber(contact.work_phone_direct)} (Business)`
      : contact.work_phone_number
      ? `${formatPhoneNumber(contact.work_phone_number)} (Business)`
      : ''

  const formatLastDonation = contact =>
    isDefined(contact.donation_date) && isDefined(contact.donation_total) ? (
      <LastDonationDiv>
        <div>{formatValue(contact.donation_total, 0, 0, true)}</div>
        <div>
          {moment(contact.donation_date).format(DATEFORMAT.DATE_FORMAT)}
        </div>
      </LastDonationDiv>
    ) : null

  const isManualType = type => type === 'VIP' || type === 'Declined'

  const toggleTypeMethod = (contact, table, index, id) => {
    let method =
      !isDefined(contact.contact_type_method) ||
      contact.contact_type_method === 'auto'
        ? 'manual'
        : 'auto'

    ContactsApi.updateContact({
      contact_person_id: contact.contact_person_id,
      body: {
        contact: {
          contact_type_method: method,
        },
      },
    })
      .then(data => {
        if (data?.acknowledged) {
          table.options.meta.updateData(index, id, method)
          table.options.meta.updateData(
            index,
            'contact_type',
            contact.contact_type
          )
        } else {
          console.log('Error setting contact type method')
        }
      })
      .catch(error => {
        console.log('Error setting contact type method ', error)
      })
  }

  const handleAssignContactType = (
    contact,
    manual_contact_type,
    table,
    index,
    id
  ) => {
    ContactsApi.updateContact({
      contact_person_id: contact.contact_person_id,
      body: {
        contact: {
          manual_contact_type,
        },
      },
    })
      .then(data => {
        if (data?.acknowledged) {
          table.options.meta.updateData(index, id, manual_contact_type)
        } else {
          console.log('Error assigning the contact type')
        }
      })
      .catch(error => {
        console.log('Error assigning the contact type', error)
      })
  }

  const handleAssignContact = (contact, assignedTo, table, index, id) => {
    ContactsApi.updateContact({
      contact_person_id: contact.contact_person_id,
      body: {
        contact: {
          assignedTo,
        },
      },
    })
      .then(data => {
        if (data?.acknowledged) {
          setToastHeader(
            assignedTo === ''
              ? 'Assignment removed'
              : `${contact.first_name} ${contact.last_name} assigned to ${assignedTo}`
          )
          setShowToast(true)
          table.options.meta.updateData(index, id, assignedTo)
        } else {
          console.log('Error assigning the contact')
        }
      })
      .catch(error => {
        console.log('Error assigning the contact ', error)
      })
  }

  const lastEmail = contact =>
    contact.last_emailed
      ? calculateDateDifferenceInDays(
          moment(contact.last_emailed).unix(),
          moment().unix()
        )
      : ''

  return [
    {
      accessorFn: row => row.first_name + ' ' + row.last_name,
      cell: ({ row }) => {
        return (
          <NameDiv>
            {getAvatarContact(row.original)}
            <span className="name" aria-label="contact-row-name">
              {row.original.first_name + ' ' + row.original.last_name}
            </span>
          </NameDiv>
        )
      },
      id: 'name',
      header: () => 'Name',
      size: 300,
      private: true,
    },
    {
      accessorFn: row => row.email,
      cell: ({ row, getValue }) => (
        <EmailDiv>
          {isDefined(getValue()) ? getValue() : ''}
          &nbsp;&nbsp;
          {isDefined(getValue()) && showSendEmail === true ? (
            <ToolTipText placement="top" description="Send Email">
              <RiMailSendFill
                color="#11273C"
                cursor="pointer"
                onClick={() => emailClickHandler(row.original)}
              />
            </ToolTipText>
          ) : null}
        </EmailDiv>
      ),
      id: 'email',
      header: () => 'Email',
      size: 250,
      private: true,
    },
    {
      accessorFn: row => row.last_email,
      cell: ({ row }) => <TextCellDiv>{lastEmail(row.original)}</TextCellDiv>,
      header: 'Last Emailed',
      size: 200,
    },
    {
      cell: ({ row }) => (
        <PhoneCellDiv>{getPhoneNumber(row.original)}</PhoneCellDiv>
      ),
      id: 'phone_number',
      header: () => 'Phone Number',
      size: 250,
      private: true,
    },
    {
      cell: ({ row }) => <TextCellDiv>{row.original.city}</TextCellDiv>,
      id: 'city',
      header: () => 'City',
      size: 250,
      private: true,
    },
    {
      accessorFn: row => row.state_abbr && stateName(row.state_abbr),
      cell: ({ getValue }) => <TextCellDiv>{getValue()}</TextCellDiv>,
      header: 'State',
      id: 'state',
      size: 200,
      private: true,
    },
    {
      cell: ({ row }) => formatLastDonation(row.original),
      header: 'Last Donation',
      id: 'last_donation',
      size: 200,
    },
    {
      accessorFn: row => row.contact_type,
      cell: ({ row: { original, index }, getValue, column: { id }, table }) => (
        <TypeDiv>
          {!isDefined(original.contact_type_method) ||
          original.contact_type_method === 'auto' ? (
            <AutoTypeDiv>{getValue()}</AutoTypeDiv>
          ) : (
            <ManualSelectDiv>
              <Select
                value={
                  isManualType(original.manual_contact_type)
                    ? findOption(
                        original.manual_contact_type,
                        manualTypeOptions
                      )
                    : findOption('VIP', manualTypeOptions)
                }
                options={manualTypeOptions}
                placeholder="Select..."
                onChange={o => {
                  handleAssignContactType(
                    original,
                    o.value,
                    table,
                    index,
                    'manual_contact_type'
                  )
                }}
              />
            </ManualSelectDiv>
          )}
          <ToolTipText
            placement="top"
            description={
              !isDefined(original.contact_type_method) ||
              original.contact_type_method === 'auto'
                ? 'Click to override the value set by the system'
                : 'Remove manual override'
            }
            className="me-2 my-auto bg-outline-success"
          >
            <IconContext.Provider value={{ className: 'text-success' }}>
              <div
                data-tip
                data-for="override"
                onClick={() =>
                  toggleTypeMethod(
                    original,
                    table,
                    index,
                    'contact_type_method'
                  )
                }
                aria-label="go-to-contact-details-button"
              >
                {!isDefined(original.contact_type_method) ||
                original.contact_type_method === 'auto' ? (
                  <BsLaptop />
                ) : (
                  <BsSliders />
                )}
              </div>
            </IconContext.Provider>
          </ToolTipText>
        </TypeDiv>
      ),
      header: 'Type',
      id: 'contact_type',
      size: 250,
    },
    {
      accessorFn: row => row.assignedTo,
      cell: ({ getValue, row: { original, index }, column: { id }, table }) => {
        const selection = findOption(getValue(), assignedToOptions)
        return (
          <AssignedToDiv>
            <FormGroup>
              <Select
                value={selection}
                options={assignedToOptions}
                placeholder="Select..."
                onChange={o => {
                  handleAssignContact(original, o.value, table, index, id)
                }}
              />
            </FormGroup>
          </AssignedToDiv>
        )
      },
      header: 'Assigned To',
      id: 'assignedTo',
      size: 300,
    },
  ]
}

export default contactColumns
