import styled from 'styled-components'
import { BsPencil, BsFilter } from 'react-icons/bs'
import { Button } from 'react-bootstrap'
import { FC } from 'react'

const StyledButton = styled(Button)`
  height: 2.375rem;
  min-width: 10.125rem;
  padding: 0.375rem 0.75rem;
  > span {
    font-family: DM Sans;
    font-size: 1rem;
    font-weight: ${props => props.fw || 400};
    line-height: 1.5rem;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
  }
  > svg {
    padding-bottom: 3px;
    padding-right: 3px;
  }
`

interface ActionButton {
  action: string
  handleClick: () => void
  variant?: string
  children?: JSX.Element | JSX.Element[]
  type?: string
  weight?: number
}
const ActionButton: FC<ActionButton> = ({
  action,
  handleClick,
  variant = 'secondary',
  children,
  type,
  weight,
}) => {
  const handleIcon = () => {
    switch (type) {
      case `filter`:
        return <BsFilter size={24} />
      case 'no-icon':
        return null
      default:
        return <BsPencil size={16} />
    }
  }
  return (
    <StyledButton onClick={handleClick} variant={variant} fw={weight || 400}>
      {handleIcon()} <span>{action}</span>
      {children}
    </StyledButton>
  )
}

export default ActionButton
