import { always, ifElse, prop } from 'ramda'
import PropTypes from 'prop-types'
import { keyframes, css } from 'styled-components'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { Stack } from 'react-bootstrap'
import companyLogo from '../../assets/logos/logo.svg'
import {
  BsFillArrowRightCircleFill,
  BsBuilding,
  BsPersonCheck,
  BsPeople,
  BsHouseDoor,
} from 'react-icons/bs'

const enter = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`

const update = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`

const MenuLeftHandleBarFill = styled.div`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 6rem;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  border: none;
  background-color: #11273c;
  color: #ffffff;
  border-radius: ${ifElse(
    prop('isMenuVisible'),
    always('0px'),
    always('0px 2px 2px 0px')
  )};
  &:hover {
    cursor: pointer;
  }
`
MenuLeftHandleBarFill.propTypes = {
  isMenuVisible: PropTypes.bool,
}

const TopIconWrapper = styled.div`
  display: inline-block;
  margin: 0;
  padding: 0;
  padding-top: 30px;
  padding-bottom: 20px;
  img {
    height: 28px;
    padding-left: 5px;
  }
  animation: ${({ toggle }) =>
    toggle
      ? css`
          ${update} 0.7s ease-out forwards
        `
      : css`
          ${enter} 0.7s ease-out forwards
        `};
`
const IconWrapper = styled.div`
  padding: 5px 8px 9px 8px;
  border-radius: 5px;
  background-color: ${ifElse(
    prop('isActive'),
    always('#233c50'),
    always('#11273c')
  )};
  margin: 0 auto;
  svg {
    color: ${ifElse(prop('isActive'), always('white'), always('#7e919f'))};
  }
  animation: ${({ toggle }) =>
    toggle
      ? css`
          ${update} 0.7s ease-out forwards
        `
      : css`
          ${enter} 0.7s ease-out forwards
        `};
`
IconWrapper.propTypes = {
  isActive: PropTypes.bool,
}

const ArrowIconWrapper = styled.div`
  padding-top: 24px;
  padding-left: 15px;
  display: inline-block;
  vertical-align: top;
  color: white;
  svg {
    filter: drop-shadow(1px 1px 1px #7e919f);
  }
  animation: ${({ toggle }) =>
    toggle
      ? css`
          ${update} 0.7s ease-out forwards
        `
      : css`
          ${enter} 0.7s ease-out forwards
        `};
`

const StyledLink = styled(Link)`
  text-decoration: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
  color: #7e919f;
`

const MenuLeftHandleBarAdmin = ({ isMenuVisible, onClick }) => {
  let { pathname } = useLocation()

  return (
    <>
      <MenuLeftHandleBarFill isMenuVisible={isMenuVisible}>
        <Stack direction="horizontal">
          <Stack gap={1} style={{ paddingTop: '0px', paddingLeft: '30px' }}>
            <StyledLink to="/">
              <TopIconWrapper toggle={isMenuVisible}>
                <img src={companyLogo} />
              </TopIconWrapper>
            </StyledLink>

            <StyledLink to="/">
              <IconWrapper toggle={isMenuVisible} isActive={pathname === '/'}>
                <BsHouseDoor />
              </IconWrapper>
            </StyledLink>

            <StyledLink to="/admin/organizations">
              <IconWrapper
                toggle={isMenuVisible}
                isActive={pathname === '/admin/organizations'}
              >
                <BsBuilding />
              </IconWrapper>
            </StyledLink>

            <StyledLink to="/admin/staffers">
              <IconWrapper
                toggle={isMenuVisible}
                isActive={pathname === '/admin/staffers'}
              >
                <BsPersonCheck />
              </IconWrapper>
            </StyledLink>

            <StyledLink to="/admin/bundlers">
              <IconWrapper
                toggle={isMenuVisible}
                isActive={pathname === '/admin/bundlers'}
              >
                <BsPeople />
              </IconWrapper>
            </StyledLink>
          </Stack>

          <Stack>
            <ArrowIconWrapper toggle={isMenuVisible}>
              <BsFillArrowRightCircleFill onClick={onClick} size={24} />
            </ArrowIconWrapper>
          </Stack>
        </Stack>
      </MenuLeftHandleBarFill>
    </>
  )
}

MenuLeftHandleBarAdmin.propTypes = {
  isMenuVisible: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
}

export default MenuLeftHandleBarAdmin
