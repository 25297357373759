import React from 'react'
import CustomDotsDropdown from '../ui-elements/CustomDotsDropdown'
import MenuItem from '../ui-elements/MenuItem'
import { Stack } from 'react-bootstrap'
import styled from 'styled-components'
import { BsShare, BsPlus, BsPencil } from 'react-icons/bs'
import PropTypes from 'prop-types'
import { getUserRole } from '../../utils/state/selectors'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

const StyledStack = styled(Stack)`
  padding-right: 6rem;
`

const EventDropdown = ({ onShareEvent, onAddPledge, editEvent }) => {
  const [searchParams] = useSearchParams()

  const userRole = useSelector(getUserRole)
  return (
    <CustomDotsDropdown>
      <MenuItem handleOnClick={onShareEvent}>
        <StyledStack gap={2} direction="horizontal">
          <BsShare color={'var(--bs-secondary)'} />
          <span>Share event invite</span>
        </StyledStack>
      </MenuItem>
      {userRole === 'staffer' ? (
        <MenuItem handleOnClick={editEvent}>
          <StyledStack gap={2} direction="horizontal">
            <BsPencil size={16} color={'var(--bs-secondary)'} />
            <span>Edit Event</span>
          </StyledStack>
        </MenuItem>
      ) : null}
      {userRole === 'bundler' && !(searchParams.get('isOnTiers') === 'true') ? (
        <MenuItem handleOnClick={onAddPledge}>
          <StyledStack gap={2} direction="horizontal">
            <BsPlus color={'var(--bs-secondary)'} />
            <span>Add Pledge</span>
          </StyledStack>
        </MenuItem>
      ) : null}
    </CustomDotsDropdown>
  )
}

EventDropdown.propTypes = {
  onShareEvent: PropTypes.func,
  onAddPledge: PropTypes.func,
  editEvent: PropTypes.func,
}

export default EventDropdown
