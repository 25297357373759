import constants from '../../config/constants'
import axiosInstance from '../api_instance'
const API_URL = 'bcalendar'

/**
 * Return all events.
 *
 * @param {number} limit The number of objects to return. Defaults to 100. If set too high, requests may fail to prevent excessively large response bodies.
 * @param {number} offset Zero-based offset from default object sorting. See pagination for more information.
 * @param {string} folder Used to filter threads, based on folder value.
 * @return {Promise} Represent an array of events.
 */
export const getBEvents = () => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance
    .get(`${API_URL}/events`, { baseURL })
    .then(data => data.data)
}

export const filterBEvents = (token, body = {}) => {
  const baseURL = `${constants.BASE_ENDPOINT()}/api/benjamin`
  return axiosInstance
    .post(`${API_URL}/events/filter`, body, {
      baseURL,
      headers: { token },
    })
    .then(data => data.data)
}
