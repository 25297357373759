import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledDiv = styled.div`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  .initials {
    font-weight: bold;
    text-transform: uppercase;
  }
`

const ImageInitials = ({ initials, size, sizeUnit, bgColor, fColor }) => {
  const fSize = size / 2

  return (
    <StyledDiv
      style={{
        minWidth: `${size}${sizeUnit}`,
        minHeight: `${size}${sizeUnit}`,
        backgroundColor: bgColor,
      }}
    >
      <span
        className="initials"
        style={{ color: fColor, fontSize: `${fSize}${sizeUnit}` }}
      >
        {initials}
      </span>
    </StyledDiv>
  )
}

ImageInitials.propTypes = {
  initials: PropTypes.string,
  size: PropTypes.string,
  sizeUnit: PropTypes.string,
  bgColor: PropTypes.string,
  fColor: PropTypes.string,
}

export default ImageInitials
