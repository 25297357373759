import React from 'react'
import { Table } from 'react-bootstrap'
import PropTypes from 'prop-types'

const FtpUploadTable = ({ data }) => {
  return (
    <>
      {data ? (
        <Table responsive>
          <thead>
            <tr>
              {Object.keys(data[0]).map((head, i) => (
                <th key={`thead${head}-${i}`}>{head}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((objData, i) => {
              return (
                <tr key={`trbody-${i}`}>
                  {Object.values(objData).map((body, i) => (
                    <th key={`tbody${body}-${i}`}>{body}</th>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </Table>
      ) : (
        <p>no results</p>
      )}
    </>
  )
}

export default FtpUploadTable

FtpUploadTable.propTypes = {
  data: PropTypes.array.isRequired,
}
