import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button, Form, Modal, FormLabel, ModalHeader } from 'react-bootstrap'
import { pathOr } from 'ramda'
import ToastWarningFields from './ToastWarningFields'

const RedAst = () => <span style={{ color: 'red' }}>*</span>

const FieldsWrapper = styled.div`
  label {
    display: block;
  }
`

const StyledFormLabel = styled(FormLabel)`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.313rem;
  letter-spacing: 0em;
  text-align: left;
`

const GuestFormModal = ({ show, onAdd, onEdit, onClose, guest, isEdit }) => {
  const [guestId] = useState(isEdit ? guest._id : undefined)
  const [guestName, setGuestName] = useState(isEdit ? guest.guestName : '')
  const [showToast, setShowToast] = useState(false)

  const clear = () => {
    setGuestName('')
  }

  const reset = () => {
    setGuestName(isEdit ? guest.guestName : '')
  }

  const changeHandler = setter => e =>
    setter(pathOr('', ['target', 'value'], e))

  const addGuestHandler = () => {
    if (guestName != '') {
      onAdd({ guestName })
      clear()
      onClose()
    } else {
      setShowToast(true)
    }
  }

  const editGuestHandler = () => {
    if (guestName != '') {
      onEdit({ _id: guestId, guestName })
      clear()
      onClose()
    } else {
      setShowToast(true)
    }
  }

  const actionButtonHandler = isEdit ? editGuestHandler : addGuestHandler

  const cancelHandler = () => {
    clear()
    setShowToast(false)
    onClose()
  }

  useEffect(() => {
    if (isEdit && guest) reset()
  }, [isEdit, guest])

  return (
    <Modal show={show} onHide={cancelHandler}>
      <ModalHeader closeButton>
        {isEdit ? 'Edit special guest' : 'New special guest'}
      </ModalHeader>
      <Modal.Body>
        <ToastWarningFields show={showToast} setShow={setShowToast} />
        <FieldsWrapper>
          <Form.Group className="mb-2">
            <StyledFormLabel htmlFor="description">
              Full Name <RedAst />
            </StyledFormLabel>
            <Form.Control
              autoComplete="off"
              type="text"
              name="guestName"
              onChange={changeHandler(setGuestName)}
              value={guestName}
              placeholder=""
            />
          </Form.Group>
        </FieldsWrapper>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" variant="light" onClick={cancelHandler}>
          Cancel
        </Button>
        <Button type="button" variant="secondary" onClick={actionButtonHandler}>
          {isEdit ? 'Save Special Guest' : 'Add Special Guest'}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
GuestFormModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  guest: PropTypes.object,
  isEdit: PropTypes.bool,
}

export default GuestFormModal
