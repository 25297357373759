import { Outlet } from 'react-router-dom'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 100vw;
  height: 100vh;
  background-color: #d5d8e6;
  @media (max-width: 430px) {
    background-color: #212529;
  }
`

const Contents = styled.div``

const LoginLayout = () => (
  <Wrapper>
    <Contents>
      <Outlet />
    </Contents>
  </Wrapper>
)

export default LoginLayout
