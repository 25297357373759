import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { byDate, monthMondays } from './helpers/tasks-utils'
import Task from './Task'
import { compose, take, isToday } from './util'

const ISO = {
  days: [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ],
}

const Header = () => {
  const daynameComponent = compose(
    s => <Dayname key={s} dayname={s} />,
    take(3)
  )

  return <div className="header">{ISO.days.map(daynameComponent)}</div>
}

function Dayname({ dayname }) {
  return <div className="dayname">{dayname}</div>
}

Dayname.propTypes = {
  dayname: PropTypes.string,
}

const Week = ({ monday, tasks, updateTask, selectTask, showPanel }) => {
  const days = Array(7)
    .fill(null)
    .map((_, i) => i + 1)
    .map(i => moment(monday).isoWeekday(i))
    .map(d => (
      <Day
        key={d.toString()}
        date={d}
        tasks={byDate(d)(tasks)}
        selectTask={selectTask}
        updateTask={updateTask}
        showPanel={showPanel}
      />
    ))

  return (
    <>
      <div className="week">{days}</div>
    </>
  )
}
Week.propTypes = {
  monday: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tasks: PropTypes.arrayOf(PropTypes.object),
  updateTask: PropTypes.func,
  selectTask: PropTypes.func,
  showPanel: PropTypes.func,
}

const ShowMoreToggleWrap = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 1rem 0.25rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: #06988f;
  &:hover {
    cursor: pointer;
  }
`

const ShowMoreToggle = ({ isOpen, onClick, children }) => {
  const toggleText = isOpen ? 'Show less' : 'Show more'
  const ToggleIcon = () => (isOpen ? <BsChevronLeft /> : <BsChevronRight />)
  return (
    <>
      <ShowMoreToggleWrap onClick={onClick}>
        <ToggleIcon />
        &nbsp;{toggleText}
      </ShowMoreToggleWrap>
      {isOpen && children}
    </>
  )
}
ShowMoreToggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
}

const Day = ({ date, tasks, updateTask, selectTask, showPanel }) => {
  const [dragover, setDragover] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const shownTasks = tasks.slice(0, 2)
  const hiddenTasks = tasks.slice(2)
  const isLargeList = hiddenTasks.length > 0
  const m = moment(date)
  const day = m.date()
  const classes = `${isToday(date) ? 'today' : ''} ${
    dragover ? 'drag-over' : ''
  }`
  // const click = () => {
  //   const m = moment(date)
  //   showPanel(m.year(), m.month(), m.date())
  // }
  const dragEnter = e => {
    setDragover(true)
    e.preventDefault()
    e.stopPropagation()
  }
  const dragLeave = e => {
    setDragover(false)
    e.preventDefault()
    e.stopPropagation()
  }
  const drop = e => {
    const json = e.dataTransfer.getData('application/json')
    const dropTask = JSON.parse(json)
    const d = moment(dropTask.date)
    d.year(date.year())
    d.month(date.month())
    d.date(date.date())
    const t = { ...dropTask, date: d }
    updateTask(t)
    setDragover(false)
    e.preventDefault()
    e.stopPropagation()
  }

  const onClick = () => {
    console.log(showPanel)
    // const m = moment(date)
    // console.log(m.year(), m.month() + 1, m.date())
    // showPanel(m.year(), m.month(), m.date(), true)
  }

  return (
    <div
      className={`day ${classes}`}
      onDragOver={dragEnter}
      onDragLeave={dragLeave}
      onDrop={drop}
      onClick={onClick}
    >
      <div className="name text-center small font-weight-bold">
        <div>{day}</div>
      </div>
      <div className="tasks">
        {shownTasks.map(t => (
          <Task
            key={`calendar-task-${t.id}`}
            task={t}
            selectTask={selectTask}
          />
        ))}
        {isLargeList && (
          <ShowMoreToggle
            isOpen={showMore}
            onClick={() => setShowMore(b => !b)}
          >
            {hiddenTasks.map(t => (
              <Task
                key={`calendar-task-${t.id}`}
                task={t}
                selectTask={selectTask}
              />
            ))}
          </ShowMoreToggle>
        )}
      </div>
    </div>
  )
}
Day.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tasks: PropTypes.arrayOf(PropTypes.object),
  updateTask: PropTypes.func,
  selectTask: PropTypes.func,
  showPanel: PropTypes.func,
}

const Calendar = ({ date, tasks, updateTask, selectTask, showPanel }) => {
  const mondays = monthMondays(date)

  return (
    <div className="calendar">
      <Header />
      {mondays.map(monday => (
        <Week
          key={`week-${monday.toString()}`}
          monday={monday}
          tasks={tasks}
          updateTask={updateTask}
          selectTask={selectTask}
          showPanel={showPanel}
        />
      ))}
    </div>
  )
}
Calendar.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  tasks: PropTypes.arrayOf(PropTypes.object),
  updateTask: PropTypes.func,
  selectTask: PropTypes.func,
  showPanel: PropTypes.func,
}

export default Calendar
