import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledButton = styled.div`
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  border-style: solid;
  border-width: 0.5px;
  border-color: #00aea4;
  border-radius: 25px;
  max-height: 2rem;
  max-width: 8rem !important;
  background-color: ${props => (props.isActive ? '#00aea4' : 'transparent')};
  color: ${props => (props.isActive ? 'white' : 'black')};
  gap: 0.5rem;
  &:hover {
    cursor: pointer;
  }
  & > p {
    margin: 0;
    white-space: nowrap;
  }
`

const ReplyForwardButton = ({
  title,
  icon: Icon,
  updateReplyForward,
  isActive,
}) => {
  return (
    <StyledButton onClick={updateReplyForward} isActive={isActive}>
      <Icon />
      <p>{title}</p>
    </StyledButton>
  )
}

ReplyForwardButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  updateReplyForward: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
}

export default ReplyForwardButton
