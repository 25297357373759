import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import moment from 'moment'
import {
  // getAttendeesCount,
  getDatetime,
  getFinancialGoal,
  getLocation,
  getTime,
  // getTotalDeposited,
  // getTotalExpected,
  // getTotalPledged,
  // getTotalRemaining,
} from '../../utils/dataModels/events'
import { statusOptions } from '../PipelineCRM/PipelineFilter'
// import { MdRsvp } from 'react-icons/md'
import rsvpIcon from '../../assets/icons/rsvp.svg'
import eventLinkIcon from '../../assets/icons/event_link.svg'
import checkInIcon from '../../assets/icons/checkin.svg'
import paperIcon from '../../assets/icons/paper.svg'
import nameTagsIcon from '../../assets/icons/nametags.svg'
import thankYouIcon from '../../assets/icons/thankyous.svg'
import { getWinRedUrl } from './utils'
// import { LinkSpan } from '../ContactUploadFilePage/ContactUploadFilePage/styles'
import { useSelector } from 'react-redux'
import { decryptData } from '../../utils/crypto/crypto'
import { getUser, getUserEmail } from '../../utils/state/selectors'

const EventContainer = styled.div`
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 3px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  min-width: 200px;
  height: auto;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const EventHeader = styled.div`
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
`

const EventSubHeader = styled.div`
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.23px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
`

const EventDetailsContainer = ({
  event,
  eventCode,
  consolidated,
  invite,
  handleEventDetailsFilter,
  handleCheckIn,
}) => {
  const moneyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  const user = useSelector(getUser) || decryptData(getUserEmail)
  const [guestStatus, setGuestStatus] = useState('')
  const [winRedUrl, setWinRedUrl] = useState(null)
  useEffect(() => {
    getWinRedUrl(event?.organizationId, event?.name, user).then(url => {
      setWinRedUrl(url)
    })
  }, [event])

  const specialGuests =
    consolidated && consolidated.attendees
      ? consolidated.attendees.guests.map(g => g.guestName.trim()).join(', ')
      : null

  const totalAttendeeCount = event
    ? event.tiers.reduce((tieredGuestsCount, currentTier) => {
        return tieredGuestsCount + currentTier.participants.length
      }, 0) + event.donations.filter(d => d.donor).length
    : 0

  const totalPledged = event
    ? event.donations
        .filter(
          d =>
            d.donation_type === 'Pledge' && typeof d.donation_total === 'number'
        )
        .reduce((acc, curr) => acc + curr.donation_total, 0)
    : 0

  const totalDeposited = event
    ? event.donations
        .filter(
          d =>
            d.donation_type === 'Deposit' &&
            typeof d.donation_total === 'number'
        )
        .reduce((acc, curr) => acc + curr.donation_total, 0)
    : 0

  return (
    <>
      <EventContainer>
        <EventHeader>{eventCode.slice(0, 15)}</EventHeader>
        <EventHeader>{`Goal: ${moneyFormatter.format(
          getFinancialGoal(event)
        )}`}</EventHeader>
        <Row>
          <Col xs={4}>
            <EventSubHeader style={{ marginBottom: '6px' }}>
              Paid
            </EventSubHeader>
            <EventSubHeader style={{ fontWeight: 700 }}>
              {moneyFormatter.format(totalDeposited)}
            </EventSubHeader>
          </Col>
          <Col xs={4}>
            <EventSubHeader style={{ marginBottom: '6px' }}>
              Attendees
            </EventSubHeader>
            <EventSubHeader style={{ fontWeight: 700 }}>
              {totalAttendeeCount}
            </EventSubHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <EventSubHeader style={{ marginBottom: '6px' }}>
              Pledged
            </EventSubHeader>
            <EventSubHeader style={{ fontWeight: 700 }}>
              {moneyFormatter.format(totalPledged)}
            </EventSubHeader>
          </Col>
          <Col xs={6}>
            <EventSubHeader style={{ marginBottom: '6px' }}>
              Total Expect
            </EventSubHeader>
            <EventSubHeader style={{ fontWeight: 700 }}>
              {moneyFormatter.format(totalDeposited + totalPledged)}
            </EventSubHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <EventSubHeader>Date:</EventSubHeader>
          </Col>
          <Col xs={5}>
            <EventSubHeader>
              {moment(getDatetime(event)).format('MMM D YYYY')}
            </EventSubHeader>
          </Col>
          <Col xs={2}>
            <EventSubHeader>{getTime(event)}</EventSubHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <EventSubHeader>Location:</EventSubHeader>
          </Col>
          <Col xs={8}>
            <EventSubHeader>{getLocation(event)}</EventSubHeader>
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <EventSubHeader>Surrogate:</EventSubHeader>
          </Col>
          <Col xs={8}>
            <EventSubHeader>{specialGuests}</EventSubHeader>
          </Col>
        </Row>
        <div>
          <EventSubHeader>Status</EventSubHeader>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              columnGap: '8px',
              gridTemplateRows: 'auto',
              rowGap: '5px',
              marginTop: '4px',
            }}
          >
            {statusOptions
              .filter(status => status.label !== 'Completed')
              .map((status, idx) => (
                <div
                  key={`status-${idx}`}
                  style={{
                    backgroundColor:
                      guestStatus !== '' && guestStatus !== status.label
                        ? `${status.color}40`
                        : status.color,
                    color:
                      guestStatus !== '' && guestStatus !== status.label
                        ? '#11273C40'
                        : '#11273C',
                    // backgroundColor: status.color,
                    // color: '#11273C',
                  }}
                  className="toggle-filter-button"
                  onClick={e => {
                    setGuestStatus(
                      guestStatus === status.label ? '' : status.label
                    )
                    handleEventDetailsFilter(
                      guestStatus === status.label ? '' : status.label
                    )
                  }}
                >
                  {status.label}
                </div>
              ))}
          </div>
        </div>
        <Row>
          <Col className="event-icon-button" xs={4} onClick={invite}>
            <img src={rsvpIcon} />
            {/* <div
              style={{
                position: 'relative',
                left: '16px',
                bottom: '50px',
              }}
            >
              <MdRsvp size={26} />
            </div> */}
            <EventSubHeader style={{ fontWeight: 700 }}>Invite</EventSubHeader>
          </Col>
          <Col
            className="event-icon-button"
            xs={4}
            onClick={() => window.open(winRedUrl, '_blanl', 'noreferrer')}
          >
            <img
              src={eventLinkIcon}
              style={{
                paddingTop: '14px',
                marginBottom: '13px',
              }}
            />
            <EventSubHeader style={{ fontWeight: 700 }}>Link</EventSubHeader>
          </Col>
          <Col className="event-icon-button" xs={4} onClick={handleCheckIn}>
            <img src={checkInIcon} style={{ marginBottom: '1px' }} />
            <EventSubHeader style={{ fontWeight: 700 }}>
              Check In
            </EventSubHeader>
          </Col>
        </Row>
        {/* <Row>
          <Col className="event-icon-button" xs={4}>
            <img
              src={paperIcon}
              style={{
                paddingTop: '6px',
                marginBottom: '8px',
              }}
            />
            <EventSubHeader
              style={{
                fontWeight: 700,
                textAlign: 'center',
              }}
            >
              Briefing Paper
            </EventSubHeader>
          </Col>
          <Col
            className="event-icon-button"
            style={{
              position: 'relative',
              bottom: '1px',
            }}
            xs={4}
          >
            <img src={nameTagsIcon} />
            <EventSubHeader
              style={{
                fontWeight: 700,
                textAlign: 'center',
              }}
            >
              Name Tags
            </EventSubHeader>
          </Col>
          <Col
            className="event-icon-button"
            style={{
              position: 'relative',
              bottom: '8px',
            }}
            xs={4}
          >
            <img src={thankYouIcon} />
            <EventSubHeader
              style={{
                fontWeight: 700,
                textAlign: 'center',
                position: 'relative',
                bottom: '2px',
              }}
            >
              Thank You Notes
            </EventSubHeader>
          </Col>
        </Row> */}
      </EventContainer>
    </>
  )
}

EventDetailsContainer.propTypes = {
  event: PropTypes.object,
  eventCode: PropTypes.string,
  consolidated: PropTypes.object,
  invite: PropTypes.func,
  handleEventDetailsFilter: PropTypes.func,
  handleCheckIn: PropTypes.func,
}

export default EventDetailsContainer
