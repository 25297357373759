//import { LogLevel } from '@azure/msal-common'
import constants from './constants'

export const msalConfig = {
  auth: {
    clientId: constants.AZURE_CLIENT_ID(),
    authority: constants.AZURE_AUTHORITY(),
    redirectUri: constants.AZURE_REDIRECT_URI(),
  },
  cache: {
    cacheLocation: 'sessionStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    /* loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message)
            return
          case LogLevel.Info:
            console.info(message)
            return
          case LogLevel.Verbose:
            console.debug(message)
            return
          case LogLevel.Warning:
            console.warn(message)
            return
          default:
            return
        }
      },
    }, */
  },
}
