import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Stack, FormCheck } from 'react-bootstrap'

const TaskTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
`

const TaskDescription = styled.span`
  font-size: 12px;
  color: ${props => props.color};
`

const TaskCheckText = ({ title, description, check }) => {
  return (
    <Stack direction="horizontal" gap={2}>
      <div>
        <FormCheck checked={check} inline type="checkbox" readOnly />
      </div>
      <div>
        <Stack>
          <TaskTitle>{title}</TaskTitle>
          <TaskDescription>{description}</TaskDescription>
        </Stack>
      </div>
    </Stack>
  )
}

TaskCheckText.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  check: PropTypes.bool,
}

export default TaskCheckText
