import TasksCalendar from '../ui-elements/TasksCalendar/TasksCalendar'
import EventsApi from '../../api/event'
import { filter, flatten, map, pipe } from 'ramda'
import moment from 'moment'
import { getDatetime } from '../../utils/dataModels/events'
import { eventToTask } from '../../utils/dataModels/eventToTask'
import { filterBEvents } from '../../api/nylas/bcalendar'
import { useState } from 'react'

const isBetween = (from, to, item) => date => {
  return (
    item?.type === 'providerEvent' ||
    moment(date).isBetween(from, to, undefined, '[]')
  )
}

const onReadTasks =
  setIsLoading => (from, to, organizationId, _, userToken, callback) => {
    setIsLoading(true)
    const eventPromises = [EventsApi.getEvents(organizationId)]
    if (userToken) {
      eventPromises.push(
        filterBEvents(userToken, {
          query: {
            from: moment(new Date(from)).format('YYYY-MM-DD'),
            to: moment(new Date(to)).format('YYYY-MM-DD'),
          },
        })
      )
    }

    return Promise.all(eventPromises)
      .then(pipe(flatten))
      .then(filter(item => isBetween(from, to, item)(getDatetime(item))))
      .then(map(eventToTask))
      .then(callback)
      .catch(error => console.log(error))
      .finally(() => setIsLoading(false))
  }

const Calendar = () => {
  const [isLoading, setIsLoading] = useState(false)
  return (
    <>
      {isLoading && (
        <center>
          <span className="text-dark">Syncing...</span>
        </center>
      )}
      <TasksCalendar
        onCreateTask={() => null}
        onReadTasks={onReadTasks(setIsLoading)}
        onUpdateTask={() => null}
        onDeleteTask={() => null}
      />
    </>
  )
}

export default Calendar
