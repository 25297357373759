import React, { useState, useMemo } from 'react'
import { Button, Container, Col, Modal, Row } from 'react-bootstrap'
import { BsSearch, BsPersonPlus } from 'react-icons/bs'
import PropTypes from 'prop-types'
import DonationsApi from '../../api/donation'
import ContactsApi from '../../api/contact'
import { useDispatch, useSelector } from 'react-redux'
import {
  getUser,
  getUserOrganizationId,
  getQueueContactsPageCount,
  getQueueContactsPage,
  getQueueContactsPageSize,
} from '../../utils/state/selectors'
import {
  initialQueryState,
  FilterContactsFormJS,
} from '../MailBoxPage/ModalFilterContacts/ModalFilterContactsJS'
import Money from '../ui-elements/Money'
import CRMTable from './CRMTable'
import IndeterminateCheckbox from './IndeterminateCheckbox'
import USStates from '../../assets/datasets/us-states.json'
import {
  setQueueContactPage,
  setQueueContactPageSize,
} from '../../redux/pipeline/pipeline'
import { omit } from 'ramda'
import { keepPreviousData, useQuery } from '@tanstack/react-query'

const initialAddContactsModalFilter = {
  ...initialQueryState,
  donation_capacity: ['', ''],
  available_capacity: ['', ''],
  current_donations_total: '',
}
const contactsColumns = [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
        }}
      />
    ),
    cell: ({ row }) => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
    size: 35,
  },
  {
    accessorFn: row => row.first_name + ' ' + row.last_name,
    header: 'NAME',
    id: 'name',
    cell: ({ getValue }) => {
      return <div className="pipeline-name-cell">{getValue()}</div>
    },
    size: 200,
    footer: props => props.column.id,
  },
  {
    accessorKey: 'donation_capacity',
    header: 'ASK/CAPACITY',
    id: 'capacity',
    cell: ({ getValue }) => (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {getValue() ? <Money value={getValue()} /> : null}
      </div>
    ),
  },
  {
    accessorFn: row => row.email,
    header: 'EMAIL',
    id: 'email',
    cell: ({ getValue }) => {
      return <div>{getValue()}</div>
    },
    size: 200,
    footer: props => props.column.id,
  },
]

const ContactsTable = React.memo(props => {
  const {
    loadingContacts,
    filterContacts,
    columnFilters,
    setColumnFilters,
    rowSelection,
    setRowSelection,
    sorting,
    setSorting,
    pagination,
    setPagination,
    currentPage,
    setCurrentPage,
    pageCount,
    pageSize,
    setPageSize,
  } = props

  const tableProps = {
    columns: contactsColumns,
    data: filterContacts,
    loadingData: loadingContacts,
    columnFilters,
    setColumnFilters,
    rowSelection,
    setRowSelection,
    sorting,
    setSorting,
    pagination,
    setPagination,
    showGoToPage: false,
    showPageSize: false,
    currentPage,
    setCurrentPage,
    pageCount,
    pageSize,
    setPageSize,
  }
  return <CRMTable {...tableProps} />
})

ContactsTable.displayName = `ContactsTable`

ContactsTable.propTypes = {
  contacts: PropTypes.array,
  loadingContacts: PropTypes.bool,
  filterContacts: PropTypes.array,
  columnFilters: PropTypes.array,
  setColumnFilters: PropTypes.func,
  rowSelection: PropTypes.object,
  setRowSelection: PropTypes.func,
  sorting: PropTypes.array,
  setSorting: PropTypes.func,
  pagination: PropTypes.object,
  setPagination: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
}

const AddContactsModal = props => {
  // TO-DO filter contacts shown by items already in the pipeline
  const [contacts, setContacts] = React.useState([])
  const [filterContacts, setFilterContacts] = React.useState([])
  const [allContacts, setAllContacts] = React.useState([])
  const [queryFilter, setQueryFilter] = React.useState(
    initialAddContactsModalFilter
  )

  const [contactsTableFilters, setContactsTableFilters] = useState([])
  const [contactsTableRowSelection, setContactsTableRowSelection] = useState({})
  const [contactsTableSorting, setContactsTableSorting] = useState([])
  const [contactsTablePagination, setContactsTablePagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  })

  const dispatch = useDispatch()
  const user = useSelector(getUser)
  const orgId = useSelector(getUserOrganizationId)
  const pageCount = useSelector(getQueueContactsPageCount)
  const pageSize = useSelector(getQueueContactsPageSize)
  const currentPage = useSelector(getQueueContactsPage)

  const noEmptyValues = obj =>
    Object.fromEntries(
      Object.entries(obj).filter(([, v]) => {
        if (Array.isArray(v)) {
          return v.every(i => i != '')
        } else {
          return v != null && v != '' && v != undefined
        }
      })
    )

  const handleSetCurrentPage = page => {
    dispatch(setQueueContactPage(page))
    setContactsTableRowSelection({})
  }
  const handleSetPageSize = size => {
    dispatch(setQueueContactPage(1))
    dispatch(setQueueContactPageSize(size))
  }

  const resetFilter = () => {
    setQueryFilter({
      ...initialAddContactsModalFilter,
      count: queryFilter.count ? queryFilter.count + 1 : 1,
    })
    setFilterContacts(contacts)
    setContactsTableRowSelection({})
    setContactsTableFilters([])
    setContactsTableSorting([])
    setContactsTablePagination({
      pageIndex: 0,
      pageSize: 50,
    })
    dispatch(setQueueContactPage(1))
  }

  const contactsQuery = useQuery({
    queryKey: ['queueContacts', currentPage, queryFilter, orgId, user],
    queryFn: () => {
      let filters = {}
      if (Object.values(queryFilter)) {
        const valueOnlyFilter = noEmptyValues(queryFilter)
        const { event, donation, count, ...rest } = valueOnlyFilter
        const state_abbr = USStates.find(
          stateObj => stateObj.name === rest['state']
        )
          ? USStates.find(stateObj => stateObj.name === rest['state']).state
          : undefined
        filters = noEmptyValues({
          ...omit(['state'], rest),
          state_abbr,
        })
      }
      const offset = currentPage === 1 ? 0 : (currentPage - 1) * pageSize
      return Object.entries(filters).length
        ? ContactsApi.getContacts(
            user,
            false,
            orgId,
            true,
            offset,
            pageSize,
            filters
          )
        : ContactsApi.getQueueContacts(orgId, offset, pageSize)
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
    enabled: !!(orgId && user),
  })
  const contactRecords = useMemo(() => {
    const { isPending, isError, error, data, isFetching, isPlaceholderData } =
      contactsQuery
    if (data) {
      return {
        ...data,
        pageCount: Math.ceil(data.count / pageSize),
        isPending,
        isError,
        error,
        isFetching,
        isPlaceholderData,
      }
    } else {
      return {
        records: [],
        count: 0,
        pageCount: 0,
        isPending,
        isError,
        error,
        isFetching,
        isPlaceholderData,
      }
    }
  }, [contactsQuery, pageSize])

  const contactsCountQuery = useQuery({
    queryKey: ['getContactsCount', orgId, queryFilter],
    queryFn: () => {
      let filters = {}
      if (Object.values(queryFilter)) {
        const valueOnlyFilter = noEmptyValues(queryFilter)
        const { event, donation, count, ...rest } = valueOnlyFilter
        const state_abbr = USStates.find(
          stateObj => stateObj.name === rest['state']
        )
          ? USStates.find(stateObj => stateObj.name === rest['state']).state
          : undefined
        filters = noEmptyValues({
          ...omit(['state'], rest),
          state_abbr,
        })
      }
      return Object.entries(filters).length
        ? ContactsApi.getContactsCount(orgId, filters)
        : ContactsApi.getQueueContactsCount(orgId)
    },
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
    enabled: !!orgId,
  })
  const contactsCount = useMemo(() => {
    const { data, isFetching } = contactsCountQuery
    if (data) {
      return {
        count: data.count,
        pageCount: Math.ceil(data.count / pageSize),
        isFetching,
      }
    } else {
      return {
        count: 0,
        pageCount: 1,
        isFetching,
      }
    }
  }, [contactsCountQuery, pageSize])

  React.useEffect(() => {
    if (contactsCount.pageCount) {
      dispatch(setQueueContactPage(1))
    }
  }, [contactsCount.pageCount])

  const fetchEventParticipants = (event, donation) =>
    DonationsApi.getEventParticipants(orgId, event, donation)

  const filterContactsByDonations = (contacts, event, donation) => {
    if (!event && !donation) {
      return contacts
    }
    return fetchEventParticipants(event, donation).then(donors =>
      contacts.filter(contact => donors.find(donor => donor.id === contact._id))
    )
  }

  const applyFilterHandler = () => {}

  const closeModal = () => {
    resetFilter()
    props.onHide()
  }

  const addContactsHandler = () => {
    const selectedContacts = contactRecords.records.reduce((acc, curr, idx) => {
      let selected = acc
      if (contactsTableRowSelection[idx]) {
        selected.push(curr)
      }
      return selected
    }, [])
    props.addContacts(selectedContacts)
    closeModal()
  }

  const filterContactsFormProps = {
    contacts: allContacts,
    queryFilter,
    setQueryFilter,
    resetFilter,
    showDonationTypes: false,
    showEvents: false,
    showCapacityFilter: true,
  }

  const contactsTableProps = {
    contacts: contactRecords.records,
    loadingContacts: contactRecords.isFetching,
    filterContacts: contactRecords.records,
    columnFilters: contactsTableFilters,
    setColumnFilters: setContactsTableFilters,
    rowSelection: contactsTableRowSelection,
    setRowSelection: setContactsTableRowSelection,
    sorting: contactsTableSorting,
    setSorting: setContactsTableSorting,
    pagination: contactsTablePagination,
    setPagination: setContactsTablePagination,
    currentPage: currentPage,
    setCurrentPage: handleSetCurrentPage,
    pageCount: contactsCount.pageCount,
    pageSize: pageSize,
    setPageSize: handleSetPageSize,
  }

  return (
    <Modal
      onHide={closeModal}
      show={props.show}
      size="xl"
      aria-labelledby="add-contacts-to-pipeline"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Contacts
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={5}>
              <FilterContactsFormJS {...filterContactsFormProps} />
              {/* <Button onClick={applyFilterHandler}>
                <BsSearch /> &nbsp;Search
              </Button> */}
            </Col>
            <Col xs={7}>
              <ContactsTable {...contactsTableProps} />
              {Object.entries(contactsTableRowSelection).length ? (
                <div style={{ marginTop: '12px' }}>
                  <Button onClick={addContactsHandler}>
                    <BsPersonPlus /> &nbsp;Add Selected
                  </Button>
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={closeModal}>Close</Button>
      </Modal.Footer>
    </Modal>
  )
}

AddContactsModal.propTypes = {
  addContacts: PropTypes.func,
  onHide: PropTypes.func,
  show: PropTypes.bool,
}

export default AddContactsModal
